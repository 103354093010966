.c-site-header {
    $class: c-site-header;

    transition: filter .25s ease-in-out, box-shadow .1s ease-in-out;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: $color-grey-xlight;
    z-index: $z-header;
    box-shadow: $header-shadow;

    &__holder {
        @include content-width();
        height: $header-height-mobile;
        margin-left: auto;
        margin-right: auto;
        -js-display: flex;
        display: flex;
        align-items: center;
    }

        &__skip-to-content {
            position: fixed;
            left: 0;
            top: -100%;
            z-index: $z-header + 1;

            &:focus {
                top: 0;
            }
        }

        &__logo {
            transform: rotateY(0);
            transition: transform .5s ease-in-out;
        }

            &__logo-title {
                margin: 0;
                display: flex;
                flex-direction: column;
                align-items: center;
            }

                &__logo-title-anchor {
                    @include reset-link-styles;
                    @include u-logo-font;
                    font-size: 50px;
                    line-height: 1;
                    padding: 0;
                    font-weight: 300;
                    color: $color-1;

                    &:hover {
                        color: $color-1;
                    }

                    &:focus {
                        outline: 1px dotted $black;
                    }
                }

            &__custom-logo-title {
                margin: 0;
                font-size: 18px;
                text-transform: none;
                display: block;
            }

                &__custom-logo-anchor {
                    @include reset-link-styles;
                    display: inline-block;
                    @include font-heavy;
                    color: $color-1;
                    padding: $space-xs;

                    &:hover {
                        color: $color-1;
                    }

                    &:focus {
                        border: 1px dotted $black;
                    }
                }

                    &__custom-logo-u {
                        @include u-font;
                        font-size: 16px;
                    }

                    &__custom-logo-name {
                        margin: 0;
                        @include font-heavy;
                        display: block;
                        color: $color-1;
                        text-align: left;
                    }

        &__navigation {
            display: none;
        }

            &__nav-list {
                list-style: none;
                margin: 0;
                padding: 0;
                margin-right: $space-m;
                line-height: 1;
            }

                &__nav-list-item {
                    display: inline-block;
                    position: relative;
                    margin: 0 $space-xs 0 0;
                    cursor: pointer;

                    &:hover,
                    &:focus {
                        .#{$class}__nav-link {
                            &:after {
                                left: $space-m;
                                right: $space-m;
                            }
                        }

                        .#{$class}__dropdown-list-wrapper {
                            left: 50%;
                            opacity: 1;
                            z-index: 1;
                            transform: translate(-50%, 0%);
                            transition-delay: 0s, 0s, 0s, 0s;
                        }

                        .#{$class}__dropdown-list-item {
                            opacity: 1;
                        }

                        &:after {
                            color: $color-1;
                            transform: translateY(-40%);
                        }
                    }

                    &:focus-within {
                        .#{$class}__nav-link {
                            &:after {
                                left: $space-m;
                                right: $space-m;
                            }
                        }

                        .#{$class}__dropdown-list-wrapper {
                            left: 50%;
                            opacity: 1;
                            z-index: 1;
                            transform: translate(-50%, 0%);
                            transition-delay: 0s, 0s, 0s, 0s;
                        }

                        .#{$class}__dropdown-list-item {
                            opacity: 1;
                        }

                        &:after {
                            color: $color-1;
                            transform: translateY(-40%);
                        }
                    }

                    &--icon-down {
                        padding-right: $space-xxs;

                        &:after {
                            @include ffrees-icons-font;
                            content: $icon-angle-down;
                            display: inline-block;
                            transform: translateY(-50%);
                            transition: all .3s ease-in-out;
                            position: absolute;
                            top: 50%;
                            right: 0;
                        }
                    }
                }

                    &__nav-link {
                        $underline-height: 2px;

                        @include navigation-link-text;
                        @include underline($black, $underline-height);
                        color: $black;
                        padding: $space-m;

                        &:after {
                            bottom: $space-m - $underline-height;
                        }

                        &:hover,
                        &:focus,
                        &:active {
                            color: $black;

                            &:after {
                                left: $space-m;
                                right: $space-m;
                            }
                        }

                        &:focus {
                            ~ .#{$class}__dropdown-list-wrapper {
                                left: 50%;
                                opacity: 1;
                                z-index: 1;
                                transform: translate(-50%, 0%);
                                transition-delay: 0s, 0s, 0s, 0s;

                                .#{$class}__dropdown-list-item {
                                    opacity: 1;
                                }
                            }
                        }

                        &.is-current {
                            &:after {
                                left: $space-m;
                                right: $space-m;
                            }
                        }
                    }

                    &__notification-holder {
                        position: absolute;
                        right: 12px;
                        top: -12px;
                    }

                    &__dropdown-list-wrapper {
                        position: absolute;
                        left: -9999px;
                        top: 100%;
                        padding-top: $space-xs;
                        opacity: 0;
                        transform: translate(-50%, -20%);
                        z-index: -1;
                        transition: transform 0.3s ease-in-out 0s, opacity 0.3s ease-in-out 0s, z-index 0s linear 0.01s, left 0s linear 0.3s;
                    }

                        &__dropdown-list {
                            list-style: none;
                            padding: 0;
                            margin: 0;
                            width: 250px;
                            background-color: $color-grey-xlight;
                            border: solid 1px $color-grey-mid;
                            box-shadow: 1px 1px 3px $color-grey-mid;
                        }

                            &__dropdown-list-item {
                                display: block;
                                margin: 0;
                                border-bottom: solid 1px $color-grey-mid;
                                opacity: 0;
                                transition: opacity .2s ease-in-out .3s;
                                position: relative;

                                &:last-child {
                                    border-bottom: 0;
                                }

                                &.is-hidden {
                                    display: none;
                                }
                            }

                                &__dropdown-notification-holder {
                                    position: absolute;
                                    left: calc(#{$space-l}/2 - 3px);
                                    top: 50%;
                                    transform: translateY(-50%);
                                }

                                &__dropdown-link {
                                    @include sub-nav-link-text;
                                    padding: $space-xxs $space-l;
                                    display: block;
                                    color: $color-1;
                                    transition: color .3s ease-in-out, background-color .3s ease-in-out;

                                    &:hover,
                                    &:focus {
                                        color: $color-1;
                                        background-color: $color-1-mid;
                                    }

                                    &.is-current {
                                        color: $white;
                                        background-color: $color-1;

                                        &:hover,
                                        &:focus {
                                            color: $white;
                                            background-color: $color-1;
                                        }
                                    }
                                }

        &__button-wrap {
            margin-left: auto;
        }

            .c-btn-outline {
                margin-right: $space-xxs;

                &:last-child {
                    margin-right: 0;
                }
            }

    &--no-shadow-until-s-bp {
        box-shadow: none;

        &.is-nav-open {
            box-shadow: $header-shadow;
            transition-delay: 0s, $bottom-nav-transition - .1s;
        }
    }

    @at-root .u-blur-content {
        .#{$class} {
            transition: filter .25s ease-in-out;
            filter: blur(20px);
        }
    }

    @at-root .l-reduced-header .#{$class} {
        &__holder {
            justify-content: center;
        }
    }

    @at-root .l-reduced-header, .l-no-navigation {
        .#{$class} {
            &__button-wrap {
                display: none;
            }

            &__navigation {
                display: none;
            }
        }
    }

    @media (min-width: $s_bp) {
        &--no-shadow-until-s-bp {
            box-shadow: $header-shadow;
        }
    }

    @media (min-width: $m_bp) {
        &__holder {
            height: $header-height-desktop;
        }

            &__logo-title-anchor {
                font-size: 80px;
            }

            &__custom-logo-title {
                @include font-size(32px, 1);
            }

                &__custom-logo-anchor {
                    padding: $space-xs;
                }

                    &__custom-logo-u {
                        font-size: 26px;
                    }

            &__navigation {
                margin-left: auto;
                display: block;
            }

            &__button-wrap {
                margin-left: 0;
            }

                .c-btn-outline {
                    margin-right: $space-xs;
                }
    }

    @media (min-width: $l_bp) {
        &__nav-list-item {
            margin: 0 $space-xl 0 0;
        }
    }
}

.styleguide {
    .c-site-header {
        position: absolute;
        z-index: 30;

        &__dropdown-list-wrapper {
            z-index: 0;
        }
    }
}

/*doc
---
title: site header (logged in)
name: site-header-logged-in
category: Global
---

Note - the second <code>is-current</code> state modifier affects the nav items in the dropdown menu.
<br>
The <code>c-site-header__dropdown-list-item--hidden</code> modifier will hide all of the dropdown items here due to the nature of the styleguide, but in practice hides single items from the list.

```html_example
<header data-parent-classes="u-sg-height-667 u-sg-overflow-hidden" class="c-site-header">
    <div class="c-site-header__holder">

        <div class="c-site-header__logo js-customise-logo">
            <h1 class="c-site-header__logo-title">
                <a class="c-site-header__logo-title-anchor" href="/">
                    U
                </a>
            </h1>
        </div>

        <nav class="c-site-header__navigation">
            <ul class="c-site-header__nav-list">

                <li class="c-site-header__nav-list-item c-site-header__nav-list-item--icon-down">
                    <a class="c-site-header__nav-link">Accounts</a>
                    <div class="c-site-header__dropdown-list-wrapper">
                        <ul class="c-site-header__dropdown-list">
                            <li class="c-site-header__dropdown-list-item">
                                <a class="c-site-header__dropdown-link" href="/dashboard">Overview</a>
                            </li>
                            <li class="c-site-header__dropdown-list-item">
                                <a class="c-site-header__dropdown-link" href="/dashboard/extra-accounts">Extra Accounts</a>
                            </li>
                            <li class="c-site-header__dropdown-list-item">
                                <a class="c-site-header__dropdown-link" href="/dashboard/money-manager">Money Manager</a>
                            </li>
                            <li class="c-site-header__dropdown-list-item">
                                <a class="c-site-header__dropdown-link" href="/dashboard/statements">Statements</a>
                            </li>
                            <li class="c-site-header__dropdown-list-item">
                                <a class="c-site-header__dropdown-link" href="/points">Points</a>
                            </li>
                            <li class="c-site-header__dropdown-list-item">
                                <a class="c-site-header__dropdown-link" href="/dashboard/referral">Refer a friend</a>
                            </li>
                        </ul>
                    </div>
                </li>

                <li class="c-site-header__nav-list-item">
                    <a class="c-site-header__nav-link" href="/dashboard/payments">Pay</a>
                </li>

                <li class="c-site-header__nav-list-item">
                    <a class="c-site-header__nav-link" href="/dashboard/my-cards">Card</a>
                </li>

                <li class="c-site-header__nav-list-item">
                    <a class="c-site-header__nav-link" href="/dashboard/profile">Profile</a>
                </li>

                <li class="c-site-header__nav-list-item c-site-header__nav-list-item--icon-down">
                    <a class="c-site-header__nav-link">Help</a>
                    <div class="c-site-header__notification-holder">
                        <div class="c-notification c-notification--font-heavy js-notification is-shown">
                            <div class="c-notification__text js-notification-count">1</div>
                        </div>
                    </div>
                    <div class="c-site-header__dropdown-list-wrapper">
                        <ul class="c-site-header__dropdown-list">
                            <li class="c-site-header__dropdown-list-item">
                                <a class="c-site-header__dropdown-link" href="/help">Help &amp; Support</a>
                            </li>
                            <li class="c-site-header__dropdown-list-item c-site-header__dropdown-list-item--hidden js-notification-link">
                                <div class="c-site-header__dropdown-notification-holder">
                                    <div class="c-notification c-notification--dot"></div>
                                </div>
                                 <a class="c-site-header__dropdown-link" href="/help?current-panel=contact"><span class="js-notifications-count">2</span> Unread Messages</a>
                            </li>
                            <li class="c-site-header__dropdown-list-item">
                                <a class="c-site-header__dropdown-link" href="/service-status">Service Status</a>
                            </li>
                            <li class="c-site-header__dropdown-list-item">
                                <a class="c-site-header__dropdown-link" href="/policies">Policies &amp; Terms</a>
                            </li>
                        </ul>
                    </div>
                </li>

                <li class="c-site-header__nav-list-item">
                    <a class="c-site-header__nav-link">U Credit</a>
                    <div class="c-site-header__notification-holder">
                        <div class="c-notification is-shown c-notification--variable-width c-notification--thin-border c-notification--color-1">
                            <div class="c-notification__text">New</div>
                        </div>
                    </div>
                </li>

            </ul>
        </nav>

        <div class="c-site-header__button-wrap">
            <a href="/logout" class="c-btn-outline c-btn-outline--small c-btn-outline--margin-bottom-0 tt_LogoutHeader">Log out</a>
        </div>

    </div>
</header>

```
<div class="c-sg-modifier-wrap js-modifier-wrap">
    <div>
        <button data-target="c-site-header__nav-list-item">c-site-header__nav-list-item--icon-down</button>
    </div>
    <div>
        <button data-target="c-site-header__nav-link">is-current</button>
    </div>
    <div>
        <button data-target="c-site-header__dropdown-link">is-current</button>
    </div>
    <div>
        <button data-target="c-site-header__dropdown-list-item">is-hidden</button>
    </div>
</div>
*/

/*doc
---
title: site header (logged out)
name: site-header-logged-out
category: Global
---

Note - the second <code>is-current</code> state modifier affects the nav items in the dropdown menu

```html_example
<header class="c-site-header js-site-header" data-parent-classes="u-sg-height-667 u-sg-overflow-hidden">

    <div class="c-site-header__holder">

        <div class="c-site-header__logo js-customise-logo">
            <h1 class="c-site-header__logo-title">
                <a class="c-site-header__logo-title-anchor" href="/">
                    U
                </a>
            </h1>
        </div>

        <nav class="c-site-header__navigation">
            <ul class="c-site-header__nav-list">
                <li class="c-site-header__nav-list-item">
                    <a class="c-site-header__nav-link" href="/pricing">Pricing</a>
                </li>
                <li class="c-site-header__nav-list-item">
                    <a class="c-site-header__nav-link" href="https://medium.com/u-account" target="blank">Blog</a>
                </li>
                <li class="c-site-header__nav-list-item c-site-header__nav-list-item--icon-down">
                    <a class="c-site-header__nav-link">Help</a>
                    <div class="c-site-header__dropdown-list-wrapper">
                        <ul class="c-site-header__dropdown-list">
                            <li class="c-site-header__dropdown-list-item">
                                <a class="c-site-header__dropdown-link" href="/help">Help &amp; Support</a>
                            </li>
                            <li class="c-site-header__dropdown-list-item">
                                <a class="c-site-header__dropdown-link" href="/service-status">Service Status</a>
                            </li>
                        </ul>
                    </div>
                </li>
            </ul>
        </nav>

        <div class="c-site-header__button-wrap">
            <a href="/unbank" id="ttJoinHeader" class="c-btn-outline c-btn-outline--small c-btn-outline--margin-bottom-0 c-btn-outline--margin-right-xs">Apply</a>
            <a href="#" class="c-btn-outline c-btn-outline--black c-btn-outline--small c-btn-outline--margin-bottom-0 js-login-button tt_LoginHeader">Log in</a>
        </div>

    </div>
</header>
```
<div class="c-sg-modifier-wrap js-modifier-wrap">
    <div>
        <button data-target="c-site-header__nav-list-item">c-site-header__nav-list-item--icon-down</button>
    </div>
    <div>
        <button data-target="c-site-header__nav-link">is-current</button>
    </div>
    <div>
        <button data-target="c-site-header__dropdown-link">is-current</button>
    </div>
</div>
*/
