/*------------------------------------*\
    placeholder
\*------------------------------------*/

@keyframes placeholder-translate {
    0% {
        transform: translateX(-100%);
    }
    100% {
        transform: translateX(0%);
    }
}

$placeholder-bg: rgb(230, 230, 230);
$placeholder-row-height: $space-xxs;
$placeholder-margin: $space-xs;
$placeholder-margin-and-row-height: $placeholder-row-height + $placeholder-margin;
$placeholder-margin-to-row-percentage: ($placeholder-margin / $placeholder-margin-and-row-height) * 100%;

@mixin placeholder-before-after() {
    overflow: hidden;
    background: $placeholder-bg;
    display: flex;
    position: relative;

    &:before,
    &:after {
        $placeholder-highlight: hsl(0, 0%, 98%);
        $center-width: 34;
        $center-width-half: $center-width / 2;

        content: '';
        width: 180%; // width increased for animation pace
        height: 100%;
        display: block;
        transform: translate(0%);
        flex-shrink: 0;
        background: linear-gradient(
            to right,
            rgba(255,255,255,0) (50% - $center-width / 2),
            $placeholder-highlight 50%,
            rgba(255,255,255,0) (50% + $center-width / 2)
        );
        animation: placeholder-translate 2s linear infinite forwards;
    }
}

@mixin placeholder-white-lines-overlay {
    height: 100%;
    position: absolute;
    top: $placeholder-row-height;
    left: 0;
    width: 100%;
    z-index: 1;
    background: linear-gradient(
        to bottom,
        $white      $placeholder-margin-to-row-percentage,
        transparent $placeholder-margin-to-row-percentage
        );
    background-size: 100% ($placeholder-row-height + $placeholder-margin);
}

.c-placeholder-rows {
    height: (($placeholder-row-height + $placeholder-margin) * 4) - $placeholder-margin;
    margin-bottom: $space-l;
    @include placeholder-before-after;
    cursor: progress;

    &__overlay-lines {
        @include placeholder-white-lines-overlay;

        &:before,
        &:after {
            content: '';
            display: block;
            position: absolute;
            height: $placeholder-row-height;
            width: 50%;
            left: 50%;
            bottom: $placeholder-row-height;
            background: $white;
        }

        &:after {
            left: 75%;
            width: 25%;
            bottom: ($placeholder-row-height * 2) + $placeholder-margin;
        }
    }

    $rows: 1 2 3 5 6 8 10 12 14 18 22;
    @each $row in $rows {
        &--#{$row}-rows {
            height: (($placeholder-row-height + $placeholder-margin) * $row) - $placeholder-margin;
        }
    }
}

.c-placeholder-table {
    height: (($placeholder-row-height + $placeholder-margin) * 5) - $placeholder-margin;
    margin-bottom: $space-l;
    padding: 0;
    @include placeholder-before-after;
    cursor: progress;

    &__overlay-head {
        height: $placeholder-row-height + $placeholder-margin;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 1;

        &:before,
        &:after {
            content: '';
            display: block;
            height: 100%;
            position: absolute;
            left: 33.33%;
            width: $placeholder-margin * 2;
            background: $white;
            transform: translateX(-66.6%);
        }

        &:after {
            left: 66.66%;
            transform: translateX(-33.3%);
        }
    }

    &__overlay-lines {
        @include placeholder-white-lines-overlay;

        &:before,
        &:after {
            content: '';
            display: block;
            position: absolute;
            height: 100%;
            width: $placeholder-margin * 2;
            top: $placeholder-row-height + $placeholder-margin;
            left: 33.33%;
            background: $white;
            transform: translateX(-66.6%);
        }

        &:after {
            left: 66.66%;
            transform: translateX(-33.3%);
        }
    }

    $table-rows: 4 6 8 10 12 14 18 22;
    @each $row in $table-rows {
        &--#{$row}-rows {
            height: (($placeholder-row-height + $placeholder-margin) * $row) - $placeholder-margin;
        }
    }
}

.c-placeholder-tile {
    height:
    (($placeholder-row-height + $placeholder-margin) * 2)
    + (($placeholder-row-height + $placeholder-margin) * 5) // rows
    - $placeholder-margin;
    margin-bottom: $space-l;
    @include placeholder-before-after;
    cursor: progress;

    &__overlay-border {
        position: absolute;
        top: $placeholder-row-height;
        left: $placeholder-row-height;
        width: calc(100% - (#{$placeholder-row-height} * 2));
        height: calc(100% - (#{$placeholder-row-height} * 2));
        padding: $placeholder-row-height;
        border: $placeholder-margin solid $white;
        z-index: 2;

        &:before {
            content: '';
            display: block;
            position: absolute;
            bottom: ($placeholder-margin + $placeholder-row-height) * 2;
            left: 75%;
            width: 25%;
            height: $placeholder-row-height;
            background: $white;
        }
    }

    &__overlay-lines {
        @include placeholder-white-lines-overlay;
        left: $placeholder-row-height;
        height: calc(100% - (#{$placeholder-row-height} * 2));
        width: calc(100% - (#{$placeholder-row-height} * 2));

        &:before,
        &:after {
            content: '';
            display: block;
            position: absolute;
            bottom: $placeholder-margin;
            left: $placeholder-margin;
            width: calc(100% - (#{$placeholder-margin} * 2));
            height: $placeholder-row-height;
            background: $white;
        }

        &:before {
            bottom: ($placeholder-margin * 2) + $placeholder-row-height;
            width: calc(50% - (#{$placeholder-margin} * 1));
            left: 50%;
            background: $white;
        }
    }

    $rows: 3 4 6 8 10 12 14;
    @each $row in $rows {
        &--#{$row}-rows {
            height:
            (($placeholder-row-height + $placeholder-margin) * 2)
            + (($placeholder-row-height + $placeholder-margin) * $row)
            - $placeholder-margin;
        }
    }
}

.c-placeholder-tile-wrap {
    $gutter: 30px;

    display:flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .c-placeholder-tile {
        flex: 0 1 auto;
        width: 100%;
    }

    @media (min-width:700px) {
        .c-placeholder-tile {
            width: calc(50% - #{$gutter / 2});
        }
    }

    @media (min-width:1400px) {
        .c-placeholder-tile {
            width: calc(33.3% - #{$gutter * 0.66});
        }
    }
}

.c-placeholder-accordion {
    $class: c-placeholder-accordion;
    $accordion-height: ($placeholder-row-height * 3) + ($placeholder-margin * 2);
    $accordion-gap: ($accordion-height - ($placeholder-row-height * 3)) / 2;
    $accordion-margin: 4px;

    @include placeholder-before-after;
    height: ($accordion-height + $accordion-margin) * 4; // 4 accordions
    margin-bottom: 1px;
    cursor: progress;

    &__lines-1 {
        height: 100%;
        position: absolute;
        top:0;
        left: $placeholder-row-height;
        width: calc(100% - (#{$placeholder-row-height} * 2));
        z-index: 1;
        background: linear-gradient(
            to bottom,
            transparent $placeholder-row-height,
            $white      $placeholder-row-height,
            $white      $placeholder-row-height + $placeholder-margin,
            transparent $placeholder-row-height + $placeholder-margin,
            transparent $placeholder-row-height * 2 + $placeholder-margin,
            $white      ($placeholder-row-height * 2) + $placeholder-margin,
            $white      ($placeholder-row-height * 2) + ($placeholder-margin * 2),
            transparent ($placeholder-row-height * 2) + ($placeholder-margin * 2)
            );
        background-size: 100% $accordion-height + $accordion-margin;

        &:before,
        &:after {
            content: '';
            height: 100%;
            position: absolute;
            top: $placeholder-row-height;
            left: 0;
            width: $placeholder-margin;
            z-index: 1;
            background: linear-gradient(
                to bottom,
                transparent $placeholder-row-height,
                $white      $placeholder-row-height,
                $white      $placeholder-row-height + ($placeholder-margin  * 2),
                transparent $placeholder-row-height + ($placeholder-margin  * 2)
                );
            background-size: 100% $accordion-height + $accordion-margin;
        }

        &:after {
            left: auto;
            right: 0;
        }
    }

    &__lines-2 {
        height: 100%;
        position: absolute;
        top:0;
        left: 0;
        width: 100%;
        z-index: 1;
        background: linear-gradient(
            to bottom,
            transparent $accordion-height,
            $white      $accordion-height,
            $white      $accordion-height + $accordion-margin,
            transparent $accordion-height + $accordion-margin
        );
        background-size: 100% $accordion-height + $accordion-margin;

        &:after {
            content: '';
            height: 100%;
            position: absolute;
            top:0;
            right: $placeholder-row-height * 2 + $placeholder-margin;
            width: 67%;
            z-index: 1;
            background: linear-gradient(
                to bottom,
                transparent $placeholder-row-height + $placeholder-margin,
                $white      $placeholder-row-height + $placeholder-margin,
                $white      ($placeholder-row-height * 2) + $placeholder-margin,
                transparent ($placeholder-row-height * 2) + $placeholder-margin
                );
            background-size: 100% $accordion-height + $accordion-margin;
        }
    }

    $rows: 1 2 3 4 5 6 7 8 9 10;
    @each $row in $rows {
        &--#{$row} {
            height: ($accordion-height + $accordion-margin) * $row;
        }
    }

    &--grey-xxxlight-margin-bg {
        .#{$class}__lines-2 {
            background: linear-gradient(
                to bottom,
                transparent          $accordion-height,
                $color-grey-xxxlight $accordion-height,
                $color-grey-xxxlight $accordion-height + $accordion-margin,
                transparent          $accordion-height + $accordion-margin
            );
            background-size: 100% $accordion-height + $accordion-margin;
        }
    }

    &--margin-bottom-xxs {
        margin-bottom: $space-xxs;
    }

    @media (min-width: $xs_bp) {
        &--grey-xxxlight-margin-bg-xs {
            $margin-bg-color: $color-grey-xxxlight;

            .#{$class}__lines-2 {
                background: linear-gradient(
                    to bottom,
                    transparent          $accordion-height,
                    $margin-bg-color     $accordion-height,
                    $margin-bg-color     $accordion-height + $accordion-margin,
                    transparent          $accordion-height + $accordion-margin
                );
                background-size: 100% $accordion-height + $accordion-margin;
            }
        }
    }

    @media (min-width: $xxl_bp) {
        &--white-margin-bg-xxl {
            $margin-bg-color: $white;

            .#{$class}__lines-2 {
                background: linear-gradient(
                    to bottom,
                    transparent          $accordion-height,
                    $margin-bg-color     $accordion-height,
                    $margin-bg-color     $accordion-height + $accordion-margin,
                    transparent          $accordion-height + $accordion-margin
                );
                background-size: 100% $accordion-height + $accordion-margin;
            }
        }
    }
}

.c-placeholder-content-filter-wrap {
    $filtersOnRow: 4;

    display: flex;
    justify-content: space-between;

    .c-placeholder-content-filter {
        flex: 0 1 auto;
        width: 25%;
    }

    @media (min-width: $m_bp) {
        &--filters-margin-left-10 {
            .c-placeholder-content-filter {
                margin-left: 10%/$filtersOnRow;
            }
        }
    }
}

.c-placeholder-content-filter {
    $class: c-placeholder-content-filter;
    $background-color: $white;
    $left-margin: calc((100% - (#{$placeholder-row-height} * 3) - (#{$placeholder-margin} * 2)) / 2);
    $right-margin: calc((100% - (#{$placeholder-row-height} * 3) - (#{$placeholder-margin} * 2)) / 2);

    @include placeholder-before-after;
    height: ($placeholder-row-height * 3) + ($placeholder-margin * 2);
    margin-bottom: $space-l;
    cursor: progress;

    &__lines-1 {
        &:before,
        &:after {
            content: '';
            display: block;
            position: absolute;
            z-index: 1;
            top: 0;
            left: 0;
            width: calc(#{$left-margin} + #{$placeholder-margin} + #{$placeholder-row-height});
            height: calc(100% - (#{$placeholder-row-height}));
            background-color: $background-color;
        }

        &:after {
            left: calc(#{$left-margin} + (#{$placeholder-row-height} * 2) + #{$placeholder-margin});
        }
    }

    &__lines-2 {
        &:before,
        &:after {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            z-index: 1;
            left: $left-margin;
            width: ($placeholder-margin * 2) + ($placeholder-row-height * 3);
            height: $placeholder-margin + $placeholder-row-height;
            background-color: $background-color;
        }

        &:after {
            top: ($placeholder-row-height * 2) + $placeholder-margin;
            height: $placeholder-margin;
        }
    }

    &__lines-3 {
        &:before {
            content: '';
            display: block;
            position: absolute;
            z-index: 1;
            top: 0;
            left: 0;
            width: $placeholder-margin;
            height: 100%;
            background-color: $background-color;
        }

        &:after {
            content: '';
            display: block;
            position: absolute;
            z-index: 1;
            top: 0;
            right: 0;
            width: $placeholder-margin;
            height: 100%;
            background-color: $background-color;
        }
    }

    &__lines-4 {
        &:before,
        &:after {
            display: block;
            position: absolute;
            z-index: 1;
            top: 0;
            right: 0;
            width: $right-margin;
            height: $placeholder-margin-and-row-height;
            background-color: $background-color;
        }

        &:after {
            top: $placeholder-margin + ($placeholder-row-height * 2);
        }
    }

    &--color-grey-xlight-bg {
        $lines-color: $color-grey-xlight;

        background-color: $color-grey-mid;

        &:before,
        &:after {
            $placeholder-highlight: $lines-color;
            $center-width: 34;

            background: linear-gradient(
                to right,
                transparent (50% - $center-width / 2),
                $placeholder-highlight 50%,
                transparent (50% + $center-width / 2)
            );
        }

        .#{$class}__lines-1,
        .#{$class}__lines-2,
        .#{$class}__lines-3,
        .#{$class}__lines-4 {
            &:before,
            &:after {
                background-color: $lines-color;
            }
        }
    }

    @media (min-width: $m_bp) {
        $right-margin: calc(100% - (#{$placeholder-margin} * 2) - (#{$placeholder-row-height} * 3));

        &__lines-1 {
            &:before,
            &:after {
                left: 0;
                width: $placeholder-margin + $placeholder-row-height;
            }

            &:after {
                left: ($placeholder-row-height * 2) + $placeholder-margin;
            }
        }

        &__lines-2 {
            &:before,
            &:after {
                left: 0;
            }

            &:after {
                height: $placeholder-margin + $placeholder-row-height;
            }
        }

        &__lines-3 {
            &:before {
                width: 0;
            }

            &:after {
                left: ($placeholder-margin * 2) + ($placeholder-row-height * 3);
                width: $placeholder-margin;
            }
        }

        &__lines-4 {
            &:before,
            &:after {
                content: '';
                width: $right-margin;
            }
        }
    }
}


/*doc
---
title: Placeholders
name: placeholders
category: Placeholders
---
<b>Placeholder rows</b> have a default height of 4 rows. Use a modifier for a different height.

```html_example
<div class="c-placeholder-rows">
    <div class="c-placeholder-rows__overlay-lines"></div>
</div>
```
<div class="c-sg-modifier-wrap js-modifier-wrap">
    <div>
        <button>c-placeholder-rows--1-rows</button>
        <button>c-placeholder-rows--2-rows</button>
        <button>c-placeholder-rows--3-rows</button>
        <button>c-placeholder-rows--5-rows</button>
        <button>c-placeholder-rows--6-rows</button>
        <button>c-placeholder-rows--8-rows</button>
        <button>c-placeholder-rows--10-rows</button>
        <button>c-placeholder-rows--12-rows</button>
        <button>c-placeholder-rows--14-rows</button>
        <button>c-placeholder-rows--18-rows</button>
        <button>c-placeholder-rows--22-rows</button>
    </div>
</div>

<b>Placeholder table</b> has a default height of 5 (2 header + 3 content rows). Use a modifier for a different height.

```html_example
<div class="c-placeholder-table">
    <div class="c-placeholder-table__overlay-head"></div>
    <div class="c-placeholder-table__overlay-lines"></div>
</div>
```
<div class="c-sg-modifier-wrap js-modifier-wrap">
    <div>
        <button>c-placeholder-table--4-rows</button>
        <button>c-placeholder-table--6-rows</button>
        <button>c-placeholder-table--8-rows</button>
        <button>c-placeholder-table--10-rows</button>
        <button>c-placeholder-table--12-rows</button>
        <button>c-placeholder-table--14-rows</button>
        <button>c-placeholder-table---18-rows</button>
        <button>c-placeholder-table---22-rows</button>
    </div>
</div>

<b>Placeholder accordion</b> has a default height of 4 accordions. Use a modifier for a different amount.

```html_example
<div class="c-placeholder-accordion">
    <div class="c-placeholder-accordion__lines-1"></div>
    <div class="c-placeholder-accordion__lines-2"></div>
</div>
```
<div class="c-sg-modifier-wrap js-modifier-wrap">
    <div>
        <button>c-placeholder-accordion--1</button>
        <button>c-placeholder-accordion--2</button>
        <button>c-placeholder-accordion--3</button>
        <button>c-placeholder-accordion--5</button>
        <button>c-placeholder-accordion--6</button>
        <button>c-placeholder-accordion--7</button>
        <button>c-placeholder-accordion--8</button>
        <button>c-placeholder-accordion--9</button>
        <button>c-placeholder-accordion--10</button>
    </div>
    <div>
        <button>c-placeholder-accordion--margin-bottom-xxs</button>
    </div>
    <div>
        <button>c-placeholder-accordion--grey-xxxlight-margin-bg-xs</button>
    </div>
    <div>
        <button>c-placeholder-accordion--white-margin-bg-xxl</button>
    </div>
</div>

<b>Placeholder tile</b> has a default height of 5 rows high. Use a modifier for a different height.

```html_example
<div class="c-placeholder-tile">
    <div class="c-placeholder-tile__overlay-border"></div>
    <div class="c-placeholder-tile__overlay-lines"></div>
</div>
```
<div class="c-sg-modifier-wrap js-modifier-wrap">
    <div>
        <button>c-placeholder-tile--3-rows</button>
        <button>c-placeholder-tile--4-rows</button>
        <button>c-placeholder-tile--6-rows</button>
        <button>c-placeholder-tile--8-rows</button>
        <button>c-placeholder-tile--10-rows</button>
        <button>c-placeholder-tile--12-rows</button>
        <button>c-placeholder-tile--14-rows</button>
    </div>
</div>

To have multiple tiles with responsive layout change, wrap them in a <code>c-placeholder-tile-wrap</code>

```html_example
 <div class="c-placeholder-tile-wrap">
    <div class="c-placeholder-tile">
        <div class="c-placeholder-tile__overlay-border"></div>
        <div class="c-placeholder-tile__overlay-lines"></div>
    </div>
    <div class="c-placeholder-tile">
        <div class="c-placeholder-tile__overlay-border"></div>
        <div class="c-placeholder-tile__overlay-lines"></div>
    </div>
    <div class="c-placeholder-tile">
        <div class="c-placeholder-tile__overlay-border"></div>
        <div class="c-placeholder-tile__overlay-lines"></div>
    </div>
    <div class="c-placeholder-tile">
        <div class="c-placeholder-tile__overlay-border"></div>
        <div class="c-placeholder-tile__overlay-lines"></div>
    </div>
</div>

```

<b>Placeholder content filter</b> has a default height of 1 rows high.

```html_example
<div class="c-placeholder-content-filter">
    <div class="c-placeholder-content-filter__lines-1"></div>
    <div class="c-placeholder-content-filter__lines-2"></div>
    <div class="c-placeholder-content-filter__lines-3"></div>
    <div class="c-placeholder-content-filter__lines-4"></div>
</div>

```
<div class="c-sg-modifier-wrap js-modifier-wrap">
    <div>
        <button>c-placeholder-content-filter--color-grey-xlight-bg</button>
    </div>
</div>

To have multiple content filters with 25% width, wrap them in a <code>c-placeholder-content-filter-wrap</code>


```html_example
<div class="c-placeholder-content-filter-wrap">
    <div class="c-placeholder-content-filter">
        <div class="c-placeholder-content-filter__lines-1"></div>
        <div class="c-placeholder-content-filter__lines-2"></div>
        <div class="c-placeholder-content-filter__lines-3"></div>
        <div class="c-placeholder-content-filter__lines-4"></div>
    </div>
    <div class="c-placeholder-content-filter">
        <div class="c-placeholder-content-filter__lines-1"></div>
        <div class="c-placeholder-content-filter__lines-2"></div>
        <div class="c-placeholder-content-filter__lines-3"></div>
        <div class="c-placeholder-content-filter__lines-4"></div>
    </div>
    <div class="c-placeholder-content-filter">
        <div class="c-placeholder-content-filter__lines-1"></div>
        <div class="c-placeholder-content-filter__lines-2"></div>
        <div class="c-placeholder-content-filter__lines-3"></div>
        <div class="c-placeholder-content-filter__lines-4"></div>
    </div>
    <div class="c-placeholder-content-filter">
        <div class="c-placeholder-content-filter__lines-1"></div>
        <div class="c-placeholder-content-filter__lines-2"></div>
        <div class="c-placeholder-content-filter__lines-3"></div>
        <div class="c-placeholder-content-filter__lines-4"></div>
    </div>
</div>

```

<div class="c-sg-modifier-wrap js-modifier-wrap">
    <div>
        <button>c-placeholder-content-filter-wrap--filters-margin-left-10</button>
    </div>
</div>

*/
