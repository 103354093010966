/*------------------------------------*\
toggle switch
\*------------------------------------*/

/* box-shadow cannot be animated
box-shadow is prefereble over border as it does not affect the layout of its contents
box-shadow is animated by transitioning the opacity of a pseudo element with box-shadow
*/

.c-toggle-switch {
    $class: c-toggle-switch;
    //box-shadow cannot be animated
    //box-shadow is prefereble over border as it does not affect the layout of its contents
    //box-shadow is animated by transitioning the opacity of a pseudo element with box-shadow

    //change variables below to customize size
    $toggle-width: 64px;
    $switch-height: 38px;
    $border-width: 1px;
    // for sizing the circle toggle
    $switch-scale: 0.8;
    $left-margin: $toggle-width - $switch-height;
    $switch-color: #ccc;

    // if you choose to use border, not box shadow, use below $left-margin var
    // $left-margin: $toggle-width - $switch-height - ($border-width * 2);

    @include clearfix;
    margin-bottom: $base-spacing-unit;
    cursor: pointer;
    display: block;
    width: 100%;
    position: relative;

    &__text {
        @include font-size(14px, 1.71);
        @include font-heavy;
        order: 2;
        color: $black;
        display: block;
        border-radius: 0;
        background: none;
        padding-top: 6px;
        float: right;
        width: calc(100% - #{$toggle-width});
        padding-left: $space-s;
        margin: auto;
        margin-left: 0;
        flex: 1;
    }

    &__input {
        display: none;

        &:checked + .#{$class}__label .#{$class}__label-inner {
            // use below if you wish to use border
            // border: $border-width solid $color-1;

            // use below if box-shadow color transition is not needed
            // box-shadow: inset 0 0 0 $border-width $color-1;

            // show pseudo element (animating a box-shadow)
            &:after {
                opacity: 1;
            }

            &:before {
                // translateX has a division to the value to compensate for the switch-scale factor
                transform: scale($switch-scale) translateX(($left-margin) / $switch-scale) translateY(-50%);
                background: $color-1;
            }
        }
    }

    &__label {
        @include clearfix;
    }

    &__label-inner {
        background-color: $white;
        box-shadow: inset 0 0 0 $border-width $switch-color;
        width: $toggle-width;
        height: $switch-height;
        display: block;
        border-radius: 500px;
        position: relative;
        transition: border .3s ease-in-out;
        float: left;

        // this after element exists purely to animate the box-shadow property
        &:after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            opacity: 0;
            box-shadow: inset 0 0 0 $border-width $color-1;
            width: $toggle-width;
            height: $switch-height;
            display: block;
            border-radius: 500px;
            transition: transform .3s ease;
            transition-property: opacity;
        }

        &:before {
            position: absolute;
            top: 50%;
            left: 0;
            content: '';
            display: block;
            width: $switch-height;
            height: $switch-height;
            background: $switch-color;
            border-radius: 50%;
            transform: scale($switch-scale) translateY(-50%);
            transform-origin: 50% 0;
            transition: transform .3s ease;
            transition-property: transform, background, left;
        }
    }

    &--expanding-content-layout {
        border-bottom: 1px solid $color-grey-mid;
        padding-bottom: $space-xxs;
        margin-bottom: $space-s;

        .#{$class}__input:checked + .#{$class}__label:after {
            content: 'Hide';
        }

        .#{$class}__label{
            display: flex;
            align-items: flex-end;
            min-height: $switch-height;
        }

        .#{$class}__text {
            order: 1;
            align-self: center;
            padding: 0;
            margin: 0;
            line-height: 1.25;
        }

        .#{$class}__label-inner{
            order: 3;
        }

        .#{$class}__label:after {
            order: 2;
            line-height: 1;
            @include font-medium;
            color: $color-grey-dark;
            font-size: 13px;
            content: 'Show';
            margin: 0 7px 13px 7px;
        }
    }

    @media (min-width: $m_bp) { // this breakpont is coupled with the js
        &--hide-toggle-on-desktop {
            cursor: default;

            // cover to prevent clicking on checkbox
            &:before {
                content: '';
                background: transparent;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                z-index: 5;
            }

            .#{$class}__label-inner,
            .#{$class}__label:after {
                display: none;
            }
        }
    }
}


/*doc
---
title: toggle-switch
name: input-toggle-switch
category: Inputs
---

```html_example
<div class="c-toggle-switch">
    <input id="signup" type="checkbox" class="c-toggle-switch__input" />
    <label for="signup" class="c-toggle-switch__label">
        <span class="c-toggle-switch__text">Create standing order for this recipient</span>
        <span class="c-toggle-switch__label-inner">
        </span>
    </label>
</div>
```
<div class="c-sg-modifier-wrap js-modifier-wrap">
    <div>
        <button>c-toggle-switch--expanding-content-layout</button>
    </div>
    <div>
        <button>c-toggle-switch--hide-toggle-on-desktop</button>
    </div>
</div>

*/
