.l-content-wrap {
    $class: l-content-wrap;

    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: $viewport-height-mobile;
    overflow: hidden;

    @at-root .l-reduced-header, .l-no-navigation {
        .#{$class} {
            min-height: calc(#{$viewport-height-mobile} + #{$bottom-nav-height});
        }
    }

    @media (min-width: $m_bp) {
        min-height: $viewport-height-desktop;

        @at-root .l-reduced-header, .l-no-navigation {
            .#{$class} {
                min-height: $viewport-height-desktop;
            }
        }
    }
}

.l-main-content {
    flex: 1 0 auto;
    background-color: $white;

    &--max-height-viewport {
        display: flex;
        flex-direction: column;
        max-height: $viewport-height-mobile;
    }

    @media (min-width: $m_bp) {
        &--max-height-viewport {
            max-height: $viewport-height-desktop;
        }
    }
}

.l-content-width {
    @include content-width();
    @include max-content-width();

    &--padding-0 {
        padding: 0;
    }
}

.l-main-content-padding {
    @include main-content-padding;

    &--breadcrumb {
        @media (min-width: $s_bp) {
            padding-top: 45px;
        }
    }

    &--large {
        padding-top: $space-xxl;
        padding-bottom: $space-xxl;

        @media (min-width: $s_bp) {
            padding-top: 155px;
        }
    }
}

.l-take-over-screen {
    transition: opacity .5s ease-in-out;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    bottom: 0;
    overflow-y: scroll;
    overflow-x: hidden;
    z-index: $z-overlay;

    &--background-color-grey-xxlight {
        background-color: $color-grey-xxlight;
    }

    &--background-color-white {
        background-color: $white;
    }

    &--background-color-white-90 {
        background-color: transparentize($white, .3);
    }
}

.l-btn-pair-sidebar-layout {
    @include clearfix;

    @media (min-width: $xs_bp) {
        .c-btn:nth-child(2),
        .c-btn-outline:nth-child(2) {
            float: right;
        }
    }

    @media (min-width: $m_bp) {
        .c-btn:first-child,
        .c-btn-outline:first-child {
            float: left;
        }

        .c-btn:nth-child(2),
        .c-btn-outline:nth-child(2) {
            float: left;
        }
    }
}
