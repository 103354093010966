.c-scroll-lock-animation {
    $class: 'c-scroll-lock-animation';
    $min-height: 640px;
    $min-width: $m_bp;
    $transition-time: 200ms;

    height: 100vh;
    overflow: hidden;

    display: flex;
    flex-wrap: wrap;

        &__wrapper {
            @include clearfix;
            width: 100%;
            max-height: calc(100vh - 210px) // minus padding and button height
        }

        &__content {
            position: relative;
            width: 100%;

            &:nth-child(even) {
                margin-top: $space-xxxxl;
            }
        }

            &__body {
                margin-top: 3vh;
                margin-bottom: 3vh;
            }

                &__item {
                    position: absolute;
                    width: 100%;
                    padding-left: $space-xxl;
                    text-align: left;
                    @include animate-slide-ltr($transition-time, 25px, 'is-active');
                    background: none;

                    &::before {
                        @include list-item-title;
                        @include ffrees-icons-font;
                        content: $icon-arrow-right;
                        position: absolute;
                        top: 0;
                        left: 0;
                        z-index: $z-progress;
                        transition: all $transition-time;
                    }

                    &.is-active {
                        &::before {
                            color: $color-1;
                            transform: scale(1.25);
                        }
                    }
                }
                    &__title {
                        @include list-item-title;
                    }

                &__image-container {
                    display: block;
                    margin: 0 auto;
                }

                    &__image {
                        display: none;
                        margin: 0 auto;
                        max-width: 165px;

                        &.is-active {
                            display: block;
                        }
                    }

                &__phone {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate3d(-50%, -50%, 0) scale(0.975);
                    max-width: 200px;
                }

    @media (min-width: $xxs_bp) {
        &__content {
            &:nth-child(even) {
                margin-top: 80px;
            }
        }

            &__phone,
            &__image {
                max-width: none;
            }
    }

    // landscape phone
    @media (min-width: $xs_bp) {
        &__content {
            float: left;
            width: 50%;

            &:nth-child(even) {
                margin-top: 0;
            }
        }
            &__body {
                margin-top: $space-xxxxl;
                margin-bottom: $space-xxxxl;
            }

                &__item {
                    @include animate-slide-ltr($transition-time, 13vw, 'is-active');
                }
    }

    // desktop
    @media (min-height: $min-height) and (min-width: $min-width) {
        &__wrapper {
            display: flex;
        }

            &__content {
                float: left;
                width: 50%;
                display: flex;
                flex-direction: column;
                justify-content: center;

                &:nth-child(even) {
                    margin-top: $space-xxxxl;
                }
            }

                &__body {
                    margin: 0;
                }

                    &__item {
                        position: relative;
                        display: block;
                        padding: 0;
                        transform: none;

                        &::before {
                            top: 5vh;
                            left: 15%;
                        }

                        &::after {
                            content: '';
                            position: absolute;
                            top: 0;
                            right: 0;
                            display: block;
                            height: 100%;
                            width: calc(100vw / 2);
                            background-color: rgba($color-grey-xxlight, 0);
                            transition: background-color $transition-time;
                            z-index: $z-base;
                        }

                        &.is-active,
                        &:focus {
                            &::after {
                                background-color: $color-grey-xxlight;
                            }
                        }
                    }

                        &__item-inner {
                            position: relative;
                            padding: 5vh $space-xxxl;
                            margin-left: 15%;
                            z-index: $z-progress;
                        }
    }
}

/*doc
---
title: Scroll lock animation
name: scroll-lock-animation
category: Organisms
---

```html_example
<section class="l-section c-scroll-lock-animation js-scroll-lock-animation">
    <div class="l-content-width c-scroll-lock-animation__wrapper">
        <div class="c-scroll-lock-animation__content">
            <div class="c-scroll-lock-animation__header">
                <h2 class="u-section-title">A hassle-free<br/> current account alternative</h2>
            </div>
            <div class="c-scroll-lock-animation__body js-scroll-lock-animation-item-wrapper">
                <button class="c-scroll-lock-animation__item js-scroll-lock-animation-item">
                    <div class="c-scroll-lock-animation__item-inner">
                        <h3 class="c-scroll-lock-animation__title">Payments</h3>
                        <p class="u-margin-bottom-0">Get your salary or benefits paid-in directly to your <span class="u-u-in-copy">U</span> Account. Receive BACS and Faster Payments, and easily make payments out.</p>
                    </div>
                </button>
                <button class="c-scroll-lock-animation__item js-scroll-lock-animation-item">
                    <div class="c-scroll-lock-animation__item-inner">
                        <h3 class="c-scroll-lock-animation__title">Direct debits</h3>
                        <p class="u-margin-bottom-0">Set up direct debits for hassle-free regular payments towards your rent, mortgage, or bills.</p>
                    </div>
                </button>
                <button class="c-scroll-lock-animation__item js-scroll-lock-animation-item">
                    <div class="c-scroll-lock-animation__item-inner">
                        <h3 class="c-scroll-lock-animation__title">Deposit cash</h3>
                        <p class="u-margin-bottom-0">Put cash into your <span class="u-u-in-copy">U</span> Account using any of the 29,000+ PayPoint stores in the UK.</p>
                    </div>
                </button>
            </div>
        </div>
        <div class="c-scroll-lock-animation__content">
            <img src="img/landing-pages/phone.png" class="c-scroll-lock-animation__phone">
            <div class="c-scroll-lock-animation__image-container">
                <img src="img/landing-pages/Payments-screen.png" class="c-scroll-lock-animation__image js-scroll-lock-animation-image" alt="Payments screen" title="Payments">
                <img src="img/landing-pages/DD-screen.png" class="c-scroll-lock-animation__image js-scroll-lock-animation-image" alt="Direct debits screen" title="Direct debits">
                <img src="img/landing-pages/paypoint-screen.png" class="c-scroll-lock-animation__image js-scroll-lock-animation-image" alt="Paypoint screen" title="Deposit cash">
            </div>
        </div>
    </div>
</section>
```
*/
