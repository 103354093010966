
.c-text-link {
    $text-link-transition-speed: .2s;
    $class: c-text-link;

    transition: color $text-link-transition-speed ease-in-out, box-shadow $text-link-transition-speed ease-in-out;
    display: inline;
    text-decoration: underline;
    text-decoration-skip: ink;

    &__icon {
        &::before,
        &::after {
            text-decoration: underline;
        }

        &--separate {
            &::before {
                text-decoration: none;
                margin-right: $space-xxs;
            }
            &::after {
                text-decoration: none;
                margin-left: $space-xxs;
            }
        }
    }

    &:hover,
    &:focus,
    &:active,
    &.is-active {
        color: $color-1-dark;
    }

    &--blob {
        background: $color-1-mid;
        text-decoration: none;
        @include font-medium;
        @include text-wrap-border;
        border-radius: $space-l;
        margin: 0 8px;
        box-decoration-break: clone;
        -webkit-box-decoration-break: clone;
        color: $black;

        &:hover,
        &:focus,
        &:active,
        &.is-active {
            @include text-wrap-border($color-1-mid, $color-1);
            color: $black;
        }
    }

    &--black {
        color: $black;

        &:hover,
        &:focus,
        &:active,
        &.is-active,
        &.is-selected-desktop {
            color: $color-1;
        }
    }
}

/*doc
---
title: Text Link
name: text-link
category: Components
---

```html_example

Nulla porttitor <a class="c-text-link">Text Link</a> accumsan tincidunt.
<br/>
Magna exercitation et elit <a class="c-text-link">Text Link <span class="c-text-link__icon u-after-icon-pencil" aria-hidden="true"></span></a> consequat nulla cupidatat ad sunt do.
```
<div class="c-sg-modifier-wrap js-modifier-wrap">
    <div>
        <button>is-active</button>
    </div>
    <div>
        <button>c-text-link--blob</button>
        <button>c-text-link--black</button>
    </div>
    <div>
        <button data-target="c-text-link__icon">c-text-link__icon--separate</button>
    </div>
</div>

*/