.c-social-share {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;

    &__card {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: $space-xs $space-s;
        background: $color-1-mid;
        border-radius: $space-s;
        @include outer-shadow;
    }

        &__text {
            padding: 0;
            border: 0;
            background: none;
            max-width: none;
            outline: none;
            flex-grow: 1;
        }

        &__link {
            display: none;
        }

        &__button {
            background: none;
            padding: 0;
            @include font-size(24px, 1);

            &:focus {
                outline: 1px dotted $black;
            }
        }

        &__alert {
            display: flex;
            align-items: center;
            justify-content: space-between;
            @include font-heavy;
            line-height: 30px;
        }

    &__footer {
        display: none;

        [class*="u-after-icon"] {
            font-size: $space-l;
        }
    }

    [class*="u-after-icon"] {
        color: $black;
    }

    @media (min-width: $m_bp) {
        &__card {
            margin-bottom: $space-s;
            padding: $space-s $space-l;
        }

            &__text {
                @include font-size(18px, 1);
            }

            &__link {
                display: inline-block;
            }

            &__button {
                display: none;
            }

        &__footer {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 40%;
            margin-right: $space-s;
        }

            &__footer-heading {
                margin: 0;
            }
    }
}

/*doc
---
title: social share
name: social-share
category: Components
---
This is a Vue component. It can be included on pages using <code>\<social-share url="https://uaccount.uk/apply/W3W3W3"\>\</social-share\></code>.
The input element must be used to allow copying the link on older browsers that don't support the clipboard API.

```html_example_table
<div class="c-social-share">
    <div class="c-social-share__card">
        <label for="urlInput" class="u-visually-hidden">https://uaccount.uk/apply/W3W3W3</label>
        <input id="urlInput" readonly="readonly" tabindex="-1" class="c-social-share__text" value="https://uaccount.uk/apply/W3W3W3">
        <a href="https://uaccount.uk/apply/W3W3W3" class="c-btn-tertiary c-btn-tertiary--margin-0 c-social-share__link">Copy link</a>
        <a href="https://uaccount.uk/apply/W3W3W3" class="c-social-share__button">
            <span class="u-after-icon-copy">
                <span class="u-visually-hidden">Copy</span>
            </span>
        </a>
    </div>
    <div class="c-social-share__footer">
        <p class="c-social-share__footer-heading">Share on:</p>
        <a rel="nofollow" href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fuaccount.uk%2Fapply%2FW3W3W3" class="u-after-icon-facebook u-color-facebook">
            <span class="u-visually-hidden">Facebook</span>
        </a>
        <a rel="nofollow" href="https://twitter.com/intent/tweet?url=https%3A%2F%2Fuaccount.uk%2Fapply%2FW3W3W3&amp;text=text" class="u-after-icon-twitter u-color-twitter">
            <span class="u-visually-hidden">Twitter</span>
        </a>
        <a href="mailto:example@example.com" class="u-after-icon-mail">
            <span class="u-visually-hidden">email</span>
        </a>
    </div>
</div>
```
*/
