.c-hr {
    width: 25%;
    margin: $space-l auto;
    height: 1px;
    background-color: $color-grey-dark;

    &--margin-top-0 {
        margin-top: 0;
    }

    &--margin-bottom-0 {
        margin-bottom: 0;
    }

    &--margin-bottom-xs {
        margin-bottom: $space-xs;
    }

    &--margin-bottom-s {
        margin-bottom: $space-s;
    }

    &--margin-top-bottom-s {
        margin-top: $space-s;
        margin-bottom: $space-s;
    }

    &--full-width {
    	width: 100%;
    }

    &--width-80-percent {
    	width: 80%;
    }

    &--width-100-px {
        width: 100px;
    }

    &--color-grey-9 {
        background-color: $color-grey-9;
    }
}
