/*------------------------------------*\
    thumbnail
\*------------------------------------*/

.c-thumbnail {
    $class: c-thumbnail;

    display: flex;
    flex-direction: column;
    margin: 0 0 $space-xs;
    overflow: hidden;
    padding: 0;
    width: 210px;

    &__file-name {
        @include font-medium;
        display: block;
        overflow: hidden;
        padding: 0;
        margin-bottom: $space-xxs;
    }

    &__image-thumbnail {
        position: relative;
        width: 100%;
        height: 140px;
        border: solid 1px $color-grey-mid;
        margin-top: auto;
        display: inline-block;
        vertical-align: bottom;
    }

        &__image {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            max-width: 100%;
            max-height: 140px;
            width: auto;
            height: auto;
            margin: 0;
            padding: 1px;
        }

    @media (min-width: $m_bp) {
        margin-left: 0;
        margin-right: $space-xs;
    }
}

.c-thumbnail-wrap {
    $gutter: 30px;

    margin: auto;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;

    .c-thumbnail {
        flex: 0 1 auto;
        width: calc(100% - #{$gutter});
    }

    @media (min-width: $xs-bp) {
        .c-thumbnail {
            width: calc(50% - #{$gutter / 2});
        }
    }

    @media (min-width: $s_bp) {
        .c-thumbnail {
            width: calc(33.3% - #{$gutter * 0.66});
        }
    }

    @media (min-width: $m_bp) {
        .c-thumbnail {
            width: calc(100% - #{$gutter * 2});
        }
    }

    @media (min-width: $l_bp) {
        .c-thumbnail {
            width: calc(50% - #{$gutter / 2});
        }
    }

    @media (min-width: $xl_bp) {
        .c-thumbnail {
            width: calc(33.3% - #{$gutter * 0.66});
        }
    }
}

/*doc
---
title: thumbnail
name: thumbnail
category: Components
---

```html_example
<div class="c-thumbnail-wrap">

    <div class="c-thumbnail">
        <span class="c-thumbnail__file-name">
            u-logo-orange.png
        </span>
        <div class="c-thumbnail__image-thumbnail">
            <img class="c-thumbnail__image" src="img/u-logo-orange.png">
        </div>
    </div>

    <div class="c-thumbnail">
        <span class="c-thumbnail__file-name">
            u-logo-orange.png
        </span>
        <div class="c-thumbnail__image-thumbnail">
            <img class="c-thumbnail__image" src="img/u-logo-orange.png">
        </div>
    </div>

    <div class="c-thumbnail">
        <span class="c-thumbnail__file-name">
            u-logo-orange.png
        </span>
        <div class="c-thumbnail__image-thumbnail">
            <img class="c-thumbnail__image" src="img/u-logo-orange.png">
        </div>
    </div>

    <div class="c-thumbnail">
        <span class="c-thumbnail__file-name">
            u-logo-orange.png
        </span>
        <div class="c-thumbnail__image-thumbnail">
            <img class="c-thumbnail__image" src="img/u-logo-orange.png">
        </div>
    </div>

</div>

```
*/
