/*------------------------------------*\
    drag file upload
\*------------------------------------*/

.c-drag-file-upload {
    $class: c-drag-file-upload;

    color: $color-grey-1;
    background-color: $color-grey-9;
    transition: all .2s ease-in-out;
    padding: $space-xl;
    text-align: center;
    outline: 2px dashed $color-grey-1;
    outline-offset: -$space-xs;

    &.is-dragover {
        color: $color-1;
        background-color: $white;
        outline: 2px dashed $color-1;
        outline-offset: -15px;
    }

    &__icon {
        font-size: 300%;
        line-height: 1;
        padding-bottom: $space-s;
    }

    &__drag-text {
        @include font-medium;
        font-size: 16px;
    }

    &__drag-text-instruction {
        padding: 0 0 $space-xxs;
        margin: 0;
        text-align: center;
    }

    &__file-input {
        display: none;
    }

    @media (min-width: $m_bp) {
        padding: $space-xxl;

        &.is-dragover {
            outline-offset: -$space-m;
        }
    }
}

/*doc
---
title: drag file upload
name: drag-file-upload
category: Components
---

```html_example
<div class="c-drag-file-upload js-drag-file-upload">

    <div class="c-drag-file-upload__icon u-before-icon-upload"></div>

    <div class="c-drag-file-upload__drag-text js-drag-text">
        Drag here to attach
        <p class="c-drag-file-upload__drag-text-instruction">or</p>
    </div>

    <label class="c-btn c-btn--black c-btn--mobile-full-width c-btn--margin-bottom-0 u-before-icon-attach" for="files">
        Choose file
    </label>

    <input id="files" class="c-drag-file-upload__file-input js-file-input" type="file" name="files[]" multiple>

    <input type="hidden" class="js-file-input-hidden" name="filesHidden" type="text" value="3487">

</div>
```
<div class="c-sg-modifier-wrap js-modifier-wrap">
    <div>
        <button>is-dragover</button>
    </div>
</div>
*/
