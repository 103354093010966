.c-numbered-list {
    $class: c-numbered-list;
    counter-reset: item;

    &__item {
        position: relative;
        display: block;

        &:before {
            content: counters(item, '.') '.';
            counter-increment: item;
            display: inline-block;
            text-align: right;
            @include font-heavy();
            font-style: italic;
            position: absolute;
            right: calc(100% + #{$space-xxs});
        }
    }

    .#{$class} {
        margin-top: $space-s;
    }

    &--letters {
        .#{$class}__item {
            &:before {
                content: '('counter(item, lower-alpha)')';
            }
        }
    }

    @media (min-width: $m_bp) {
        padding-left: $space-xxxl;
    }
}

/*doc
---
title: Numbered List
name: numbered-list
category: Components
---
```html_example
<ol class="c-numbered-list">
    <li class="c-numbered-list__item">Make sure all your information and loan details are correct</li>
    <li class="c-numbered-list__item">Confirm you're happy for us to share your application details with the lender before continuing</li>
    <li class="c-numbered-list__item">Wait a few moments for My Finance Club to perform a credit check. If they accept your application, you'll continue on their website</li>
    <li class="c-numbered-list__item">Provide your <span class="u-u-in-copy">U</span> card details to My Finance Club to repay the loan by CPA</li>
    <li class="c-numbered-list__item">Take time to thoroughly read the loan agreement between you and the lender, as well as their terms and conditions, before signing the agreement</li>
</ol>
```
<div class="c-sg-modifier-wrap js-modifier-wrap">
    <div>
        <button>c-numbered-list--letters</button>
    </div>
</div>
*/
