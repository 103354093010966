.c-offset-box {
    $desktop-spacing: 100px;

    position: relative;
    padding: $space-m;

    &::after {
        content: "";
        position: absolute;
        top: 25%;
        left: 50%;
        height: 75%;
        width: 100%;
        transform: translateX(-50%);
        background: $color-grey-xxlight;
    }

    @media (min-width: $m_bp) {
        margin-top: $desktop-spacing;
        margin-bottom: $desktop-spacing;
        padding: 0;

        &::after {
            top: -$desktop-spacing;
            right: -25%;
            height: calc(100% + (#{$desktop-spacing} * 2));
            width: 75%;
            transform: none;
        }
    }
}

/*doc
---
title: Offset Box
name: offset-box
category: Organisms
---

```html_example
<div class="l-row u-display-flex u-justify-content-center">
    <div class="l-col-m-66">
        <div class="c-offset-box">
            <div class="c-card c-card--padding c-card--extra-raised c-card--white-bg">
                <p>This card is wrapped by <code>c-offset-box</code></p>
            </div>
        </div>
    </div>
</div>
```
*/