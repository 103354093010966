.c-bottom-nav {
    $class: c-bottom-nav;

    margin: 0;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: $bottom-nav-height;
    z-index: $z-header - 1;
    box-shadow: 0 0 5px transparentize($black, .75);

    &__list {
        list-style: none;
        padding: 0;
        margin: 0;
        width: 100%;
        height: 100%;
        display: flex;
    }

        &__list-item {
            flex-basis: 0;
            flex-grow: 1;
            height: 100%;
            border: 1px solid $color-grey-mid;
            border-top-width: 2px;
            border-bottom-width: 0;

            &.is-selected {
                .#{$class}__link {
                    background-color: $color-1-light;
                    color: $color-1;

                    &:before,
                    &:after {
                        background-color: $color-1;
                    }

                    &:after {
                        transform: translateY(-4px);
                    }
                }

                .#{$class}__sub-menu-list {
                    transform: translateY(-100%);
                    visibility: visible;
                    transition-delay: 0s, 0s;
                }
            }
        }

            &__link {
                @include bottom-nav-link-text;
                position: relative;
                display: flex;
                align-items: center;
                flex-direction: column;
                justify-content: center;
                background-color: $white;
                color: $color-1;
                height: 100%;
                transition: color $bottom-nav-transition ease-in-out, background-color $bottom-nav-transition ease-in-out;

                &--has-sub-menu {
                    $padding-left-right: 3px;

                    &:before,
                    &:after {
                        content: '';
                        position: absolute;
                        left: $padding-left-right;
                        right: $padding-left-right;
                        display: block;
                        height: 2px;
                        background-color: $color-1;
                    }

                    &:before {
                        top: 2px;
                        transition: background-color $bottom-nav-transition ease-in-out;
                    }

                    &:after {
                        top: 6px;
                        transition: background-color $bottom-nav-transition ease-in-out, transform $bottom-nav-transition ease-in-out;
                    }
                }

                &.is-current {
                    background-color: $color-1;
                    color: $white;

                    &:before,
                    &:after {
                        background-color: $white;
                    }
                }
            }

                &__link-icon {
                    &:before {
                        @include font-size(20px, 26px);
                        display: block;
                        @include ffrees-icons-font;
                    }

                    &--u-logo {
                        &:before {
                            @include u-font;
                            content: 'U';
                        }
                    }

                    &--pounds-sign {
                        &:before {
                            @include font-extra-bold;
                            content: '£';
                        }
                    }
                }

                &__notification-holder {
                    position: absolute;
                    right: 1px;
                    top: -10px;
                    z-index: 2;
                }

            &__sub-menu-list {
                padding: 0;
                margin: 0;
                list-style: none;
                position: fixed;
                top: calc(100% - #{$bottom-nav-height});
                left: 0;
                height: calc(100% - #{$header-height-mobile} - #{$bottom-nav-height});
                transform: translateY(0);
                width: 100%;
                background-color: $color-grey-xlight;
                z-index: -1;
                overflow-y: auto;
                visibility: hidden;
                transition: transform $bottom-nav-transition ease-in-out, visibility 0s linear $bottom-nav-transition;
            }

                &__sub-menu-list-item {
                    display: block;
                    margin: 0;
                    border-bottom: 2px solid $color-grey-mid;
                    position: relative;

                    &--footer-item {
                        border-bottom: 0px none transparent;
                        padding: $space-l $space-xxl;
                        text-align: center;
                    }

                    &.is-hidden {
                        display: none;
                    }
                }

                    &__sub-menu-notification-holder {
                        position: absolute;
                        left: calc(#{$space-xxl}/2 - 3px);
                        top: 50%;
                        transform: translateY(-50%);
                    }

                    &__sub-menu-link {
                        @include sub-nav-link-text;
                        display: block;
                        padding: $space-l $space-xxl;
                        color: $color-1;
                        transition: color $bottom-nav-transition ease-in-out, background-color $bottom-nav-transition ease-in-out;

                        &:focus {
                            color: $color-1;
                            background-color: $color-1-mid;
                        }

                        &.is-current {
                            color: $white;
                            background-color: $color-1;

                            &:focus {
                                color: $white;
                                background-color: $color-1;
                            }
                        }
                    }

    @at-root.u-blur-content {
        .#{$class} {
            transition: filter .25s ease-in-out;
            filter: blur(20px);
        }
    }

    @at-root .l-reduced-header, .l-no-navigation {
        .#{$class} {
            display: none;
        }
    }

    @media (min-width: $m_bp) {
        display: none;
    }
}

.styleguide {
    .c-bottom-nav {
        position: absolute;

        &__sub-menu-list {
            position: absolute;
            height: 667px - $header-height-mobile;
        }
    }
}

/*doc
---
title: bottom nav
name: bottom-nav
category: Global
---

Note - the second <code>is-current</code> state modifier affects the nav items in the sub menu
<br/>
(Also - this component is only visible on mobile)

```html_example
<nav class="c-bottom-nav js-bottom-nav" data-parent-classes="u-sg-height-667 u-sg-overflow-hidden">
    <ul class="c-bottom-nav__list">
        <li class="c-bottom-nav__list-item">
            <a class="c-bottom-nav__link" href="/">
                <span class="c-bottom-nav__link-icon c-bottom-nav__link-icon--u-logo"></span>
                Home
            </a>
        </li>
        <li class="c-bottom-nav__list-item">
            <a class="c-bottom-nav__link" href="/pricing">
                <span class="c-bottom-nav__link-icon c-bottom-nav__link-icon--pounds-sign"></span>
                Pricing
            </a>
        </li>
        <li class="c-bottom-nav__list-item">
            <a class="c-bottom-nav__link" href="https://medium.com/u-account">
                <span class="c-bottom-nav__link-icon u-before-icon-users-group"></span>
                Blog
            </a>
        </li>
        <li class="c-bottom-nav__list-item js-bottom-nav-list-item">
            <a class="c-bottom-nav__link c-bottom-nav__link--has-sub-menu js-bottom-nav-link">
                <span class="c-bottom-nav__link-icon u-before-icon-info-fill"></span>
                Help
            </a>
            <ul class="c-bottom-nav__sub-menu-list js-bottom-nav-sub-menu">
                <li class="c-bottom-nav__sub-menu-list-item">
                    <a class="c-bottom-nav__sub-menu-link" href="/help">Help</a>
                </li>
                <li class="c-bottom-nav__sub-menu-list-item c-bottom-nav__sub-menu-list-item--hidden js-notification-link">
                    <div class="c-bottom-nav__sub-menu-notification-holder">
                        <div class="c-notification c-notification--dot"></div>
                    </div>
                    <a class="c-bottom-nav__sub-menu-link" href="/help?current-panel=contact"><span class="js-notifications-count">2</span> Unread Messages</a>
                </li>
                <li class="c-bottom-nav__sub-menu-list-item">
                    <a class="c-bottom-nav__sub-menu-link" href="/contact-us">Contact</a>
                </li>
                <li class="c-bottom-nav__sub-menu-list-item">
                    <a class="c-bottom-nav__sub-menu-link" href="/service-status">Service Status</a>
                </li>
            </ul>
        </li>
        <li class="c-bottom-nav__list-item js-bottom-nav-list-item">
            <a class="c-bottom-nav__link c-bottom-nav__link--has-sub-menu js-bottom-nav-link">
                <span class="c-bottom-nav__link-icon u-before-icon-plus"></span>
                More
            </a>
            <ul class="c-bottom-nav__sub-menu-list">
                <li class="c-bottom-nav__sub-menu-list-item">
                    <a class="c-bottom-nav__sub-menu-link">About</a>
                </li>
                <li class="c-bottom-nav__sub-menu-list-item">
                    <a class="c-bottom-nav__sub-menu-link">Careers</a>
                </li>
                <li class="c-bottom-nav__sub-menu-list-item">
                    <a class="c-bottom-nav__sub-menu-link">Terms &amp; Policies</a>
                </li>
                <li class="c-bottom-nav__sub-menu-list-item">
                    <a class="c-bottom-nav__sub-menu-link">Press</a>
                </li>
            </ul>
        </li>
    </ul>
</nav>

```
<div class="c-sg-modifier-wrap js-modifier-wrap">
    <div>
        <button data-target="c-bottom-nav__link">is-current</button>
    </div>
    <div>
        <button data-target="c-bottom-nav__list-item">is-selected</button>
    </div>
    <div>
        <button data-target="c-bottom-nav__link">c-bottom-nav__link--has-sub-menu</button>
    </div>
    <div>
        <button data-target="c-bottom-nav__link-icon">c-bottom-nav__link-icon--u-logo</button>
        <button data-target="c-bottom-nav__link-icon">c-bottom-nav__link-icon--pounds-sign</button>
    </div>
    <div>
        <button data-target="c-bottom-nav__sub-menu-link">is-current</button>
    </div>
    <div>
        <button data-target="c-bottom-nav__sub-menu-list-item">c-bottom-nav__sub-menu-list-item--footer-item</button>
    </div>
    <div>
        <button data-target="c-bottom-nav__sub-menu-list-item">is-hidden</button>
    </div>
</div>
*/

/*doc
---
title: Logged In Bottom Nav
name: bottom-nav-logged-in
category: Global
---

```html_example
<nav class="c-bottom-nav js-bottom-nav" data-parent-classes="u-sg-height-667 u-sg-overflow-hidden">
    <ul class="c-bottom-nav__list">

        <li class="c-bottom-nav__list-item js-bottom-nav-list-item">
            <a class="c-bottom-nav__link c-bottom-nav__link--has-sub-menu js-bottom-nav-link {% if in_array(uri, ['/dashboard', '/dashboard/extra-accounts', '/dashboard/money-manager', '/dashboard/statements', '/dashboard/referral', '/points']) %}is-current{% endif %}">
                <span class="c-bottom-nav__link-icon u-before-icon-chart-area"></span>
                Accounts
            </a>
            <ul class="c-bottom-nav__sub-menu-list js-bottom-nav-sub-menu">
                <li class="c-bottom-nav__sub-menu-list-item">
                    <a class="c-bottom-nav__sub-menu-link" href="/dashboard">Overview</a>
                </li>
                <li class="c-bottom-nav__sub-menu-list-item">
                    <a class="c-bottom-nav__sub-menu-link" href="/dashboard/extra-accounts">Extra Accounts</a>
                </li>
                <li class="c-bottom-nav__sub-menu-list-item">
                    <a class="c-bottom-nav__sub-menu-link" href="/dashboard/money-manager">Money Manager</a>
                </li>
                <li class="c-bottom-nav__sub-menu-list-item">
                    <a class="c-bottom-nav__sub-menu-link" href="/dashboard/statements">Statements</a>
                </li>
                <li class="c-bottom-nav__sub-menu-list-item">
                    <a class="c-bottom-nav__sub-menu-link" href="/points">Points</a>
                </li>
                <li class="c-bottom-nav__sub-menu-list-item">
                    <a class="c-bottom-nav__sub-menu-link" href="/dashboard/referral">Refer a friend</a>
                </li>
            </ul>
        </li>

        <li class="c-bottom-nav__list-item">
            <a class="c-bottom-nav__link js-bottom-nav-link" href="/dashboard/payments">
                <span class="c-bottom-nav__link-icon u-before-icon-send"></span>
                Pay
            </a>
        </li>

        <li class="c-bottom-nav__list-item js-bottom-nav-list-item">
            <a class="c-bottom-nav__link c-bottom-nav__link--has-sub-menu js-bottom-nav-link {% if in_array(uri, ['/dashboard/my-cards', '/dashboard/profile']) %}is-current{% endif %}">
                <span class="c-bottom-nav__link-icon u-before-icon-sliders"></span>
                Settings
            </a>
            <ul class="c-bottom-nav__sub-menu-list js-bottom-nav-sub-menu">
                <li class="c-bottom-nav__sub-menu-list-item">
                    <a class="c-bottom-nav__sub-menu-link" href="/dashboard/my-cards">Card</a>
                </li>
                <li class="c-bottom-nav__sub-menu-list-item">
                    <a class="c-bottom-nav__sub-menu-link" href="/dashboard/profile">Profile</a>
                </li>
            </ul>
        </li>

        <li class="c-bottom-nav__list-item js-bottom-nav-list-item">
            <a class="c-bottom-nav__link c-bottom-nav__link--has-sub-menu js-bottom-nav-link {% if in_array(uri, ['/help', '/service-status', '/policies']) %}is-current{% endif %}">
                <span class="c-bottom-nav__link-icon u-before-icon-info-fill"></span>
                Help
                <div class="c-bottom-nav__notification-holder">
                    <div class="c-notification js-notification">
                        <div class="c-notification__number js-notification-count"></div>
                    </div>
                </div>
            </a>
            <ul class="c-bottom-nav__sub-menu-list js-bottom-nav-sub-menu">
                <li class="c-bottom-nav__sub-menu-list-item">
                    <a class="c-bottom-nav__sub-menu-link" href="/help">Help &amp; Support</a>
                </li>
                {# Extra menu item for unread notifications #}
                <li class="c-bottom-nav__sub-menu-list-item is-hidden js-notification-link">
                    <div class="c-bottom-nav__sub-menu-notification-holder">
                        <div class="c-notification c-notification--dot is-shown"></div>
                    </div>
                    <a class="c-bottom-nav__sub-menu-link" href="/help?current-panel=contact"><span class="js-notification-count"></span> Unread Message<span class="js-notification-variable-text"></span></a>
                </li>
                <li class="c-bottom-nav__sub-menu-list-item">
                    <a class="c-bottom-nav__sub-menu-link" href="/service-status">Service Status</a>
                </li>
                <li class="c-bottom-nav__sub-menu-list-item">
                    <a class="c-bottom-nav__sub-menu-link" href="/policies">Policies &amp; Terms</a>
                </li>
            </ul>
        </li>

        <li class="c-bottom-nav__list-item">
            <a class="c-bottom-nav__link js-bottom-nav-link tt-nav-credit" href="/dashboard/credit">
                <span class="c-bottom-nav__link-icon u-before-icon-u-logo-simplified"></span>
                U Credit
            </a>
            <div class="c-bottom-nav__notification-holder">
                <div class="c-notification c-notification--variable-width c-notification--color-1 c-notification--thin-border is-shown">
                    <div class="c-notification__text">New</div>
                </div>
            </div>
        </li>
    </ul>
</nav>
```
<div class="c-sg-modifier-wrap js-modifier-wrap">
    <div>
        <button data-target="c-bottom-nav__link">is-current</button>
    </div>
    <div>
        <button data-target="c-bottom-nav__list-item">is-selected</button>
    </div>
    <div>
        <button data-target="c-bottom-nav__link">c-bottom-nav__link--has-sub-menu</button>
    </div>
    <div>
        <button data-target="c-bottom-nav__link-icon">c-bottom-nav__link-icon--u-logo</button>
        <button data-target="c-bottom-nav__link-icon">c-bottom-nav__link-icon--pounds-sign</button>
    </div>
    <div>
        <button data-target="c-bottom-nav__sub-menu-link">is-current</button>
    </div>
    <div>
        <button data-target="c-bottom-nav__sub-menu-list-item">c-bottom-nav__sub-menu-list-item--footer-item</button>
    </div>
    <div>
        <button data-target="c-bottom-nav__sub-menu-list-item">is-hidden</button>
    </div>
</div>
*/