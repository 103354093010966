.c-bullet-list {
    $class: 'c-bullet-list';

    padding: 0;
    margin: 0;
    list-style: none;

    &__item {
        position: relative;
        margin-bottom: $space-xs;
        padding-left: $space-m;

        &::before {
            @include ffrees-icons-font;
            content: $icon-arrow-right;
            position: absolute;
            top: 0;
            left: 0;
            color: $color-1;
        }
    }
        &__title {
            @include list-item-title;
        }

        &__info {
            display: block;
            @include footnote-text;
            color: $color-grey-dark;
        }

    &--large {
        .#{$class}__item {
            &::before {
                @include list-item-title;
                @include ffrees-icons-font;
            }
        }
    }

    &--extra-spacing {
        .#{$class}__item {
            margin-top: $space-m;
            margin-bottom: $space-m;
            padding-left: $space-xl;
        }
    }

    @media (min-width: $m_bp) {
        &__item {
            padding-left: $space-xxl;
        }

        &--columns {
            display: flex;
            flex-wrap: wrap;
            padding-left: $space-xl;
            padding-right: $space-xl;

            .#{$class}__item {
                width: 30%;

                &:nth-child(3n+2),
                &:nth-child(3n+3) {
                    margin-left: 5%;
                }
            }
        }

        &--extra-spacing {
            .#{$class}__item {
                margin-top: $space-xxxl;
                margin-bottom: $space-xxxl;
                padding-left: $space-xxxl;
            }
        }

        &--indented {
            margin-left: $space-xxxxl;
        }
    }
}

/*doc
---
title: Bullet List
name: bullet-list
category: Components
---

```html_example
<ul class="c-bullet-list">
    <li class="c-bullet-list__item">
        <h3 class="c-bullet-list__title">Contactless Card</h3>
        You'll receive a free contactless card as standard so that you can tap and go with contactless payments.
    </li>
    <li class="c-bullet-list__item">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas maximus convallis ipsum, eget consectetur diam commodo et.
        <span class="c-bullet-list__info">Supplementary text lorem ipsum dolor</span>
    </li>
    <li class="c-bullet-list__item">
        Save £10 or as much as you can into an extra account, to create a small buffer.
    </li>
    <li class="c-bullet-list__item">
        Have at least one monthly direct or STO, which does not bounce.
    </li>
    <li class="c-bullet-list__item u-u-in-copy">
        Use your U Account as your primary day to day account
    </li>
</ul>
```

<div class="c-sg-modifier-wrap js-modifier-wrap">
    <div>
        <button>c-bullet-list--columns</button>
    </div>
    <div>
        <button>c-bullet-list--large</button>
    </div>
    <div>
        <button>c-bullet-list--extra-spacing</button>
    </div>
    <div>
        <button>c-bullet-list--indented</button>
    </div>
</div>
*/
