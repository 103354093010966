.fourCol {
  float: left;
  display: block;
  margin-right: 2.35765%;
  width: 100%; }
.fourCol:last-child {
  margin-right: 0; }
@media screen and (min-width: 61.25em) {
  .fourCol {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 31.76157%; }
  .fourCol:last-child {
    margin-right: 0; } }

section.greySection {
  background-color: #f0f1f3;
  border-bottom: 1px solid #a7a9ac; }
section.greySection h1 {
  margin-bottom: 0.5em; }
@media screen and (min-width: 45em) {
  section.greySection h1 {
    margin-bottom: 0; } }

.loggedIn section .topMargin {
  max-width: 90em;
  margin-left: auto;
  margin-right: auto; }
.loggedIn section .topMargin:after {
  content: "";
  display: table;
  clear: both; }

.servicestatus .fourCol {
  margin-top: 1.5em;
  margin-bottom: 1.5em; }
.servicestatus .fourCol th,
.servicestatus .fourCol td {
  padding: 15px 0; }
.servicestatus .fourCol tr td:nth-child(1n),
.servicestatus .fourCol tr th:nth-child(1n) {
  width: 80%; }
.servicestatus .fourCol tr td:nth-child(2n),
.servicestatus .fourCol tr th:nth-child(2n) {
  width: 10%; }

.globalContainer {
  transition:(all .2s ease-in-out);
  background-color: $white;
  overflow: hidden;
  position: relative;
  left: 0;

  section {
    background-color: $white;
  }
}

.twoColumns {
  float: left;
  display: block;
  margin-right: 2.35765%;
  width: 100%; }
.twoColumns:last-child {
  margin-right: 0; }
@media screen and (min-width: 45em) {
  .twoColumns {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 48.82117%; }
  .twoColumns:last-child {
    margin-right: 0; } }
.twoColumns:nth-child(2n) {
  margin-right: 0; }
.twoColumns.shift {
  margin-left: 0; }
@media screen and (min-width: 45em) {
  .twoColumns.shift {
    margin-left: 25.58941%; } }

h1.un {
  font-size: 3em;
  line-height: 0.75;
  letter-spacing: -3px; }
@media screen and (min-width: 45em) {
  h1.un {
    text-align: left;
    font-size: 3.5em; } }
@media screen and (min-width: 61.25em) {
  h1.un {
    font-size: 3.5em; }
  h1.un.vLong {
    font-size: 2.8em; } }
@media screen and (min-width: 80em) {
  h1.un {
    font-size: 3.8em; }
  h1.un.long {
    font-size: 3.6em; }
  h1.un.vLong {
    font-size: 3.4em; } }
@media screen and (min-width: 45em) {
  h1.un.noUn {
    margin-left: 1.3em; } }
@media screen and (min-width: 61.25em) {
  h1.un.noUn {
    margin-left: 1.2em; } }
@media screen and (min-width: 80em) {
  h1.un.noUn {
    margin-left: 1.5em; } }

h6 {
  font-size: 0.938em;
  letter-spacing: 0;
  margin-bottom: 1em;
  font-weight: 600; }

section {
  width: 100%;
  min-width: 100%; }
section:after {
  content: "";
  display: table;
  clear: both; }
@media screen and (min-width: 45em) {
  section:first-of-type {
    margin-top: 0; }}

section > div {
  max-width: 90em;
  margin-left: auto;
  margin-right: auto; }
section > div:after {
  content: "";
  display: table;
  clear: both; }
section > div:after {
  content: "";
  display: table;
  clear: both; }
section > div > hr {
  margin-top: 0; }

section article {
  float: left;
  display: block;
  margin-right: 2.35765%;
  width: 100%; }
section article:last-child {
  margin-right: 0; }
section article div {
  padding: 0; }

table {
  border-collapse: collapse;
  margin: 0.65em 0;
  table-layout: fixed;
  width: 100%; }

table {
  empty-cells: show;
  margin: 0; }

table tr {
  border-top: 1px solid #d1d3d4;
  border-bottom: 1px solid #d1d3d4;}

tr, td, th {
  vertical-align: middle;
}

th {
  border-bottom: 1px solid #a9adaf;
  font-weight: bold;
  padding: 0.65em 0;
  text-align: left; }

table th {
  color: #f2663b;
  font-weight: 800;
  font-size: 100%;
  font-family: "futura-pt", sans-serif;
  padding-top: 0; }
@media screen and (min-width: 45em) {
  table th {
    font-size: 1.3em; } }

table td,
table th {
  border: 0; }
table td:nth-child(2),
table th:nth-child(2) {
  text-align: center; }
@media screen and (min-width: 45em) {
  table td:nth-child(2),
  table th:nth-child(2) {
    text-align: right; } }

[class^="icon"]:before, [class*=" icon"]:before, .okImg,
.offlineImg,
.warningImg {
  font-family: 'ffreesicons';
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */ }

.okImg,
.offlineImg,
.warningImg {
  font-size: 1.2em; }

.okImg:before {
  color: #6eb52b;
  content: ""; }

.offlineImg:before {
  color: #ee114a;
  content: ""; }

.warningImg:before {
  color: #ffb20a;
  content: ""; }

.serviceStatusBox {
  width: 90%;
  padding-top: 20px;
  padding-right: 20px;
  text-align: left; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "futura-pt", sans-serif;
  line-height: 1.3;
  margin: 0;
  font-weight: 800;
  letter-spacing: -0.5px;
  margin-bottom: 0.6em;
  text-align: center; }

@media screen and (min-width: 45em) {
  h1, h2, h3, h4, h5, h6 {
    text-align: inherit;
    margin-bottom: 0.8em;
  }
}

h6 {
  font-size: 0.938em;
  letter-spacing: 0;
  margin-bottom: 1em;
  font-weight: 600; }
