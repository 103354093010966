.c-image-bg {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    &--weighted-top {
        background-position: center 25%;
    }
}
/*doc
---
title: Image Background
name: image-bg
category: Components
---

```html_example
<div class="c-image-bg u-padding-xxxxl" style="background-image: url('img/landing-pages/section3-copy.png')">
    <h3>Background image</h3>
</div>

```
<div class="c-sg-modifier-wrap js-modifier-wrap">
    <div>
        <button>c-image-bg--weighted-top</button>
    </div>
</div>
*/