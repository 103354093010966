@mixin rangeSliderTrack() {
    width: 100%;
    height: $space-xs;
    cursor: pointer;
    transition: all .2s ease;
}

@mixin rangeSliderThumb() {
    position: relative;
    border: 0 solid rgba(255, 255, 255, 0.5);
    height: $space-m;
    width: $space-m;
    border-radius: 100%;
    background: $color-1;
    cursor: pointer;
    z-index: 1;
    transition: background-color 250ms;
}

.c-range-slider {
    $class: c-range-slider;

    box-sizing: border-box;
    position: relative;
    width: 100%;
    height: $space-xxxxl;

    &:after {
        content: '';
        display: block;
        width: 100%;
        clear: both;
    }

    &__input {
        -webkit-appearance: none;
        margin: 0;
        padding: 0;
        display: block;
        width: 100%;
        height: $space-xxxxl;

        &::-webkit-slider-runnable-track {
            @include rangeSliderTrack();
            background: $color-grey-xlight;
            border: 0 solid transparent;
            border-radius: 0;
        }

        &::-webkit-slider-thumb {
            @include rangeSliderThumb();
            -webkit-appearance: none;
            margin-top: ((0 * 2 + $space-xs) / 2) - ($space-m / 2);
        }

        &::-moz-range-track {
            @include rangeSliderTrack();
            background: $color-grey-xlight;
            border: 0 solid transparent;
            border-radius: 0;
        }

        &::-moz-range-thumb {
            @include rangeSliderThumb();
        }

        &::-moz-focus-outer {
            border: 0;
        }

        &::-ms-track {
            @include rangeSliderTrack();
            background: transparent;
            border-color: transparent;
            border-width: $space-m 0;
            color: transparent;
        }

        &::-ms-fill-lower {
            background: darken($color-grey-xlight, 0%);
            border: 0 solid transparent;
            border-radius: 0 * 2;
        }

        &::-ms-fill-upper {
            background: $color-grey-xlight;
            border: 0 solid transparent;
            border-radius: 0 * 2;
        }

        &::-ms-thumb {
            @include rangeSliderThumb()
        }

        &::-ms-tooltip {
            display: none;
            appearance: none;
        }

        &:focus {
            outline: none;

            &::-webkit-slider-runnable-track,
            &::-ms-fill-lower,
            &::-ms-fill-upper {
                background: $color-grey-xlight
            }

            &::-ms-thumb,
            &::-moz-range-thumb,
            &::-webkit-slider-thumb {
                background: $color-1-dark;
            }
        }
    }

    &__output {
        position: absolute;
        top: -$space-xxs;
        @include list-item-title;
        @include font-heavy;
        color: $color-1;
        width: auto;
    }

    &__min,
    &__max {
        @include list-item-title;
        @include font-heavy;
        color: $black;
        position: relative;
        top: -$space-s;
    }

    &__max {
        float: right;
    }

    @media(min-width: $s_bp) {
        &__min,
        &__max {
            top: -$space-l;
        }
    }
}

/*doc
---
title: Range Slider
name: range-slider
category: Inputs
---


```html_example
    <base-range-slider :steps="10" :min="100" :max="400" />
```
*/