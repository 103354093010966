.c-input-datalist {
    $class: c-input-datalist;

    position: relative;
    margin-bottom: $space-xxl;

    &__list {
        position: absolute;
        top: calc(100% + 4px);
        left: 0;
        width: 100%;
        z-index: $z-tooltip;

        list-style: none;
        color: inherit;
        border-radius: $space-s;
        background: $white;
        border: 1px solid $color-active;
        @include outer-shadow-tight($color-active);
        margin: 0;
        padding: 0;
        overflow: hidden;
        display: none;

        &.is-active {
            display: block;
        }

        &:empty {
            border: 0;
            box-shadow: none;
        }
    }

    &__fallback {
        margin: $space-xl auto $space-m;
        text-align: center;
    }

    &__clear {
        display: none;
        @include absolute-center('y');
        height: 100%;
        font-size: inherit;
        line-height: inherit;
        right: 0;
        background: none;
        border: none;
        box-shadow: none;
        padding: 0 $space-s;
        transition: all 200ms;

        &::after {
            @include ffrees-icons-font;
            content: $icon-cancel;
        }

        &:hover,
        &:focus,
        &:active {
            color: $color-1;
        }
    }

    &__result {
        margin: 0;
        padding: 0;
    }

        &__result-link {
            position: relative;
            display: block;
            color: inherit;
            text-decoration: none;
            padding: $space-xxs $space-m;
            font-weight: inherit;

            &::after {
                @include ffrees-icons-font;
                @include absolute-center('y');
                content: $icon-search;
                display: block;
                right: $space-s;
                color: $color-grey-8;
                font-size: inherit;
                line-height: inherit;
            }

            &:hover,
            &:focus,
            &:active {
                color: inherit;
                background: $color-grey-6;
            }
        }

    &.is-submitted {
        .c-input {
            background: $color-1-light;

            &::after {
                display: none;
            }
        }

        .#{$class}__clear {
            display: block;
        }
    }

    @media (min-width: $s_bp) {
        &--large {
            @include list-item-font-desktop;
            border-radius: $space-m;
            margin-bottom: $space-xxxxl;

            .#{$class}__result-link {
                padding: $space-s $space-xl;

                &::after {
                    right: $space-xl;
                }
            }

            .#{$class}__clear {
                padding-left: $space-xl;
                padding-right: $space-xl;
            }
        }
    }
}

/*doc
---
title: Datalist
name: datalist-input
category: Inputs
---
Note: The `--large` modifier should be used on both the input and datalist if used

```html_example_table
<form autocomplete="off">
    <div class="c-input-datalist js-input-validation js-datalist-wrapper">
        <label class="c-input c-input--search js-input-wrapper">
            <span class="c-input__label">Search for an answer</span>
            <input value="money" type="search" class="c-input__input js-input js-search-input" aria-controls="faq-search-results"/>
        </label>
        <ul id="faq-search-results" class="c-input-datalist__list js-datalist" aria-live="polite">
            <li class="c-input-datalist__result">
                <a class="c-input-datalist__result-link js-result-link" href="/help/question/1131" data-index="0">What's Money Manager?</a>
            </li>
            <li class="c-input-datalist__result">
                <a class="c-input-datalist__result-link js-result-link" href="/help/question/1048" data-index="1">Can I have more than one account?</a>
            </li>
            <li class="c-input-datalist__result">
                <a class="c-input-datalist__result-link js-result-link" href="/help/question/1151" data-index="2">Can I have more than one card?</a>
            </li>
            <li class="c-input-datalist__result">
                <a class="c-input-datalist__result-link js-result-link" href="/help/question/1111" data-index="3">Where is my money held?</a>
            </li>
            <li class="c-input-datalist__result">
                <a class="c-input-datalist__result-link js-result-link" href="/help/question/1130" data-index="4">Can I earn interest on my money?</a>
            </li>
        </ul>
    </div>
</form>
```
<div class="c-sg-modifier-wrap js-modifier-wrap">
    <div>
        <button data-target="c-input">c-input--large</button>
    </div>
    <div>
        <button data-target="c-input-datalist">c-input-datalist--large</button>
    </div>
</div>
*/