
@keyframes featureContactless {
    0% {
        opacity: 0;
    }

    10% {
        opacity: 1;
        z-index: 5;
        transform: rotate(0deg) translate(50%, -100%) scale(0);
    }

    50% {
        transform: rotate(0deg) translate(50%, 0) scale(.5);
    }

    100% {
        transform: rotate(-17deg) translate(50%, 0) scale(1);
    }
}

@keyframes featureCashbackMobile {
    0% {
        opacity: 0;
        z-index: 5;
    }

    10% {
        opacity: 1;
        z-index: 5;
        transform: translate(-50%, -100%) scale(0);
    }

    50% {
        z-index: 5;
        transform: translate(-50%, 0) scale(.5);
    }

    100% {
        top: -53px;
        transform: translate(-50%, 0) scale(1);
    }
}

@keyframes featureCashbackDesktop {
    0% {
        opacity: 0;
        z-index: 5;
    }

    10% {
        opacity: 1;
        z-index: 5;
        transform: translate(-50%, -100%) scale(0);
    }

    50% {
        z-index: 5;
        top: 10px;
        transform: translate(-50%, 0) scale(.5);
    }

    100% {
        top: -89px;
        transform: translate(-50%, 0) scale(1);
    }
}

@keyframes featureMobileQueriesXsmallScreen {
    0% {
        opacity: 0;
    }

    10% {
        opacity: 1;
        z-index: 5;
        transform: translate(50%, -100%) scale(.5);
        left: 50%;
    }

    50% {
        transform: translate(50%, 0) scale(1);
        left: 50%;
    }

    100% {
        transform: translate(0, 24px);
        left: 60%;
    }
}

@keyframes featureDesktopQueriesXsmallScreen {
    0% {
        opacity: 0;
    }

    10% {
        opacity: 1;
        z-index: 5;
        transform: translate(-50%, -100%) scale(.5);
        left: 50%;
    }

    50% {
        transform: translate(-50%, 0) scale(1);
        left: 50%;
    }

    100% {
        transform: translate(0, -42px);
        left: 0;
    }
}

@keyframes featureMobileQueriesSmallScreen {
    0% {
        opacity: 0;
    }

    10% {
        opacity: 1;
        z-index: 5;
        transform: translate(50%, -100%) scale(.5);
    }

    50% {
        transform: translate(50%, 0) scale(1);
    }

    100% {
        transform: translate(0, 51px);
        left: 60%;
    }
}

@keyframes featureDesktopQueriesSmallScreen {
    0% {
        opacity: 0;
    }

    10% {
        opacity: 1;
        z-index: 5;
        transform: translate(-50%, -100%) scale(.5);
        left: 50%;
    }

    50% {
        transform: translate(-50%, 0) scale(1);
        left: 50%;
    }

    100% {
        transform: translate(0, -42px);
        left: 20%;
    }
}

@keyframes featureDesktopQueriesMidScreen {
    0% {
        opacity: 0;
    }

    10% {
        opacity: 1;
        z-index: 5;
        transform: translate(-50%, -100%) scale(.5);
        left: 50%;
    }

    50% {
        transform: translate(-50%, 0) scale(1);
        left: 50%;
    }

    100% {
        transform: translate(0, -51px);
        left: 0%;
    }
}

@keyframes featureMobileQueriesMidScreen {
    0% {
        opacity: 0;
    }

    10% {
        opacity: 1;
        z-index: 5;
        transform: translate(50%, -100%) scale(.5);
    }

    50% {
        transform: translate(50%, 0) scale(1);
    }

    100% {
        transform: translate(0, 24px);
    }
}

@keyframes featureDesktopQueriesLargeScreen {
    0% {
        opacity: 0;
    }

    10% {
        opacity: 1;
        z-index: 5;
        transform: translate(-50%, -100%) scale(.5);
        left: 50%;
    }

    50% {
        transform: translate(-50%, 0) scale(.5);
        left: 50%;
    }

    100% {
        transform: translate(0, -65px) scale(1);
        left: 15%;
    }
}

@keyframes featureMobileQueriesLargeScreen {
    0% {
        opacity: 0;
    }

    10% {
        opacity: 1;
        z-index: 5;
        transform: translate(50%, -100%) scale(0);
        left: 50%;
    }

    50% {
        transform: translate(30%, 10%) scale(.5);
        left: 50%;
    }

    100% {
        transform: translate(0, 50px) scale(1);
        left: 56%;
    }
}

.c-feature-animations {
    $class: 'c-feature-animations';

    &__content {
        @include boxes(transparent, $black, 0, $space-xxl);
        margin-bottom: $space-xxl;
    }

    &__title {
        color: $color-1;
        @include section-title-secondary;
    }

    &__copy {
        text-align: left;
        color: $black;
    }

    &__images {
        position: relative;
    }

    &__image {
        @include image-reset();
        position: absolute;
        display: block;
    }

    &--perspective {
        perspective: 1000px;
    }

    &--z-10 {
        z-index: 10;
    }

    &--no-margin {
        margin: 0;
    }

    &--contactless  {
        transform: rotate(-17deg) translate(50%);
        height: 175px;
        opacity: 0;
        right: 50%;
        z-index: 20;

        &.is-animated {
            opacity: 1;
            animation: featureContactless;
            animation-duration: 2s;
        }
    }

    &--extra-accounts {
        max-width: 80%;
        transition: transform .75s cubic-bezier(.49,.45,.58,1.62);
        top: -79px;
        left: 60%;
        transform: translate(-50%) rotateX(-95deg);
        transform-origin: 0 0;

        &.is-animated {
            transform: translate(-50%) rotateX(0deg);
        }
    }

    &--mobile-apps {
        transform: translate(50%);
        max-width: 250px;
        right: 50%;
    }

    &--money-manager-screen {
        transition: transform 1s ease-in-out;
        transform-origin: 0 100%;
        max-height: 135px;
        left: 50%;
        top: -50px;
        transform: rotateX(-79deg) translate(-50%);

        &.is-animated {
            transform: rotateX(0deg) translate(-50%);
        }
    }

    &--money-manager-base {
        max-height: 57px;
        max-width: 308px !important; //TODO: RFC Once section > image rule is removed
        left: 50%;
        top: 85px;
        transform: translate(-50%);
    }

    &--payments {
        transition: transform .85s ease-in-out;
        max-height: 132px;
        top: -60px;
        left: 0;
        transform: rotateX(90deg);
        z-index: 1;

        &.is-animated {
            transform: rotateX(0deg);
        }
    }

    &--payments-icon-one,
    &--payments-icon-two {
        transition: {
            property: left, opacity;
            duration: .85s;
            timing-function: ease-in-out;
        }
        top: -31px;
        left: 0;
        opacity: 0;
        font-size: 61px;

        &:before {
            @include ffrees-icons-font;
            content: $icon-faster-payments;
        }

        &.is-animated {
            opacity: 1;
            left: 55%;
        }
    }

    &--payments-icon-two {
        left: 55%;

        &:before {
            content: $icon-direct-debit;
        }

        &.is-animated {
            left: 78%;
        }
    }

    &--cashback {
        width: 80%;
        max-width: 420px;
        top: -53px;
        left: 50%;
        transform: translate(-50%);
        z-index: 20;

        &.is-animated {
            opacity: 1;
            animation: featureCashbackMobile;
            animation-duration: 2s;
        }
    }

    &--contact-mobile {
        transform: translate(0, 24px);
        max-height: 160px;
        left: 60%;
        opacity: 0;
        z-index: 20;

        &.is-animated {
            opacity: 1;
            animation: featureMobileQueriesXsmallScreen;
            animation-duration: 2s;
        }
    }

    &--contact-desktop {
        transform: translate(0, -42px);
        max-width: 250px;
        left: 0;
        opacity: 0;
        z-index: 20;

        &.is-animated {
            opacity: 1;
            animation: featureDesktopQueriesXsmallScreen;
            animation-duration: 2s;
        }
    }

    @media (min-width: 550px) {
        &--contact-mobile {
            transform: translate(0, 51px);
            max-height: 225px;

            &.is-animated {
                animation: featureMobileQueriesSmallScreen;
                animation-duration: 2s;
            }
        }

        &--contact-desktop {
            max-width: 400px;
            left: 20%;

            &.is-animated {
                animation: featureDesktopQueriesSmallScreen;
                animation-duration: 2s;
            }
        }
    }

    @media (min-width: $xs_bp) {
        &--contactless {
            height: 232px;
        }

        &--extra-accounts {
            max-width: 500px;
        }

        &--money-manager-screen {
            max-height: 218px;
        }

        &--money-manager-base {
            top: 165px;
            max-width: 100% !important; //TODO: RFC Once section > image rule is removed
        }

        &--payments {
            max-height: 200px;
        }

        &--payments-icon-one,
        &--payments-icon-two {
            top: -34px;
            font-size: 99px;
        }

        &--payments-icon-one.is-animated {
            left: 55%;
        }

        &--payments-icon-two {
            left: 55%;

            &.is-animated {
                left: 78%;
            }
        }
    }

    @media (min-width: $s_bp) {
        &__content {
            min-height: 0;
        }

        &--contactless {
            height: 247px;
            top: 20px;
        }

        &--payments {
            max-height: 300px;
        }

        &--payments-icon-one,
        &--payments-icon-two {
            top: -40px;
            font-size: 150px;
        }

        &--cashback {
            max-width: 430px;
        }
    }

    @media (min-width: $m_bp) {
        &__content {
            min-height: 0;
        }

        &--contactless {
            height: 175px;
            top: 0;
        }

        &--extra-accounts {
            max-width: 80%;
        }


        &--mobile-apps {
            max-width: 450px;
        }

        &--money-manager-base {
            top: 167px;
        }

        &--payments {
            top: -76px;
            left: -40px;
            max-height: 250px;
        }

        &--payments-icon-one,
        &--payments-icon-two {
            font-size: 86px;
        }

        &--payments-icon-one.is-animated {
            left: 55%;
        }

        &--payments-icon-two {
            left: 55%;

            &.is-animated {
                left: 78%;
            }
        }

        &--contact-mobile {
            transform: translate(0, 24px);
            max-height: 200px;
            left: 50%;

            &.is-animated {
                animation: featureMobileQueriesMidScreen;
                animation-duration: 2s;
            }
        }

        &--contact-desktop {
            transform: translate(0, -51px);
            max-width: 300px;
            left: 0;

            &.is-animated {
                animation: featureDesktopQueriesMidScreen;
                animation-duration: 2s;
            }
        }
    }

    @media (min-width: $l-bp) {
        &__content {
            min-height: 0;
        }

        &--contactless {
            height: 274px;
            top: 20px;
        }

        &--extra-accounts {
            max-width: 500px;
        }

        &--money-manager-screen {
            max-height: 250px;
        }

        &--money-manager-base {
            top: 199px;
        }

        &--payments-icon-one,
        &--payments-icon-two {
            top: -31px;
            font-size: 110px;
        }

        &--contact-mobile {
            transform: translate(0, 50px);
            max-height: 233px;
            left: 56%;

            &.is-animated {
                animation: featureMobileQueriesLargeScreen;
                animation-duration: 2s;
            }
        }

        &--contact-desktop {
            transform: translate(0, -65px);
            max-width: 400px;

            &.is-animated {
                animation: featureDesktopQueriesLargeScreen;
                animation-duration: 2s;
                left: 15%;
            }
        }
    }

    @media (min-width: $xl_bp) {
        &__content {
            min-height: 0;
        }

        &--payments-icon-one,
        &--payments-icon-two {
            font-size: 126px;
        }
    }
}
