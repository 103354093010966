.l-section {
    $class: 'l-section';

    padding: $space-m 0;

    .l-content-width {
        width: 100%;
    }

    &--overlap-vertical {
        .#{$class}__content {
            &:nth-child(even) {
                margin-top: -50%;
            }
        }
    }

    &--overlap-vertical.#{$class}--overlap-horizontal {
        .#{$class}__content {
            &:nth-child(even) {
                padding-left: $space-s;
            }
            &:nth-child(odd) {
                padding-right: $space-s;
            }
        }
    }

    &--margin-top-xl {
        margin-top: $space-xl;
    }

    &--bg-color-grey-7-until-bp-m {
        background: $color-grey-7;
    }

    @media (min-width: $xs_bp) {
        &--overlap-vertical {
            .#{$class}__content {
                &:nth-child(even) {
                    margin-top: -25%;
                }
            }
        }
    }

    @media (min-width: $m_bp) {
        padding: $space-xxxxl 0;

        &--overlap-vertical {
            .#{$class}__content {
                &:nth-child(even) {
                    margin-top: 150px;
                }
            }
        }

        &--overlap-horizontal {
            .#{$class}__content {
                float: left;
                width: 55%;

                &:nth-child(even) {
                    margin-left: -10%;
                }
            }
        }

        &--padding-l-bpm {
            padding: $space-l 0;
        }

        &--padding-xxl-bpm {
            padding: $space-xxl 0;
        }

        &--margin-top-xxxxl-bp-m {
            margin-top: $space-xxxxl;
        }

        &--bg-color-grey-7-until-bp-m {
            background: none;
        }
    }
}


.offset {
    section.sectionOffset,
    .globalContainer {
        background-color: $color-grey-7;
    }
}

.globalContainer {
    transition:(all .2s ease-in-out);
    background-color: $white;
    overflow: hidden;
    position: relative;
    left: 0;

    section {
        background-color: $white;
    }
}

.allowedHeight {
    min-height: 1px;
}

.policiesTabs {

    > div {
        display: inline-block;
        float: left;
        position: relative;
        max-width: 100%;
        min-width: 50%;
        text-align: center;

        @media (min-width: $m_bp) {
            max-width: 30%;
            min-width: 20%;
            text-align: left;
        }
        @media (min-width: $xl_bp) {
            max-width: 27em;
        }
    }

    a {
        font-size: 0.938em;
        min-height: auto;
        text-decoration: none;
    }
}

.policiesContent {

    width: 100%;
    margin-left: auto;
    margin-right: auto;

    @media (min-width: $m_bp) {
        width: 60%;
        float: right;
    }

    h1,h2,h3,h4,h5,h6 {
        text-align: left;
    }

    p {
        text-align: left;
    }

    ol {
        text-align: left;
    }
}

%button {
    transition:(all .2s ease-in);
    max-width: 100%;
    max-height: 100%;
    color: $black;
    background-color: $white;
    text-align: center;
    padding: 10px 0;
    font-size: 16px;
    font-weight: 700;
    cursor: pointer;

    @media(min-width: 720px) {
        max-width: 100%;
    }

    &:disabled {
        cursor: not-allowed;
        opacity: .5;
    }

    &:focus,
    &:active {
        outline: none;
    }

    &:hover {
        color: $black;
    }
}

button,
input[type="submit"] {
    @extend %button;
    appearance:(none);
    border: none;
    cursor: pointer;
    user-select: none;
    vertical-align: middle;
    white-space: nowrap;
}

%small-button {
    @extend %button;
    max-width: none;
    width: auto;
    height: 30px;
    padding: 7px;
    display: inline-block;
    float: left;
    color: $white;
    font-size: 0.8em;
}
    .smallButton {
        @extend %small-button;
    }

.smallWideButton {
    @extend %small-button;
    width: 100%;
}
.smallInlineButton {
    @extend %small-button;
    min-height: 40px;
    height: auto;
    width: 100%;
    max-width: 100%;
    min-width: 0;
    display: inline-block;
    padding: 10px 1em;

    @media(min-width: 720px)  {
        width: auto;
    }
}
.subCategoriesButton {
    transition:(background .3s ease-in-out, bottom .5s ease-in-out);
    @extend %small-button;
    width: 100%;
    margin: 0;
    position: absolute;
    left: 0;
    bottom: 0;

    &:active,
    &:focus {
        outline: none;
    }
}
.button {
    @extend %button;
}
.rightButton {
    @extend %button;
    margin-right: 0;
    float: right;
}
.wideButton {
    @extend %button;
    max-width: 100%;
}
.inlineButton {
    @extend %button;
    min-height: 40px;
    height: auto;
    width: 100%;
    max-width: 100%;
    min-width: 0;
    display: inline-block;
    padding: 10px 1.3em;

    @media(min-width: 720px)  {
        width: auto;
    }
}
.noBkgdButton {
    @extend %button;
    width: auto;
    margin: 0;
    padding: 0;
    color: $black;
    line-height: 2.5em;
    background-color: transparent;

    &:hover {
        color: $color-1;
    }
}
.largeButton {
    @extend %button;
    max-width: 100%;
    height: auto;
    white-space: normal;

    &:before {
        width: 100%;
    }

    &.newProgressButton {
        margin-left: auto;
        margin-right: auto;
        position: relative;

        span,
        &:before {
            position: relative;
            z-index: 1;
        }

        .progress {
            transition:(width .2s ease-in-out);
            position: absolute;
            left: 0;
            top: 0;
            width: 0;
            height: 100%;
            background: transparentize($color-2, .5);
            z-index: 0;
        }

        &.btnLoading {
            border-color: $color-2;

            span,
            &:before {
                transition:(color .3s linear);
                color: $color-2;
            }
        }
    }
}
.progressButton {
    width: 100%;
    max-width: 100%;
    margin-bottom: 2%;
    margin-right: 2%;
    display: block;
    position: relative;
    text-align: center;

    @media(min-width: 720px)  {
        max-width: 240px;
        display: inline-block;
    }

    button {
        @extend %button;
        transition:(background-color .3s, color .3s, width .3s, border-width .3s, border-color .3s);
        width: 100%;
        //height: 42px;
        margin: 0 auto;
        padding: 10px 12px;
        display: block;
        color: $white;
        font-weight: 700;
        background: $color-2;
        -webkit-tap-highlight-color: transparent;

        &:hover {
            background-color: darken($color-2, 10);
        }

        &.submitDisabled {
            opacity: .5;

            &:hover {
                background-color: $color-4;
            }
        }

        &:focus {
            outline: none;
        }

        span {
           transition:(opacity .3s .1s);
        }
    }

    svg {
        transform:(translateX(-50%));
        position: absolute;
        top: 0;
        left: 50%;
        pointer-events: none;

        path {
            opacity: 0;
            fill: none;
        }

        &.progressCircle path {
            stroke: $color-2;
            stroke-width: 5;
        }

        &.checkmark path,
        &.cross path {
            transition:(opacity .1s);
            stroke: $white;
            stroke-linecap: round;
            stroke-width: 4;
        }
    }
}
.blackBg {
    background-color: $black;

    &.smallRoundedLabel:hover,
    &.smallFontRoundedLabel:hover {
        background-color: $black;
    }

    &:hover {
        color: $white;
        background-color: lighten($black, 30);
    }
}
.inlineTabs .activeTabButton {
    background-color: $color-1;
}
.numberedList {
    counter-reset: item;

    .policiesTwoColumn {
        th {
            text-align: left;
        }
        td {
            text-align: left;
        }
    }


    .policiesFourColumn {
        th {
            text-align: center;

            &:first-child {
                text-align: left;
            }

        }
        td {
            text-align: center;

            &:first-child {
                text-align: left;
            }
        }
    }

    li {
        display: block;

        &:before {
            content: counters(item, '.') ' ';
            counter-increment: item;
            min-width: 1.2em;
            margin-left: -1.2em;
            display: inline-block;
            font-weight: 800;
            margin-right: 5px;
            text-align: right;
        }

        li:before {
            min-width: 2.2em;
            margin-left: -2.2em;
            font-weight: 700;
        }

        li li:before {
            min-width: 3.2em;
            margin-left: -3.2em;
        }

        li li li:before {
            min-width: 4.2em;
            margin-left: -4.2em;
        }

        ol {
            margin-top: 1em;
            padding-left: 2.5em;
        }

    }

    h3,
    h6 {
        margin-bottom: 0;
        display: inline-block;
    }

    table {
        margin-top: 2em;

        thead {
            border-bottom: 1px solid $color-grey-3;
        }

        tr > th:first-child {
            width: 25%;
        }

        th,
        td {
            padding-left: 0.5em;
            padding-right: 0.5em;
        }
    }
}

.termsTable {
    margin-top: 1.5em;
    margin-bottom: 1.5em;

    tr > td {
        padding: 0;
    }

    .firstTable {
        margin-top: 0;

        thead tr > th:first-child {
            padding: 0.5em;

            &:first-child {
                width: 80%;
            }
        }

        tr > td {
            padding: 0.5em;
        }
    }

    .secondTable {
        margin-top: 0;

        thead tr > th {
            width: 20%;
            text-align: right;

            &:first-child {
                width: 40%;
                text-align: left;
            }
        }

        tr > td {
            padding: 0.5em;
            text-align: right;

            &:first-child {
                text-align: left;
            }
        }

    }
}
table {
    empty-cells: show;
    margin: 0;

    &.myAccountTable {

        th,
        td {
            &:first-child {
                padding-left: 1em;
            }

            &:nth-child(2) {
                text-align: left;
            }
        }

        tbody tr {
            transition:(background .3s ease-in-out);

            &:hover {
                background-color: $color-1-light-2;
            }
        }
    }

    &.priv-table tr td {
        border: 1px solid black;
        border-collapse: collapse;
        text-align: left;
        vertical-align: baseline;
        padding-left: 5px;
        padding-right: 5px;
    }

    &.t-heading {

        th {
            padding-left: 5px;
        }
    }


    &.centerTable th,
    &.centerTable td {

        &:nth-child(n) {
            text-align: center;
            padding: 15px 0;

            p {
                text-align: center;
            }
        }
    }

    &.leftTable {

        th,
        td {
            &:nth-child(n) {
                text-align: left;
                vertical-align: baseline;
                margin-right: 10px;
            }
        }
    }

    .iconRightOpenBig {
        font-size: 1.4em;
    }

    tr {
        border-top: 1px solid $color-grey-3;
        border-bottom: 1px solid $color-grey-3;
    }

    thead tr {
        border: 0;
    }

    th {
        color: $color-1;
        font-weight: 800;
        font-size: 100%;
        font-family: $main-font;
        padding-top: 0;

        @media (min-width: 720px) {
            font-size: 1.3em;
        }
    }

    td,
    th {
        border: 0;

        &:nth-child(2) {
            text-align: center;

            @media (min-width: 720px) {
                text-align: right;
            }
        }

        &:nth-of-type(3n) {
            text-align: right;
        }

        &.smallRow {
            padding-left: 5px;
            width: 20px;
            padding-right: 5px;

            @media (min-width: 720px) {
                padding-left: 10px;
                width: 40px;
                padding-right: 10px;
            }
        }
    }

    .iconDown {
        font-size: .8em;
        color: $color-4;
    }

    .iconUp {
        font-size: .8em;
        color: $color-2;
    }

    .smallText {
        font-size: .8em;
        color: $color-grey-3;
    }
}
.c-glossary {
    margin-bottom: $space-xxl;

    &__term {
        display: block;
        margin-bottom: $space-xs;
        font-weight: 700;
        letter-spacing: -0.5px;
        font-style: normal;
        font-size: 22px;
    }

    &__definition__inner {
        padding: 18px;
        background: $color-grey-xxlight;
    }

    &__aside__inner {
        padding: 18px 90px 18px 18px;
        background: $color-grey-xxlight;
    }

    &__subtitle {
        @extend .u-text-transform-none;
        @extend .u-color-1;
        @extend .u-font-heavy;
    }

    @media (min-width: $m_bp) {
        margin-bottom: $space-xxxxl;

        &__term {
            margin-bottom: $space-xs;
            max-width: 50%;
        }

        &__definition__inner {
            padding: 18px 0;
            background: transparent;
        }

        &__aside {
            padding-left: 0;
        }

        &__aside__inner {
            padding: 18px 90px 18px 18px;
        }
    }
}
