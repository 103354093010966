.c-account-balance {
    $class: 'c-account-balance';

    position: relative;

    &__main-balance {
        @include section-intro-text;
        margin: 0;
        padding-bottom: $space-s;
        text-align: left;
        color: $black;
        position: relative;
    }

        &__bracket {
            transition: width .9s ease-in;
            width: 0;
            height: 2px;
            position: absolute;
            left: 0;
            bottom: $space-xs;
            background-color: $black;

            &:before,
            &:after {
                content: '';
                width: 2px;
                height: 6px;
                position: absolute;
                left: 0;
                bottom: -6px;
                background-color: $black;
            }

            &:after {
                left: auto;
                right: 0;
            }
        }

        &--total-bracket {
            top: -6px;

            &:before,
            &:after {
                top: -6px;
                bottom: auto;
            }
        }

    &__bar {
        animation: backgroundForwards 2s linear infinite;
        height: $space-l;
        display: block;
        margin-bottom: $space-xxs;
        width: 100%;
        overflow: hidden;
        background: linear-gradient(135deg, rgba(209,211,212,0.8) 0%,rgba(255,255,255,0.8) 50%,rgba(209,211,212,0.8) 100%);
        background-size: 200% 200%;
        white-space: nowrap;
        font-size: 0;
    }

        &__section {
            transition: width .4s ease-in-out .4s;
            height: $space-l;
            width: 0;
            display: inline-block;
        }

        &--main-account-section {
            background: $color-1;
        }

        &--extra-accounts-section {
            background: $color-1-mid;
        }

        &--pending-section {
            background: $color-grey-dark;
        }

    &__balance {
        @include list-item-text;
        color: $color-1;
        display: inline-block;
        transition: opacity .25s ease-in;
        line-height: 1;
        opacity: 0;
    }

        &__extra-account-link {
            color: $color-1;

            &:hover {
                color: $color-1-dark;
            }
        }

    &--main-account-balance {
        float: left;
    }

    &--extra-account-balance {
        margin: 0;
        position: absolute;
        text-align: right;
    }

    &--pending-balance {
        color: $color-grey-dark;
        float: right;
    }

    &--total-balance {
        margin: $space-xxl 0 0;
    }

    &--header {
        .#{$class}__main-balance,
        .#{$class}__extra-account-link,
        .#{$class}__balance {
            color: $white;
        }

            .#{$class}__bracket {
                background-color: $white;

                &:before,
                &:after {
                    background-color: $white;
                }
            }

        .#{$class}__bar {
            border: 2px solid $white;
            background: linear-gradient(135deg, rgba(234,67,16,0.8) 0%,rgba(239,144,115,0.8) 50%,rgba(234,67,16,0.8) 100%);
            background-size: 200% 200%;
        }
    }

    @media (min-width: $m_bp) {
        &__main-balance {
            padding-bottom: $space-m;
        }

            &__bracket {
                bottom: $space-s;
            }

        &__balance {
            line-height: 1;
        }

        &--total-balance {
            margin: $space-xxxl 0 0;
        }
    }
}

/*doc
---
title: account balance
name: account-balance
category: Components
---

```html_example
    <div class="c-account-balance js-account-balance js-balance-ls">

        <div class="c-reveal-vertical js-reveal is-revealed" data-event-trigger="" data-reveal-height="64" style="">

            <div class="c-reveal-vertical__content">

                <h3 class="c-account-balance__main-balance">
                    Available Balance
                    £<span class="js-available-balance-figure js-balance-count-up tt-available-balance">900.00</span>
                    <span class="c-account-balance__bracket js-available-bracket" style="width: 90%;"></span>
                </h3>

                <div class="u-clearfix">

                    <p class="c-account-balance__balance c-account-balance--main-account-balance js-main-account-balance" style="opacity: 1;">
                        Main Account £<span class="js-main-account-balance-figure tt-main-account-balance">400.00</span>
                    </p>

                    <p class="c-account-balance__balance c-account-balance--pending-balance js-pending-balance" style="opacity: 1;">
                        Pending £<span class="js-pending-balance-figure tt-pending-balance">100.00</span>
                    </p>

                </div>

            </div>

        </div>

        <div class="c-account-balance__bar js-balance-bar">

            <div class="c-account-balance__section c-account-balance--main-account-section js-main-account-section" style="width: calc(40% + 1px);"></div>

            <div class="c-account-balance__section c-account-balance--extra-accounts-section js-extra-section" style="width: 50%;"></div>

            <div class="c-account-balance__section c-account-balance--pending-section js-pending-section" style="width: 10%;"></div>

        </div>

        <div class="c-reveal-vertical js-reveal is-revealed" data-event-trigger="" data-reveal-height="56" style="">

            <div class="c-reveal-vertical__content">

                <p class="c-account-balance__balance c-account-balance--extra-account-balance js-extra-account-balance" style="right: 10%; opacity: 1;">
                    Extra Accounts £<span class="js-extra-account-balance-figure tt-extra-account-balance">500.00</span>
                </p>

                <h3 class="c-account-balance__main-balance c-account-balance--total-balance">
                    <span class="c-account-balance__bracket c-account-balance--total-bracket js-total-bracket" style="width: 100%;"></span>
                    Total Balance
                    £<span class="js-total-balance-figure js-balance-count-up tt-total-balance">1000.00</span>
                </h3>

            </div>

        </div>
    </div>
```

*/
