@charset "UTF-8";
/*------------------------------------*\
    Settings & Tools
\*------------------------------------*/
/*------------------------------------*\
    Font variables
\*------------------------------------*/
/*------------------------------------*\
    Spacing variables
\*------------------------------------*/
/*------------------------------------*\
    Color palette
\*------------------------------------*/
/*------------------------------------*\
    z-index table
\*------------------------------------*/
/* 1 */
/* 5 */
/* 10 */
/* 20 */
/* 30 */
/* 40 */
/* 50 */
/* 60 */
/* 70 */
/* 80 */
/* 90 */
/* 100 */
/*------------------------------------*\
    reusuable style variables
\*------------------------------------*/
/*------------------------------------*\
    Media query variables
\*------------------------------------*/
/*------------------------------------*\
    base64 images
\*------------------------------------*/
/*------------------------------------*\
    component variables
\*------------------------------------*/
/*doc
---
title: colors
name: colors
category: Colors
---
```html_example
<div class="l-row l-row--no-vertical-padding ">
    <div class="l-col-25">
        <div class="c-sg-color-block u-color-1-bg"></div>
        <p class="sg-p">$color-1: #f2663b</p>
    </div>
    <div class="l-col-25">
        <div class="c-sg-color-block u-color-1-light-bg"></div>
        <p class="sg-p">$color-1-light: #fef8f7</p>
    </div>
    <div class="l-col-25">
        <div class="c-sg-color-block u-color-1-mid-bg"></div>
        <p class="sg-p">$color-1-mid: #fcded5</p>
    </div>
    <div class="l-col-25">
        <div class="c-sg-color-block u-color-1-dark-bg"></div>
        <p class="sg-p">$color-1-dark: #ea4310</p>
    </div>
    <div class="l-col-25">
        <div class="c-sg-color-block u-color-2-bg"></div>
        <p class="sg-p">$color-2: #6eb52b</p>
    </div>
    <div class="l-col-25">
        <div class="c-sg-color-block u-color-2-light-bg"></div>
        <p class="sg-p">$color-2-light: #eff9e6</p>
    </div>
    <div class="l-col-25">
        <div class="c-sg-color-block u-color-2-mid-bg"></div>
        <p class="sg-p">$color-2-mid: #cfe4ba</p>
    </div>
    <div class="l-col-25">
        <div class="c-sg-color-block u-color-2-dark-bg"></div>
        <p class="sg-p">$color-2-dark: #558c21</p>
    </div>
    <div class="l-col-25">
        <div class="c-sg-color-block u-color-3-bg"></div>
        <p class="sg-p">$color-3: #ffb20a</p>
    </div>
    <div class="l-col-25">
        <div class="c-sg-color-block u-color-3-light-bg"></div>
        <p class="sg-p">$color-3-light: #fff6e1</p>
    </div>
    <div class="l-col-25">&nbsp;</div>
    <div class="l-col-25">
        <div class="c-sg-color-block u-color-3-dark-bg"></div>
        <p class="sg-p">$color-3-dark: #d69300</p>
    </div>
    <div class="l-col-25">
        <div class="c-sg-color-block u-color-4-bg"></div>
        <p class="sg-p">$color-4: #d31a35</p>
    </div>
    <div class="l-col-25">
        <div class="c-sg-color-block u-color-4-light-bg"></div>
        <p class="sg-p">$color-4-light: #fabccc</p>
    </div>
    <div class="l-col-25">&nbsp;</div>
    <div class="l-col-25">
        <div class="c-sg-color-block u-color-4-dark-bg"></div>
        <p class="sg-p">$color-4-dark: #a6142a</p>
    </div>
    <div class="l-col-25">
        <div class="c-sg-color-block u-color-5-bg"></div>
        <p class="sg-p">$color-5: #005eb0</p>
    </div>
    <div class="l-col-25">
        <div class="c-sg-color-block u-color-5-light-bg"></div>
        <p class="sg-p">$color-5-light: #e8f1f8</p>
    </div>
    <div class="l-col-25">&nbsp;</div>
    <div class="l-col-25">
        <div class="c-sg-color-block u-color-5-dark-bg"></div>
        <p class="sg-p">$color-5-dark: #00437d</p>
    </div>
</div>
```
*/
/*doc
---
title: greys
name: greys
category: Colors
---
```html_example
<div class="l-row l-row--no-vertical-padding ">
    <div class="l-col-25">
        <div class="c-sg-color-block u-color-black-bg"></div>
        <p class="sg-p">$black: #000000</p>
    </div>
    <div class="l-col-25">
        <div class="c-sg-color-block u-color-white-bg"></div>
        <p class="sg-p">$white: #ffffff</p>
    </div>
    <div class="l-col-25">
        <div class="c-sg-color-block u-color-grey-1-bg"></div>
        <p class="sg-p">$color-grey-1: #4d4d4d</p>
    </div>
    <div class="l-col-25">
        <div class="c-sg-color-block u-color-grey-2-bg"></div>
        <p class="sg-p">$color-grey-2: #afafaf</p>
    </div>
    <div class="l-col-25">
        <div class="c-sg-color-block u-color-grey-3-bg"></div>
        <p class="sg-p">$color-grey-3: #d1d3d4</p>
    </div>
    <div class="l-col-25">
        <div class="c-sg-color-block u-color-grey-4-bg"></div>
        <p class="sg-p">$color-grey-4: #f1f1f1</p>
    </div>
    <div class="l-col-25">
        <div class="c-sg-color-block u-color-grey-5-bg"></div>
        <p class="sg-p">$color-grey-5: #f6f6f6</p>
    </div>
    <div class="l-col-25">
        <div class="c-sg-color-block u-color-grey-6-bg"></div>
        <p class="sg-p">$color-grey-6: #f8f8f8</p>
    </div>
    <div class="l-col-25">
        <div class="c-sg-color-block u-color-grey-7-bg"></div>
        <p class="sg-p">$color-grey-7: #fbfcfc</p>
    </div>
    <div class="l-col-25">
        <div class="c-sg-color-block u-color-grey-8-bg"></div>
        <p class="sg-p">$color-grey-9: #757575</p>
    </div>
    <div class="l-col-25">
        <div class="c-sg-color-block u-color-grey-9-bg"></div>
        <p class="sg-p">$color-grey-9: #e5e5e5</p>
    </div>
</div>
```
*/
.fourCol {
  float: left;
  display: block;
  margin-right: 2.35765%;
  width: 100%; }

.fourCol:last-child {
  margin-right: 0; }

@media screen and (min-width: 61.25em) {
  .fourCol {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 31.76157%; }
  .fourCol:last-child {
    margin-right: 0; } }

section.greySection {
  background-color: #f0f1f3;
  border-bottom: 1px solid #a7a9ac; }

section.greySection h1 {
  margin-bottom: 0.5em; }

@media screen and (min-width: 45em) {
  section.greySection h1 {
    margin-bottom: 0; } }

.loggedIn section .topMargin {
  max-width: 90em;
  margin-left: auto;
  margin-right: auto; }

.loggedIn section .topMargin:after {
  content: "";
  display: table;
  clear: both; }

.servicestatus .fourCol {
  margin-top: 1.5em;
  margin-bottom: 1.5em; }

.servicestatus .fourCol th,
.servicestatus .fourCol td {
  padding: 15px 0; }

.servicestatus .fourCol tr td:nth-child(1n),
.servicestatus .fourCol tr th:nth-child(1n) {
  width: 80%; }

.servicestatus .fourCol tr td:nth-child(2n),
.servicestatus .fourCol tr th:nth-child(2n) {
  width: 10%; }

.globalContainer {
  transition: all 0.2s ease-in-out;
  background-color: #ffffff;
  overflow: hidden;
  position: relative;
  left: 0; }
  .globalContainer section {
    background-color: #ffffff; }

.twoColumns {
  float: left;
  display: block;
  margin-right: 2.35765%;
  width: 100%; }

.twoColumns:last-child {
  margin-right: 0; }

@media screen and (min-width: 45em) {
  .twoColumns {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 48.82117%; }
  .twoColumns:last-child {
    margin-right: 0; } }

.twoColumns:nth-child(2n) {
  margin-right: 0; }

.twoColumns.shift {
  margin-left: 0; }

@media screen and (min-width: 45em) {
  .twoColumns.shift {
    margin-left: 25.58941%; } }

h1.un {
  font-size: 3em;
  line-height: 0.75;
  letter-spacing: -3px; }

@media screen and (min-width: 45em) {
  h1.un {
    text-align: left;
    font-size: 3.5em; } }

@media screen and (min-width: 61.25em) {
  h1.un {
    font-size: 3.5em; }
  h1.un.vLong {
    font-size: 2.8em; } }

@media screen and (min-width: 80em) {
  h1.un {
    font-size: 3.8em; }
  h1.un.long {
    font-size: 3.6em; }
  h1.un.vLong {
    font-size: 3.4em; } }

@media screen and (min-width: 45em) {
  h1.un.noUn {
    margin-left: 1.3em; } }

@media screen and (min-width: 61.25em) {
  h1.un.noUn {
    margin-left: 1.2em; } }

@media screen and (min-width: 80em) {
  h1.un.noUn {
    margin-left: 1.5em; } }

h6 {
  font-size: 0.938em;
  letter-spacing: 0;
  margin-bottom: 1em;
  font-weight: 600; }

section {
  width: 100%;
  min-width: 100%; }

section:after {
  content: "";
  display: table;
  clear: both; }

@media screen and (min-width: 45em) {
  section:first-of-type {
    margin-top: 0; } }

section > div {
  max-width: 90em;
  margin-left: auto;
  margin-right: auto; }

section > div:after {
  content: "";
  display: table;
  clear: both; }

section > div:after {
  content: "";
  display: table;
  clear: both; }

section > div > hr {
  margin-top: 0; }

section article {
  float: left;
  display: block;
  margin-right: 2.35765%;
  width: 100%; }

section article:last-child {
  margin-right: 0; }

section article div {
  padding: 0; }

table {
  border-collapse: collapse;
  margin: 0.65em 0;
  table-layout: fixed;
  width: 100%; }

table {
  empty-cells: show;
  margin: 0; }

table tr {
  border-top: 1px solid #d1d3d4;
  border-bottom: 1px solid #d1d3d4; }

tr, td, th {
  vertical-align: middle; }

th {
  border-bottom: 1px solid #a9adaf;
  font-weight: bold;
  padding: 0.65em 0;
  text-align: left; }

table th {
  color: #f2663b;
  font-weight: 800;
  font-size: 100%;
  font-family: "futura-pt", sans-serif;
  padding-top: 0; }

@media screen and (min-width: 45em) {
  table th {
    font-size: 1.3em; } }

table td,
table th {
  border: 0; }

table td:nth-child(2),
table th:nth-child(2) {
  text-align: center; }

@media screen and (min-width: 45em) {
  table td:nth-child(2),
  table th:nth-child(2) {
    text-align: right; } }

[class^="icon"]:before, [class*=" icon"]:before, .okImg,
.offlineImg,
.warningImg {
  font-family: 'ffreesicons';
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */ }

.okImg,
.offlineImg,
.warningImg {
  font-size: 1.2em; }

.okImg:before {
  color: #6eb52b;
  content: ""; }

.offlineImg:before {
  color: #ee114a;
  content: ""; }

.warningImg:before {
  color: #ffb20a;
  content: ""; }

.serviceStatusBox {
  width: 90%;
  padding-top: 20px;
  padding-right: 20px;
  text-align: left; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "futura-pt", sans-serif;
  line-height: 1.3;
  margin: 0;
  font-weight: 800;
  letter-spacing: -0.5px;
  margin-bottom: 0.6em;
  text-align: center; }

@media screen and (min-width: 45em) {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    text-align: inherit;
    margin-bottom: 0.8em; } }

h6 {
  font-size: 0.938em;
  letter-spacing: 0;
  margin-bottom: 1em;
  font-weight: 600; }

/* dont use placeholder as a label replacement */
/* for accessibilty purposes */
/*------------------------------------*\
    Animation Mixins
\*------------------------------------*/
/*------------------------------------*\
    Generic
\*------------------------------------*/
html {
  font-family: sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%; }

body {
  margin: 0; }

article, aside, details, figcaption, figure, footer, header, hgroup, main, menu, nav, section, summary {
  display: block; }

audio, canvas, progress, video {
  display: inline-block;
  vertical-align: baseline; }

audio:not([controls]) {
  display: none;
  height: 0; }

[hidden], template {
  display: none; }

a {
  background-color: transparent; }

a:active, a:hover {
  outline: 0; }

abbr[title] {
  border-bottom: 1px dotted; }

dfn {
  font-style: italic; }

h1 {
  font-size: 2em;
  margin: .67em 0; }

mark {
  background: #ff0;
  color: #000; }

small {
  font-size: 80%; }

sub, sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sup {
  top: -.5em; }

sub {
  bottom: -.25em; }

img {
  border: 0; }

svg:not(:root) {
  overflow: hidden; }

figure {
  margin: 0; }

hr {
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  height: 0; }

code, kbd, pre, samp {
  font-family: monospace,monospace;
  font-size: 1em; }

button, input, optgroup, select, textarea {
  color: inherit;
  font: inherit;
  margin: 0; }

button {
  overflow: visible; }

button, select {
  text-transform: none; }

button, html input[type=button], input[type=reset], input[type=submit] {
  -webkit-appearance: button;
  cursor: pointer; }

button[disabled], html input[disabled] {
  cursor: default; }

input {
  line-height: normal; }

input[type=checkbox], input[type=radio] {
  box-sizing: border-box;
  padding: 0; }

input[type=number]::-webkit-inner-spin-button, input[type=number]::-webkit-outer-spin-button {
  height: auto; }

input[type=search] {
  -webkit-appearance: textfield;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  box-sizing: content-box; }

input[type=search]::-webkit-search-cancel-button, input[type=search]::-webkit-search-decoration {
  -webkit-appearance: none; }

fieldset {
  border: none;
  margin: 0;
  padding: 0; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

td, th {
  padding: 0; }

b, strong, optgroup {
  font-weight: 700; }

pre, textarea {
  overflow: auto; }

button::-moz-focus-inner, input::-moz-focus-inner, legend {
  border: 0;
  padding: 0; }

html {
  box-sizing: border-box; }

*, *:before, *:after {
  box-sizing: inherit; }

/*------------------------------------*\
    Elements
\*------------------------------------*/
input[type="button"],
input[type="submit"],
input[type="reset"],
input[type="file"],
button {
  border: none;
  text-align: center;
  cursor: pointer;
  vertical-align: top; }
  input[type="button"]:focus,
  input[type="submit"]:focus,
  input[type="reset"]:focus,
  input[type="file"]:focus,
  button:focus {
    outline: none; }
  input[type="button"]:disabled,
  input[type="submit"]:disabled,
  input[type="reset"]:disabled,
  input[type="file"]:disabled,
  button:disabled {
    cursor: not-allowed;
    opacity: .5; }

@font-face {
  font-family: "uLogo";
  font-weight: normal;
  font-style: normal;
  src: url("../font/u-logo.eot");
  src: url("../font/u-logo.eot?#iefix") format("embedded-opentype"), url("../font/u-logo.woff") format("woff"), url("../font/u-logo.ttf") format("truetype"), url("../font/u-logo.svg#u-font") format("svg"); }

@font-face {
  font-family: "u-font";
  font-weight: normal;
  font-style: normal;
  src: url("../font/ufont2016.eot");
  src: url("../font/ufont2016.eot?#iefix") format("embedded-opentype"), url("../font/ufont2016.woff") format("woff"), url("../font/ufont2016.ttf") format("truetype"), url("../font/ufont2016.svg#u-font") format("svg"); }

@font-face {
  font-family: "ffreesicons";
  font-weight: normal;
  font-style: normal;
  src: url("../font/ffreesicons.eot");
  src: url("../font/ffreesicons.eot?#iefix") format("embedded-opentype"), url("../font/ffreesicons.woff") format("woff"), url("../font/ffreesicons.ttf") format("truetype"), url("../font/ffreesicons.svg#ffreesicons") format("svg"); }

.u-u-font {
  font-family: 'u-font', sans-serif;
  font-weight: 300; }

.u-u-font-large-center {
  font-family: 'u-font', sans-serif;
  font-weight: 300;
  vertical-align: middle;
  font-size: 120%; }

body {
  -webkit-font-smoothing: antialiased;
  color: #000000;
  font-family: "futura-pt", sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px; }
  @media (min-width: 980px) {
    body {
      font-size: 16px;
      line-height: 26px; } }

p {
  margin: 0 0 16px; }

a {
  transition: color 0.2s ease-in-out;
  cursor: pointer;
  font-family: "futura-pt", sans-serif;
  font-weight: 700;
  color: black;
  text-decoration: none; }
  a:hover, a:focus, a:active {
    outline: none;
    color: #ea4310; }

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  margin-bottom: 16px; }

.u-mega-title {
  display: block;
  margin-bottom: 24px;
  color: #f2663b;
  text-transform: uppercase;
  font-size: 42px;
  line-height: 1; }
  @media (min-width: 980px) {
    .u-mega-title {
      margin-bottom: 36px;
      font-size: 96px;
      line-height: 1; } }

.u-super-title {
  display: block;
  margin-bottom: 24px;
  color: #f2663b;
  font-size: 30px;
  line-height: 40px; }
  @media (min-width: 980px) {
    .u-super-title {
      margin-bottom: 36px;
      font-size: 85px;
      line-height: 109px; } }

.u-title {
  color: #f2663b;
  margin-bottom: 28px;
  font-size: 30px;
  line-height: 38px; }
  @media (min-width: 980px) {
    .u-title {
      margin-bottom: 36px;
      font-size: 44px;
      line-height: 56px; } }

.u-subtitle {
  display: block;
  margin-bottom: 24px;
  font-size: 24px;
  line-height: 31px; }
  @media (min-width: 980px) {
    .u-subtitle {
      margin-bottom: 28px;
      font-size: 36px;
      line-height: 46px; } }

.u-section-title {
  margin-bottom: 16px;
  font-size: 22px;
  line-height: 29px; }
  @media (min-width: 980px) {
    .u-section-title {
      font-size: 28px;
      line-height: 40px; } }

.u-section-title-secondary,
.u-tabs-section-title {
  font-size: 16px;
  line-height: 24px;
  font-weight: 500; }
  @media (min-width: 980px) {
    .u-section-title-secondary,
    .u-tabs-section-title {
      font-size: 20px;
      line-height: 30px; } }

.u-section-subtitle {
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  padding-bottom: 8px;
  border-bottom: 1px solid #d1d3d4; }
  @media (min-width: 980px) {
    .u-section-subtitle {
      font-size: 20px;
      line-height: 30px; } }

.u-section-intro {
  margin-bottom: 16px;
  font-size: 18px;
  line-height: 26px; }
  @media (min-width: 980px) {
    .u-section-intro {
      font-size: 24px;
      line-height: 36px; } }

.u-label {
  display: block;
  margin-bottom: 8px;
  font-family: "futura-pt", sans-serif;
  font-weight: 500; }

.u-footnote {
  display: block;
  font-size: 12px;
  line-height: 18px; }
  @media (min-width: 980px) {
    .u-footnote {
      font-size: 14px;
      line-height: 18px; } }

/*doc
---
title: Headings
name: headings
category: Typography
---
```html_example
<h1 class="u-mega-title">Mega Title</h1>
<h1 class="u-super-title">Super Title</h1>
<h1 class="u-title">Title</h1>
<h2 class="u-subtitle">Subtitle</h2>
<h3 class="u-section-title">Section Title</h3>
<h3 class="u-section-subtitle">Section Subtitle</h3>
<h4 class="u-section-intro">Section Intro</h4>
<p>Copy</p>
```
*/
/*doc
---
title: Body font
name: bodyfont
category: Typography
---
```html_example
<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Magni sunt dolores vitae libero quasi incidunt quas, quos, voluptates repellat facilis, optio, praesentium assumenda earum? Sit at maxime, necessitatibus voluptates. Ratione.</p>
<small class="u-footnote">Footnote</small>
```
*/
hr {
  width: 100%;
  height: 1px;
  background-color: #afafaf;
  border: none; }

html,
body {
  transition: background .3s ease-in-out;
  min-height: 100%;
  min-width: 320px;
  width: 100%;
  max-width: 100%;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #ebebeb; }

body {
  display: flex;
  margin-top: 60px;
  margin-bottom: 60px; }
  body.u-no-header {
    margin-top: 0;
    margin-bottom: 0; }
  body.l-reduced-header, body.l-no-navigation {
    margin-bottom: 0; }

@media (min-width: 980px) {
  body {
    margin-top: 100px;
    margin-bottom: 0; }
    body.u-no-header {
      margin-top: 0; } }

img,
picture {
  max-width: 100%; }

ul,
ol {
  margin: 0;
  padding: 0;
  padding-left: 16px;
  list-style-type: none; }
  ul li,
  ol li {
    margin-bottom: 16px; }

ul {
  list-style-type: disc; }

ol {
  list-style-type: decimal; }

/*------------------------------------*\
    Layout
\*------------------------------------*/
.l-content-wrap {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: calc(100vh - 60px - 60px);
  overflow: hidden; }
  .l-reduced-header .l-content-wrap, .l-no-navigation .l-content-wrap {
    min-height: calc(calc(100vh - 60px - 60px) + 60px); }
  @media (min-width: 980px) {
    .l-content-wrap {
      min-height: calc(100vh - 100px); }
      .l-reduced-header .l-content-wrap, .l-no-navigation .l-content-wrap {
        min-height: calc(100vh - 100px); } }

.l-main-content {
  flex: 1 0 auto;
  background-color: #ffffff; }
  .l-main-content--max-height-viewport {
    display: flex;
    flex-direction: column;
    max-height: calc(100vh - 60px - 60px); }
  @media (min-width: 980px) {
    .l-main-content--max-height-viewport {
      max-height: calc(100vh - 100px); } }

.l-content-width {
  max-width: 1440px;
  margin-right: auto;
  margin-left: auto;
  padding-right: 24px;
  padding-left: 24px;
  min-width: 0; }
  .l-content-width--max-width-960 {
    max-width: 960px; }
  .l-content-width--max-width-1080 {
    max-width: 1080px; }
  .l-content-width--max-width-1300 {
    max-width: 1300px; }
  .l-content-width--max-width-720 {
    max-width: 720px; }
  .l-content-width--max-width-420 {
    max-width: 420px; }
  .l-content-width--padding-0 {
    padding: 0; }

.l-main-content-padding {
  padding-top: 24px;
  padding-bottom: 24px; }
  @media (min-width: 800px) {
    .l-main-content-padding {
      padding-top: 60px;
      padding-bottom: 36px; } }
  @media (min-width: 800px) {
    .l-main-content-padding--breadcrumb {
      padding-top: 45px; } }
  .l-main-content-padding--large {
    padding-top: 36px;
    padding-bottom: 36px; }
    @media (min-width: 800px) {
      .l-main-content-padding--large {
        padding-top: 155px; } }

.l-take-over-screen {
  transition: opacity .5s ease-in-out;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  bottom: 0;
  overflow-y: scroll;
  overflow-x: hidden;
  z-index: 90; }
  .l-take-over-screen--background-color-grey-xxlight {
    background-color: #f6f6f6; }
  .l-take-over-screen--background-color-white {
    background-color: #ffffff; }
  .l-take-over-screen--background-color-white-90 {
    background-color: rgba(255, 255, 255, 0.7); }

.l-btn-pair-sidebar-layout:after {
  content: "";
  display: table;
  clear: both; }

@media (min-width: 540px) {
  .l-btn-pair-sidebar-layout .c-btn:nth-child(2),
  .l-btn-pair-sidebar-layout .c-btn-outline:nth-child(2) {
    float: right; } }

@media (min-width: 980px) {
  .l-btn-pair-sidebar-layout .c-btn:first-child,
  .l-btn-pair-sidebar-layout .c-btn-outline:first-child {
    float: left; }
  .l-btn-pair-sidebar-layout .c-btn:nth-child(2),
  .l-btn-pair-sidebar-layout .c-btn-outline:nth-child(2) {
    float: left; } }

[class^=l-col] {
  width: 100%;
  float: left;
  padding: 16px;
  margin-right: 0;
  margin-left: 0; }

.l-col-100 {
  width: 100%; }

.l-col-75 {
  width: 75%; }

.l-col-66 {
  width: 66.66%; }

.l-col-60 {
  width: 60%; }

.l-col-50 {
  width: 50%; }

.l-col-40 {
  width: 40%; }

.l-col-33 {
  width: 33.33%; }

.l-col-25 {
  width: 25%; }

.l-row {
  margin-left: -16px;
  margin-right: -16px; }
  .l-row:after {
    content: "";
    display: table;
    clear: both; }
  .l-row--w-1600 {
    max-width: 1600px; }
  .l-row--w-1440 {
    max-width: 1440px; }
  .l-row--w-1400 {
    max-width: 1400px; }
  .l-row--w-1200 {
    max-width: 1200px; }
  .l-row--w-1000 {
    max-width: 1000px; }
  .l-row--w-800 {
    max-width: 800px; }
  .l-row--w-600 {
    max-width: 600px; }
  .l-row--w-400 {
    max-width: 400px; }
  .l-row--side-padding {
    margin: 0 auto; }
    .l-row--side-padding.l-row--w-1600 {
      max-width: calc(1600px + (16px * 2));
      margin: 0 auto; }
    .l-row--side-padding.l-row--w-1440 {
      max-width: calc(1440px + (16px * 2));
      margin: 0 auto; }
    .l-row--side-padding.l-row--w-1400 {
      max-width: calc(1400px + (16px * 2));
      margin: 0 auto; }
    .l-row--side-padding.l-row--w-1200 {
      max-width: calc(1200px + (16px * 2));
      margin: 0 auto; }
    .l-row--side-padding.l-row--w-1000 {
      max-width: calc(1000px + (16px * 2));
      margin: 0 auto; }
    .l-row--side-padding.l-row--w-800 {
      max-width: calc(800px + (16px * 2));
      margin: 0 auto; }
    .l-row--side-padding.l-row--w-600 {
      max-width: calc(600px + (16px * 2));
      margin: 0 auto; }
    .l-row--side-padding.l-row--w-400 {
      max-width: calc(400px + (16px * 2));
      margin: 0 auto; }
  .l-row--vertical-margins {
    margin-top: 16px;
    margin-bottom: 16px; }
  .l-row--no-vertical-padding > [class^=l-col] {
    padding-top: 0;
    padding-bottom: 0; }
  .l-row--no-padding {
    margin-left: 0;
    margin-right: 0; }
    .l-row--no-padding > [class^=l-col] {
      padding: 0; }

@media (min-width: 540px) {
  .l-col-xs-100 {
    width: 100%; }
  .l-col-xs-75 {
    width: 75%; }
  .l-col-xs-66 {
    width: 66.66%; }
  .l-col-xs-60 {
    width: 60%; }
  .l-col-xs-50 {
    width: 50%; }
  .l-col-xs-40 {
    width: 40%; }
  .l-col-xs-33 {
    width: 33.33%; }
  .l-col-xs-25 {
    width: 25%; } }

@media (min-width: 800px) {
  .l-col-s-100 {
    width: 100%; }
  .l-col-s-75 {
    width: 75%; }
  .l-col-s-66 {
    width: 66.66%; }
  .l-col-s-60 {
    width: 60%; }
  .l-col-s-50 {
    width: 50%; }
  .l-col-s-40 {
    width: 40%; }
  .l-col-s-33 {
    width: 33.33%; }
  .l-col-s-25 {
    width: 25%; } }

@media (min-width: 980px) {
  .l-col-m-100 {
    width: 100%; }
  .l-col-m-75 {
    width: 75%; }
  .l-col-m-66 {
    width: 66.66%; }
  .l-col-m-60 {
    width: 60%; }
  .l-col-m-50 {
    width: 50%; }
  .l-col-m-40 {
    width: 40%; }
  .l-col-m-33 {
    width: 33.33%; }
  .l-col-m-25 {
    width: 25%; } }

@media (min-width: 1080px) {
  .l-col-l-100 {
    width: 100%; }
  .l-col-l-75 {
    width: 75%; }
  .l-col-l-66 {
    width: 66.66%; }
  .l-col-l-60 {
    width: 60%; }
  .l-col-l-50 {
    width: 50%; }
  .l-col-l-40 {
    width: 40%; }
  .l-col-l-33 {
    width: 33.33%; }
  .l-col-l-25 {
    width: 25%; } }

@media (min-width: 1200px) {
  .l-col-xl-100 {
    width: 100%; }
  .l-col-xl-75 {
    width: 75%; }
  .l-col-xl-66 {
    width: 66.66%; }
  .l-col-xl-60 {
    width: 60%; }
  .l-col-xl-50 {
    width: 50%; }
  .l-col-xl-40 {
    width: 40%; }
  .l-col-xl-33 {
    width: 33.33%; }
  .l-col-xl-25 {
    width: 25%; } }

@media (min-width: 1440px) {
  .l-col-xxl-100 {
    width: 100%; }
  .l-col-xxl-75 {
    width: 75%; }
  .l-col-xxl-66 {
    width: 66.66%; }
  .l-col-xxl-60 {
    width: 60%; }
  .l-col-xxl-50 {
    width: 50%; }
  .l-col-xxl-40 {
    width: 40%; }
  .l-col-xxl-33 {
    width: 33.33%; }
  .l-col-xxl-25 {
    width: 25%; } }

/*doc
---
title: Base grid system
name: base-grid-system
category: Grid system
---

```html_example
<div class="l-row">
    <div class="l-col-33 u-sg-col">l-col-33</div>
    <div class="l-col-33 u-sg-col">l-col-33</div>
    <div class="l-col-33 u-sg-col">l-col-33</div>
</div>
```
<div class="c-sg-modifier-wrap js-modifier-wrap">
    <div><button>l-row--side-padding</button></div>
    <div><button>l-row--vertical-margins</button></div>
    <div><button>l-row--no-vertical-padding</button></div>
    <div><button data-target="l-col-33">u-sg-col</button></div>
</div>

All grids columns are wrapped in a <code>.l-row</code>.<br>
The grid uses padding on each column to create the gutters/spacing between elements as opposed to margins. This means with dont need to constantly adjust margins (like margin-right) based on the amount of columns in the grid.

Having this padding would usually mean that the content might not align with the content around it. We have negative margins on the <code>.l-row</code> element to negate this. You can see this negative margin above, note how the column content is aligned correctly, and the background on each column shows the negative margin expanding beyond its parents padding.

Note the <code>.u-sg-col</code> is used to better present the columns, and is not needed in practice.

Side padding is enabled using modifier <code>'l-row--side-padding'</code>. This removes the negative margin on <code>.l-row</code>.

```html_example
<div class="l-row l-row--side-padding">
    <div class="l-col-33 u-sg-col">l-col-33</div>
    <div class="l-col-33 u-sg-col">l-col-33</div>
    <div class="l-col-33 u-sg-col">l-col-33</div>
</div>
```

<div class="c-sg-modifier-wrap js-modifier-wrap">
    <div><button>l-row--side-padding</button></div>
    <div><button>l-row--vertical-margins</button></div>
    <div><button>l-row--no-vertical-padding</button></div>
    <div><button data-target="l-col-33">u-sg-col</button></div>
</div>

To give spacing between column elements, wrap each column content with a div. This may cause unwanted vertical padding. see next example for a modifier. <br>
Note the <code>.u-sg-col-padding</code> is used to better present the columns, and is not needed in practice.

```html_example
<div class="l-row l-row--side-padding">
    <div class="l-col-33"><div class="u-sg-col-padding"> l-col-33</div></div>
    <div class="l-col-33"><div class="u-sg-col-padding"> l-col-33</div></div>
    <div class="l-col-33"><div class="u-sg-col-padding"> l-col-33</div></div>
</div>
```
<div class="c-sg-modifier-wrap js-modifier-wrap">
    <div><button>l-row--side-padding</button></div>
    <div><button>l-row--vertical-margins</button></div>
    <div><button>l-row--no-vertical-padding</button></div>
    <div><button data-target="l-col-33 > div">u-sg-col-padding</button></div>
</div>

To remove padding top and bottom on the grid columns use the modifier <code>'l-row--no-vertical-padding'.</code>

```html_example
<div class="l-row l-row--no-vertical-padding">
    <div class="l-col-33"><div class="u-sg-col-padding"> l-col-33</div></div>
    <div class="l-col-33"><div class="u-sg-col-padding"> l-col-33</div></div>
    <div class="l-col-33"><div class="u-sg-col-padding"> l-col-33</div></div>
</div>
```
<div class="c-sg-modifier-wrap js-modifier-wrap">
    <div><button>l-row--side-padding</button></div>
    <div><button>l-row--vertical-margins</button></div>
    <div><button>l-row--no-vertical-padding</button></div>
    <div><button data-target="l-col-33 > div">u-sg-col-padding</button></div>
</div>

To add vertical margins to the grid, use the modifier <code>'l-row--vertical-margins'</code>.

```html_example
<div class="l-row l-row--vertical-margins l-row--no-vertical-padding">
    <div class="l-col-33"><div class="u-sg-col-padding">l-col-33</div></div>
    <div class="l-col-33"><div class="u-sg-col-padding">l-col-33</div></div>
    <div class="l-col-33"><div class="u-sg-col-padding">l-col-33</div></div>
</div>
```


*/
/*doc
---
title: Mobile first responsive grid
name: mobile-first-responsive-grid
category: Grid system
---


Columns can be the following widths: <b>100%, 75%, 66%, 60%, 50%, 40%, 33%, 25%</b>.

Responsive changes are made by using classes which indicate a responsive breakpoint and a column width. For example <code>'.l-col-m-50'</code> will cause a column to change from 100% width (the default width for all columns) at the medium breakpoint (and upwards - mobile first) to 50% width.</p>

Reposnive breakpoints are as follows

<pre class="c-sg-code-block-light">
xs:  540px;
s:   800px;
m:   980px;
l:   1080px;
xl:  1200px;
xxl: 1440px;</pre>

In the exmaple below all columns will be <b>100%</b> width (default column width) until medium breakpoint.<br>
Then the first two columns become <b>50%</b> width and the last column becomes <b>100%</b> width.<br>
Note how we dont need to use the class <code>l-col-100</code> at all, just the responsive class <code>l-col-m-50</code>, becuase all columns are default to 100% width. This makes for much cleaner markup.

```html_example
<div class="l-row l-row--side-padding">
    <div class="l-col-m-50 u-sg-col"> l-col-m-50 </div>
    <div class="l-col-m-50 u-sg-col"> l-col-m-50 </div>
    <div class="l-col-m-100 u-sg-col"> l-col-m-100 </div>
</div>
```
<div class="c-sg-modifier-wrap js-modifier-wrap">
    <div><button>l-row--side-padding</button></div>
    <div><button>l-row--vertical-margins</button></div>
    <div><button>l-row--no-vertical-padding</button></div>
    <div><button data-target="l-row > div">u-sg-col</button></div>
</div>


Another example in which all columns will be <b>33%</b> width until medium breakpoint<br>
Then the first two columns become <b>50%</b> width and the last column becomes <b>100%</b> width.<br>
This offers a lot of flexibilty in creating grid layouts.

```html_example
<div class="l-row l-row--side-padding">
    <div class="l-col-33 l-col-m-50 u-sg-col"> l-col-33, l-col-m-50</div>
    <div class="l-col-33 l-col-m-50 u-sg-col"> l-col-33, l-col-m-50 </div>
    <div class="l-col-33 l-col-m-100 u-sg-col"> l-col-33, l-col-m-100 </div>
</div>
```
<div class="c-sg-modifier-wrap js-modifier-wrap">
    <div><button>l-row--side-padding</button></div>
    <div><button>l-row--vertical-margins</button></div>
    <div><button>l-row--no-vertical-padding</button></div>
    <div><button data-target="l-col-33">u-sg-col</button></div>
</div>

You dont necessarily need to wrap a <code>'.l-row'</code> around each presentational row. You can stack them up to make more complex layouts:

```html_example
<div class="l-row l-row--side-padding">
    <div class="l-col-33 l-col-m-50 u-sg-col"> l-col-33, l-col-m-50 </div>
    <div class="l-col-33 l-col-m-50 u-sg-col"> l-col-33, l-col-m-50 </div>
    <div class="l-col-33 l-col-m-100 u-sg-col"> l-col-33, l-col-m-100 </div>

    <div class="l-col-25 l-col-m-50 u-sg-col"> l-col-25, l-col-m-50 </div>
    <div class="l-col-25 l-col-m-50 u-sg-col"> l-col-25, l-col-m-50 </div>
    <div class="l-col-25 l-col-m-100 u-sg-col"> l-col-25, l-col-m-100 </div>
    <div class="l-col-25 l-col-m-100 u-sg-col"> l-col-25, l-col-m-100 </div>
</div>
```
<div class="c-sg-modifier-wrap js-modifier-wrap">
    <div><button>l-row--side-padding</button></div>
    <div><button>l-row--vertical-margins</button></div>
    <div><button>l-row--no-vertical-padding</button></div>
    <div><button data-target="l-row > div">u-sg-col</button></div>
</div>

Same as above but with spacing

```html_example
<div class="l-row l-row--side-padding">
    <div class="l-col-33 l-col-m-50"><div class="u-sg-col-padding"> l-col-33, l-col-m-50 </div></div>
    <div class="l-col-33 l-col-m-50"><div class="u-sg-col-padding"> l-col-33, l-col-m-50 </div></div>
    <div class="l-col-33 l-col-m-100"><div class="u-sg-col-padding"> l-col-33, l-col-m-100 </div></div>

    <div class="l-col-25 l-col-m-50"><div class="u-sg-col-padding"> l-col-25, l-col-m-50 </div></div>
    <div class="l-col-25 l-col-m-50"><div class="u-sg-col-padding"> l-col-25, l-col-m-50 </div></div>
    <div class="l-col-25 l-col-m-100"><div class="u-sg-col-padding"> l-col-25, l-col-m-100 </div></div>
    <div class="l-col-25 l-col-m-100"><div class="u-sg-col-padding"> l-col-25, l-col-m-100 </div></div>
</div>
```
<div class="c-sg-modifier-wrap js-modifier-wrap">
    <div><button>l-row--side-padding</button></div>
    <div><button>l-row--vertical-margins</button></div>
    <div><button>l-row--no-vertical-padding</button></div>
    <div><button data-target="l-row > div > div">u-sg-col-padding</button></div>
</div>

*/
/*doc
---
title: Responsive form grid example (using wip inputs)
name: responsive-form-grid-example
category: Grid system
---

```html_example
<div class="u-white-box">
    <div class="l-row l-row--no-vertical-padding">
        <div class="l-col-m-50">
            <label class="c-input js-input-validation">
                <span class="c-input__label">First name</span>
                <input name="Name" value="" type="text" id="test" class="c-input__input"
                data-v-input-min="1"
                data-v-input-max="100"
                data-v-type="letters"
                data-v-hint="Please fill this in"
                data-v-message="Please enter a valid name"
                required>
                <span class="c-input__validation-hint"></span>
                <span class="c-input__validation-message"></span>
            </label>
        </div>

        <div class="l-col-m-50">
            <div class="c-input-group c-input-group--width-50-50">
                <label class="c-input js-input-validation">
                    <span class="c-input__label">First name</span>
                    <input name="Name" value="" type="text" id="test" class="c-input__input"
                    data-v-input-min="1"
                    data-v-input-max="100"
                    data-v-type="letters"
                    data-v-hint="Please fill this in"
                    data-v-message="Please enter a valid name"
                    required>
                    <span class="c-input__validation-hint"></span>
                    <span class="c-input__validation-message"></span>
                </label>
                <label class="c-input js-input-validation">
                    <span class="c-input__label">Last Name</span>
                    <input name="LastName" value="" type="text" id="test" class="c-input__input"
                    data-v-input-min="1"
                    data-v-input-max="100"
                    data-v-type="letters"
                    data-v-hint="Please fill this in"
                    data-v-message="Please enter a valid name"
                    required>
                    <span class="c-input__validation-hint"></span>
                    <span class="c-input__validation-message"></span>
                </label>
            </div>
        </div>

        <div class="l-col-m-50">
            <label class="c-input c-input--dropdown js-dropdown-input">
                <span class="c-input__label">How often</span>
                <span class="c-input__dropdown-text">
                    <span>filler</span>
                </span>
                <select name="gender" required="" class="c-input__dropdown" id="test3">
                    <option selected="" disabled="" class="c-input__hidden-option"></option>
                    <option disabled="">Please pick one</option>
                    <option value="M">Male</option>
                    <option value="F">Female</option>
                </select>
                <span class="c-input__validation-hint"></span>
                <span class="c-input__validation-message"></span>
            </label>
        </div>

        <div class="l-col-m-50">
            <label class="c-input js-input-validation">
                <span class="c-input__label">First name</span>
                <input name="Name" value="" type="text" id="test" class="c-input__input"
                data-v-input-min="1"
                data-v-input-max="100"
                data-v-type="letters"
                data-v-hint="Please fill this in"
                data-v-message="Please enter a valid name"
                required>
                <span class="c-input__validation-hint"></span>
                <span class="c-input__validation-message"></span>
            </label>
        </div>
    </div>
    <div class="c-checkbox-radio manAddCheck">
        <input class="c-checkbox-radio__input showManualAddress" checked="" type="checkbox" name="radio" id="manuallyAddAddress2">
        <label class="c-checkbox-radio__label" for="manuallyAddAddress2">
            <div class="c-checkbox-radio__label-indicator"></div>
            <div class="c-checkbox-radio__label-text">Manually Enter Address</div>
        </label>
    </div>

    <div class="c-toggle-switch ">
        <input id="signup2" type="checkbox" class="c-toggle-switch__input" />
        <label for="signup2" class="c-toggle-switch__label">
            <span class="c-toggle-switch__text">Create standing order for this recipient</span>
            <span class="c-toggle-switch__label-inner">
            </span>
        </label>
    </div>
    <button class="c-btn c-btn--4 c-btn--mobile-full-width u-before-icon-cancel u-margin-bottom-xs">Cancel</button>
    <button class="c-btn c-btn--2 c-btn--mobile-full-width c-btn--float-right u-before-icon-arrow-right u-margin-bottom-0">Review</button>

</div>

```

*/
/*doc
---
title: Common base page layout
name: q-common-base-page-layout
category: Grid system
---

```html_example
<div class="l-content-width">
        <div class="l-row u-padding-top-bottom-l">
            <div class="l-col-m-40 l-col-l-33">
                <h1 class="u-color-1">Dashboard</h1>

                <div class="c-info-text">
                    <h4 class="c-info-text__title">
                        <span class="u-before-icon-info-fill c-info-text__icon"></span>
                        Payment Information
                    </h4>
                    <p class="c-info-text__copy">Make sure you're in the know by reading through our payment information and terms.</p>
                </div>
            </div>
            <div class="l-col-m-60 u-float-right">
                        <label for="sortCode" class=" input-radius-zero">
                            <span class="label">Sort Code</span>
                            <input
                                id="sortCode"
                                class="changeable validateInput numberOnly input-radius-zero js-modulus-check tt-recipient-sortcode"
                                name="toSortCode"
                                type="text"
                                value=""
                                maxlength="8"
                                max="999999"
                                autocomplete="off"
                                data-type="regMatch"
                                data-reg-match="sortCode"
                                data-input-hint="A valid Sort Code will be 6 numbers"
                                required
                            />
                        </label>
                            <label class="datePickerLabel showDay datepicker--radius-zero tt-standing-order-end-date">
                                <span class="label">Last payment date <sup>(optional)</sup></span>
                                <input
                                        id="nextPaymentDate"
                                        class="secondPaymentDate datePicker future"
                                        name="endDate"
                                        type="date"
                                        value=""
                                        data-history="future"
                                        data-year-limit="5"
                                        data-input-hint="This date must be after your first payment"
                                        data-required="no"
                                />
                            </label>
                        <button type="button" class="c-btn c-btn--4 c-btn--mobile-full-width u-before-icon-cancel u-margin-bottom-zero">Cancel</button>
                        <button type="submit" class="c-btn c-btn--2 c-btn--mobile-full-width c-btn--float-right u-before-icon-arrow-right u-margin-bottom-zero">Submit</button>
            </div>
        </div>
</div>

```

*/
.l-section {
  padding: 20px 0; }
  .l-section .l-content-width {
    width: 100%; }
  .l-section--overlap-vertical .l-section__content:nth-child(even) {
    margin-top: -50%; }
  .l-section--overlap-vertical.l-section--overlap-horizontal .l-section__content:nth-child(even) {
    padding-left: 16px; }
  .l-section--overlap-vertical.l-section--overlap-horizontal .l-section__content:nth-child(odd) {
    padding-right: 16px; }
  .l-section--margin-top-xl {
    margin-top: 28px; }
  .l-section--bg-color-grey-7-until-bp-m {
    background: #fbfcfc; }
  @media (min-width: 540px) {
    .l-section--overlap-vertical .l-section__content:nth-child(even) {
      margin-top: -25%; } }
  @media (min-width: 980px) {
    .l-section {
      padding: 60px 0; }
      .l-section--overlap-vertical .l-section__content:nth-child(even) {
        margin-top: 150px; }
      .l-section--overlap-horizontal .l-section__content {
        float: left;
        width: 55%; }
        .l-section--overlap-horizontal .l-section__content:nth-child(even) {
          margin-left: -10%; }
      .l-section--padding-l-bpm {
        padding: 24px 0; }
      .l-section--padding-xxl-bpm {
        padding: 36px 0; }
      .l-section--margin-top-xxxxl-bp-m {
        margin-top: 60px; }
      .l-section--bg-color-grey-7-until-bp-m {
        background: none; } }

.offset section.sectionOffset,
.offset .globalContainer {
  background-color: #fbfcfc; }

.globalContainer {
  transition: all 0.2s ease-in-out;
  background-color: #ffffff;
  overflow: hidden;
  position: relative;
  left: 0; }
  .globalContainer section {
    background-color: #ffffff; }

.allowedHeight {
  min-height: 1px; }

.policiesTabs > div {
  display: inline-block;
  float: left;
  position: relative;
  max-width: 100%;
  min-width: 50%;
  text-align: center; }
  @media (min-width: 980px) {
    .policiesTabs > div {
      max-width: 30%;
      min-width: 20%;
      text-align: left; } }
  @media (min-width: 1200px) {
    .policiesTabs > div {
      max-width: 27em; } }

.policiesTabs a {
  font-size: 0.938em;
  min-height: auto;
  text-decoration: none; }

.policiesContent {
  width: 100%;
  margin-left: auto;
  margin-right: auto; }
  @media (min-width: 980px) {
    .policiesContent {
      width: 60%;
      float: right; } }
  .policiesContent h1, .policiesContent h2, .policiesContent h3, .policiesContent h4, .policiesContent h5, .policiesContent h6 {
    text-align: left; }
  .policiesContent p {
    text-align: left; }
  .policiesContent ol {
    text-align: left; }

button,
input[type="submit"], .smallButton, .smallWideButton, .smallInlineButton, .subCategoriesButton, .button, .rightButton, .wideButton, .inlineButton, .noBkgdButton, .largeButton, .progressButton button {
  transition: all 0.2s ease-in;
  max-width: 100%;
  max-height: 100%;
  color: #000000;
  background-color: #ffffff;
  text-align: center;
  padding: 10px 0;
  font-size: 16px;
  font-weight: 700;
  cursor: pointer; }
  @media (min-width: 720px) {
    button,
    input[type="submit"], .smallButton, .smallWideButton, .smallInlineButton, .subCategoriesButton, .button, .rightButton, .wideButton, .inlineButton, .noBkgdButton, .largeButton, .progressButton button {
      max-width: 100%; } }
  button:disabled,
  input:disabled[type="submit"], .smallButton:disabled, .smallWideButton:disabled, .smallInlineButton:disabled, .subCategoriesButton:disabled, .button:disabled, .rightButton:disabled, .wideButton:disabled, .inlineButton:disabled, .noBkgdButton:disabled, .largeButton:disabled {
    cursor: not-allowed;
    opacity: .5; }
  button:focus,
  input:focus[type="submit"], .smallButton:focus, .smallWideButton:focus, .smallInlineButton:focus, .subCategoriesButton:focus, .button:focus, .rightButton:focus, .wideButton:focus, .inlineButton:focus, .noBkgdButton:focus, .largeButton:focus, button:active,
  input:active[type="submit"], .smallButton:active, .smallWideButton:active, .smallInlineButton:active, .subCategoriesButton:active, .button:active, .rightButton:active, .wideButton:active, .inlineButton:active, .noBkgdButton:active, .largeButton:active {
    outline: none; }
  button:hover,
  input:hover[type="submit"], .smallButton:hover, .smallWideButton:hover, .smallInlineButton:hover, .subCategoriesButton:hover, .button:hover, .rightButton:hover, .wideButton:hover, .inlineButton:hover, .noBkgdButton:hover, .largeButton:hover {
    color: #000000; }

button,
input[type="submit"] {
  appearance: none;
  border: none;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap; }

.smallButton, .smallWideButton, .smallInlineButton, .subCategoriesButton {
  max-width: none;
  width: auto;
  height: 30px;
  padding: 7px;
  display: inline-block;
  float: left;
  color: #ffffff;
  font-size: 0.8em; }

.smallWideButton {
  width: 100%; }

.smallInlineButton {
  min-height: 40px;
  height: auto;
  width: 100%;
  max-width: 100%;
  min-width: 0;
  display: inline-block;
  padding: 10px 1em; }
  @media (min-width: 720px) {
    .smallInlineButton {
      width: auto; } }

.subCategoriesButton {
  transition: background 0.3s ease-in-out, bottom 0.5s ease-in-out;
  width: 100%;
  margin: 0;
  position: absolute;
  left: 0;
  bottom: 0; }
  .subCategoriesButton:active, .subCategoriesButton:focus {
    outline: none; }

.rightButton {
  margin-right: 0;
  float: right; }

.wideButton {
  max-width: 100%; }

.inlineButton {
  min-height: 40px;
  height: auto;
  width: 100%;
  max-width: 100%;
  min-width: 0;
  display: inline-block;
  padding: 10px 1.3em; }
  @media (min-width: 720px) {
    .inlineButton {
      width: auto; } }

.noBkgdButton {
  width: auto;
  margin: 0;
  padding: 0;
  color: #000000;
  line-height: 2.5em;
  background-color: transparent; }
  .noBkgdButton:hover {
    color: #f2663b; }

.largeButton {
  max-width: 100%;
  height: auto;
  white-space: normal; }
  .largeButton:before {
    width: 100%; }
  .largeButton.newProgressButton {
    margin-left: auto;
    margin-right: auto;
    position: relative; }
    .largeButton.newProgressButton span, .largeButton.newProgressButton:before {
      position: relative;
      z-index: 1; }
    .largeButton.newProgressButton .progress {
      transition: width 0.2s ease-in-out;
      position: absolute;
      left: 0;
      top: 0;
      width: 0;
      height: 100%;
      background: rgba(110, 181, 43, 0.5);
      z-index: 0; }
    .largeButton.newProgressButton.btnLoading {
      border-color: #6eb52b; }
      .largeButton.newProgressButton.btnLoading span, .largeButton.newProgressButton.btnLoading:before {
        transition: color 0.3s linear;
        color: #6eb52b; }

.progressButton {
  width: 100%;
  max-width: 100%;
  margin-bottom: 2%;
  margin-right: 2%;
  display: block;
  position: relative;
  text-align: center; }
  @media (min-width: 720px) {
    .progressButton {
      max-width: 240px;
      display: inline-block; } }
  .progressButton button {
    transition: background-color 0.3s, color 0.3s, width 0.3s, border-width 0.3s, border-color 0.3s;
    width: 100%;
    margin: 0 auto;
    padding: 10px 12px;
    display: block;
    color: #ffffff;
    font-weight: 700;
    background: #6eb52b;
    -webkit-tap-highlight-color: transparent; }
    .progressButton button:hover {
      background-color: #558c21; }
    .progressButton button.submitDisabled {
      opacity: .5; }
      .progressButton button.submitDisabled:hover {
        background-color: #d31a35; }
    .progressButton button:focus {
      outline: none; }
    .progressButton button span {
      transition: opacity 0.3s 0.1s; }
  .progressButton svg {
    transform: translateX(-50%);
    position: absolute;
    top: 0;
    left: 50%;
    pointer-events: none; }
    .progressButton svg path {
      opacity: 0;
      fill: none; }
    .progressButton svg.progressCircle path {
      stroke: #6eb52b;
      stroke-width: 5; }
    .progressButton svg.checkmark path,
    .progressButton svg.cross path {
      transition: opacity 0.1s;
      stroke: #ffffff;
      stroke-linecap: round;
      stroke-width: 4; }

.blackBg {
  background-color: #000000; }
  .blackBg.smallRoundedLabel:hover, .blackBg.smallFontRoundedLabel:hover {
    background-color: #000000; }
  .blackBg:hover {
    color: #ffffff;
    background-color: #4d4d4d; }

.inlineTabs .activeTabButton {
  background-color: #f2663b; }

.numberedList {
  counter-reset: item; }
  .numberedList .policiesTwoColumn th {
    text-align: left; }
  .numberedList .policiesTwoColumn td {
    text-align: left; }
  .numberedList .policiesFourColumn th {
    text-align: center; }
    .numberedList .policiesFourColumn th:first-child {
      text-align: left; }
  .numberedList .policiesFourColumn td {
    text-align: center; }
    .numberedList .policiesFourColumn td:first-child {
      text-align: left; }
  .numberedList li {
    display: block; }
    .numberedList li:before {
      content: counters(item, ".") " ";
      counter-increment: item;
      min-width: 1.2em;
      margin-left: -1.2em;
      display: inline-block;
      font-weight: 800;
      margin-right: 5px;
      text-align: right; }
    .numberedList li li:before {
      min-width: 2.2em;
      margin-left: -2.2em;
      font-weight: 700; }
    .numberedList li li li:before {
      min-width: 3.2em;
      margin-left: -3.2em; }
    .numberedList li li li li:before {
      min-width: 4.2em;
      margin-left: -4.2em; }
    .numberedList li ol {
      margin-top: 1em;
      padding-left: 2.5em; }
  .numberedList h3,
  .numberedList h6 {
    margin-bottom: 0;
    display: inline-block; }
  .numberedList table {
    margin-top: 2em; }
    .numberedList table thead {
      border-bottom: 1px solid #d1d3d4; }
    .numberedList table tr > th:first-child {
      width: 25%; }
    .numberedList table th,
    .numberedList table td {
      padding-left: 0.5em;
      padding-right: 0.5em; }

.termsTable {
  margin-top: 1.5em;
  margin-bottom: 1.5em; }
  .termsTable tr > td {
    padding: 0; }
  .termsTable .firstTable {
    margin-top: 0; }
    .termsTable .firstTable thead tr > th:first-child {
      padding: 0.5em; }
      .termsTable .firstTable thead tr > th:first-child:first-child {
        width: 80%; }
    .termsTable .firstTable tr > td {
      padding: 0.5em; }
  .termsTable .secondTable {
    margin-top: 0; }
    .termsTable .secondTable thead tr > th {
      width: 20%;
      text-align: right; }
      .termsTable .secondTable thead tr > th:first-child {
        width: 40%;
        text-align: left; }
    .termsTable .secondTable tr > td {
      padding: 0.5em;
      text-align: right; }
      .termsTable .secondTable tr > td:first-child {
        text-align: left; }

table {
  empty-cells: show;
  margin: 0; }
  table.myAccountTable th:first-child,
  table.myAccountTable td:first-child {
    padding-left: 1em; }
  table.myAccountTable th:nth-child(2),
  table.myAccountTable td:nth-child(2) {
    text-align: left; }
  table.myAccountTable tbody tr {
    transition: background 0.3s ease-in-out; }
    table.myAccountTable tbody tr:hover {
      background-color: #F39200; }
  table.priv-table tr td {
    border: 1px solid black;
    border-collapse: collapse;
    text-align: left;
    vertical-align: baseline;
    padding-left: 5px;
    padding-right: 5px; }
  table.t-heading th {
    padding-left: 5px; }
  table.centerTable th:nth-child(n),
  table.centerTable td:nth-child(n) {
    text-align: center;
    padding: 15px 0; }
    table.centerTable th:nth-child(n) p,
    table.centerTable td:nth-child(n) p {
      text-align: center; }
  table.leftTable th:nth-child(n),
  table.leftTable td:nth-child(n) {
    text-align: left;
    vertical-align: baseline;
    margin-right: 10px; }
  table .iconRightOpenBig {
    font-size: 1.4em; }
  table tr {
    border-top: 1px solid #d1d3d4;
    border-bottom: 1px solid #d1d3d4; }
  table thead tr {
    border: 0; }
  table th {
    color: #f2663b;
    font-weight: 800;
    font-size: 100%;
    font-family: "futura-pt", sans-serif;
    padding-top: 0; }
    @media (min-width: 720px) {
      table th {
        font-size: 1.3em; } }
  table td,
  table th {
    border: 0; }
    table td:nth-child(2),
    table th:nth-child(2) {
      text-align: center; }
      @media (min-width: 720px) {
        table td:nth-child(2),
        table th:nth-child(2) {
          text-align: right; } }
    table td:nth-of-type(3n),
    table th:nth-of-type(3n) {
      text-align: right; }
    table td.smallRow,
    table th.smallRow {
      padding-left: 5px;
      width: 20px;
      padding-right: 5px; }
      @media (min-width: 720px) {
        table td.smallRow,
        table th.smallRow {
          padding-left: 10px;
          width: 40px;
          padding-right: 10px; } }
  table .iconDown {
    font-size: .8em;
    color: #d31a35; }
  table .iconUp {
    font-size: .8em;
    color: #6eb52b; }
  table .smallText {
    font-size: .8em;
    color: #d1d3d4; }

.c-glossary {
  margin-bottom: 36px; }
  .c-glossary__term {
    display: block;
    margin-bottom: 12px;
    font-weight: 700;
    letter-spacing: -0.5px;
    font-style: normal;
    font-size: 22px; }
  .c-glossary__definition__inner {
    padding: 18px;
    background: #f6f6f6; }
  .c-glossary__aside__inner {
    padding: 18px 90px 18px 18px;
    background: #f6f6f6; }
  @media (min-width: 980px) {
    .c-glossary {
      margin-bottom: 60px; }
      .c-glossary__term {
        margin-bottom: 12px;
        max-width: 50%; }
      .c-glossary__definition__inner {
        padding: 18px 0;
        background: transparent; }
      .c-glossary__aside {
        padding-left: 0; }
      .c-glossary__aside__inner {
        padding: 18px 90px 18px 18px; } }

.l-sidebar-layout {
  position: relative;
  flex-grow: 1; }
  .l-sidebar-layout__content, .l-sidebar-layout__sidebar {
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    overflow-y: auto; }
  .l-sidebar-layout__content {
    left: 0;
    visibility: hidden;
    z-index: 1;
    transform: translateX(100%);
    transition: transform 0.5s ease-in-out, visibility 0s linear 0.5s, z-index 0s linear 0.5s;
    background-color: #ffffff; }
    .l-sidebar-layout__content.is-shown {
      visibility: visible;
      z-index: 2;
      transform: translateX(0%);
      transition: transform 0.5s ease-in-out; }
  .l-sidebar-layout__content-inner {
    height: 100%; }
  .l-sidebar-layout__panels-container {
    position: relative;
    height: 100%;
    margin: auto; }
    .l-sidebar-layout__panels-container--max-width-800 {
      max-width: 800px; }
  .l-sidebar-layout__panel {
    max-width: 1440px;
    margin-right: auto;
    margin-left: auto;
    padding-right: 24px;
    padding-left: 24px;
    min-width: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    opacity: 0;
    height: 0;
    overflow: hidden; }
    .l-sidebar-layout__panel.is-current {
      opacity: 1;
      height: auto; }
  .l-sidebar-layout__sidebar {
    right: 0;
    z-index: 1;
    background-color: #fbfcfc; }
  .l-sidebar-layout__sidebar-inner {
    height: 100%;
    margin: auto; }
  .l-sidebar-layout--theme-reversed .l-sidebar-layout__sidebar {
    background-color: #ffffff; }
  @media (min-width: 980px) {
    .l-sidebar-layout__content {
      visibility: visible;
      z-index: 1;
      display: block;
      transform: none;
      transition: none;
      width: calc(100% - 400px); }
      .l-sidebar-layout__content.is-shown {
        z-index: 1;
        transform: none;
        transition: none; }
    .l-sidebar-layout__panel {
      transition: opacity 0.5s ease-in-out; }
      .l-sidebar-layout__panel.is-permanent-desktop {
        opacity: 1;
        height: auto; }
    .l-sidebar-layout__sidebar, .l-sidebar-layout__sidebar-inner {
      width: 400px; }
    .l-sidebar-layout__sidebar {
      overflow-x: hidden; }
    .l-sidebar-layout__sidebar-inner {
      margin: 0;
      margin-right: auto; }
    .l-sidebar-layout--theme-reversed .l-sidebar-layout__sidebar {
      background-color: #fbfcfc; }
    .l-sidebar-layout--sidebar-padding-bp-m .l-sidebar-layout__sidebar-inner {
      padding-left: 24px;
      padding-right: 24px; } }
  @media (min-width: 1080px) {
    .l-sidebar-layout__content {
      width: calc(100% - 480px); }
    .l-sidebar-layout__sidebar, .l-sidebar-layout__sidebar-inner {
      width: 480px; } }
  @media (min-width: 1440px) {
    .l-sidebar-layout__sidebar {
      background-color: #ffffff;
      box-shadow: inset 2px 0 0 #fbfcfc;
      width: calc(480px + ((100% - 1440px) / 2)); }
    .l-sidebar-layout__content {
      width: calc(100% - (480px + ((100% - 1440px) / 2))); }
    .l-sidebar-layout__content-inner {
      width: calc(1440px - 480px);
      margin-left: auto; }
    .l-sidebar-layout--theme-reversed .l-sidebar-layout__sidebar {
      background-color: #ffffff; } }

/*------------------------------------*\
    Animations
\*------------------------------------*/
@keyframes backgroundBackAndForth {
  0% {
    background-position: 0 50%; }
  50% {
    background-position: 100% 50%; }
  100% {
    background-position: 0 50%; } }

@keyframes backgroundForwards {
  0% {
    background-position: 200% 0%; }
  100% {
    background-position: 0 0%; } }

/*------------------------------------*\
    Components
\*------------------------------------*/
/*------------------------------------*\
    accordion block
\*------------------------------------*/
.c-accordion-block__header {
  position: relative;
  display: block;
  width: 100%;
  padding: 16px;
  padding-right: 36px;
  margin-bottom: 1px;
  background: #000000;
  color: #ffffff;
  cursor: pointer;
  transition: color 300ms ease-in-out; }
  .c-accordion-block__header::after {
    content: "";
    font-family: 'ffreesicons', sans-serif;
    font-weight: 500;
    font-style: normal;
    position: absolute;
    transform: translate3d(0, -50%, 0);
    top: 50%;
    right: 16px;
    transition: transform 300ms ease-in-out, color 300ms ease-in-out; }

.c-accordion-block__title {
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  margin: 0; }
  @media (min-width: 980px) {
    .c-accordion-block__title {
      font-size: 20px;
      line-height: 30px; } }

.c-accordion-block__meta {
  margin: 0; }

.c-accordion-block__content {
  background-color: #ffffff;
  height: 0;
  overflow: hidden;
  transition: height 300ms ease-in-out; }

.c-accordion-block__content-inner {
  padding: 16px;
  border: 1px solid #ebebeb; }

.c-accordion-block.is-active .c-accordion-block__header::after {
  transform: translateY(-50%) rotate(180deg); }

.c-accordion-block.is-disabled .c-accordion-block__header {
  cursor: not-allowed; }

.c-accordion-block.is-disabled.is-active .c-accordion-block__header {
  cursor: auto; }

.c-accordion-block--padding-small .c-accordion-block__header {
  padding: 16px;
  padding-right: 36px; }
  .c-accordion-block--padding-small .c-accordion-block__header::after {
    right: 16px; }

.c-accordion-block--outline-center .c-accordion-block__header {
  background-color: #ffffff;
  color: #000000;
  box-shadow: inset 0 0 0 4px #000000;
  text-align: center; }

.c-accordion-block--raised {
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.21);
  margin-bottom: 16px;
  position: relative;
  z-index: 1;
  border-radius: 12px;
  border: 1px solid #e5e5e5;
  background: #ffffff; }
  .c-accordion-block--raised .c-accordion-block__header {
    padding-right: 16px;
    margin-bottom: 0;
    color: inherit;
    background: none;
    text-align: left;
    overflow: hidden; }
    .c-accordion-block--raised .c-accordion-block__header:after {
      display: none; }
  .c-accordion-block--raised .c-accordion-block__title {
    font-size: 16px;
    line-height: 24px;
    font-family: "futura-pt", sans-serif;
    font-weight: 500;
    margin-bottom: 8px;
    display: inline-block;
    position: relative;
    transform: translateX(-50%);
    left: 50%;
    font-size: 16px;
    line-height: 24px;
    font-family: "futura-pt", sans-serif;
    font-weight: 500;
    margin-bottom: 8px;
    margin-bottom: 0;
    text-transform: none;
    transition: all 300ms ease; }
    @media (min-width: 980px) {
      .c-accordion-block--raised .c-accordion-block__title {
        font-size: 20px;
        line-height: 28px; } }
    @media (min-width: 980px) {
      .c-accordion-block--raised .c-accordion-block__title {
        font-size: 20px;
        line-height: 28px; } }
  .c-accordion-block--raised .c-accordion-block__subtitle {
    position: relative;
    transform: translateX(-50%);
    left: 50%;
    margin: 0;
    text-align: center; }
  .c-accordion-block--raised .c-accordion-block__meta {
    max-height: 0;
    opacity: 0;
    overflow: hidden;
    transition: all 300ms ease-in-out; }
  .c-accordion-block--raised .c-accordion-block__content {
    background: none; }
  .c-accordion-block--raised .c-accordion-block__content-inner {
    border: 0;
    padding-top: 0; }
  .c-accordion-block--raised.is-active {
    z-index: 10; }
    .c-accordion-block--raised.is-active .c-accordion-block__header {
      text-align: left; }
    .c-accordion-block--raised.is-active .c-accordion-block__title {
      margin-bottom: 16px;
      font-size: 22px;
      line-height: 29px;
      margin-bottom: 0;
      color: #f2663b;
      left: 0;
      transform: translateX(0); }
      @media (min-width: 980px) {
        .c-accordion-block--raised.is-active .c-accordion-block__title {
          font-size: 28px;
          line-height: 40px; } }
    .c-accordion-block--raised.is-active .c-accordion-block__meta {
      max-height: 24px;
      opacity: 1; }
  .c-accordion-block--raised.is-disabled {
    color: #757575; }
  .c-accordion-block--raised.is-not-allowed {
    box-shadow: none;
    color: #000000;
    background: #f8f8f8; }

@media (min-width: 540px) {
  .c-accordion-block__header {
    padding: 24px;
    padding-right: 60px; }
    .c-accordion-block__header::after {
      right: 24px; }
  .c-accordion-block__content-inner {
    padding: 24px; }
  .c-accordion-block--raised .c-accordion-block__header {
    padding-right: 24px; } }

@media (min-width: 980px) {
  .c-accordion-block__indent {
    padding-left: 48px; }
    .c-accordion-block__indent--left-right {
      padding-right: 48px; }
  .c-accordion-block--show-accordion-content-on-desktop .c-accordion-block__header {
    display: none; }
  .c-accordion-block--show-accordion-content-on-desktop .c-accordion-block__content {
    height: auto; }
    .c-accordion-block--show-accordion-content-on-desktop .c-accordion-block__content .c-accordion-block__content-inner {
      padding: 0;
      border: 0; }
  .c-accordion-block--raised .c-accordion-block__header {
    padding-top: 28px; }
  .c-accordion-block--raised .c-accordion-block__header,
  .c-accordion-block--raised .c-accordion-block__content-inner {
    padding-left: 36px;
    padding-right: 36px; }
  .c-accordion-block--padding-0 .c-accordion-block__content-inner {
    padding-left: 0;
    padding-right: 0; }
  .c-accordion-block--padding-90 .c-accordion-block__content-inner {
    padding-left: 90px;
    padding-right: 90px; } }

/*doc
---
title: accordion block
name: accordion-block
category: Components
---
Please note the state modifier will not perform all the functionality as a click event is needed to run the JS slideToggle function.

```html_example
<section class="c-accordion-block js-accordion-block">
    <header class="c-accordion-block__header js-accordion-header">
        <h2 class="c-accordion-block__title">
            January 2018
        </h2>
        <p class="c-accordion-block__meta">Step 1 of 2</p>
    </header>
    <div class="c-accordion-block__content">
        <div class="c-accordion-block__content-inner">
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nostrum consequuntur vero quaerat veritatis dolore, similique facilis ea, tempora, eaque reiciendis, quia nisi. Ut veritatis dicta expedita, corporis hic libero accusantium.
        </div>
    </div>
</section>

<section class="c-accordion-block js-accordion-block">
    <header class="c-accordion-block__header js-accordion-header">
        <h2 class="c-accordion-block__title">
            January 2018
        </h2>
    </header>
    <div class="c-accordion-block__content">
        <div class="c-accordion-block__content-inner">
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nostrum consequuntur vero quaerat veritatis dolore, similique facilis ea, tempora, eaque reiciendis, quia nisi. Ut veritatis dicta expedita, corporis hic libero accusantium.
        </div>
    </div>
</section>

```

<div class="c-sg-modifier-wrap js-modifier-wrap">
    <div>
        <button>is-active</button>
    </div>
    <div>
        <button>c-accordion-block--outline-center</button>
    </div>
    <div>
        <button>c-accordion-block--padding-small</button>
    </div>
    <div>
        <button>c-accordion-block--padding-0</button>
        <button>c-accordion-block--padding-90</button>
    </div>
    <div>
        <button>c-accordion-block--show-accordion-content-on-desktop</button>
    </div>
    <div>
        <button>c-accordion-block--raised</button>
    </div>
    <div>
        <button>is-disabled</button>
    </div>
</div>
*/
.c-account-balance {
  position: relative; }
  .c-account-balance__main-balance {
    margin-bottom: 16px;
    font-size: 18px;
    line-height: 26px;
    margin: 0;
    padding-bottom: 16px;
    text-align: left;
    color: #000000;
    position: relative; }
    @media (min-width: 980px) {
      .c-account-balance__main-balance {
        font-size: 24px;
        line-height: 36px; } }
  .c-account-balance__bracket {
    transition: width .9s ease-in;
    width: 0;
    height: 2px;
    position: absolute;
    left: 0;
    bottom: 12px;
    background-color: #000000; }
    .c-account-balance__bracket:before, .c-account-balance__bracket:after {
      content: '';
      width: 2px;
      height: 6px;
      position: absolute;
      left: 0;
      bottom: -6px;
      background-color: #000000; }
    .c-account-balance__bracket:after {
      left: auto;
      right: 0; }
  .c-account-balance--total-bracket {
    top: -6px; }
    .c-account-balance--total-bracket:before, .c-account-balance--total-bracket:after {
      top: -6px;
      bottom: auto; }
  .c-account-balance__bar {
    animation: backgroundForwards 2s linear infinite;
    height: 24px;
    display: block;
    margin-bottom: 8px;
    width: 100%;
    overflow: hidden;
    background: linear-gradient(135deg, rgba(209, 211, 212, 0.8) 0%, rgba(255, 255, 255, 0.8) 50%, rgba(209, 211, 212, 0.8) 100%);
    background-size: 200% 200%;
    white-space: nowrap;
    font-size: 0; }
  .c-account-balance__section {
    transition: width .4s ease-in-out .4s;
    height: 24px;
    width: 0;
    display: inline-block; }
  .c-account-balance--main-account-section {
    background: #f2663b; }
  .c-account-balance--extra-accounts-section {
    background: #fcded5; }
  .c-account-balance--pending-section {
    background: #afafaf; }
  .c-account-balance__balance {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 8px;
    color: #f2663b;
    display: inline-block;
    transition: opacity .25s ease-in;
    line-height: 1;
    opacity: 0; }
    @media (min-width: 980px) {
      .c-account-balance__balance {
        font-size: 20px;
        line-height: 28px; } }
  .c-account-balance__extra-account-link {
    color: #f2663b; }
    .c-account-balance__extra-account-link:hover {
      color: #ea4310; }
  .c-account-balance--main-account-balance {
    float: left; }
  .c-account-balance--extra-account-balance {
    margin: 0;
    position: absolute;
    text-align: right; }
  .c-account-balance--pending-balance {
    color: #afafaf;
    float: right; }
  .c-account-balance--total-balance {
    margin: 36px 0 0; }
  .c-account-balance--header .c-account-balance__main-balance,
  .c-account-balance--header .c-account-balance__extra-account-link,
  .c-account-balance--header .c-account-balance__balance {
    color: #ffffff; }
  .c-account-balance--header .c-account-balance__bracket {
    background-color: #ffffff; }
    .c-account-balance--header .c-account-balance__bracket:before, .c-account-balance--header .c-account-balance__bracket:after {
      background-color: #ffffff; }
  .c-account-balance--header .c-account-balance__bar {
    border: 2px solid #ffffff;
    background: linear-gradient(135deg, rgba(234, 67, 16, 0.8) 0%, rgba(239, 144, 115, 0.8) 50%, rgba(234, 67, 16, 0.8) 100%);
    background-size: 200% 200%; }
  @media (min-width: 980px) {
    .c-account-balance__main-balance {
      padding-bottom: 20px; }
    .c-account-balance__bracket {
      bottom: 16px; }
    .c-account-balance__balance {
      line-height: 1; }
    .c-account-balance--total-balance {
      margin: 48px 0 0; } }

/*doc
---
title: account balance
name: account-balance
category: Components
---

```html_example
    <div class="c-account-balance js-account-balance js-balance-ls">

        <div class="c-reveal-vertical js-reveal is-revealed" data-event-trigger="" data-reveal-height="64" style="">

            <div class="c-reveal-vertical__content">

                <h3 class="c-account-balance__main-balance">
                    Available Balance
                    £<span class="js-available-balance-figure js-balance-count-up tt-available-balance">900.00</span>
                    <span class="c-account-balance__bracket js-available-bracket" style="width: 90%;"></span>
                </h3>

                <div class="u-clearfix">

                    <p class="c-account-balance__balance c-account-balance--main-account-balance js-main-account-balance" style="opacity: 1;">
                        Main Account £<span class="js-main-account-balance-figure tt-main-account-balance">400.00</span>
                    </p>

                    <p class="c-account-balance__balance c-account-balance--pending-balance js-pending-balance" style="opacity: 1;">
                        Pending £<span class="js-pending-balance-figure tt-pending-balance">100.00</span>
                    </p>

                </div>

            </div>

        </div>

        <div class="c-account-balance__bar js-balance-bar">

            <div class="c-account-balance__section c-account-balance--main-account-section js-main-account-section" style="width: calc(40% + 1px);"></div>

            <div class="c-account-balance__section c-account-balance--extra-accounts-section js-extra-section" style="width: 50%;"></div>

            <div class="c-account-balance__section c-account-balance--pending-section js-pending-section" style="width: 10%;"></div>

        </div>

        <div class="c-reveal-vertical js-reveal is-revealed" data-event-trigger="" data-reveal-height="56" style="">

            <div class="c-reveal-vertical__content">

                <p class="c-account-balance__balance c-account-balance--extra-account-balance js-extra-account-balance" style="right: 10%; opacity: 1;">
                    Extra Accounts £<span class="js-extra-account-balance-figure tt-extra-account-balance">500.00</span>
                </p>

                <h3 class="c-account-balance__main-balance c-account-balance--total-balance">
                    <span class="c-account-balance__bracket c-account-balance--total-bracket js-total-bracket" style="width: 100%;"></span>
                    Total Balance
                    £<span class="js-total-balance-figure js-balance-count-up tt-total-balance">1000.00</span>
                </h3>

            </div>

        </div>
    </div>
```

*/
/*------------------------------------*\
    account listing
\*------------------------------------*/
.c-account-listing {
  display: flex;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 12px; }
  .c-account-listing__block {
    color: #000000;
    background-color: #ffffff;
    border: 1px solid #e5e5e5;
    border-radius: 16px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.21);
    padding: 0;
    flex: 1 1 auto;
    transition: all 300ms ease-in-out;
    min-width: 0;
    overflow: hidden; }
    .c-account-listing__block:hover, .c-account-listing__block:focus, .c-account-listing__block:focus-within, .c-account-listing__block:active {
      border-color: #f2663b;
      box-shadow: 0px 2px 4px rgba(234, 67, 16, 0.21); }
  .c-account-listing__message-wrap {
    height: 0;
    overflow: hidden;
    transition: height 300ms ease-in-out;
    background-color: #cfe4ba; }
    .c-account-listing__message-wrap--error {
      background-color: #FABCCC; }
  .c-account-listing__message {
    padding: 8px;
    height: 100%; }
  .c-account-listing__main-content {
    height: 56px;
    width: 100%;
    padding: 8px;
    cursor: pointer;
    background-color: #ffffff;
    transition: background-color 300ms ease-in-out; }
    @media (min-width: 980px) {
      .c-account-listing__main-content {
        height: 76px; } }
    .c-account-listing__main-content:hover, .c-account-listing__main-content:focus {
      background-color: #fef8f7; }
      .c-account-listing__main-content:hover ~ .c-account-listing__dropdown-content-wrap, .c-account-listing__main-content:focus ~ .c-account-listing__dropdown-content-wrap {
        background-color: #fef8f7; }
  .c-account-listing__main-content-inner {
    display: flex;
    align-items: center;
    height: 100%; }
  .c-account-listing__info-wrap {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    flex-grow: 1;
    min-width: 0;
    margin-right: 8px; }
    .c-account-listing__info-wrap--no-direct-debit {
      justify-content: center; }
      .c-account-listing__info-wrap--no-direct-debit .c-account-listing__details {
        display: none; }
  .c-account-listing__name {
    font-size: 16px;
    line-height: 24px;
    font-family: "futura-pt", sans-serif;
    font-weight: 500;
    margin: 0;
    text-align: left;
    line-height: 1.2;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden; }
    @media (min-width: 980px) {
      .c-account-listing__name {
        font-size: 20px;
        line-height: 30px; } }
  .c-account-listing__details {
    color: #f2663b;
    margin: 0;
    line-height: 1;
    text-align: left; }
  .c-account-listing__account-number, .c-account-listing__sort-code {
    font-size: 14px;
    line-height: 22px;
    font-family: "futura-pt", sans-serif;
    font-weight: 500;
    display: inline-block;
    margin: 0;
    line-height: 1; }
    @media (min-width: 980px) {
      .c-account-listing__account-number, .c-account-listing__sort-code {
        font-size: 16px;
        line-height: 26px; } }
  .c-account-listing__account-number {
    padding-right: 4px; }
  .c-account-listing__sort-code {
    padding-left: 4px; }
  .c-account-listing__amount {
    font-size: 16px;
    line-height: 24px;
    font-family: "futura-pt", sans-serif;
    font-weight: 500;
    margin: 0; }
    @media (min-width: 980px) {
      .c-account-listing__amount {
        font-size: 20px;
        line-height: 30px; } }
  .c-account-listing__dropdown-content-wrap {
    height: 0;
    overflow: hidden;
    transition: height 300ms ease-in-out, background-color 300ms ease-in-out; }
  .c-account-listing__dropdown-hr, .c-account-listing__dropdown-content {
    opacity: 0;
    transition: opacity 300ms ease-in-out; }
  .c-account-listing__dropdown-hr {
    padding: 0 8px 8px; }
  .c-account-listing__dropdown-content {
    padding: 0 8px 8px; }
  .c-account-listing__dropdown-button {
    font-family: "futura-pt", sans-serif;
    font-weight: 700;
    display: inline-block;
    padding: 8px 20px;
    text-decoration: none;
    text-align: center;
    margin-bottom: 16px;
    transition-property: background-color, color, transform, box-shadow, border-color;
    transition-duration: .2s, .2s, .2s, .2s;
    transition-timing-function: ease-in-out, ease-in-out, ease-in-out, ease-in-out;
    height: 56px;
    flex-shrink: 0;
    margin: 0;
    margin-left: 8px;
    padding: 0;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.21);
    color: #000000;
    background-color: #ebebeb;
    transition: margin 300ms ease-in-out, background-color 300ms ease-in-out, width 300ms ease-in-out;
    overflow: hidden; }
    @media (min-width: 980px) {
      .c-account-listing__dropdown-button {
        height: 76px; } }
    .c-account-listing__dropdown-button:hover {
      color: #000000;
      background-color: #d1d3d4; }
    .c-account-listing__dropdown-button:focus {
      outline: 1px dotted #afafaf; }
    .c-account-listing__dropdown-button.is-disabled:hover {
      background-color: #ebebeb; }
  .c-account-listing__dropdown-button-inner {
    padding: 12px; }
    .c-account-listing__dropdown-button-inner::before {
      content: "";
      font-family: 'ffreesicons', sans-serif;
      font-weight: 500;
      font-style: normal;
      font-size: 20px;
      display: inline-block;
      transition: transform 300ms ease-in-out; }
  .c-account-listing--dropdown-btn-hidden .c-account-listing__dropdown-button {
    margin-left: 0;
    width: 0; }
  .c-account-listing--with-message .c-account-listing__message-wrap {
    height: 56px; }
    @media (min-width: 980px) {
      .c-account-listing--with-message .c-account-listing__message-wrap {
        height: 76px; } }
  .c-account-listing--with-message .c-account-listing__dropdown-button {
    margin-top: 56px; }
  .c-account-listing.is-expanded .c-account-listing__dropdown-content-wrap {
    height: auto;
    overflow: visible; }
  .c-account-listing.is-expanded .c-account-listing__dropdown-hr,
  .c-account-listing.is-expanded .c-account-listing__dropdown-content {
    opacity: 1; }
  .c-account-listing.is-expanded .c-account-listing__dropdown-content {
    transition: opacity 300ms ease-in-out 300ms; }
  .c-account-listing.is-expanded .c-account-listing__dropdown-button-inner::before {
    transform: rotate(180deg); }
  @media (min-width: 540px) {
    .c-account-listing__account-number {
      padding-right: 8px; }
    .c-account-listing__sort-code {
      padding-left: 8px; } }
  @media (min-width: 980px) {
    .c-account-listing__block.is-selected-desktop {
      background-color: #fef8f7;
      border-color: #f2663b;
      box-shadow: 0px 2px 4px rgba(234, 67, 16, 0.21); }
      .c-account-listing__block.is-selected-desktop .c-account-listing__main-content {
        background-color: #fef8f7; }
    .c-account-listing__message {
      padding: 12px 8px; }
    .c-account-listing__main-content {
      padding: 12px 8px; }
    .c-account-listing__name {
      line-height: 1.2; }
    .c-account-listing__account-number, .c-account-listing__sort-code {
      line-height: 1; }
    .c-account-listing__account-number {
      padding-right: 4px; }
    .c-account-listing__sort-code {
      padding-left: 4px; }
    .c-account-listing__dropdown-hr {
      padding: 0 12px 8px 12px 8px; }
    .c-account-listing__dropdown-content {
      padding: 0 12px 8px 12px 8px; }
    .c-account-listing__dropdown-button-inner {
      padding: 12px; }
    .c-account-listing--with-message .c-account-listing__dropdown-button {
      margin-top: 76px; } }
  @media (min-width: 1080px) {
    .c-account-listing__message {
      padding: 12px; }
    .c-account-listing__main-content {
      padding: 12px; }
    .c-account-listing__account-number {
      padding-right: 12px; }
    .c-account-listing__sort-code {
      padding-left: 12px; }
    .c-account-listing__dropdown-hr {
      padding: 0 12px 12px; }
    .c-account-listing__dropdown-content {
      padding: 0 12px 12px; }
    .c-account-listing__dropdown-button-inner {
      padding: 16px; } }

/*doc
---
title: account listing
name: account-listing
category: Components
---

```html_example
<div class="c-account-listing js-account-listing">
    <div class="c-account-listing__block">
        <div class="c-account-listing__message-wrap">
            <div class="c-account-listing__message">
                Message text here
            </div>
        </div>
        <button class="c-account-listing__main-content" type="button">
            <div class="c-account-listing__main-content-inner">
                <div class="c-account-listing__info-wrap">
                    <h3 class="c-account-listing__name">
                        Main Account
                    </h3>
                    <div class="c-account-listing__details">
                        <h5 class="c-account-listing__account-number">12345678</h5>|<h5 class="c-account-listing__sort-code">21-45-56</h5>
                    </div>
                </div>
                <h4 class="c-account-listing__amount">
                    £80.00
                </h4>
            </div>
        </button>
        <div class="c-account-listing__dropdown-content-wrap js-dropdown-content-wrap">
            <div>
                <div class="c-account-listing__dropdown-hr">
                    <hr class="c-hr c-hr--full-width c-hr--margin-top-0 c-hr--margin-bottom-0" />
                </div>
                <div class="c-account-listing__dropdown-content">
                    Test dropdown content
                </div>
            </div>
        </div>
    </div>
    <button class="c-account-listing__dropdown-button js-listing-dropdown-btn">
        <div class="c-account-listing__dropdown-button-inner js-listing-dropdown-btn-inner"></div>
    </button>
</div>

```
<div class="c-sg-modifier-wrap js-modifier-wrap">
    <div>
        <button data-target="c-account-listing__block">is-selected-desktop</button>
    </div>
    <div>
        <button>is-expanded</button>
    </div>
    <div>
        <button>c-account-listing--dropdown-btn-hidden</button>
    </div>
    <div>
        <button>c-account-listing--with-message</button>
    </div>
    <div>
        <button data-target="c-account-listing__message-wrap">c-account-listing__message-wrap--error</button>
    </div>
    <div>
        <button data-target="c-account-listing__info-wrap">c-account-listing__info-wrap--no-direct-debit</button>
    </div>
</div>
*/
.c-accounts-sidebar {
  height: 100%; }
  .c-accounts-sidebar__section-wrapper {
    padding: 16px 8px; }
    .c-accounts-sidebar__section-wrapper--accounts-list {
      background-color: #ffffff; }
    .c-accounts-sidebar__section-wrapper--hr {
      display: none;
      padding-top: 0;
      padding-bottom: 0; }
  .c-accounts-sidebar__section {
    max-width: 1440px;
    margin-right: auto;
    margin-left: auto;
    padding-right: 24px;
    padding-left: 24px;
    min-width: 0;
    max-width: 420px;
    padding: 0; }
  @media (min-width: 540px) {
    .c-accounts-sidebar__section-wrapper {
      padding: 24px; }
      .c-accounts-sidebar__section-wrapper--accounts-list {
        background-color: transparent; }
      .c-accounts-sidebar__section-wrapper--hr {
        display: block;
        padding-top: 0;
        padding-bottom: 0; }
        .c-accounts-sidebar__section-wrapper--hr .c-accounts-sidebar__section {
          padding: 0 28px; }
    .c-accounts-sidebar__section--hr {
      display: block; } }
  @media (min-width: 980px) {
    .c-accounts-sidebar__section-wrapper {
      padding: 24px 20px; }
      .c-accounts-sidebar__section-wrapper--hr {
        padding-top: 0;
        padding-bottom: 0; }
    .c-accounts-sidebar__section {
      max-width: none; } }
  @media (min-width: 1080px) {
    .c-accounts-sidebar__section-wrapper {
      padding: 24px; }
      .c-accounts-sidebar__section-wrapper--hr {
        padding-top: 0;
        padding-bottom: 0; } }

/*doc
---
title: accounts sidebar
name: accounts-sidebar
category: Organisms
---

```html_example
<div class="c-accounts-sidebar">

    <div class="c-accounts-sidebar__section-wrapper">
        <div class="c-accounts-sidebar__section">
            <div class="c-account-balance js-accounts-page-balance js-balance-ls">

                <div class="c-reveal-vertical js-reveal is-revealed" data-event-trigger="" data-reveal-height="76" style="">

                    <div class="c-reveal-vertical__content">

                        <h3 class="c-account-balance__main-balance">
                            Available Balance
                            £<span class="js-available-balance-figure js-balance-count-up tt-available-balance">597.72</span>
                            <span class="c-account-balance__bracket js-available-bracket" style="width: 100%;"></span>
                        </h3>

                        <div class="u-clearfix">

                            <p class="c-account-balance__balance c-account-balance--main-account-balance js-main-account-balance" style="opacity: 1;">
                                Main Account £<span class="js-main-account-balance-figure tt-main-account-balance">509.71</span>
                            </p>

                            <p class="c-account-balance__balance c-account-balance--pending-balance js-pending-balance">
                                Pending £<span class="js-pending-balance-figure tt-pending-balance"></span>
                            </p>

                        </div>

                    </div>

                </div>

                <div class="c-account-balance__bar js-balance-bar">

                    <div class="c-account-balance__section c-account-balance--main-account-section js-main-account-section" style="width: calc(85% + 1px);"></div>

                    <div class="c-account-balance__section c-account-balance--extra-accounts-section js-extra-section" style="width: 15%;"></div>

                    <div class="c-account-balance__section c-account-balance--pending-section js-pending-section"></div>

                </div>

                <div class="c-reveal-vertical js-reveal is-revealed" data-event-trigger="" data-reveal-height="96" style="">

                    <div class="c-reveal-vertical__content">

                        <p class="c-account-balance__balance c-account-balance--extra-account-balance js-extra-account-balance" style="right: 0%; opacity: 1;">
                            Extra Accounts £<span class="js-extra-account-balance-figure tt-extra-account-balance">88.01</span>
                        </p>

                        <h3 class="c-account-balance__main-balance c-account-balance--total-balance">
                            <span class="c-account-balance__bracket c-account-balance--total-bracket js-total-bracket" style="width: 100%;"></span>
                            Total Balance
                            £<span class="js-total-balance-figure js-balance-count-up tt-total-balance">597.72</span>
                        </h3>

                    </div>

                </div>
            </div>
        </div>
    </div>

    <div class="c-accounts-sidebar__section-wrapper c-accounts-sidebar__section-wrapper--hr">
        <div class="c-accounts-sidebar__section">
            <hr class="c-hr c-hr--full-width c-hr--margin-top-0 c-hr--margin-bottom-0 u-color-grey-mid-bg">
        </div>
    </div>

    <div class="c-accounts-sidebar__section-wrapper c-accounts-sidebar__section-wrapper--accounts-list">
        <div class="c-accounts-sidebar__section">
            <div class="js-accounts-list">
                <div class="c-account-listing js-account-listing js-main-account">
                    <div class="c-account-listing__block">
                        <div class="c-account-listing__message-wrap">
                            <div class="c-account-listing__message">
                                Message text here
                            </div>
                        </div>
                        <button class="c-account-listing__main-content" type="button">
                            <div class="c-account-listing__main-content-inner">
                                <div class="c-account-listing__info-wrap">
                                    <h3 class="c-account-listing__name">
                                        Main Account
                                    </h3>
                                    <div class="c-account-listing__details">
                                        <h5 class="c-account-listing__account-number">12345678</h5>|<h5 class="c-account-listing__sort-code">21-45-56</h5>
                                    </div>
                                </div>
                                <h4 class="c-account-listing__amount">
                                    £80.00
                                </h4>
                            </div>
                        </button>
                        <div class="c-account-listing__dropdown-content-wrap js-dropdown-content-wrap">
                            <div>
                                <div class="c-account-listing__dropdown-hr">
                                    <hr class="c-hr c-hr--full-width c-hr--margin-top-0 c-hr--margin-bottom-0" />
                                </div>
                                <div class="c-account-listing__dropdown-content">
                                    Test dropdown content
                                </div>
                            </div>
                        </div>
                    </div>
                    <button class="c-account-listing__dropdown-button js-listing-dropdown-btn">
                        <div class="c-account-listing__dropdown-button-inner js-listing-dropdown-btn-inner"></div>
                    </button>
                </div>

                <a class="c-btn-block c-btn-block--full-width c-btn-block--margin-0" href="/dashboard/extra-accounts">
                    <span>Manage Extra Accounts</span>
                </a>
            </div>
        </div>
    </div>

    <div class="c-accounts-sidebar__section-wrapper c-accounts-sidebar__section-wrapper--hr">
        <div class="c-accounts-sidebar__section">
            <hr class="c-hr c-hr--full-width c-hr--margin-top-0 c-hr--margin-bottom-0 u-color-grey-mid-bg">
        </div>
    </div>

    <div class="c-accounts-sidebar__section-wrapper">
        <div class="c-accounts-sidebar__section">
            <div class="c-btn-block-group c-btn-block-group--flex-layout-400 c-btn-block-group--standard-layout-m c-btn-block-group--flex-layout-l">
                <a class="c-btn-block c-btn-block--full-width c-btn-block--margin-right-0" href="/pay-in">
                    How to pay in
                </a>
                <a class="c-btn-block c-btn-block--margin-right-0 c-btn-block--with-subtext" href="/dashboard/my-fees">
                    <span class="c-btn-block__content">
                        Fees &amp; Limits
                        <span class="c-btn-block__subtext">Next <span class="js-dashboard-price-point" data-product-handle="ACC_TWO_500">£5</span> bill: <span class="js-billing-date">14/03/17</span></span>
                    </span>
                </a>
            </div>
        </div>
    </div>

</div>

```

*/
.c-app-badges {
  width: 100%;
  padding: 12px 0 24px;
  clear: left;
  float: left; }
  .c-app-badges__link {
    margin-bottom: 12px;
    display: block; }
  .c-app-badges__img {
    margin: 0 auto;
    display: block;
    width: auto; }
  .c-app-badges--no-float {
    float: none;
    padding-bottom: 0; }
  @media (min-width: 540px) {
    .c-app-badges__link {
      margin-right: 12px;
      margin-bottom: 24px;
      display: inline-block; }
    .c-app-badges__img {
      margin: 0; }
    .c-app-badges--margin-bottom-0 .c-app-badges__link {
      margin-bottom: 0; } }

/*doc
---
title: App Badges
name: app-badges
category: Components
---

```html_example
<div class="c-app-badges">

    <a class="c-app-badges__link" href="https://itunes.apple.com/gb/app/balance/id1224502407?mt=8" target="_blank">
        <img class="c-app-badges__img" src="img/app_store_badge_us-uk.svg" title="iOS App Store Badge" height="55" alt="apple app store link">
    </a>

    <a class="c-app-badges__link" href="https://play.google.com/store/apps/details?id=uk.uaccount.android" target="_blank">
        <img class="c-app-badges__img" src="img/google-play-badge.svg" title="Google Play Store Badge" height="55" alt="google play store link">
    </a>

</div>
```
<div class="c-sg-modifier-wrap js-modifier-wrap">
    <div>
        <button>c-app-badges--margin-bottom-0</button>
    </div>
</div>
*/
.c-bottom-nav {
  margin: 0;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 60px;
  z-index: 39;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.25); }
  .c-bottom-nav__list {
    list-style: none;
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100%;
    display: flex; }
  .c-bottom-nav__list-item {
    flex-basis: 0;
    flex-grow: 1;
    height: 100%;
    border: 1px solid #d1d3d4;
    border-top-width: 2px;
    border-bottom-width: 0; }
    .c-bottom-nav__list-item.is-selected .c-bottom-nav__link {
      background-color: #fef8f7;
      color: #f2663b; }
      .c-bottom-nav__list-item.is-selected .c-bottom-nav__link:before, .c-bottom-nav__list-item.is-selected .c-bottom-nav__link:after {
        background-color: #f2663b; }
      .c-bottom-nav__list-item.is-selected .c-bottom-nav__link:after {
        transform: translateY(-4px); }
    .c-bottom-nav__list-item.is-selected .c-bottom-nav__sub-menu-list {
      transform: translateY(-100%);
      visibility: visible;
      transition-delay: 0s, 0s; }
  .c-bottom-nav__link {
    text-decoration: none;
    color: inherit;
    font-size: 10px;
    line-height: 13px;
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background-color: #ffffff;
    color: #f2663b;
    height: 100%;
    transition: color 300ms ease-in-out, background-color 300ms ease-in-out; }
    .c-bottom-nav__link--has-sub-menu:before, .c-bottom-nav__link--has-sub-menu:after {
      content: '';
      position: absolute;
      left: 3px;
      right: 3px;
      display: block;
      height: 2px;
      background-color: #f2663b; }
    .c-bottom-nav__link--has-sub-menu:before {
      top: 2px;
      transition: background-color 300ms ease-in-out; }
    .c-bottom-nav__link--has-sub-menu:after {
      top: 6px;
      transition: background-color 300ms ease-in-out, transform 300ms ease-in-out; }
    .c-bottom-nav__link.is-current {
      background-color: #f2663b;
      color: #ffffff; }
      .c-bottom-nav__link.is-current:before, .c-bottom-nav__link.is-current:after {
        background-color: #ffffff; }
  .c-bottom-nav__link-icon:before {
    font-size: 20px;
    line-height: 26px;
    display: block;
    font-family: 'ffreesicons', sans-serif;
    font-weight: 500;
    font-style: normal; }
  .c-bottom-nav__link-icon--u-logo:before {
    font-family: 'u-font', sans-serif;
    font-weight: 300;
    content: 'U'; }
  .c-bottom-nav__link-icon--pounds-sign:before {
    font-family: "futura-pt", sans-serif;
    font-weight: 800;
    content: '£'; }
  .c-bottom-nav__notification-holder {
    position: absolute;
    right: 1px;
    top: -10px;
    z-index: 2; }
  .c-bottom-nav__sub-menu-list {
    padding: 0;
    margin: 0;
    list-style: none;
    position: fixed;
    top: calc(100% - 60px);
    left: 0;
    height: calc(100% - 60px - 60px);
    transform: translateY(0);
    width: 100%;
    background-color: #f1f1f1;
    z-index: -1;
    overflow-y: auto;
    visibility: hidden;
    transition: transform 300ms ease-in-out, visibility 0s linear 300ms; }
  .c-bottom-nav__sub-menu-list-item {
    display: block;
    margin: 0;
    border-bottom: 2px solid #d1d3d4;
    position: relative; }
    .c-bottom-nav__sub-menu-list-item--footer-item {
      border-bottom: 0px none transparent;
      padding: 24px 36px;
      text-align: center; }
    .c-bottom-nav__sub-menu-list-item.is-hidden {
      display: none; }
  .c-bottom-nav__sub-menu-notification-holder {
    position: absolute;
    left: calc(36px/2 - 3px);
    top: 50%;
    transform: translateY(-50%); }
  .c-bottom-nav__sub-menu-link {
    text-decoration: none;
    color: inherit;
    font-size: 16px;
    line-height: 20px;
    font-family: "futura-pt", sans-serif;
    font-weight: 500;
    display: block;
    padding: 24px 36px;
    color: #f2663b;
    transition: color 300ms ease-in-out, background-color 300ms ease-in-out; }
    .c-bottom-nav__sub-menu-link:focus {
      color: #f2663b;
      background-color: #fcded5; }
    .c-bottom-nav__sub-menu-link.is-current {
      color: #ffffff;
      background-color: #f2663b; }
      .c-bottom-nav__sub-menu-link.is-current:focus {
        color: #ffffff;
        background-color: #f2663b; }
  .u-blur-content .c-bottom-nav {
    transition: filter .25s ease-in-out;
    filter: blur(20px); }
  .l-reduced-header .c-bottom-nav, .l-no-navigation .c-bottom-nav {
    display: none; }
  @media (min-width: 980px) {
    .c-bottom-nav {
      display: none; } }

.styleguide .c-bottom-nav {
  position: absolute; }
  .styleguide .c-bottom-nav__sub-menu-list {
    position: absolute;
    height: 607px; }

/*doc
---
title: bottom nav
name: bottom-nav
category: Global
---

Note - the second <code>is-current</code> state modifier affects the nav items in the sub menu
<br/>
(Also - this component is only visible on mobile)

```html_example
<nav class="c-bottom-nav js-bottom-nav" data-parent-classes="u-sg-height-667 u-sg-overflow-hidden">
    <ul class="c-bottom-nav__list">
        <li class="c-bottom-nav__list-item">
            <a class="c-bottom-nav__link" href="/">
                <span class="c-bottom-nav__link-icon c-bottom-nav__link-icon--u-logo"></span>
                Home
            </a>
        </li>
        <li class="c-bottom-nav__list-item">
            <a class="c-bottom-nav__link" href="/pricing">
                <span class="c-bottom-nav__link-icon c-bottom-nav__link-icon--pounds-sign"></span>
                Pricing
            </a>
        </li>
        <li class="c-bottom-nav__list-item">
            <a class="c-bottom-nav__link" href="https://medium.com/u-account">
                <span class="c-bottom-nav__link-icon u-before-icon-users-group"></span>
                Blog
            </a>
        </li>
        <li class="c-bottom-nav__list-item js-bottom-nav-list-item">
            <a class="c-bottom-nav__link c-bottom-nav__link--has-sub-menu js-bottom-nav-link">
                <span class="c-bottom-nav__link-icon u-before-icon-info-fill"></span>
                Help
            </a>
            <ul class="c-bottom-nav__sub-menu-list js-bottom-nav-sub-menu">
                <li class="c-bottom-nav__sub-menu-list-item">
                    <a class="c-bottom-nav__sub-menu-link" href="/help">Help</a>
                </li>
                <li class="c-bottom-nav__sub-menu-list-item c-bottom-nav__sub-menu-list-item--hidden js-notification-link">
                    <div class="c-bottom-nav__sub-menu-notification-holder">
                        <div class="c-notification c-notification--dot"></div>
                    </div>
                    <a class="c-bottom-nav__sub-menu-link" href="/help?current-panel=contact"><span class="js-notifications-count">2</span> Unread Messages</a>
                </li>
                <li class="c-bottom-nav__sub-menu-list-item">
                    <a class="c-bottom-nav__sub-menu-link" href="/contact-us">Contact</a>
                </li>
                <li class="c-bottom-nav__sub-menu-list-item">
                    <a class="c-bottom-nav__sub-menu-link" href="/service-status">Service Status</a>
                </li>
            </ul>
        </li>
        <li class="c-bottom-nav__list-item js-bottom-nav-list-item">
            <a class="c-bottom-nav__link c-bottom-nav__link--has-sub-menu js-bottom-nav-link">
                <span class="c-bottom-nav__link-icon u-before-icon-plus"></span>
                More
            </a>
            <ul class="c-bottom-nav__sub-menu-list">
                <li class="c-bottom-nav__sub-menu-list-item">
                    <a class="c-bottom-nav__sub-menu-link">About</a>
                </li>
                <li class="c-bottom-nav__sub-menu-list-item">
                    <a class="c-bottom-nav__sub-menu-link">Careers</a>
                </li>
                <li class="c-bottom-nav__sub-menu-list-item">
                    <a class="c-bottom-nav__sub-menu-link">Terms &amp; Policies</a>
                </li>
                <li class="c-bottom-nav__sub-menu-list-item">
                    <a class="c-bottom-nav__sub-menu-link">Press</a>
                </li>
            </ul>
        </li>
    </ul>
</nav>

```
<div class="c-sg-modifier-wrap js-modifier-wrap">
    <div>
        <button data-target="c-bottom-nav__link">is-current</button>
    </div>
    <div>
        <button data-target="c-bottom-nav__list-item">is-selected</button>
    </div>
    <div>
        <button data-target="c-bottom-nav__link">c-bottom-nav__link--has-sub-menu</button>
    </div>
    <div>
        <button data-target="c-bottom-nav__link-icon">c-bottom-nav__link-icon--u-logo</button>
        <button data-target="c-bottom-nav__link-icon">c-bottom-nav__link-icon--pounds-sign</button>
    </div>
    <div>
        <button data-target="c-bottom-nav__sub-menu-link">is-current</button>
    </div>
    <div>
        <button data-target="c-bottom-nav__sub-menu-list-item">c-bottom-nav__sub-menu-list-item--footer-item</button>
    </div>
    <div>
        <button data-target="c-bottom-nav__sub-menu-list-item">is-hidden</button>
    </div>
</div>
*/
/*doc
---
title: Logged In Bottom Nav
name: bottom-nav-logged-in
category: Global
---

```html_example
<nav class="c-bottom-nav js-bottom-nav" data-parent-classes="u-sg-height-667 u-sg-overflow-hidden">
    <ul class="c-bottom-nav__list">

        <li class="c-bottom-nav__list-item js-bottom-nav-list-item">
            <a class="c-bottom-nav__link c-bottom-nav__link--has-sub-menu js-bottom-nav-link {% if in_array(uri, ['/dashboard', '/dashboard/extra-accounts', '/dashboard/money-manager', '/dashboard/statements', '/dashboard/referral', '/points']) %}is-current{% endif %}">
                <span class="c-bottom-nav__link-icon u-before-icon-chart-area"></span>
                Accounts
            </a>
            <ul class="c-bottom-nav__sub-menu-list js-bottom-nav-sub-menu">
                <li class="c-bottom-nav__sub-menu-list-item">
                    <a class="c-bottom-nav__sub-menu-link" href="/dashboard">Overview</a>
                </li>
                <li class="c-bottom-nav__sub-menu-list-item">
                    <a class="c-bottom-nav__sub-menu-link" href="/dashboard/extra-accounts">Extra Accounts</a>
                </li>
                <li class="c-bottom-nav__sub-menu-list-item">
                    <a class="c-bottom-nav__sub-menu-link" href="/dashboard/money-manager">Money Manager</a>
                </li>
                <li class="c-bottom-nav__sub-menu-list-item">
                    <a class="c-bottom-nav__sub-menu-link" href="/dashboard/statements">Statements</a>
                </li>
                <li class="c-bottom-nav__sub-menu-list-item">
                    <a class="c-bottom-nav__sub-menu-link" href="/points">Points</a>
                </li>
                <li class="c-bottom-nav__sub-menu-list-item">
                    <a class="c-bottom-nav__sub-menu-link" href="/dashboard/referral">Refer a friend</a>
                </li>
            </ul>
        </li>

        <li class="c-bottom-nav__list-item">
            <a class="c-bottom-nav__link js-bottom-nav-link" href="/dashboard/payments">
                <span class="c-bottom-nav__link-icon u-before-icon-send"></span>
                Pay
            </a>
        </li>

        <li class="c-bottom-nav__list-item js-bottom-nav-list-item">
            <a class="c-bottom-nav__link c-bottom-nav__link--has-sub-menu js-bottom-nav-link {% if in_array(uri, ['/dashboard/my-cards', '/dashboard/profile']) %}is-current{% endif %}">
                <span class="c-bottom-nav__link-icon u-before-icon-sliders"></span>
                Settings
            </a>
            <ul class="c-bottom-nav__sub-menu-list js-bottom-nav-sub-menu">
                <li class="c-bottom-nav__sub-menu-list-item">
                    <a class="c-bottom-nav__sub-menu-link" href="/dashboard/my-cards">Card</a>
                </li>
                <li class="c-bottom-nav__sub-menu-list-item">
                    <a class="c-bottom-nav__sub-menu-link" href="/dashboard/profile">Profile</a>
                </li>
            </ul>
        </li>

        <li class="c-bottom-nav__list-item js-bottom-nav-list-item">
            <a class="c-bottom-nav__link c-bottom-nav__link--has-sub-menu js-bottom-nav-link {% if in_array(uri, ['/help', '/service-status', '/policies']) %}is-current{% endif %}">
                <span class="c-bottom-nav__link-icon u-before-icon-info-fill"></span>
                Help
                <div class="c-bottom-nav__notification-holder">
                    <div class="c-notification js-notification">
                        <div class="c-notification__number js-notification-count"></div>
                    </div>
                </div>
            </a>
            <ul class="c-bottom-nav__sub-menu-list js-bottom-nav-sub-menu">
                <li class="c-bottom-nav__sub-menu-list-item">
                    <a class="c-bottom-nav__sub-menu-link" href="/help">Help &amp; Support</a>
                </li>
                {# Extra menu item for unread notifications #}
                <li class="c-bottom-nav__sub-menu-list-item is-hidden js-notification-link">
                    <div class="c-bottom-nav__sub-menu-notification-holder">
                        <div class="c-notification c-notification--dot is-shown"></div>
                    </div>
                    <a class="c-bottom-nav__sub-menu-link" href="/help?current-panel=contact"><span class="js-notification-count"></span> Unread Message<span class="js-notification-variable-text"></span></a>
                </li>
                <li class="c-bottom-nav__sub-menu-list-item">
                    <a class="c-bottom-nav__sub-menu-link" href="/service-status">Service Status</a>
                </li>
                <li class="c-bottom-nav__sub-menu-list-item">
                    <a class="c-bottom-nav__sub-menu-link" href="/policies">Policies &amp; Terms</a>
                </li>
            </ul>
        </li>

        <li class="c-bottom-nav__list-item">
            <a class="c-bottom-nav__link js-bottom-nav-link tt-nav-credit" href="/dashboard/credit">
                <span class="c-bottom-nav__link-icon u-before-icon-u-logo-simplified"></span>
                U Credit
            </a>
            <div class="c-bottom-nav__notification-holder">
                <div class="c-notification c-notification--variable-width c-notification--color-1 c-notification--thin-border is-shown">
                    <div class="c-notification__text">New</div>
                </div>
            </div>
        </li>
    </ul>
</nav>
```
<div class="c-sg-modifier-wrap js-modifier-wrap">
    <div>
        <button data-target="c-bottom-nav__link">is-current</button>
    </div>
    <div>
        <button data-target="c-bottom-nav__list-item">is-selected</button>
    </div>
    <div>
        <button data-target="c-bottom-nav__link">c-bottom-nav__link--has-sub-menu</button>
    </div>
    <div>
        <button data-target="c-bottom-nav__link-icon">c-bottom-nav__link-icon--u-logo</button>
        <button data-target="c-bottom-nav__link-icon">c-bottom-nav__link-icon--pounds-sign</button>
    </div>
    <div>
        <button data-target="c-bottom-nav__sub-menu-link">is-current</button>
    </div>
    <div>
        <button data-target="c-bottom-nav__sub-menu-list-item">c-bottom-nav__sub-menu-list-item--footer-item</button>
    </div>
    <div>
        <button data-target="c-bottom-nav__sub-menu-list-item">is-hidden</button>
    </div>
</div>
*/
.c-breadcrumb {
  height: 48px;
  text-align: center; }
  .c-breadcrumb__inner {
    max-width: 1440px;
    margin-right: auto;
    margin-left: auto;
    padding-right: 24px;
    padding-left: 24px;
    min-width: 0;
    position: fixed;
    top: 60px;
    left: 0;
    width: 100%;
    background: #ffffff;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25);
    font-size: 18px;
    z-index: 20; }
    .c-breadcrumb__inner--max-width-960 {
      max-width: 960px; }
    .c-breadcrumb__inner--max-width-1080 {
      max-width: 1080px; }
    .c-breadcrumb__inner--max-width-1300 {
      max-width: 1300px; }
    .c-breadcrumb__inner--max-width-720 {
      max-width: 720px; }
    .c-breadcrumb__inner--max-width-420 {
      max-width: 420px; }
    .u-no-header .c-breadcrumb__inner {
      top: 0; }
    .styleguide .c-breadcrumb__inner {
      position: static; }
  .c-breadcrumb__list {
    list-style: none; }
  .c-breadcrumb__item {
    position: relative;
    display: none;
    margin: 0; }
    .c-breadcrumb__item::before {
      font-family: 'ffreesicons', sans-serif;
      font-weight: 500;
      font-style: normal;
      content: "";
      position: absolute;
      transform: translate3d(0, -50%, 0);
      top: 50%;
      left: 0;
      pointer-events: none; }
    .c-breadcrumb__item::after {
      font-family: 'ffreesicons', sans-serif;
      font-weight: 500;
      font-style: normal;
      content: "";
      display: none;
      color: #757575;
      vertical-align: top;
      margin: 0 4px; }
    .c-breadcrumb__item:nth-last-child(2) {
      display: block; }
  .c-breadcrumb__link {
    display: block;
    padding: 12px 0;
    text-decoration: none;
    color: inherit;
    font-weight: 500; }
  @media (min-width: 800px) {
    .c-breadcrumb {
      height: auto;
      text-align: left; }
      .c-breadcrumb__inner {
        position: static;
        top: auto;
        width: auto;
        padding-top: 52px;
        box-shadow: none;
        font-size: 12px;
        line-height: 16px; } }
    @media (min-width: 800px) and (min-width: 980px) {
      .c-breadcrumb__inner {
        font-weight: 500;
        font-size: 16px;
        line-height: 26px; } }
  @media (min-width: 800px) {
      .c-breadcrumb__list {
        padding: 16px 0; }
      .c-breadcrumb__item {
        display: inline-block; }
        .c-breadcrumb__item::before {
          display: none; }
        .c-breadcrumb__item::after {
          display: inline; }
        .c-breadcrumb__item:nth-last-child(2) {
          display: inline-block; }
        .c-breadcrumb__item:last-child::after {
          display: none; }
      .c-breadcrumb__link {
        display: inline;
        color: #757575;
        padding: 0; }
        .c-breadcrumb__link--current {
          color: #f2663b; } }

/*doc
---
title: breadcrumb
name: breadcrumb
category: Components
---

```html_example
<nav aria-label="breadcrumb" class="c-breadcrumb">
    <h2 class="u-visually-hidden">Breadcrumbs</h2>
    <div class="c-breadcrumb__inner">
        <ol itemscope itemtype="http://schema.org/BreadcrumbList" class="c-breadcrumb__list">
            <li itemprop="itemListElement" itemscope itemtype="http://schema.org/ListItem" class="c-breadcrumb__item">
                <meta itemprop="position" content="1"/>
                <a href="/u-credit/" itemprop="item" class="c-breadcrumb__link">
                    <span itemprop="name"><span class="u-u-in-copy">U</span> Credit</span>
                </a>
            </li>
            <li itemprop="itemListElement" itemscope itemtype="http://schema.org/ListItem" class="c-breadcrumb__item">
                <meta itemprop="position" content="2"/>
                <a href="/u-credit/short-term-loan/" itemprop="item" class="c-breadcrumb__link">
                    <span itemprop="name">Short Term Loan</span>
                </a>
            </li>
            <li itemprop="itemListElement" itemscope itemtype="http://schema.org/ListItem" class="c-breadcrumb__item">
                <meta itemprop="position" content="3"/>
                <a href="/u-credit/short-term-loan/loan-application/" itemprop="item" class="c-breadcrumb__link c-breadcrumb__link--current" aria-current="page">
                    <span itemprop="name">Loan Application</span>
                </a>
            </li>
        </ol>
    </div>
</nav>
```
*/
.c-bullet-list {
  padding: 0;
  margin: 0;
  list-style: none; }
  .c-bullet-list__item {
    position: relative;
    margin-bottom: 12px;
    padding-left: 20px; }
    .c-bullet-list__item::before {
      font-family: 'ffreesicons', sans-serif;
      font-weight: 500;
      font-style: normal;
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      color: #f2663b; }
  .c-bullet-list__title {
    font-size: 16px;
    line-height: 24px;
    font-family: "futura-pt", sans-serif;
    font-weight: 500;
    margin-bottom: 8px; }
    @media (min-width: 980px) {
      .c-bullet-list__title {
        font-size: 20px;
        line-height: 28px; } }
  .c-bullet-list__info {
    display: block;
    font-size: 12px;
    line-height: 18px;
    color: #afafaf; }
    @media (min-width: 980px) {
      .c-bullet-list__info {
        font-size: 14px;
        line-height: 18px; } }
  .c-bullet-list--large .c-bullet-list__item::before {
    font-size: 16px;
    line-height: 24px;
    font-family: "futura-pt", sans-serif;
    font-weight: 500;
    margin-bottom: 8px;
    font-family: 'ffreesicons', sans-serif;
    font-weight: 500;
    font-style: normal; }
    @media (min-width: 980px) {
      .c-bullet-list--large .c-bullet-list__item::before {
        font-size: 20px;
        line-height: 28px; } }
  .c-bullet-list--extra-spacing .c-bullet-list__item {
    margin-top: 20px;
    margin-bottom: 20px;
    padding-left: 28px; }
  @media (min-width: 980px) {
    .c-bullet-list__item {
      padding-left: 36px; }
    .c-bullet-list--columns {
      display: flex;
      flex-wrap: wrap;
      padding-left: 28px;
      padding-right: 28px; }
      .c-bullet-list--columns .c-bullet-list__item {
        width: 30%; }
        .c-bullet-list--columns .c-bullet-list__item:nth-child(3n+2), .c-bullet-list--columns .c-bullet-list__item:nth-child(3n+3) {
          margin-left: 5%; }
    .c-bullet-list--extra-spacing .c-bullet-list__item {
      margin-top: 48px;
      margin-bottom: 48px;
      padding-left: 48px; }
    .c-bullet-list--indented {
      margin-left: 60px; } }

/*doc
---
title: Bullet List
name: bullet-list
category: Components
---

```html_example
<ul class="c-bullet-list">
    <li class="c-bullet-list__item">
        <h3 class="c-bullet-list__title">Contactless Card</h3>
        You'll receive a free contactless card as standard so that you can tap and go with contactless payments.
    </li>
    <li class="c-bullet-list__item">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas maximus convallis ipsum, eget consectetur diam commodo et.
        <span class="c-bullet-list__info">Supplementary text lorem ipsum dolor</span>
    </li>
    <li class="c-bullet-list__item">
        Save £10 or as much as you can into an extra account, to create a small buffer.
    </li>
    <li class="c-bullet-list__item">
        Have at least one monthly direct or STO, which does not bounce.
    </li>
    <li class="c-bullet-list__item u-u-in-copy">
        Use your U Account as your primary day to day account
    </li>
</ul>
```

<div class="c-sg-modifier-wrap js-modifier-wrap">
    <div>
        <button>c-bullet-list--columns</button>
    </div>
    <div>
        <button>c-bullet-list--large</button>
    </div>
    <div>
        <button>c-bullet-list--extra-spacing</button>
    </div>
    <div>
        <button>c-bullet-list--indented</button>
    </div>
</div>
*/
.c-btn-group:after {
  content: "";
  display: table;
  clear: both; }

.c-btn-group .c-btn,
.c-btn-group .c-btn-outline {
  float: left; }

.c-btn-group--margin-right .c-btn,
.c-btn-group--margin-right .c-btn-outline {
  margin-right: 24px; }

.c-btn-block-group .c-btn-block {
  width: 100%; }

.c-btn-block-group.c-btn-block-group--standard-layout {
  display: block; }
  .c-btn-block-group.c-btn-block-group--standard-layout .c-btn-block {
    display: flex;
    flex-grow: 0;
    margin-right: 0; }

.c-btn-block-group.c-btn-block-group--flex-layout {
  display: flex; }
  .c-btn-block-group.c-btn-block-group--flex-layout .c-btn-block {
    display: inline-flex;
    flex-grow: 1;
    margin-right: 8px; }
    .c-btn-block-group.c-btn-block-group--flex-layout .c-btn-block:last-child {
      margin-right: 0; }

@media (min-width: 400px) {
  .c-btn-block-group.c-btn-block-group--standard-layout-400 {
    display: block; }
    .c-btn-block-group.c-btn-block-group--standard-layout-400 .c-btn-block {
      display: flex;
      flex-grow: 0;
      margin-right: 0; }
  .c-btn-block-group.c-btn-block-group--flex-layout-400 {
    display: flex; }
    .c-btn-block-group.c-btn-block-group--flex-layout-400 .c-btn-block {
      display: inline-flex;
      flex-grow: 1;
      margin-right: 8px; }
      .c-btn-block-group.c-btn-block-group--flex-layout-400 .c-btn-block:last-child {
        margin-right: 0; } }

@media (min-width: 540px) {
  .c-btn-block-group.c-btn-block-group--standard-layout-xs {
    display: block; }
    .c-btn-block-group.c-btn-block-group--standard-layout-xs .c-btn-block {
      display: flex;
      flex-grow: 0;
      margin-right: 0; }
  .c-btn-block-group.c-btn-block-group--flex-layout-xs {
    display: flex; }
    .c-btn-block-group.c-btn-block-group--flex-layout-xs .c-btn-block {
      display: inline-flex;
      flex-grow: 1;
      margin-right: 8px; }
      .c-btn-block-group.c-btn-block-group--flex-layout-xs .c-btn-block:last-child {
        margin-right: 0; } }

@media (min-width: 800px) {
  .c-btn-block-group.c-btn-block-group--standard-layout-s {
    display: block; }
    .c-btn-block-group.c-btn-block-group--standard-layout-s .c-btn-block {
      display: flex;
      flex-grow: 0;
      margin-right: 0; }
  .c-btn-block-group.c-btn-block-group--flex-layout-s {
    display: flex; }
    .c-btn-block-group.c-btn-block-group--flex-layout-s .c-btn-block {
      display: inline-flex;
      flex-grow: 1;
      margin-right: 8px; }
      .c-btn-block-group.c-btn-block-group--flex-layout-s .c-btn-block:last-child {
        margin-right: 0; } }

@media (min-width: 980px) {
  .c-btn-block-group.c-btn-block-group--standard-layout-m {
    display: block; }
    .c-btn-block-group.c-btn-block-group--standard-layout-m .c-btn-block {
      display: flex;
      flex-grow: 0;
      margin-right: 0; }
  .c-btn-block-group.c-btn-block-group--flex-layout-m {
    display: flex; }
    .c-btn-block-group.c-btn-block-group--flex-layout-m .c-btn-block {
      display: inline-flex;
      flex-grow: 1;
      margin-right: 8px; }
      .c-btn-block-group.c-btn-block-group--flex-layout-m .c-btn-block:last-child {
        margin-right: 0; } }

@media (min-width: 1080px) {
  .c-btn-block-group.c-btn-block-group--standard-layout-l {
    display: block; }
    .c-btn-block-group.c-btn-block-group--standard-layout-l .c-btn-block {
      display: flex;
      flex-grow: 0;
      margin-right: 0; }
  .c-btn-block-group.c-btn-block-group--flex-layout-l {
    display: flex; }
    .c-btn-block-group.c-btn-block-group--flex-layout-l .c-btn-block {
      display: inline-flex;
      flex-grow: 1;
      margin-right: 8px; }
      .c-btn-block-group.c-btn-block-group--flex-layout-l .c-btn-block:last-child {
        margin-right: 0; } }

@media (min-width: 1200px) {
  .c-btn-block-group.c-btn-block-group--standard-layout-xl {
    display: block; }
    .c-btn-block-group.c-btn-block-group--standard-layout-xl .c-btn-block {
      display: flex;
      flex-grow: 0;
      margin-right: 0; }
  .c-btn-block-group.c-btn-block-group--flex-layout-xl {
    display: flex; }
    .c-btn-block-group.c-btn-block-group--flex-layout-xl .c-btn-block {
      display: inline-flex;
      flex-grow: 1;
      margin-right: 8px; }
      .c-btn-block-group.c-btn-block-group--flex-layout-xl .c-btn-block:last-child {
        margin-right: 0; } }

@media (min-width: 1440px) {
  .c-btn-block-group.c-btn-block-group--standard-layout-xxl {
    display: block; }
    .c-btn-block-group.c-btn-block-group--standard-layout-xxl .c-btn-block {
      display: flex;
      flex-grow: 0;
      margin-right: 0; }
  .c-btn-block-group.c-btn-block-group--flex-layout-xxl {
    display: flex; }
    .c-btn-block-group.c-btn-block-group--flex-layout-xxl .c-btn-block {
      display: inline-flex;
      flex-grow: 1;
      margin-right: 8px; }
      .c-btn-block-group.c-btn-block-group--flex-layout-xxl .c-btn-block:last-child {
        margin-right: 0; } }

/*doc
---
title: Button group
name: z-button-group
category: Buttons
---

```html_example
<div class="c-btn-group">
    <a href="/" class="c-btn c-btn--2">Apply now</a>
    <a href="/" class="c-btn c-btn--black">See pricing</a>
</div>
```

<div class="c-sg-modifier-wrap js-modifier-wrap">
    <div>
        <button>c-btn-group--margin-right</button>
    </div>
</div>

*/
/*doc
---
title: Button block group
name: button-block-group
category: Buttons
---

```html_example
<div class="c-btn-block-group js-account-functions">
    <button class="c-btn-block c-btn-block--with-subtext">
        <span class="c-btn-block__content">
            How to pay in
        </span>
    </button>

    <button class="c-btn-block c-btn-block--with-subtext">
        <span class="c-btn-block__content">
            Fees &amp; Limits
            <span class="c-btn-block__subtext">Next £5 bill: 12/07/19</span>
        </span>
    </button>
</div>
```

<div class="c-sg-modifier-wrap js-modifier-wrap">
    <div>
        <button>c-btn-block-group--standard-layout</button>
    </div>
    <div>
        <button>c-btn-block-group--standard-layout-xs</button>
    </div>
    <div>
        <button>c-btn-block-group--standard-layout-s</button>
    </div>
    <div>
        <button>c-btn-block-group--standard-layout-m</button>
    </div>
    <div>
        <button>c-btn-block-group--standard-layout-l</button>
    </div>
    <div>
        <button>c-btn-block-group--standard-layout-xl</button>
    </div>
    <div>
        <button>c-btn-block-group--standard-layout-xxl</button>
    </div>
    <div>
        <button>c-btn-block-group--flex-layout</button>
    </div>
    <div>
        <button>c-btn-block-group--flex-layout-xs</button>
    </div>
    <div>
        <button>c-btn-block-group--flex-layout-s</button>
    </div>
    <div>
        <button>c-btn-block-group--flex-layout-m</button>
    </div>
    <div>
        <button>c-btn-block-group--flex-layout-l</button>
    </div>
    <div>
        <button>c-btn-block-group--flex-layout-xl</button>
    </div>
    <div>
        <button>c-btn-block-group--flex-layout-xxl</button>
    </div>
</div>

*/
/*------------------------------------*\
    buttons
\*------------------------------------*/
.c-btn {
  font-family: "futura-pt", sans-serif;
  font-weight: 700;
  display: inline-block;
  padding: 8px 20px;
  text-decoration: none;
  text-align: center;
  margin-bottom: 16px;
  transition-property: background-color, color, transform, box-shadow, border-color;
  transition-duration: .2s, .2s, .2s, .2s;
  transition-timing-function: ease-in-out, ease-in-out, ease-in-out, ease-in-out;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  background: #f2663b; }
  @media (min-width: 980px) {
    .c-btn {
      font-size: 20px;
      line-height: 30px; } }
  .c-btn[class*="u-before-icon"]:before {
    margin-right: 8px; }
  .c-btn[class*="u-after-icon"]:after {
    margin-left: 8px; }
  .c-btn--full-width {
    width: 100%;
    max-width: none;
    display: block; }
  .c-btn--icon-down:after {
    font-family: 'ffreesicons', sans-serif;
    font-weight: 500;
    font-style: normal;
    content: "";
    display: inline-block;
    margin-left: 8px; }
  .c-btn--icon-next:after {
    font-family: 'ffreesicons', sans-serif;
    font-weight: 500;
    font-style: normal;
    content: "";
    display: inline-block;
    margin-left: 8px; }
  .c-btn--float-right {
    float: right;
    margin-right: 0; }
  .c-btn--small-header {
    padding: 8px 16px;
    font-size: 14px;
    line-height: 1.5; }
  .c-btn--margin-right-s {
    margin-right: 16px; }
  .c-btn--margin-bottom-s {
    margin-bottom: 16px; }
  .c-btn--margin-0 {
    margin: 0; }
  .c-btn--margin-bottom-0 {
    margin-bottom: 0; }
  .c-btn--more-padding {
    padding: 20px; }
  .c-btn--nowrap {
    white-space: nowrap; }
  .c-btn--text-align-left {
    text-align: left; }
  .c-btn--icon-only[class*="u-before-icon"]:before {
    margin-right: 0; }
  .c-btn--icon-only[class*="u-after-icon"]:after {
    margin-left: 0; }
  .c-btn--plus-minus-icon {
    position: relative; }
    .c-btn--plus-minus-icon:before, .c-btn--plus-minus-icon:after {
      position: absolute;
      content: '';
      background-color: currentColor;
      display: block; }
    .c-btn--plus-minus-icon:before {
      width: 15px;
      height: 3px;
      top: calc(50% - 1px);
      right: 20px; }
    .c-btn--plus-minus-icon:after {
      width: 3px;
      height: 15px;
      top: calc(50% - 7px);
      right: 26px;
      transition: transform 0.3s ease-in-out; }
    .c-btn--plus-minus-icon.is-active:after {
      transform: rotate(90deg); }
  .c-btn--large {
    font-size: 24px;
    line-height: 31px;
    padding: 16px 48px; }
    @media (min-width: 980px) {
      .c-btn--large {
        font-size: 36px;
        line-height: 46px; } }
  .c-btn--raised {
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.21); }
  .c-btn.is-disabled {
    opacity: .4;
    cursor: not-allowed; }
  @media (max-width: 540px) {
    .c-btn--mobile-full-width {
      width: 100%;
      max-width: none;
      display: block; } }
  @media (min-width: 540px) {
    .c-btn--margin-bottom-0-xs {
      margin-bottom: 0; } }
  @media (min-width: 800px) {
    .c-btn--more-padding {
      padding: 20px 36px; } }
  @media (min-width: 980px) {
    .c-btn--icon-next:after {
      content: ""; } }
  .c-btn:hover, .c-btn:focus {
    color: #ffffff;
    background: #ea4310; }
  .c-btn--1-light {
    color: #f2663b;
    background-color: #ffffff; }
    .c-btn--1-light:hover, .c-btn--1-light:focus {
      color: #f2663b;
      background-color: #fef8f7; }
  .c-btn--2 {
    color: #ffffff;
    background: #6eb52b; }
    .c-btn--2:hover, .c-btn--2:focus {
      color: #ffffff;
      background: #558c21; }
  .c-btn--3 {
    color: #ffffff;
    background: #ffb20a; }
    .c-btn--3:hover, .c-btn--3:focus {
      color: #ffffff;
      background: #d69300; }
  .c-btn--4 {
    color: #ffffff;
    background: #d31a35; }
    .c-btn--4:hover, .c-btn--4:focus {
      color: #ffffff;
      background: #a6142a; }
  .c-btn--5 {
    color: #ffffff;
    background: #005EB0; }
    .c-btn--5:hover, .c-btn--5:focus {
      color: #ffffff;
      background: #00437d; }
  .c-btn--white {
    color: #000000;
    background: #ffffff; }
    .c-btn--white:hover, .c-btn--white:focus {
      color: #000000;
      background: #e6e6e6; }
  .c-btn--black {
    color: #ffffff;
    background: #000000; }
    .c-btn--black:hover, .c-btn--black:focus {
      color: #ffffff;
      background: #4d4d4d; }

.c-btn-outline {
  font-family: "futura-pt", sans-serif;
  font-weight: 700;
  display: inline-block;
  padding: 8px 20px;
  text-decoration: none;
  text-align: center;
  margin-bottom: 16px;
  transition-property: background-color, color, transform, box-shadow, border-color;
  transition-duration: .2s, .2s, .2s, .2s;
  transition-timing-function: ease-in-out, ease-in-out, ease-in-out, ease-in-out;
  font-size: 16px;
  line-height: 24px;
  color: #f2663b;
  background: none;
  box-shadow: inset 0 0 0 4px currentColor; }
  @media (min-width: 980px) {
    .c-btn-outline {
      font-size: 20px;
      line-height: 30px; } }
  .c-btn-outline[class*="u-before-icon"]:before {
    margin-right: 8px; }
  .c-btn-outline[class*="u-after-icon"]:after {
    margin-left: 8px; }
  .c-btn-outline--full-width {
    width: 100%;
    max-width: none;
    display: block; }
  .c-btn-outline--icon-down:after {
    font-family: 'ffreesicons', sans-serif;
    font-weight: 500;
    font-style: normal;
    content: "";
    display: inline-block;
    margin-left: 8px; }
  .c-btn-outline--icon-next:after {
    font-family: 'ffreesicons', sans-serif;
    font-weight: 500;
    font-style: normal;
    content: "";
    display: inline-block;
    margin-left: 8px; }
  .c-btn-outline--float-right {
    float: right;
    margin-right: 0; }
  .c-btn-outline--small-header {
    padding: 8px 16px;
    font-size: 14px;
    line-height: 1.5; }
  .c-btn-outline--margin-right-s {
    margin-right: 16px; }
  .c-btn-outline--margin-bottom-s {
    margin-bottom: 16px; }
  .c-btn-outline--margin-0 {
    margin: 0; }
  .c-btn-outline--margin-bottom-0 {
    margin-bottom: 0; }
  .c-btn-outline--more-padding {
    padding: 20px; }
  .c-btn-outline--nowrap {
    white-space: nowrap; }
  .c-btn-outline--text-align-left {
    text-align: left; }
  .c-btn-outline--icon-only[class*="u-before-icon"]:before {
    margin-right: 0; }
  .c-btn-outline--icon-only[class*="u-after-icon"]:after {
    margin-left: 0; }
  .c-btn-outline--plus-minus-icon {
    position: relative; }
    .c-btn-outline--plus-minus-icon:before, .c-btn-outline--plus-minus-icon:after {
      position: absolute;
      content: '';
      background-color: currentColor;
      display: block; }
    .c-btn-outline--plus-minus-icon:before {
      width: 15px;
      height: 3px;
      top: calc(50% - 1px);
      right: 20px; }
    .c-btn-outline--plus-minus-icon:after {
      width: 3px;
      height: 15px;
      top: calc(50% - 7px);
      right: 26px;
      transition: transform 0.3s ease-in-out; }
    .c-btn-outline--plus-minus-icon.is-active:after {
      transform: rotate(90deg); }
  .c-btn-outline--large {
    font-size: 24px;
    line-height: 31px;
    padding: 16px 48px; }
    @media (min-width: 980px) {
      .c-btn-outline--large {
        font-size: 36px;
        line-height: 46px; } }
  .c-btn-outline--raised {
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.21); }
  .c-btn-outline.is-disabled {
    opacity: .4;
    cursor: not-allowed; }
  @media (max-width: 540px) {
    .c-btn-outline--mobile-full-width {
      width: 100%;
      max-width: none;
      display: block; } }
  @media (min-width: 540px) {
    .c-btn-outline--margin-bottom-0-xs {
      margin-bottom: 0; } }
  @media (min-width: 800px) {
    .c-btn-outline--more-padding {
      padding: 20px 36px; } }
  @media (min-width: 980px) {
    .c-btn-outline--icon-next:after {
      content: ""; } }
  .c-btn-outline:hover, .c-btn-outline:focus {
    color: #f2663b;
    background: rgba(242, 102, 59, 0.3); }
  .c-btn-outline--2 {
    color: #6eb52b;
    box-shadow: inset 0 0 0 4px currentColor; }
    .c-btn-outline--2:hover, .c-btn-outline--2:focus {
      color: #6eb52b;
      background: rgba(110, 181, 43, 0.3); }
  .c-btn-outline--3 {
    color: #ffb20a;
    box-shadow: inset 0 0 0 4px currentColor; }
    .c-btn-outline--3:hover, .c-btn-outline--3:focus {
      color: #ffb20a;
      background: rgba(255, 178, 10, 0.3); }
  .c-btn-outline--4 {
    color: #d31a35;
    box-shadow: inset 0 0 0 4px currentColor; }
    .c-btn-outline--4:hover, .c-btn-outline--4:focus {
      color: #d31a35;
      background: rgba(211, 26, 53, 0.3); }
  .c-btn-outline--5 {
    color: #005EB0;
    box-shadow: inset 0 0 0 4px currentColor; }
    .c-btn-outline--5:hover, .c-btn-outline--5:focus {
      color: #005EB0;
      background: rgba(0, 94, 176, 0.3); }
  .c-btn-outline--black {
    color: #000000;
    box-shadow: inset 0 0 0 4px currentColor; }
    .c-btn-outline--black:hover, .c-btn-outline--black:focus {
      color: #000000;
      background: rgba(0, 0, 0, 0.3); }
  .c-btn-outline--white {
    color: #ffffff;
    box-shadow: inset 0 0 0 4px currentColor; }
    .c-btn-outline--white:hover, .c-btn-outline--white:focus {
      color: #ffffff;
      background: rgba(255, 255, 255, 0.3); }

.c-btn-small-square {
  font-family: "futura-pt", sans-serif;
  font-weight: 700;
  display: inline-block;
  padding: 8px 20px;
  text-decoration: none;
  text-align: center;
  margin-bottom: 16px;
  transition-property: background-color, color, transform, box-shadow, border-color;
  transition-duration: .2s, .2s, .2s, .2s;
  transition-timing-function: ease-in-out, ease-in-out, ease-in-out, ease-in-out;
  font-size: 16px;
  line-height: 24px;
  padding: 0;
  color: #f2663b;
  background: none; }
  @media (min-width: 980px) {
    .c-btn-small-square {
      font-size: 20px;
      line-height: 30px; } }
  .c-btn-small-square[class*="u-before-icon"]:before {
    margin-right: 8px; }
  .c-btn-small-square[class*="u-after-icon"]:after {
    margin-left: 8px; }
  .c-btn-small-square--full-width {
    width: 100%;
    max-width: none;
    display: block; }
  .c-btn-small-square--icon-down:after {
    font-family: 'ffreesicons', sans-serif;
    font-weight: 500;
    font-style: normal;
    content: "";
    display: inline-block;
    margin-left: 8px; }
  .c-btn-small-square--icon-next:after {
    font-family: 'ffreesicons', sans-serif;
    font-weight: 500;
    font-style: normal;
    content: "";
    display: inline-block;
    margin-left: 8px; }
  .c-btn-small-square--float-right {
    float: right;
    margin-right: 0; }
  .c-btn-small-square--small-header {
    padding: 8px 16px;
    font-size: 14px;
    line-height: 1.5; }
  .c-btn-small-square--margin-right-s {
    margin-right: 16px; }
  .c-btn-small-square--margin-bottom-s {
    margin-bottom: 16px; }
  .c-btn-small-square--margin-0 {
    margin: 0; }
  .c-btn-small-square--margin-bottom-0 {
    margin-bottom: 0; }
  .c-btn-small-square--more-padding {
    padding: 20px; }
  .c-btn-small-square--nowrap {
    white-space: nowrap; }
  .c-btn-small-square--text-align-left {
    text-align: left; }
  .c-btn-small-square--icon-only[class*="u-before-icon"]:before {
    margin-right: 0; }
  .c-btn-small-square--icon-only[class*="u-after-icon"]:after {
    margin-left: 0; }
  .c-btn-small-square--plus-minus-icon {
    position: relative; }
    .c-btn-small-square--plus-minus-icon:before, .c-btn-small-square--plus-minus-icon:after {
      position: absolute;
      content: '';
      background-color: currentColor;
      display: block; }
    .c-btn-small-square--plus-minus-icon:before {
      width: 15px;
      height: 3px;
      top: calc(50% - 1px);
      right: 20px; }
    .c-btn-small-square--plus-minus-icon:after {
      width: 3px;
      height: 15px;
      top: calc(50% - 7px);
      right: 26px;
      transition: transform 0.3s ease-in-out; }
    .c-btn-small-square--plus-minus-icon.is-active:after {
      transform: rotate(90deg); }
  .c-btn-small-square--large {
    font-size: 24px;
    line-height: 31px;
    padding: 16px 48px; }
    @media (min-width: 980px) {
      .c-btn-small-square--large {
        font-size: 36px;
        line-height: 46px; } }
  .c-btn-small-square--raised {
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.21); }
  .c-btn-small-square.is-disabled {
    opacity: .4;
    cursor: not-allowed; }
  @media (max-width: 540px) {
    .c-btn-small-square--mobile-full-width {
      width: 100%;
      max-width: none;
      display: block; } }
  @media (min-width: 540px) {
    .c-btn-small-square--margin-bottom-0-xs {
      margin-bottom: 0; } }
  @media (min-width: 800px) {
    .c-btn-small-square--more-padding {
      padding: 20px 36px; } }
  @media (min-width: 980px) {
    .c-btn-small-square--icon-next:after {
      content: ""; } }
  .c-btn-small-square:before {
    width: 44px;
    height: 44px;
    margin: 0 auto;
    font-size: 44px;
    line-height: 44px;
    display: block !important;
    color: #ffffff;
    background-color: #f2663b;
    box-shadow: 2px 1px 4px rgba(0, 0, 0, 0.2); }
  .c-btn-small-square:hover, .c-btn-small-square:focus {
    color: #ea4310;
    background: none; }
    .c-btn-small-square:hover:before, .c-btn-small-square:focus:before {
      background-color: #ea4310; }
  .c-btn-small-square--2 {
    color: #6eb52b; }
    .c-btn-small-square--2:before {
      color: #ffffff;
      background-color: #6eb52b; }
    .c-btn-small-square--2:hover:before, .c-btn-small-square--2:focus:before {
      background: #558c21; }
  .c-btn-small-square--3 {
    color: #ffb20a; }
    .c-btn-small-square--3:before {
      color: #ffffff;
      background-color: #ffb20a; }
    .c-btn-small-square--3:hover, .c-btn-small-square--3:focus {
      color: #d69300; }
      .c-btn-small-square--3:hover:before, .c-btn-small-square--3:focus:before {
        background: #d69300; }
  .c-btn-small-square--4 {
    color: #d31a35; }
    .c-btn-small-square--4:before {
      color: #ffffff;
      background-color: #d31a35; }
    .c-btn-small-square--4:hover, .c-btn-small-square--4:focus {
      color: #a6142a; }
      .c-btn-small-square--4:hover:before, .c-btn-small-square--4:focus:before {
        background: #a6142a; }
  .c-btn-small-square--5 {
    color: #005EB0; }
    .c-btn-small-square--5:before {
      color: #ffffff;
      background-color: #005EB0; }
    .c-btn-small-square--5:hover, .c-btn-small-square--5:focus {
      color: #00437d; }
      .c-btn-small-square--5:hover:before, .c-btn-small-square--5:focus:before {
        background: #00437d; }
  .c-btn-small-square--white {
    color: #ffffff; }
    .c-btn-small-square--white:before {
      color: #000000;
      background-color: #ffffff; }
    .c-btn-small-square--white:hover, .c-btn-small-square--white:focus {
      color: #ffffff; }
      .c-btn-small-square--white:hover:before, .c-btn-small-square--white:focus:before {
        background: #f2663b; }
  .c-btn-small-square--black {
    color: #000000; }
    .c-btn-small-square--black:before {
      color: #ffffff;
      background-color: #000000; }
    .c-btn-small-square--black:hover, .c-btn-small-square--black:focus {
      color: #f2663b; }
      .c-btn-small-square--black:hover:before, .c-btn-small-square--black:focus:before {
        background: #f2663b; }
  .c-btn-small-square--position-fixed-right-top {
    margin: 0;
    position: fixed;
    top: 24px;
    right: 12px; }
  .c-btn-small-square--position-fixed-right-bottom {
    margin: 0;
    position: fixed;
    right: 12px;
    bottom: 24px; }
  @media (min-width: 980px) {
    .c-btn-small-square {
      right: 24px; } }

.c-btn-circle {
  font-family: "futura-pt", sans-serif;
  font-weight: 700;
  display: inline-block;
  padding: 8px 20px;
  text-decoration: none;
  text-align: center;
  margin-bottom: 16px;
  transition-property: background-color, color, transform, box-shadow, border-color;
  transition-duration: .2s, .2s, .2s, .2s;
  transition-timing-function: ease-in-out, ease-in-out, ease-in-out, ease-in-out;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  background: #f2663b;
  position: relative;
  font-size: 16px;
  line-height: 24px;
  font-family: "futura-pt", sans-serif;
  font-weight: 500;
  margin-bottom: 8px;
  width: 70px;
  padding: 0;
  border-radius: 50%;
  background: #f2663b;
  color: #ffffff;
  text-transform: none; }
  @media (min-width: 980px) {
    .c-btn-circle {
      font-size: 20px;
      line-height: 30px; } }
  .c-btn-circle[class*="u-before-icon"]:before {
    margin-right: 8px; }
  .c-btn-circle[class*="u-after-icon"]:after {
    margin-left: 8px; }
  .c-btn-circle--full-width {
    width: 100%;
    max-width: none;
    display: block; }
  .c-btn-circle--icon-down:after {
    font-family: 'ffreesicons', sans-serif;
    font-weight: 500;
    font-style: normal;
    content: "";
    display: inline-block;
    margin-left: 8px; }
  .c-btn-circle--icon-next:after {
    font-family: 'ffreesicons', sans-serif;
    font-weight: 500;
    font-style: normal;
    content: "";
    display: inline-block;
    margin-left: 8px; }
  .c-btn-circle--float-right {
    float: right;
    margin-right: 0; }
  .c-btn-circle--small-header {
    padding: 8px 16px;
    font-size: 14px;
    line-height: 1.5; }
  .c-btn-circle--margin-right-s {
    margin-right: 16px; }
  .c-btn-circle--margin-bottom-s {
    margin-bottom: 16px; }
  .c-btn-circle--margin-0 {
    margin: 0; }
  .c-btn-circle--margin-bottom-0 {
    margin-bottom: 0; }
  .c-btn-circle--more-padding {
    padding: 20px; }
  .c-btn-circle--nowrap {
    white-space: nowrap; }
  .c-btn-circle--text-align-left {
    text-align: left; }
  .c-btn-circle--icon-only[class*="u-before-icon"]:before {
    margin-right: 0; }
  .c-btn-circle--icon-only[class*="u-after-icon"]:after {
    margin-left: 0; }
  .c-btn-circle--plus-minus-icon {
    position: relative; }
    .c-btn-circle--plus-minus-icon:before, .c-btn-circle--plus-minus-icon:after {
      position: absolute;
      content: '';
      background-color: currentColor;
      display: block; }
    .c-btn-circle--plus-minus-icon:before {
      width: 15px;
      height: 3px;
      top: calc(50% - 1px);
      right: 20px; }
    .c-btn-circle--plus-minus-icon:after {
      width: 3px;
      height: 15px;
      top: calc(50% - 7px);
      right: 26px;
      transition: transform 0.3s ease-in-out; }
    .c-btn-circle--plus-minus-icon.is-active:after {
      transform: rotate(90deg); }
  .c-btn-circle--large {
    font-size: 24px;
    line-height: 31px;
    padding: 16px 48px; }
    @media (min-width: 980px) {
      .c-btn-circle--large {
        font-size: 36px;
        line-height: 46px; } }
  .c-btn-circle--raised {
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.21); }
  .c-btn-circle.is-disabled {
    opacity: .4;
    cursor: not-allowed; }
  @media (max-width: 540px) {
    .c-btn-circle--mobile-full-width {
      width: 100%;
      max-width: none;
      display: block; } }
  @media (min-width: 540px) {
    .c-btn-circle--margin-bottom-0-xs {
      margin-bottom: 0; } }
  @media (min-width: 800px) {
    .c-btn-circle--more-padding {
      padding: 20px 36px; } }
  @media (min-width: 980px) {
    .c-btn-circle--icon-next:after {
      content: ""; } }
  .c-btn-circle:hover, .c-btn-circle:focus {
    color: #ffffff;
    background: #ea4310; }
  .c-btn-circle--1-light {
    color: #f2663b;
    background-color: #ffffff; }
    .c-btn-circle--1-light:hover, .c-btn-circle--1-light:focus {
      color: #f2663b;
      background-color: #fef8f7; }
  .c-btn-circle--2 {
    color: #ffffff;
    background: #6eb52b; }
    .c-btn-circle--2:hover, .c-btn-circle--2:focus {
      color: #ffffff;
      background: #558c21; }
  .c-btn-circle--3 {
    color: #ffffff;
    background: #ffb20a; }
    .c-btn-circle--3:hover, .c-btn-circle--3:focus {
      color: #ffffff;
      background: #d69300; }
  .c-btn-circle--4 {
    color: #ffffff;
    background: #d31a35; }
    .c-btn-circle--4:hover, .c-btn-circle--4:focus {
      color: #ffffff;
      background: #a6142a; }
  .c-btn-circle--5 {
    color: #ffffff;
    background: #005EB0; }
    .c-btn-circle--5:hover, .c-btn-circle--5:focus {
      color: #ffffff;
      background: #00437d; }
  .c-btn-circle--white {
    color: #000000;
    background: #ffffff; }
    .c-btn-circle--white:hover, .c-btn-circle--white:focus {
      color: #000000;
      background: #e6e6e6; }
  .c-btn-circle--black {
    color: #ffffff;
    background: #000000; }
    .c-btn-circle--black:hover, .c-btn-circle--black:focus {
      color: #ffffff;
      background: #4d4d4d; }
  .c-btn-circle:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: 100%; }
  @media (min-width: 980px) {
    .c-btn-circle {
      font-size: 20px;
      line-height: 28px; } }
  .c-btn-circle__content {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%); }
  .c-btn-circle--raised {
    box-shadow: 0px 2px 4px 1px rgba(0, 0, 0, 0.25); }
  @media (min-width: 980px) {
    .c-btn-circle {
      width: 90px; } }

.c-btn-small-round {
  font-family: "futura-pt", sans-serif;
  font-weight: 700;
  display: inline-block;
  padding: 8px 20px;
  text-decoration: none;
  text-align: center;
  margin-bottom: 16px;
  transition-property: background-color, color, transform, box-shadow, border-color;
  transition-duration: .2s, .2s, .2s, .2s;
  transition-timing-function: ease-in-out, ease-in-out, ease-in-out, ease-in-out;
  font-size: 16px;
  line-height: 24px;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.21);
  position: relative;
  width: 44px;
  min-width: 44px;
  background-color: #ffffff;
  border-radius: 50%;
  padding: 0; }
  @media (min-width: 980px) {
    .c-btn-small-round {
      font-size: 20px;
      line-height: 30px; } }
  .c-btn-small-round[class*="u-before-icon"]:before {
    margin-right: 8px; }
  .c-btn-small-round[class*="u-after-icon"]:after {
    margin-left: 8px; }
  .c-btn-small-round--full-width {
    width: 100%;
    max-width: none;
    display: block; }
  .c-btn-small-round--icon-down:after {
    font-family: 'ffreesicons', sans-serif;
    font-weight: 500;
    font-style: normal;
    content: "";
    display: inline-block;
    margin-left: 8px; }
  .c-btn-small-round--icon-next:after {
    font-family: 'ffreesicons', sans-serif;
    font-weight: 500;
    font-style: normal;
    content: "";
    display: inline-block;
    margin-left: 8px; }
  .c-btn-small-round--float-right {
    float: right;
    margin-right: 0; }
  .c-btn-small-round--small-header {
    padding: 8px 16px;
    font-size: 14px;
    line-height: 1.5; }
  .c-btn-small-round--margin-right-s {
    margin-right: 16px; }
  .c-btn-small-round--margin-bottom-s {
    margin-bottom: 16px; }
  .c-btn-small-round--margin-0 {
    margin: 0; }
  .c-btn-small-round--margin-bottom-0 {
    margin-bottom: 0; }
  .c-btn-small-round--more-padding {
    padding: 20px; }
  .c-btn-small-round--nowrap {
    white-space: nowrap; }
  .c-btn-small-round--text-align-left {
    text-align: left; }
  .c-btn-small-round--icon-only[class*="u-before-icon"]:before {
    margin-right: 0; }
  .c-btn-small-round--icon-only[class*="u-after-icon"]:after {
    margin-left: 0; }
  .c-btn-small-round--plus-minus-icon {
    position: relative; }
    .c-btn-small-round--plus-minus-icon:before, .c-btn-small-round--plus-minus-icon:after {
      position: absolute;
      content: '';
      background-color: currentColor;
      display: block; }
    .c-btn-small-round--plus-minus-icon:before {
      width: 15px;
      height: 3px;
      top: calc(50% - 1px);
      right: 20px; }
    .c-btn-small-round--plus-minus-icon:after {
      width: 3px;
      height: 15px;
      top: calc(50% - 7px);
      right: 26px;
      transition: transform 0.3s ease-in-out; }
    .c-btn-small-round--plus-minus-icon.is-active:after {
      transform: rotate(90deg); }
  .c-btn-small-round--large {
    font-size: 24px;
    line-height: 31px;
    padding: 16px 48px; }
    @media (min-width: 980px) {
      .c-btn-small-round--large {
        font-size: 36px;
        line-height: 46px; } }
  .c-btn-small-round--raised {
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.21); }
  .c-btn-small-round.is-disabled {
    opacity: .4;
    cursor: not-allowed; }
  @media (max-width: 540px) {
    .c-btn-small-round--mobile-full-width {
      width: 100%;
      max-width: none;
      display: block; } }
  @media (min-width: 540px) {
    .c-btn-small-round--margin-bottom-0-xs {
      margin-bottom: 0; } }
  @media (min-width: 800px) {
    .c-btn-small-round--more-padding {
      padding: 20px 36px; } }
  @media (min-width: 980px) {
    .c-btn-small-round--icon-next:after {
      content: ""; } }
  .c-btn-small-round:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: 100%; }
  .c-btn-small-round:hover, .c-btn-small-round:focus {
    background-color: #fef8f7; }
  .c-btn-small-round__content {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    color: #000000;
    text-transform: none;
    font-size: 14px;
    line-height: 22px; }
    @media (min-width: 980px) {
      .c-btn-small-round__content {
        font-size: 16px;
        line-height: 26px; } }
    .c-btn-small-round__content:before, .c-btn-small-round__content:after {
      font-size: 14px;
      line-height: 22px; }
      @media (min-width: 980px) {
        .c-btn-small-round__content:before, .c-btn-small-round__content:after {
          font-size: 16px;
          line-height: 26px; } }
  .c-btn-small-round--large {
    padding: 0;
    width: 54px;
    min-width: 54px; }

.c-btn-block {
  font-family: "futura-pt", sans-serif;
  font-weight: 700;
  display: inline-block;
  padding: 8px 20px;
  text-decoration: none;
  text-align: center;
  margin-bottom: 16px;
  transition-property: background-color, color, transform, box-shadow, border-color;
  transition-duration: .2s, .2s, .2s, .2s;
  transition-timing-function: ease-in-out, ease-in-out, ease-in-out, ease-in-out;
  font-size: 16px;
  line-height: 24px;
  height: 56px;
  padding-top: 0;
  padding-bottom: 0;
  color: #000000;
  background-color: #ffffff;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.21);
  font-family: "futura-pt", sans-serif;
  font-weight: 500;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border: 1px solid #e5e5e5;
  border-radius: 16px;
  overflow: hidden; }
  @media (min-width: 980px) {
    .c-btn-block {
      font-size: 20px;
      line-height: 30px; } }
  .c-btn-block[class*="u-before-icon"]:before {
    margin-right: 8px; }
  .c-btn-block[class*="u-after-icon"]:after {
    margin-left: 8px; }
  .c-btn-block--full-width {
    width: 100%;
    max-width: none;
    display: block; }
  .c-btn-block--icon-down:after {
    font-family: 'ffreesicons', sans-serif;
    font-weight: 500;
    font-style: normal;
    content: "";
    display: inline-block;
    margin-left: 8px; }
  .c-btn-block--icon-next:after {
    font-family: 'ffreesicons', sans-serif;
    font-weight: 500;
    font-style: normal;
    content: "";
    display: inline-block;
    margin-left: 8px; }
  .c-btn-block--float-right {
    float: right;
    margin-right: 0; }
  .c-btn-block--small-header {
    padding: 8px 16px;
    font-size: 14px;
    line-height: 1.5; }
  .c-btn-block--margin-right-s {
    margin-right: 16px; }
  .c-btn-block--margin-bottom-s {
    margin-bottom: 16px; }
  .c-btn-block--margin-0 {
    margin: 0; }
  .c-btn-block--margin-bottom-0 {
    margin-bottom: 0; }
  .c-btn-block--more-padding {
    padding: 20px; }
  .c-btn-block--nowrap {
    white-space: nowrap; }
  .c-btn-block--text-align-left {
    text-align: left; }
  .c-btn-block--icon-only[class*="u-before-icon"]:before {
    margin-right: 0; }
  .c-btn-block--icon-only[class*="u-after-icon"]:after {
    margin-left: 0; }
  .c-btn-block--plus-minus-icon {
    position: relative; }
    .c-btn-block--plus-minus-icon:before, .c-btn-block--plus-minus-icon:after {
      position: absolute;
      content: '';
      background-color: currentColor;
      display: block; }
    .c-btn-block--plus-minus-icon:before {
      width: 15px;
      height: 3px;
      top: calc(50% - 1px);
      right: 20px; }
    .c-btn-block--plus-minus-icon:after {
      width: 3px;
      height: 15px;
      top: calc(50% - 7px);
      right: 26px;
      transition: transform 0.3s ease-in-out; }
    .c-btn-block--plus-minus-icon.is-active:after {
      transform: rotate(90deg); }
  .c-btn-block--large {
    font-size: 24px;
    line-height: 31px;
    padding: 16px 48px; }
    @media (min-width: 980px) {
      .c-btn-block--large {
        font-size: 36px;
        line-height: 46px; } }
  .c-btn-block--raised {
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.21); }
  .c-btn-block.is-disabled {
    opacity: .4;
    cursor: not-allowed; }
  @media (max-width: 540px) {
    .c-btn-block--mobile-full-width {
      width: 100%;
      max-width: none;
      display: block; } }
  @media (min-width: 540px) {
    .c-btn-block--margin-bottom-0-xs {
      margin-bottom: 0; } }
  @media (min-width: 800px) {
    .c-btn-block--more-padding {
      padding: 20px 36px; } }
  @media (min-width: 980px) {
    .c-btn-block--icon-next:after {
      content: ""; } }
  @media (min-width: 980px) {
    .c-btn-block {
      height: 76px; } }
  .c-btn-block:hover, .c-btn-block:focus, .c-btn-block:active {
    color: #000000;
    background-color: #fef8f7;
    border-color: #f2663b;
    box-shadow: 0px 2px 4px rgba(234, 67, 16, 0.21); }
  .c-btn-block[class*="u-before-icon"]:before {
    margin-right: 12px; }
  .c-btn-block[class*="u-after-icon"]:after {
    margin-left: 12px; }
  .c-btn-block__image {
    margin-right: 16px;
    width: 80px; }
  .c-btn-block__content {
    display: flex;
    flex-direction: column;
    justify-content: center; }
  .c-btn-block__subtext {
    display: none;
    color: #f2663b;
    font-family: "futura-pt", sans-serif;
    font-weight: 500;
    text-transform: none;
    font-size: 14px;
    line-height: 22px; }
    @media (min-width: 980px) {
      .c-btn-block__subtext {
        font-size: 16px;
        line-height: 26px; } }
  .c-btn-block--with-subtext .c-btn-block__subtext {
    display: block; }
  .c-btn-block--full-width {
    display: flex; }
  .c-btn-block--justify-content-flex-start {
    justify-content: flex-start; }
  .c-btn-block--no-fixed-height {
    height: auto;
    padding-top: 12px;
    padding-bottom: 12px; }
  .c-btn-block--dot-dot-loans {
    background-color: #feefd7;
    border: 2px solid #ea9605;
    box-shadow: 0px 2px 4px rgba(234, 150, 5, 0.21);
    animation: fadeInButtonShadow 200ms; }
    .c-btn-block--dot-dot-loans:hover, .c-btn-block--dot-dot-loans:focus, .c-btn-block--dot-dot-loans:active {
      color: #000000;
      background-color: #ffe5bb;
      border-color: #ea9605;
      box-shadow: inset 0px 2px 4px rgba(234, 150, 5, 0.21);
      animation: fadeOutButtonShadow 200ms; }
  @media (min-width: 980px) {
    .c-btn-block__image {
      margin-right: 20px;
      width: 100px; }
    .c-btn-block--no-fixed-height {
      padding: 20px; }
    .c-btn-block.is-selected-desktop {
      background-color: #fef8f7;
      border-color: #f2663b;
      box-shadow: 0px 2px 4px rgba(234, 67, 16, 0.21); } }

.c-btn-tertiary {
  font-family: "futura-pt", sans-serif;
  font-weight: 700;
  display: inline-block;
  padding: 8px 20px;
  text-decoration: none;
  text-align: center;
  margin-bottom: 16px;
  transition-property: background-color, color, transform, box-shadow, border-color;
  transition-duration: .2s, .2s, .2s, .2s;
  transition-timing-function: ease-in-out, ease-in-out, ease-in-out, ease-in-out;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  text-transform: none;
  font-size: 14px;
  position: relative;
  overflow: hidden; }
  @media (min-width: 980px) {
    .c-btn-tertiary {
      font-size: 20px;
      line-height: 30px; } }
  .c-btn-tertiary[class*="u-before-icon"]:before {
    margin-right: 8px; }
  .c-btn-tertiary[class*="u-after-icon"]:after {
    margin-left: 8px; }
  .c-btn-tertiary--full-width {
    width: 100%;
    max-width: none;
    display: block; }
  .c-btn-tertiary--icon-down:after {
    font-family: 'ffreesicons', sans-serif;
    font-weight: 500;
    font-style: normal;
    content: "";
    display: inline-block;
    margin-left: 8px; }
  .c-btn-tertiary--icon-next:after {
    font-family: 'ffreesicons', sans-serif;
    font-weight: 500;
    font-style: normal;
    content: "";
    display: inline-block;
    margin-left: 8px; }
  .c-btn-tertiary--float-right {
    float: right;
    margin-right: 0; }
  .c-btn-tertiary--small-header {
    padding: 8px 16px;
    font-size: 14px;
    line-height: 1.5; }
  .c-btn-tertiary--margin-right-s {
    margin-right: 16px; }
  .c-btn-tertiary--margin-bottom-s {
    margin-bottom: 16px; }
  .c-btn-tertiary--margin-0 {
    margin: 0; }
  .c-btn-tertiary--margin-bottom-0 {
    margin-bottom: 0; }
  .c-btn-tertiary--more-padding {
    padding: 20px; }
  .c-btn-tertiary--nowrap {
    white-space: nowrap; }
  .c-btn-tertiary--text-align-left {
    text-align: left; }
  .c-btn-tertiary--icon-only[class*="u-before-icon"]:before {
    margin-right: 0; }
  .c-btn-tertiary--icon-only[class*="u-after-icon"]:after {
    margin-left: 0; }
  .c-btn-tertiary--plus-minus-icon {
    position: relative; }
    .c-btn-tertiary--plus-minus-icon:before, .c-btn-tertiary--plus-minus-icon:after {
      position: absolute;
      content: '';
      background-color: currentColor;
      display: block; }
    .c-btn-tertiary--plus-minus-icon:before {
      width: 15px;
      height: 3px;
      top: calc(50% - 1px);
      right: 20px; }
    .c-btn-tertiary--plus-minus-icon:after {
      width: 3px;
      height: 15px;
      top: calc(50% - 7px);
      right: 26px;
      transition: transform 0.3s ease-in-out; }
    .c-btn-tertiary--plus-minus-icon.is-active:after {
      transform: rotate(90deg); }
  .c-btn-tertiary--large {
    font-size: 24px;
    line-height: 31px;
    padding: 16px 48px; }
    @media (min-width: 980px) {
      .c-btn-tertiary--large {
        font-size: 36px;
        line-height: 46px; } }
  .c-btn-tertiary--raised {
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.21); }
  .c-btn-tertiary.is-disabled {
    opacity: .4;
    cursor: not-allowed; }
  @media (max-width: 540px) {
    .c-btn-tertiary--mobile-full-width {
      width: 100%;
      max-width: none;
      display: block; } }
  @media (min-width: 540px) {
    .c-btn-tertiary--margin-bottom-0-xs {
      margin-bottom: 0; } }
  @media (min-width: 800px) {
    .c-btn-tertiary--more-padding {
      padding: 20px 36px; } }
  @media (min-width: 980px) {
    .c-btn-tertiary--icon-next:after {
      content: ""; } }
  .c-btn-tertiary:after {
    transition-property: left, right;
    transition-duration: .2s;
    transition-timing-function: ease-in-out;
    content: '';
    position: absolute;
    left: 51%;
    right: 51%;
    bottom: -2px;
    background-color: #000000;
    height: 2px; }
  .c-btn-tertiary:hover:after, .c-btn-tertiary:focus:after, .c-btn-tertiary:active:after {
    left: 0;
    right: 0; }
  .c-btn-tertiary::after {
    left: 20px;
    right: 20px;
    bottom: 12px;
    transition: transform .2s ease-in-out; }
  .c-btn-tertiary:hover::after, .c-btn-tertiary:focus::after, .c-btn-tertiary:active::after {
    left: 20px;
    right: 20px;
    transform: translateY(4px); }
  .c-btn-tertiary:hover, .c-btn-tertiary:focus {
    color: #000000; }
  @media (min-width: 980px) {
    .c-btn-tertiary {
      font-size: 16px;
      padding: 0; }
      .c-btn-tertiary::after {
        left: 0;
        right: 0;
        bottom: 7px; }
      .c-btn-tertiary:hover::after, .c-btn-tertiary:focus::after, .c-btn-tertiary:active::after {
        left: 0;
        right: 0; } }

@keyframes fadeInButtonShadow {
  0% {
    box-shadow: inset 0px 2px 4px rgba(234, 67, 16, 0.21); }
  50% {
    box-shadow: none; }
  100% {
    box-shadow: 0px 2px 4px rgba(234, 67, 16, 0.21); } }

@keyframes fadeOutButtonShadow {
  0% {
    box-shadow: 0px 2px 4px rgba(234, 67, 16, 0.21); }
  50% {
    box-shadow: none; }
  100% {
    box-shadow: inset 0px 2px 4px rgba(234, 67, 16, 0.21); } }

@keyframes fadeInButtonShadowValid {
  0% {
    box-shadow: inset 0px 2px 4px rgba(85, 140, 33, 0.21); }
  50% {
    box-shadow: none; }
  100% {
    box-shadow: 0px 2px 4px rgba(85, 140, 33, 0.21); } }

@keyframes fadeOutButtonShadowValid {
  0% {
    box-shadow: 0px 2px 4px rgba(85, 140, 33, 0.21); }
  50% {
    box-shadow: none; }
  100% {
    box-shadow: inset 0px 2px 4px rgba(85, 140, 33, 0.21); } }

@keyframes fadeInButtonShadowDeselected {
  0% {
    box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.21); }
  50% {
    box-shadow: none; }
  100% {
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.21); } }

@keyframes fadeOutButtonShadowDeselected {
  0% {
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.21); }
  50% {
    box-shadow: none; }
  100% {
    box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.21); } }

.c-btn-rounded {
  font-family: "futura-pt", sans-serif;
  font-weight: 700;
  display: inline-block;
  padding: 8px 20px;
  text-decoration: none;
  text-align: center;
  margin-bottom: 16px;
  transition-property: background-color, color, transform, box-shadow, border-color;
  transition-duration: .2s, .2s, .2s, .2s;
  transition-timing-function: ease-in-out, ease-in-out, ease-in-out, ease-in-out;
  font-size: 16px;
  line-height: 24px;
  display: inline-block;
  padding-left: 24px;
  padding-right: 24px;
  border-radius: 24px;
  border: 1px solid #f2663b;
  background-color: #fef8f7;
  font-family: "futura-pt", sans-serif;
  font-weight: 500;
  color: #000000;
  box-shadow: 0px 2px 4px rgba(234, 67, 16, 0.21);
  animation: fadeInButtonShadow 200ms;
  min-width: 295px; }
  @media (min-width: 980px) {
    .c-btn-rounded {
      font-size: 20px;
      line-height: 30px; } }
  .c-btn-rounded[class*="u-before-icon"]:before {
    margin-right: 8px; }
  .c-btn-rounded[class*="u-after-icon"]:after {
    margin-left: 8px; }
  .c-btn-rounded--full-width {
    width: 100%;
    max-width: none;
    display: block; }
  .c-btn-rounded--icon-down:after {
    font-family: 'ffreesicons', sans-serif;
    font-weight: 500;
    font-style: normal;
    content: "";
    display: inline-block;
    margin-left: 8px; }
  .c-btn-rounded--icon-next:after {
    font-family: 'ffreesicons', sans-serif;
    font-weight: 500;
    font-style: normal;
    content: "";
    display: inline-block;
    margin-left: 8px; }
  .c-btn-rounded--float-right {
    float: right;
    margin-right: 0; }
  .c-btn-rounded--small-header {
    padding: 8px 16px;
    font-size: 14px;
    line-height: 1.5; }
  .c-btn-rounded--margin-right-s {
    margin-right: 16px; }
  .c-btn-rounded--margin-bottom-s {
    margin-bottom: 16px; }
  .c-btn-rounded--margin-0 {
    margin: 0; }
  .c-btn-rounded--margin-bottom-0 {
    margin-bottom: 0; }
  .c-btn-rounded--more-padding {
    padding: 20px; }
  .c-btn-rounded--nowrap {
    white-space: nowrap; }
  .c-btn-rounded--text-align-left {
    text-align: left; }
  .c-btn-rounded--icon-only[class*="u-before-icon"]:before {
    margin-right: 0; }
  .c-btn-rounded--icon-only[class*="u-after-icon"]:after {
    margin-left: 0; }
  .c-btn-rounded--plus-minus-icon {
    position: relative; }
    .c-btn-rounded--plus-minus-icon:before, .c-btn-rounded--plus-minus-icon:after {
      position: absolute;
      content: '';
      background-color: currentColor;
      display: block; }
    .c-btn-rounded--plus-minus-icon:before {
      width: 15px;
      height: 3px;
      top: calc(50% - 1px);
      right: 20px; }
    .c-btn-rounded--plus-minus-icon:after {
      width: 3px;
      height: 15px;
      top: calc(50% - 7px);
      right: 26px;
      transition: transform 0.3s ease-in-out; }
    .c-btn-rounded--plus-minus-icon.is-active:after {
      transform: rotate(90deg); }
  .c-btn-rounded--large {
    font-size: 24px;
    line-height: 31px;
    padding: 16px 48px; }
    @media (min-width: 980px) {
      .c-btn-rounded--large {
        font-size: 36px;
        line-height: 46px; } }
  .c-btn-rounded--raised {
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.21); }
  .c-btn-rounded.is-disabled {
    opacity: .4;
    cursor: not-allowed; }
  @media (max-width: 540px) {
    .c-btn-rounded--mobile-full-width {
      width: 100%;
      max-width: none;
      display: block; } }
  @media (min-width: 540px) {
    .c-btn-rounded--margin-bottom-0-xs {
      margin-bottom: 0; } }
  @media (min-width: 800px) {
    .c-btn-rounded--more-padding {
      padding: 20px 36px; } }
  @media (min-width: 980px) {
    .c-btn-rounded--icon-next:after {
      content: ""; } }
  .c-btn-rounded:hover, .c-btn-rounded:focus, .c-btn-rounded:active {
    color: #000000;
    box-shadow: inset 0px 2px 4px rgba(234, 67, 16, 0.21);
    animation: fadeOutButtonShadow 200ms; }
  .c-btn-rounded.is-valid {
    border-color: #6eb52b;
    background-color: #eff9e6;
    box-shadow: 0px 2px 4px rgba(85, 140, 33, 0.21);
    animation: fadeInButtonShadowValid 200ms; }
    .c-btn-rounded.is-valid:hover, .c-btn-rounded.is-valid:focus, .c-btn-rounded.is-valid:active {
      box-shadow: inset 0px 2px 4px rgba(85, 140, 33, 0.21);
      animation: fadeOutButtonShadowValid 200ms; }
  .c-btn-rounded.is-selected {
    background-color: #fcded5; }
  .c-btn-rounded.is-deselected {
    background-color: white;
    border-color: #afafaf;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.21);
    animation: fadeInButtonShadowDeselected 200ms; }
    .c-btn-rounded.is-deselected:hover, .c-btn-rounded.is-deselected:focus, .c-btn-rounded.is-deselected:active {
      box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.21);
      animation: fadeOutButtonShadowDeselected 200ms; }
  .c-btn-rounded.is-disabled {
    background-color: white;
    border-color: #afafaf;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.21);
    opacity: 1;
    animation: none; }

.styleguide .c-btn-small-square--position-fixed-right-top,
.styleguide .c-btn-small-square--position-fixed-right-bottom {
  position: absolute; }

/*doc
---
title: Buttons
name: buttons
category: Buttons
---

```html_example_table
    <button class="c-btn">Button</button>

    <button data-parent-classes="u-sg-darkgrey-bg" class="c-btn c-btn--1-light">Button</button>

    <button class="c-btn c-btn--2">Button</button>

    <button class="c-btn c-btn--3">Button</button>

    <button class="c-btn c-btn--4">Button</button>

    <button class="c-btn c-btn--5">Button</button>

    <button data-parent-classes="u-sg-darkgrey-bg" class="c-btn c-btn--white">Button</button>

    <button class="c-btn c-btn--black">Button</button>

```
<div class="c-sg-modifier-wrap js-modifier-wrap">
    <div>
        <button>c-btn--full-width</button>
        <button>c-btn--mobile-full-width</button>
    </div>
    <div>
        <button>c-btn--raised</button>
    </div>
    <div>
        <button>c-btn--small-header</button>
        <button>c-btn--large</button>
    </div>
    <div>
        <button>c-btn--more-padding</button>
    </div>
    <div>
        <button>c-btn--margin-0</button>
    </div>
    <div>
        <button>c-btn--margin-right-s</button>
    </div>
    <div>
        <button>c-btn--margin-bottom-0</button>
        <button>c-btn--margin-bottom-0-xs</button>
    </div>
    <div>
        <button>c-btn--text-align-left</button>
    </div>
    <div>
        <button>c-btn--icon-only</button>
    </div>
    <div>
        <button>c-btn--plus-minus-icon</button>
        <button>c-btn--plus-minus-icon is-active</button>
    </div>
    <div>
        <button>is-disabled</button>
    </div>
    <div>
        <button>c-btn--float-right</button>
    </div>
</div>

*/
/*doc
---
title: Buttons rounded
name: buttons-rounded
category: Buttons
---

```html_example_table
    <button class="c-btn-rounded">Button</button>

```
<div class="c-sg-modifier-wrap js-modifier-wrap">
    <div>
        <button>is-valid</button>
        <button>is-disabled</button>
    </div>
    <div>
        <button>c-btn-rounded--full-width</button>
        <button>c-btn-rounded--mobile-full-width</button>
    </div>
    <div>
        <button>c-btn-rounded--margin-left-s</button>
    </div>
    <div>
        <button>c-btn-rounded--margin-right-0</button>
        <button>c-btn-rounded--margin-right-xs</button>
        <button>c-btn-rounded--margin-right-s</button>
    </div>
    <div>
        <button>c-btn-rounded--more-padding</button>
    </div>
    <div>
        <button>c-btn-rounded--margin-0</button>
    </div>
    <div>
        <button>c-btn-rounded--margin-bottom-0</button>
        <button>c-btn-rounded--margin-bottom-0-xs</button>
    </div>
    <div>
        <button>c-btn-rounded--text-align-left</button>
    </div>
    <div>
        <button>c-btn-rounded--float-right</button>
    </div>
</div>

*/
/*doc
---
title: Buttons outlined
name: buttons-outlined
category: Buttons
---

```html_example_table
    <button class="c-btn-outline">Button</button>

    <button class="c-btn-outline c-btn-outline--2">Button</button>

    <button class="c-btn-outline c-btn-outline--3">Button</button>

    <button class="c-btn-outline c-btn-outline--4">Button</button>

    <button class="c-btn-outline c-btn-outline--5">Button</button>

    <button data-parent-classes="u-sg-darkgrey-bg" class="c-btn-outline c-btn-outline--white">Button</button>

    <button class="c-btn-outline c-btn-outline--black">Button</button>
```

<div class="c-sg-modifier-wrap js-modifier-wrap">
    <div>
        <button>c-btn-outline--full-width</button>
        <button>c-btn-outline--mobile-full-width</button>
    </div>
    <div>
        <button>c-btn-outline--small-header</button>
        <button>c-btn-outline--large</button>
    </div>
    <div>
        <button>c-btn-outline--more-padding</button>
    </div>
    <div>
        <button>c-btn-outline--margin-0</button>
    </div>
    <div>
        <button>c-btn-outline--margin-right-s</button>
    </div>
    <div>
        <button>c-btn-outline--margin-bottom-0</button>
        <button>c-btn-outline--margin-bottom-0-xs</button>
    </div>
    <div>
        <button>c-btn-outline--text-align-left</button>
    </div>
    <div>
        <button>c-btn-outline--icon-only</button>
    </div>
    <div>
        <button>c-btn-outline--plus-minus-icon</button>
        <button>c-btn-outline--plus-minus-icon is-active</button>
    </div>
    <div>
        <button>is-disabled</button>
    </div>
    <div>
        <button>c-btn-outline--float-right</button>
    </div>
</div>

*/
/*doc
---
title: Buttons small square
name: buttons-small-square
category: Buttons
---

```html_example_table
    <button data-parent-classes="u-sg-height-200" class="c-btn-small-square u-icon-cancel">Button</button>

    <button data-parent-classes="u-sg-height-200" class="c-btn-small-square c-btn-small-square--2 u-icon-cancel">Button</button>

    <button data-parent-classes="u-sg-height-200" class="c-btn-small-square c-btn-small-square--3 u-icon-cancel">Button</button>

    <button data-parent-classes="u-sg-height-200" class="c-btn-small-square c-btn-small-square--4 u-icon-cancel">Button</button>

    <button data-parent-classes="u-sg-height-200" class="c-btn-small-square c-btn-small-square--5 u-icon-cancel">Button</button>

    <button data-parent-classes="u-sg-height-200 u-sg-darkgrey-bg" class="c-btn-small-square c-btn-small-square--white u-icon-cancel">Button</button>

    <button data-parent-classes="u-sg-height-200" class="c-btn-small-square c-btn-small-square--black u-icon-cancel">Button</button>
```

<div class="c-sg-modifier-wrap js-modifier-wrap">
    <div>
        <button>c-btn-small-square--margin-0</button>
    </div>
    <div>
        <button>c-btn-small-square--margin-right-s</button>
    </div>
    <div>
        <button>c-btn-small-square--margin-bottom-0</button>
        <button>c-btn-small-square--margin-bottom-0-xs</button>
    </div>
    <div>
        <button>is-disabled</button>
    </div>
    <div>
        <button>c-btn-small-square--float-right</button>
    </div>
    <div>
        <button>c-btn-small-square--position-fixed-right-top</button>
        <button>c-btn-small-square--position-fixed-right-bottom</button>
    </div>
</div>

*/
/*doc
---
title: Buttons circled
name: buttons-circled
category: Buttons
---

```html_example_table
    <button class="c-btn-circle">
        <div class="c-btn-circle__content">Button</div>
    </button>

    <button data-parent-classes="u-sg-darkgrey-bg" class="c-btn-circle c-btn-circle--1-light">
        <div class="c-btn-circle__content">Button</div>
    </button>

    <button class="c-btn-circle c-btn-circle--2">
        <div class="c-btn-circle__content">Button</div>
    </button>

    <button class="c-btn-circle c-btn-circle--3">
        <div class="c-btn-circle__content">Button</div>
    </button>

    <button class="c-btn-circle c-btn-circle--4">
        <div class="c-btn-circle__content">Button</div>
    </button>

    <button class="c-btn-circle c-btn-circle--5">
        <div class="c-btn-circle__content">Button</div>
    </button>

    <button data-parent-classes="u-sg-darkgrey-bg" class="c-btn-circle c-btn-circle--white">
        <div class="c-btn-circle__content">Button</div>
    </button>

    <button class="c-btn-circle c-btn-circle--black">
        <div class="c-btn-circle__content">Button</div>
    </button>
```

<div class="c-sg-modifier-wrap js-modifier-wrap">
    <div>
        <button>c-btn-circle--raised</button>
    </div>
    <div>
        <button>c-btn-circle--margin-left-s</button>
    </div>
    <div>
        <button>c-btn-circle--margin-right-s</button>
    </div>
    <div>
        <button>c-btn-circle--margin-bottom-0</button>
        <button>c-btn-circle--margin-bottom-0-xs</button>
    </div>
    <div>
        <button>is-disabled</button>
    </div>
    <div>
        <button>c-btn-circle--float-right</button>
    </div>
</div>
*/
/*doc
---
title: Buttons small round
name: buttons-small-round
category: Buttons
---

```html_example_table
    <button class="c-btn-small-round">
        <div class="c-btn-small-round__content">Send</div>
    </button>

    <button class="c-btn-small-round">
        <div class="c-btn-small-round__content u-before-icon-paper-plane"></div>
    </button>
```

<div class="c-sg-modifier-wrap js-modifier-wrap">
    <div>
        <button>c-btn-small-round--large</button>
    </div>
    <div>
        <button>c-btn-small-round--margin-0</button>
    </div>
    <div>
        <button>c-btn-small-round--margin-right-s</button>
    </div>
    <div>
        <button>c-btn-small-round--margin-bottom-0</button>
        <button>c-btn-small-round--margin-bottom-0-xs</button>
    </div>
    <div>
        <button>is-disabled</button>
    </div>
    <div>
        <button>c-btn-small-round--float-right</button>
    </div>
</div>
*/
/*doc
---
title: Buttons block
name: buttons-block
category: Buttons
---

```html_example
    <button class="c-btn-block">
        <span class="c-btn-block__content">
            Button
            <span class="c-btn-block__subtext">Subtext</span>
        </span>
    </button>
```

<div class="c-sg-modifier-wrap js-modifier-wrap">
    <div>
        <button>c-btn-block--with-subtext</button>
    </div>
    <div>
        <button>c-btn-block--full-width</button>
    </div>
    <div>
        <button>c-btn-block--margin-0</button>
    </div>
    <div>
        <button>c-btn-block--margin-right-s</button>
    </div>
    <div>
        <button>c-btn-block--margin-bottom-0</button>
        <button>c-btn-block--margin-bottom-0-xs</button>
    </div>
    <div>
        <button>c-btn-block--text-align-left</button>
    </div>
    <div>
        <button>c-btn-block--icon-only</button>
    </div>
    <div>
        <button>c-btn-block--plus-minus-icon</button>
        <button>c-btn-block--plus-minus-icon is-active</button>
    </div>
    <div>
        <button>is-disabled</button>
    </div>
    <div>
        <button>c-btn-block--justify-content-flex-start</button>
    </div>
    <div>
        <button>c-btn-block--no-fixed-height</button>
    </div>
</div>
*/
/*doc
---
title: Buttons block (Dot Dot Loans example)
name: buttons-block-dot-dot-loans-example
category: Buttons
---
Note - This button block component example also includes an image, as well as a number of modifiers available to the component

```html_example
    <a href="#" class="c-btn-block c-btn-block--full-width c-btn-block--justify-content-flex-start c-btn-block--text-align-left c-btn-block--no-fixed-height c-btn-block--dot-dot-loans">
        <img class="c-btn-block__image" src="img/dotdotlogo.png" alt="Dot Dot Loans Logo" />
        <span class="c-btn-block__content">
            Simple online loans from £200 - £1,000*
        </span>
    </a>
```

<div class="c-sg-modifier-wrap js-modifier-wrap">
    <div>
        <button>c-btn-block--with-subtext</button>
    </div>
    <div>
        <button>c-btn-block--full-width</button>
    </div>
    <div>
        <button>c-btn-block--margin-0</button>
    </div>
    <div>
        <button>c-btn-block--margin-right-s</button>
    </div>
    <div>
        <button>c-btn-block--margin-bottom-0</button>
        <button>c-btn-block--margin-bottom-0-xs</button>
    </div>
    <div>
        <button>c-btn-block--text-align-left</button>
    </div>
    <div>
        <button>c-btn-block--icon-only</button>
    </div>
    <div>
        <button>c-btn-block--plus-minus-icon</button>
        <button>c-btn-block--plus-minus-icon is-active</button>
    </div>
    <div>
        <button>is-disabled</button>
    </div>
    <div>
        <button>c-btn-block--justify-content-flex-start</button>
    </div>
    <div>
        <button>c-btn-block--no-fixed-height</button>
    </div>
</div>
*/
/*doc
---
title: Button tertiary
name: button-tertiary
category: Buttons
---

```html_example
    <button class="c-btn-tertiary">Log out</button>
```

Note - other modifiers can be applied to this component (as it uses the base buttons styles mixin), but here all of the relevant modifiers which won't break the component are shown.

<div class="c-sg-modifier-wrap js-modifier-wrap">
    <div>
        <button>c-btn-tertiary--margin-0</button>
    </div>
    <div>
        <button>c-btn-tertiary--margin-right-s</button>
    </div>
    <div>
        <button>c-btn-tertiary--margin-bottom-0</button>
        <button>c-btn-tertiary--margin-bottom-0-xs</button>
    </div>
    <div>
        <button>is-disabled</button>
    </div>
</div>
*/
.c-card {
  position: relative;
  display: block;
  color: inherit;
  font-weight: inherit;
  text-decoration: none;
  z-index: 1; }
  .c-card:hover, .c-card:focus {
    color: inherit; }
  .c-card__title {
    margin-bottom: 16px;
    font-size: 22px;
    line-height: 29px; }
    @media (min-width: 980px) {
      .c-card__title {
        font-size: 28px;
        line-height: 40px; } }
  .c-card__image-header {
    position: relative;
    margin: 16px; }
  .c-card__header-image {
    display: block;
    margin: auto;
    max-height: 90px; }
  .c-card--padding, .c-card--padding-xs-bp, .c-card--padding-less {
    padding: 12px; }
  .c-card--raised {
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.21); }
  .c-card--extra-raised {
    box-shadow: 5px 5px 30px rgba(0, 0, 0, 0.25); }
  .c-card--white-bg {
    background: #ffffff; }
  .c-card--interactive {
    transition: transform 300ms ease-in-out; }
    .c-card--interactive:hover, .c-card--interactive:focus {
      transform: scale(1.08); }
  .c-card--interactive.c-card--raised {
    transition: transform 300ms ease-in-out, box-shadow 300ms ease-in-out; }
    .c-card--interactive.c-card--raised:hover, .c-card--interactive.c-card--raised:focus {
      box-shadow: 1px 1px 8px 1px rgba(0, 0, 0, 0.3); }
  @media (min-width: 540px) {
    .c-card__image-header {
      height: 100px;
      margin: 24px; }
    .c-card__header-image {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate3d(-50%, -50%, 0);
      max-height: 76px; }
    .c-card--full-height {
      height: 100%; }
    .c-card--padding-xs-bp {
      padding: 36px 48px; } }
  @media (min-width: 800px) {
    .c-card__indent {
      padding-left: 60px; }
    .c-card__image-header {
      margin-top: 0; }
    .c-card--padding {
      padding: 36px 48px; }
    .c-card--padding-less {
      padding: 24px; }
    .c-card--raised-s-bp {
      box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.21); } }

.borderRadius {
  border-radius: 10px; }

/*doc
---
title: Card
name: card
category: Components
---

```html_example
<div class="c-card">
    <h2 class="c-card__title">Card title</h2>
    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed suscipit ullamcorper luctus. Curabitur fermentum mollis feugiat. Quisque placerat sem nec est cursus, quis convallis est fringilla. Sed ut libero massa. Sed at tincidunt magna. Nunc ex sem, imperdiet vitae placerat sed, luctus ac ligula. Proin facilisis ornare blandit. Nunc interdum eros a maximus pharetra.</p>
    <div class="c-card__indent">
        <p>Curabitur eleifend faucibus quam nec gravida. In at justo ac dolor auctor faucibus id in mi. Donec et nunc ante. Nunc ultricies at enim nec lacinia. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Nulla sit amet neque lacus. Suspendisse potenti. Pellentesque non diam nulla.</p>
    </div>
</div>
```

<div class="c-sg-modifier-wrap js-modifier-wrap">
    <div>
        <button>c-card--padding</button>
        <button>c-card--padding-xs-bp</button>
        <button>c-card--padding-less</button>
    </div>
    <div>
        <button>c-card--full-height</button>
    </div>
    <div>
        <button>c-card--raised</button>
        <button>c-card--raised-s-bp</button>
        <button>c-card--extra-raised</button>
    </div>
    <div>
        <button>c-card--interactive</button>
    </div>
    <div>
        <button>c-card--white-bg</button>
    </div>
</div>

```html_example
<div class="l-row u-display-flex u-flex-wrap">
    <div class="l-col-xs-50 l-col-m-33">
        <div class="c-card">
            <div class="c-card__image-header">
                <img class="c-card__header-image" src="img/advice-and-support-logos/ND.png" alt="National Debtline"/>
            </div>
            <p>Free advice from expert debt advisors, plus step-by-step guides and answers to common questions on dealing with debt and managing your income. Call or chat online.</p>
        </div>
    </div>
    <div class="l-col-xs-50 l-col-m-33">
        <div class="c-card">
            <div class="c-card__image-header">
                <img class="c-card__header-image" src="img/advice-and-support-logos/CA.svg" alt="Citizens Advice"/>
            </div>
            <p>Local centres offering face-to-face advice on everything from managing overdue payments to applying for benefits, plus helpful online information and a 5-step process for dealing with debt. </p>
        </div>
    </div>
    <div class="l-col-xs-50 l-col-m-33">
        <div class="c-card">
            <div class="c-card__image-header">
                <img class="c-card__header-image" src="img/advice-and-support-logos/DA.png" alt="Debt Advice Foundation"/>
            </div>
            <p>UK charity offering a free and confidential phone line for advice on a full range of debt solutions, plus online tools like a debt analyser, budget planner and money-management guide.</p>
        </div>
    </div>
</div>
```

<div class="c-sg-modifier-wrap js-modifier-wrap">
    <div>
        <button data-target="c-card">c-card--padding</button>
        <button data-target="c-card">c-card--padding-xs-bp</button>
        <button data-target="c-card">c-card--padding-less</button>
    </div>
    <div>
        <button data-target="c-card">c-card--full-height</button>
    </div>
    <div>
        <button data-target="c-card">c-card--raised</button>
        <button data-target="c-card">c-card--raised-s-bp</button>
        <button data-target="c-card">c-card--extra-raised</button>
    </div>
    <div>
        <button data-target="c-card">c-card--interactive</button>
    </div>
    <div>
        <button data-target="c-card">c-card--white-bg</button>
    </div>
</div>
  */
.c-checkbox-radio {
  margin-bottom: 24px; }
  .c-checkbox-radio:after {
    content: "";
    display: table;
    clear: both; }
  .c-checkbox-radio__label {
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;
    margin: 0;
    font-weight: 100; }
  .c-checkbox-radio__label-indicator {
    position: relative;
    display: flex;
    width: 28px;
    height: 28px;
    border: 1px solid #afafaf;
    border-radius: 100%;
    margin-right: 12px;
    background: #ffffff;
    justify-content: center;
    align-items: center;
    flex-shrink: 0; }
    .c-checkbox-radio__label-indicator:before {
      content: "";
      font-family: 'ffreesicons', sans-serif;
      font-weight: 500;
      font-style: normal;
      font-size: 20px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: none; }
  .c-checkbox-radio__label-subtext {
    display: block;
    font-size: 12px;
    line-height: 18px;
    color: #f2663b; }
    @media (min-width: 980px) {
      .c-checkbox-radio__label-subtext {
        font-size: 14px;
        line-height: 18px; } }
  .c-checkbox-radio__input {
    margin: -1px;
    padding: 0;
    width: 1px;
    height: 1px;
    overflow: hidden;
    clip: rect(0 0 0 0);
    clip: rect(0, 0, 0, 0);
    position: absolute; }
    .c-checkbox-radio__input:checked + .c-checkbox-radio__label .c-checkbox-radio__label-indicator {
      border-color: #6eb52b; }
      .c-checkbox-radio__input:checked + .c-checkbox-radio__label .c-checkbox-radio__label-indicator:before {
        display: block; }
  .c-checkbox-radio--checkbox-right {
    margin-right: 0; }
    .c-checkbox-radio--checkbox-right .c-checkbox-radio__label-indicator {
      order: 2;
      margin-right: 0;
      margin-left: auto; }
    .c-checkbox-radio--checkbox-right .c-checkbox-radio__label-text {
      order: 1;
      margin-right: 20px; }
  .c-checkbox-radio--preferences:nth-child(1n) .c-checkbox-radio__label {
    border-radius: 24px; }
  .c-checkbox-radio--border-bottom .c-checkbox-radio__label::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 7px;
    background: #f8f8f8;
    border-radius: 7px; }
  .c-checkbox-radio--border-bottom .c-checkbox-radio__input:focus + .c-checkbox-radio__label, .c-checkbox-radio--border-bottom .c-checkbox-radio__input:checked + .c-checkbox-radio__label {
    border-radius: 24px; }
    .c-checkbox-radio--border-bottom .c-checkbox-radio__input:focus + .c-checkbox-radio__label::after, .c-checkbox-radio--border-bottom .c-checkbox-radio__input:checked + .c-checkbox-radio__label::after {
      display: none; }
  .c-checkbox-radio--margin-bottom-s {
    margin-bottom: 16px; }
  .c-checkbox-radio--margin-bottom-0 {
    margin-bottom: 0; }
  .c-checkbox-radio.is-loading .c-checkbox-radio__label {
    cursor: default; }
  .c-checkbox-radio.is-loading .c-checkbox-radio__label-indicator.c-checkbox-radio__label-indicator.c-checkbox-radio__label-indicator {
    border-color: transparent;
    background: transparent; }
    .c-checkbox-radio.is-loading .c-checkbox-radio__label-indicator.c-checkbox-radio__label-indicator.c-checkbox-radio__label-indicator:before {
      display: none; }
    .c-checkbox-radio.is-loading .c-checkbox-radio__label-indicator.c-checkbox-radio__label-indicator.c-checkbox-radio__label-indicator:after {
      content: "";
      display: block;
      width: auto;
      height: auto;
      font-family: 'ffreesicons', sans-serif;
      font-weight: 500;
      font-style: normal;
      color: #f2663b;
      text-align: center;
      animation: rotate 0.8s linear infinite; }

@keyframes rotate {
  0% {
    transform: rotate(0deg) scale(1); }
  100% {
    transform: rotate(360deg) scale(1); } }
  @media (min-width: 980px) {
    .c-checkbox-radio__label-indicator {
      margin-right: 24px; }
    .c-checkbox-radio--border-bottom .c-checkbox-radio__label, .c-checkbox-radio--grey-bg .c-checkbox-radio__label {
      padding-left: 24px; }
    .c-checkbox-radio--checkbox-right.c-checkbox-radio--border-bottom .c-checkbox-radio__label, .c-checkbox-radio--checkbox-right.c-checkbox-radio--grey-bg .c-checkbox-radio__label {
      padding-left: 20px;
      padding-right: 24px; } }

.c-checkbox-radio:nth-child(odd) {
  background: #f6f6f6;
  border-radius: 25px; }

/*doc
---
title: checkbox radio
name: input-checkbox-radio
category: Inputs
---

Note <code>c-checkbox-radio__label-subtext</code> is optional, remove this element if it is not needed.

```html_example
<div class="c-checkbox-radio">
    <input class="c-checkbox-radio__input showManualAddress" type="checkbox" id="manuallyAddAddress">
    <label class="c-checkbox-radio__label" for="manuallyAddAddress">
        <div class="c-checkbox-radio__label-indicator"></div>
        <div class="c-checkbox-radio__label-text">Manually Enter Address
            <div class="c-checkbox-radio__label-subtext">(opted in at 14:24 on 24 Feb 2018)</div>
        </div>
    </label>
</div>
```
<div class="c-sg-modifier-wrap js-modifier-wrap">
    <div>
        <button>is-loading</button>
    </div>
    <div>
        <button>c-checkbox-radio--checkbox-right</button>
    </div>
    <div>
        <button>c-checkbox-radio--grey-bg</button>
        <button>c-checkbox-radio--border-bottom</button>
    </div>
</div>

Common layouts:

```html_example_table
<div class="c-checkbox-radio">
    <input class="c-checkbox-radio__input showManualAddress" type="checkbox" id="manuallyAddAddress2">
    <label class="c-checkbox-radio__label" for="manuallyAddAddress2">
        <div class="c-checkbox-radio__label-indicator"></div>
        <div class="c-checkbox-radio__label-text">Manually Enter Address
            <div class="c-checkbox-radio__label-subtext">(opted in at 14:24 on 24 Feb 2018)</div>
        </div>
    </label>
</div>

<div class="c-checkbox-radio c-checkbox-radio--grey-bg">
    <input class="c-checkbox-radio__input showManualAddress" type="checkbox" id="manuallyAddAddress3">
    <label class="c-checkbox-radio__label" for="manuallyAddAddress3">
        <div class="c-checkbox-radio__label-indicator"></div>
        <div class="c-checkbox-radio__label-text">Manually Enter Address
            <div class="c-checkbox-radio__label-subtext">(opted in at 14:24 on 24 Feb 2018)</div>
        </div>
    </label>
</div>

<div class="c-checkbox-radio c-checkbox-radio--checkbox-right c-checkbox-radio--grey-bg">
    <input class="c-checkbox-radio__input showManualAddress" type="checkbox" id="manuallyAddAddress4">
    <label class="c-checkbox-radio__label" for="manuallyAddAddress4">
        <div class="c-checkbox-radio__label-indicator"></div>
        <div class="c-checkbox-radio__label-text">Manually Enter Address
            <div class="c-checkbox-radio__label-subtext">(opted in at 14:24 on 24 Feb 2018)</div>
        </div>
    </label>
</div>

<div class="c-checkbox-radio c-checkbox-radio--checkbox-right c-checkbox-radio--border-bottom">
    <input class="c-checkbox-radio__input showManualAddress" type="checkbox" id="manuallyAddAddress5">
    <label class="c-checkbox-radio__label" for="manuallyAddAddress5">
        <div class="c-checkbox-radio__label-indicator"></div>
        <div class="c-checkbox-radio__label-text">Manually Enter Address
            <div class="c-checkbox-radio__label-subtext">(opted in at 14:24 on 24 Feb 2018)</div>
        </div>
    </label>
</div>
```
<div class="c-sg-modifier-wrap js-modifier-wrap">
    <div>
        <button>is-loading</button>
    </div>
    <div>
        <button>c-checkbox-radio--checkbox-right</button>
    </div>
    <div>
        <button>c-checkbox-radio--grey-bg</button>
        <button>c-checkbox-radio--border-bottom</button>
    </div>
    <div>
        <button>c-checkbox-radio--margin-bottom-s</button>
    </div>
    <div>
        <button>c-checkbox-radio--margin-bottom-0</button>
    </div>
</div>
*/
.c-create-name__input-holder {
  transition: opacity .3s ease-in-out 1.6s, background .3s ease-in-out;
  width: 100%;
  position: relative;
  display: inline-block; }
  .c-create-name__input-holder::after {
    content: '\23CE';
    display: block;
    opacity: 0;
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translateY(-50%);
    color: #afafaf;
    font-size: 24px;
    transition: opacity .3s ease-in-out; }

.c-create-name__input {
  outline: none;
  display: block;
  width: 100%;
  transition: all .2s ease-in-out;
  height: auto;
  padding: 0;
  margin-bottom: 28px;
  font-size: 30px;
  line-height: 38px;
  margin-bottom: 0;
  border: 0;
  border-radius: 0;
  color: #f2663b;
  background-color: transparent;
  font-family: "futura-pt", sans-serif;
  font-weight: 700; }
  @media (min-width: 980px) {
    .c-create-name__input {
      margin-bottom: 36px;
      font-size: 44px;
      line-height: 56px; } }
  .c-create-name__input:hover {
    box-shadow: inset 0 -2px 0 0 #ebebeb; }
  .c-create-name__input::-webkit-input-placeholder {
    color: #f2663b; }
  .c-create-name__input:-moz-placeholder {
    color: #f2663b;
    opacity: 1; }
  .c-create-name__input:-ms-input-placeholder {
    color: #f2663b; }

.c-create-name--create {
  background-color: transparent;
  box-shadow: inset 0 -2px 0 0 #ebebeb; }
  .c-create-name--create::after {
    opacity: 1; }

@media (min-width: 800px) {
  .c-create-name__input {
    text-align: left; } }

@media (min-width: 980px) {
  .c-create-name__input {
    margin-bottom: 0; } }

/*doc
---
title: create name
name: create-name
category: Components
---

```html_example
<div class="c-create-name js-create-name">

    <div class="c-create-name__input-holder js-name-input-holder">
        <input type="text" class="c-create-name__input js-custom-name-input" value="Your" maxlength="12" />
    </div>

</div>

<h1 class="u-heading-secondary u-color-1 u-text-align-left">new current account</h1>

```
<div class="c-sg-modifier-wrap js-modifier-wrap">
    <div>
        <button data-target="c-create-name__input-holder">c-create-name--create</button>
    </div>
    <div>
        <button data-target="c-create-name__input-holder">c-create-name--created</button>
    </div>
</div>
*/
.c-datepicker {
  position: relative; }
  .c-datepicker__dropdown {
    position: absolute;
    top: 100%;
    width: 100%;
    padding: 12px 20px 16px;
    border: 1px solid #d1d3d4;
    border-top: 0;
    background: #ffffff;
    z-index: 10; }
  .c-datepicker__selectors {
    display: flex;
    justify-content: space-between; }
    .c-datepicker__selectors .c-input {
      width: 100%;
      margin-right: 16px;
      margin-bottom: 8px; }
      .c-datepicker__selectors .c-input:last-child {
        width: 40%;
        margin-right: 0; }
  .c-datepicker__calendar {
    width: 100%;
    table-layout: fixed;
    text-align: center; }
  .c-datepicker__calendar-row {
    border: 0; }
  .c-datepicker__heading, .c-datepicker__date {
    padding: 8px 0; }
  .c-datepicker__heading {
    color: #afafaf;
    font-family: "futura-pt", sans-serif;
    font-weight: 500; }
  .c-datepicker__date {
    cursor: pointer; }
    .c-datepicker__date:hover, .c-datepicker__date:focus {
      outline: none; }
      .c-datepicker__date:hover .c-datepicker__circle, .c-datepicker__date:focus .c-datepicker__circle {
        background-color: #fcded5; }
    .c-datepicker__date.is-selected .c-datepicker__circle {
      background-color: #f2663b;
      color: #ffffff; }
    .c-datepicker__date.is-selected:focus .c-datepicker__circle {
      background-color: #ea4310; }
    .c-datepicker__date.is-invalid {
      pointer-events: none; }
      .c-datepicker__date.is-invalid .c-datepicker__circle {
        color: #d1d3d4; }
    .c-datepicker__date.is-selected.is-invalid .c-datepicker__circle {
      background-color: transparent;
      color: #d1d3d4; }
    .c-datepicker__date.is-selected.is-invalid:focus .c-datepicker__circle {
      background-color: #fcded5; }
  .c-datepicker__circle {
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 28px;
    width: 28px;
    border-radius: 100%;
    background-color: rgba(255, 255, 255, 0);
    transition: background-color 100ms;
    z-index: 1; }

.c-dot-dot-loans-banner {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 0; }
  .c-dot-dot-loans-banner__logo {
    width: 173px;
    flex-shrink: 0;
    margin-bottom: 20px; }
  .c-dot-dot-loans-banner__title, .c-dot-dot-loans-banner__disclaimer {
    font-family: "futura-pt", sans-serif;
    font-weight: 700; }
  .c-dot-dot-loans-banner__title {
    font-size: 23px;
    line-height: 33px;
    font-family: "futura-pt", sans-serif;
    font-weight: 700;
    margin-bottom: 24px; }
  .c-dot-dot-loans-banner__disclaimer {
    font-size: 18.5px;
    line-height: 28px;
    margin-bottom: 0; }
    .c-dot-dot-loans-banner__disclaimer .c-text-link--blob {
      font-family: "futura-pt", sans-serif;
      font-weight: 700;
      background-color: #ebcada;
      box-shadow: -6px 0 0 0 #ebcada, 6px 0 0 0 #ebcada, 0 0 0 1px #ebcada, 6px 0 0 1px #ebcada, -6px 0 0 1px #ebcada; }
      .c-dot-dot-loans-banner__disclaimer .c-text-link--blob:hover, .c-dot-dot-loans-banner__disclaimer .c-text-link--blob:focus, .c-dot-dot-loans-banner__disclaimer .c-text-link--blob:active {
        box-shadow: -6px 0 0 0 #ebcada, 6px 0 0 0 #ebcada, 0 0 0 1px #e42386, 6px 0 0 1px #e42386, -6px 0 0 1px #e42386; }
  .c-dot-dot-loans-banner__btn {
    font-family: "futura-pt", sans-serif;
    font-weight: 700;
    display: inline-block;
    padding: 8px 20px;
    text-decoration: none;
    text-align: center;
    margin-bottom: 16px;
    transition-property: background-color, color, transform, box-shadow, border-color;
    transition-duration: .2s, .2s, .2s, .2s;
    transition-timing-function: ease-in-out, ease-in-out, ease-in-out, ease-in-out;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.21);
    font-size: 18.5px;
    line-height: 24px;
    color: #000000;
    background-color: #ffffff;
    margin-bottom: 20px; }
    .c-dot-dot-loans-banner__btn:hover, .c-dot-dot-loans-banner__btn:focus, .c-dot-dot-loans-banner__btn:active {
      color: #000000;
      background-color: #ebcada; }
  @media (min-width: 980px) {
    .c-dot-dot-loans-banner {
      flex-direction: row;
      padding: 28px 0;
      align-items: start; }
      .c-dot-dot-loans-banner__logo {
        width: 203px;
        order: 2; }
      .c-dot-dot-loans-banner__content {
        order: 1;
        margin-right: 60px; }
      .c-dot-dot-loans-banner__title {
        font-size: 28px;
        line-height: 36px;
        margin-bottom: 36px; }
      .c-dot-dot-loans-banner__disclaimer {
        font-size: 23.5px;
        line-height: 32px; }
      .c-dot-dot-loans-banner__btn {
        font-size: 23.5px;
        line-height: 30px;
        margin-bottom: 28px; } }

/*doc
---
title: dot dot loans banner
name: dot-dot-loans-banner
category: Organisms
---

```html_example
<div class="l-content-width l-content-width--max-width-1080">
    <div class="c-full-width-box c-full-width-box--slanted c-full-width-box--color-grey-4">
        <div class="c-full-width-box__content">
            <div class="c-dot-dot-loans-banner">
                <img class="c-dot-dot-loans-banner__logo" src="img/dotdotlogo.png" alt="Dot Dot Loans Logo" width="200px" />

                <div class="c-dot-dot-loans-banner__content">
                    <p class="c-dot-dot-loans-banner__title">Simple online loans from £200 - £1,000.</p>

                    <a class="c-dot-dot-loans-banner__btn" href="https://www.dotdotloans.co.uk/">Apply with Dot Dot today</a>

                    <p class="c-dot-dot-loans-banner__disclaimer">Warning: Late repayment can cause you serious money problems. For help, go to <a class="c-text-link c-text-link--blob" href="http://moneyadviceservice.org.uk/">moneyadviceservice.org.uk</a></p>
                </div>
            </div>
        </div>
    </div>
</div>
```
*/
/*------------------------------------*\
    drag file upload
\*------------------------------------*/
.c-drag-file-upload {
  color: #4d4d4d;
  background-color: #e5e5e5;
  transition: all .2s ease-in-out;
  padding: 28px;
  text-align: center;
  outline: 2px dashed #4d4d4d;
  outline-offset: -12px; }
  .c-drag-file-upload.is-dragover {
    color: #f2663b;
    background-color: #ffffff;
    outline: 2px dashed #f2663b;
    outline-offset: -15px; }
  .c-drag-file-upload__icon {
    font-size: 300%;
    line-height: 1;
    padding-bottom: 16px; }
  .c-drag-file-upload__drag-text {
    font-family: "futura-pt", sans-serif;
    font-weight: 500;
    font-size: 16px; }
  .c-drag-file-upload__drag-text-instruction {
    padding: 0 0 8px;
    margin: 0;
    text-align: center; }
  .c-drag-file-upload__file-input {
    display: none; }
  @media (min-width: 980px) {
    .c-drag-file-upload {
      padding: 36px; }
      .c-drag-file-upload.is-dragover {
        outline-offset: -20px; } }

/*doc
---
title: drag file upload
name: drag-file-upload
category: Components
---

```html_example
<div class="c-drag-file-upload js-drag-file-upload">

    <div class="c-drag-file-upload__icon u-before-icon-upload"></div>

    <div class="c-drag-file-upload__drag-text js-drag-text">
        Drag here to attach
        <p class="c-drag-file-upload__drag-text-instruction">or</p>
    </div>

    <label class="c-btn c-btn--black c-btn--mobile-full-width c-btn--margin-bottom-0 u-before-icon-attach" for="files">
        Choose file
    </label>

    <input id="files" class="c-drag-file-upload__file-input js-file-input" type="file" name="files[]" multiple>

    <input type="hidden" class="js-file-input-hidden" name="filesHidden" type="text" value="3487">

</div>
```
<div class="c-sg-modifier-wrap js-modifier-wrap">
    <div>
        <button>is-dragover</button>
    </div>
</div>
*/
@keyframes featureContactless {
  0% {
    opacity: 0; }
  10% {
    opacity: 1;
    z-index: 5;
    transform: rotate(0deg) translate(50%, -100%) scale(0); }
  50% {
    transform: rotate(0deg) translate(50%, 0) scale(0.5); }
  100% {
    transform: rotate(-17deg) translate(50%, 0) scale(1); } }

@keyframes featureCashbackMobile {
  0% {
    opacity: 0;
    z-index: 5; }
  10% {
    opacity: 1;
    z-index: 5;
    transform: translate(-50%, -100%) scale(0); }
  50% {
    z-index: 5;
    transform: translate(-50%, 0) scale(0.5); }
  100% {
    top: -53px;
    transform: translate(-50%, 0) scale(1); } }

@keyframes featureCashbackDesktop {
  0% {
    opacity: 0;
    z-index: 5; }
  10% {
    opacity: 1;
    z-index: 5;
    transform: translate(-50%, -100%) scale(0); }
  50% {
    z-index: 5;
    top: 10px;
    transform: translate(-50%, 0) scale(0.5); }
  100% {
    top: -89px;
    transform: translate(-50%, 0) scale(1); } }

@keyframes featureMobileQueriesXsmallScreen {
  0% {
    opacity: 0; }
  10% {
    opacity: 1;
    z-index: 5;
    transform: translate(50%, -100%) scale(0.5);
    left: 50%; }
  50% {
    transform: translate(50%, 0) scale(1);
    left: 50%; }
  100% {
    transform: translate(0, 24px);
    left: 60%; } }

@keyframes featureDesktopQueriesXsmallScreen {
  0% {
    opacity: 0; }
  10% {
    opacity: 1;
    z-index: 5;
    transform: translate(-50%, -100%) scale(0.5);
    left: 50%; }
  50% {
    transform: translate(-50%, 0) scale(1);
    left: 50%; }
  100% {
    transform: translate(0, -42px);
    left: 0; } }

@keyframes featureMobileQueriesSmallScreen {
  0% {
    opacity: 0; }
  10% {
    opacity: 1;
    z-index: 5;
    transform: translate(50%, -100%) scale(0.5); }
  50% {
    transform: translate(50%, 0) scale(1); }
  100% {
    transform: translate(0, 51px);
    left: 60%; } }

@keyframes featureDesktopQueriesSmallScreen {
  0% {
    opacity: 0; }
  10% {
    opacity: 1;
    z-index: 5;
    transform: translate(-50%, -100%) scale(0.5);
    left: 50%; }
  50% {
    transform: translate(-50%, 0) scale(1);
    left: 50%; }
  100% {
    transform: translate(0, -42px);
    left: 20%; } }

@keyframes featureDesktopQueriesMidScreen {
  0% {
    opacity: 0; }
  10% {
    opacity: 1;
    z-index: 5;
    transform: translate(-50%, -100%) scale(0.5);
    left: 50%; }
  50% {
    transform: translate(-50%, 0) scale(1);
    left: 50%; }
  100% {
    transform: translate(0, -51px);
    left: 0%; } }

@keyframes featureMobileQueriesMidScreen {
  0% {
    opacity: 0; }
  10% {
    opacity: 1;
    z-index: 5;
    transform: translate(50%, -100%) scale(0.5); }
  50% {
    transform: translate(50%, 0) scale(1); }
  100% {
    transform: translate(0, 24px); } }

@keyframes featureDesktopQueriesLargeScreen {
  0% {
    opacity: 0; }
  10% {
    opacity: 1;
    z-index: 5;
    transform: translate(-50%, -100%) scale(0.5);
    left: 50%; }
  50% {
    transform: translate(-50%, 0) scale(0.5);
    left: 50%; }
  100% {
    transform: translate(0, -65px) scale(1);
    left: 15%; } }

@keyframes featureMobileQueriesLargeScreen {
  0% {
    opacity: 0; }
  10% {
    opacity: 1;
    z-index: 5;
    transform: translate(50%, -100%) scale(0);
    left: 50%; }
  50% {
    transform: translate(30%, 10%) scale(0.5);
    left: 50%; }
  100% {
    transform: translate(0, 50px) scale(1);
    left: 56%; } }

.c-feature-animations__content {
  margin-bottom: 24px;
  position: relative;
  color: #000000;
  padding: 36px;
  background-color: transparent;
  border: 0;
  margin-bottom: 36px; }

.c-feature-animations__title {
  color: #f2663b;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500; }
  @media (min-width: 980px) {
    .c-feature-animations__title {
      font-size: 20px;
      line-height: 30px; } }

.c-feature-animations__copy {
  text-align: left;
  color: #000000; }

.c-feature-animations__images {
  position: relative; }

.c-feature-animations__image {
  max-width: 100%;
  display: inline-block;
  margin: 0 0 0 0;
  position: absolute;
  display: block; }

.c-feature-animations--perspective {
  perspective: 1000px; }

.c-feature-animations--z-10 {
  z-index: 10; }

.c-feature-animations--no-margin {
  margin: 0; }

.c-feature-animations--contactless {
  transform: rotate(-17deg) translate(50%);
  height: 175px;
  opacity: 0;
  right: 50%;
  z-index: 20; }
  .c-feature-animations--contactless.is-animated {
    opacity: 1;
    animation: featureContactless;
    animation-duration: 2s; }

.c-feature-animations--extra-accounts {
  max-width: 80%;
  transition: transform 0.75s cubic-bezier(0.49, 0.45, 0.58, 1.62);
  top: -79px;
  left: 60%;
  transform: translate(-50%) rotateX(-95deg);
  transform-origin: 0 0; }
  .c-feature-animations--extra-accounts.is-animated {
    transform: translate(-50%) rotateX(0deg); }

.c-feature-animations--mobile-apps {
  transform: translate(50%);
  max-width: 250px;
  right: 50%; }

.c-feature-animations--money-manager-screen {
  transition: transform 1s ease-in-out;
  transform-origin: 0 100%;
  max-height: 135px;
  left: 50%;
  top: -50px;
  transform: rotateX(-79deg) translate(-50%); }
  .c-feature-animations--money-manager-screen.is-animated {
    transform: rotateX(0deg) translate(-50%); }

.c-feature-animations--money-manager-base {
  max-height: 57px;
  max-width: 308px !important;
  left: 50%;
  top: 85px;
  transform: translate(-50%); }

.c-feature-animations--payments {
  transition: transform .85s ease-in-out;
  max-height: 132px;
  top: -60px;
  left: 0;
  transform: rotateX(90deg);
  z-index: 1; }
  .c-feature-animations--payments.is-animated {
    transform: rotateX(0deg); }

.c-feature-animations--payments-icon-one, .c-feature-animations--payments-icon-two {
  transition-property: left, opacity;
  transition-duration: .85s;
  transition-timing-function: ease-in-out;
  top: -31px;
  left: 0;
  opacity: 0;
  font-size: 61px; }
  .c-feature-animations--payments-icon-one:before, .c-feature-animations--payments-icon-two:before {
    font-family: 'ffreesicons', sans-serif;
    font-weight: 500;
    font-style: normal;
    content: ""; }
  .c-feature-animations--payments-icon-one.is-animated, .c-feature-animations--payments-icon-two.is-animated {
    opacity: 1;
    left: 55%; }

.c-feature-animations--payments-icon-two {
  left: 55%; }
  .c-feature-animations--payments-icon-two:before {
    content: ""; }
  .c-feature-animations--payments-icon-two.is-animated {
    left: 78%; }

.c-feature-animations--cashback {
  width: 80%;
  max-width: 420px;
  top: -53px;
  left: 50%;
  transform: translate(-50%);
  z-index: 20; }
  .c-feature-animations--cashback.is-animated {
    opacity: 1;
    animation: featureCashbackMobile;
    animation-duration: 2s; }

.c-feature-animations--contact-mobile {
  transform: translate(0, 24px);
  max-height: 160px;
  left: 60%;
  opacity: 0;
  z-index: 20; }
  .c-feature-animations--contact-mobile.is-animated {
    opacity: 1;
    animation: featureMobileQueriesXsmallScreen;
    animation-duration: 2s; }

.c-feature-animations--contact-desktop {
  transform: translate(0, -42px);
  max-width: 250px;
  left: 0;
  opacity: 0;
  z-index: 20; }
  .c-feature-animations--contact-desktop.is-animated {
    opacity: 1;
    animation: featureDesktopQueriesXsmallScreen;
    animation-duration: 2s; }

@media (min-width: 550px) {
  .c-feature-animations--contact-mobile {
    transform: translate(0, 51px);
    max-height: 225px; }
    .c-feature-animations--contact-mobile.is-animated {
      animation: featureMobileQueriesSmallScreen;
      animation-duration: 2s; }
  .c-feature-animations--contact-desktop {
    max-width: 400px;
    left: 20%; }
    .c-feature-animations--contact-desktop.is-animated {
      animation: featureDesktopQueriesSmallScreen;
      animation-duration: 2s; } }

@media (min-width: 540px) {
  .c-feature-animations--contactless {
    height: 232px; }
  .c-feature-animations--extra-accounts {
    max-width: 500px; }
  .c-feature-animations--money-manager-screen {
    max-height: 218px; }
  .c-feature-animations--money-manager-base {
    top: 165px;
    max-width: 100% !important; }
  .c-feature-animations--payments {
    max-height: 200px; }
  .c-feature-animations--payments-icon-one, .c-feature-animations--payments-icon-two {
    top: -34px;
    font-size: 99px; }
  .c-feature-animations--payments-icon-one.is-animated {
    left: 55%; }
  .c-feature-animations--payments-icon-two {
    left: 55%; }
    .c-feature-animations--payments-icon-two.is-animated {
      left: 78%; } }

@media (min-width: 800px) {
  .c-feature-animations__content {
    min-height: 0; }
  .c-feature-animations--contactless {
    height: 247px;
    top: 20px; }
  .c-feature-animations--payments {
    max-height: 300px; }
  .c-feature-animations--payments-icon-one, .c-feature-animations--payments-icon-two {
    top: -40px;
    font-size: 150px; }
  .c-feature-animations--cashback {
    max-width: 430px; } }

@media (min-width: 980px) {
  .c-feature-animations__content {
    min-height: 0; }
  .c-feature-animations--contactless {
    height: 175px;
    top: 0; }
  .c-feature-animations--extra-accounts {
    max-width: 80%; }
  .c-feature-animations--mobile-apps {
    max-width: 450px; }
  .c-feature-animations--money-manager-base {
    top: 167px; }
  .c-feature-animations--payments {
    top: -76px;
    left: -40px;
    max-height: 250px; }
  .c-feature-animations--payments-icon-one, .c-feature-animations--payments-icon-two {
    font-size: 86px; }
  .c-feature-animations--payments-icon-one.is-animated {
    left: 55%; }
  .c-feature-animations--payments-icon-two {
    left: 55%; }
    .c-feature-animations--payments-icon-two.is-animated {
      left: 78%; }
  .c-feature-animations--contact-mobile {
    transform: translate(0, 24px);
    max-height: 200px;
    left: 50%; }
    .c-feature-animations--contact-mobile.is-animated {
      animation: featureMobileQueriesMidScreen;
      animation-duration: 2s; }
  .c-feature-animations--contact-desktop {
    transform: translate(0, -51px);
    max-width: 300px;
    left: 0; }
    .c-feature-animations--contact-desktop.is-animated {
      animation: featureDesktopQueriesMidScreen;
      animation-duration: 2s; } }

@media (min-width: 1080px) {
  .c-feature-animations__content {
    min-height: 0; }
  .c-feature-animations--contactless {
    height: 274px;
    top: 20px; }
  .c-feature-animations--extra-accounts {
    max-width: 500px; }
  .c-feature-animations--money-manager-screen {
    max-height: 250px; }
  .c-feature-animations--money-manager-base {
    top: 199px; }
  .c-feature-animations--payments-icon-one, .c-feature-animations--payments-icon-two {
    top: -31px;
    font-size: 110px; }
  .c-feature-animations--contact-mobile {
    transform: translate(0, 50px);
    max-height: 233px;
    left: 56%; }
    .c-feature-animations--contact-mobile.is-animated {
      animation: featureMobileQueriesLargeScreen;
      animation-duration: 2s; }
  .c-feature-animations--contact-desktop {
    transform: translate(0, -65px);
    max-width: 400px; }
    .c-feature-animations--contact-desktop.is-animated {
      animation: featureDesktopQueriesLargeScreen;
      animation-duration: 2s;
      left: 15%; } }

@media (min-width: 1200px) {
  .c-feature-animations__content {
    min-height: 0; }
  .c-feature-animations--payments-icon-one, .c-feature-animations--payments-icon-two {
    font-size: 126px; } }

.c-fees-table {
  margin-bottom: 24px; }
  .c-fees-table__row-outer {
    text-align: left;
    padding: 8px 12px;
    background-color: #f6f6f6; }
    .c-fees-table__row-outer:nth-child(2n) {
      background-color: #ffffff; }
  .c-fees-table__row-inner {
    margin: auto;
    display: flex;
    align-items: center;
    max-width: 660px; }
  .c-fees-table__left-col {
    font-size: 16px;
    line-height: 24px;
    font-family: "futura-pt", sans-serif;
    font-weight: 500;
    margin-bottom: 8px;
    font-family: "futura-pt", sans-serif;
    font-weight: 700;
    display: flex;
    align-items: center;
    margin: 0;
    padding-right: 12px; }
    @media (min-width: 980px) {
      .c-fees-table__left-col {
        font-size: 20px;
        line-height: 28px; } }
    .c-fees-table__left-col:before {
      width: 24px;
      text-align: center;
      display: block;
      font-size: 20px;
      color: #f2663b;
      margin-right: 8px; }
  .c-fees-table__right-col {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 8px;
    margin: 0;
    margin-left: auto; }
    @media (min-width: 980px) {
      .c-fees-table__right-col {
        font-size: 20px;
        line-height: 28px; } }
  @media (min-width: 540px) {
    .c-fees-table__row-outer {
      padding: 12px 36px; }
    .c-fees-table__left-col:before {
      width: 28px;
      font-size: 22px;
      margin-right: 12px; } }
  @media (min-width: 980px) {
    .c-fees-table__row-outer {
      padding: 16px 100px; }
    .c-fees-table__row-inner {
      max-width: none; }
    .c-fees-table__left-col:before {
      width: 36px;
      font-size: 24px;
      margin-right: 24px; } }

/*doc
---
title: Fees table
name: fees-table
category: Components
---

```html_example
<div class="c-fees-table">
    <div class="c-fees-table__row-outer">
        <div class="c-fees-table__row-inner">
            <h3 class="c-fees-table__left-col u-before-icon-contactless">Contactless card</h3>
            <p class="c-fees-table__right-col">FREE</p>
        </div>
    </div>

    <div class="c-fees-table__row-outer">
        <div class="c-fees-table__row-inner">
            <h3 class="c-fees-table__left-col u-before-icon-card-back">Card transactions</h3>
            <p class="c-fees-table__right-col">FREE</p>
        </div>
    </div>

    <div class="c-fees-table__row-outer">
        <div class="c-fees-table__row-inner">
            <h3 class="c-fees-table__left-col u-before-icon-download">Transfers in</h3>
            <p class="c-fees-table__right-col">FREE</p>
        </div>
    </div>

    <div class="c-fees-table__row-outer">
        <div class="c-fees-table__row-inner">
            <h3 class="c-fees-table__left-col u-before-icon-paypoint">Paying in with PayPoint</h3>
            <p class="c-fees-table__right-col">3%</p>
        </div>
    </div>

    <div class="c-fees-table__row-outer">
        <div class="c-fees-table__row-inner">
            <h3 class="c-fees-table__left-col u-before-icon-globe">Transactions not in GBP (&pound;)</h3>
            <p class="c-fees-table__right-col">3%</p>
        </div>
    </div>

    <div class="c-fees-table__row-outer">
        <div class="c-fees-table__row-inner">
            <h3 class="c-fees-table__left-col u-before-icon-statements">Postal statements</h3>
            <p class="c-fees-table__right-col">&pound;2.50</p>
        </div>
    </div>

    <div class="c-fees-table__row-outer">
        <div class="c-fees-table__row-inner">
            <h3 class="c-fees-table__left-col u-before-icon-mastercard">Replacement card</h3>
            <p class="c-fees-table__right-col">&pound;2.50</p>
        </div>
    </div>
</div>

```

*/
.c-full-width-box {
  position: relative;
  background: #fbfcfc;
  padding: 16px 0; }
  .c-full-width-box::before, .c-full-width-box::after {
    content: '';
    position: absolute;
    top: 0;
    right: 100%;
    height: 100%;
    width: 100vw;
    background: #fbfcfc; }
  .c-full-width-box::after {
    left: 100%;
    right: auto; }
  .c-full-width-box--left {
    padding-right: 24px;
    border-radius: 0 20px 20px 0; }
    .c-full-width-box--left::after {
      content: none; }
  .c-full-width-box--right {
    padding-left: 24px;
    border-radius: 20px 0 0 20px; }
    .c-full-width-box--right::before {
      content: none; }
  .c-full-width-box--color-grey-4 {
    background: #f1f1f1; }
    .c-full-width-box--color-grey-4::before, .c-full-width-box--color-grey-4::after {
      background: #f1f1f1; }
  .c-full-width-box--color-grey-5 {
    background: #f6f6f6; }
    .c-full-width-box--color-grey-5::before, .c-full-width-box--color-grey-5::after {
      background: #f6f6f6; }
  .c-full-width-box--slanted {
    transform: rotate(-3deg); }
    .c-full-width-box--slanted .c-full-width-box__content {
      transform: rotate(3deg); }
  @media (min-width: 980px) {
    .c-full-width-box {
      padding: 36px 0; }
      .c-full-width-box--left, .c-full-width-box--left-bp-m {
        padding-right: 36px; }
      .c-full-width-box--right, .c-full-width-box--right-bp-m {
        padding-left: 36px; }
      .c-full-width-box--left-bp-m {
        border-radius: 0 20px 20px 0;
        padding-left: 0; }
        .c-full-width-box--left-bp-m::before {
          content: ''; }
        .c-full-width-box--left-bp-m::after {
          content: none; }
      .c-full-width-box--right-bp-m {
        border-radius: 20px 0 0 20px;
        padding-right: 0; }
        .c-full-width-box--right-bp-m::before {
          content: none; }
        .c-full-width-box--right-bp-m::after {
          content: ''; } }

/*doc
---
title: full width box
name: full-width-box
category: Components
---

```html_example
<div class="l-content-width l-content-width--max-width-420">
    <div class="c-full-width-box">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla luctus blandit erat, sagittis ultricies ante posuere id. Nulla pulvinar vulputate purus, vel semper sapien euismod sed. Cras vehicula tempus placerat. Nunc sit amet eleifend justo. Pellentesque quis mauris quam. In vel tortor dui. Nulla quam magna, varius at urna pulvinar, eleifend lobortis purus. Nulla facilisi. Suspendisse vitae orci erat. Ut lobortis magna velit, non molestie nulla blandit vitae. Praesent luctus cursus sem vel dapibus. Nunc semper est sit amet nisl auctor sollicitudin. Vestibulum blandit sit amet mi sed feugiat. Nulla facilisi.
    </div>
</div>
```
<div class="c-sg-modifier-wrap js-modifier-wrap">
    <div>
        <button data-target="c-full-width-box">c-full-width-box--left</button>
        <button data-target="c-full-width-box">c-full-width-box--right</button>
    </div>
    <div>
        <button data-target="c-full-width-box">c-full-width-box--left-bp-m</button>
        <button data-target="c-full-width-box">c-full-width-box--right-bp-m</button>
    </div>
    <div>
        <button data-target="c-full-width-box">c-full-width-box--color-grey-4</button>
        <button data-target="c-full-width-box">c-full-width-box--color-grey-5</button>
    </div>
</div>
*/
/*doc
---
title: full width box (slanted)
name: full-width-box-slanted
category: Components
---

Note - For the slanted modified version of the component, the content within the full width box is included within a new content element

```html_example
<div class="l-content-width l-content-width--max-width-420">
    <div class="c-full-width-box c-full-width-box--slanted">
        <div class="c-full-width-box__content">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla luctus blandit erat, sagittis ultricies ante posuere id. Nulla pulvinar vulputate purus, vel semper sapien euismod sed. Cras vehicula tempus placerat. Nunc sit amet eleifend justo. Pellentesque quis mauris quam. In vel tortor dui. Nulla quam magna, varius at urna pulvinar, eleifend lobortis purus. Nulla facilisi. Suspendisse vitae orci erat. Ut lobortis magna velit, non molestie nulla blandit vitae. Praesent luctus cursus sem vel dapibus. Nunc semper est sit amet nisl auctor sollicitudin. Vestibulum blandit sit amet mi sed feugiat. Nulla facilisi.
        </div>
    </div>
</div>
```
<div class="c-sg-modifier-wrap js-modifier-wrap">
    <div>
        <button data-target="c-full-width-box">c-full-width-box--left</button>
        <button data-target="c-full-width-box">c-full-width-box--right</button>
    </div>
    <div>
        <button data-target="c-full-width-box">c-full-width-box--color-grey-4</button>
        <button data-target="c-full-width-box">c-full-width-box--color-grey-5</button>
    </div>
</div>
*/
.c-home-image-animation {
  position: relative;
  height: 220px; }

@keyframes move-card-x {
  0% {
    transform: translateX(-50%);
    z-index: 3; }
  25% {
    transform: translateX(-70%) rotate(0deg); }
  50% {
    transform: translateX(-70%) rotate(-50deg);
    z-index: 3; }
  51% {
    z-index: 1; }
  60% {
    transform: translateX(-70%) rotate(-33.3deg); }
  80% {
    transform: translateX(-60%) rotate(0deg); }
  100% {
    transform: translateX(-50%);
    z-index: 1; } }

@keyframes move-card-y {
  0% {
    transform: translateY(10%);
    box-shadow: 0 8px 6px -6px rgba(0, 0, 0, 0.8); }
  25% {
    box-shadow: 0 8px 6px -6px rgba(0, 0, 0, 0.8); }
  50% {
    box-shadow: 0 8px 6px -6px rgba(0, 0, 0, 0.3); }
  80% {
    box-shadow: 0 8px 6px -6px rgba(0, 0, 0, 0.8); }
  100% {
    transform: translateY(-10%);
    box-shadow: 0 8px 6px -6px rgba(0, 0, 0, 0.8); } }
  .c-home-image-animation__card-wrapper {
    position: absolute;
    z-index: 3;
    bottom: 0;
    left: 40%;
    transform: translateX(-50%);
    transform-origin: bottom left;
    height: 50%; }
  .c-home-image-animation__card {
    margin: 0;
    max-height: 100%;
    max-width: 100%;
    box-shadow: 0 8px 6px -6px rgba(0, 0, 0, 0.8);
    transform: translateY(10%); }
  .c-home-image-animation__phone {
    position: absolute;
    z-index: 2;
    bottom: 0;
    left: 65%;
    transform: translateX(-50%);
    margin: 0;
    max-height: 100%;
    max-width: 100%;
    box-shadow: 0 8px 6px -6px rgba(0, 0, 0, 0.8); }
  .c-home-image-animation__screen {
    position: absolute;
    z-index: 2;
    bottom: 0;
    left: 65%;
    transform: translateX(-50%);
    margin: 0;
    max-height: 100%;
    max-width: 100%; }
  .c-home-image-animation--no-shadow .c-home-image-animation__phone,
  .c-home-image-animation--no-shadow .c-home-image-animation__card {
    box-shadow: none; }
  .c-home-image-animation.is-animated .c-home-image-animation__card-wrapper {
    animation: move-card-x 1.25s linear;
    transform: translateX(-50%);
    z-index: 1; }
  .c-home-image-animation.is-animated .c-home-image-animation__card {
    animation: move-card-y 1.25s cubic-bezier(0.9, 0.25, 0.21, 0.92);
    transform: translateY(-10%); }
  @media (min-width: 540px) {
    .c-home-image-animation {
      height: 240px; }
      .c-home-image-animation__card-wrapper {
        left: 47%; }
      .c-home-image-animation__phone {
        left: 58%; } }
  @media (min-width: 800px) {
    .c-home-image-animation {
      height: 220px; }
      .c-home-image-animation__card-wrapper {
        left: 45%; }
      .c-home-image-animation__phone {
        left: 60%; }
      .c-home-image-animation--large {
        height: 260px; } }
  @media (min-width: 980px) {
    .c-home-image-animation {
      height: 280px; }
      .c-home-image-animation--large {
        height: 400px; } }
  @media (min-width: 1200px) {
    .c-home-image-animation--large {
      height: 500px; } }

/*doc
---
title: home image animation
name: home-image-animation
category: Organisms
---

```html_example
<div class="c-home-image-animation js-home-image-animation">
    <div class="c-home-image-animation__card-wrapper js-home-animation-card">
        <img class="c-home-image-animation__card " src="img/homepage-images/u-card.png" alt="U Card" title="U Card">
    </div>
    <img class="c-home-image-animation__phone" src="img/homepage-images/u-app-mobile.png" alt="U Account App on Mobile" title="U Account App on Mobile">
</div>

```
<div class="c-sg-modifier-wrap js-modifier-wrap">
    <div>
        <button>is-animated</button>
    </div>
    <div>
        <button>c-home-image-animation--large</button>
    </div>
    <div>
        <button>c-home-image-animation--no-shadow</button>
    </div>
</div>
*/
.c-hr {
  width: 25%;
  margin: 24px auto;
  height: 1px;
  background-color: #afafaf; }
  .c-hr--margin-top-0 {
    margin-top: 0; }
  .c-hr--margin-bottom-0 {
    margin-bottom: 0; }
  .c-hr--margin-bottom-xs {
    margin-bottom: 12px; }
  .c-hr--margin-bottom-s {
    margin-bottom: 16px; }
  .c-hr--margin-top-bottom-s {
    margin-top: 16px;
    margin-bottom: 16px; }
  .c-hr--full-width {
    width: 100%; }
  .c-hr--width-80-percent {
    width: 80%; }
  .c-hr--width-100-px {
    width: 100px; }
  .c-hr--color-grey-9 {
    background-color: #e5e5e5; }

.c-icon {
  width: 27px;
  height: 21px;
  fill: transparent;
  shape-rendering: geometricPrecision; }
  .c-icon--fill-color-1 {
    fill: #f2663b;
    stroke-width: 0; }
  .c-icon--outline-color-1 {
    fill: transparent;
    stroke: #f2663b;
    stroke-width: 1; }
  .c-icon--fill-color-1-light {
    fill: #fef8f7;
    stroke-width: 0; }
  .c-icon--outline-color-1-light {
    fill: transparent;
    stroke: #fef8f7;
    stroke-width: 1; }
  .c-icon--fill-color-1-mid {
    fill: #fcded5;
    stroke-width: 0; }
  .c-icon--outline-color-1-mid {
    fill: transparent;
    stroke: #fcded5;
    stroke-width: 1; }
  .c-icon--fill-color-1-dark {
    fill: #ea4310;
    stroke-width: 0; }
  .c-icon--outline-color-1-dark {
    fill: transparent;
    stroke: #ea4310;
    stroke-width: 1; }
  .c-icon--fill-color-2 {
    fill: #6eb52b;
    stroke-width: 0; }
  .c-icon--outline-color-2 {
    fill: transparent;
    stroke: #6eb52b;
    stroke-width: 1; }
  .c-icon--fill-color-2-light {
    fill: #eff9e6;
    stroke-width: 0; }
  .c-icon--outline-color-2-light {
    fill: transparent;
    stroke: #eff9e6;
    stroke-width: 1; }
  .c-icon--fill-color-2-mid {
    fill: #cfe4ba;
    stroke-width: 0; }
  .c-icon--outline-color-2-mid {
    fill: transparent;
    stroke: #cfe4ba;
    stroke-width: 1; }
  .c-icon--fill-color-2-dark {
    fill: #558c21;
    stroke-width: 0; }
  .c-icon--outline-color-2-dark {
    fill: transparent;
    stroke: #558c21;
    stroke-width: 1; }
  .c-icon--fill-color-3 {
    fill: #ffb20a;
    stroke-width: 0; }
  .c-icon--outline-color-3 {
    fill: transparent;
    stroke: #ffb20a;
    stroke-width: 1; }
  .c-icon--fill-color-3-light {
    fill: #FFF6E1;
    stroke-width: 0; }
  .c-icon--outline-color-3-light {
    fill: transparent;
    stroke: #FFF6E1;
    stroke-width: 1; }
  .c-icon--fill-color-3-dark {
    fill: #d69300;
    stroke-width: 0; }
  .c-icon--outline-color-3-dark {
    fill: transparent;
    stroke: #d69300;
    stroke-width: 1; }
  .c-icon--fill-color-4 {
    fill: #d31a35;
    stroke-width: 0; }
  .c-icon--outline-color-4 {
    fill: transparent;
    stroke: #d31a35;
    stroke-width: 1; }
  .c-icon--fill-color-4-light {
    fill: #FABCCC;
    stroke-width: 0; }
  .c-icon--outline-color-4-light {
    fill: transparent;
    stroke: #FABCCC;
    stroke-width: 1; }
  .c-icon--fill-color-4-dark {
    fill: #a6142a;
    stroke-width: 0; }
  .c-icon--outline-color-4-dark {
    fill: transparent;
    stroke: #a6142a;
    stroke-width: 1; }
  .c-icon--fill-color-5 {
    fill: #005EB0;
    stroke-width: 0; }
  .c-icon--outline-color-5 {
    fill: transparent;
    stroke: #005EB0;
    stroke-width: 1; }
  .c-icon--fill-color-5-light {
    fill: #E8F1F8;
    stroke-width: 0; }
  .c-icon--outline-color-5-light {
    fill: transparent;
    stroke: #E8F1F8;
    stroke-width: 1; }
  .c-icon--fill-color-5-dark {
    fill: #00437d;
    stroke-width: 0; }
  .c-icon--outline-color-5-dark {
    fill: transparent;
    stroke: #00437d;
    stroke-width: 1; }
  .c-icon--fill-color-black {
    fill: #000000;
    stroke-width: 0; }
  .c-icon--outline-color-black {
    fill: transparent;
    stroke: #000000;
    stroke-width: 1; }
  .c-icon--fill-color-white {
    fill: #ffffff;
    stroke-width: 0; }
  .c-icon--outline-color-white {
    fill: transparent;
    stroke: #ffffff;
    stroke-width: 1; }
  .c-icon--fill-color-grey-xdark {
    fill: #4d4d4d;
    stroke-width: 0; }
  .c-icon--outline-color-grey-xdark {
    fill: transparent;
    stroke: #4d4d4d;
    stroke-width: 1; }
  .c-icon--fill-color-grey-dark {
    fill: #afafaf;
    stroke-width: 0; }
  .c-icon--outline-color-grey-dark {
    fill: transparent;
    stroke: #afafaf;
    stroke-width: 1; }
  .c-icon--fill-color-grey-mid {
    fill: #d1d3d4;
    stroke-width: 0; }
  .c-icon--outline-color-grey-mid {
    fill: transparent;
    stroke: #d1d3d4;
    stroke-width: 1; }
  .c-icon--fill-color-grey-light {
    fill: #ebebeb;
    stroke-width: 0; }
  .c-icon--outline-color-grey-light {
    fill: transparent;
    stroke: #ebebeb;
    stroke-width: 1; }
  .c-icon--fill-color-grey-xlight {
    fill: #f1f1f1;
    stroke-width: 0; }
  .c-icon--outline-color-grey-xlight {
    fill: transparent;
    stroke: #f1f1f1;
    stroke-width: 1; }
  .c-icon--fill-color-grey-xxlight {
    fill: #f6f6f6;
    stroke-width: 0; }
  .c-icon--outline-color-grey-xxlight {
    fill: transparent;
    stroke: #f6f6f6;
    stroke-width: 1; }
  .c-icon--fill-color-grey-xxxlight {
    fill: #fbfcfc;
    stroke-width: 0; }
  .c-icon--outline-color-grey-xxxlight {
    fill: transparent;
    stroke: #fbfcfc;
    stroke-width: 1; }
  .c-icon--fill-color-grey-1 {
    fill: #4d4d4d;
    stroke-width: 0; }
  .c-icon--outline-color-grey-1 {
    fill: transparent;
    stroke: #4d4d4d;
    stroke-width: 1; }
  .c-icon--fill-color-grey-2 {
    fill: #afafaf;
    stroke-width: 0; }
  .c-icon--outline-color-grey-2 {
    fill: transparent;
    stroke: #afafaf;
    stroke-width: 1; }
  .c-icon--fill-color-grey-3 {
    fill: #d1d3d4;
    stroke-width: 0; }
  .c-icon--outline-color-grey-3 {
    fill: transparent;
    stroke: #d1d3d4;
    stroke-width: 1; }
  .c-icon--fill-color-grey-4 {
    fill: #f1f1f1;
    stroke-width: 0; }
  .c-icon--outline-color-grey-4 {
    fill: transparent;
    stroke: #f1f1f1;
    stroke-width: 1; }
  .c-icon--fill-color-grey-5 {
    fill: #f6f6f6;
    stroke-width: 0; }
  .c-icon--outline-color-grey-5 {
    fill: transparent;
    stroke: #f6f6f6;
    stroke-width: 1; }
  .c-icon--fill-color-grey-6 {
    fill: #f8f8f8;
    stroke-width: 0; }
  .c-icon--outline-color-grey-6 {
    fill: transparent;
    stroke: #f8f8f8;
    stroke-width: 1; }
  .c-icon--fill-color-grey-7 {
    fill: #fbfcfc;
    stroke-width: 0; }
  .c-icon--outline-color-grey-7 {
    fill: transparent;
    stroke: #fbfcfc;
    stroke-width: 1; }
  .c-icon--fill-color-grey-8 {
    fill: #757575;
    stroke-width: 0; }
  .c-icon--outline-color-grey-8 {
    fill: transparent;
    stroke: #757575;
    stroke-width: 1; }
  .c-icon--fill-color-grey-9 {
    fill: #e5e5e5;
    stroke-width: 0; }
  .c-icon--outline-color-grey-9 {
    fill: transparent;
    stroke: #e5e5e5;
    stroke-width: 1; }
  .c-icon--fill-highlight {
    fill: #f2663b;
    stroke-width: 0; }
  .c-icon--outline-highlight {
    fill: transparent;
    stroke: #f2663b;
    stroke-width: 1; }
  .c-icon--fill-color-twitter {
    fill: #0FA5F8;
    stroke-width: 0; }
  .c-icon--outline-color-twitter {
    fill: transparent;
    stroke: #0FA5F8;
    stroke-width: 1; }
  .c-icon--fill-color-facebook {
    fill: #27579A;
    stroke-width: 0; }
  .c-icon--outline-color-facebook {
    fill: transparent;
    stroke: #27579A;
    stroke-width: 1; }
  .c-icon--fill-color-dot-dot-loans-1 {
    fill: #ea9605;
    stroke-width: 0; }
  .c-icon--outline-color-dot-dot-loans-1 {
    fill: transparent;
    stroke: #ea9605;
    stroke-width: 1; }
  .c-icon--fill-color-dot-dot-loans-2 {
    fill: #feefd7;
    stroke-width: 0; }
  .c-icon--outline-color-dot-dot-loans-2 {
    fill: transparent;
    stroke: #feefd7;
    stroke-width: 1; }
  .c-icon--fill-color-dot-dot-loans-3 {
    fill: #ffe5bb;
    stroke-width: 0; }
  .c-icon--outline-color-dot-dot-loans-3 {
    fill: transparent;
    stroke: #ffe5bb;
    stroke-width: 1; }
  .c-icon--fill-color-dot-dot-loans-4 {
    fill: #ebcada;
    stroke-width: 0; }
  .c-icon--outline-color-dot-dot-loans-4 {
    fill: transparent;
    stroke: #ebcada;
    stroke-width: 1; }
  .c-icon--fill-color-dot-dot-loans-5 {
    fill: #e42386;
    stroke-width: 0; }
  .c-icon--outline-color-dot-dot-loans-5 {
    fill: transparent;
    stroke: #e42386;
    stroke-width: 1; }
  .c-icon--center {
    margin-right: auto;
    margin-left: auto; }
  .c-icon--stroke-width-2 {
    stroke-width: 2; }

.styleguide .c-icon {
  width: 100px;
  height: 88px; }

/*doc
---
title: Filled Icons
name: filled-icons
category: Icons
---

You need to create a virtual host for the icons to load correctly. Follow the steps on <a href="https://ffrees.atlassian.net/wiki/spaces/PROD/pages/1472922027/Creating+vhost+for+Styleguides" target="_Blank">this confluence page</a>.

Icons need a title to be accessible to screenreader users, or to be hidden from them using aria-hidden="true" if they are purely decorative. <a href="https://css-tricks.com/accessible-svgs/#article-header-id-9">Here are some examples of accessible svg icon use</a>.

```html_example_table

<svg class="c-icon c-icon--fill-color-1">
    <title>Account</title>
    <use xlink:href="#account-outline" />
</svg>

<svg class="c-icon c-icon--fill-color-1">
    <title>Account</title>
    <use xlink:href="#account" />
</svg>

<svg class="c-icon c-icon--fill-color-1">
    <title>Card</title>
    <use xlink:href="#card" />
</svg>

<svg class="c-icon c-icon--fill-color-1">
    <title>Damaged card</title>
    <use xlink:href="#card-damaged" />
</svg>

<svg class="c-icon c-icon--fill-color-1">
    <title>Key</title>
    <use xlink:href="#key" />
</svg>

<svg class="c-icon c-icon--fill-color-1">
    <title>Plus</title>
    <use xlink:href="#plus" />
</svg>

<svg class="c-icon c-icon--fill-color-1">
    <title>Share</title>
    <use xlink:href="#share" />
</svg>

<svg class="c-icon c-icon--fill-color-1">
    <title>Snowflake</title>
    <use xlink:href="#snowflake" />
</svg>

<svg class="c-icon c-icon--fill-color-1">
    <title>Warning</title>
    <use xlink:href="#warning" />
</svg>

<svg class="c-icon c-icon--fill-color-1">
    <title>Checkmark</title>
    <use xlink:href="#check" />
</svg>

<svg class="c-icon c-icon--fill-color-1">
    <title>Copy</title>
    <use xlink:href="#copy" />
</svg>

<svg class="c-icon c-icon--fill-color-1">
    <title>Help</title>
    <use xlink:href="#help" />
</svg>

<svg class="c-icon c-icon--fill-color-1">
    <title>Pay</title>
    <use xlink:href="#pay" />
</svg>

<svg class="c-icon c-icon--fill-color-1">
    <title>Settings</title>
    <use xlink:href="#settings" />
</svg>

<svg class="c-icon c-icon--fill-color-1">
    <title>Share</title>
    <use xlink:href="#share" />
</svg>

```
<div class="c-sg-modifier-wrap js-modifier-wrap">
    <div>
        <button>c-icon--fill-color-1</button>
        <button>c-icon--fill-color-2</button>
        <button>c-icon--fill-color-3</button>
        <button>c-icon--fill-color-4</button>
        <button>c-icon--fill-color-black</button>
        <button>c-icon--fill-color-white</button>
    </div>
    <div>
        <button>c-icon--center</button>
    </div>
</div>
*/
/*doc
---
title: Outlined Icons
name: outlined-icons
category: Icons
---

```html_example_table

<svg class="c-icon c-icon--outline-color-1">
    <title>Account</title>
    <use xlink:href="#account" />
</svg>

<svg class="c-icon c-icon--outline-color-1">
    <title>Card</title>
    <use xlink:href="#card" />
</svg>

<svg class="c-icon c-icon--outline-color-1">
    <title>Damaged card</title>
    <use xlink:href="#card-damaged" />
</svg>

<svg class="c-icon c-icon--outline-color-1">
    <title>Key</title>
    <use xlink:href="#key" />
</svg>

<svg class="c-icon c-icon--outline-color-1">
    <title>Plus</title>
    <use xlink:href="#plus" />
</svg>

<svg class="c-icon c-icon--outline-color-1">
    <title>Share</title>
    <use xlink:href="#share" />
</svg>

<svg class="c-icon c-icon--outline-color-1">
    <title>Snowflake</title>
    <use xlink:href="#snowflake" />
</svg>

<svg class="c-icon c-icon--outline-color-1">
    <title>Warning</title>
    <use xlink:href="#warning" />
</svg>

<svg class="c-icon c-icon--outline-color-1">
    <title>Checkmark</title>
    <use xlink:href="#check" />
</svg>

<svg class="c-icon c-icon--outline-color-1">
    <title>Copy</title>
    <use xlink:href="#copy" />
</svg>

<svg class="c-icon c-icon--outline-color-1">
    <title>Help</title>
    <use xlink:href="#help" />
</svg>

<svg class="c-icon c-icon--outline-color-1">
    <title>Pay</title>
    <use xlink:href="#pay" />
</svg>

<svg class="c-icon c-icon--outline-color-1">
    <title>Settings</title>
    <use xlink:href="#settings" />
</svg>

<svg class="c-icon c-icon--outline-color-1">
    <title>Share</title>
    <use xlink:href="#share" />
</svg>

```
<div class="c-sg-modifier-wrap js-modifier-wrap">
    <div>
        <button>c-icon--outline-color-1</button>
        <button>c-icon--outline-color-2</button>
        <button>c-icon--outline-color-3</button>
        <button>c-icon--outline-color-4</button>
        <button>c-icon--outline-color-black</button>
        <button>c-icon--outline-color-white</button>
    </div>
    <div>
        <button>c-icon--center</button>
        <button>c-icon--stroke-width-2</button>
    </div>
</div>
*/
.c-icon-tab-system__circle-nav {
  overflow: hidden; }

.c-icon-tab-system__circle-nav-title {
  margin-bottom: 16px;
  font-size: 22px;
  line-height: 29px; }
  @media (min-width: 980px) {
    .c-icon-tab-system__circle-nav-title {
      font-size: 28px;
      line-height: 40px; } }

.c-icon-tab-system__tab-nav {
  margin: 0 auto;
  transition: transform .35s ease-in-out;
  width: 469px; }

.c-icon-tab-system__tab {
  width: 55px;
  height: 55px;
  margin: 0 8px 8px 0;
  padding: 0;
  font-size: 24px;
  line-height: 1;
  background-color: #ffffff;
  border: 0;
  border-radius: 50%;
  transition: all 200ms; }
  .c-icon-tab-system__tab:hover, .c-icon-tab-system__tab:focus {
    color: #ea4310; }
  .c-icon-tab-system__tab.is-active {
    color: #ffffff;
    background-color: #f2663b; }
    .c-icon-tab-system__tab.is-active .c-icon-tab-system__btn-content {
      box-shadow: 0px 2px 4px rgba(234, 67, 16, 0.21); }

.c-icon-tab-system__title {
  margin: -1px;
  padding: 0;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0 0 0 0);
  clip: rect(0, 0, 0, 0);
  position: absolute; }

.c-icon-tab-system__btn-content {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 50%;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.21); }

.c-icon-tab-system__tab-content-wrap {
  height: 100%;
  position: relative; }

.c-icon-tab-system__tab-content {
  width: 100%;
  transition: opacity .3s ease-in-out;
  position: absolute;
  opacity: 0; }
  .c-icon-tab-system__tab-content.is-active {
    opacity: 1; }

@media (min-width: 980px) {
  .c-icon-tab-system {
    display: flex; }
    .c-icon-tab-system__circle-nav-title {
      transform: translate(0, -50%);
      position: absolute;
      top: 50%;
      display: block;
      line-height: 1;
      text-align: left; }
    .c-icon-tab-system__circle-nav {
      position: relative;
      overflow: visible;
      width: 50%;
      padding: 60px 0; }
    .c-icon-tab-system__tab-nav {
      position: relative;
      width: 500px;
      height: 500px;
      padding: 0;
      border-radius: 50%;
      list-style: none;
      transform: translate(-50%) rotate(-80deg);
      margin: 0; }
      .c-icon-tab-system__tab-nav > .c-icon-tab-system__tab {
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        width: 82px;
        height: 82px;
        margin: -41px; }
        .c-icon-tab-system__tab-nav > .c-icon-tab-system__tab:nth-of-type(1) {
          transform: rotate(0deg) translate(250px) rotate(0deg); }
        .c-icon-tab-system__tab-nav > .c-icon-tab-system__tab:nth-of-type(2) {
          transform: rotate(32.14286deg) translate(250px) rotate(-32.14286deg); }
        .c-icon-tab-system__tab-nav > .c-icon-tab-system__tab:nth-of-type(3) {
          transform: rotate(64.28571deg) translate(250px) rotate(-64.28571deg); }
        .c-icon-tab-system__tab-nav > .c-icon-tab-system__tab:nth-of-type(4) {
          transform: rotate(96.42857deg) translate(250px) rotate(-96.42857deg); }
        .c-icon-tab-system__tab-nav > .c-icon-tab-system__tab:nth-of-type(5) {
          transform: rotate(128.57143deg) translate(250px) rotate(-128.57143deg); }
        .c-icon-tab-system__tab-nav > .c-icon-tab-system__tab:nth-of-type(6) {
          transform: rotate(160.71429deg) translate(250px) rotate(-160.71429deg); }
        .c-icon-tab-system__tab-nav > .c-icon-tab-system__tab:nth-of-type(7) {
          transform: rotate(192.85714deg) translate(250px) rotate(-192.85714deg); }
        .c-icon-tab-system__tab-nav > .c-icon-tab-system__tab:nth-of-type(8) {
          transform: rotate(225deg) translate(250px) rotate(-225deg); }
        .c-icon-tab-system__tab-nav > .c-icon-tab-system__tab:nth-of-type(9) {
          transform: rotate(257.14286deg) translate(250px) rotate(-257.14286deg); }
        .c-icon-tab-system__tab-nav > .c-icon-tab-system__tab:nth-of-type(10) {
          transform: rotate(289.28571deg) translate(250px) rotate(-289.28571deg); }
        .c-icon-tab-system__tab-nav > .c-icon-tab-system__tab:nth-of-type(11) {
          transform: rotate(321.42857deg) translate(250px) rotate(-321.42857deg); }
        .c-icon-tab-system__tab-nav > .c-icon-tab-system__tab:nth-of-type(12) {
          transform: rotate(353.57143deg) translate(250px) rotate(-353.57143deg); }
    .c-icon-tab-system__tab {
      padding: 8px 4px 4px;
      line-height: 1; }
    .c-icon-tab-system__btn-content {
      position: absolute;
      top: 0;
      left: 0;
      transform: rotate(80deg); }
      .c-icon-tab-system__btn-content:before {
        font-size: 36px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        margin-bottom: 8px; }
    .c-icon-tab-system__tab-content-wrap {
      width: 50%; } }

/*doc
---
title: icon tab system (circle)
name: icon-tab-system-circle
category: Organisms
---

```html_example
<div class="c-icon-tab-system js-icon-tab-wrapper" data-style="circle" data-auto-scroll="true">
    <div class="c-icon-tab-system__circle-nav js-circle-nav">
        <h2 class="c-icon-tab-system__circle-nav-title u-section-title js-circle-nav-title">
            <span class="u-u-font u-color-1">U</span> Account <br class="u-display-none u-display-block-m">features
        </h2>
        <nav class="c-icon-tab-system__tab-nav js-icon-tab-nav">
            <button
                class="c-icon-tab-system__tab js-tab-func-btn tt-contactless-feature-button"
                data-hash="contactless-card-tab"
                data-tab="contactless-card"
            >
                <span class="c-icon-tab-system__btn-content u-before-icon-contactless">
                    <span class="c-icon-tab-system__title">
                        Contactless Mastercard<sup>&reg;</sup> card
                    </span>
                </span>
            </button>
            <button
                class="c-icon-tab-system__tab js-tab-func-btn tt-extra-accounts-feature-button"
                data-hash="extra-accounts-tab"
                data-tab="extra-accounts"
            >
                <span class="c-icon-tab-system__btn-content u-before-icon-user-add">
                    <span class="c-icon-tab-system__title">
                        Extra Accounts for budgeting
                    </span>
                </span>
            </button>
            <button
                class="c-icon-tab-system__tab js-tab-func-btn tt-apps-feature-button"
                data-hash="mobile-apps-tab"
                data-tab="mobile-apps"
            >
                <span class="c-icon-tab-system__btn-content u-before-icon-mobile">
                    <span class="c-icon-tab-system__title">
                        App for iOS and Android
                    </span>
                </span>
            </button>
            <button
                class="c-icon-tab-system__tab js-tab-func-btn tt-money-manager-feature-button"
                data-hash="money-manager-tab"
                data-tab="money-manager"
            >
                <span class="c-icon-tab-system__btn-content u-before-icon-line-chart">
                    <span class="c-icon-tab-system__title">
                        Free Money Manager
                    </span>
                </span>
            </button>
            <button
                class="c-icon-tab-system__tab js-tab-func-btn tt-payments-feature-button"
                data-hash="payments-in-tab"
                data-tab="payments-in"
            >
                <span class="c-icon-tab-system__btn-content u-before-icon-download">
                    <span class="c-icon-tab-system__title">
                        Payments
                    </span>
                </span>
            </button>
            <button
                class="c-icon-tab-system__tab js-tab-func-btn tt-cashback-feature-button"
                data-hash="cash-back-tab"
                data-tab="cash-back"
            >
                <span class="c-icon-tab-system__btn-content u-before-icon-cash-back">
                    <span class="c-icon-tab-system__title">
                        Cashback Rewards
                    </span>
                </span>
            </button>
            <button
                class="c-icon-tab-system__tab js-tab-func-btn tt-online-support-button"
                data-hash="online-support-tab"
                data-tab="online-support"
            >
                <span class="c-icon-tab-system__btn-content u-before-icon-chat">
                    <span class="c-icon-tab-system__title">
                        Online Support
                    </span>
                </span>
            </button>
        </nav>
    </div>
    <div class="c-icon-tab-system__tab-content-wrap c-icon-tab-system--orange-text js-tab-content-wrap js-features">
        <div class="c-icon-tab-system__tab-content js-tab-content" data-tab-content="contactless-card">
            <p>Tab 1</p>
        </div>
        <div class="c-icon-tab-system__tab-content js-tab-content" data-tab-content="extra-accounts">
            <p>Tab 2</p>
        </div>
        <div class="c-icon-tab-system__tab-content js-tab-content" data-tab-content="mobile-apps">
            <p>Tab 3</p>
        </div>
        <div class="c-icon-tab-system__tab-content js-tab-content" data-tab-content="money-manager">
            <p>Tab 4</p>
        </div>
        <div class="c-icon-tab-system__tab-content js-tab-content" data-tab-content="payments-in">
            <p>Tab 5</p>
        </div>
        <div class="c-icon-tab-system__tab-content js-tab-content" data-tab-content="cash-back">
            <p>Tab 6</p>
        </div>
        <div class="c-icon-tab-system__tab-content js-tab-content" data-tab-content="no-credit-checks">
            <p>Tab 7</p>
        </div>
        <div class="c-icon-tab-system__tab-content js-tab-content" data-tab-content="online-support">
            <p>Tab 8</p>
        </div>
    </div>
</div>
```
*/
.c-image-bg {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center; }
  .c-image-bg--weighted-top {
    background-position: center 25%; }

/*doc
---
title: Image Background
name: image-bg
category: Components
---

```html_example
<div class="c-image-bg u-padding-xxxxl" style="background-image: url('img/landing-pages/section3-copy.png')">
    <h3>Background image</h3>
</div>

```
<div class="c-sg-modifier-wrap js-modifier-wrap">
    <div>
        <button>c-image-bg--weighted-top</button>
    </div>
</div>
*/
.c-input-datalist {
  position: relative;
  margin-bottom: 36px; }
  .c-input-datalist__list {
    position: absolute;
    top: calc(100% + 4px);
    left: 0;
    width: 100%;
    z-index: 10;
    list-style: none;
    color: inherit;
    border-radius: 16px;
    background: #ffffff;
    border: 1px solid #f2663b;
    box-shadow: 0px 2px 4px rgba(242, 102, 59, 0.21);
    margin: 0;
    padding: 0;
    overflow: hidden;
    display: none; }
    .c-input-datalist__list.is-active {
      display: block; }
    .c-input-datalist__list:empty {
      border: 0;
      box-shadow: none; }
  .c-input-datalist__fallback {
    margin: 28px auto 20px;
    text-align: center; }
  .c-input-datalist__clear {
    display: none;
    position: absolute;
    transform: translate3d(0, -50%, 0);
    top: 50%;
    height: 100%;
    font-size: inherit;
    line-height: inherit;
    right: 0;
    background: none;
    border: none;
    box-shadow: none;
    padding: 0 16px;
    transition: all 200ms; }
    .c-input-datalist__clear::after {
      font-family: 'ffreesicons', sans-serif;
      font-weight: 500;
      font-style: normal;
      content: ""; }
    .c-input-datalist__clear:hover, .c-input-datalist__clear:focus, .c-input-datalist__clear:active {
      color: #f2663b; }
  .c-input-datalist__result {
    margin: 0;
    padding: 0; }
  .c-input-datalist__result-link {
    position: relative;
    display: block;
    color: inherit;
    text-decoration: none;
    padding: 8px 20px;
    font-weight: inherit; }
    .c-input-datalist__result-link::after {
      font-family: 'ffreesicons', sans-serif;
      font-weight: 500;
      font-style: normal;
      position: absolute;
      transform: translate3d(0, -50%, 0);
      top: 50%;
      content: "";
      display: block;
      right: 16px;
      color: #757575;
      font-size: inherit;
      line-height: inherit; }
    .c-input-datalist__result-link:hover, .c-input-datalist__result-link:focus, .c-input-datalist__result-link:active {
      color: inherit;
      background: #f8f8f8; }
  .c-input-datalist.is-submitted .c-input {
    background: #fef8f7; }
    .c-input-datalist.is-submitted .c-input::after {
      display: none; }
  .c-input-datalist.is-submitted .c-input-datalist__clear {
    display: block; }
  @media (min-width: 800px) {
    .c-input-datalist--large {
      font-size: 20px;
      line-height: 28px;
      border-radius: 20px;
      margin-bottom: 60px; }
      .c-input-datalist--large .c-input-datalist__result-link {
        padding: 16px 28px; }
        .c-input-datalist--large .c-input-datalist__result-link::after {
          right: 28px; }
      .c-input-datalist--large .c-input-datalist__clear {
        padding-left: 28px;
        padding-right: 28px; } }

/*doc
---
title: Datalist
name: datalist-input
category: Inputs
---
Note: The `--large` modifier should be used on both the input and datalist if used

```html_example_table
<form autocomplete="off">
    <div class="c-input-datalist js-input-validation js-datalist-wrapper">
        <label class="c-input c-input--search js-input-wrapper">
            <span class="c-input__label">Search for an answer</span>
            <input value="money" type="search" class="c-input__input js-input js-search-input" aria-controls="faq-search-results"/>
        </label>
        <ul id="faq-search-results" class="c-input-datalist__list js-datalist" aria-live="polite">
            <li class="c-input-datalist__result">
                <a class="c-input-datalist__result-link js-result-link" href="/help/question/1131" data-index="0">What's Money Manager?</a>
            </li>
            <li class="c-input-datalist__result">
                <a class="c-input-datalist__result-link js-result-link" href="/help/question/1048" data-index="1">Can I have more than one account?</a>
            </li>
            <li class="c-input-datalist__result">
                <a class="c-input-datalist__result-link js-result-link" href="/help/question/1151" data-index="2">Can I have more than one card?</a>
            </li>
            <li class="c-input-datalist__result">
                <a class="c-input-datalist__result-link js-result-link" href="/help/question/1111" data-index="3">Where is my money held?</a>
            </li>
            <li class="c-input-datalist__result">
                <a class="c-input-datalist__result-link js-result-link" href="/help/question/1130" data-index="4">Can I earn interest on my money?</a>
            </li>
        </ul>
    </div>
</form>
```
<div class="c-sg-modifier-wrap js-modifier-wrap">
    <div>
        <button data-target="c-input">c-input--large</button>
    </div>
    <div>
        <button data-target="c-input-datalist">c-input-datalist--large</button>
    </div>
</div>
*/
.c-input {
  position: relative;
  display: block;
  padding: 20px 48px 4px 16px;
  margin-bottom: 24px;
  border: 1px solid #e5e5e5;
  color: #000000;
  background-color: #ffffff;
  border-radius: 16px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.21);
  transition-property: background, border, color;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out; }
  .c-input:hover, .c-input.is-hover {
    border-color: #f2663b;
    box-shadow: 0px 2px 4px rgba(242, 102, 59, 0.21); }
  .c-input__prepend {
    position: absolute;
    top: 12px;
    left: 16px;
    color: #757575;
    color: #000000;
    transition-property: transform, top, color, opacity;
    transition-duration: 0.2s;
    transition-timing-function: ease-in-out; }
  .c-input__label {
    position: absolute;
    display: block;
    color: #757575;
    top: 12px;
    left: 16px;
    opacity: 1;
    transition-property: font-size, transform, line-height, top, left, color, opacity;
    transition-duration: 0.2s;
    transition-timing-function: ease-in-out; }
  .c-input__input {
    position: relative;
    display: block;
    width: 100%;
    border: none;
    border-radius: 0;
    background: none;
    outline: none;
    box-shadow: none;
    transition-property: color;
    transition-duration: 0.2s;
    transition-timing-function: ease-in-out;
    z-index: 1;
    resize: none; }
    .c-input__input:focus, .c-input__input:hover {
      background: none;
      color: inherit; }
    .c-input__input[type=number] {
      -moz-appearance: textfield; }
      .c-input__input[type=number]::-webkit-inner-spin-button, .c-input__input[type=number]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0; }
  .c-input__validation-hint, .c-input__validation-message, .c-input__info-message {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    position: absolute;
    top: 100%;
    left: 0;
    margin-top: 8px;
    padding: 8px;
    background: #ffb20a;
    color: #ffffff;
    z-index: 10;
    font-family: "futura-pt", sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: normal;
    opacity: 0;
    pointer-events: none;
    transition: opacity .34s ease-in-out; }
    .c-input__validation-hint::before, .c-input__validation-message::before, .c-input__info-message::before {
      content: '';
      position: absolute;
      display: block;
      top: -16px;
      left: 8px;
      border: 8px solid #ffb20a;
      border-color: transparent transparent #ffb20a; }
  .c-input__validation-message {
    background: #d31a35; }
    .c-input__validation-message::before {
      border-color: transparent transparent #d31a35; }
  .c-input__info-message {
    background: #005EB0; }
    .c-input__info-message::before {
      border-color: transparent transparent #005EB0; }
  .c-input__validation-hint, .c-input__validation-message {
    padding: 2px 8px;
    background: #ffffff;
    color: #000000;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.21); }
    .c-input__validation-hint::before, .c-input__validation-message::before {
      content: '';
      display: block;
      position: absolute;
      top: -16px;
      border: 8px solid #ffffff;
      border-color: transparent transparent #ffffff; }
  .c-input .c-progress-circle {
    position: absolute;
    right: 12px;
    bottom: 12px;
    transform: scale(0); }
  .c-input--input-button .c-input__input,
  .c-input--input-button .c-input__input-button {
    width: 50%;
    max-width: none; }
  .c-input--input-button .c-input__input {
    padding-right: 36px; }
  .c-input--input-button .c-input__input-button {
    font-family: "futura-pt", sans-serif;
    font-weight: 700;
    height: calc(100% + 2px);
    position: absolute;
    top: -1px;
    right: -1px;
    background: #000000;
    color: #ffffff;
    padding: 8px; }
    .c-input--input-button .c-input__input-button::before {
      font-family: 'ffreesicons', sans-serif;
      font-weight: 500;
      font-style: normal;
      content: "";
      margin-right: 8px; }
  .c-input--input-button::after {
    left: calc(50% - $space-xl);
    right: auto; }
  .c-input--dropdown {
    position: relative; }
    .c-input--dropdown::after {
      font-family: 'ffreesicons', sans-serif;
      font-weight: 500;
      font-style: normal;
      transition-property: color, opacity, transform, font-size, top, bottom;
      transition-duration: 0.2s;
      transition-timing-function: ease-in-out;
      position: absolute;
      transform: translate3d(0, -50%, 0);
      top: 50%;
      display: block;
      right: 16px;
      color: #757575;
      font-size: inherit;
      line-height: inherit;
      content: "";
      color: rgba(0, 0, 0, 0.5);
      pointer-events: none; }
    .c-input--dropdown .c-input__dropdown {
      position: absolute;
      opacity: 0;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0; }
    .c-input--dropdown .c-input__hidden-option {
      display: none; }
    .c-input--dropdown .c-input__dropdown-text {
      display: block;
      line-height: 1.35; }
      .c-input--dropdown .c-input__dropdown-text span {
        visibility: hidden; }
  .c-input--search::after {
    font-family: 'ffreesicons', sans-serif;
    font-weight: 500;
    font-style: normal;
    transition-property: color, opacity, transform, font-size, top, bottom;
    transition-duration: 0.2s;
    transition-timing-function: ease-in-out;
    position: absolute;
    transform: translate3d(0, -50%, 0);
    top: 50%;
    display: block;
    right: 16px;
    color: #757575;
    font-size: inherit;
    line-height: inherit;
    content: ""; }
  .c-input--max-width-350 {
    max-width: 350px; }
  .c-input--prepended {
    padding-left: 28px; }
    .c-input--prepended .c-input__prepend {
      opacity: 0; }
  .c-input--margin-bottom-xxs {
    margin-bottom: 8px; }
  .c-input.is-active .c-input__label, .c-input.is-filled .c-input__label, .c-input--small-label .c-input__label {
    font-size: 10px;
    line-height: 1px;
    transform: translate3d(0, 3px, 0);
    top: 6px; }
  .c-input.is-active .c-input__prepend, .c-input.is-filled .c-input__prepend, .c-input--small-label .c-input__prepend {
    top: 19px;
    transform: translateY(0); }
  .c-input.is-active.c-input--prepended .c-input__prepend, .c-input.is-filled.c-input--prepended .c-input__prepend, .c-input--small-label.c-input--prepended .c-input__prepend {
    opacity: 1; }
  .c-input.is-active.c-input--prepended .c-input__label, .c-input.is-filled.c-input--prepended .c-input__label, .c-input--small-label.c-input--prepended .c-input__label {
    left: 30px; }
  .c-input.is-empty, .c-input.is-empty:hover, .c-input.is-empty:focus {
    box-shadow: 0px 2px 4px rgba(255, 178, 10, 0.21);
    border-color: #ffb20a; }
    .c-input.is-empty::after, .c-input.is-empty:hover::after, .c-input.is-empty:focus::after {
      font-family: 'ffreesicons', sans-serif;
      font-weight: 500;
      font-style: normal;
      transition-property: color, opacity, transform, font-size, top, bottom;
      transition-duration: 0.2s;
      transition-timing-function: ease-in-out;
      position: absolute;
      transform: translate3d(0, -50%, 0);
      top: 50%;
      display: block;
      right: 16px;
      color: #757575;
      font-size: inherit;
      line-height: inherit;
      content: "";
      color: #ffb20a; }
    .c-input.is-empty .c-input__validation-hint, .c-input.is-empty:hover .c-input__validation-hint, .c-input.is-empty:focus .c-input__validation-hint {
      opacity: 1; }
      .c-input.is-empty .c-input__validation-hint::after, .c-input.is-empty:hover .c-input__validation-hint::after, .c-input.is-empty:focus .c-input__validation-hint::after {
        font-family: 'ffreesicons', sans-serif;
        font-weight: 500;
        font-style: normal;
        transition-property: color, opacity;
        transition-duration: 0.2s;
        transition-timing-function: ease-in-out;
        display: block;
        position: relative;
        float: left;
        color: #757575;
        margin-right: 12px;
        content: "";
        color: #ffb20a; }
  .c-input.is-active, .c-input.is-active:hover, .c-input.is-active:focus {
    box-shadow: 0px 2px 4px rgba(242, 102, 59, 0.21);
    border-color: #f2663b; }
    .c-input.is-active::after, .c-input.is-active:hover::after, .c-input.is-active:focus::after {
      color: #f2663b; }
    .c-input.is-active .c-progress-circle, .c-input.is-active:hover .c-progress-circle, .c-input.is-active:focus .c-progress-circle {
      transform: scale(1); }
  .c-input.is-invalid, .c-input.is-invalid:hover, .c-input.is-invalid:focus {
    box-shadow: 0px 2px 4px rgba(211, 26, 53, 0.21);
    border-color: #d31a35; }
    .c-input.is-invalid::after, .c-input.is-invalid:hover::after, .c-input.is-invalid:focus::after {
      font-family: 'ffreesicons', sans-serif;
      font-weight: 500;
      font-style: normal;
      transition-property: color, opacity, transform, font-size, top, bottom;
      transition-duration: 0.2s;
      transition-timing-function: ease-in-out;
      position: absolute;
      transform: translate3d(0, -50%, 0);
      top: 50%;
      display: block;
      right: 16px;
      color: #757575;
      font-size: inherit;
      line-height: inherit;
      content: "";
      color: #d31a35; }
    .c-input.is-invalid .c-input__validation-message, .c-input.is-invalid:hover .c-input__validation-message, .c-input.is-invalid:focus .c-input__validation-message {
      opacity: 1; }
      .c-input.is-invalid .c-input__validation-message::after, .c-input.is-invalid:hover .c-input__validation-message::after, .c-input.is-invalid:focus .c-input__validation-message::after {
        font-family: 'ffreesicons', sans-serif;
        font-weight: 500;
        font-style: normal;
        transition-property: color, opacity;
        transition-duration: 0.2s;
        transition-timing-function: ease-in-out;
        display: block;
        position: relative;
        float: left;
        color: #757575;
        margin-right: 12px;
        content: "";
        color: #d31a35; }
  .c-input.is-valid, .c-input.is-valid:hover, .c-input.is-valid:focus {
    box-shadow: 0px 2px 4px rgba(110, 181, 43, 0.21);
    border-color: #6eb52b; }
    .c-input.is-valid::after, .c-input.is-valid:hover::after, .c-input.is-valid:focus::after {
      font-family: 'ffreesicons', sans-serif;
      font-weight: 500;
      font-style: normal;
      transition-property: color, opacity, transform, font-size, top, bottom;
      transition-duration: 0.2s;
      transition-timing-function: ease-in-out;
      position: absolute;
      transform: translate3d(0, -50%, 0);
      top: 50%;
      display: block;
      right: 16px;
      color: #757575;
      font-size: inherit;
      line-height: inherit;
      content: "";
      color: #6eb52b; }
    .c-input.is-valid.c-input--dropdown::after, .c-input.is-valid:hover.c-input--dropdown::after, .c-input.is-valid:focus.c-input--dropdown::after {
      content: ""; }
  .c-input.is-instructional .c-input__info-message, .c-input.is-instructional:hover .c-input__info-message, .c-input.is-instructional:focus .c-input__info-message {
    opacity: 1; }
  .c-input--with-progress-circle.c-input--with-progress-circle.c-input--with-progress-circle::after {
    top: auto;
    bottom: 12px;
    transform: scale(1);
    transition: all 250ms 250ms; }
  .c-input--with-progress-circle.c-input--with-progress-circle.c-input--with-progress-circle.is-active::after {
    transform: scale(0);
    transition-delay: 0; }
  .c-input--with-progress-circle.c-input--with-progress-circle.c-input--with-progress-circle.is-active .c-progress-circle {
    transition-delay: 250ms; }
  @media (min-width: 800px) {
    .c-input {
      padding-top: 16px;
      margin-bottom: 28px; }
      .c-input__prepend, .c-input__label {
        top: 10px; }
      .c-input__prepend, .c-input__label {
        top: 10px; }
      .c-input__prepend, .c-input__label {
        top: 10px; }
      .c-input.is-active .c-input__prepend, .c-input.is-filled .c-input__prepend, .c-input--small-label .c-input__prepend {
        top: 15px; }
      .c-input--large {
        font-size: 20px;
        line-height: 28px;
        padding: 26px 60px 10px 28px;
        border-radius: 20px; }
        .c-input--large .c-input__prepend,
        .c-input--large .c-input__label {
          top: 18px;
          left: 28px; }
        .c-input--large::after {
          right: 28px; }
        .c-input--large.c-input--prepended {
          padding-left: 60px; }
        .c-input--large.is-active .c-input__label, .c-input--large.is-filled .c-input__label,
        .c-input--large .c-input--small-label .c-input__label {
          font-size: 14px;
          line-height: 1px;
          top: 12px; }
        .c-input--large.is-active .c-input__prepend, .c-input--large.is-filled .c-input__prepend,
        .c-input--large .c-input--small-label .c-input__prepend {
          top: 27px; }
        .c-input--large.is-active.c-input--prepended .c-input__label, .c-input--large.is-filled.c-input--prepended .c-input__label,
        .c-input--large .c-input--small-label.c-input--prepended .c-input__label {
          left: 62px; } }

#imessage {
  display: none; }

/*doc
---
title: Text Input
name: text-input
category: Inputs
---

Full documentation can be found in <a href="https://ffrees.atlassian.net/wiki/spaces/PROD/pages/1003815560/Input+component+.c-input+config">input documentation</a>

```html_example_table
<form>
    <div class="js-input-validation">
        <label for="test" id="test-label" class="u-display-block u-margin-bottom-xxs">First name</label>
        <label class="c-input">
            <span class="c-input__label">Type here</span>
            <input
            name="Name"
            value=""
            type="text"
            id="test"
            class="c-input__input js-input"
            data-v-input-min="1"
            data-v-input-max="100"
            data-v-type="free-text"
            data-v-hint="Please fill this in"
            data-v-message="Please enter a valid name"
            aria-labelledby="test-label"
            required>
            <span class="c-input__validation-hint"></span>
            <span class="c-input__validation-message"></span>
        </label>
    </div>
</form>
```
<div class="c-sg-modifier-wrap js-modifier-wrap">
    <div>
        <button data-target="c-input">c-input--large</button>
    </div>
    <div>
        <button data-target="c-input">c-input--small-label</button>
    </div>
    <div>
        <button data-target="c-input">c-input--search</button>
    </div>
</div>

*/
/*doc
---
title: Prepended Input
name: prepended-input
category: Inputs
---

Full documentation can be found in <a href="https://ffrees.atlassian.net/wiki/spaces/PROD/pages/1003815560/Input+component+.c-input+config">input documentation</a>

```html_example_table
<form>
    <div class="js-input-validation">
        <label for="test" id="test-label" class="u-display-block u-margin-bottom-xxs">Monthly take-home pay</label>
        <label class="c-input c-input--prepended">
            <span class="c-input__label">Type here</span>
            <span class="c-input__prepend">£</span>
            <input
            name="Name"
            value=""
            type="text"
            id="test"
            class="c-input__input js-input"
            data-v-input-min="1"
            data-v-input-max="100"
            data-v-type="free-text"
            data-v-hint="Please fill this in"
            data-v-message="Please enter a valid name"
            aria-labelledby="test-label"
            required>
            <span class="c-input__validation-hint"></span>
            <span class="c-input__validation-message"></span>
        </label>
    </div>
</form>
```
<div class="c-sg-modifier-wrap js-modifier-wrap">
    <div>
        <button data-target="c-input">c-input--large</button>
    </div>
    <div>
        <button data-target="c-input">c-input--margin-bottom-xxs</button>
    </div>
</div>

*/
/*doc
---
title: Select Input
name: select-input
category: Inputs
---

Full documentation can be found in <a href="https://ffrees.atlassian.net/wiki/spaces/PROD/pages/1003815560/Input+component+.c-input+config">input documentation</a>

```html_example_table
<form>
    <label class="c-input c-input--dropdown">
        <span class="c-input__label">How often</span>
        <span class="c-input__dropdown-text js-dropdown-text">
            <span>filler</span>
        </span>
        <select
        id="test3"
        class="c-input__dropdown js-input"
        name="frequency"
        required=""
        >
            <option selected="" disabled="" class="c-input__hidden-option"></option>
            <option disabled="">Please pick one</option>
            <option value="Monthly">Monthly</option>
            <option value="Fortnightly">Fortnightly</option>
        </select>
        <span class="c-input__validation-hint"></span>
        <span class="c-input__validation-message"></span>
    </label>
</form>
```
<div class="c-sg-modifier-wrap js-modifier-wrap">
    <div>
        <button data-target="c-input">c-input--large</button>
    </div>
</div>

*/
/*doc
---
title: Input and Button
name: input-button
category: Inputs
---

Full documentation can be found in <a href="https://ffrees.atlassian.net/wiki/spaces/PROD/pages/1003815560/Input+component+.c-input+config">input documentation</a>

```html_example_table
<form>
    <label class="c-input c-input--input-button js-input-validation">
        <span class="c-input__label">Postcode</span>
        <input
        id="firstAddress"
        class="c-input__input js-input"
        name="addressPostcode"
        value=""
        type="text"
        data-v-input-min="5"
        data-v-input-max="8"
        data-v-test="reg-match"
        data-v-type="postcode"
        data-v-hint="Please fill this in"
        data-v-message="Oops! Invalid postcode"
        required >
        <button class="c-input__input-button">
            Find Address
        </button>
        <span class="c-input__validation-hint"></span>
        <span class="c-input__validation-message"></span>
    </label>
</form>
```
*/
.c-limit-table {
  height: 0;
  overflow: hidden; }
  .c-limit-table__row {
    width: 100%;
    padding: 12px;
    background-color: #f6f6f6; }
    .c-limit-table__row:nth-child(2n) {
      background-color: #ffffff; }
  .c-limit-table__title {
    font-size: 16px;
    line-height: 24px;
    font-family: "futura-pt", sans-serif;
    font-weight: 500;
    margin-bottom: 8px;
    font-family: "futura-pt", sans-serif;
    font-weight: 700;
    text-align: left;
    text-transform: none;
    margin-bottom: 12px; }
    @media (min-width: 980px) {
      .c-limit-table__title {
        font-size: 20px;
        line-height: 28px; } }
  .c-limit-table__values {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    text-align: center; }
  .c-limit-table__value {
    margin: 0;
    padding: 0 8px;
    align-content: flex-start;
    text-align: center; }
  .c-limit-table__value-title {
    display: block;
    text-transform: none;
    color: #f2663b;
    font-size: 12px;
    line-height: 18px;
    font-family: "futura-pt", sans-serif;
    font-weight: 500; }
    @media (min-width: 980px) {
      .c-limit-table__value-title {
        font-size: 14px;
        line-height: 18px; } }

/*doc
---
title: Limit table
name: limits-table
category: Components
---

Note - This component is hidden until the data is loaded in on the page, at which point height: auto is added to the element

```html_example
<div class="c-limit-table js-account-limits" data-limit="getActiveLimits" style="height: auto;">
    <div class="c-limit-table__row js-limit-row">
        <h3 class="c-limit-table__title js-limit-title">Debit Card Payments</h3>
        <div class="c-limit-table__values">
            <h4 class="c-limit-table__value">
                <small class="c-limit-table__value-title">Daily Limit</small>
                <span class="js-limit-daily-limit">£3500</span>
            </h4>
            <h4 class="c-limit-table__value">
                <small class="c-limit-table__value-title">7 Day Limit</small>
                <span class="js-weekly-limit">£7500</span>
            </h4>
            <h4 class="c-limit-table__value">
                <small class="c-limit-table__value-title">30 Day Limit</small>
                <span class="js-monthly-limit">£12000</span>
            </h4>
        </div>
    </div>
    <div class="c-limit-table__row js-limit-row">
        <h3 class="c-limit-table__title js-limit-title">Same-day Payments Out</h3>
        <div class="c-limit-table__values">
            <h4 class="c-limit-table__value">
                <small class="c-limit-table__value-title">Daily Limit</small>
                <span class="js-limit-daily-limit">£3250</span>
            </h4>
            <h4 class="c-limit-table__value">
                <small class="c-limit-table__value-title">7 Day Limit</small>
                <span class="js-weekly-limit">£6000</span>
            </h4>
            <h4 class="c-limit-table__value">
                <small class="c-limit-table__value-title">30 Day Limit</small>
                <span class="js-monthly-limit">£10000</span>
            </h4>
        </div>
    </div>
</div>

```
*/
.c-list {
  padding: 0;
  margin: 28px 0;
  list-style: none;
  text-align: left; }
  .c-list__item {
    background: #ffffff;
    padding: 16px 24px;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-items: center;
    margin: 0;
    border-top: 1px solid #e5e5e5; }
    .c-list__item:last-child {
      border-bottom: 1px solid #e5e5e5; }
    .c-list__item--condensed {
      padding-top: 8px;
      padding-bottom: 8px; }
      .c-list__item--condensed .c-list__link {
        padding-top: 8px;
        padding-bottom: 8px; }
    .c-list__item--link {
      padding: 0;
      background: none; }
      .c-list__item--link > * {
        width: 100%; }
  .c-list__link {
    background: #ffffff;
    padding: 16px 24px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    color: inherit;
    text-decoration: none;
    font-family: "futura-pt", sans-serif;
    font-weight: 500;
    transition: all 250ms; }
    .c-list__link:hover, .c-list__link:focus, .c-list__link:active {
      color: inherit;
      background: #f1f1f1; }
  .c-list__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 20px; }
    .c-list__icon .c-icon {
      height: 24px;
      width: 28px; }
  .c-list__body {
    display: block; }
  .c-list__subtext {
    font-size: 12px;
    line-height: 18px;
    color: #757575;
    margin-top: -2px;
    margin-bottom: 1px;
    display: block; }
    @media (min-width: 980px) {
      .c-list__subtext {
        font-size: 14px;
        line-height: 18px; } }
  .c-list__actions {
    margin-left: auto; }
  .c-list__action + .c-list__action {
    margin-left: 12px; }
  .c-list--style-b {
    font-size: 16px;
    line-height: 24px;
    margin: 0; }
    .c-list--style-b .c-list__item {
      border: 0; }
    .c-list--style-b .c-list__link {
      margin-bottom: 4px;
      padding: 8px 24px;
      border-radius: 16px; }
  @media (min-width: 980px) {
    .c-list__icon {
      margin-right: 36px; }
    .c-list--buttons-after-m .c-list__item {
      border: 0;
      margin-bottom: 8px; }
    .c-list--buttons-after-m .c-list__link {
      border: 1px solid #e5e5e5;
      border-radius: 16px;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.21);
      padding-left: 16px;
      padding-right: 16px; }
      .c-list--buttons-after-m .c-list__link:hover, .c-list--buttons-after-m .c-list__link:focus, .c-list--buttons-after-m .c-list__link:active, .c-list--buttons-after-m .c-list__link.is-selected-desktop {
        border-color: #f2663b;
        background: #fef8f7;
        box-shadow: 0px 2px 4px rgba(234, 67, 16, 0.21); }
      .c-list--buttons-after-m .c-list__link.is-disabled {
        pointer-events: none; }
    .c-list--style-b {
      font-size: 20px;
      line-height: 28px; }
      .c-list--style-b .c-list__link {
        padding: 16px 36px; } }

/*doc
---
title: List
name: list
category: Components
---

```html_example
<ol class="c-list">
    <li class="c-list__item c-list__item--link">
        <a href="#" class="c-list__link">
            <span class="c-list__icon" aria-hidden="true">
                <svg viewBox="0 0 100 100" class="c-icon c-icon--fill-color-1">
                    <use xlink:href="#help"></use>
                </svg>
            </span>
            <span class="c-list__body">Retrieve my PIN</span>
            <span class="c-list__actions">
                <span class="c-list__action u-before-icon-angle-right"></span>
            </span>
        </a>
    </li>
    <li class="c-list__item c-list__item--link c-list__item--condensed">
        <button class="c-list__link">
            <span class="c-list__icon" aria-hidden="true">
                <svg viewBox="0 0 100 100" class="c-icon c-icon--fill-color-1">
                    <use xlink:href="#help"></use>
                </svg>
            </span>
            <span class="c-list__body">
                Lock my card
                <span class="c-list__subtext">Tap again to unlock your card</span>
            </span>
        </button>
    </li>
    <li class="c-list__item">
        2 Oct 2018 - 1 Oct 2019
        <span class="c-list__actions">
            <span class="c-list__action" title="Generating">
                <span class="u-before-icon-loading-circle u-animate-const-rotate-before u-color-1"></span>
            </span>
        </span>
    </li>
</ol>
```
<div class="c-sg-modifier-wrap js-modifier-wrap">
    <div>
        <button>c-list--buttons-after-m</button>
        <button>c-list--style-b</button>
    </div>
</div>
*/
.c-loan-calculator__calculator {
  max-width: 520px; }

.c-loan-calculator__table {
  opacity: 1;
  transition: opacity 250ms; }

.c-loan-calculator__row {
  display: flex;
  justify-content: flex-start; }
  .c-loan-calculator__row--after-s-bp {
    display: block; }
    .c-loan-calculator__row--after-s-bp .c-loan-calculator__row-item {
      width: auto; }

.c-loan-calculator__row-item {
  width: 33%; }

.c-loan-calculator__hint {
  display: flex;
  color: #005EB0;
  font-size: 14px;
  line-height: 18px;
  margin-top: 8px; }
  @media (min-width: 980px) {
    .c-loan-calculator__hint {
      font-size: 16px;
      line-height: 20px; } }
  .c-loan-calculator__hint::before {
    font-family: 'ffreesicons', sans-serif;
    font-weight: 500;
    font-style: normal;
    content: "";
    margin-right: 8px; }

.c-loan-calculator__separator {
  background-color: #ebebeb; }

.c-loan-calculator__label, .c-loan-calculator__total-label {
  font-family: "futura-pt", sans-serif;
  font-weight: 700;
  margin-bottom: 0;
  color: #afafaf; }

.c-loan-calculator__total-label {
  color: #f2663b; }

.c-loan-calculator__value {
  margin-bottom: 16px;
  font-size: 22px;
  line-height: 29px;
  font-family: "futura-pt", sans-serif;
  font-weight: 700;
  display: block; }
  @media (min-width: 980px) {
    .c-loan-calculator__value {
      font-size: 28px;
      line-height: 40px; } }
  .c-loan-calculator__value::after {
    content: "​"; }

.c-loan-calculator__sign {
  font-size: 70%;
  line-height: 1; }

.c-loan-calculator__total {
  color: #f2663b;
  margin-bottom: 28px;
  font-size: 30px;
  line-height: 38px;
  font-family: "futura-pt", sans-serif;
  font-weight: 700;
  margin: 0; }
  @media (min-width: 980px) {
    .c-loan-calculator__total {
      margin-bottom: 36px;
      font-size: 44px;
      line-height: 56px; } }

.c-loan-calculator.is-loading .c-loan-calculator__table {
  opacity: 0.3; }

@media (min-width: 800px) {
  .c-loan-calculator__row--after-s-bp {
    display: flex; }
    .c-loan-calculator__row--after-s-bp .c-loan-calculator__row-item {
      width: 33%; } }

@media (min-width: 980px) {
  .c-loan-calculator__total {
    margin: 0; }
  .c-loan-calculator__row {
    justify-content: space-between; }
    .c-loan-calculator__row--until-m-bp {
      display: block; }
      .c-loan-calculator__row--until-m-bp .c-loan-calculator__row-item {
        width: auto; }
  .c-loan-calculator__row-item {
    width: auto; } }

.c-message {
  transition: height .5s ease-in-out, max-height .5s ease-in-out;
  padding: 0;
  border-bottom: 1px solid #ffffff;
  font-family: "futura-pt", sans-serif;
  font-weight: 500; }
  .c-message__body {
    max-width: 1440px;
    margin-right: auto;
    margin-left: auto;
    padding-right: 24px;
    padding-left: 24px;
    min-width: 0;
    padding: 12px;
    color: #ffffff;
    position: relative;
    will-change: height; }
  .c-message__copy:last-child {
    margin-bottom: 0; }
  .c-message__copy--text-align-center {
    text-align: center; }
  .c-message__link {
    color: #ffffff;
    text-decoration: underline; }
    .c-message__link:hover, .c-message__link:focus, .c-message__link:active {
      color: #e6e6e6; }
  .c-message__dismiss-btn {
    font-family: "futura-pt", sans-serif;
    font-weight: 700;
    display: inline-block;
    padding: 8px 20px;
    text-decoration: none;
    text-align: center;
    margin-bottom: 16px;
    transition-property: background-color, color, transform, box-shadow, border-color;
    transition-duration: .2s, .2s, .2s, .2s;
    transition-timing-function: ease-in-out, ease-in-out, ease-in-out, ease-in-out;
    display: none;
    width: 44px;
    height: 44px;
    padding: 0;
    margin: 0;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    color: #ffffff;
    background-color: transparent;
    line-height: 1; }
    .c-message__dismiss-btn:after {
      font-family: 'ffreesicons', sans-serif;
      font-weight: 500;
      font-style: normal;
      content: "";
      font-weight: 900;
      font-size: 25px; }
    .c-message__dismiss-btn:hover, .c-message__dismiss-btn:focus, .c-message__dismiss-btn:active {
      color: #f2663b; }
  .c-message--with-dismiss-btn .c-message__body {
    padding-left: 68px; }
  .c-message--with-dismiss-btn .c-message__dismiss-btn {
    display: block; }
  .c-message--error {
    background-color: #d31a35; }
    .c-message--error .c-message__dismiss-btn:hover, .c-message--error .c-message__dismiss-btn:focus, .c-message--error .c-message__dismiss-btn:active {
      color: #FABCCC; }
  .c-message--info {
    background-color: #005EB0; }
    .c-message--info .c-message__dismiss-btn:hover, .c-message--info .c-message__dismiss-btn:focus, .c-message--info .c-message__dismiss-btn:active {
      color: #E8F1F8; }
  .c-message--info-light {
    background-color: #E8F1F8;
    color: #000000;
    border: 1px solid #005EB0; }
    .c-message--info-light .c-message__body {
      color: #000000; }
    .c-message--info-light .c-message__link {
      color: #000000; }
    .c-message--info-light .c-message__dismiss-btn {
      color: #000000; }
      .c-message--info-light .c-message__dismiss-btn:hover, .c-message--info-light .c-message__dismiss-btn:focus, .c-message--info-light .c-message__dismiss-btn:active {
        color: #000000; }
  .c-message--margin-bottom-xs {
    margin-bottom: 12px; }
  .c-message--padding-left-right-l {
    padding: 0 24px; }
  .c-message--no-border {
    border-bottom: 0; }
  .c-message--error-container > div {
    padding: 24px;
    text-align: center;
    color: #ffffff;
    position: relative;
    will-change: height; }
    .c-message--error-container > div a {
      color: currentColor; }
    .c-message--error-container > div p:last-child {
      margin-bottom: 0; }
    .c-message--error-container > div .flash-highlight {
      color: black; }
  .c-message--error-container .successMessage {
    background-color: #6eb52b; }
  .c-message--error-container .errorMessage {
    background-color: #d31a35; }
  .c-message--error-container .warningMessage {
    background-color: #ffb20a; }
  .c-message--error-container .infoMessage {
    background-color: #005EB0; }
  @media (min-width: 980px) {
    .c-message__body {
      padding: 16px; }
    .c-message--with-dismiss-btn .c-message__body {
      padding-left: 60px; } }

/*doc
---
title: Message
name: message
category: Components
---

```html_example
    <div class="c-message c-message--info js-dismissible-message">
        <div class="c-message__body">
            <button class="c-message__dismiss-btn js-message-dismiss-btn"></button>
            <p class="c-message__copy">You have an outstanding account management fee of <strong>£10</strong>. we'll take this amount from your balance when you next pay in to your account. </p>
        </div>
    </div>
```
<div class="c-sg-modifier-wrap js-modifier-wrap">
    <div>
        <button>c-message--with-dismiss-btn</button>
    </div>
    <div>
        <button>c-message--error</button>
        <button>c-message--info</button>
        <button>c-message--info-light</button>
    </div>
    <div>
        <button>c-message--margin-bottom-xs</button>
    </div>
    <div>
        <button>c-message--padding-left-right-l</button>
    </div>
    <div>
        <button>c-message--no-border</button>
    </div>
    <div>
        <button data-target="c-message__copy">c-message__copy--text-align-center</button>
    </div>
</div>

*/
/*doc
---
title: Message (Error Container)
name: message-error-container
category: Components
---
These messages are not fully refactored - they need to be reworked or removed from back end

```html_example_table
    <div class="c-message c-message--error-container"><div class="successMessage">Success Message</div></div>

    <div class="c-message c-message--error-container"><div class="errorMessage">Error Message</div></div>

    <div class="c-message c-message--error-container"><div class="infoMessage">Info Message</div></div>

    <div class="c-message c-message--error-container"><div class="warningMessage">Warning Message</div></div>
```

*/
.c-notification {
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.21);
  position: relative;
  width: 0;
  margin: calc(22px/2);
  border-radius: 50%;
  background-color: #d31a35;
  color: #ffffff;
  transition: width 0.3s ease-in-out, margin 0.3s ease-in-out; }
  .c-notification:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: 100%; }
  .c-notification__text {
    opacity: 0;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-size: 13px;
    line-height: 1;
    transition: opacity 0.3s ease-in-out; }
  .c-notification--font-heavy {
    font-family: "futura-pt", sans-serif;
    font-weight: 700; }
  .c-notification--variable-width {
    display: none;
    border-radius: 10px;
    padding: 2px 5px; }
    .c-notification--variable-width::before {
      content: none; }
    .c-notification--variable-width .c-notification__text {
      position: relative;
      top: 0;
      left: 0;
      transform: none; }
  .c-notification--dot {
    border-radius: 50%;
    padding: 0;
    box-shadow: none; }
    .c-notification--dot::before {
      content: ""; }
    .c-notification--dot .c-notification__text {
      display: none; }
  .c-notification--color-1 {
    background-color: #f2663b; }
    .c-notification--color-1 .c-notification__text {
      background-color: #f2663b; }
  .c-notification.is-shown {
    width: 22px;
    margin: 0;
    border: 2px solid #ffffff; }
    .c-notification.is-shown .c-notification__text {
      opacity: 1; }
  .c-notification.is-shown.c-notification--variable-width {
    display: inline-block;
    width: auto;
    margin: 0; }
  .c-notification.is-shown.c-notification--dot {
    width: 8px;
    margin: 0;
    border: 0; }
  .c-notification.is-shown.c-notification--thin-border {
    border-width: 1px;
    box-shadow: none; }
  @media (min-width: 980px) {
    .c-notification__text {
      font-size: 12px;
      line-height: 1; }
    .c-notification.is-shown {
      width: 18px;
      margin: 0;
      border: 0; }
      .c-notification.is-shown .c-notification__text {
        opacity: 1; }
    .c-notification.is-shown.c-notification--variable-width {
      width: auto;
      margin: 0; }
    .c-notification.is-shown.c-notification--dot {
      width: 6px;
      margin: 0; } }

/*doc
---
title: notification
name: notification
category: Components
---

Use the <code>is-shown</code> modifier to reveal the notification.

```html_example_table
<div class="c-notification">
    <div class="c-notification__text">2</div>
</div>

<div class="c-notification c-notification--variable-width">
    <div class="c-notification__text">New</div>
</div>
```
<div class="c-sg-modifier-wrap js-modifier-wrap">
    <div>
        <button>c-notification--dot</button>
    </div>
    <div>
        <button>c-notification--color-1</button>
    </div>
    <div>
        <button>c-notification--font-heavy</button>
    </div>
    <div>
        <button>c-notification--thin-border</button>
    </div>
    <div>
        <button>is-shown</button>
    </div>
</div>
*/
.c-numbered-list {
  counter-reset: item; }
  .c-numbered-list__item {
    position: relative;
    display: block; }
    .c-numbered-list__item:before {
      content: counters(item, ".") ".";
      counter-increment: item;
      display: inline-block;
      text-align: right;
      font-family: "futura-pt", sans-serif;
      font-weight: 700;
      font-style: italic;
      position: absolute;
      right: calc(100% + 8px); }
  .c-numbered-list .c-numbered-list {
    margin-top: 16px; }
  .c-numbered-list--letters .c-numbered-list__item:before {
    content: "(" counter(item, lower-alpha) ")"; }
  @media (min-width: 980px) {
    .c-numbered-list {
      padding-left: 48px; } }

/*doc
---
title: Numbered List
name: numbered-list
category: Components
---
```html_example
<ol class="c-numbered-list">
    <li class="c-numbered-list__item">Make sure all your information and loan details are correct</li>
    <li class="c-numbered-list__item">Confirm you're happy for us to share your application details with the lender before continuing</li>
    <li class="c-numbered-list__item">Wait a few moments for My Finance Club to perform a credit check. If they accept your application, you'll continue on their website</li>
    <li class="c-numbered-list__item">Provide your <span class="u-u-in-copy">U</span> card details to My Finance Club to repay the loan by CPA</li>
    <li class="c-numbered-list__item">Take time to thoroughly read the loan agreement between you and the lender, as well as their terms and conditions, before signing the agreement</li>
</ol>
```
<div class="c-sg-modifier-wrap js-modifier-wrap">
    <div>
        <button>c-numbered-list--letters</button>
    </div>
</div>
*/
.c-offset-box {
  position: relative;
  padding: 20px; }
  .c-offset-box::after {
    content: "";
    position: absolute;
    top: 25%;
    left: 50%;
    height: 75%;
    width: 100%;
    transform: translateX(-50%);
    background: #f6f6f6; }
  @media (min-width: 980px) {
    .c-offset-box {
      margin-top: 100px;
      margin-bottom: 100px;
      padding: 0; }
      .c-offset-box::after {
        top: -100px;
        right: -25%;
        height: calc(100% + (100px * 2));
        width: 75%;
        transform: none; } }

/*doc
---
title: Offset Box
name: offset-box
category: Organisms
---

```html_example
<div class="l-row u-display-flex u-justify-content-center">
    <div class="l-col-m-66">
        <div class="c-offset-box">
            <div class="c-card c-card--padding c-card--extra-raised c-card--white-bg">
                <p>This card is wrapped by <code>c-offset-box</code></p>
            </div>
        </div>
    </div>
</div>
```
*/
.c-payment-card {
  position: relative;
  height: 153px;
  width: 240px;
  margin: 20px auto;
  border-radius: 6px;
  background-color: #f2663b;
  border: 1px solid #f2663b;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.25);
  text-align: left; }
  .c-payment-card__info {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    font-size: 16px;
    line-height: 24px;
    margin: 0;
    width: 100%;
    padding: 12px; }
    @media (min-width: 980px) {
      .c-payment-card__info {
        font-size: 20px;
        line-height: 30px; } }
  .c-payment-card__title {
    color: #ffffff;
    margin-bottom: 8px; }
  .c-payment-card__value {
    margin: 0;
    line-height: 1;
    margin-bottom: 16px;
    font-family: 'Courier New', monospace;
    font-weight: 700; }
  .c-payment-card__logo {
    font-family: 'uLogo', sans-serif;
    font-weight: 300;
    position: absolute;
    bottom: 12px;
    right: 12px;
    color: #ffffff;
    font-size: 28px;
    line-height: 1; }
  .c-payment-card--inactive {
    background: #afafaf;
    border-color: #afafaf; }
  .c-payment-card--damaged {
    background: #ffb20a;
    border-color: #ffb20a; }
  .c-payment-card--inverse {
    background: #ffffff;
    border-color: #f2663b; }
    .c-payment-card--inverse .c-payment-card__title {
      color: #757575; }
    .c-payment-card--inverse .c-payment-card__logo {
      color: #f2663b; }
  .c-payment-card--frozen {
    background: #E8F1F8;
    border-color: #E8F1F8; }
    .c-payment-card--frozen .c-payment-card__title {
      color: #000000; }
    .c-payment-card--frozen .c-payment-card__logo {
      color: #f2663b; }
  .c-payment-card--small {
    height: 48px;
    width: 74px;
    margin: 0;
    box-shadow: none; }
    .c-payment-card--small .c-payment-card__logo {
      font-size: 20px;
      bottom: 4px;
      right: 2px; }
    .c-payment-card--small .c-payment-card__info {
      display: none; }
  @media (min-width: 980px) {
    .c-payment-card {
      width: 297px;
      height: 190px;
      box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.25); }
      .c-payment-card__info {
        padding: 16px; }
      .c-payment-card__logo {
        font-size: 48px; }
      .c-payment-card--small {
        height: 48px;
        width: 74px;
        box-shadow: none; }
        .c-payment-card--small .c-payment-card__logo {
          font-size: 20px; } }

/*doc
---
title: Payment card
name: payment-card
category: Components
---

```html_example
<div class="c-payment-card">
    <span class="c-payment-card__logo">U</span>
    <dl class="c-payment-card__info">
        <dt class="c-payment-card__title">Name</dt>
        <dd class="c-payment-card__value">MR MORAY ABDIOU</dd>
        <dt class="c-payment-card__title">Exp. date</dt>
        <dd class="c-payment-card__value">11/22</dd>
    </dl>
</div>
```
<div class="c-sg-modifier-wrap js-modifier-wrap">
    <div>
        <button>c-payment-card--inactive</button>
        <button>c-payment-card--damaged</button>
        <button>c-payment-card--inverse</button>
        <button>c-payment-card--frozen</button>
    </div>
    <div>
        <button>c-payment-card--small</button>
    </div>
</div>
*/
/*------------------------------------*\
    percentage bar
\*------------------------------------*/
.c-percentage-bar {
  width: 100%;
  height: 24px;
  background: #fcded5;
  position: relative;
  overflow: hidden;
  margin-bottom: 16px; }
  .c-percentage-bar__total {
    transition: width 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    width: 0;
    background: #f2663b;
    position: absolute;
    left: 0;
    height: 24px; }
  .c-percentage-bar--sg-animate-bar .c-percentage-bar__total {
    width: 54%; }

/*doc
---
title: percentage bar
name: percentage-bar
category: Components
---

Note - Set the position of the bar using width as an inline style in the js
<br>
The modifier <code>sg-animate-bar</code> is solely for demonstration purposes in the styleguide to show the bar animate, and is set to 54% in this example

```html_example
<div class="c-percentage-bar">
    <div class="c-percentage-bar__total"></div>
</div>

```
<div class="c-sg-modifier-wrap js-modifier-wrap">
    <div>
        <button>c-percentage-bar--sg-animate-bar</button>
    </div>
</div>
*/
/*------------------------------------*\
    placeholder
\*------------------------------------*/
@keyframes placeholder-translate {
  0% {
    transform: translateX(-100%); }
  100% {
    transform: translateX(0%); } }

.c-placeholder-rows {
  height: 68px;
  margin-bottom: 24px;
  overflow: hidden;
  background: #e6e6e6;
  display: flex;
  position: relative;
  cursor: progress; }
  .c-placeholder-rows:before, .c-placeholder-rows:after {
    content: '';
    width: 180%;
    height: 100%;
    display: block;
    transform: translate(0%);
    flex-shrink: 0;
    background: linear-gradient(to right, rgba(255, 255, 255, 0) 33%, #fafafa 50%, rgba(255, 255, 255, 0) 67%);
    animation: placeholder-translate 2s linear infinite forwards; }
  .c-placeholder-rows__overlay-lines {
    height: 100%;
    position: absolute;
    top: 8px;
    left: 0;
    width: 100%;
    z-index: 1;
    background: linear-gradient(to bottom, #ffffff 60%, transparent 60%);
    background-size: 100% 20px; }
    .c-placeholder-rows__overlay-lines:before, .c-placeholder-rows__overlay-lines:after {
      content: '';
      display: block;
      position: absolute;
      height: 8px;
      width: 50%;
      left: 50%;
      bottom: 8px;
      background: #ffffff; }
    .c-placeholder-rows__overlay-lines:after {
      left: 75%;
      width: 25%;
      bottom: 28px; }
  .c-placeholder-rows--1-rows {
    height: 8px; }
  .c-placeholder-rows--2-rows {
    height: 28px; }
  .c-placeholder-rows--3-rows {
    height: 48px; }
  .c-placeholder-rows--5-rows {
    height: 88px; }
  .c-placeholder-rows--6-rows {
    height: 108px; }
  .c-placeholder-rows--8-rows {
    height: 148px; }
  .c-placeholder-rows--10-rows {
    height: 188px; }
  .c-placeholder-rows--12-rows {
    height: 228px; }
  .c-placeholder-rows--14-rows {
    height: 268px; }
  .c-placeholder-rows--18-rows {
    height: 348px; }
  .c-placeholder-rows--22-rows {
    height: 428px; }

.c-placeholder-table {
  height: 88px;
  margin-bottom: 24px;
  padding: 0;
  overflow: hidden;
  background: #e6e6e6;
  display: flex;
  position: relative;
  cursor: progress; }
  .c-placeholder-table:before, .c-placeholder-table:after {
    content: '';
    width: 180%;
    height: 100%;
    display: block;
    transform: translate(0%);
    flex-shrink: 0;
    background: linear-gradient(to right, rgba(255, 255, 255, 0) 33%, #fafafa 50%, rgba(255, 255, 255, 0) 67%);
    animation: placeholder-translate 2s linear infinite forwards; }
  .c-placeholder-table__overlay-head {
    height: 20px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1; }
    .c-placeholder-table__overlay-head:before, .c-placeholder-table__overlay-head:after {
      content: '';
      display: block;
      height: 100%;
      position: absolute;
      left: 33.33%;
      width: 24px;
      background: #ffffff;
      transform: translateX(-66.6%); }
    .c-placeholder-table__overlay-head:after {
      left: 66.66%;
      transform: translateX(-33.3%); }
  .c-placeholder-table__overlay-lines {
    height: 100%;
    position: absolute;
    top: 8px;
    left: 0;
    width: 100%;
    z-index: 1;
    background: linear-gradient(to bottom, #ffffff 60%, transparent 60%);
    background-size: 100% 20px; }
    .c-placeholder-table__overlay-lines:before, .c-placeholder-table__overlay-lines:after {
      content: '';
      display: block;
      position: absolute;
      height: 100%;
      width: 24px;
      top: 20px;
      left: 33.33%;
      background: #ffffff;
      transform: translateX(-66.6%); }
    .c-placeholder-table__overlay-lines:after {
      left: 66.66%;
      transform: translateX(-33.3%); }
  .c-placeholder-table--4-rows {
    height: 68px; }
  .c-placeholder-table--6-rows {
    height: 108px; }
  .c-placeholder-table--8-rows {
    height: 148px; }
  .c-placeholder-table--10-rows {
    height: 188px; }
  .c-placeholder-table--12-rows {
    height: 228px; }
  .c-placeholder-table--14-rows {
    height: 268px; }
  .c-placeholder-table--18-rows {
    height: 348px; }
  .c-placeholder-table--22-rows {
    height: 428px; }

.c-placeholder-tile {
  height: 128px;
  margin-bottom: 24px;
  overflow: hidden;
  background: #e6e6e6;
  display: flex;
  position: relative;
  cursor: progress; }
  .c-placeholder-tile:before, .c-placeholder-tile:after {
    content: '';
    width: 180%;
    height: 100%;
    display: block;
    transform: translate(0%);
    flex-shrink: 0;
    background: linear-gradient(to right, rgba(255, 255, 255, 0) 33%, #fafafa 50%, rgba(255, 255, 255, 0) 67%);
    animation: placeholder-translate 2s linear infinite forwards; }
  .c-placeholder-tile__overlay-border {
    position: absolute;
    top: 8px;
    left: 8px;
    width: calc(100% - (8px * 2));
    height: calc(100% - (8px * 2));
    padding: 8px;
    border: 12px solid #ffffff;
    z-index: 2; }
    .c-placeholder-tile__overlay-border:before {
      content: '';
      display: block;
      position: absolute;
      bottom: 40px;
      left: 75%;
      width: 25%;
      height: 8px;
      background: #ffffff; }
  .c-placeholder-tile__overlay-lines {
    height: 100%;
    position: absolute;
    top: 8px;
    left: 0;
    width: 100%;
    z-index: 1;
    background: linear-gradient(to bottom, #ffffff 60%, transparent 60%);
    background-size: 100% 20px;
    left: 8px;
    height: calc(100% - (8px * 2));
    width: calc(100% - (8px * 2)); }
    .c-placeholder-tile__overlay-lines:before, .c-placeholder-tile__overlay-lines:after {
      content: '';
      display: block;
      position: absolute;
      bottom: 12px;
      left: 12px;
      width: calc(100% - (12px * 2));
      height: 8px;
      background: #ffffff; }
    .c-placeholder-tile__overlay-lines:before {
      bottom: 32px;
      width: calc(50% - (12px * 1));
      left: 50%;
      background: #ffffff; }
  .c-placeholder-tile--3-rows {
    height: 88px; }
  .c-placeholder-tile--4-rows {
    height: 108px; }
  .c-placeholder-tile--6-rows {
    height: 148px; }
  .c-placeholder-tile--8-rows {
    height: 188px; }
  .c-placeholder-tile--10-rows {
    height: 228px; }
  .c-placeholder-tile--12-rows {
    height: 268px; }
  .c-placeholder-tile--14-rows {
    height: 308px; }

.c-placeholder-tile-wrap {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap; }
  .c-placeholder-tile-wrap .c-placeholder-tile {
    flex: 0 1 auto;
    width: 100%; }
  @media (min-width: 700px) {
    .c-placeholder-tile-wrap .c-placeholder-tile {
      width: calc(50% - 15px); } }
  @media (min-width: 1400px) {
    .c-placeholder-tile-wrap .c-placeholder-tile {
      width: calc(33.3% - 19.8px); } }

.c-placeholder-accordion {
  overflow: hidden;
  background: #e6e6e6;
  display: flex;
  position: relative;
  height: 208px;
  margin-bottom: 1px;
  cursor: progress; }
  .c-placeholder-accordion:before, .c-placeholder-accordion:after {
    content: '';
    width: 180%;
    height: 100%;
    display: block;
    transform: translate(0%);
    flex-shrink: 0;
    background: linear-gradient(to right, rgba(255, 255, 255, 0) 33%, #fafafa 50%, rgba(255, 255, 255, 0) 67%);
    animation: placeholder-translate 2s linear infinite forwards; }
  .c-placeholder-accordion__lines-1 {
    height: 100%;
    position: absolute;
    top: 0;
    left: 8px;
    width: calc(100% - (8px * 2));
    z-index: 1;
    background: linear-gradient(to bottom, transparent 8px, #ffffff 8px, #ffffff 20px, transparent 20px, transparent 28px, #ffffff 28px, #ffffff 40px, transparent 40px);
    background-size: 100% 52px; }
    .c-placeholder-accordion__lines-1:before, .c-placeholder-accordion__lines-1:after {
      content: '';
      height: 100%;
      position: absolute;
      top: 8px;
      left: 0;
      width: 12px;
      z-index: 1;
      background: linear-gradient(to bottom, transparent 8px, #ffffff 8px, #ffffff 32px, transparent 32px);
      background-size: 100% 52px; }
    .c-placeholder-accordion__lines-1:after {
      left: auto;
      right: 0; }
  .c-placeholder-accordion__lines-2 {
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1;
    background: linear-gradient(to bottom, transparent 48px, #ffffff 48px, #ffffff 52px, transparent 52px);
    background-size: 100% 52px; }
    .c-placeholder-accordion__lines-2:after {
      content: '';
      height: 100%;
      position: absolute;
      top: 0;
      right: 28px;
      width: 67%;
      z-index: 1;
      background: linear-gradient(to bottom, transparent 20px, #ffffff 20px, #ffffff 28px, transparent 28px);
      background-size: 100% 52px; }
  .c-placeholder-accordion--1 {
    height: 52px; }
  .c-placeholder-accordion--2 {
    height: 104px; }
  .c-placeholder-accordion--3 {
    height: 156px; }
  .c-placeholder-accordion--4 {
    height: 208px; }
  .c-placeholder-accordion--5 {
    height: 260px; }
  .c-placeholder-accordion--6 {
    height: 312px; }
  .c-placeholder-accordion--7 {
    height: 364px; }
  .c-placeholder-accordion--8 {
    height: 416px; }
  .c-placeholder-accordion--9 {
    height: 468px; }
  .c-placeholder-accordion--10 {
    height: 520px; }
  .c-placeholder-accordion--grey-xxxlight-margin-bg .c-placeholder-accordion__lines-2 {
    background: linear-gradient(to bottom, transparent 48px, #fbfcfc 48px, #fbfcfc 52px, transparent 52px);
    background-size: 100% 52px; }
  .c-placeholder-accordion--margin-bottom-xxs {
    margin-bottom: 8px; }
  @media (min-width: 540px) {
    .c-placeholder-accordion--grey-xxxlight-margin-bg-xs .c-placeholder-accordion__lines-2 {
      background: linear-gradient(to bottom, transparent 48px, #fbfcfc 48px, #fbfcfc 52px, transparent 52px);
      background-size: 100% 52px; } }
  @media (min-width: 1440px) {
    .c-placeholder-accordion--white-margin-bg-xxl .c-placeholder-accordion__lines-2 {
      background: linear-gradient(to bottom, transparent 48px, #ffffff 48px, #ffffff 52px, transparent 52px);
      background-size: 100% 52px; } }

.c-placeholder-content-filter-wrap {
  display: flex;
  justify-content: space-between; }
  .c-placeholder-content-filter-wrap .c-placeholder-content-filter {
    flex: 0 1 auto;
    width: 25%; }
  @media (min-width: 980px) {
    .c-placeholder-content-filter-wrap--filters-margin-left-10 .c-placeholder-content-filter {
      margin-left: 2.5%; } }

.c-placeholder-content-filter {
  overflow: hidden;
  background: #e6e6e6;
  display: flex;
  position: relative;
  height: 48px;
  margin-bottom: 24px;
  cursor: progress; }
  .c-placeholder-content-filter:before, .c-placeholder-content-filter:after {
    content: '';
    width: 180%;
    height: 100%;
    display: block;
    transform: translate(0%);
    flex-shrink: 0;
    background: linear-gradient(to right, rgba(255, 255, 255, 0) 33%, #fafafa 50%, rgba(255, 255, 255, 0) 67%);
    animation: placeholder-translate 2s linear infinite forwards; }
  .c-placeholder-content-filter__lines-1:before, .c-placeholder-content-filter__lines-1:after {
    content: '';
    display: block;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: calc(calc((100% - (8px * 3) - (12px * 2)) / 2) + 12px + 8px);
    height: calc(100% - (8px));
    background-color: #ffffff; }
  .c-placeholder-content-filter__lines-1:after {
    left: calc(calc((100% - (8px * 3) - (12px * 2)) / 2) + (8px * 2) + 12px); }
  .c-placeholder-content-filter__lines-2:before, .c-placeholder-content-filter__lines-2:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    z-index: 1;
    left: calc((100% - (8px * 3) - (12px * 2)) / 2);
    width: 48px;
    height: 20px;
    background-color: #ffffff; }
  .c-placeholder-content-filter__lines-2:after {
    top: 28px;
    height: 12px; }
  .c-placeholder-content-filter__lines-3:before {
    content: '';
    display: block;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 12px;
    height: 100%;
    background-color: #ffffff; }
  .c-placeholder-content-filter__lines-3:after {
    content: '';
    display: block;
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    width: 12px;
    height: 100%;
    background-color: #ffffff; }
  .c-placeholder-content-filter__lines-4:before, .c-placeholder-content-filter__lines-4:after {
    display: block;
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    width: calc((100% - (8px * 3) - (12px * 2)) / 2);
    height: 20px;
    background-color: #ffffff; }
  .c-placeholder-content-filter__lines-4:after {
    top: 28px; }
  .c-placeholder-content-filter--color-grey-xlight-bg {
    background-color: #d1d3d4; }
    .c-placeholder-content-filter--color-grey-xlight-bg:before, .c-placeholder-content-filter--color-grey-xlight-bg:after {
      background: linear-gradient(to right, transparent 33%, #f1f1f1 50%, transparent 67%); }
    .c-placeholder-content-filter--color-grey-xlight-bg .c-placeholder-content-filter__lines-1:before, .c-placeholder-content-filter--color-grey-xlight-bg .c-placeholder-content-filter__lines-1:after,
    .c-placeholder-content-filter--color-grey-xlight-bg .c-placeholder-content-filter__lines-2:before,
    .c-placeholder-content-filter--color-grey-xlight-bg .c-placeholder-content-filter__lines-2:after,
    .c-placeholder-content-filter--color-grey-xlight-bg .c-placeholder-content-filter__lines-3:before,
    .c-placeholder-content-filter--color-grey-xlight-bg .c-placeholder-content-filter__lines-3:after,
    .c-placeholder-content-filter--color-grey-xlight-bg .c-placeholder-content-filter__lines-4:before,
    .c-placeholder-content-filter--color-grey-xlight-bg .c-placeholder-content-filter__lines-4:after {
      background-color: #f1f1f1; }
  @media (min-width: 980px) {
    .c-placeholder-content-filter__lines-1:before, .c-placeholder-content-filter__lines-1:after {
      left: 0;
      width: 20px; }
    .c-placeholder-content-filter__lines-1:after {
      left: 28px; }
    .c-placeholder-content-filter__lines-2:before, .c-placeholder-content-filter__lines-2:after {
      left: 0; }
    .c-placeholder-content-filter__lines-2:after {
      height: 20px; }
    .c-placeholder-content-filter__lines-3:before {
      width: 0; }
    .c-placeholder-content-filter__lines-3:after {
      left: 48px;
      width: 12px; }
    .c-placeholder-content-filter__lines-4:before, .c-placeholder-content-filter__lines-4:after {
      content: '';
      width: calc(100% - (12px * 2) - (8px * 3)); } }

/*doc
---
title: Placeholders
name: placeholders
category: Placeholders
---
<b>Placeholder rows</b> have a default height of 4 rows. Use a modifier for a different height.

```html_example
<div class="c-placeholder-rows">
    <div class="c-placeholder-rows__overlay-lines"></div>
</div>
```
<div class="c-sg-modifier-wrap js-modifier-wrap">
    <div>
        <button>c-placeholder-rows--1-rows</button>
        <button>c-placeholder-rows--2-rows</button>
        <button>c-placeholder-rows--3-rows</button>
        <button>c-placeholder-rows--5-rows</button>
        <button>c-placeholder-rows--6-rows</button>
        <button>c-placeholder-rows--8-rows</button>
        <button>c-placeholder-rows--10-rows</button>
        <button>c-placeholder-rows--12-rows</button>
        <button>c-placeholder-rows--14-rows</button>
        <button>c-placeholder-rows--18-rows</button>
        <button>c-placeholder-rows--22-rows</button>
    </div>
</div>

<b>Placeholder table</b> has a default height of 5 (2 header + 3 content rows). Use a modifier for a different height.

```html_example
<div class="c-placeholder-table">
    <div class="c-placeholder-table__overlay-head"></div>
    <div class="c-placeholder-table__overlay-lines"></div>
</div>
```
<div class="c-sg-modifier-wrap js-modifier-wrap">
    <div>
        <button>c-placeholder-table--4-rows</button>
        <button>c-placeholder-table--6-rows</button>
        <button>c-placeholder-table--8-rows</button>
        <button>c-placeholder-table--10-rows</button>
        <button>c-placeholder-table--12-rows</button>
        <button>c-placeholder-table--14-rows</button>
        <button>c-placeholder-table---18-rows</button>
        <button>c-placeholder-table---22-rows</button>
    </div>
</div>

<b>Placeholder accordion</b> has a default height of 4 accordions. Use a modifier for a different amount.

```html_example
<div class="c-placeholder-accordion">
    <div class="c-placeholder-accordion__lines-1"></div>
    <div class="c-placeholder-accordion__lines-2"></div>
</div>
```
<div class="c-sg-modifier-wrap js-modifier-wrap">
    <div>
        <button>c-placeholder-accordion--1</button>
        <button>c-placeholder-accordion--2</button>
        <button>c-placeholder-accordion--3</button>
        <button>c-placeholder-accordion--5</button>
        <button>c-placeholder-accordion--6</button>
        <button>c-placeholder-accordion--7</button>
        <button>c-placeholder-accordion--8</button>
        <button>c-placeholder-accordion--9</button>
        <button>c-placeholder-accordion--10</button>
    </div>
    <div>
        <button>c-placeholder-accordion--margin-bottom-xxs</button>
    </div>
    <div>
        <button>c-placeholder-accordion--grey-xxxlight-margin-bg-xs</button>
    </div>
    <div>
        <button>c-placeholder-accordion--white-margin-bg-xxl</button>
    </div>
</div>

<b>Placeholder tile</b> has a default height of 5 rows high. Use a modifier for a different height.

```html_example
<div class="c-placeholder-tile">
    <div class="c-placeholder-tile__overlay-border"></div>
    <div class="c-placeholder-tile__overlay-lines"></div>
</div>
```
<div class="c-sg-modifier-wrap js-modifier-wrap">
    <div>
        <button>c-placeholder-tile--3-rows</button>
        <button>c-placeholder-tile--4-rows</button>
        <button>c-placeholder-tile--6-rows</button>
        <button>c-placeholder-tile--8-rows</button>
        <button>c-placeholder-tile--10-rows</button>
        <button>c-placeholder-tile--12-rows</button>
        <button>c-placeholder-tile--14-rows</button>
    </div>
</div>

To have multiple tiles with responsive layout change, wrap them in a <code>c-placeholder-tile-wrap</code>

```html_example
 <div class="c-placeholder-tile-wrap">
    <div class="c-placeholder-tile">
        <div class="c-placeholder-tile__overlay-border"></div>
        <div class="c-placeholder-tile__overlay-lines"></div>
    </div>
    <div class="c-placeholder-tile">
        <div class="c-placeholder-tile__overlay-border"></div>
        <div class="c-placeholder-tile__overlay-lines"></div>
    </div>
    <div class="c-placeholder-tile">
        <div class="c-placeholder-tile__overlay-border"></div>
        <div class="c-placeholder-tile__overlay-lines"></div>
    </div>
    <div class="c-placeholder-tile">
        <div class="c-placeholder-tile__overlay-border"></div>
        <div class="c-placeholder-tile__overlay-lines"></div>
    </div>
</div>

```

<b>Placeholder content filter</b> has a default height of 1 rows high.

```html_example
<div class="c-placeholder-content-filter">
    <div class="c-placeholder-content-filter__lines-1"></div>
    <div class="c-placeholder-content-filter__lines-2"></div>
    <div class="c-placeholder-content-filter__lines-3"></div>
    <div class="c-placeholder-content-filter__lines-4"></div>
</div>

```
<div class="c-sg-modifier-wrap js-modifier-wrap">
    <div>
        <button>c-placeholder-content-filter--color-grey-xlight-bg</button>
    </div>
</div>

To have multiple content filters with 25% width, wrap them in a <code>c-placeholder-content-filter-wrap</code>


```html_example
<div class="c-placeholder-content-filter-wrap">
    <div class="c-placeholder-content-filter">
        <div class="c-placeholder-content-filter__lines-1"></div>
        <div class="c-placeholder-content-filter__lines-2"></div>
        <div class="c-placeholder-content-filter__lines-3"></div>
        <div class="c-placeholder-content-filter__lines-4"></div>
    </div>
    <div class="c-placeholder-content-filter">
        <div class="c-placeholder-content-filter__lines-1"></div>
        <div class="c-placeholder-content-filter__lines-2"></div>
        <div class="c-placeholder-content-filter__lines-3"></div>
        <div class="c-placeholder-content-filter__lines-4"></div>
    </div>
    <div class="c-placeholder-content-filter">
        <div class="c-placeholder-content-filter__lines-1"></div>
        <div class="c-placeholder-content-filter__lines-2"></div>
        <div class="c-placeholder-content-filter__lines-3"></div>
        <div class="c-placeholder-content-filter__lines-4"></div>
    </div>
    <div class="c-placeholder-content-filter">
        <div class="c-placeholder-content-filter__lines-1"></div>
        <div class="c-placeholder-content-filter__lines-2"></div>
        <div class="c-placeholder-content-filter__lines-3"></div>
        <div class="c-placeholder-content-filter__lines-4"></div>
    </div>
</div>

```

<div class="c-sg-modifier-wrap js-modifier-wrap">
    <div>
        <button>c-placeholder-content-filter-wrap--filters-margin-left-10</button>
    </div>
</div>

*/
.c-product-card {
  margin-bottom: 12px;
  background-color: #ffffff;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.21);
  text-align: center;
  transition: all .3s ease-in-out;
  outline: none; }
  .c-product-card__flag {
    margin-bottom: 16px;
    font-size: 18px;
    line-height: 26px;
    font-family: "futura-pt", sans-serif;
    font-weight: 700;
    color: #ffffff;
    background-color: #f2663b;
    text-align: center;
    margin: 0;
    padding: 8px;
    transition: color 0.3s ease-in-out; }
    @media (min-width: 980px) {
      .c-product-card__flag {
        font-size: 24px;
        line-height: 36px; } }
  .c-product-card__outer-wrap {
    padding: 8px; }
  .c-product-card__header {
    position: relative;
    padding: 8px;
    cursor: pointer; }
    .c-product-card__header:after {
      position: absolute;
      top: 50%;
      right: 8px;
      content: "";
      font-family: 'ffreesicons', sans-serif;
      font-weight: 500;
      font-style: normal;
      font-size: 32px;
      color: #f2663b;
      transition: all 0.3s ease-in-out;
      transform: translateY(-50%); }
  .c-product-card__info-wrapper {
    height: 0;
    overflow: hidden;
    transition: height 0.3s ease-in-out;
    margin-bottom: 8px; }
  .c-product-card__info {
    padding: 8px 12px; }
  .c-product-card__info-entry {
    display: flex;
    align-items: center;
    min-height: 28px; }
  .c-product-card__entry-title {
    display: flex;
    align-items: center;
    font-size: 12px;
    font-family: "futura-pt", sans-serif;
    font-weight: 700;
    margin: 0;
    padding-right: 16px;
    text-align: left; }
    .c-product-card__entry-title:before {
      color: #f2663b;
      font-size: 18px;
      margin-right: 8px; }
  .c-product-card__entry-value {
    font-size: 12px;
    line-height: 16px;
    margin: 0;
    margin-left: auto;
    text-align: right; }
  .c-product-card__entry-value-sub-text {
    font-size: 12px;
    line-height: 18px;
    text-align: right;
    margin: 0;
    color: #757575; }
    @media (min-width: 980px) {
      .c-product-card__entry-value-sub-text {
        font-size: 14px;
        line-height: 18px; } }
  .c-product-card__dormancy-info {
    font-size: 12px;
    line-height: 18px;
    color: #757575;
    text-align: center;
    margin: 0;
    margin-top: 8px; }
    @media (min-width: 980px) {
      .c-product-card__dormancy-info {
        font-size: 14px;
        line-height: 18px; } }
  .c-product-card.is-open .c-product-card__header:after {
    transform: translateY(-50%) rotate(180deg); }
  .c-product-card.is-open .c-product-card__info-wrapper {
    height: auto; }
  @media (min-width: 540px) {
    .c-product-card__flag {
      padding: 12px; }
    .c-product-card__header {
      padding: 12px; }
      .c-product-card__header:after {
        font-size: 32px; }
    .c-product-card__info {
      padding-left: 24px;
      padding-right: 24px; }
    .c-product-card__info-entry {
      min-height: 36px; }
    .c-product-card__entry-title {
      font-size: 16px;
      line-height: 24px; }
      .c-product-card__entry-title:before {
        font-size: 22px; }
    .c-product-card__entry-value {
      font-size: 16px;
      line-height: 24px; } }
  @media (min-width: 980px) {
    .c-product-card {
      margin: 0;
      box-shadow: none;
      transition: all .3s ease-in-out; }
      .c-product-card:hover, .c-product-card:focus {
        z-index: 10;
        transform: scale(1.05, 1.05);
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.21);
        border-color: transparent; }
      .c-product-card__flag {
        margin: -1px;
        padding: 0;
        width: 1px;
        height: 1px;
        overflow: hidden;
        clip: rect(0 0 0 0);
        clip: rect(0, 0, 0, 0);
        position: absolute; }
      .c-product-card__outer-wrap {
        padding: 12px; }
      .c-product-card__header {
        padding: 12px 0;
        margin-bottom: 12px;
        cursor: auto; }
        .c-product-card__header:after {
          content: ''; }
      .c-product-card__info-wrapper {
        height: auto;
        margin-bottom: 12px; }
      .c-product-card__info {
        padding: 0; }
      .c-product-card__info-entry {
        min-height: 70px;
        justify-content: center; }
      .c-product-card__entry-title {
        display: none; }
      .c-product-card__entry-value {
        text-align: center;
        margin-left: 0;
        font-size: 18px; }
      .c-product-card__entry-value-sub-text {
        text-align: center; }
      .c-product-card__dormancy-info {
        display: none; } }

/*doc
---
title: product card
name: product-card
category: Components
---
This component is used in the <code>.c-product-selector</code> component.

```html_example
<div class="c-product-card">
    <p class="c-product-card__flag">
        Most Popular
    </p>
    <div class="c-product-card__outer-wrap">
        <div class="c-product-card__header">
            <h3 class="c-product-price">
                <span class="c-product-price__currency">&pound;</span>
                <span class="c-product-price__value">0</span>
                <span class="c-product-price__frequency">per<br>month*</span>
            </h3>
        </div>
        <div class="c-product-card__info-wrapper">
            <div class="c-product-card__info">
                <div class="c-product-card__info-entry">
                    <p class="c-product-card__entry-title u-before-icon-upload">Payments out/direct debits</p>
                    <p class="c-product-card__entry-value">&pound;1</p>
                </div>
                <div class="c-product-card__info-entry">
                    <p class="c-product-card__entry-title u-before-icon-atm">UK cash withdrawals</p>
                    <p class="c-product-card__entry-value">&pound;1</p>
                </div>
                <div class="c-product-card__info-entry">
                    <p class="c-product-card__entry-title u-before-icon-user-add">Free Extra Accounts</p>
                    <p class="c-product-card__entry-value">0</p>
                </div>
                <div class="c-product-card__info-entry">
                    <p class="c-product-card__entry-title u-before-icon-user-add">Additional Extra Accounts</p>
                    <div class="c-product-card__entry-value">
                        &pound;1
                        <p class="c-product-card__entry-value-sub-text">per month</p>
                    </div>
                </div>
                <div class="c-product-card__info-entry">
                    <p class="c-product-card__entry-title u-before-icon-globe">Cash withdrawals abroad</p>
                    <p class="c-product-card__entry-value">3% + &pound;1</p>
                </div>
                <p class="c-product-card__dormancy-info">*&pound;1.00 monthly dormancy fee after 60 days of inactivity</p>
            </div>
        </div>
        <button class="c-btn c-btn--2 c-btn--full-width c-btn--margin-0">Apply</button>
    </div>
</div>
```
<div class="c-sg-modifier-wrap js-modifier-wrap">
    <div>
        <button>is-open</button>
    </div>
</div>
*/
.c-product-price {
  display: inline-flex;
  margin: 0; }
  .c-product-price__currency {
    margin: 0;
    align-self: flex-start;
    font-size: 18px;
    line-height: 14px;
    font-family: "futura-pt", sans-serif;
    font-weight: 500; }
  .c-product-price__value {
    margin: 0;
    font-size: 44px;
    line-height: 33px;
    letter-spacing: -5px; }
  .c-product-price__frequency {
    margin: 0 0 0 6px;
    align-self: flex-end;
    text-align: left;
    font-size: 15px;
    line-height: 14px;
    font-family: "futura-pt", sans-serif;
    font-weight: 500; }
  @media (min-width: 980px) {
    .c-product-price__currency {
      font-size: 22px;
      line-height: 18px; }
    .c-product-price__value {
      font-size: 56px;
      line-height: 44px;
      letter-spacing: -8px; }
    .c-product-price__frequency {
      font-size: 20px;
      line-height: 19px;
      margin-left: 10px; } }

/*doc
---
title: product price
name: product-price
category: Components
---

```html_example
<h3 class="c-product-price">
    <span class="c-product-price__currency">&pound;</span>
    <span class="c-product-price__value">0</span>
    <span class="c-product-price__frequency">per<br>month*</span>
</h3>
```
*/
.c-product-selector {
  margin: 28px 0; }
  .c-product-selector__info-desktop {
    display: none;
    background-color: #f1f1f1; }
  .c-product-selector__card-flag {
    display: none;
    margin-bottom: 16px;
    font-size: 18px;
    line-height: 26px;
    font-family: "futura-pt", sans-serif;
    font-weight: 700;
    margin: 0;
    width: calc((100% / 3) - (2 * 2px / 3));
    padding: 12px;
    color: #ffffff;
    background-color: #f2663b;
    text-align: center; }
    @media (min-width: 980px) {
      .c-product-selector__card-flag {
        font-size: 24px;
        line-height: 36px; } }
  .c-product-selector__cards-wrapper-inner .c-product-card {
    border-right-color: #ebebeb; }
  .c-product-selector__dormancy-info {
    display: none; }
  .c-product-selector__info-desktop-right-bar {
    display: none;
    background-color: #f1f1f1; }
  @media (min-width: 980px) {
    .c-product-selector__card-flag-wrap-outer {
      display: flex; }
    .c-product-selector__card-flag-wrap-inner {
      margin-left: 42%;
      flex: 0 0 auto;
      width: 52%; }
    .c-product-selector__card-flag {
      line-height: 28px; }
    .c-product-selector__main {
      display: flex;
      margin-bottom: 24px; }
    .c-product-selector__info-desktop {
      flex: 0 0 auto;
      width: 42%;
      display: block;
      padding: 0 24px;
      margin: 0;
      margin-top: calc(44px + 4 * 12px);
      margin-bottom: calc(2 * 12px + 14px + 12px + 44px); }
    .c-product-selector__entry-title {
      display: flex;
      align-items: center;
      font-size: 20px;
      min-height: 70px;
      font-family: "futura-pt", sans-serif;
      font-weight: 700;
      margin: 0;
      padding: 4px 0;
      min-height: 70px; }
      .c-product-selector__entry-title:before {
        color: #f2663b;
        font-size: 24px;
        margin-right: 16px; }
    .c-product-selector__cards-wrapper-outer {
      flex: 0 0 auto;
      width: 52%;
      z-index: 2;
      display: flex;
      flex-direction: column; }
    .c-product-selector__cards-wrapper-inner {
      flex-grow: 1;
      display: flex;
      background-color: #ffffff;
      box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.21); }
      .c-product-selector__cards-wrapper-inner .c-product-card {
        flex: 1 0 auto;
        width: calc((100% / 3) - (2 * 2px / 3) + 2px);
        border-right: solid 2px #ebebeb; }
        .c-product-selector__cards-wrapper-inner .c-product-card:last-child {
          width: calc((100% / 3) - (2 * 2px / 3));
          border-right-width: 0; }
        .c-product-selector__cards-wrapper-inner .c-product-card:hover, .c-product-selector__cards-wrapper-inner .c-product-card:focus {
          border-right-width: 0; }
    .c-product-selector__dormancy-info {
      display: block;
      margin: 0;
      padding-top: 12px;
      font-size: 14px;
      line-height: 14px;
      text-align: center; }
    .c-product-selector__info-desktop-right-bar {
      flex: 0 0 auto;
      width: 6%;
      display: block;
      margin: 0;
      margin-top: calc(44px + 4 * 12px);
      margin-bottom: calc(2 * 12px + 14px + 12px + 44px); }
    .c-product-selector--first-flagged .c-product-selector__card-flag {
      display: inline-block;
      margin-left: 0; }
    .c-product-selector--second-flagged .c-product-selector__card-flag {
      display: inline-block;
      margin-left: calc((100% / 3) - (2 * 2px / 3) + 2px); }
    .c-product-selector--last-flagged .c-product-selector__card-flag {
      display: inline-block;
      margin-left: calc(2 * ((100% / 3) - (2 * 2px / 3) + 2px)); } }

/*doc
---
title: product selector
name: product-selector
category: Organisms
---
Requires click event to open and close the cards in mobile view. The state modifier does show the different states the cards will be in, however.
<br>
Note that this component also contains the <code>.c-product-card</code> and <code>.c-product-price</code> components.

```html_example
<div class="c-product-selector">
    <div class="c-product-selector__card-flag-wrap-outer">
        <div class="c-product-selector__card-flag-wrap-inner">
            <div class="c-product-selector__card-flag">Most<br>Popular</div>
        </div>
    </div>
    <div class="c-product-selector__main">
        <div class="c-product-selector__info-desktop">
            <p class="c-product-selector__entry-title u-before-icon-upload">Payments out/direct debits</p>
            <p class="c-product-selector__entry-title u-before-icon-atm">UK cash withdrawals</p>
            <p class="c-product-selector__entry-title u-before-icon-user-add">Free Extra Accounts</p>
            <p class="c-product-selector__entry-title u-before-icon-user-add">Additional Extra Accounts</p>
            <p class="c-product-selector__entry-title u-before-icon-globe">Cash withdrawals abroad</p>
        </div>
        <div class="c-product-selector__cards-wrapper-outer">
            <div class="c-product-selector__cards-wrapper-inner">
                <div class="c-product-card">
                    <p class="c-product-card__flag">
                        Most<br>Popular
                    </p>
                    <div class="c-product-card__outer-wrap">
                        <div class="c-product-card__header">
                            <h3 class="c-product-price">
                                <span class="c-product-price__currency">&pound;</span>
                                <span class="c-product-price__value">10</span>
                                <span class="c-product-price__frequency">per<br>month</span>
                            </h3>
                        </div>
                        <div class="c-product-card__info-wrapper">
                            <div class="c-product-card__info">
                                <div class="c-product-card__info-entry">
                                    <p class="c-product-card__entry-title u-before-icon-upload">Payments out/direct debits</p>
                                    <p class="c-product-card__entry-value">FREE</p>
                                </div>
                                <div class="c-product-card__info-entry">
                                    <p class="c-product-card__entry-title u-before-icon-atm">UK cash withdrawals</p>
                                    <p class="c-product-card__entry-value">FREE</p>
                                </div>
                                <div class="c-product-card__info-entry">
                                    <p class="c-product-card__entry-title u-before-icon-user-add">Free Extra Accounts</p>
                                    <p class="c-product-card__entry-value">5</p>
                                </div>
                                <div class="c-product-card__info-entry">
                                    <p class="c-product-card__entry-title u-before-icon-user-add">Additional Extra Accounts</p>
                                    <div class="c-product-card__entry-value">
                                        50p
                                        <p class="c-product-card__entry-value-sub-text">per month</p>
                                    </div>
                                </div>
                                <div class="c-product-card__info-entry">
                                    <p class="c-product-card__entry-title u-before-icon-globe">Cash withdrawals abroad</p>
                                    <p class="c-product-card__entry-value">3%</p>
                                </div>
                            </div>
                        </div>
                        <button class="c-btn c-btn--2 c-btn--full-width c-btn--margin-0">Apply</button>
                    </div>
                </div>
                <div class="c-product-card">
                    <div class="c-product-card__outer-wrap">
                        <div class="c-product-card__header">
                            <h3 class="c-product-price">
                                <span class="c-product-price__currency">&pound;</span>
                                <span class="c-product-price__value">5</span>
                                <span class="c-product-price__frequency">per<br>month</span>
                            </h3>
                        </div>
                        <div class="c-product-card__info-wrapper">
                            <div class="c-product-card__info">
                                <div class="c-product-card__info-entry">
                                    <p class="c-product-card__entry-title u-before-icon-upload">Payments out/direct debits</p>
                                    <p class="c-product-card__entry-value">50p</p>
                                </div>
                                <div class="c-product-card__info-entry">
                                    <p class="c-product-card__entry-title u-before-icon-atm">UK cash withdrawals</p>
                                    <p class="c-product-card__entry-value">50p</p>
                                </div>
                                <div class="c-product-card__info-entry">
                                    <p class="c-product-card__entry-title u-before-icon-user-add">Free Extra Accounts</p>
                                    <p class="c-product-card__entry-value">2</p>
                                </div>
                                <div class="c-product-card__info-entry">
                                    <p class="c-product-card__entry-title u-before-icon-user-add">Additional Extra Accounts</p>
                                    <div class="c-product-card__entry-value">
                                        50p
                                        <p class="c-product-card__entry-value-sub-text">per month</p>
                                    </div>
                                </div>
                                <div class="c-product-card__info-entry">
                                    <p class="c-product-card__entry-title u-before-icon-globe">Cash withdrawals abroad</p>
                                    <p class="c-product-card__entry-value">3% + 50p</p>
                                </div>
                            </div>
                        </div>
                        <button class="c-btn c-btn--2 c-btn--full-width c-btn--margin-0">Apply</button>
                    </div>
                </div>
                <div class="c-product-card">
                    <div class="c-product-card__outer-wrap">
                        <div class="c-product-card__header">
                            <h3 class="c-product-price">
                                <span class="c-product-price__currency">&pound;</span>
                                <span class="c-product-price__value">0</span>
                                <span class="c-product-price__frequency">per<br>month*</span>
                            </h3>
                        </div>
                        <div class="c-product-card__info-wrapper">
                            <div class="c-product-card__info">
                                <div class="c-product-card__info-entry">
                                    <p class="c-product-card__entry-title u-before-icon-upload">Payments out/direct debits</p>
                                    <p class="c-product-card__entry-value">&pound;1</p>
                                </div>
                                <div class="c-product-card__info-entry">
                                    <p class="c-product-card__entry-title u-before-icon-atm">UK cash withdrawals</p>
                                    <p class="c-product-card__entry-value">&pound;1</p>
                                </div>
                                <div class="c-product-card__info-entry">
                                    <p class="c-product-card__entry-title u-before-icon-user-add">Free Extra Accounts</p>
                                    <p class="c-product-card__entry-value">0</p>
                                </div>
                                <div class="c-product-card__info-entry">
                                    <p class="c-product-card__entry-title u-before-icon-user-add">Additional Extra Accounts</p>
                                    <div class="c-product-card__entry-value">
                                        &pound;1
                                        <p class="c-product-card__entry-value-sub-text">per month</p>
                                    </div>
                                </div>
                                <div class="c-product-card__info-entry">
                                    <p class="c-product-card__entry-title u-before-icon-globe">Cash withdrawals abroad</p>
                                    <p class="c-product-card__entry-value">3% + &pound;1</p>
                                </div>
                                <p class="c-product-card__dormancy-info">*&pound;1.00 monthly dormancy fee after 60 days of inactivity</p>
                            </div>
                        </div>
                        <button class="c-btn c-btn--2 c-btn--full-width c-btn--margin-0">Apply</button>
                    </div>
                </div>
            </div>
            <p class="c-product-selector__dormancy-info">*&pound;1.00 monthly dormancy fee after 60 days of inactivity</p>
        </div>
        <div class="c-product-selector__info-desktop-right-bar"></div>
    </div>
</div>
```
<div class="c-sg-modifier-wrap js-modifier-wrap">
    <div>
        <button>c-product-selector--first-flagged</button>
        <button>c-product-selector--second-flagged</button>
        <button>c-product-selector--last-flagged</button>
    </div>
</div>
*/
.c-progress-circle {
  position: relative;
  height: 28px;
  width: 28px;
  font-size: 12px;
  line-height: 18px;
  transition-property: transform;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out; }
  @media (min-width: 980px) {
    .c-progress-circle {
      font-size: 14px;
      line-height: 18px; } }
  .c-progress-circle__meter {
    transform: rotate(-90deg); }
  .c-progress-circle__circle {
    fill: #f1f1f1; }
  .c-progress-circle__bar {
    stroke: #757575;
    stroke-width: 1.5;
    stroke-linecap: round;
    fill: none;
    transition: all 250ms; }
  .c-progress-circle__text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0); }
  .c-progress-circle--warning .c-progress-circle__circle {
    fill: #fef8f7; }
  .c-progress-circle--warning .c-progress-circle__bar {
    stroke: #f2663b; }
  .c-progress-circle--invalid .c-progress-circle__circle {
    fill: #FABCCC; }
  .c-progress-circle--invalid .c-progress-circle__bar {
    stroke: #d31a35; }
  .c-progress-circle--invalid-b .c-progress-circle__circle {
    fill: none; }
  .c-progress-circle--invalid-b .c-progress-circle__bar {
    stroke: none; }

.c-promo {
  display: block;
  text-decoration: none;
  color: inherit;
  margin-bottom: 24px; }
  .c-promo__title {
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    margin-bottom: 8px; }
    @media (min-width: 980px) {
      .c-promo__title {
        font-size: 20px;
        line-height: 30px; } }
  .c-promo__footer {
    font-size: 12px;
    line-height: 18px;
    font-weight: 300;
    display: flex;
    align-items: center;
    justify-content: flex-start; }
    @media (min-width: 980px) {
      .c-promo__footer {
        font-size: 14px;
        line-height: 18px; } }
    .c-promo__footer::before {
      content: '';
      height: 2px;
      width: 90px;
      margin-right: 8px;
      background: #4d4d4d;
      transition: all 250ms ease-in-out; }
  .c-promo:hover, .c-promo:focus, .c-promo:active {
    color: inherit; }
    .c-promo:hover .c-promo__footer::before, .c-promo:focus .c-promo__footer::before, .c-promo:active .c-promo__footer::before {
      background: #f2663b;
      width: 110px; }
  @media (min-width: 980px) {
    .c-promo {
      margin-bottom: 48px;
      padding-right: 24px; } }

/*doc
---
title: Promo
name: promo
category: Components
---

```html_example
<a href="/help/question/977" class="c-promo">
    <h2 class="c-promo__title">Why is my available amount different to my balance?</h2>
    <p class="c-promo__footer">Read answer</p>
</a>
```
*/
.c-range-slider {
  box-sizing: border-box;
  position: relative;
  width: 100%;
  height: 60px; }
  .c-range-slider:after {
    content: '';
    display: block;
    width: 100%;
    clear: both; }
  .c-range-slider__input {
    -webkit-appearance: none;
    margin: 0;
    padding: 0;
    display: block;
    width: 100%;
    height: 60px; }
    .c-range-slider__input::-webkit-slider-runnable-track {
      width: 100%;
      height: 12px;
      cursor: pointer;
      transition: all .2s ease;
      background: #f1f1f1;
      border: 0 solid transparent;
      border-radius: 0; }
    .c-range-slider__input::-webkit-slider-thumb {
      position: relative;
      border: 0 solid rgba(255, 255, 255, 0.5);
      height: 20px;
      width: 20px;
      border-radius: 100%;
      background: #f2663b;
      cursor: pointer;
      z-index: 1;
      transition: background-color 250ms;
      -webkit-appearance: none;
      margin-top: -4px; }
    .c-range-slider__input::-moz-range-track {
      width: 100%;
      height: 12px;
      cursor: pointer;
      transition: all .2s ease;
      background: #f1f1f1;
      border: 0 solid transparent;
      border-radius: 0; }
    .c-range-slider__input::-moz-range-thumb {
      position: relative;
      border: 0 solid rgba(255, 255, 255, 0.5);
      height: 20px;
      width: 20px;
      border-radius: 100%;
      background: #f2663b;
      cursor: pointer;
      z-index: 1;
      transition: background-color 250ms; }
    .c-range-slider__input::-moz-focus-outer {
      border: 0; }
    .c-range-slider__input::-ms-track {
      width: 100%;
      height: 12px;
      cursor: pointer;
      transition: all .2s ease;
      background: transparent;
      border-color: transparent;
      border-width: 20px 0;
      color: transparent; }
    .c-range-slider__input::-ms-fill-lower {
      background: #f1f1f1;
      border: 0 solid transparent;
      border-radius: 0; }
    .c-range-slider__input::-ms-fill-upper {
      background: #f1f1f1;
      border: 0 solid transparent;
      border-radius: 0; }
    .c-range-slider__input::-ms-thumb {
      position: relative;
      border: 0 solid rgba(255, 255, 255, 0.5);
      height: 20px;
      width: 20px;
      border-radius: 100%;
      background: #f2663b;
      cursor: pointer;
      z-index: 1;
      transition: background-color 250ms; }
    .c-range-slider__input::-ms-tooltip {
      display: none;
      appearance: none; }
    .c-range-slider__input:focus {
      outline: none; }
      .c-range-slider__input:focus::-webkit-slider-runnable-track, .c-range-slider__input:focus::-ms-fill-lower, .c-range-slider__input:focus::-ms-fill-upper {
        background: #f1f1f1; }
      .c-range-slider__input:focus::-ms-thumb, .c-range-slider__input:focus::-moz-range-thumb, .c-range-slider__input:focus::-webkit-slider-thumb {
        background: #ea4310; }
  .c-range-slider__output {
    position: absolute;
    top: -8px;
    font-size: 16px;
    line-height: 24px;
    font-family: "futura-pt", sans-serif;
    font-weight: 500;
    margin-bottom: 8px;
    font-family: "futura-pt", sans-serif;
    font-weight: 700;
    color: #f2663b;
    width: auto; }
    @media (min-width: 980px) {
      .c-range-slider__output {
        font-size: 20px;
        line-height: 28px; } }
  .c-range-slider__min, .c-range-slider__max {
    font-size: 16px;
    line-height: 24px;
    font-family: "futura-pt", sans-serif;
    font-weight: 500;
    margin-bottom: 8px;
    font-family: "futura-pt", sans-serif;
    font-weight: 700;
    color: #000000;
    position: relative;
    top: -16px; }
    @media (min-width: 980px) {
      .c-range-slider__min, .c-range-slider__max {
        font-size: 20px;
        line-height: 28px; } }
  .c-range-slider__max {
    float: right; }
  @media (min-width: 800px) {
    .c-range-slider__min, .c-range-slider__max {
      top: -24px; } }

/*doc
---
title: Range Slider
name: range-slider
category: Inputs
---


```html_example
    <base-range-slider :steps="10" :min="100" :max="400" />
```
*/
.c-reveal-vertical {
  overflow: hidden;
  transition: height .5s ease-in-out;
  height: 0; }
  .c-reveal-vertical.is-revealed {
    height: auto; }
  .c-reveal-vertical__content {
    overflow: auto; }

.c-scroll-lock-animation {
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-wrap: wrap; }
  .c-scroll-lock-animation__wrapper {
    width: 100%;
    max-height: calc(100vh - 210px); }
    .c-scroll-lock-animation__wrapper:after {
      content: "";
      display: table;
      clear: both; }
  .c-scroll-lock-animation__content {
    position: relative;
    width: 100%; }
    .c-scroll-lock-animation__content:nth-child(even) {
      margin-top: 60px; }
  .c-scroll-lock-animation__body {
    margin-top: 3vh;
    margin-bottom: 3vh; }
  .c-scroll-lock-animation__item {
    position: absolute;
    width: 100%;
    padding-left: 36px;
    text-align: left;
    transition: transform 200ms ease-in-out;
    transform: translateX(-100vw);
    transform: translateX(calc(-100% - 25px));
    background: none; }
    .c-scroll-lock-animation__item.is-active {
      transform: translateX(0); }
    .c-scroll-lock-animation__item::before {
      font-size: 16px;
      line-height: 24px;
      font-family: "futura-pt", sans-serif;
      font-weight: 500;
      margin-bottom: 8px;
      font-family: 'ffreesicons', sans-serif;
      font-weight: 500;
      font-style: normal;
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      z-index: 5;
      transition: all 200ms; }
      @media (min-width: 980px) {
        .c-scroll-lock-animation__item::before {
          font-size: 20px;
          line-height: 28px; } }
    .c-scroll-lock-animation__item.is-active::before {
      color: #f2663b;
      transform: scale(1.25); }
  .c-scroll-lock-animation__title {
    font-size: 16px;
    line-height: 24px;
    font-family: "futura-pt", sans-serif;
    font-weight: 500;
    margin-bottom: 8px; }
    @media (min-width: 980px) {
      .c-scroll-lock-animation__title {
        font-size: 20px;
        line-height: 28px; } }
  .c-scroll-lock-animation__image-container {
    display: block;
    margin: 0 auto; }
  .c-scroll-lock-animation__image {
    display: none;
    margin: 0 auto;
    max-width: 165px; }
    .c-scroll-lock-animation__image.is-active {
      display: block; }
  .c-scroll-lock-animation__phone {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0) scale(0.975);
    max-width: 200px; }
  @media (min-width: 375px) {
    .c-scroll-lock-animation__content:nth-child(even) {
      margin-top: 80px; }
    .c-scroll-lock-animation__phone, .c-scroll-lock-animation__image {
      max-width: none; } }
  @media (min-width: 540px) {
    .c-scroll-lock-animation__content {
      float: left;
      width: 50%; }
      .c-scroll-lock-animation__content:nth-child(even) {
        margin-top: 0; }
    .c-scroll-lock-animation__body {
      margin-top: 60px;
      margin-bottom: 60px; }
    .c-scroll-lock-animation__item {
      transition: transform 200ms ease-in-out;
      transform: translateX(-100vw);
      transform: translateX(calc(-100% - 13vw)); }
      .c-scroll-lock-animation__item.is-active {
        transform: translateX(0); } }
  @media (min-height: 640px) and (min-width: 980px) {
    .c-scroll-lock-animation__wrapper {
      display: flex; }
    .c-scroll-lock-animation__content {
      float: left;
      width: 50%;
      display: flex;
      flex-direction: column;
      justify-content: center; }
      .c-scroll-lock-animation__content:nth-child(even) {
        margin-top: 60px; }
    .c-scroll-lock-animation__body {
      margin: 0; }
    .c-scroll-lock-animation__item {
      position: relative;
      display: block;
      padding: 0;
      transform: none; }
      .c-scroll-lock-animation__item::before {
        top: 5vh;
        left: 15%; }
      .c-scroll-lock-animation__item::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        display: block;
        height: 100%;
        width: calc(100vw / 2);
        background-color: rgba(246, 246, 246, 0);
        transition: background-color 200ms;
        z-index: 1; }
      .c-scroll-lock-animation__item.is-active::after, .c-scroll-lock-animation__item:focus::after {
        background-color: #f6f6f6; }
    .c-scroll-lock-animation__item-inner {
      position: relative;
      padding: 5vh 48px;
      margin-left: 15%;
      z-index: 5; } }

/*doc
---
title: Scroll lock animation
name: scroll-lock-animation
category: Organisms
---

```html_example
<section class="l-section c-scroll-lock-animation js-scroll-lock-animation">
    <div class="l-content-width c-scroll-lock-animation__wrapper">
        <div class="c-scroll-lock-animation__content">
            <div class="c-scroll-lock-animation__header">
                <h2 class="u-section-title">A hassle-free<br/> current account alternative</h2>
            </div>
            <div class="c-scroll-lock-animation__body js-scroll-lock-animation-item-wrapper">
                <button class="c-scroll-lock-animation__item js-scroll-lock-animation-item">
                    <div class="c-scroll-lock-animation__item-inner">
                        <h3 class="c-scroll-lock-animation__title">Payments</h3>
                        <p class="u-margin-bottom-0">Get your salary or benefits paid-in directly to your <span class="u-u-in-copy">U</span> Account. Receive BACS and Faster Payments, and easily make payments out.</p>
                    </div>
                </button>
                <button class="c-scroll-lock-animation__item js-scroll-lock-animation-item">
                    <div class="c-scroll-lock-animation__item-inner">
                        <h3 class="c-scroll-lock-animation__title">Direct debits</h3>
                        <p class="u-margin-bottom-0">Set up direct debits for hassle-free regular payments towards your rent, mortgage, or bills.</p>
                    </div>
                </button>
                <button class="c-scroll-lock-animation__item js-scroll-lock-animation-item">
                    <div class="c-scroll-lock-animation__item-inner">
                        <h3 class="c-scroll-lock-animation__title">Deposit cash</h3>
                        <p class="u-margin-bottom-0">Put cash into your <span class="u-u-in-copy">U</span> Account using any of the 29,000+ PayPoint stores in the UK.</p>
                    </div>
                </button>
            </div>
        </div>
        <div class="c-scroll-lock-animation__content">
            <img src="img/landing-pages/phone.png" class="c-scroll-lock-animation__phone">
            <div class="c-scroll-lock-animation__image-container">
                <img src="img/landing-pages/Payments-screen.png" class="c-scroll-lock-animation__image js-scroll-lock-animation-image" alt="Payments screen" title="Payments">
                <img src="img/landing-pages/DD-screen.png" class="c-scroll-lock-animation__image js-scroll-lock-animation-image" alt="Direct debits screen" title="Direct debits">
                <img src="img/landing-pages/paypoint-screen.png" class="c-scroll-lock-animation__image js-scroll-lock-animation-image" alt="Paypoint screen" title="Deposit cash">
            </div>
        </div>
    </div>
</section>
```
*/
.c-sidebar-content-header {
  position: relative;
  margin-bottom: 36px; }
  .c-sidebar-content-header__close-content-btn, .c-sidebar-content-header__content-right {
    position: absolute;
    top: 50%;
    transform: translateY(-50%); }
  .c-sidebar-content-header__close-content-btn {
    font-family: "futura-pt", sans-serif;
    font-weight: 700;
    display: inline-block;
    padding: 8px 20px;
    text-decoration: none;
    text-align: center;
    margin-bottom: 16px;
    transition-property: background-color, color, transform, box-shadow, border-color;
    transition-duration: .2s, .2s, .2s, .2s;
    transition-timing-function: ease-in-out, ease-in-out, ease-in-out, ease-in-out;
    font-size: 16px;
    line-height: 24px;
    left: 0;
    background-color: transparent;
    margin: 0;
    min-height: 100%; }
    @media (min-width: 980px) {
      .c-sidebar-content-header__close-content-btn {
        font-size: 20px;
        line-height: 30px; } }
    .c-sidebar-content-header__close-content-btn::before {
      content: "";
      font-family: 'ffreesicons', sans-serif;
      font-weight: 500;
      font-style: normal;
      display: inline-block; }
  .c-sidebar-content-header__title {
    text-align: center;
    margin: 0 48px; }
  .c-sidebar-content-header__content-right {
    right: 0; }
  @media (min-width: 980px) {
    .c-sidebar-content-header__close-content-btn {
      display: none; }
    .c-sidebar-content-header--display-until-m {
      display: none; } }

/*doc
---
title: sidebar content header
name: sidebar-content-header
category: Organisms
---

```html_example
<div class="c-sidebar-content-header">
    <button class="c-sidebar-content-header__close-content-btn js-hide-sidebar-content"></button>
    <div class="c-sidebar-content-header__title js-main-account-header">
        <h1 class="u-tabs-section-title u-margin-bottom-0 u-line-height-1">Main Account</h1>
        <h2 class="u-title u-margin-bottom-0 u-line-height-1">£179.67</h2>
    </div>
    <div class="c-sidebar-content-header__content-right">
        <a class="c-text-link">Edit <span class="c-text-link__icon u-after-icon-pencil"></span></a>
    </div>
</div>

```
<div class="c-sg-modifier-wrap js-modifier-wrap">
    <div>c-sidebar-content-header--display-until-m</div>
</div>

*/
.c-site-footer {
  flex-shrink: 0;
  background-color: #f2663b;
  color: #ffffff; }
  .c-site-footer__content {
    text-align: center;
    display: inline-block;
    padding: 24px;
    width: 100%; }
  .c-site-footer__card {
    margin-top: -96px;
    margin-bottom: 28px;
    padding: 24px;
    background: #ffffff;
    color: #000000;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.21); }
  .c-site-footer__card-title {
    margin-bottom: 24px;
    font-size: 24px;
    line-height: 31px; }
    @media (min-width: 980px) {
      .c-site-footer__card-title {
        margin-bottom: 28px;
        font-size: 36px;
        line-height: 46px; } }
  .c-site-footer__card-action .c-btn {
    width: 100%;
    padding: 24px; }
  .c-site-footer__links-list {
    text-align: center;
    list-style: none;
    list-style-image: none;
    margin-bottom: 12px;
    padding: 0;
    display: none;
    justify-content: center; }
  .c-site-footer__links-list-item {
    margin: 0 36px; }
  .c-site-footer__link {
    text-decoration: none;
    color: inherit;
    font-size: 20px;
    line-height: 26px;
    font-family: "futura-pt", sans-serif;
    font-weight: 500;
    font-family: "futura-pt", sans-serif;
    font-weight: 500;
    margin: 0;
    color: #ffffff;
    position: relative;
    overflow: hidden; }
    .c-site-footer__link:after {
      transition-property: left, right;
      transition-duration: .2s;
      transition-timing-function: ease-in-out;
      content: '';
      position: absolute;
      left: 51%;
      right: 51%;
      bottom: -2px;
      background-color: #ffffff;
      height: 2px; }
    .c-site-footer__link:hover:after, .c-site-footer__link:focus:after, .c-site-footer__link:active:after {
      left: 0;
      right: 0; }
    .c-site-footer__link:hover, .c-site-footer__link:focus {
      color: #ffffff; }
    .c-site-footer__link.is-current:after {
      left: 0;
      right: 0; }
  .c-site-footer__social-links {
    display: none;
    margin-bottom: 12px; }
  .c-site-footer__cookie-preferences {
    margin-bottom: 12px; }
    .c-site-footer__cookie-preferences a {
      color: #ffffff;
      position: relative;
      overflow: hidden; }
      .c-site-footer__cookie-preferences a:after {
        transition-property: left, right;
        transition-duration: .2s;
        transition-timing-function: ease-in-out;
        content: '';
        position: absolute;
        left: 51%;
        right: 51%;
        bottom: -2px;
        background-color: #ffffff;
        height: 2px; }
      .c-site-footer__cookie-preferences a:hover:after, .c-site-footer__cookie-preferences a:focus:after, .c-site-footer__cookie-preferences a:active:after {
        left: 0;
        right: 0; }
      .c-site-footer__cookie-preferences a:hover, .c-site-footer__cookie-preferences a:focus {
        color: #ffffff; }
  .c-site-footer__legals {
    max-width: 1440px;
    margin-right: auto;
    margin-left: auto;
    padding-right: 24px;
    padding-left: 24px;
    min-width: 0;
    margin: 0 auto;
    text-align: center;
    font-size: 12px;
    line-height: 1.3; }
  .c-site-footer--reduced .c-site-footer__links-list,
  .c-site-footer--reduced .c-site-footer__social-links {
    display: none; }
  .c-site-footer--card {
    margin-top: 120px; }
  @media (min-width: 980px) {
    .c-site-footer__links-list {
      display: inline-flex; }
    .c-site-footer__social-links {
      display: block; }
    .c-site-footer__card {
      display: flex;
      justify-content: space-around;
      align-items: center;
      max-width: 800px;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 48px;
      margin-top: -120px;
      padding-top: 36px;
      padding-bottom: 36px; }
    .c-site-footer__card-title {
      margin-bottom: 0; }
    .c-site-footer__card-action .c-btn {
      padding: 24px 48px; }
    .c-site-footer--card {
      margin-top: 240px; } }

/*doc
---
title: site footer
name: site-footer
category: Global
---

```html_example
<footer class="c-site-footer">

    <div class="c-site-footer__content">

        <ul class="c-site-footer__links-list">
            <li id="ttAboutUsFooterLink" class="c-site-footer__links-list-item">
                <a class="c-site-footer__link" href="/about">About</a>
            </li>
            <li id="ttCareersFooterLink" class="c-site-footer__links-list-item">
                <a class="c-site-footer__link" href="/careers">Careers</a>
            </li>
            <li id="ttTermsFooterLink" class="c-site-footer__links-list-item">
                <a class="c-site-footer__link" href="/policies">Policies &amp; Terms</a>
            </li>
            <li id="ttMediaFooterLink" class="c-site-footer__links-list-item">
                <a class="c-site-footer__link" href="/media">Press</a>
            </li>
        </ul>

        <div class="c-site-footer__social-links">

            <a class="c-social-link c-social-link--white-circle u-icon-facebook" href="//facebook.com/U-Account-205171676580179" target="_blank" title="U Account on Facebook"></a>

            <a class="c-social-link c-social-link--white-circle u-icon-twitter" href="//twitter.com/uaccount" target="_blank" title="U Account on Twitter"></a>

            <a class="c-social-link c-social-link--white-circle u-icon-linkedin" href="//www.linkedin.com/company/15193705" target="_blank" title="U Account LinkedIn"></a>

            <a class="c-social-link c-social-link--white-circle u-icon-instagram" title="U Account on Instagram" target="_blank" href="https://www.instagram.com/uaccountuk/"></a>

        </div>

        <div class="c-site-footer__cookie-preferences">
            <a>Cookie Preferences</a>
        </div>

        <p class="c-site-footer__legals">U is the trading name of Ffrees Family Finance Ltd (Company Number: 7516554). U is not a bank. U is authorised and regulated by the Financial Conduct Authority as a credit broker (Number: 630255). U Accounts are serviced online and by telephone. U does not offer overdrafts. Mastercard<sup>®</sup> is a registered trademark, and the circles design is a trademark of Mastercard<sup>®</sup> International Incorporated. The Card is issued by Wirecard Card Solutions Ltd ("WDCS") pursuant to license by Mastercard<sup>®</sup> International Inc. WDCS is authorised by the Financial Conduct Authority to conduct electronic money service activities under the Electronic Money Regulations 2011 (Ref: 900051).</p>

    </div>

</footer>
```

<div class="c-sg-modifier-wrap js-modifier-wrap">
    <div>
        <button>c-site-footer--reduced</button>
    </div>
    <div>
        <button data-target="c-site-footer__link">is-current</button>
    </div>
</div>
*/
/*doc
---
title: Site Footer with Card
name: site-footer-card
category: Global
---
Used on landing pages

```html_example
<footer class="c-site-footer c-site-footer--card">
    <div class="c-site-footer__content">
        <div class="c-site-footer__card">
            <h2 class="c-site-footer__card-title">Ready to join?</h2>
            <div class="c-site-footer__card-action">
                <a href="/unbank" class="c-btn-block u-color-1">Apply now</a>
            </div>
        </div>
        <div class="c-site-footer__social-links">
            <a class="c-social-link c-social-link--white-circle u-icon-facebook" href="//facebook.com/U-Account-205171676580179" target="_blank" title="U Account on Facebook"></a>
            <a class="c-social-link c-social-link--white-circle u-icon-twitter" href="//twitter.com/uaccount" target="_blank" title="U Account on Twitter"></a>
            <a class="c-social-link c-social-link--white-circle u-icon-linkedin" href="//www.linkedin.com/company/15193705" target="_blank" title="U Account LinkedIn"></a>
            <a class="c-social-link c-social-link--white-circle u-icon-instagram" title="U Account on Instagram" target="_blank" href="https://www.instagram.com/uaccountuk/"></a>
        </div>
        <p class="c-site-footer__legals">U is the trading name of Ffrees Family Finance Ltd (Company Number: 7516554). U is not a bank. U is authorised and regulated by the Financial Conduct Authority as a credit broker (Number: 630255). U Accounts are serviced online and by telephone. U does not offer overdrafts. Mastercard<sup>®</sup> is a registered trademark, and the circles design is a trademark of Mastercard<sup>®</sup> International Incorporated. The Card is issued by Wirecard Card Solutions Ltd ("WDCS") pursuant to license by Mastercard<sup>®</sup> International Inc. WDCS is authorised by the Financial Conduct Authority to conduct electronic money service activities under the Electronic Money Regulations 2011 (Ref: 900051).</p>
    </div>
</footer>
```
*/
.c-site-header {
  transition: filter .25s ease-in-out, box-shadow .1s ease-in-out;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #f1f1f1;
  z-index: 40;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25); }
  .c-site-header__holder {
    max-width: 1440px;
    margin-right: auto;
    margin-left: auto;
    padding-right: 24px;
    padding-left: 24px;
    min-width: 0;
    height: 60px;
    margin-left: auto;
    margin-right: auto;
    -js-display: flex;
    display: flex;
    align-items: center; }
  .c-site-header__skip-to-content {
    position: fixed;
    left: 0;
    top: -100%;
    z-index: 41; }
    .c-site-header__skip-to-content:focus {
      top: 0; }
  .c-site-header__logo {
    transform: rotateY(0);
    transition: transform .5s ease-in-out; }
  .c-site-header__logo-title {
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center; }
  .c-site-header__logo-title-anchor {
    text-decoration: none;
    color: inherit;
    font-family: 'uLogo', sans-serif;
    font-weight: 300;
    font-size: 50px;
    line-height: 1;
    padding: 0;
    font-weight: 300;
    color: #f2663b; }
    .c-site-header__logo-title-anchor:hover {
      color: #f2663b; }
    .c-site-header__logo-title-anchor:focus {
      outline: 1px dotted #000000; }
  .c-site-header__custom-logo-title {
    margin: 0;
    font-size: 18px;
    text-transform: none;
    display: block; }
  .c-site-header__custom-logo-anchor {
    text-decoration: none;
    color: inherit;
    display: inline-block;
    font-family: "futura-pt", sans-serif;
    font-weight: 700;
    color: #f2663b;
    padding: 12px; }
    .c-site-header__custom-logo-anchor:hover {
      color: #f2663b; }
    .c-site-header__custom-logo-anchor:focus {
      border: 1px dotted #000000; }
  .c-site-header__custom-logo-u {
    font-family: 'u-font', sans-serif;
    font-weight: 300;
    font-size: 16px; }
  .c-site-header__custom-logo-name {
    margin: 0;
    font-family: "futura-pt", sans-serif;
    font-weight: 700;
    display: block;
    color: #f2663b;
    text-align: left; }
  .c-site-header__navigation {
    display: none; }
  .c-site-header__nav-list {
    list-style: none;
    margin: 0;
    padding: 0;
    margin-right: 20px;
    line-height: 1; }
  .c-site-header__nav-list-item {
    display: inline-block;
    position: relative;
    margin: 0 12px 0 0;
    cursor: pointer; }
    .c-site-header__nav-list-item:hover .c-site-header__nav-link:after, .c-site-header__nav-list-item:focus .c-site-header__nav-link:after {
      left: 20px;
      right: 20px; }
    .c-site-header__nav-list-item:hover .c-site-header__dropdown-list-wrapper, .c-site-header__nav-list-item:focus .c-site-header__dropdown-list-wrapper {
      left: 50%;
      opacity: 1;
      z-index: 1;
      transform: translate(-50%, 0%);
      transition-delay: 0s, 0s, 0s, 0s; }
    .c-site-header__nav-list-item:hover .c-site-header__dropdown-list-item, .c-site-header__nav-list-item:focus .c-site-header__dropdown-list-item {
      opacity: 1; }
    .c-site-header__nav-list-item:hover:after, .c-site-header__nav-list-item:focus:after {
      color: #f2663b;
      transform: translateY(-40%); }
    .c-site-header__nav-list-item:focus-within .c-site-header__nav-link:after {
      left: 20px;
      right: 20px; }
    .c-site-header__nav-list-item:focus-within .c-site-header__dropdown-list-wrapper {
      left: 50%;
      opacity: 1;
      z-index: 1;
      transform: translate(-50%, 0%);
      transition-delay: 0s, 0s, 0s, 0s; }
    .c-site-header__nav-list-item:focus-within .c-site-header__dropdown-list-item {
      opacity: 1; }
    .c-site-header__nav-list-item:focus-within:after {
      color: #f2663b;
      transform: translateY(-40%); }
    .c-site-header__nav-list-item--icon-down {
      padding-right: 8px; }
      .c-site-header__nav-list-item--icon-down:after {
        font-family: 'ffreesicons', sans-serif;
        font-weight: 500;
        font-style: normal;
        content: "";
        display: inline-block;
        transform: translateY(-50%);
        transition: all .3s ease-in-out;
        position: absolute;
        top: 50%;
        right: 0; }
  .c-site-header__nav-link {
    text-decoration: none;
    color: inherit;
    font-size: 20px;
    line-height: 26px;
    font-family: "futura-pt", sans-serif;
    font-weight: 500;
    position: relative;
    overflow: hidden;
    color: #000000;
    padding: 20px; }
    .c-site-header__nav-link:after {
      transition-property: left, right;
      transition-duration: .2s;
      transition-timing-function: ease-in-out;
      content: '';
      position: absolute;
      left: 51%;
      right: 51%;
      bottom: -2px;
      background-color: #000000;
      height: 2px; }
    .c-site-header__nav-link:hover:after, .c-site-header__nav-link:focus:after, .c-site-header__nav-link:active:after {
      left: 0;
      right: 0; }
    .c-site-header__nav-link:after {
      bottom: 18px; }
    .c-site-header__nav-link:hover, .c-site-header__nav-link:focus, .c-site-header__nav-link:active {
      color: #000000; }
      .c-site-header__nav-link:hover:after, .c-site-header__nav-link:focus:after, .c-site-header__nav-link:active:after {
        left: 20px;
        right: 20px; }
    .c-site-header__nav-link:focus ~ .c-site-header__dropdown-list-wrapper {
      left: 50%;
      opacity: 1;
      z-index: 1;
      transform: translate(-50%, 0%);
      transition-delay: 0s, 0s, 0s, 0s; }
      .c-site-header__nav-link:focus ~ .c-site-header__dropdown-list-wrapper .c-site-header__dropdown-list-item {
        opacity: 1; }
    .c-site-header__nav-link.is-current:after {
      left: 20px;
      right: 20px; }
  .c-site-header__notification-holder {
    position: absolute;
    right: 12px;
    top: -12px; }
  .c-site-header__dropdown-list-wrapper {
    position: absolute;
    left: -9999px;
    top: 100%;
    padding-top: 12px;
    opacity: 0;
    transform: translate(-50%, -20%);
    z-index: -1;
    transition: transform 0.3s ease-in-out 0s, opacity 0.3s ease-in-out 0s, z-index 0s linear 0.01s, left 0s linear 0.3s; }
  .c-site-header__dropdown-list {
    list-style: none;
    padding: 0;
    margin: 0;
    width: 250px;
    background-color: #f1f1f1;
    border: solid 1px #d1d3d4;
    box-shadow: 1px 1px 3px #d1d3d4; }
  .c-site-header__dropdown-list-item {
    display: block;
    margin: 0;
    border-bottom: solid 1px #d1d3d4;
    opacity: 0;
    transition: opacity .2s ease-in-out .3s;
    position: relative; }
    .c-site-header__dropdown-list-item:last-child {
      border-bottom: 0; }
    .c-site-header__dropdown-list-item.is-hidden {
      display: none; }
  .c-site-header__dropdown-notification-holder {
    position: absolute;
    left: calc(24px/2 - 3px);
    top: 50%;
    transform: translateY(-50%); }
  .c-site-header__dropdown-link {
    text-decoration: none;
    color: inherit;
    font-size: 16px;
    line-height: 20px;
    font-family: "futura-pt", sans-serif;
    font-weight: 500;
    padding: 8px 24px;
    display: block;
    color: #f2663b;
    transition: color .3s ease-in-out, background-color .3s ease-in-out; }
    .c-site-header__dropdown-link:hover, .c-site-header__dropdown-link:focus {
      color: #f2663b;
      background-color: #fcded5; }
    .c-site-header__dropdown-link.is-current {
      color: #ffffff;
      background-color: #f2663b; }
      .c-site-header__dropdown-link.is-current:hover, .c-site-header__dropdown-link.is-current:focus {
        color: #ffffff;
        background-color: #f2663b; }
  .c-site-header__button-wrap {
    margin-left: auto; }
  .c-site-header .c-btn-outline {
    margin-right: 8px; }
    .c-site-header .c-btn-outline:last-child {
      margin-right: 0; }
  .c-site-header--no-shadow-until-s-bp {
    box-shadow: none; }
    .c-site-header--no-shadow-until-s-bp.is-nav-open {
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25);
      transition-delay: 0s, 200ms; }
  .u-blur-content .c-site-header {
    transition: filter .25s ease-in-out;
    filter: blur(20px); }
  .l-reduced-header .c-site-header__holder {
    justify-content: center; }
  .l-reduced-header .c-site-header__button-wrap, .l-no-navigation .c-site-header__button-wrap {
    display: none; }
  .l-reduced-header .c-site-header__navigation, .l-no-navigation .c-site-header__navigation {
    display: none; }
  @media (min-width: 800px) {
    .c-site-header--no-shadow-until-s-bp {
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25); } }
  @media (min-width: 980px) {
    .c-site-header__holder {
      height: 100px; }
    .c-site-header__logo-title-anchor {
      font-size: 80px; }
    .c-site-header__custom-logo-title {
      font-size: 32px;
      line-height: 1; }
    .c-site-header__custom-logo-anchor {
      padding: 12px; }
    .c-site-header__custom-logo-u {
      font-size: 26px; }
    .c-site-header__navigation {
      margin-left: auto;
      display: block; }
    .c-site-header__button-wrap {
      margin-left: 0; }
    .c-site-header .c-btn-outline {
      margin-right: 12px; } }
  @media (min-width: 1080px) {
    .c-site-header__nav-list-item {
      margin: 0 28px 0 0; } }

.styleguide .c-site-header {
  position: absolute;
  z-index: 30; }
  .styleguide .c-site-header__dropdown-list-wrapper {
    z-index: 0; }

/*doc
---
title: site header (logged in)
name: site-header-logged-in
category: Global
---

Note - the second <code>is-current</code> state modifier affects the nav items in the dropdown menu.
<br>
The <code>c-site-header__dropdown-list-item--hidden</code> modifier will hide all of the dropdown items here due to the nature of the styleguide, but in practice hides single items from the list.

```html_example
<header data-parent-classes="u-sg-height-667 u-sg-overflow-hidden" class="c-site-header">
    <div class="c-site-header__holder">

        <div class="c-site-header__logo js-customise-logo">
            <h1 class="c-site-header__logo-title">
                <a class="c-site-header__logo-title-anchor" href="/">
                    U
                </a>
            </h1>
        </div>

        <nav class="c-site-header__navigation">
            <ul class="c-site-header__nav-list">

                <li class="c-site-header__nav-list-item c-site-header__nav-list-item--icon-down">
                    <a class="c-site-header__nav-link">Accounts</a>
                    <div class="c-site-header__dropdown-list-wrapper">
                        <ul class="c-site-header__dropdown-list">
                            <li class="c-site-header__dropdown-list-item">
                                <a class="c-site-header__dropdown-link" href="/dashboard">Overview</a>
                            </li>
                            <li class="c-site-header__dropdown-list-item">
                                <a class="c-site-header__dropdown-link" href="/dashboard/extra-accounts">Extra Accounts</a>
                            </li>
                            <li class="c-site-header__dropdown-list-item">
                                <a class="c-site-header__dropdown-link" href="/dashboard/money-manager">Money Manager</a>
                            </li>
                            <li class="c-site-header__dropdown-list-item">
                                <a class="c-site-header__dropdown-link" href="/dashboard/statements">Statements</a>
                            </li>
                            <li class="c-site-header__dropdown-list-item">
                                <a class="c-site-header__dropdown-link" href="/points">Points</a>
                            </li>
                            <li class="c-site-header__dropdown-list-item">
                                <a class="c-site-header__dropdown-link" href="/dashboard/referral">Refer a friend</a>
                            </li>
                        </ul>
                    </div>
                </li>

                <li class="c-site-header__nav-list-item">
                    <a class="c-site-header__nav-link" href="/dashboard/payments">Pay</a>
                </li>

                <li class="c-site-header__nav-list-item">
                    <a class="c-site-header__nav-link" href="/dashboard/my-cards">Card</a>
                </li>

                <li class="c-site-header__nav-list-item">
                    <a class="c-site-header__nav-link" href="/dashboard/profile">Profile</a>
                </li>

                <li class="c-site-header__nav-list-item c-site-header__nav-list-item--icon-down">
                    <a class="c-site-header__nav-link">Help</a>
                    <div class="c-site-header__notification-holder">
                        <div class="c-notification c-notification--font-heavy js-notification is-shown">
                            <div class="c-notification__text js-notification-count">1</div>
                        </div>
                    </div>
                    <div class="c-site-header__dropdown-list-wrapper">
                        <ul class="c-site-header__dropdown-list">
                            <li class="c-site-header__dropdown-list-item">
                                <a class="c-site-header__dropdown-link" href="/help">Help &amp; Support</a>
                            </li>
                            <li class="c-site-header__dropdown-list-item c-site-header__dropdown-list-item--hidden js-notification-link">
                                <div class="c-site-header__dropdown-notification-holder">
                                    <div class="c-notification c-notification--dot"></div>
                                </div>
                                 <a class="c-site-header__dropdown-link" href="/help?current-panel=contact"><span class="js-notifications-count">2</span> Unread Messages</a>
                            </li>
                            <li class="c-site-header__dropdown-list-item">
                                <a class="c-site-header__dropdown-link" href="/service-status">Service Status</a>
                            </li>
                            <li class="c-site-header__dropdown-list-item">
                                <a class="c-site-header__dropdown-link" href="/policies">Policies &amp; Terms</a>
                            </li>
                        </ul>
                    </div>
                </li>

                <li class="c-site-header__nav-list-item">
                    <a class="c-site-header__nav-link">U Credit</a>
                    <div class="c-site-header__notification-holder">
                        <div class="c-notification is-shown c-notification--variable-width c-notification--thin-border c-notification--color-1">
                            <div class="c-notification__text">New</div>
                        </div>
                    </div>
                </li>

            </ul>
        </nav>

        <div class="c-site-header__button-wrap">
            <a href="/logout" class="c-btn-outline c-btn-outline--small c-btn-outline--margin-bottom-0 tt_LogoutHeader">Log out</a>
        </div>

    </div>
</header>

```
<div class="c-sg-modifier-wrap js-modifier-wrap">
    <div>
        <button data-target="c-site-header__nav-list-item">c-site-header__nav-list-item--icon-down</button>
    </div>
    <div>
        <button data-target="c-site-header__nav-link">is-current</button>
    </div>
    <div>
        <button data-target="c-site-header__dropdown-link">is-current</button>
    </div>
    <div>
        <button data-target="c-site-header__dropdown-list-item">is-hidden</button>
    </div>
</div>
*/
/*doc
---
title: site header (logged out)
name: site-header-logged-out
category: Global
---

Note - the second <code>is-current</code> state modifier affects the nav items in the dropdown menu

```html_example
<header class="c-site-header js-site-header" data-parent-classes="u-sg-height-667 u-sg-overflow-hidden">

    <div class="c-site-header__holder">

        <div class="c-site-header__logo js-customise-logo">
            <h1 class="c-site-header__logo-title">
                <a class="c-site-header__logo-title-anchor" href="/">
                    U
                </a>
            </h1>
        </div>

        <nav class="c-site-header__navigation">
            <ul class="c-site-header__nav-list">
                <li class="c-site-header__nav-list-item">
                    <a class="c-site-header__nav-link" href="/pricing">Pricing</a>
                </li>
                <li class="c-site-header__nav-list-item">
                    <a class="c-site-header__nav-link" href="https://medium.com/u-account" target="blank">Blog</a>
                </li>
                <li class="c-site-header__nav-list-item c-site-header__nav-list-item--icon-down">
                    <a class="c-site-header__nav-link">Help</a>
                    <div class="c-site-header__dropdown-list-wrapper">
                        <ul class="c-site-header__dropdown-list">
                            <li class="c-site-header__dropdown-list-item">
                                <a class="c-site-header__dropdown-link" href="/help">Help &amp; Support</a>
                            </li>
                            <li class="c-site-header__dropdown-list-item">
                                <a class="c-site-header__dropdown-link" href="/service-status">Service Status</a>
                            </li>
                        </ul>
                    </div>
                </li>
            </ul>
        </nav>

        <div class="c-site-header__button-wrap">
            <a href="/unbank" id="ttJoinHeader" class="c-btn-outline c-btn-outline--small c-btn-outline--margin-bottom-0 c-btn-outline--margin-right-xs">Apply</a>
            <a href="#" class="c-btn-outline c-btn-outline--black c-btn-outline--small c-btn-outline--margin-bottom-0 js-login-button tt_LoginHeader">Log in</a>
        </div>

    </div>
</header>
```
<div class="c-sg-modifier-wrap js-modifier-wrap">
    <div>
        <button data-target="c-site-header__nav-list-item">c-site-header__nav-list-item--icon-down</button>
    </div>
    <div>
        <button data-target="c-site-header__nav-link">is-current</button>
    </div>
    <div>
        <button data-target="c-site-header__dropdown-link">is-current</button>
    </div>
</div>
*/
.modalContainer .modalOverLay {
  transition: all .3s ease-in-out;
  transform: translateX(50%);
  height: 100%;
  position: fixed;
  width: 0;
  overflow: hidden;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10;
  left: 50%;
  z-index: 1000; }
  .modalContainer .modalOverLay.modalOpen {
    transform: translateX(0);
    width: 100%;
    left: 0; }

.modalContainer .modalContent {
  transform: translateY(-50%);
  position: relative;
  top: 50%;
  transition: opacity .3s ease-in-out .3s;
  transform: translate(-50%, -50%);
  background-color: #000000;
  width: 90%;
  max-width: 600px;
  max-height: 90%;
  color: #ffffff;
  padding: 20px;
  z-index: 1001;
  opacity: 0;
  left: 50%;
  position: fixed;
  pointer-events: none;
  overflow: visible;
  /* Have also included extra old styles (needed for old modal to work) within the modal class */
  /* */ }
  .modalContainer .modalContent.modalOpen {
    opacity: 1;
    pointer-events: auto; }
  .modalContainer .modalContent.centreContent {
    text-align: center; }
    .modalContainer .modalContent.centreContent p {
      text-align: center; }
  .modalContainer .modalContent .modalBody {
    overflow-y: auto;
    overflow-x: hidden; }
    .modalContainer .modalContent .modalBody a:not(.button) {
      color: #f2663b; }
      .modalContainer .modalContent .modalBody a:not(.button):hover {
        color: #bb350c; }
  .modalContainer .modalContent .closeModalIcon {
    width: 1.5em;
    position: absolute;
    top: -.725em;
    right: -.725em;
    background-color: #000000;
    border-radius: 50%;
    font-size: 1.8em;
    line-height: 1.5em;
    text-align: center;
    cursor: pointer; }
  .modalContainer .modalContent h2 {
    font-size: 1.3em;
    line-height: 1.15; }
  .modalContainer .modalContent .buttonContainer {
    float: left;
    display: block;
    width: 100%;
    overflow: hidden; }
    .modalContainer .modalContent .buttonContainer.buttonCenter {
      display: inline-flex; }
      .modalContainer .modalContent .buttonContainer.buttonCenter a {
        margin-left: auto; }
      .modalContainer .modalContent .buttonContainer.buttonCenter button {
        margin-right: auto; }
    .modalContainer .modalContent .buttonContainer > * {
      float: left;
      display: block; }
      .modalContainer .modalContent .buttonContainer > *:last-child {
        margin-right: 0;
        float: right;
        clear: none; }
  .modalContainer .modalContent [class^="icon"]:before, .modalContainer .modalContent [class*=" icon"]:before {
    font-family: 'ffreesicons';
    font-style: normal;
    font-weight: normal;
    speak: none;
    display: inline-block;
    text-decoration: inherit;
    margin-right: .2em;
    text-align: center;
    /* For safety - reset parent styles, that can break glyph codes*/
    font-variant: normal;
    text-transform: none;
    /* fix buttons height, for twitter bootstrap */
    line-height: 1em;
    /* Animation center compensation - margins should be symmetric */
    /* remove if not needed */
    margin-left: .2em;
    /* Font smoothing. That was taken from TWBS */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }
  .modalContainer .modalContent .button {
    line-height: 1.3;
    transition: all .2s ease-in;
    display: block;
    width: 100%;
    max-width: 100%;
    height: 40px;
    color: #ffffff;
    background-color: #ffffff;
    text-align: center;
    padding: 10px 0;
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 2%;
    margin-right: 2%;
    cursor: pointer; }
    @media (min-width: 720px) {
      .modalContainer .modalContent .button {
        max-width: 240px; } }
    .modalContainer .modalContent .button:disabled {
      cursor: not-allowed;
      opacity: .5; }
    .modalContainer .modalContent .button:focus, .modalContainer .modalContent .button:active {
      outline: none; }
    .modalContainer .modalContent .button:hover {
      color: #ffffff; }
  .modalContainer .modalContent .redBg {
    background-color: #d31a35; }
    .modalContainer .modalContent .redBg:hover {
      background-color: #780f1e; }
  .modalContainer .modalContent .greenBg {
    background-color: #6eb52b; }
    .modalContainer .modalContent .greenBg:hover {
      background-color: #3c6317; }
  .modalContainer .modalContent .iconCheck:before {
    content: '\e805'; }
  .modalContainer .modalContent .iconCancel:before {
    content: '\e806'; }
  .modalContainer .modalContent .button {
    width: 100%;
    margin-bottom: 20px; }
    .modalContainer .modalContent .button:last-child {
      margin-right: 0;
      margin-bottom: 0; }
    @media (min-width: 720px) {
      .modalContainer .modalContent .button {
        width: 48%;
        margin-bottom: 0; }
        .modalContainer .modalContent .button:last-child {
          width: 50%; }
        .modalContainer .modalContent .button.wideButton {
          width: 100%; } }
  .modalContainer .modalContent .buttonContainer {
    margin-top: 20px; }
  .modalContainer .modalContent h1,
  .modalContainer .modalContent h2,
  .modalContainer .modalContent h3,
  .modalContainer .modalContent h4,
  .modalContainer .modalContent h5,
  .modalContainer .modalContent h6 {
    color: #ffffff; }
    .modalContainer .modalContent h1.orange,
    .modalContainer .modalContent h2.orange,
    .modalContainer .modalContent h3.orange,
    .modalContainer .modalContent h4.orange,
    .modalContainer .modalContent h5.orange,
    .modalContainer .modalContent h6.orange {
      color: #f2663b; }
    .modalContainer .modalContent h1.light,
    .modalContainer .modalContent h2.light,
    .modalContainer .modalContent h3.light,
    .modalContainer .modalContent h4.light,
    .modalContainer .modalContent h5.light,
    .modalContainer .modalContent h6.light {
      font-weight: 300; }
  .modalContainer .modalContent h2 {
    margin-bottom: 20px;
    border-bottom: 1px solid #ffffff;
    padding-bottom: 5px;
    font-family: "futura-pt", sans-serif;
    font-weight: 800; }

.modalContainer .heightAdjust {
  overflow: auto; }

.modalContainer.opaHidden {
  opacity: 0; }

.modalContainer.securitySetupHolder.opaHidden {
  transition: opacity .5s ease-out; }

.modalContainer .wideSecurityModal {
  transform: translate(0);
  max-width: 100%;
  max-height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  overflow: scroll; }
  @media (min-width: 720px) {
    .modalContainer .wideSecurityModal {
      transform: translate(-50%, -50%);
      max-width: 1140px;
      max-height: 90%;
      width: 90%;
      padding: 3em;
      left: 50%;
      top: 50%;
      overflow: auto; } }
  .modalContainer .wideSecurityModal section > div {
    padding: 0; }
  @media (min-width: 720px) {
    .modalContainer .wideSecurityModal .heightAdjust {
      height: 650px; } }
  @media (min-width: 980px) {
    .modalContainer .wideSecurityModal .heightAdjust {
      height: 100%;
      overflow: hidden; } }
  .modalContainer .wideSecurityModal .closeModalIcon {
    display: none; }
  .modalContainer .wideSecurityModal .modalHeader {
    display: none; }
  .modalContainer .wideSecurityModal section {
    background: #000000; }
  .modalContainer .wideSecurityModal .securityMessage {
    transition: height .5s ease-in-out, max-height .5s ease-in-out;
    max-width: 1440px;
    margin-left: auto;
    margin-right: auto;
    padding: 0; }
    .modalContainer .wideSecurityModal .securityMessage:after {
      content: "";
      display: table;
      clear: both; }
    .modalContainer .wideSecurityModal .securityMessage > div {
      padding: 1.5em;
      text-align: center;
      color: #ffffff;
      position: relative;
      will-change: height;
      margin: 0 0 1.5em; }
      .modalContainer .wideSecurityModal .securityMessage > div p:last-child {
        margin-bottom: 0; }
    .modalContainer .wideSecurityModal .securityMessage .successMessage {
      background-color: #6eb52b; }
    .modalContainer .wideSecurityModal .securityMessage .errorMessage {
      background-color: #d31a35; }
    .modalContainer .wideSecurityModal .securityMessage .warningMessage {
      background-color: #ffb20a; }
    .modalContainer .wideSecurityModal .securityMessage .infoMessage {
      background-color: #005EB0; }
  .modalContainer .wideSecurityModal .fiveCol {
    float: left;
    margin-right: 2.35765%;
    width: 100%; }
    .modalContainer .wideSecurityModal .fiveCol:last-child {
      margin-right: 0; }
    @media (min-width: 980px) {
      .modalContainer .wideSecurityModal .fiveCol {
        float: left;
        margin-right: 2.35765%;
        width: 40.29137%; }
        .modalContainer .wideSecurityModal .fiveCol:last-child {
          margin-right: 0; } }
  .modalContainer .wideSecurityModal .sixCol {
    float: left;
    margin-right: 2.35765%;
    width: 100%; }
    .modalContainer .wideSecurityModal .sixCol:last-child {
      margin-right: 0; }
    @media (min-width: 980px) {
      .modalContainer .wideSecurityModal .sixCol {
        float: left;
        margin-right: 2.35765%;
        width: 48.82117%; }
        .modalContainer .wideSecurityModal .sixCol:last-child {
          margin-right: 0; }
        .modalContainer .wideSecurityModal .sixCol.shiftOne {
          float: left;
          margin-right: 2.35765%;
          width: 48.82117%; }
          .modalContainer .wideSecurityModal .sixCol.shiftOne:last-child {
            margin-right: 0; } }
  .modalContainer .wideSecurityModal h1.un {
    font-size: 3em;
    line-height: 0.75;
    letter-spacing: -3px; }
    @media (min-width: 720px) {
      .modalContainer .wideSecurityModal h1.un {
        text-align: left;
        font-size: 3.5em; } }
    @media (min-width: 980px) {
      .modalContainer .wideSecurityModal h1.un {
        font-size: 3.5em; }
        .modalContainer .wideSecurityModal h1.un.vLong {
          font-size: 2.8em; } }
    @media (min-width: 1280px) {
      .modalContainer .wideSecurityModal h1.un {
        font-size: 3.8em; }
        .modalContainer .wideSecurityModal h1.un.long {
          font-size: 3.6em; }
        .modalContainer .wideSecurityModal h1.un.vLong {
          font-size: 3.4em; } }
  .modalContainer .wideSecurityModal .securityQuestion {
    margin-top: 1em; }
  .modalContainer .wideSecurityModal textarea, .modalContainer .wideSecurityModal input, .modalContainer .wideSecurityModal .selectInput select {
    appearance: none;
    box-sizing: border-box;
    width: 100%;
    height: 2.8rem;
    margin: 0;
    padding: .85rem 1rem 0 .5rem;
    display: block;
    color: #000000;
    font-size: 16px;
    background-color: #ffffff;
    border: 1px solid #d1d3d4;
    border-radius: 6px;
    white-space: nowrap;
    outline: none;
    box-shadow: none;
    word-break: normal; }
    @media (min-width: 400px) {
      .modalContainer .wideSecurityModal textarea, .modalContainer .wideSecurityModal input, .modalContainer .wideSecurityModal .selectInput select {
        padding: .85rem 2rem 0 1rem; } }
    .animation .modalContainer .wideSecurityModal textarea, .animation .modalContainer .wideSecurityModal input, .animation .modalContainer .wideSecurityModal .selectInput select {
      transition: all .2s ease-in-out; }
    .modalContainer .wideSecurityModal textarea:active, .modalContainer .wideSecurityModal input:active, .modalContainer .wideSecurityModal .selectInput select:active, .modalContainer .wideSecurityModal textarea:focus, .modalContainer .wideSecurityModal input:focus, .modalContainer .wideSecurityModal .selectInput select:focus, .modalContainer .wideSecurityModal textarea:hover, .modalContainer .wideSecurityModal input:hover, .modalContainer .wideSecurityModal .selectInput select:hover {
      color: #f2663b;
      background-color: #fffaf3;
      border-color: #f2663b;
      outline: none; }
    .modalContainer .wideSecurityModal textarea:-moz-submit-invalid, .modalContainer .wideSecurityModal input:-moz-submit-invalid, .modalContainer .wideSecurityModal .selectInput select:-moz-submit-invalid {
      display: none; }
    .modalContainer .wideSecurityModal textarea::-ms-clear, .modalContainer .wideSecurityModal input::-ms-clear, .modalContainer .wideSecurityModal .selectInput select::-ms-clear {
      display: none; }
    .noLabel .modalContainer .wideSecurityModal textarea, .noLabel .modalContainer .wideSecurityModal input, .noLabel .modalContainer .wideSecurityModal .selectInput select {
      padding-top: 0; }
  .modalContainer .wideSecurityModal .inputHint, .modalContainer .wideSecurityModal .requiredHint, .modalContainer .wideSecurityModal .boxHint {
    max-width: calc(100% - 20px);
    min-width: 85px;
    margin: 0 10px;
    padding: 3px 5px;
    position: absolute;
    top: 3.1rem;
    visibility: hidden;
    opacity: 0;
    color: #ffffff;
    font-size: .75rem;
    background-color: #d31a35;
    border-radius: 3px;
    box-sizing: border-box;
    z-index: 5; }
    .animation .modalContainer .wideSecurityModal .inputHint, .animation .modalContainer .wideSecurityModal .requiredHint, .animation .modalContainer .wideSecurityModal .boxHint {
      transition: all .2s ease-in-out; }
    .modalContainer .wideSecurityModal .inputHint:before, .modalContainer .wideSecurityModal .requiredHint:before, .modalContainer .wideSecurityModal .boxHint:before {
      content: '';
      width: 1px;
      height: 1px;
      margin-left: 16px;
      position: absolute;
      top: -14px;
      border: 8px solid #d1d3d4;
      border-color: transparent transparent #d31a35; }
  .modalContainer .wideSecurityModal fieldset {
    background: #ebeced;
    border: 1px solid #d1d3d4;
    margin: 0 0 0.5em;
    padding: 1em; }
  .modalContainer .wideSecurityModal input,
  .modalContainer .wideSecurityModal label,
  .modalContainer .wideSecurityModal select {
    display: block;
    font-family: 'futura-pt', sans-serif;
    font-size: 1em; }
  .modalContainer .wideSecurityModal textarea {
    white-space: pre-wrap;
    min-height: 150px;
    min-width: 100%;
    max-width: 100%; }
    .modalContainer .wideSecurityModal textarea + .requiredHint {
      top: auto;
      bottom: -22px; }
  .modalContainer .wideSecurityModal label:not(.labelReset) {
    width: 100%;
    margin: 0 0 2em;
    position: relative; }
    .modalContainer .wideSecurityModal label:not(.labelReset) .label {
      position: absolute;
      top: .9rem;
      left: .5rem;
      z-index: 1;
      color: rgba(0, 0, 0, 0.35);
      font-size: 1em / 1.25;
      font-weight: 700;
      pointer-events: none; }
      @media (min-width: 400px) {
        .modalContainer .wideSecurityModal label:not(.labelReset) .label {
          left: 1rem; } }
      .animation .modalContainer .wideSecurityModal label:not(.labelReset) .label {
        transition: all .2s ease-in-out; }
      .modalContainer .wideSecurityModal label:not(.labelReset) .label.vertCenter {
        top: 50%;
        transform: translateY(-50%); }
    .modalContainer .wideSecurityModal label:not(.labelReset).toggle:before {
      width: auto;
      height: auto; }
    .modalContainer .wideSecurityModal label:not(.labelReset):before {
      width: 1.3rem;
      height: 1.3rem;
      position: absolute;
      right: .5rem;
      bottom: .55rem;
      text-align: center;
      color: rgba(0, 0, 0, 0.5);
      line-height: .9rem;
      font-family: 'ffreesicons';
      font-size: .8em;
      pointer-events: none;
      z-index: 3; }
      .animation .modalContainer .wideSecurityModal label:not(.labelReset):before {
        transition: all .2s ease-in-out; }
      .ie10 .modalContainer .wideSecurityModal label:not(.labelReset):before,
      .ie11 .modalContainer .wideSecurityModal label:not(.labelReset):before {
        bottom: 0; }
    .modalContainer .wideSecurityModal label:not(.labelReset):after {
      width: 1.3rem;
      height: 1.3rem;
      position: absolute;
      left: .5rem;
      bottom: .5rem;
      text-align: center;
      color: #bcbec0;
      line-height: .9rem;
      font-size: 16px;
      font-family: 'ffreesicons';
      z-index: 4; }
      .animation .modalContainer .wideSecurityModal label:not(.labelReset):after {
        transition: all .2s ease-in-out; }
      .ie10.ie10.ie10 .modalContainer .wideSecurityModal label:not(.labelReset):after,
      .ie11.ie11.ie11 .modalContainer .wideSecurityModal label:not(.labelReset):after {
        bottom: -6px; }
    .modalContainer .wideSecurityModal label:not(.labelReset):hover:before, .modalContainer .wideSecurityModal label:not(.labelReset):hover:after,
    .modalContainer .wideSecurityModal label:not(.labelReset):hover .label {
      color: #f2663b; }
    .modalContainer .wideSecurityModal label:not(.labelReset).smallLabel .label {
      top: 5px;
      font-size: 8px;
      color: #f2663b;
      transform: translateY(0); }
    .modalContainer .wideSecurityModal label:not(.labelReset).smallLabel sup {
      visibility: hidden;
      opacity: 0; }
    .modalContainer .wideSecurityModal label:not(.labelReset).smallLabel:after {
      top: auto;
      bottom: .1rem;
      opacity: 1;
      color: #000000;
      font-size: .9em; }
  .modalContainer .wideSecurityModal label.invalid:before {
    content: "";
    color: #d31a35;
    font-size: 1rem;
    font-family: 'ffreesicons'; }
  .modalContainer .wideSecurityModal label.invalid.smallLabel .label, .modalContainer .wideSecurityModal label.invalid:after {
    color: #d31a35; }
  .modalContainer .wideSecurityModal label.invalid label {
    color: rgba(211, 26, 53, 0.5); }
  .modalContainer .wideSecurityModal label.invalid input,
  .modalContainer .wideSecurityModal label.invalid .selectInput,
  .modalContainer .wideSecurityModal label.invalid .dpTagBox {
    color: #d31a35;
    background-color: #FABCCC;
    border-color: #d31a35; }
    .modalContainer .wideSecurityModal label.invalid input select,
    .modalContainer .wideSecurityModal label.invalid .selectInput select,
    .modalContainer .wideSecurityModal label.invalid .dpTagBox select {
      color: #d31a35;
      background: none;
      border: 0; }
  .modalContainer .wideSecurityModal label.invalid .selectInput:before {
    color: #d31a35; }
  .modalContainer .wideSecurityModal label.warning:before {
    content: "";
    color: #ffb20a;
    font-size: 1rem;
    font-family: 'ffreesicons'; }
  .modalContainer .wideSecurityModal label.warning.smallLabel .label, .modalContainer .wideSecurityModal label.warning:after {
    color: #ffb20a; }
  .modalContainer .wideSecurityModal label.warning .label {
    color: rgba(255, 178, 10, 0.5); }
  .modalContainer .wideSecurityModal label.warning input,
  .modalContainer .wideSecurityModal label.warning .selectInput,
  .modalContainer .wideSecurityModal label.warning .dpTagBox {
    color: #ffb20a;
    background-color: #FFF6E1;
    border-color: #ffb20a;
    box-shadow: none; }
    .modalContainer .wideSecurityModal label.warning input select,
    .modalContainer .wideSecurityModal label.warning .selectInput select,
    .modalContainer .wideSecurityModal label.warning .dpTagBox select {
      color: #ffb20a;
      background: none;
      border: 0; }
  .modalContainer .wideSecurityModal label.warning .selectInput:before {
    color: #ffb20a; }
  .modalContainer .wideSecurityModal label.valid.validWithScrollbar:before {
    padding-right: 2rem; }
  .modalContainer .wideSecurityModal label.valid:before {
    content: "";
    color: #6eb52b;
    font-size: 1rem;
    font-family: 'ffreesicons'; }
  .modalContainer .wideSecurityModal label.valid.smallLabel .label, .modalContainer .wideSecurityModal label.valid:after {
    color: #6eb52b; }
  .modalContainer .wideSecurityModal label.valid:hover:before {
    color: #6eb52b; }
  .modalContainer .wideSecurityModal label.valid .label {
    color: rgba(110, 181, 43, 0.5); }
  .modalContainer .wideSecurityModal label.valid input,
  .modalContainer .wideSecurityModal label.valid .selectInput,
  .modalContainer .wideSecurityModal label.valid .dpTagBox {
    color: #6eb52b;
    background-color: #eff9e6;
    border-color: #6eb52b; }
    .modalContainer .wideSecurityModal label.valid input select,
    .modalContainer .wideSecurityModal label.valid .selectInput select,
    .modalContainer .wideSecurityModal label.valid .dpTagBox select {
      color: #6eb52b;
      background: none;
      border: 0;
      white-space: nowrap;
      text-overflow: ellipsis; }
  .modalContainer .wideSecurityModal label.disabled:before {
    color: #a7a9ac;
    font-size: 1rem;
    font-family: 'ffreesicons'; }
  .modalContainer .wideSecurityModal label.disabled.smallLabel .label, .modalContainer .wideSecurityModal label.disabled:after {
    color: #a7a9ac; }
  .modalContainer .wideSecurityModal label.disabled:hover {
    cursor: not-allowed; }
    .modalContainer .wideSecurityModal label.disabled:hover:before {
      color: #a7a9ac; }
  .modalContainer .wideSecurityModal label.disabled.valid:before {
    color: #6eb52b; }
  .modalContainer .wideSecurityModal label.disabled .label {
    color: rgba(167, 169, 172, 0.5); }
  .modalContainer .wideSecurityModal label.disabled input,
  .modalContainer .wideSecurityModal label.disabled .selectInput,
  .modalContainer .wideSecurityModal label.disabled .dpTagBox {
    color: #a7a9ac;
    background-color: #fbfcfc;
    border-color: #f0f1f3; }
    .modalContainer .wideSecurityModal label.disabled input select,
    .modalContainer .wideSecurityModal label.disabled .selectInput select,
    .modalContainer .wideSecurityModal label.disabled .dpTagBox select {
      color: #a7a9ac;
      background: none;
      border: 0; }
  .modalContainer .wideSecurityModal label.inline {
    display: inline-block;
    width: auto;
    vertical-align: top;
    margin-right: 10px; }
  .modalContainer .wideSecurityModal input[disabled='disabled'] {
    color: #a7a9ac;
    background-color: #fbfcfc;
    border-color: #d1d3d4; }
  .modalContainer .wideSecurityModal input:focus + .inputHint,
  .modalContainer .wideSecurityModal label[class*='date'] .inputHint,
  .modalContainer .wideSecurityModal .showIt {
    visibility: visible;
    opacity: 1;
    z-index: 10; }
  .modalContainer .wideSecurityModal .requiredHint {
    visibility: visible;
    opacity: 1;
    background-color: #ffb20a;
    z-index: 11; }
    .modalContainer .wideSecurityModal .requiredHint:before {
      border-color: transparent transparent #ffb20a; }
  .modalContainer .wideSecurityModal .boxHint {
    padding: 6px;
    list-style: none;
    color: #ffffff;
    background-color: #fbfcfc;
    border: 1px solid #d1d3d4;
    border-radius: 6px;
    pointer-events: none; }
    .modalContainer .wideSecurityModal .boxHint:before {
      border-color: transparent transparent #d1d3d4; }
    .modalContainer .wideSecurityModal .boxHint li {
      font-size: .75rem;
      font-weight: bold;
      line-height: 1.4rem;
      color: #000000; }
      .modalContainer .wideSecurityModal .boxHint li:before {
        content: '-';
        width: 16px;
        height: 16px;
        margin-top: 4px;
        float: left;
        line-height: normal;
        text-align: center;
        box-sizing: border-box; }
      .modalContainer .wideSecurityModal .boxHint li.invalid {
        color: #d31a35; }
        .modalContainer .wideSecurityModal .boxHint li.invalid:before {
          content: '\e806';
          padding: 1px 0 0 1px;
          font-family: 'ffreesicons';
          font-size: .65rem; }
      .modalContainer .wideSecurityModal .boxHint li.valid {
        color: #6eb52b; }
        .modalContainer .wideSecurityModal .boxHint li.valid:before {
          font-family: 'ffreesicons';
          content: '\e805';
          padding: 1px 0 0 1px;
          font-size: .65rem; }
  .modalContainer .wideSecurityModal input[type=number]::-webkit-inner-spin-button,
  .modalContainer .wideSecurityModal input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0; }
  .modalContainer .wideSecurityModal input[type=number] {
    -moz-appearance: textfield; }
  .modalContainer .wideSecurityModal input[type="password"] {
    font-family: initial; }
  .modalContainer .wideSecurityModal input[disabled="disabled"]:hover {
    color: #a7a9ac;
    background-color: #fbfcfc;
    border-color: #d1d3d4; }
  .modalContainer .wideSecurityModal button {
    width: auto;
    float: left;
    clear: left;
    display: inline-block;
    color: #ffffff;
    background-color: #6eb52b;
    border: 0; }
    .modalContainer .wideSecurityModal button.disabled {
      opacity: .4; }
    .modalContainer .wideSecurityModal button.submitDisabled, .modalContainer .wideSecurityModal button[disabled="disabled"] {
      opacity: .4;
      cursor: not-allowed; }
      .animation .modalContainer .wideSecurityModal button.submitDisabled, .animation .modalContainer .wideSecurityModal button[disabled="disabled"] {
        transition: all .2s ease-in-out; }
    .modalContainer .wideSecurityModal button:hover {
      background-color: #558c21; }
  .modalContainer .wideSecurityModal .selectInput {
    box-sizing: border-box;
    width: 100%;
    padding: 0;
    position: relative;
    background-color: #ffffff;
    border: 1px solid #d1d3d4;
    border-radius: 6px;
    z-index: 4; }
    .animation .modalContainer .wideSecurityModal .selectInput {
      transition: all .2s ease-in-out; }
    .modalContainer .wideSecurityModal .selectInput:hover {
      background-color: #fffaf3;
      border-color: #f2663b; }
      .modalContainer .wideSecurityModal .selectInput:hover:after {
        color: #f2663b; }
    .modalContainer .wideSecurityModal .selectInput:after {
      content: '\e804';
      height: 100%;
      padding: 0 .8em;
      display: block;
      position: absolute;
      top: 0;
      right: -5px;
      color: #bcbec0;
      line-height: 2.8rem;
      font-family: 'ffreesicons';
      font-size: 16px;
      text-align: center;
      z-index: -1; }
      @media (min-width: 400px) {
        .modalContainer .wideSecurityModal .selectInput:after {
          right: 0; } }
      .animation .modalContainer .wideSecurityModal .selectInput:after {
        transition: all .2s ease-in-out; }
      .ie10 .modalContainer .wideSecurityModal .selectInput:after,
      .ie11 .modalContainer .wideSecurityModal .selectInput:after {
        top: 0px; }
    .modalContainer .wideSecurityModal .selectInput select {
      height: calc(2.8rem - 2px);
      margin-bottom: 0;
      background-color: transparent;
      border: 0 none;
      box-shadow: none;
      cursor: pointer;
      padding-right: 10px; }
      @media (min-width: 330px) {
        .modalContainer .wideSecurityModal .selectInput select {
          padding-right: inherit; } }
      .modalContainer .wideSecurityModal .selectInput select:hover, .modalContainer .wideSecurityModal .selectInput select:focus {
        background-color: transparent;
        border: 0; }
      .modalContainer .wideSecurityModal .selectInput select:hover {
        color: #f2663b; }
      .modalContainer .wideSecurityModal .selectInput select:-moz-focusring {
        color: transparent;
        text-shadow: 0 0 0 #f2663b; }
      .modalContainer .wideSecurityModal .selectInput select::-ms-expand {
        display: none; }
    .modalContainer .wideSecurityModal .selectInput option {
      appearance: none;
      padding: 3px;
      color: #000000;
      text-shadow: none;
      background-color: #ffffff;
      border: 0; }
      .modalContainer .wideSecurityModal .selectInput option[disabled] {
        color: #d1d3d4; }
        .modalContainer .wideSecurityModal .selectInput option[disabled]:not(.view) {
          display: none; }
  .modalContainer .wideSecurityModal :-moz-any(.selectLabel) .selectInput select {
    width: calc(100% - 2px); }
  .modalContainer .wideSecurityModal :-moz-any(.selectLabel) .selectInput:after {
    background-color: #ffffff;
    pointer-events: none;
    border-radius: 0 6px 6px 0;
    z-index: 1; }
    .animation .modalContainer .wideSecurityModal :-moz-any(.selectLabel) .selectInput:after {
      transition: all .2s ease-in-out; }
  .modalContainer .wideSecurityModal :-moz-any(.selectLabel) .selectInput:hover:after {
    background-color: #fffaf3; }
  .modalContainer .wideSecurityModal :-moz-any(.selectLabel).invalid .selectInput:after {
    background-color: #FABCCC; }
  .modalContainer .wideSecurityModal :-moz-any(.selectLabel).valid .selectInput:after {
    background-color: #eff9e6; }

.c-slider-v2__pips {
  width: 100%;
  margin-bottom: 12px;
  padding: 0;
  text-align: center; }

.c-slider-v2__pip {
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.21);
  width: 12px;
  height: 12px;
  margin: 12px;
  background-color: #ffffff;
  display: inline-block;
  border-radius: 50%;
  transition: background-color .25s ease-in-out; }
  .c-slider-v2__pip.is-highlighted {
    background-color: #000000; }

.c-slider-v2__wrap {
  position: relative;
  max-width: 100%;
  overflow: hidden;
  transition: height 0.3s ease-in-out; }

.c-slider-v2__slide {
  position: absolute;
  width: 100%;
  align-self: flex-start;
  margin-right: 1%; }

.c-sliding-panels {
  position: relative; }
  .c-sliding-panels__content {
    max-width: 1440px;
    margin-right: auto;
    margin-left: auto;
    padding-right: 24px;
    padding-left: 24px;
    min-width: 0; }
  .c-sliding-panels__nav {
    margin: 0 0 12px;
    text-align: center; }
    .c-sliding-panels__nav .is-active {
      background-color: #f2663b; }
  .c-sliding-panels__panels-holder {
    width: 100%;
    height: 0;
    padding: 0;
    position: relative;
    overflow: hidden;
    transition: height .35s ease-in-out; }
  .c-sliding-panels__panels-tray {
    transition-property: transform;
    transition-timing-function: ease-in-out; }
    .c-sliding-panels__panels-tray:after {
      content: "";
      display: table;
      clear: both; }
    .c-sliding-panels__panels-tray.no-transition {
      transition: none; }
      .c-sliding-panels__panels-tray.no-transition .c-sliding-panels__body,
      .c-sliding-panels__panels-tray.no-transition .c-sliding-panels__nav-item,
      .c-sliding-panels__panels-tray.no-transition .c-sliding-panels__nav-item::before {
        transition: none; }
  .c-sliding-panels__panel {
    float: left; }
  .c-sliding-panels__title {
    margin-top: 16px;
    margin-bottom: 16px;
    text-align: left; }
  .c-sliding-panels__body {
    display: none;
    height: 0;
    transition: height 0.2s ease-in-out; }
  .c-sliding-panels--progress {
    text-align: left; }
    .c-sliding-panels--progress .c-sliding-panels__panels-holder {
      float: right;
      height: auto;
      width: calc(100% - 20px); }
    .c-sliding-panels--progress .c-sliding-panels__panel {
      width: 100%;
      overflow: hidden; }
      .c-sliding-panels--progress .c-sliding-panels__panel.is-filled {
        color: #6eb52b;
        cursor: pointer; }
    .c-sliding-panels--progress .c-sliding-panels__nav {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;
      position: absolute;
      top: 0;
      left: 0;
      width: 20px;
      height: 100%;
      margin: 0;
      padding: 0; }
    .c-sliding-panels--progress .c-sliding-panels__nav-item {
      position: relative;
      height: 33.33%;
      width: 1px;
      margin: 0;
      padding: 0;
      flex-grow: 1;
      flex-shrink: 1;
      background-color: #f1f1f1;
      font-size: 0;
      transition: height 0.2s ease-in-out, background-color 0.2s ease-in-out; }
      .c-sliding-panels--progress .c-sliding-panels__nav-item::before {
        content: '';
        width: 8px;
        height: 8px;
        border-radius: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 5;
        background-color: #000000;
        transition: background-color 0.4s ease-out; }
      .c-sliding-panels--progress .c-sliding-panels__nav-item.is-active {
        background-color: #f2663b;
        flex-grow: 0;
        flex-shrink: 0; }
        .c-sliding-panels--progress .c-sliding-panels__nav-item.is-active::before {
          background-color: #f2663b; }
      .c-sliding-panels--progress .c-sliding-panels__nav-item.is-disabled::before {
        background-color: #f1f1f1; }
    .c-sliding-panels--progress .c-sliding-panels__content {
      padding: 0;
      clear: both; }
  @media (min-width: 980px) {
    .c-sliding-panels__title {
      margin-top: 0;
      margin-bottom: 28px; }
    .c-sliding-panels__body {
      display: block;
      height: auto; }
    .c-sliding-panels--progress .c-sliding-panels__body {
      padding: 0 48px; }
    .c-sliding-panels--progress .c-sliding-panels__panels-holder {
      float: none;
      height: 0;
      width: 100%; }
    .c-sliding-panels--progress .c-sliding-panels__nav {
      flex-direction: row;
      position: relative;
      width: auto;
      height: auto;
      padding: 28px 36px 16px; }
    .c-sliding-panels--progress .c-sliding-panels__nav-item {
      height: 1px;
      width: 100%;
      max-width: none;
      flex-grow: 1;
      flex-shrink: 1; }
      .c-sliding-panels--progress .c-sliding-panels__nav-item::before {
        background-color: #f1f1f1; }
      .c-sliding-panels--progress .c-sliding-panels__nav-item.is-active {
        height: 2px;
        flex-grow: 1;
        flex-shrink: 1; }
        .c-sliding-panels--progress .c-sliding-panels__nav-item.is-active::before {
          width: 12px;
          height: 12px; }
    .c-sliding-panels--padding .c-sliding-panels__content {
      padding-left: 36px;
      padding-right: 36px; } }

/*doc
---
title: Sliding Panels
name: sliding-panels
category: Organisms
---

```html_example

<section class="c-sliding-panels">

    <div class="c-sliding-panels__content" data-controls="careers">

        <p>

            <a href="#" class="c-text-link c-text-link--color-1 js-slide-to-panel" data-slide-to-panel="1">Panel 1</a>

            <a href="#" class="c-text-link c-text-link--color-1 js-slide-to-panel" data-slide-to-panel="2">Panel 2</a>

            <a href="#" class="c-text-link c-text-link--color-1 js-slide-to-panel" data-slide-to-panel="3">Panel 3</a>

        </p>

        <div class="c-sliding-panels__nav">

            <button class="c-btn c-btn--black c-btn--margin-bottom-s c-btn--margin-right-s js-slide-to-panel" data-slide-to-panel="previous">Previous</button>

            <button class="c-btn c-btn--black c-btn--margin-bottom-s c-btn--margin-right-s js-slide-to-panel" data-slide-to-panel="1">Panel 1</button>

            <button class="c-btn c-btn--black c-btn--margin-bottom-s c-btn--margin-right-s js-slide-to-panel" data-slide-to-panel="2">Panel 2</button>

            <button class="c-btn c-btn--black c-btn--margin-bottom-s c-btn--margin-right-s js-slide-to-panel" data-slide-to-panel="3">Panel 3</button>

            <button class="c-btn c-btn--black c-btn--margin-bottom-s c-btn--margin-right-s js-slide-to-panel" data-slide-to-panel="next">Next</button>

        </div>

    </div>

    <h1>First Panel Set</h1>

    <div class="c-sliding-panels__panels-holder u-reset-section-div js-sliding-panels" data-nav="careers">

        <div class="c-sliding-panels__panels-tray js-sliding-panels-tray">

            <div class="c-sliding-panels__panel js-sliding-panel" data-panel="1">

                <div class="c-sliding-panels__content">

                    <p>Vivamus magna justo, lacinia eget consectetur sed, convallis at tellus. Nulla quis lorem ut libero malesuada feugiat. Donec rutrum congue leo eget malesuada. Nulla quis lorem ut libero malesuada feugiat. Sed porttitor lectus nibh.</p>

                    <p>Curabitur arcu erat, accumsan id imperdiet et, porttitor at sem. Curabitur aliquet quam id dui posuere blandit. Vestibulum ac diam sit amet quam vehicula elementum sed sit amet dui. Nulla quis lorem ut libero malesuada feugiat. Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>

                    <p>Vestibulum ac diam sit amet quam vehicula elementum sed sit amet dui. Vestibulum ac diam sit amet quam vehicula elementum sed sit amet dui. Vivamus suscipit tortor eget felis porttitor volutpat. Curabitur non nulla sit amet nisl tempus convallis quis ac lectus. Mauris blandit aliquet elit, eget tincidunt nibh pulvinar a.</p>

                    <p>Cras ultricies ligula sed magna dictum porta. Vestibulum ac diam sit amet quam vehicula elementum sed sit amet dui. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Donec velit neque, auctor sit amet aliquam vel, ullamcorper sit amet ligula. Donec sollicitudin molestie malesuada. Proin eget tortor risus.</p>

                </div>

            </div>

            <div class="c-sliding-panels__panel js-sliding-panel" data-panel="2">

                <div class="c-sliding-panels__content">

                    <p>Praesent sapien massa, convallis a pellentesque nec, egestas non nisi. Mauris blandit aliquet elit, eget tincidunt nibh pulvinar a. Curabitur non nulla sit amet nisl tempus convallis quis ac lectus. Curabitur arcu erat, accumsan id imperdiet et, porttitor at sem. Cras ultricies ligula sed magna dictum porta.</p>

                    <p>Proin eget tortor risus. Vivamus suscipit tortor eget felis porttitor volutpat. Praesent sapien massa, convallis a pellentesque nec, egestas non nisi. Curabitur arcu erat, accumsan id imperdiet et, porttitor at sem. Nulla quis lorem ut libero malesuada feugiat.</p>

                </div>

            </div>

            <div class="c-sliding-panels__panel js-sliding-panel" data-panel="3">

                <div class="c-sliding-panels__content">

                    <p>Vivamus suscipit tortor eget felis porttitor volutpat. Curabitur non nulla sit amet nisl tempus convallis quis ac lectus. Pellentesque in ipsum id orci porta dapibus. Cras ultricies ligula sed magna dictum porta. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Donec velit neque, auctor sit amet aliquam vel, ullamcorper sit amet ligula.</p>

                    <p>Curabitur arcu erat, accumsan id imperdiet et, porttitor at sem. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur aliquet quam id dui posuere blandit. Curabitur arcu erat, accumsan id imperdiet et, porttitor at sem. Nulla quis lorem ut libero malesuada feugiat.</p>

                    <p>Donec rutrum congue leo eget malesuada. Vivamus magna justo, lacinia eget consectetur sed, convallis at tellus. Curabitur non nulla sit amet nisl tempus convallis quis ac lectus. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum ac diam sit amet quam vehicula elementum sed sit amet dui.</p>

                </div>

            </div>

        </div>

    </div>

    <h1>Second Panel Set</h1>

    <div class="c-sliding-panels__panels-holder u-color-grey-light-bg u-reset-section-div js-sliding-panels" data-nav="careers">

        <div class="c-sliding-panels__panels-tray js-sliding-panels-tray">

            <div class="c-sliding-panels__panel js-sliding-panel" data-panel="1">

                <div class="c-sliding-panels__content">

                    <p>Vivamus magna justo, lacinia eget consectetur sed, convallis at tellus. Nulla quis lorem ut libero malesuada feugiat. Donec rutrum congue leo eget malesuada. Nulla quis lorem ut libero malesuada feugiat. Sed porttitor lectus nibh.</p>

                    <p>Curabitur arcu erat, accumsan id imperdiet et, porttitor at sem. Curabitur aliquet quam id dui posuere blandit. Vestibulum ac diam sit amet quam vehicula elementum sed sit amet dui. Nulla quis lorem ut libero malesuada feugiat. Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>

                    <p>Vestibulum ac diam sit amet quam vehicula elementum sed sit amet dui. Vestibulum ac diam sit amet quam vehicula elementum sed sit amet dui. Vivamus suscipit tortor eget felis porttitor volutpat. Curabitur non nulla sit amet nisl tempus convallis quis ac lectus. Mauris blandit aliquet elit, eget tincidunt nibh pulvinar a.</p>

                    <p>Cras ultricies ligula sed magna dictum porta. Vestibulum ac diam sit amet quam vehicula elementum sed sit amet dui. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Donec velit neque, auctor sit amet aliquam vel, ullamcorper sit amet ligula. Donec sollicitudin molestie malesuada. Proin eget tortor risus.</p>

                </div>

            </div>

            <div class="c-sliding-panels__panel js-sliding-panel" data-panel="2">

                <div class="c-sliding-panels__content">

                    <p>Praesent sapien massa, convallis a pellentesque nec, egestas non nisi. Mauris blandit aliquet elit, eget tincidunt nibh pulvinar a. Curabitur non nulla sit amet nisl tempus convallis quis ac lectus. Curabitur arcu erat, accumsan id imperdiet et, porttitor at sem. Cras ultricies ligula sed magna dictum porta.</p>

                    <p>Proin eget tortor risus. Vivamus suscipit tortor eget felis porttitor volutpat. Praesent sapien massa, convallis a pellentesque nec, egestas non nisi. Curabitur arcu erat, accumsan id imperdiet et, porttitor at sem. Nulla quis lorem ut libero malesuada feugiat.</p>

                </div>

            </div>

            <div class="c-sliding-panels__panel js-sliding-panel" data-panel="3">

                <div class="c-sliding-panels__content">

                    <p>Vivamus suscipit tortor eget felis porttitor volutpat. Curabitur non nulla sit amet nisl tempus convallis quis ac lectus. Pellentesque in ipsum id orci porta dapibus. Cras ultricies ligula sed magna dictum porta. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Donec velit neque, auctor sit amet aliquam vel, ullamcorper sit amet ligula.</p>

                    <p>Curabitur arcu erat, accumsan id imperdiet et, porttitor at sem. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur aliquet quam id dui posuere blandit. Curabitur arcu erat, accumsan id imperdiet et, porttitor at sem. Nulla quis lorem ut libero malesuada feugiat.</p>

                    <p>Donec rutrum congue leo eget malesuada. Vivamus magna justo, lacinia eget consectetur sed, convallis at tellus. Curabitur non nulla sit amet nisl tempus convallis quis ac lectus. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum ac diam sit amet quam vehicula elementum sed sit amet dui.</p>

                </div>

            </div>

        </div>

    </div>


</section>
```
*/
/*doc
---
title: Sliding Panels Progress
name: sliding-panels-progress
category: Organisms
---

```html_example

<section class="c-sliding-panels c-sliding-panels--progress js-sliding-panels-progress-wrapper">

    <div class="c-sliding-panels__panels-holder u-reset-section-div js-sliding-panels js-sliding-panels-progress" data-nav="progress">

        <div class="c-sliding-panels__panels-tray js-sliding-panels-tray">

            <div class="c-sliding-panels__panel js-sliding-panel" data-panel="4">

                <div class="c-sliding-panels__content">
                    <h2 class="c-sliding-panels__title u-section-intro">Source of Income</h2>

                    <div class="c-sliding-panels__body js-sliding-panel-body">

                        <p>Vivamus magna justo, lacinia eget consectetur sed, convallis at tellus. Nulla quis lorem ut libero malesuada feugiat. Donec rutrum congue leo eget malesuada. Nulla quis lorem ut libero malesuada feugiat. Sed porttitor lectus nibh.</p>

                        <p>Curabitur arcu erat, accumsan id imperdiet et, porttitor at sem. Curabitur aliquet quam id dui posuere blandit. Vestibulum ac diam sit amet quam vehicula elementum sed sit amet dui. Nulla quis lorem ut libero malesuada feugiat. Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>

                        <p>Vestibulum ac diam sit amet quam vehicula elementum sed sit amet dui. Vestibulum ac diam sit amet quam vehicula elementum sed sit amet dui. Vivamus suscipit tortor eget felis porttitor volutpat. Curabitur non nulla sit amet nisl tempus convallis quis ac lectus. Mauris blandit aliquet elit, eget tincidunt nibh pulvinar a.</p>

                        <p>Cras ultricies ligula sed magna dictum porta. Vestibulum ac diam sit amet quam vehicula elementum sed sit amet dui. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Donec velit neque, auctor sit amet aliquam vel, ullamcorper sit amet ligula. Donec sollicitudin molestie malesuada. Proin eget tortor risus.</p>

                    </div>

                </div>

            </div>

            <div class="c-sliding-panels__panel js-sliding-panel" data-panel="5">

                <div class="c-sliding-panels__content">
                    <h2 class="c-sliding-panels__title u-section-intro">Company Information</h2>

                    <div class="c-sliding-panels__body js-sliding-panel-body">

                        <p>Praesent sapien massa, convallis a pellentesque nec, egestas non nisi. Mauris blandit aliquet elit, eget tincidunt nibh pulvinar a. Curabitur non nulla sit amet nisl tempus convallis quis ac lectus. Curabitur arcu erat, accumsan id imperdiet et, porttitor at sem. Cras ultricies ligula sed magna dictum porta.</p>

                        <p>Proin eget tortor risus. Vivamus suscipit tortor eget felis porttitor volutpat. Praesent sapien massa, convallis a pellentesque nec, egestas non nisi. Curabitur arcu erat, accumsan id imperdiet et, porttitor at sem. Nulla quis lorem ut libero malesuada feugiat.</p>

                        <p>Vivamus magna justo, lacinia eget consectetur sed, convallis at tellus. Nulla quis lorem ut libero malesuada feugiat. Donec rutrum congue leo eget malesuada. Nulla quis lorem ut libero malesuada feugiat. Sed porttitor lectus nibh.</p>

                        <p>Curabitur arcu erat, accumsan id imperdiet et, porttitor at sem. Curabitur aliquet quam id dui posuere blandit. Vestibulum ac diam sit amet quam vehicula elementum sed sit amet dui. Nulla quis lorem ut libero malesuada feugiat. Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>

                        <p>Vestibulum ac diam sit amet quam vehicula elementum sed sit amet dui. Vestibulum ac diam sit amet quam vehicula elementum sed sit amet dui. Vivamus suscipit tortor eget felis porttitor volutpat. Curabitur non nulla sit amet nisl tempus convallis quis ac lectus. Mauris blandit aliquet elit, eget tincidunt nibh pulvinar a.</p>

                        <p>Cras ultricies ligula sed magna dictum porta. Vestibulum ac diam sit amet quam vehicula elementum sed sit amet dui. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Donec velit neque, auctor sit amet aliquam vel, ullamcorper sit amet ligula. Donec sollicitudin molestie malesuada. Proin eget tortor risus.</p>

                    </div>

                </div>

            </div>

            <div class="c-sliding-panels__panel js-sliding-panel" data-panel="6">

                <div class="c-sliding-panels__content">
                    <h2 class="c-sliding-panels__title u-section-intro">Salary Information</h2>

                    <div class="c-sliding-panels__body js-sliding-panel-body">

                        <p>Vivamus suscipit tortor eget felis porttitor volutpat. Curabitur non nulla sit amet nisl tempus convallis quis ac lectus. Pellentesque in ipsum id orci porta dapibus. Cras ultricies ligula sed magna dictum porta. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Donec velit neque, auctor sit amet aliquam vel, ullamcorper sit amet ligula.</p>

                        <p>Curabitur arcu erat, accumsan id imperdiet et, porttitor at sem. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur aliquet quam id dui posuere blandit. Curabitur arcu erat, accumsan id imperdiet et, porttitor at sem. Nulla quis lorem ut libero malesuada feugiat.</p>

                        <p>Donec rutrum congue leo eget malesuada. Vivamus magna justo, lacinia eget consectetur sed, convallis at tellus. Curabitur non nulla sit amet nisl tempus convallis quis ac lectus. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum ac diam sit amet quam vehicula elementum sed sit amet dui.</p>

                    </div>

                </div>

            </div>

        </div>

    </div>

    <div class="c-sliding-panels__content" data-controls="progress">
        <button class="c-btn c-btn--2 c-btn--full-width c-btn--raised u-display-after-m js-slide-to-panel u-margin-bottom-l" data-slide-to-panel="next">Next</button>

        <div class="c-sliding-panels__nav js-slide-nav">

            <button class="c-sliding-panels__nav-item js-slide-to-panel" data-slide-to-panel="4">Panel 1</button>

            <button class="c-sliding-panels__nav-item js-slide-to-panel" data-slide-to-panel="5">Panel 2</button>

            <button class="c-sliding-panels__nav-item js-slide-to-panel" data-slide-to-panel="6">Panel 3</button>

        </div>

    </div>

</section>

<div class="c-sliding-panels__content u-display-until-m u-margin-top-m" data-controls="progress">
    <button class="c-btn c-btn--2 c-btn--full-width c-btn--raised js-slide-to-panel" data-slide-to-panel="next">Next</button>
</div>
```
*/
.c-social-link {
  width: 44px;
  height: 44px;
  margin-right: 8px;
  display: inline-block;
  text-align: center;
  color: #ffffff;
  font-size: 22px;
  line-height: 44px;
  background-color: #000000;
  border-radius: 50%;
  transition: background .2s ease-in-out, color .2s ease-in-out; }
  .c-social-link:last-child {
    margin-right: 0; }
  .c-social-link--white-circle {
    color: #f2663b;
    background-color: #ffffff; }
    .c-social-link--white-circle:hover, .c-social-link--white-circle:focus, .c-social-link--white-circle:active {
      color: #000000; }
  .c-social-link:hover, .c-social-link:focus, .c-social-link:active {
    color: #ffffff;
    background-color: #f2663b; }
  @media (min-width: 980px) {
    .c-social-link {
      width: 53px;
      height: 53px;
      font-size: 24px;
      line-height: 53px; } }

.c-social-share {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap; }
  .c-social-share__card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 12px 16px;
    background: #fcded5;
    border-radius: 16px;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.21); }
  .c-social-share__text {
    padding: 0;
    border: 0;
    background: none;
    max-width: none;
    outline: none;
    flex-grow: 1; }
  .c-social-share__link {
    display: none; }
  .c-social-share__button {
    background: none;
    padding: 0;
    font-size: 24px;
    line-height: 1; }
    .c-social-share__button:focus {
      outline: 1px dotted #000000; }
  .c-social-share__alert {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: "futura-pt", sans-serif;
    font-weight: 700;
    line-height: 30px; }
  .c-social-share__footer {
    display: none; }
    .c-social-share__footer [class*="u-after-icon"] {
      font-size: 24px; }
  .c-social-share [class*="u-after-icon"] {
    color: #000000; }
  @media (min-width: 980px) {
    .c-social-share__card {
      margin-bottom: 16px;
      padding: 16px 24px; }
    .c-social-share__text {
      font-size: 18px;
      line-height: 1; }
    .c-social-share__link {
      display: inline-block; }
    .c-social-share__button {
      display: none; }
    .c-social-share__footer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 40%;
      margin-right: 16px; }
    .c-social-share__footer-heading {
      margin: 0; } }

/*doc
---
title: social share
name: social-share
category: Components
---
This is a Vue component. It can be included on pages using <code>\<social-share url="https://uaccount.uk/apply/W3W3W3"\>\</social-share\></code>.
The input element must be used to allow copying the link on older browsers that don't support the clipboard API.

```html_example_table
<div class="c-social-share">
    <div class="c-social-share__card">
        <label for="urlInput" class="u-visually-hidden">https://uaccount.uk/apply/W3W3W3</label>
        <input id="urlInput" readonly="readonly" tabindex="-1" class="c-social-share__text" value="https://uaccount.uk/apply/W3W3W3">
        <a href="https://uaccount.uk/apply/W3W3W3" class="c-btn-tertiary c-btn-tertiary--margin-0 c-social-share__link">Copy link</a>
        <a href="https://uaccount.uk/apply/W3W3W3" class="c-social-share__button">
            <span class="u-after-icon-copy">
                <span class="u-visually-hidden">Copy</span>
            </span>
        </a>
    </div>
    <div class="c-social-share__footer">
        <p class="c-social-share__footer-heading">Share on:</p>
        <a rel="nofollow" href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fuaccount.uk%2Fapply%2FW3W3W3" class="u-after-icon-facebook u-color-facebook">
            <span class="u-visually-hidden">Facebook</span>
        </a>
        <a rel="nofollow" href="https://twitter.com/intent/tweet?url=https%3A%2F%2Fuaccount.uk%2Fapply%2FW3W3W3&amp;text=text" class="u-after-icon-twitter u-color-twitter">
            <span class="u-visually-hidden">Twitter</span>
        </a>
        <a href="mailto:example@example.com" class="u-after-icon-mail">
            <span class="u-visually-hidden">email</span>
        </a>
    </div>
</div>
```
*/
/*------------------------------------*\
    tab system
\*------------------------------------*/
.c-tab-system {
  margin-bottom: 24px; }
  .c-tab-system__tab-nav {
    display: flex;
    flex-direction: row; }
  .c-tab-system__tab {
    font-family: "futura-pt", sans-serif;
    font-weight: 700;
    font-size: 16px;
    line-height: 1.3;
    height: auto;
    min-height: 110px;
    margin: 0 12px 0 0;
    padding: 20px 0;
    position: relative;
    text-align: center;
    color: #000000;
    background: #f1f1f1;
    border-width: 2px;
    border-style: solid;
    border-color: #f1f1f1 #f1f1f1 #d1d3d4;
    border-bottom: 0;
    flex-grow: 1;
    z-index: 1; }
    .c-tab-system__tab:after {
      content: '';
      height: 2px;
      width: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
      background-color: #e8e8e8; }
    .c-tab-system__tab:last-of-type {
      margin: 0; }
    .c-tab-system__tab:hover, .c-tab-system__tab:focus {
      background: #f8f8f8;
      color: #000000;
      border-color: #d1d3d4; }
    .c-tab-system__tab.is-active {
      background: #ffffff;
      color: #000000;
      border-color: #d1d3d4;
      z-index: 2; }
      .c-tab-system__tab.is-active:after {
        bottom: -2px;
        background-color: #ffffff; }
      .c-tab-system__tab.is-active:hover, .c-tab-system__tab.is-active:focus {
        background: #ffffff;
        cursor: default; }
  .c-tab-system__accordion-btn {
    font-family: "futura-pt", sans-serif;
    font-weight: 700;
    display: inline-block;
    padding: 8px 20px;
    text-decoration: none;
    text-align: center;
    margin-bottom: 16px;
    transition-property: background-color, color, transform, box-shadow, border-color;
    transition-duration: .2s, .2s, .2s, .2s;
    transition-timing-function: ease-in-out, ease-in-out, ease-in-out, ease-in-out;
    color: #ffffff;
    display: block;
    width: 100%;
    height: auto;
    margin: 0;
    position: relative;
    background: #000000;
    border-top: 2px solid #ffffff; }
    .c-tab-system__accordion-btn:hover {
      background: #000000; }
    .c-tab-system__accordion-btn:after {
      font-family: 'ffreesicons', sans-serif;
      font-weight: 500;
      font-style: normal;
      transition: rotate .3s linear;
      content: "";
      padding: 0 8px;
      margin: 0;
      position: absolute;
      right: 0; }
    .c-tab-system__accordion-btn:first-child {
      border-top: 0; }
    .c-tab-system__accordion-btn.is-active {
      background: #f2663b; }
      .c-tab-system__accordion-btn.is-active:after {
        transform: rotate(180deg); }
  .c-tab-system__accordion-content-holder {
    transition: height .83s ease-in-out;
    width: 100%;
    height: 0;
    overflow: hidden;
    position: relative; }
  .c-tab-system__tab-icon {
    font-size: 18px;
    line-height: 24px;
    display: block;
    margin-bottom: 8px; }
  .c-tab-system__tab-content-wrap {
    background: #ffffff; }
  .c-tab-system__tab-content {
    width: 100%;
    margin-top: -2px;
    padding: 12px;
    display: block;
    left: 0;
    border: 2px solid #d1d3d4;
    z-index: 1;
    display: none;
    position: absolute;
    display: block; }
    .c-tab-system__tab-content.is-active {
      display: block; }
  .c-tab-system__tab-nav {
    display: none; }
  @media (min-width: 600px) {
    .c-tab-system__tab {
      font-size: 16px;
      line-height: 1.3; }
    .c-tab-system__accordion-btn {
      display: none; }
    .c-tab-system__accordion-content-holder {
      height: auto;
      overflow: visible; }
    .c-tab-system__tab-nav {
      display: flex;
      flex-direction: row; }
    .c-tab-system__tab-nav-inner {
      display: flex;
      flex-direction: row; }
    .c-tab-system__tab-content {
      padding: 28px;
      border: 2px solid #d1d3d4;
      position: relative;
      display: none; }
      .c-tab-system__tab-content.is-active {
        display: block; }
    .c-tab-system__accordion-btn {
      display: none; } }

/*doc
---
title: Tab system
name: tab-system
category: Components
---

When using multiple tab systems on a page, use unique id, data-hash and data-tab/data-tab-content (do not use an id that contains another id as part of it e.g. 'tab-system' and 'tab-system2'). <br>
The <code>c-tab-system__tab-icon</code> element is optional and can be removed.

```html_example
<div data-parent-classes="u-sg-vlightgrey-bg" id="tab-one" class="c-tab-system js-tab-wrapper">
    <nav class="c-tab-system__tab-nav js-tab-nav">
        <button class="c-tab-system__tab js-function-btn" data-hash="tab-name-1" data-tab="1">
            <span class="c-tab-system__tab-icon u-before-icon-clock"></span> Future
            <br> Payments
        </button>
        <button class="c-tab-system__tab js-function-btn" data-hash="tab-name-2" data-tab="2">
            <span class="c-tab-system__tab-icon u-before-icon-direct-debit"></span> Direct
            <br> Debits
        </button>
        <button class="c-tab-system__tab js-function-btn" data-hash="tab-name-3" data-tab="3">
            <span class="c-tab-system__tab-icon u-before-icon-user-add"></span> Saved
            <br> Recipients
        </button>
    </nav>
    <div class="c-tab-system__tab-content-wrap">
        <button class="c-tab-system__accordion-btn js-function-btn" data-hash="tab-name-1" data-tab="1">Future Payments</button>
        <div class="c-tab-system__accordion-content-holder js-accordion-holder">
            <div class="c-tab-system__tab-content js-tab-content" data-tab-content="1">
                Pellentesque in ipsum id orci porta dapibus. Cras ultricies ligula sed magna dictum porta. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Donec velit neque, auctor sit amet aliquam vel, ullamcorper sit amet ligula.
            </div>
        </div>
        <button class="c-tab-system__accordion-btn js-function-btn" data-hash="tab-name-2" data-tab="2">Direct Debits</button>
        <div class="c-tab-system__accordion-content-holder js-accordion-holder">
            <div class="c-tab-system__tab-content js-tab-content" data-tab-content="2">
                quidem omnis nostrum reprehenderit nam quo, sit corporis provident facilis, doloremque repellat adipisci soluta. Aspernatur hic in, recusandae minus.
            </div>
        </div>
        <button class="c-tab-system__accordion-btn js-function-btn" data-hash="tab-name-3" data-tab="3">Saved Recipients</button>
        <div class="c-tab-system__accordion-content-holder js-accordion-holder">
            <div class="c-tab-system__tab-content js-tab-content" data-tab-content="3">
                doloremque repellat adipisci soluta. Aspernatur hic in, recusandae minus.
            </div>
        </div>
    </div>
</div>
```

*/
/*------------------------------------*\
    text hint
\*------------------------------------*/
.c-text-hint {
  background: #f1f1f1;
  padding: 12px;
  margin-bottom: 16px;
  position: relative;
  text-align: center; }
  .c-text-hint *:last-child {
    margin-bottom: 0; }
  .c-text-hint:before {
    font-family: 'ffreesicons', sans-serif;
    font-weight: 500;
    font-style: normal;
    display: block;
    margin-bottom: 8px; }
  .c-text-hint--success {
    background: #6eb52b;
    color: #ffffff; }
    .c-text-hint--success .c-text-hint__link {
      color: #ffffff; }
  .c-text-hint--success-icon:before {
    content: ""; }
  .c-text-hint--info {
    background: #005EB0;
    color: #ffffff; }
    .c-text-hint--info .c-text-hint__link {
      color: #ffffff; }
  .c-text-hint--info-icon:before {
    content: ""; }
  .c-text-hint--warning {
    background: #ffb20a;
    color: #000000; }
    .c-text-hint--warning .c-text-hint__link {
      color: #000000; }
  .c-text-hint--warning-icon:before {
    content: ""; }
  .c-text-hint--error {
    background: #d31a35;
    color: #ffffff; }
    .c-text-hint--error .c-text-hint__link {
      color: #ffffff; }
  .c-text-hint--error-icon:before {
    content: ""; }
  .c-text-hint--bold-message {
    font-family: "futura-pt", sans-serif;
    font-weight: 700;
    text-transform: uppercase; }
  .c-text-hint--inverse {
    text-align: left;
    background: none;
    color: inherit;
    padding: 0;
    padding-left: 12px; }
    .c-text-hint--inverse:before {
      top: 0;
      left: 0; }
    .c-text-hint--inverse[class*="-icon"] {
      padding-left: 36px; }
      .c-text-hint--inverse[class*="-icon"]:after {
        left: 26px; }
    .c-text-hint--inverse:before {
      position: absolute;
      font-size: 18px; }
    .c-text-hint--inverse:after {
      content: '';
      position: absolute;
      transform: translate3d(0, -50%, 0);
      top: 50%;
      left: 0;
      width: 2px;
      border-radius: 1px;
      height: calc(100% - 12px);
      min-height: 22px;
      background-color: #d1d3d4; }
    .c-text-hint--inverse.c-text-hint--success:before {
      color: #6eb52b; }
    .c-text-hint--inverse.c-text-hint--success:after {
      background-color: #6eb52b; }
    .c-text-hint--inverse.c-text-hint--info:before {
      color: #005EB0; }
    .c-text-hint--inverse.c-text-hint--info:after {
      background-color: #005EB0; }
    .c-text-hint--inverse.c-text-hint--warning:before {
      color: #ffb20a; }
    .c-text-hint--inverse.c-text-hint--warning:after {
      background-color: #ffb20a; }
    .c-text-hint--inverse.c-text-hint--error:before {
      color: #d31a35; }
    .c-text-hint--inverse.c-text-hint--error:after {
      background-color: #d31a35; }
  .c-text-hint--full-width {
    width: 100%; }
  @media (min-width: 720px) {
    .c-text-hint {
      text-align: left; }
      .c-text-hint:before {
        position: absolute;
        top: 50%;
        left: 15px;
        transform: translateY(-50%);
        margin-bottom: 0; }
      .c-text-hint[class*="-icon"] {
        padding-left: 40px; }
      .c-text-hint--inverse:before {
        top: 0;
        left: 0;
        transform: none; } }
  @media (min-width: 900px) {
    .c-text-hint {
      padding: 16px 20px; }
      .c-text-hint--inverse {
        padding: 0;
        padding-left: 12px; }
        .c-text-hint--inverse:before {
          top: 0;
          left: 0; }
        .c-text-hint--inverse[class*="-icon"] {
          padding-left: 36px; }
          .c-text-hint--inverse[class*="-icon"]:after {
            left: 26px; } }

/*doc
---
title: Text hint
name: texthint
category: Components
---
```html_example_table
<div class="c-text-hint">
    <p>Make sure this is memorable - you’ll need it to make payments and account changes.</p>
</div>

<div class="c-text-hint c-text-hint--success">
    <p>Make sure this is memorable - you’ll need it to make payments and account changes.</p>
</div>

<div class="c-text-hint c-text-hint--success c-text-hint--success-icon">
    <p>Make sure this is memorable - you’ll need it to make payments and account changes.</p>
</div>

<div class="c-text-hint c-text-hint--info">
    <p>Make sure this is memorable - you’ll need it to make payments and account changes.</p>
</div>

<div class="c-text-hint c-text-hint--info c-text-hint--info-icon">
    <p>Make sure this is memorable - you’ll need it to make payments and account changes.</p>
</div>

<div class="c-text-hint c-text-hint--warning">
    <p>Make sure this is memorable - you’ll need it to make payments and account changes.</p>
</div>

<div class="c-text-hint c-text-hint--warning c-text-hint--warning-icon">
    <p>Make sure this is memorable - you’ll need it to make payments and account changes.</p>
</div>

<div class="c-text-hint c-text-hint--error">
    <p>Make sure this is memorable - you’ll need it to make payments and account changes.</p>
</div>

<div class="c-text-hint c-text-hint--error c-text-hint--error-icon">
    <p>Make sure this is memorable - you’ll need it to make payments and account changes.</p>
</div>
```
<div class="c-sg-modifier-wrap js-modifier-wrap">
    <div>
        <button>c-text-hint--bold-message</button>
    </div>
    <div>
        <button>c-text-hint--inverse</button>
    </div>
    <div>
        <button>c-text-hint--full-width</button>
    </div>
</div>
*/
.c-text-link {
  transition: color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
  display: inline;
  text-decoration: underline;
  text-decoration-skip: ink; }
  .c-text-link__icon::before, .c-text-link__icon::after {
    text-decoration: underline; }
  .c-text-link__icon--separate::before {
    text-decoration: none;
    margin-right: 8px; }
  .c-text-link__icon--separate::after {
    text-decoration: none;
    margin-left: 8px; }
  .c-text-link:hover, .c-text-link:focus, .c-text-link:active, .c-text-link.is-active {
    color: #ea4310; }
  .c-text-link--blob {
    background: #fcded5;
    text-decoration: none;
    font-family: "futura-pt", sans-serif;
    font-weight: 500;
    box-shadow: -6px 0 0 0 #fcded5, 6px 0 0 0 #fcded5, 0 0 0 1px #fcded5, 6px 0 0 1px #fcded5, -6px 0 0 1px #fcded5;
    border-radius: 24px;
    margin: 0 8px;
    box-decoration-break: clone;
    -webkit-box-decoration-break: clone;
    color: #000000; }
    .c-text-link--blob:hover, .c-text-link--blob:focus, .c-text-link--blob:active, .c-text-link--blob.is-active {
      box-shadow: -6px 0 0 0 #fcded5, 6px 0 0 0 #fcded5, 0 0 0 1px #f2663b, 6px 0 0 1px #f2663b, -6px 0 0 1px #f2663b;
      color: #000000; }
  .c-text-link--black {
    color: #000000; }
    .c-text-link--black:hover, .c-text-link--black:focus, .c-text-link--black:active, .c-text-link--black.is-active, .c-text-link--black.is-selected-desktop {
      color: #f2663b; }

/*doc
---
title: Text Link
name: text-link
category: Components
---

```html_example

Nulla porttitor <a class="c-text-link">Text Link</a> accumsan tincidunt.
<br/>
Magna exercitation et elit <a class="c-text-link">Text Link <span class="c-text-link__icon u-after-icon-pencil" aria-hidden="true"></span></a> consequat nulla cupidatat ad sunt do.
```
<div class="c-sg-modifier-wrap js-modifier-wrap">
    <div>
        <button>is-active</button>
    </div>
    <div>
        <button>c-text-link--blob</button>
        <button>c-text-link--black</button>
    </div>
    <div>
        <button data-target="c-text-link__icon">c-text-link__icon--separate</button>
    </div>
</div>

*/
/*------------------------------------*\
    thumbnail
\*------------------------------------*/
.c-thumbnail {
  display: flex;
  flex-direction: column;
  margin: 0 0 12px;
  overflow: hidden;
  padding: 0;
  width: 210px; }
  .c-thumbnail__file-name {
    font-family: "futura-pt", sans-serif;
    font-weight: 500;
    display: block;
    overflow: hidden;
    padding: 0;
    margin-bottom: 8px; }
  .c-thumbnail__image-thumbnail {
    position: relative;
    width: 100%;
    height: 140px;
    border: solid 1px #d1d3d4;
    margin-top: auto;
    display: inline-block;
    vertical-align: bottom; }
  .c-thumbnail__image {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    max-width: 100%;
    max-height: 140px;
    width: auto;
    height: auto;
    margin: 0;
    padding: 1px; }
  @media (min-width: 980px) {
    .c-thumbnail {
      margin-left: 0;
      margin-right: 12px; } }

.c-thumbnail-wrap {
  margin: auto;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap; }
  .c-thumbnail-wrap .c-thumbnail {
    flex: 0 1 auto;
    width: calc(100% - 30px); }
  @media (min-width: 540px) {
    .c-thumbnail-wrap .c-thumbnail {
      width: calc(50% - 15px); } }
  @media (min-width: 800px) {
    .c-thumbnail-wrap .c-thumbnail {
      width: calc(33.3% - 19.8px); } }
  @media (min-width: 980px) {
    .c-thumbnail-wrap .c-thumbnail {
      width: calc(100% - 60px); } }
  @media (min-width: 1080px) {
    .c-thumbnail-wrap .c-thumbnail {
      width: calc(50% - 15px); } }
  @media (min-width: 1200px) {
    .c-thumbnail-wrap .c-thumbnail {
      width: calc(33.3% - 19.8px); } }

/*doc
---
title: thumbnail
name: thumbnail
category: Components
---

```html_example
<div class="c-thumbnail-wrap">

    <div class="c-thumbnail">
        <span class="c-thumbnail__file-name">
            u-logo-orange.png
        </span>
        <div class="c-thumbnail__image-thumbnail">
            <img class="c-thumbnail__image" src="img/u-logo-orange.png">
        </div>
    </div>

    <div class="c-thumbnail">
        <span class="c-thumbnail__file-name">
            u-logo-orange.png
        </span>
        <div class="c-thumbnail__image-thumbnail">
            <img class="c-thumbnail__image" src="img/u-logo-orange.png">
        </div>
    </div>

    <div class="c-thumbnail">
        <span class="c-thumbnail__file-name">
            u-logo-orange.png
        </span>
        <div class="c-thumbnail__image-thumbnail">
            <img class="c-thumbnail__image" src="img/u-logo-orange.png">
        </div>
    </div>

    <div class="c-thumbnail">
        <span class="c-thumbnail__file-name">
            u-logo-orange.png
        </span>
        <div class="c-thumbnail__image-thumbnail">
            <img class="c-thumbnail__image" src="img/u-logo-orange.png">
        </div>
    </div>

</div>

```
*/
/*------------------------------------*\
    title block
\*------------------------------------*/
.c-title-block {
  font-size: 14px;
  line-height: 22px;
  padding: 12px;
  margin-bottom: 24px;
  background: #f1f1f1; }
  @media (min-width: 980px) {
    .c-title-block {
      font-size: 16px;
      line-height: 26px; } }
  .c-title-block__title {
    font-size: 14px;
    line-height: 22px;
    font-family: "futura-pt", sans-serif;
    font-weight: 500; }
    @media (min-width: 980px) {
      .c-title-block__title {
        font-size: 16px;
        line-height: 26px; } }
  .c-title-block *:last-child {
    margin-bottom: 0; }
  .c-title-block--info {
    color: #005EB0;
    background: #e8f1f8; }
  .c-title-block--margin-bottom-0 {
    margin-bottom: 0; }

/*doc
---
title: Title block
name: title-block
category: Components
---

Note you may prefer to use a different heading tag for SEO purposes.

```html_example
<div class="c-title-block">
    <h3 class="c-title-block__title">Legal information</h3>
</div>
```
<div class="c-sg-modifier-wrap js-modifier-wrap">
    <div>
        <button>c-title-block--info</button>
    </div>
    <div>
        <button>c-title-block--margin-bottom-0</button>
    </div>
</div>

*/
/*------------------------------------*\ 
    toggle buttons    
\*------------------------------------*/
.c-toggle-buttons {
  padding: 5px 3px;
  border: 2px solid #d1d3d4;
  background: #f1f1f1;
  display: flex;
  margin-bottom: 24px; }
  .c-toggle-buttons__button {
    font-family: "futura-pt", sans-serif;
    font-weight: 700;
    display: inline-block;
    padding: 8px 20px;
    text-decoration: none;
    text-align: center;
    margin-bottom: 16px;
    transition-property: background-color, color, transform, box-shadow, border-color;
    transition-duration: .2s, .2s, .2s, .2s;
    transition-timing-function: ease-in-out, ease-in-out, ease-in-out, ease-in-out;
    font-family: "futura-pt", sans-serif;
    font-weight: 700;
    font-size: 10px;
    line-height: 1.5;
    flex: 1;
    padding: 5px;
    color: #000000;
    background: transparent;
    margin: 0;
    height: auto;
    max-width: none;
    margin: 0 2px; }
    .c-toggle-buttons__button.is-active, .c-toggle-buttons__button:hover {
      color: white;
      background: #f2663b; }
  @media (min-width: 600px) {
    .c-toggle-buttons__button {
      font-size: 16px;
      line-height: 1.5;
      padding: 8px 20px; } }

/*doc
---
title: Toggle Buttons
name: toggle-buttons
category: Buttons
---

```html_example
<div class="c-toggle-buttons js-toggle-buttons">
    <button type="button" class="c-toggle-buttons__button">Money in</button>
    <button type="button" class="c-toggle-buttons__button">Money out</button>
    <button type="button" class="c-toggle-buttons__button">All</button>
</div>

```

*/
/*------------------------------------*\
    toggle content
\*------------------------------------*/
.c-toggle-content {
  margin-bottom: 24px;
  position: relative; }
  .c-toggle-content__content {
    height: 0;
    transition: height .3s ease-in-out;
    overflow: hidden; }
  @media (min-width: 980px) {
    .c-toggle-content--show-content-on-desktop .c-toggle-content__content {
      height: auto; } }

/*doc
---
title: toggle content
name: toggle-content
category: Components
---

If this component is to be expanded by default on page load, add <code>checked="checked"</code> to the input.

```html_example
<div class="c-toggle-content">
    <div class="c-toggle-switch c-toggle-switch--expanding-content-layout">
        <input id="signup" type="checkbox" class="c-toggle-switch__input js-toggle-content">
        <label for="signup" class="c-toggle-switch__label">
            <span class="c-toggle-switch__text">
                Types of notification
            </span>
            <span class="c-toggle-switch__label-inner"></span>
        </label>
    </div>
    <div class="c-toggle-content__content js-toggle-content-target">
        <div>
            <p class="u-color-5 u-font-heavy"><span class="u-before-icon-info-fill"></span> Lorem ipsum dolor sit amet, consectetur adipisicing elit. Pariatur libero, perspiciatis rem aspernatur amet eius labore? Perferendis nesciunt, ab temporibus beatae officiis, dolorum debitis eveniet. Hic dolor culpa vero mollitia.</p>
            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Esse praesentium molestias reiciendis sint, sapiente voluptate accusamus dolorum earum eos, nostrum possimus quis nulla ullam quasi, deleniti nihil inventore porro cum!</p>
        </div>
    </div>
</div>

```
<div class="c-sg-modifier-wrap js-modifier-wrap">
    <div>
        <button>c-toggle-content--show-content-on-desktop</button>
    </div>
    <div>
        <button data-target="c-toggle-switch">c-toggle-switch--hide-toggle-on-desktop</button>
    </div>
</div>

To have the content shown on desktop, and the toggle hidden, give the nested child component <code>c-toggle-switch</code> the modifier <code>c-toggle-switch--hide-toggle-on-desktop</code> and the parent <code>c-toggle-content</code> the modifier of <code>c-toggle-content--show-content-on-desktop</code>:

```html_example
<div class="c-toggle-content c-toggle-content--show-content-on-desktop">
    <div class="c-toggle-switch c-toggle-switch--expanding-content-layout c-toggle-switch--hide-toggle-on-desktop">
        <input id="signup2" type="checkbox" class="c-toggle-switch__input js-toggle-content">
        <label for="signup2" class="c-toggle-switch__label">
            <span class="c-toggle-switch__text">
                Types of notification
            </span>
            <span class="c-toggle-switch__label-inner"></span>
        </label>
    </div>
    <div class="c-toggle-content__content js-toggle-content-target">
        <div>
            <p class="u-color-5 u-font-heavy"><span class="u-before-icon-info-fill"></span> Lorem ipsum dolor sit amet, consectetur adipisicing elit. Pariatur libero, perspiciatis rem aspernatur amet eius labore? Perferendis nesciunt, ab temporibus beatae officiis, dolorum debitis eveniet. Hic dolor culpa vero mollitia.</p>

            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Esse praesentium molestias reiciendis sint, sapiente voluptate accusamus dolorum earum eos, nostrum possimus quis nulla ullam quasi, deleniti nihil inventore porro cum!</p>
        </div>
    </div>
</div>

```
<div class="c-sg-modifier-wrap js-modifier-wrap">
    <div>
        <button>c-toggle-content--show-content-on-desktop</button>
    </div>
    <div>
        <button data-target="c-toggle-switch">c-toggle-switch--hide-toggle-on-desktop</button>
    </div>
</div>

*/
.c-toggle-option__input {
  margin: -1px;
  padding: 0;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0 0 0 0);
  clip: rect(0, 0, 0, 0);
  position: absolute; }

.c-toggle-option__indicator {
  font-family: "futura-pt", sans-serif;
  font-weight: 700;
  display: inline-block;
  padding: 8px 20px;
  text-decoration: none;
  text-align: center;
  margin-bottom: 16px;
  transition-property: background-color, color, transform, box-shadow, border-color;
  transition-duration: .2s, .2s, .2s, .2s;
  transition-timing-function: ease-in-out, ease-in-out, ease-in-out, ease-in-out;
  font-size: 16px;
  line-height: 24px;
  display: inline-block;
  padding-left: 24px;
  padding-right: 24px;
  border-radius: 24px;
  border: 1px solid #f2663b;
  background-color: #fef8f7;
  font-family: "futura-pt", sans-serif;
  font-weight: 500;
  color: #000000;
  box-shadow: 0px 2px 4px rgba(234, 67, 16, 0.21);
  animation: fadeInButtonShadow 200ms;
  min-width: 120px;
  cursor: pointer; }
  @media (min-width: 980px) {
    .c-toggle-option__indicator {
      font-size: 20px;
      line-height: 30px; } }
  .c-toggle-option__indicator[class*="u-before-icon"]:before {
    margin-right: 8px; }
  .c-toggle-option__indicator[class*="u-after-icon"]:after {
    margin-left: 8px; }
  .c-toggle-option__indicator--full-width {
    width: 100%;
    max-width: none;
    display: block; }
  .c-toggle-option__indicator--icon-down:after {
    font-family: 'ffreesicons', sans-serif;
    font-weight: 500;
    font-style: normal;
    content: "";
    display: inline-block;
    margin-left: 8px; }
  .c-toggle-option__indicator--icon-next:after {
    font-family: 'ffreesicons', sans-serif;
    font-weight: 500;
    font-style: normal;
    content: "";
    display: inline-block;
    margin-left: 8px; }
  .c-toggle-option__indicator--float-right {
    float: right;
    margin-right: 0; }
  .c-toggle-option__indicator--small-header {
    padding: 8px 16px;
    font-size: 14px;
    line-height: 1.5; }
  .c-toggle-option__indicator--margin-right-s {
    margin-right: 16px; }
  .c-toggle-option__indicator--margin-bottom-s {
    margin-bottom: 16px; }
  .c-toggle-option__indicator--margin-0 {
    margin: 0; }
  .c-toggle-option__indicator--margin-bottom-0 {
    margin-bottom: 0; }
  .c-toggle-option__indicator--more-padding {
    padding: 20px; }
  .c-toggle-option__indicator--nowrap {
    white-space: nowrap; }
  .c-toggle-option__indicator--text-align-left {
    text-align: left; }
  .c-toggle-option__indicator--icon-only[class*="u-before-icon"]:before {
    margin-right: 0; }
  .c-toggle-option__indicator--icon-only[class*="u-after-icon"]:after {
    margin-left: 0; }
  .c-toggle-option__indicator--plus-minus-icon {
    position: relative; }
    .c-toggle-option__indicator--plus-minus-icon:before, .c-toggle-option__indicator--plus-minus-icon:after {
      position: absolute;
      content: '';
      background-color: currentColor;
      display: block; }
    .c-toggle-option__indicator--plus-minus-icon:before {
      width: 15px;
      height: 3px;
      top: calc(50% - 1px);
      right: 20px; }
    .c-toggle-option__indicator--plus-minus-icon:after {
      width: 3px;
      height: 15px;
      top: calc(50% - 7px);
      right: 26px;
      transition: transform 0.3s ease-in-out; }
    .c-toggle-option__indicator--plus-minus-icon.is-active:after {
      transform: rotate(90deg); }
  .c-toggle-option__indicator--large {
    font-size: 24px;
    line-height: 31px;
    padding: 16px 48px; }
    @media (min-width: 980px) {
      .c-toggle-option__indicator--large {
        font-size: 36px;
        line-height: 46px; } }
  .c-toggle-option__indicator--raised {
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.21); }
  .c-toggle-option__indicator.is-disabled {
    opacity: .4;
    cursor: not-allowed; }
  @media (max-width: 540px) {
    .c-toggle-option__indicator--mobile-full-width {
      width: 100%;
      max-width: none;
      display: block; } }
  @media (min-width: 540px) {
    .c-toggle-option__indicator--margin-bottom-0-xs {
      margin-bottom: 0; } }
  @media (min-width: 800px) {
    .c-toggle-option__indicator--more-padding {
      padding: 20px 36px; } }
  @media (min-width: 980px) {
    .c-toggle-option__indicator--icon-next:after {
      content: ""; } }
  .c-toggle-option__indicator:hover, .c-toggle-option__indicator:focus, .c-toggle-option__indicator:active {
    color: #000000;
    box-shadow: inset 0px 2px 4px rgba(234, 67, 16, 0.21);
    animation: fadeOutButtonShadow 200ms; }
  .c-toggle-option__indicator.is-valid {
    border-color: #6eb52b;
    background-color: #eff9e6;
    box-shadow: 0px 2px 4px rgba(85, 140, 33, 0.21);
    animation: fadeInButtonShadowValid 200ms; }
    .c-toggle-option__indicator.is-valid:hover, .c-toggle-option__indicator.is-valid:focus, .c-toggle-option__indicator.is-valid:active {
      box-shadow: inset 0px 2px 4px rgba(85, 140, 33, 0.21);
      animation: fadeOutButtonShadowValid 200ms; }
  .c-toggle-option__indicator.is-selected {
    background-color: #fcded5; }
  .c-toggle-option__indicator.is-deselected {
    background-color: white;
    border-color: #afafaf;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.21);
    animation: fadeInButtonShadowDeselected 200ms; }
    .c-toggle-option__indicator.is-deselected:hover, .c-toggle-option__indicator.is-deselected:focus, .c-toggle-option__indicator.is-deselected:active {
      box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.21);
      animation: fadeOutButtonShadowDeselected 200ms; }
  .c-toggle-option__indicator.is-disabled {
    background-color: white;
    border-color: #afafaf;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.21);
    opacity: 1;
    animation: none; }

.c-toggle-option__input:focus + .c-toggle-option__indicator {
  box-shadow: inset 0px 2px 4px rgba(234, 67, 16, 0.21);
  animation: fadeOutButtonShadow 200ms; }
  .c-toggle-option__input:focus + .c-toggle-option__indicator.is-deselected {
    box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.21);
    animation: fadeOutButtonShadowDeselected 200ms; }

.c-toggle-option__input:checked + .c-toggle-option__indicator {
  background-color: #fcded5; }

/*doc
---
title: toggle-option
name: input-toggle-option
category: Inputs
---

```html_example
<fieldset>
    <legend class="u-label">Where was the transaction made?</legend>
    <label class="c-toggle-option">
        <input type="radio" name="location" class="c-toggle-option__input">
        <div class="c-toggle-option__indicator">ATM</div>
    </label>
    <label class="c-toggle-option"><input type="radio" name="location" class="c-toggle-option__input">
        <div class="c-toggle-option__indicator">Point of sale / Online</div>
    </label>
</fieldset>
```
<div class="c-sg-modifier-wrap js-modifier-wrap">
    <div>
        <button data-target="c-toggle-option__indicator">is-selected</button>
        <button data-target="c-toggle-option__indicator">is-deselected</button>
        <button data-target="c-toggle-option__indicator">is-disabled</button>
    </div>
</div>
*/
/*------------------------------------*\
toggle switch
\*------------------------------------*/
/* box-shadow cannot be animated
box-shadow is prefereble over border as it does not affect the layout of its contents
box-shadow is animated by transitioning the opacity of a pseudo element with box-shadow
*/
.c-toggle-switch {
  margin-bottom: 16px;
  cursor: pointer;
  display: block;
  width: 100%;
  position: relative; }
  .c-toggle-switch:after {
    content: "";
    display: table;
    clear: both; }
  .c-toggle-switch__text {
    font-size: 14px;
    line-height: 1.71;
    font-family: "futura-pt", sans-serif;
    font-weight: 700;
    order: 2;
    color: #000000;
    display: block;
    border-radius: 0;
    background: none;
    padding-top: 6px;
    float: right;
    width: calc(100% - 64px);
    padding-left: 16px;
    margin: auto;
    margin-left: 0;
    flex: 1; }
  .c-toggle-switch__input {
    display: none; }
    .c-toggle-switch__input:checked + .c-toggle-switch__label .c-toggle-switch__label-inner:after {
      opacity: 1; }
    .c-toggle-switch__input:checked + .c-toggle-switch__label .c-toggle-switch__label-inner:before {
      transform: scale(0.8) translateX(32.5px) translateY(-50%);
      background: #f2663b; }
  .c-toggle-switch__label:after {
    content: "";
    display: table;
    clear: both; }
  .c-toggle-switch__label-inner {
    background-color: #ffffff;
    box-shadow: inset 0 0 0 1px #ccc;
    width: 64px;
    height: 38px;
    display: block;
    border-radius: 500px;
    position: relative;
    transition: border .3s ease-in-out;
    float: left; }
    .c-toggle-switch__label-inner:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      box-shadow: inset 0 0 0 1px #f2663b;
      width: 64px;
      height: 38px;
      display: block;
      border-radius: 500px;
      transition: transform .3s ease;
      transition-property: opacity; }
    .c-toggle-switch__label-inner:before {
      position: absolute;
      top: 50%;
      left: 0;
      content: '';
      display: block;
      width: 38px;
      height: 38px;
      background: #ccc;
      border-radius: 50%;
      transform: scale(0.8) translateY(-50%);
      transform-origin: 50% 0;
      transition: transform .3s ease;
      transition-property: transform, background, left; }
  .c-toggle-switch--expanding-content-layout {
    border-bottom: 1px solid #d1d3d4;
    padding-bottom: 8px;
    margin-bottom: 16px; }
    .c-toggle-switch--expanding-content-layout .c-toggle-switch__input:checked + .c-toggle-switch__label:after {
      content: 'Hide'; }
    .c-toggle-switch--expanding-content-layout .c-toggle-switch__label {
      display: flex;
      align-items: flex-end;
      min-height: 38px; }
    .c-toggle-switch--expanding-content-layout .c-toggle-switch__text {
      order: 1;
      align-self: center;
      padding: 0;
      margin: 0;
      line-height: 1.25; }
    .c-toggle-switch--expanding-content-layout .c-toggle-switch__label-inner {
      order: 3; }
    .c-toggle-switch--expanding-content-layout .c-toggle-switch__label:after {
      order: 2;
      line-height: 1;
      font-family: "futura-pt", sans-serif;
      font-weight: 500;
      color: #afafaf;
      font-size: 13px;
      content: 'Show';
      margin: 0 7px 13px 7px; }
  @media (min-width: 980px) {
    .c-toggle-switch--hide-toggle-on-desktop {
      cursor: default; }
      .c-toggle-switch--hide-toggle-on-desktop:before {
        content: '';
        background: transparent;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 5; }
      .c-toggle-switch--hide-toggle-on-desktop .c-toggle-switch__label-inner,
      .c-toggle-switch--hide-toggle-on-desktop .c-toggle-switch__label:after {
        display: none; } }

/*doc
---
title: toggle-switch
name: input-toggle-switch
category: Inputs
---

```html_example
<div class="c-toggle-switch">
    <input id="signup" type="checkbox" class="c-toggle-switch__input" />
    <label for="signup" class="c-toggle-switch__label">
        <span class="c-toggle-switch__text">Create standing order for this recipient</span>
        <span class="c-toggle-switch__label-inner">
        </span>
    </label>
</div>
```
<div class="c-sg-modifier-wrap js-modifier-wrap">
    <div>
        <button>c-toggle-switch--expanding-content-layout</button>
    </div>
    <div>
        <button>c-toggle-switch--hide-toggle-on-desktop</button>
    </div>
</div>

*/
.c-u-points-chart {
  overflow: hidden; }
  .c-u-points-chart__holder {
    display: flex;
    overflow: hidden; }
  .c-u-points-chart__points-paid {
    width: 40%;
    padding: 8px 0 0 16px;
    color: #ffffff;
    background-color: #000000; }
  .c-u-points-chart__sub-title {
    margin-bottom: 8px;
    font-size: 13px;
    text-align: left; }
  .c-u-points-chart__paid-value {
    color: #ffffff;
    margin: 0;
    font-size: 24px;
    text-align: left;
    display: inherit; }
  .c-u-points-chart__chart-holder {
    width: 26.6%;
    padding-top: 8px;
    margin-right: 0;
    position: relative;
    z-index: 5;
    overflow: visible;
    background-color: #ffffff;
    border-width: 1px 0;
    border-style: solid;
    border-color: #000000; }
  .c-u-points-chart__chart {
    margin: 0 auto 12px;
    display: block; }
  .c-u-points-chart__chart-values {
    width: 33.3%;
    padding: 8px 12px 0 8px;
    border-width: 1px 1px 1px 0;
    border-style: solid;
    border-color: #000000;
    text-align: center;
    background-color: #ffffff; }
  .c-u-points-chart__pending-points, .c-u-points-chart__confirmed-points {
    margin-bottom: 8px;
    font-size: 13px;
    line-height: 1.2;
    text-align: left; }
  .c-u-points-chart__pending-points {
    color: #afafaf; }
  .c-u-points-chart__confirmed-points {
    color: #6eb52b; }
  @media (min-width: 980px) {
    .c-u-points-chart__sub-title, .c-u-points-chart__pending-points, .c-u-points-chart__confirmed-points {
      padding-left: 0;
      padding-right: 16px; } }
  @media (min-width: 1080px) {
    .c-u-points-chart__sub-title, .c-u-points-chart__pending-points, .c-u-points-chart__confirmed-points {
      font-size: 11px; }
    .c-u-points-chart__points-paid, .c-u-points-chart__chart-holder, .c-u-points-chart__chart-values {
      padding-top: 16px; } }

/*doc
---
title: u points chart
name: u-points-chart
category: Components
---

Note - The points chart only appears when the graphs.js file is included, and it is not present in the styleguide

```html_example
<div class="c-u-points-chart">

    <div class="c-u-points-chart__holder">

        <div class="c-u-points-chart__points-paid">

            <p class="c-u-points-chart__sub-title">Total Points paid:</p>

            <p class="c-u-points-chart__paid-value">£0.00</p>

        </div>

        <div class="c-u-points-chart__chart-holder">

            <canvas class="c-u-points-chart__chart js-u-points-chart" width="65" height="65" data-green-value="0" data-gray-value="1" style="width: 65px; height: 65px;"></canvas>

        </div>

        <div class="c-u-points-chart__chart-values">

            <p class="c-u-points-chart__pending-points">Pending Points: 0.00</p>

            <p class="c-u-points-chart__confirmed-points">
                Confirmed Points to be paid on 20th Dec: 0.00
            </p>

        </div>

    </div>

</div>

```

*/
/*------------------------------------*\
    Utilities & Fallbacks
\*------------------------------------*/
/*------------------------------------*\
    Display Utilities
\*------------------------------------*/
.u-display-none {
  display: none; }

@media (min-width: 540px) {
  .u-display-none-xs {
    display: none !important; } }

@media (min-width: 800px) {
  .u-display-none-s.u-display-none-s {
    display: none !important; } }

@media (min-width: 980px) {
  .u-display-none-m.u-display-none-m.u-display-none-m {
    display: none !important; } }

@media (min-width: 1080px) {
  .u-display-none-l.u-display-none-l.u-display-none-l.u-display-none-l {
    display: none !important; } }

@media (min-width: 1200px) {
  .u-display-none-xl.u-display-none-xl.u-display-none-xl.u-display-none-xl.u-display-none-xl {
    display: none !important; } }

@media (min-width: 1440px) {
  .u-display-none-xxl.u-display-none-xxl.u-display-none-xxl.u-display-none-xxl.u-display-none-xxl.u-display-none-xxl {
    display: none !important; } }

.u-display-block {
  display: block; }

@media (min-width: 540px) {
  .u-display-block-xs {
    display: block !important; } }

@media (min-width: 800px) {
  .u-display-block-s.u-display-block-s {
    display: block !important; } }

@media (min-width: 980px) {
  .u-display-block-m.u-display-block-m.u-display-block-m {
    display: block !important; } }

@media (min-width: 1080px) {
  .u-display-block-l.u-display-block-l.u-display-block-l.u-display-block-l {
    display: block !important; } }

@media (min-width: 1200px) {
  .u-display-block-xl.u-display-block-xl.u-display-block-xl.u-display-block-xl.u-display-block-xl {
    display: block !important; } }

@media (min-width: 1440px) {
  .u-display-block-xxl.u-display-block-xxl.u-display-block-xxl.u-display-block-xxl.u-display-block-xxl.u-display-block-xxl {
    display: block !important; } }

.u-display-inline {
  display: inline; }

@media (min-width: 540px) {
  .u-display-inline-xs {
    display: inline !important; } }

@media (min-width: 800px) {
  .u-display-inline-s.u-display-inline-s {
    display: inline !important; } }

@media (min-width: 980px) {
  .u-display-inline-m.u-display-inline-m.u-display-inline-m {
    display: inline !important; } }

@media (min-width: 1080px) {
  .u-display-inline-l.u-display-inline-l.u-display-inline-l.u-display-inline-l {
    display: inline !important; } }

@media (min-width: 1200px) {
  .u-display-inline-xl.u-display-inline-xl.u-display-inline-xl.u-display-inline-xl.u-display-inline-xl {
    display: inline !important; } }

@media (min-width: 1440px) {
  .u-display-inline-xxl.u-display-inline-xxl.u-display-inline-xxl.u-display-inline-xxl.u-display-inline-xxl.u-display-inline-xxl {
    display: inline !important; } }

.u-display-inline-block {
  display: inline-block; }

.u-opacity-50 {
  opacity: 0.5; }

/* -- table ------------------------- */
.u-display-table {
  display: table; }

.u-display-table-row {
  display: table-row; }

.u-display-table-cell {
  padding-right: 8px;
  display: table-cell; }

/* -- Flex -------------------------- */
.u-display-flex {
  display: flex; }

@media (min-width: 540px) {
  .u-display-flex-xs {
    display: flex !important; } }

@media (min-width: 800px) {
  .u-display-flex-s.u-display-flex-s {
    display: flex !important; } }

@media (min-width: 980px) {
  .u-display-flex-m.u-display-flex-m.u-display-flex-m {
    display: flex !important; } }

@media (min-width: 1080px) {
  .u-display-flex-l.u-display-flex-l.u-display-flex-l.u-display-flex-l {
    display: flex !important; } }

@media (min-width: 1200px) {
  .u-display-flex-xl.u-display-flex-xl.u-display-flex-xl.u-display-flex-xl.u-display-flex-xl {
    display: flex !important; } }

@media (min-width: 1440px) {
  .u-display-flex-xxl.u-display-flex-xxl.u-display-flex-xxl.u-display-flex-xxl.u-display-flex-xxl.u-display-flex-xxl {
    display: flex !important; } }

.u-align-items-center {
  align-items: center; }

.u-align-items-stretch {
  align-items: stretch; }

.u-justify-content-center {
  justify-content: center; }

.u-justify-content-space-around {
  justify-content: space-around; }

.u-justify-content-space-between {
  justify-content: space-between; }

.u-flex-basis-auto {
  flex-basis: auto; }

.u-flex-basis-100 {
  flex-basis: 100%; }

.u-flex-wrap {
  flex-wrap: wrap; }

.u-flex-direction-column {
  flex-direction: column; }

.u-flex-grow-1 {
  flex-grow: 1; }

/* -- Responsive -------------------- */
@media (min-width: 540px) {
  .u-display-until-xs {
    display: none !important; } }

@media (min-width: 800px) {
  .u-display-until-s {
    display: none !important; } }

@media (min-width: 980px) {
  .u-display-until-m {
    display: none !important; } }

@media (min-width: 1080px) {
  .u-display-until-l {
    display: none !important; } }

@media (min-width: 1200px) {
  .u-display-until-xl {
    display: none !important; } }

@media (min-width: 1440px) {
  .u-display-until-xxl {
    display: none !important; } }

.u-display-after-xs {
  display: none !important; }
  @media (min-width: 540px) {
    .u-display-after-xs {
      display: block !important; } }

.u-display-after-s {
  display: none !important; }
  @media (min-width: 800px) {
    .u-display-after-s {
      display: block !important; } }

.u-display-after-m {
  display: none !important; }
  @media (min-width: 980px) {
    .u-display-after-m {
      display: block !important; } }

.u-display-after-l {
  display: none !important; }
  @media (min-width: 1080px) {
    .u-display-after-l {
      display: block !important; } }

.u-display-after-xl {
  display: none !important; }
  @media (min-width: 1200px) {
    .u-display-after-xl {
      display: block !important; } }

.u-display-after-xxl {
  display: none !important; }
  @media (min-width: 1440px) {
    .u-display-after-xxl {
      display: block !important; } }

/* -- Widths ------------------------ */
.u-max-width-xs {
  max-width: 540px; }

/*------------------------------------*\
    Typography Utilities
\*------------------------------------*/
.u-line-height-1 {
  line-height: 1; }

/* -- Fonts ------------------------- */
.u-u-font {
  font-family: 'u-font', sans-serif;
  font-weight: 300; }

.u-u-logo-font {
  font-family: 'uLogo', sans-serif;
  font-weight: 300; }

.u-ffrees-icons-font {
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal; }

/* -- Font Transform ---------------- */
.u-text-transform-none, .c-glossary__subtitle {
  text-transform: none !important; }

.u-text-transform-uppercase {
  text-transform: uppercase; }

.u-text-transform-capitalize {
  text-transform: capitalize; }

.u-text-transform-lowercase {
  text-transform: lowercase; }

.u-text-transform-none, .c-glossary__subtitle {
  text-transform: none !important; }

.u-first-letter-uppercase:first-letter {
  text-transform: uppercase; }

.u-text-decoration-underline {
  text-decoration: underline; }

.u-text-decoration-none {
  text-decoration: none; }

/* -- Font White Space -------------- */
.u-no-wrap {
  white-space: nowrap; }

.u-word-wrap {
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  word-break: break-all; }

/* -- Custom ------------------------ */
.u-u-in-copy {
  font-family: "futura-pt", sans-serif;
  font-weight: 700;
  font-style: italic; }

/* -- Font Alignment ---------------- */
.u-text-align-left {
  text-align: left !important; }

.u-children-text-align-left h1, .u-children-text-align-left h2, .u-children-text-align-left h3, .u-children-text-align-left h4, .u-children-text-align-left h5, .u-children-text-align-left h6, .u-children-text-align-left p, .u-children-text-align-left a {
  text-align: left; }

.u-text-align-right {
  text-align: right !important; }

.u-text-align-center {
  text-align: center !important; }

/* -- Responsive Alignment ----------- */
@media (min-width: 540px) {
  .u-text-align-left-xs {
    text-align: left !important; } }

@media (min-width: 800px) {
  .u-text-align-left-s.u-text-align-left-s {
    text-align: left !important; } }

@media (min-width: 980px) {
  .u-text-align-left-m.u-text-align-left-m.u-text-align-left-m {
    text-align: left !important; } }

@media (min-width: 1080px) {
  .u-text-align-left-l.u-text-align-left-l.u-text-align-left-l.u-text-align-left-l {
    text-align: left !important; } }

@media (min-width: 1200px) {
  .u-text-align-left-xl.u-text-align-left-xl.u-text-align-left-xl.u-text-align-left-xl.u-text-align-left-xl {
    text-align: left !important; } }

@media (min-width: 1440px) {
  .u-text-align-left-xxl.u-text-align-left-xxl.u-text-align-left-xxl.u-text-align-left-xxl.u-text-align-left-xxl.u-text-align-left-xxl {
    text-align: left !important; } }

@media (min-width: 540px) {
  .u-text-align-center-xs {
    text-align: center !important; } }

@media (min-width: 800px) {
  .u-text-align-center-s.u-text-align-center-s {
    text-align: center !important; } }

@media (min-width: 980px) {
  .u-text-align-center-m.u-text-align-center-m.u-text-align-center-m {
    text-align: center !important; } }

@media (min-width: 1080px) {
  .u-text-align-center-l.u-text-align-center-l.u-text-align-center-l.u-text-align-center-l {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .u-text-align-center-xl.u-text-align-center-xl.u-text-align-center-xl.u-text-align-center-xl.u-text-align-center-xl {
    text-align: center !important; } }

@media (min-width: 1440px) {
  .u-text-align-center-xxl.u-text-align-center-xxl.u-text-align-center-xxl.u-text-align-center-xxl.u-text-align-center-xxl.u-text-align-center-xxl {
    text-align: center !important; } }

@media (min-width: 980px) {
  .u-children-text-align-left-m {
    text-align: left; }
    .u-children-text-align-left-m h1, .u-children-text-align-left-m h2, .u-children-text-align-left-m h3, .u-children-text-align-left-m h4, .u-children-text-align-left-m h5, .u-children-text-align-left-m h6, .u-children-text-align-left-m p, .u-children-text-align-left-m a {
      text-align: left; } }

/* -- Font Weights ------------------ */
.u-page-fonts {
  font-weight: 300; }
  .u-page-fonts h1,
  .u-page-fonts h2,
  .u-page-fonts h3,
  .u-page-fonts h4,
  .u-page-fonts h5,
  .u-page-fonts h6,
  .u-page-fonts b {
    font-weight: 500; }

.u-font-book {
  font-family: "futura-pt", sans-serif !important;
  font-weight: 300 !important; }

.u-font-medium {
  font-family: "futura-pt", sans-serif !important;
  font-weight: 500 !important; }

.u-font-heavy, .c-glossary__subtitle {
  font-family: "futura-pt", sans-serif !important;
  font-weight: 700 !important; }

.u-font-extra-bold {
  font-family: "futura-pt", sans-serif !important;
  font-weight: 800 !important; }

/* -- Font Sizes ------------------ */
.u-font-size-11 {
  font-size: 11px; }

.u-font-size-13 {
  font-size: 13px; }

.u-font-size-15 {
  font-size: 15px; }

.u-font-size-28 {
  font-size: 28px; }

.u-font-size-20 {
  font-size: 20px;
  font-weight: 100;
  margin-bottom: 20px; }

/* -- Font Styles ----------------- */
.u-font-style-italic {
  font-style: italic; }

/*------------------------------------*\
    Spacing Utilities
\*------------------------------------*/
/* -- Padding ----------------------- */
.u-padding-0 {
  padding: 0px !important; }

.u-padding-left-right-0 {
  padding-left: 0px !important;
  padding-right: 0px !important; }

.u-padding-top-bottom-0 {
  padding-top: 0px !important;
  padding-bottom: 0px !important; }

.u-padding-top-0 {
  padding-top: 0px !important; }

.u-padding-right-0 {
  padding-right: 0px !important; }

.u-padding-bottom-0 {
  padding-bottom: 0px !important; }

.u-padding-left-0 {
  padding-left: 0px !important; }

@media (min-width: 540px) {
  .u-padding-0-bp-xs {
    padding: 0px !important; } }

@media (min-width: 800px) {
  .u-padding-0-bp-s.u-padding-0-bp-s {
    padding: 0px !important; } }

@media (min-width: 980px) {
  .u-padding-0-bp-m.u-padding-0-bp-m.u-padding-0-bp-m {
    padding: 0px !important; } }

@media (min-width: 1080px) {
  .u-padding-0-bp-l.u-padding-0-bp-l.u-padding-0-bp-l.u-padding-0-bp-l {
    padding: 0px !important; } }

@media (min-width: 1200px) {
  .u-padding-0-bp-xl.u-padding-0-bp-xl.u-padding-0-bp-xl.u-padding-0-bp-xl.u-padding-0-bp-xl {
    padding: 0px !important; } }

@media (min-width: 1440px) {
  .u-padding-0-bp-xxl.u-padding-0-bp-xxl.u-padding-0-bp-xxl.u-padding-0-bp-xxl.u-padding-0-bp-xxl.u-padding-0-bp-xxl {
    padding: 0px !important; } }

@media (min-width: 540px) {
  .u-padding-left-right-0-bp-xs {
    padding-left: 0px !important; } }

@media (min-width: 800px) {
  .u-padding-left-right-0-bp-s.u-padding-left-right-0-bp-s {
    padding-left: 0px !important; } }

@media (min-width: 980px) {
  .u-padding-left-right-0-bp-m.u-padding-left-right-0-bp-m.u-padding-left-right-0-bp-m {
    padding-left: 0px !important; } }

@media (min-width: 1080px) {
  .u-padding-left-right-0-bp-l.u-padding-left-right-0-bp-l.u-padding-left-right-0-bp-l.u-padding-left-right-0-bp-l {
    padding-left: 0px !important; } }

@media (min-width: 1200px) {
  .u-padding-left-right-0-bp-xl.u-padding-left-right-0-bp-xl.u-padding-left-right-0-bp-xl.u-padding-left-right-0-bp-xl.u-padding-left-right-0-bp-xl {
    padding-left: 0px !important; } }

@media (min-width: 1440px) {
  .u-padding-left-right-0-bp-xxl.u-padding-left-right-0-bp-xxl.u-padding-left-right-0-bp-xxl.u-padding-left-right-0-bp-xxl.u-padding-left-right-0-bp-xxl.u-padding-left-right-0-bp-xxl {
    padding-left: 0px !important; } }

@media (min-width: 540px) {
  .u-padding-left-right-0-bp-xs {
    padding-right: 0px !important; } }

@media (min-width: 800px) {
  .u-padding-left-right-0-bp-s.u-padding-left-right-0-bp-s {
    padding-right: 0px !important; } }

@media (min-width: 980px) {
  .u-padding-left-right-0-bp-m.u-padding-left-right-0-bp-m.u-padding-left-right-0-bp-m {
    padding-right: 0px !important; } }

@media (min-width: 1080px) {
  .u-padding-left-right-0-bp-l.u-padding-left-right-0-bp-l.u-padding-left-right-0-bp-l.u-padding-left-right-0-bp-l {
    padding-right: 0px !important; } }

@media (min-width: 1200px) {
  .u-padding-left-right-0-bp-xl.u-padding-left-right-0-bp-xl.u-padding-left-right-0-bp-xl.u-padding-left-right-0-bp-xl.u-padding-left-right-0-bp-xl {
    padding-right: 0px !important; } }

@media (min-width: 1440px) {
  .u-padding-left-right-0-bp-xxl.u-padding-left-right-0-bp-xxl.u-padding-left-right-0-bp-xxl.u-padding-left-right-0-bp-xxl.u-padding-left-right-0-bp-xxl.u-padding-left-right-0-bp-xxl {
    padding-right: 0px !important; } }

@media (min-width: 540px) {
  .u-padding-top-bottom-0-bp-xs {
    padding-top: 0px !important; } }

@media (min-width: 800px) {
  .u-padding-top-bottom-0-bp-s.u-padding-top-bottom-0-bp-s {
    padding-top: 0px !important; } }

@media (min-width: 980px) {
  .u-padding-top-bottom-0-bp-m.u-padding-top-bottom-0-bp-m.u-padding-top-bottom-0-bp-m {
    padding-top: 0px !important; } }

@media (min-width: 1080px) {
  .u-padding-top-bottom-0-bp-l.u-padding-top-bottom-0-bp-l.u-padding-top-bottom-0-bp-l.u-padding-top-bottom-0-bp-l {
    padding-top: 0px !important; } }

@media (min-width: 1200px) {
  .u-padding-top-bottom-0-bp-xl.u-padding-top-bottom-0-bp-xl.u-padding-top-bottom-0-bp-xl.u-padding-top-bottom-0-bp-xl.u-padding-top-bottom-0-bp-xl {
    padding-top: 0px !important; } }

@media (min-width: 1440px) {
  .u-padding-top-bottom-0-bp-xxl.u-padding-top-bottom-0-bp-xxl.u-padding-top-bottom-0-bp-xxl.u-padding-top-bottom-0-bp-xxl.u-padding-top-bottom-0-bp-xxl.u-padding-top-bottom-0-bp-xxl {
    padding-top: 0px !important; } }

@media (min-width: 540px) {
  .u-padding-top-bottom-0-bp-xs {
    padding-bottom: 0px !important; } }

@media (min-width: 800px) {
  .u-padding-top-bottom-0-bp-s.u-padding-top-bottom-0-bp-s {
    padding-bottom: 0px !important; } }

@media (min-width: 980px) {
  .u-padding-top-bottom-0-bp-m.u-padding-top-bottom-0-bp-m.u-padding-top-bottom-0-bp-m {
    padding-bottom: 0px !important; } }

@media (min-width: 1080px) {
  .u-padding-top-bottom-0-bp-l.u-padding-top-bottom-0-bp-l.u-padding-top-bottom-0-bp-l.u-padding-top-bottom-0-bp-l {
    padding-bottom: 0px !important; } }

@media (min-width: 1200px) {
  .u-padding-top-bottom-0-bp-xl.u-padding-top-bottom-0-bp-xl.u-padding-top-bottom-0-bp-xl.u-padding-top-bottom-0-bp-xl.u-padding-top-bottom-0-bp-xl {
    padding-bottom: 0px !important; } }

@media (min-width: 1440px) {
  .u-padding-top-bottom-0-bp-xxl.u-padding-top-bottom-0-bp-xxl.u-padding-top-bottom-0-bp-xxl.u-padding-top-bottom-0-bp-xxl.u-padding-top-bottom-0-bp-xxl.u-padding-top-bottom-0-bp-xxl {
    padding-bottom: 0px !important; } }

@media (min-width: 540px) {
  .u-padding-top-0-bp-xs {
    padding-top: 0px !important; } }

@media (min-width: 800px) {
  .u-padding-top-0-bp-s.u-padding-top-0-bp-s {
    padding-top: 0px !important; } }

@media (min-width: 980px) {
  .u-padding-top-0-bp-m.u-padding-top-0-bp-m.u-padding-top-0-bp-m {
    padding-top: 0px !important; } }

@media (min-width: 1080px) {
  .u-padding-top-0-bp-l.u-padding-top-0-bp-l.u-padding-top-0-bp-l.u-padding-top-0-bp-l {
    padding-top: 0px !important; } }

@media (min-width: 1200px) {
  .u-padding-top-0-bp-xl.u-padding-top-0-bp-xl.u-padding-top-0-bp-xl.u-padding-top-0-bp-xl.u-padding-top-0-bp-xl {
    padding-top: 0px !important; } }

@media (min-width: 1440px) {
  .u-padding-top-0-bp-xxl.u-padding-top-0-bp-xxl.u-padding-top-0-bp-xxl.u-padding-top-0-bp-xxl.u-padding-top-0-bp-xxl.u-padding-top-0-bp-xxl {
    padding-top: 0px !important; } }

@media (min-width: 540px) {
  .u-padding-right-0-bp-xs {
    padding-right: 0px !important; } }

@media (min-width: 800px) {
  .u-padding-right-0-bp-s.u-padding-right-0-bp-s {
    padding-right: 0px !important; } }

@media (min-width: 980px) {
  .u-padding-right-0-bp-m.u-padding-right-0-bp-m.u-padding-right-0-bp-m {
    padding-right: 0px !important; } }

@media (min-width: 1080px) {
  .u-padding-right-0-bp-l.u-padding-right-0-bp-l.u-padding-right-0-bp-l.u-padding-right-0-bp-l {
    padding-right: 0px !important; } }

@media (min-width: 1200px) {
  .u-padding-right-0-bp-xl.u-padding-right-0-bp-xl.u-padding-right-0-bp-xl.u-padding-right-0-bp-xl.u-padding-right-0-bp-xl {
    padding-right: 0px !important; } }

@media (min-width: 1440px) {
  .u-padding-right-0-bp-xxl.u-padding-right-0-bp-xxl.u-padding-right-0-bp-xxl.u-padding-right-0-bp-xxl.u-padding-right-0-bp-xxl.u-padding-right-0-bp-xxl {
    padding-right: 0px !important; } }

@media (min-width: 540px) {
  .u-padding-bottom-0-bp-xs {
    padding-bottom: 0px !important; } }

@media (min-width: 800px) {
  .u-padding-bottom-0-bp-s.u-padding-bottom-0-bp-s {
    padding-bottom: 0px !important; } }

@media (min-width: 980px) {
  .u-padding-bottom-0-bp-m.u-padding-bottom-0-bp-m.u-padding-bottom-0-bp-m {
    padding-bottom: 0px !important; } }

@media (min-width: 1080px) {
  .u-padding-bottom-0-bp-l.u-padding-bottom-0-bp-l.u-padding-bottom-0-bp-l.u-padding-bottom-0-bp-l {
    padding-bottom: 0px !important; } }

@media (min-width: 1200px) {
  .u-padding-bottom-0-bp-xl.u-padding-bottom-0-bp-xl.u-padding-bottom-0-bp-xl.u-padding-bottom-0-bp-xl.u-padding-bottom-0-bp-xl {
    padding-bottom: 0px !important; } }

@media (min-width: 1440px) {
  .u-padding-bottom-0-bp-xxl.u-padding-bottom-0-bp-xxl.u-padding-bottom-0-bp-xxl.u-padding-bottom-0-bp-xxl.u-padding-bottom-0-bp-xxl.u-padding-bottom-0-bp-xxl {
    padding-bottom: 0px !important; } }

@media (min-width: 540px) {
  .u-padding-left-0-bp-xs {
    padding-left: 0px !important; } }

@media (min-width: 800px) {
  .u-padding-left-0-bp-s.u-padding-left-0-bp-s {
    padding-left: 0px !important; } }

@media (min-width: 980px) {
  .u-padding-left-0-bp-m.u-padding-left-0-bp-m.u-padding-left-0-bp-m {
    padding-left: 0px !important; } }

@media (min-width: 1080px) {
  .u-padding-left-0-bp-l.u-padding-left-0-bp-l.u-padding-left-0-bp-l.u-padding-left-0-bp-l {
    padding-left: 0px !important; } }

@media (min-width: 1200px) {
  .u-padding-left-0-bp-xl.u-padding-left-0-bp-xl.u-padding-left-0-bp-xl.u-padding-left-0-bp-xl.u-padding-left-0-bp-xl {
    padding-left: 0px !important; } }

@media (min-width: 1440px) {
  .u-padding-left-0-bp-xxl.u-padding-left-0-bp-xxl.u-padding-left-0-bp-xxl.u-padding-left-0-bp-xxl.u-padding-left-0-bp-xxl.u-padding-left-0-bp-xxl {
    padding-left: 0px !important; } }

.u-padding-xxs {
  padding: 8px !important; }

.u-padding-left-right-xxs {
  padding-left: 8px !important;
  padding-right: 8px !important; }

.u-padding-top-bottom-xxs {
  padding-top: 8px !important;
  padding-bottom: 8px !important; }

.u-padding-top-xxs {
  padding-top: 8px !important; }

.u-padding-right-xxs {
  padding-right: 8px !important; }

.u-padding-bottom-xxs {
  padding-bottom: 8px !important; }

.u-padding-left-xxs {
  padding-left: 8px !important; }

@media (min-width: 540px) {
  .u-padding-xxs-bp-xs {
    padding: 8px !important; } }

@media (min-width: 800px) {
  .u-padding-xxs-bp-s.u-padding-xxs-bp-s {
    padding: 8px !important; } }

@media (min-width: 980px) {
  .u-padding-xxs-bp-m.u-padding-xxs-bp-m.u-padding-xxs-bp-m {
    padding: 8px !important; } }

@media (min-width: 1080px) {
  .u-padding-xxs-bp-l.u-padding-xxs-bp-l.u-padding-xxs-bp-l.u-padding-xxs-bp-l {
    padding: 8px !important; } }

@media (min-width: 1200px) {
  .u-padding-xxs-bp-xl.u-padding-xxs-bp-xl.u-padding-xxs-bp-xl.u-padding-xxs-bp-xl.u-padding-xxs-bp-xl {
    padding: 8px !important; } }

@media (min-width: 1440px) {
  .u-padding-xxs-bp-xxl.u-padding-xxs-bp-xxl.u-padding-xxs-bp-xxl.u-padding-xxs-bp-xxl.u-padding-xxs-bp-xxl.u-padding-xxs-bp-xxl {
    padding: 8px !important; } }

@media (min-width: 540px) {
  .u-padding-left-right-xxs-bp-xs {
    padding-left: 8px !important; } }

@media (min-width: 800px) {
  .u-padding-left-right-xxs-bp-s.u-padding-left-right-xxs-bp-s {
    padding-left: 8px !important; } }

@media (min-width: 980px) {
  .u-padding-left-right-xxs-bp-m.u-padding-left-right-xxs-bp-m.u-padding-left-right-xxs-bp-m {
    padding-left: 8px !important; } }

@media (min-width: 1080px) {
  .u-padding-left-right-xxs-bp-l.u-padding-left-right-xxs-bp-l.u-padding-left-right-xxs-bp-l.u-padding-left-right-xxs-bp-l {
    padding-left: 8px !important; } }

@media (min-width: 1200px) {
  .u-padding-left-right-xxs-bp-xl.u-padding-left-right-xxs-bp-xl.u-padding-left-right-xxs-bp-xl.u-padding-left-right-xxs-bp-xl.u-padding-left-right-xxs-bp-xl {
    padding-left: 8px !important; } }

@media (min-width: 1440px) {
  .u-padding-left-right-xxs-bp-xxl.u-padding-left-right-xxs-bp-xxl.u-padding-left-right-xxs-bp-xxl.u-padding-left-right-xxs-bp-xxl.u-padding-left-right-xxs-bp-xxl.u-padding-left-right-xxs-bp-xxl {
    padding-left: 8px !important; } }

@media (min-width: 540px) {
  .u-padding-left-right-xxs-bp-xs {
    padding-right: 8px !important; } }

@media (min-width: 800px) {
  .u-padding-left-right-xxs-bp-s.u-padding-left-right-xxs-bp-s {
    padding-right: 8px !important; } }

@media (min-width: 980px) {
  .u-padding-left-right-xxs-bp-m.u-padding-left-right-xxs-bp-m.u-padding-left-right-xxs-bp-m {
    padding-right: 8px !important; } }

@media (min-width: 1080px) {
  .u-padding-left-right-xxs-bp-l.u-padding-left-right-xxs-bp-l.u-padding-left-right-xxs-bp-l.u-padding-left-right-xxs-bp-l {
    padding-right: 8px !important; } }

@media (min-width: 1200px) {
  .u-padding-left-right-xxs-bp-xl.u-padding-left-right-xxs-bp-xl.u-padding-left-right-xxs-bp-xl.u-padding-left-right-xxs-bp-xl.u-padding-left-right-xxs-bp-xl {
    padding-right: 8px !important; } }

@media (min-width: 1440px) {
  .u-padding-left-right-xxs-bp-xxl.u-padding-left-right-xxs-bp-xxl.u-padding-left-right-xxs-bp-xxl.u-padding-left-right-xxs-bp-xxl.u-padding-left-right-xxs-bp-xxl.u-padding-left-right-xxs-bp-xxl {
    padding-right: 8px !important; } }

@media (min-width: 540px) {
  .u-padding-top-bottom-xxs-bp-xs {
    padding-top: 8px !important; } }

@media (min-width: 800px) {
  .u-padding-top-bottom-xxs-bp-s.u-padding-top-bottom-xxs-bp-s {
    padding-top: 8px !important; } }

@media (min-width: 980px) {
  .u-padding-top-bottom-xxs-bp-m.u-padding-top-bottom-xxs-bp-m.u-padding-top-bottom-xxs-bp-m {
    padding-top: 8px !important; } }

@media (min-width: 1080px) {
  .u-padding-top-bottom-xxs-bp-l.u-padding-top-bottom-xxs-bp-l.u-padding-top-bottom-xxs-bp-l.u-padding-top-bottom-xxs-bp-l {
    padding-top: 8px !important; } }

@media (min-width: 1200px) {
  .u-padding-top-bottom-xxs-bp-xl.u-padding-top-bottom-xxs-bp-xl.u-padding-top-bottom-xxs-bp-xl.u-padding-top-bottom-xxs-bp-xl.u-padding-top-bottom-xxs-bp-xl {
    padding-top: 8px !important; } }

@media (min-width: 1440px) {
  .u-padding-top-bottom-xxs-bp-xxl.u-padding-top-bottom-xxs-bp-xxl.u-padding-top-bottom-xxs-bp-xxl.u-padding-top-bottom-xxs-bp-xxl.u-padding-top-bottom-xxs-bp-xxl.u-padding-top-bottom-xxs-bp-xxl {
    padding-top: 8px !important; } }

@media (min-width: 540px) {
  .u-padding-top-bottom-xxs-bp-xs {
    padding-bottom: 8px !important; } }

@media (min-width: 800px) {
  .u-padding-top-bottom-xxs-bp-s.u-padding-top-bottom-xxs-bp-s {
    padding-bottom: 8px !important; } }

@media (min-width: 980px) {
  .u-padding-top-bottom-xxs-bp-m.u-padding-top-bottom-xxs-bp-m.u-padding-top-bottom-xxs-bp-m {
    padding-bottom: 8px !important; } }

@media (min-width: 1080px) {
  .u-padding-top-bottom-xxs-bp-l.u-padding-top-bottom-xxs-bp-l.u-padding-top-bottom-xxs-bp-l.u-padding-top-bottom-xxs-bp-l {
    padding-bottom: 8px !important; } }

@media (min-width: 1200px) {
  .u-padding-top-bottom-xxs-bp-xl.u-padding-top-bottom-xxs-bp-xl.u-padding-top-bottom-xxs-bp-xl.u-padding-top-bottom-xxs-bp-xl.u-padding-top-bottom-xxs-bp-xl {
    padding-bottom: 8px !important; } }

@media (min-width: 1440px) {
  .u-padding-top-bottom-xxs-bp-xxl.u-padding-top-bottom-xxs-bp-xxl.u-padding-top-bottom-xxs-bp-xxl.u-padding-top-bottom-xxs-bp-xxl.u-padding-top-bottom-xxs-bp-xxl.u-padding-top-bottom-xxs-bp-xxl {
    padding-bottom: 8px !important; } }

@media (min-width: 540px) {
  .u-padding-top-xxs-bp-xs {
    padding-top: 8px !important; } }

@media (min-width: 800px) {
  .u-padding-top-xxs-bp-s.u-padding-top-xxs-bp-s {
    padding-top: 8px !important; } }

@media (min-width: 980px) {
  .u-padding-top-xxs-bp-m.u-padding-top-xxs-bp-m.u-padding-top-xxs-bp-m {
    padding-top: 8px !important; } }

@media (min-width: 1080px) {
  .u-padding-top-xxs-bp-l.u-padding-top-xxs-bp-l.u-padding-top-xxs-bp-l.u-padding-top-xxs-bp-l {
    padding-top: 8px !important; } }

@media (min-width: 1200px) {
  .u-padding-top-xxs-bp-xl.u-padding-top-xxs-bp-xl.u-padding-top-xxs-bp-xl.u-padding-top-xxs-bp-xl.u-padding-top-xxs-bp-xl {
    padding-top: 8px !important; } }

@media (min-width: 1440px) {
  .u-padding-top-xxs-bp-xxl.u-padding-top-xxs-bp-xxl.u-padding-top-xxs-bp-xxl.u-padding-top-xxs-bp-xxl.u-padding-top-xxs-bp-xxl.u-padding-top-xxs-bp-xxl {
    padding-top: 8px !important; } }

@media (min-width: 540px) {
  .u-padding-right-xxs-bp-xs {
    padding-right: 8px !important; } }

@media (min-width: 800px) {
  .u-padding-right-xxs-bp-s.u-padding-right-xxs-bp-s {
    padding-right: 8px !important; } }

@media (min-width: 980px) {
  .u-padding-right-xxs-bp-m.u-padding-right-xxs-bp-m.u-padding-right-xxs-bp-m {
    padding-right: 8px !important; } }

@media (min-width: 1080px) {
  .u-padding-right-xxs-bp-l.u-padding-right-xxs-bp-l.u-padding-right-xxs-bp-l.u-padding-right-xxs-bp-l {
    padding-right: 8px !important; } }

@media (min-width: 1200px) {
  .u-padding-right-xxs-bp-xl.u-padding-right-xxs-bp-xl.u-padding-right-xxs-bp-xl.u-padding-right-xxs-bp-xl.u-padding-right-xxs-bp-xl {
    padding-right: 8px !important; } }

@media (min-width: 1440px) {
  .u-padding-right-xxs-bp-xxl.u-padding-right-xxs-bp-xxl.u-padding-right-xxs-bp-xxl.u-padding-right-xxs-bp-xxl.u-padding-right-xxs-bp-xxl.u-padding-right-xxs-bp-xxl {
    padding-right: 8px !important; } }

@media (min-width: 540px) {
  .u-padding-bottom-xxs-bp-xs {
    padding-bottom: 8px !important; } }

@media (min-width: 800px) {
  .u-padding-bottom-xxs-bp-s.u-padding-bottom-xxs-bp-s {
    padding-bottom: 8px !important; } }

@media (min-width: 980px) {
  .u-padding-bottom-xxs-bp-m.u-padding-bottom-xxs-bp-m.u-padding-bottom-xxs-bp-m {
    padding-bottom: 8px !important; } }

@media (min-width: 1080px) {
  .u-padding-bottom-xxs-bp-l.u-padding-bottom-xxs-bp-l.u-padding-bottom-xxs-bp-l.u-padding-bottom-xxs-bp-l {
    padding-bottom: 8px !important; } }

@media (min-width: 1200px) {
  .u-padding-bottom-xxs-bp-xl.u-padding-bottom-xxs-bp-xl.u-padding-bottom-xxs-bp-xl.u-padding-bottom-xxs-bp-xl.u-padding-bottom-xxs-bp-xl {
    padding-bottom: 8px !important; } }

@media (min-width: 1440px) {
  .u-padding-bottom-xxs-bp-xxl.u-padding-bottom-xxs-bp-xxl.u-padding-bottom-xxs-bp-xxl.u-padding-bottom-xxs-bp-xxl.u-padding-bottom-xxs-bp-xxl.u-padding-bottom-xxs-bp-xxl {
    padding-bottom: 8px !important; } }

@media (min-width: 540px) {
  .u-padding-left-xxs-bp-xs {
    padding-left: 8px !important; } }

@media (min-width: 800px) {
  .u-padding-left-xxs-bp-s.u-padding-left-xxs-bp-s {
    padding-left: 8px !important; } }

@media (min-width: 980px) {
  .u-padding-left-xxs-bp-m.u-padding-left-xxs-bp-m.u-padding-left-xxs-bp-m {
    padding-left: 8px !important; } }

@media (min-width: 1080px) {
  .u-padding-left-xxs-bp-l.u-padding-left-xxs-bp-l.u-padding-left-xxs-bp-l.u-padding-left-xxs-bp-l {
    padding-left: 8px !important; } }

@media (min-width: 1200px) {
  .u-padding-left-xxs-bp-xl.u-padding-left-xxs-bp-xl.u-padding-left-xxs-bp-xl.u-padding-left-xxs-bp-xl.u-padding-left-xxs-bp-xl {
    padding-left: 8px !important; } }

@media (min-width: 1440px) {
  .u-padding-left-xxs-bp-xxl.u-padding-left-xxs-bp-xxl.u-padding-left-xxs-bp-xxl.u-padding-left-xxs-bp-xxl.u-padding-left-xxs-bp-xxl.u-padding-left-xxs-bp-xxl {
    padding-left: 8px !important; } }

.u-padding-xs {
  padding: 12px !important; }

.u-padding-left-right-xs {
  padding-left: 12px !important;
  padding-right: 12px !important; }

.u-padding-top-bottom-xs {
  padding-top: 12px !important;
  padding-bottom: 12px !important; }

.u-padding-top-xs {
  padding-top: 12px !important; }

.u-padding-right-xs {
  padding-right: 12px !important; }

.u-padding-bottom-xs {
  padding-bottom: 12px !important; }

.u-padding-left-xs {
  padding-left: 12px !important; }

@media (min-width: 540px) {
  .u-padding-xs-bp-xs {
    padding: 12px !important; } }

@media (min-width: 800px) {
  .u-padding-xs-bp-s.u-padding-xs-bp-s {
    padding: 12px !important; } }

@media (min-width: 980px) {
  .u-padding-xs-bp-m.u-padding-xs-bp-m.u-padding-xs-bp-m {
    padding: 12px !important; } }

@media (min-width: 1080px) {
  .u-padding-xs-bp-l.u-padding-xs-bp-l.u-padding-xs-bp-l.u-padding-xs-bp-l {
    padding: 12px !important; } }

@media (min-width: 1200px) {
  .u-padding-xs-bp-xl.u-padding-xs-bp-xl.u-padding-xs-bp-xl.u-padding-xs-bp-xl.u-padding-xs-bp-xl {
    padding: 12px !important; } }

@media (min-width: 1440px) {
  .u-padding-xs-bp-xxl.u-padding-xs-bp-xxl.u-padding-xs-bp-xxl.u-padding-xs-bp-xxl.u-padding-xs-bp-xxl.u-padding-xs-bp-xxl {
    padding: 12px !important; } }

@media (min-width: 540px) {
  .u-padding-left-right-xs-bp-xs {
    padding-left: 12px !important; } }

@media (min-width: 800px) {
  .u-padding-left-right-xs-bp-s.u-padding-left-right-xs-bp-s {
    padding-left: 12px !important; } }

@media (min-width: 980px) {
  .u-padding-left-right-xs-bp-m.u-padding-left-right-xs-bp-m.u-padding-left-right-xs-bp-m {
    padding-left: 12px !important; } }

@media (min-width: 1080px) {
  .u-padding-left-right-xs-bp-l.u-padding-left-right-xs-bp-l.u-padding-left-right-xs-bp-l.u-padding-left-right-xs-bp-l {
    padding-left: 12px !important; } }

@media (min-width: 1200px) {
  .u-padding-left-right-xs-bp-xl.u-padding-left-right-xs-bp-xl.u-padding-left-right-xs-bp-xl.u-padding-left-right-xs-bp-xl.u-padding-left-right-xs-bp-xl {
    padding-left: 12px !important; } }

@media (min-width: 1440px) {
  .u-padding-left-right-xs-bp-xxl.u-padding-left-right-xs-bp-xxl.u-padding-left-right-xs-bp-xxl.u-padding-left-right-xs-bp-xxl.u-padding-left-right-xs-bp-xxl.u-padding-left-right-xs-bp-xxl {
    padding-left: 12px !important; } }

@media (min-width: 540px) {
  .u-padding-left-right-xs-bp-xs {
    padding-right: 12px !important; } }

@media (min-width: 800px) {
  .u-padding-left-right-xs-bp-s.u-padding-left-right-xs-bp-s {
    padding-right: 12px !important; } }

@media (min-width: 980px) {
  .u-padding-left-right-xs-bp-m.u-padding-left-right-xs-bp-m.u-padding-left-right-xs-bp-m {
    padding-right: 12px !important; } }

@media (min-width: 1080px) {
  .u-padding-left-right-xs-bp-l.u-padding-left-right-xs-bp-l.u-padding-left-right-xs-bp-l.u-padding-left-right-xs-bp-l {
    padding-right: 12px !important; } }

@media (min-width: 1200px) {
  .u-padding-left-right-xs-bp-xl.u-padding-left-right-xs-bp-xl.u-padding-left-right-xs-bp-xl.u-padding-left-right-xs-bp-xl.u-padding-left-right-xs-bp-xl {
    padding-right: 12px !important; } }

@media (min-width: 1440px) {
  .u-padding-left-right-xs-bp-xxl.u-padding-left-right-xs-bp-xxl.u-padding-left-right-xs-bp-xxl.u-padding-left-right-xs-bp-xxl.u-padding-left-right-xs-bp-xxl.u-padding-left-right-xs-bp-xxl {
    padding-right: 12px !important; } }

@media (min-width: 540px) {
  .u-padding-top-bottom-xs-bp-xs {
    padding-top: 12px !important; } }

@media (min-width: 800px) {
  .u-padding-top-bottom-xs-bp-s.u-padding-top-bottom-xs-bp-s {
    padding-top: 12px !important; } }

@media (min-width: 980px) {
  .u-padding-top-bottom-xs-bp-m.u-padding-top-bottom-xs-bp-m.u-padding-top-bottom-xs-bp-m {
    padding-top: 12px !important; } }

@media (min-width: 1080px) {
  .u-padding-top-bottom-xs-bp-l.u-padding-top-bottom-xs-bp-l.u-padding-top-bottom-xs-bp-l.u-padding-top-bottom-xs-bp-l {
    padding-top: 12px !important; } }

@media (min-width: 1200px) {
  .u-padding-top-bottom-xs-bp-xl.u-padding-top-bottom-xs-bp-xl.u-padding-top-bottom-xs-bp-xl.u-padding-top-bottom-xs-bp-xl.u-padding-top-bottom-xs-bp-xl {
    padding-top: 12px !important; } }

@media (min-width: 1440px) {
  .u-padding-top-bottom-xs-bp-xxl.u-padding-top-bottom-xs-bp-xxl.u-padding-top-bottom-xs-bp-xxl.u-padding-top-bottom-xs-bp-xxl.u-padding-top-bottom-xs-bp-xxl.u-padding-top-bottom-xs-bp-xxl {
    padding-top: 12px !important; } }

@media (min-width: 540px) {
  .u-padding-top-bottom-xs-bp-xs {
    padding-bottom: 12px !important; } }

@media (min-width: 800px) {
  .u-padding-top-bottom-xs-bp-s.u-padding-top-bottom-xs-bp-s {
    padding-bottom: 12px !important; } }

@media (min-width: 980px) {
  .u-padding-top-bottom-xs-bp-m.u-padding-top-bottom-xs-bp-m.u-padding-top-bottom-xs-bp-m {
    padding-bottom: 12px !important; } }

@media (min-width: 1080px) {
  .u-padding-top-bottom-xs-bp-l.u-padding-top-bottom-xs-bp-l.u-padding-top-bottom-xs-bp-l.u-padding-top-bottom-xs-bp-l {
    padding-bottom: 12px !important; } }

@media (min-width: 1200px) {
  .u-padding-top-bottom-xs-bp-xl.u-padding-top-bottom-xs-bp-xl.u-padding-top-bottom-xs-bp-xl.u-padding-top-bottom-xs-bp-xl.u-padding-top-bottom-xs-bp-xl {
    padding-bottom: 12px !important; } }

@media (min-width: 1440px) {
  .u-padding-top-bottom-xs-bp-xxl.u-padding-top-bottom-xs-bp-xxl.u-padding-top-bottom-xs-bp-xxl.u-padding-top-bottom-xs-bp-xxl.u-padding-top-bottom-xs-bp-xxl.u-padding-top-bottom-xs-bp-xxl {
    padding-bottom: 12px !important; } }

@media (min-width: 540px) {
  .u-padding-top-xs-bp-xs {
    padding-top: 12px !important; } }

@media (min-width: 800px) {
  .u-padding-top-xs-bp-s.u-padding-top-xs-bp-s {
    padding-top: 12px !important; } }

@media (min-width: 980px) {
  .u-padding-top-xs-bp-m.u-padding-top-xs-bp-m.u-padding-top-xs-bp-m {
    padding-top: 12px !important; } }

@media (min-width: 1080px) {
  .u-padding-top-xs-bp-l.u-padding-top-xs-bp-l.u-padding-top-xs-bp-l.u-padding-top-xs-bp-l {
    padding-top: 12px !important; } }

@media (min-width: 1200px) {
  .u-padding-top-xs-bp-xl.u-padding-top-xs-bp-xl.u-padding-top-xs-bp-xl.u-padding-top-xs-bp-xl.u-padding-top-xs-bp-xl {
    padding-top: 12px !important; } }

@media (min-width: 1440px) {
  .u-padding-top-xs-bp-xxl.u-padding-top-xs-bp-xxl.u-padding-top-xs-bp-xxl.u-padding-top-xs-bp-xxl.u-padding-top-xs-bp-xxl.u-padding-top-xs-bp-xxl {
    padding-top: 12px !important; } }

@media (min-width: 540px) {
  .u-padding-right-xs-bp-xs {
    padding-right: 12px !important; } }

@media (min-width: 800px) {
  .u-padding-right-xs-bp-s.u-padding-right-xs-bp-s {
    padding-right: 12px !important; } }

@media (min-width: 980px) {
  .u-padding-right-xs-bp-m.u-padding-right-xs-bp-m.u-padding-right-xs-bp-m {
    padding-right: 12px !important; } }

@media (min-width: 1080px) {
  .u-padding-right-xs-bp-l.u-padding-right-xs-bp-l.u-padding-right-xs-bp-l.u-padding-right-xs-bp-l {
    padding-right: 12px !important; } }

@media (min-width: 1200px) {
  .u-padding-right-xs-bp-xl.u-padding-right-xs-bp-xl.u-padding-right-xs-bp-xl.u-padding-right-xs-bp-xl.u-padding-right-xs-bp-xl {
    padding-right: 12px !important; } }

@media (min-width: 1440px) {
  .u-padding-right-xs-bp-xxl.u-padding-right-xs-bp-xxl.u-padding-right-xs-bp-xxl.u-padding-right-xs-bp-xxl.u-padding-right-xs-bp-xxl.u-padding-right-xs-bp-xxl {
    padding-right: 12px !important; } }

@media (min-width: 540px) {
  .u-padding-bottom-xs-bp-xs {
    padding-bottom: 12px !important; } }

@media (min-width: 800px) {
  .u-padding-bottom-xs-bp-s.u-padding-bottom-xs-bp-s {
    padding-bottom: 12px !important; } }

@media (min-width: 980px) {
  .u-padding-bottom-xs-bp-m.u-padding-bottom-xs-bp-m.u-padding-bottom-xs-bp-m {
    padding-bottom: 12px !important; } }

@media (min-width: 1080px) {
  .u-padding-bottom-xs-bp-l.u-padding-bottom-xs-bp-l.u-padding-bottom-xs-bp-l.u-padding-bottom-xs-bp-l {
    padding-bottom: 12px !important; } }

@media (min-width: 1200px) {
  .u-padding-bottom-xs-bp-xl.u-padding-bottom-xs-bp-xl.u-padding-bottom-xs-bp-xl.u-padding-bottom-xs-bp-xl.u-padding-bottom-xs-bp-xl {
    padding-bottom: 12px !important; } }

@media (min-width: 1440px) {
  .u-padding-bottom-xs-bp-xxl.u-padding-bottom-xs-bp-xxl.u-padding-bottom-xs-bp-xxl.u-padding-bottom-xs-bp-xxl.u-padding-bottom-xs-bp-xxl.u-padding-bottom-xs-bp-xxl {
    padding-bottom: 12px !important; } }

@media (min-width: 540px) {
  .u-padding-left-xs-bp-xs {
    padding-left: 12px !important; } }

@media (min-width: 800px) {
  .u-padding-left-xs-bp-s.u-padding-left-xs-bp-s {
    padding-left: 12px !important; } }

@media (min-width: 980px) {
  .u-padding-left-xs-bp-m.u-padding-left-xs-bp-m.u-padding-left-xs-bp-m {
    padding-left: 12px !important; } }

@media (min-width: 1080px) {
  .u-padding-left-xs-bp-l.u-padding-left-xs-bp-l.u-padding-left-xs-bp-l.u-padding-left-xs-bp-l {
    padding-left: 12px !important; } }

@media (min-width: 1200px) {
  .u-padding-left-xs-bp-xl.u-padding-left-xs-bp-xl.u-padding-left-xs-bp-xl.u-padding-left-xs-bp-xl.u-padding-left-xs-bp-xl {
    padding-left: 12px !important; } }

@media (min-width: 1440px) {
  .u-padding-left-xs-bp-xxl.u-padding-left-xs-bp-xxl.u-padding-left-xs-bp-xxl.u-padding-left-xs-bp-xxl.u-padding-left-xs-bp-xxl.u-padding-left-xs-bp-xxl {
    padding-left: 12px !important; } }

.u-padding-s {
  padding: 16px !important; }

.u-padding-left-right-s {
  padding-left: 16px !important;
  padding-right: 16px !important; }

.u-padding-top-bottom-s {
  padding-top: 16px !important;
  padding-bottom: 16px !important; }

.u-padding-top-s {
  padding-top: 16px !important; }

.u-padding-right-s {
  padding-right: 16px !important; }

.u-padding-bottom-s {
  padding-bottom: 16px !important; }

.u-padding-left-s {
  padding-left: 16px !important; }

@media (min-width: 540px) {
  .u-padding-s-bp-xs {
    padding: 16px !important; } }

@media (min-width: 800px) {
  .u-padding-s-bp-s.u-padding-s-bp-s {
    padding: 16px !important; } }

@media (min-width: 980px) {
  .u-padding-s-bp-m.u-padding-s-bp-m.u-padding-s-bp-m {
    padding: 16px !important; } }

@media (min-width: 1080px) {
  .u-padding-s-bp-l.u-padding-s-bp-l.u-padding-s-bp-l.u-padding-s-bp-l {
    padding: 16px !important; } }

@media (min-width: 1200px) {
  .u-padding-s-bp-xl.u-padding-s-bp-xl.u-padding-s-bp-xl.u-padding-s-bp-xl.u-padding-s-bp-xl {
    padding: 16px !important; } }

@media (min-width: 1440px) {
  .u-padding-s-bp-xxl.u-padding-s-bp-xxl.u-padding-s-bp-xxl.u-padding-s-bp-xxl.u-padding-s-bp-xxl.u-padding-s-bp-xxl {
    padding: 16px !important; } }

@media (min-width: 540px) {
  .u-padding-left-right-s-bp-xs {
    padding-left: 16px !important; } }

@media (min-width: 800px) {
  .u-padding-left-right-s-bp-s.u-padding-left-right-s-bp-s {
    padding-left: 16px !important; } }

@media (min-width: 980px) {
  .u-padding-left-right-s-bp-m.u-padding-left-right-s-bp-m.u-padding-left-right-s-bp-m {
    padding-left: 16px !important; } }

@media (min-width: 1080px) {
  .u-padding-left-right-s-bp-l.u-padding-left-right-s-bp-l.u-padding-left-right-s-bp-l.u-padding-left-right-s-bp-l {
    padding-left: 16px !important; } }

@media (min-width: 1200px) {
  .u-padding-left-right-s-bp-xl.u-padding-left-right-s-bp-xl.u-padding-left-right-s-bp-xl.u-padding-left-right-s-bp-xl.u-padding-left-right-s-bp-xl {
    padding-left: 16px !important; } }

@media (min-width: 1440px) {
  .u-padding-left-right-s-bp-xxl.u-padding-left-right-s-bp-xxl.u-padding-left-right-s-bp-xxl.u-padding-left-right-s-bp-xxl.u-padding-left-right-s-bp-xxl.u-padding-left-right-s-bp-xxl {
    padding-left: 16px !important; } }

@media (min-width: 540px) {
  .u-padding-left-right-s-bp-xs {
    padding-right: 16px !important; } }

@media (min-width: 800px) {
  .u-padding-left-right-s-bp-s.u-padding-left-right-s-bp-s {
    padding-right: 16px !important; } }

@media (min-width: 980px) {
  .u-padding-left-right-s-bp-m.u-padding-left-right-s-bp-m.u-padding-left-right-s-bp-m {
    padding-right: 16px !important; } }

@media (min-width: 1080px) {
  .u-padding-left-right-s-bp-l.u-padding-left-right-s-bp-l.u-padding-left-right-s-bp-l.u-padding-left-right-s-bp-l {
    padding-right: 16px !important; } }

@media (min-width: 1200px) {
  .u-padding-left-right-s-bp-xl.u-padding-left-right-s-bp-xl.u-padding-left-right-s-bp-xl.u-padding-left-right-s-bp-xl.u-padding-left-right-s-bp-xl {
    padding-right: 16px !important; } }

@media (min-width: 1440px) {
  .u-padding-left-right-s-bp-xxl.u-padding-left-right-s-bp-xxl.u-padding-left-right-s-bp-xxl.u-padding-left-right-s-bp-xxl.u-padding-left-right-s-bp-xxl.u-padding-left-right-s-bp-xxl {
    padding-right: 16px !important; } }

@media (min-width: 540px) {
  .u-padding-top-bottom-s-bp-xs {
    padding-top: 16px !important; } }

@media (min-width: 800px) {
  .u-padding-top-bottom-s-bp-s.u-padding-top-bottom-s-bp-s {
    padding-top: 16px !important; } }

@media (min-width: 980px) {
  .u-padding-top-bottom-s-bp-m.u-padding-top-bottom-s-bp-m.u-padding-top-bottom-s-bp-m {
    padding-top: 16px !important; } }

@media (min-width: 1080px) {
  .u-padding-top-bottom-s-bp-l.u-padding-top-bottom-s-bp-l.u-padding-top-bottom-s-bp-l.u-padding-top-bottom-s-bp-l {
    padding-top: 16px !important; } }

@media (min-width: 1200px) {
  .u-padding-top-bottom-s-bp-xl.u-padding-top-bottom-s-bp-xl.u-padding-top-bottom-s-bp-xl.u-padding-top-bottom-s-bp-xl.u-padding-top-bottom-s-bp-xl {
    padding-top: 16px !important; } }

@media (min-width: 1440px) {
  .u-padding-top-bottom-s-bp-xxl.u-padding-top-bottom-s-bp-xxl.u-padding-top-bottom-s-bp-xxl.u-padding-top-bottom-s-bp-xxl.u-padding-top-bottom-s-bp-xxl.u-padding-top-bottom-s-bp-xxl {
    padding-top: 16px !important; } }

@media (min-width: 540px) {
  .u-padding-top-bottom-s-bp-xs {
    padding-bottom: 16px !important; } }

@media (min-width: 800px) {
  .u-padding-top-bottom-s-bp-s.u-padding-top-bottom-s-bp-s {
    padding-bottom: 16px !important; } }

@media (min-width: 980px) {
  .u-padding-top-bottom-s-bp-m.u-padding-top-bottom-s-bp-m.u-padding-top-bottom-s-bp-m {
    padding-bottom: 16px !important; } }

@media (min-width: 1080px) {
  .u-padding-top-bottom-s-bp-l.u-padding-top-bottom-s-bp-l.u-padding-top-bottom-s-bp-l.u-padding-top-bottom-s-bp-l {
    padding-bottom: 16px !important; } }

@media (min-width: 1200px) {
  .u-padding-top-bottom-s-bp-xl.u-padding-top-bottom-s-bp-xl.u-padding-top-bottom-s-bp-xl.u-padding-top-bottom-s-bp-xl.u-padding-top-bottom-s-bp-xl {
    padding-bottom: 16px !important; } }

@media (min-width: 1440px) {
  .u-padding-top-bottom-s-bp-xxl.u-padding-top-bottom-s-bp-xxl.u-padding-top-bottom-s-bp-xxl.u-padding-top-bottom-s-bp-xxl.u-padding-top-bottom-s-bp-xxl.u-padding-top-bottom-s-bp-xxl {
    padding-bottom: 16px !important; } }

@media (min-width: 540px) {
  .u-padding-top-s-bp-xs {
    padding-top: 16px !important; } }

@media (min-width: 800px) {
  .u-padding-top-s-bp-s.u-padding-top-s-bp-s {
    padding-top: 16px !important; } }

@media (min-width: 980px) {
  .u-padding-top-s-bp-m.u-padding-top-s-bp-m.u-padding-top-s-bp-m {
    padding-top: 16px !important; } }

@media (min-width: 1080px) {
  .u-padding-top-s-bp-l.u-padding-top-s-bp-l.u-padding-top-s-bp-l.u-padding-top-s-bp-l {
    padding-top: 16px !important; } }

@media (min-width: 1200px) {
  .u-padding-top-s-bp-xl.u-padding-top-s-bp-xl.u-padding-top-s-bp-xl.u-padding-top-s-bp-xl.u-padding-top-s-bp-xl {
    padding-top: 16px !important; } }

@media (min-width: 1440px) {
  .u-padding-top-s-bp-xxl.u-padding-top-s-bp-xxl.u-padding-top-s-bp-xxl.u-padding-top-s-bp-xxl.u-padding-top-s-bp-xxl.u-padding-top-s-bp-xxl {
    padding-top: 16px !important; } }

@media (min-width: 540px) {
  .u-padding-right-s-bp-xs {
    padding-right: 16px !important; } }

@media (min-width: 800px) {
  .u-padding-right-s-bp-s.u-padding-right-s-bp-s {
    padding-right: 16px !important; } }

@media (min-width: 980px) {
  .u-padding-right-s-bp-m.u-padding-right-s-bp-m.u-padding-right-s-bp-m {
    padding-right: 16px !important; } }

@media (min-width: 1080px) {
  .u-padding-right-s-bp-l.u-padding-right-s-bp-l.u-padding-right-s-bp-l.u-padding-right-s-bp-l {
    padding-right: 16px !important; } }

@media (min-width: 1200px) {
  .u-padding-right-s-bp-xl.u-padding-right-s-bp-xl.u-padding-right-s-bp-xl.u-padding-right-s-bp-xl.u-padding-right-s-bp-xl {
    padding-right: 16px !important; } }

@media (min-width: 1440px) {
  .u-padding-right-s-bp-xxl.u-padding-right-s-bp-xxl.u-padding-right-s-bp-xxl.u-padding-right-s-bp-xxl.u-padding-right-s-bp-xxl.u-padding-right-s-bp-xxl {
    padding-right: 16px !important; } }

@media (min-width: 540px) {
  .u-padding-bottom-s-bp-xs {
    padding-bottom: 16px !important; } }

@media (min-width: 800px) {
  .u-padding-bottom-s-bp-s.u-padding-bottom-s-bp-s {
    padding-bottom: 16px !important; } }

@media (min-width: 980px) {
  .u-padding-bottom-s-bp-m.u-padding-bottom-s-bp-m.u-padding-bottom-s-bp-m {
    padding-bottom: 16px !important; } }

@media (min-width: 1080px) {
  .u-padding-bottom-s-bp-l.u-padding-bottom-s-bp-l.u-padding-bottom-s-bp-l.u-padding-bottom-s-bp-l {
    padding-bottom: 16px !important; } }

@media (min-width: 1200px) {
  .u-padding-bottom-s-bp-xl.u-padding-bottom-s-bp-xl.u-padding-bottom-s-bp-xl.u-padding-bottom-s-bp-xl.u-padding-bottom-s-bp-xl {
    padding-bottom: 16px !important; } }

@media (min-width: 1440px) {
  .u-padding-bottom-s-bp-xxl.u-padding-bottom-s-bp-xxl.u-padding-bottom-s-bp-xxl.u-padding-bottom-s-bp-xxl.u-padding-bottom-s-bp-xxl.u-padding-bottom-s-bp-xxl {
    padding-bottom: 16px !important; } }

@media (min-width: 540px) {
  .u-padding-left-s-bp-xs {
    padding-left: 16px !important; } }

@media (min-width: 800px) {
  .u-padding-left-s-bp-s.u-padding-left-s-bp-s {
    padding-left: 16px !important; } }

@media (min-width: 980px) {
  .u-padding-left-s-bp-m.u-padding-left-s-bp-m.u-padding-left-s-bp-m {
    padding-left: 16px !important; } }

@media (min-width: 1080px) {
  .u-padding-left-s-bp-l.u-padding-left-s-bp-l.u-padding-left-s-bp-l.u-padding-left-s-bp-l {
    padding-left: 16px !important; } }

@media (min-width: 1200px) {
  .u-padding-left-s-bp-xl.u-padding-left-s-bp-xl.u-padding-left-s-bp-xl.u-padding-left-s-bp-xl.u-padding-left-s-bp-xl {
    padding-left: 16px !important; } }

@media (min-width: 1440px) {
  .u-padding-left-s-bp-xxl.u-padding-left-s-bp-xxl.u-padding-left-s-bp-xxl.u-padding-left-s-bp-xxl.u-padding-left-s-bp-xxl.u-padding-left-s-bp-xxl {
    padding-left: 16px !important; } }

.u-padding-m {
  padding: 20px !important; }

.u-padding-left-right-m {
  padding-left: 20px !important;
  padding-right: 20px !important; }

.u-padding-top-bottom-m {
  padding-top: 20px !important;
  padding-bottom: 20px !important; }

.u-padding-top-m {
  padding-top: 20px !important; }

.u-padding-right-m {
  padding-right: 20px !important; }

.u-padding-bottom-m {
  padding-bottom: 20px !important; }

.u-padding-left-m {
  padding-left: 20px !important; }

@media (min-width: 540px) {
  .u-padding-m-bp-xs {
    padding: 20px !important; } }

@media (min-width: 800px) {
  .u-padding-m-bp-s.u-padding-m-bp-s {
    padding: 20px !important; } }

@media (min-width: 980px) {
  .u-padding-m-bp-m.u-padding-m-bp-m.u-padding-m-bp-m {
    padding: 20px !important; } }

@media (min-width: 1080px) {
  .u-padding-m-bp-l.u-padding-m-bp-l.u-padding-m-bp-l.u-padding-m-bp-l {
    padding: 20px !important; } }

@media (min-width: 1200px) {
  .u-padding-m-bp-xl.u-padding-m-bp-xl.u-padding-m-bp-xl.u-padding-m-bp-xl.u-padding-m-bp-xl {
    padding: 20px !important; } }

@media (min-width: 1440px) {
  .u-padding-m-bp-xxl.u-padding-m-bp-xxl.u-padding-m-bp-xxl.u-padding-m-bp-xxl.u-padding-m-bp-xxl.u-padding-m-bp-xxl {
    padding: 20px !important; } }

@media (min-width: 540px) {
  .u-padding-left-right-m-bp-xs {
    padding-left: 20px !important; } }

@media (min-width: 800px) {
  .u-padding-left-right-m-bp-s.u-padding-left-right-m-bp-s {
    padding-left: 20px !important; } }

@media (min-width: 980px) {
  .u-padding-left-right-m-bp-m.u-padding-left-right-m-bp-m.u-padding-left-right-m-bp-m {
    padding-left: 20px !important; } }

@media (min-width: 1080px) {
  .u-padding-left-right-m-bp-l.u-padding-left-right-m-bp-l.u-padding-left-right-m-bp-l.u-padding-left-right-m-bp-l {
    padding-left: 20px !important; } }

@media (min-width: 1200px) {
  .u-padding-left-right-m-bp-xl.u-padding-left-right-m-bp-xl.u-padding-left-right-m-bp-xl.u-padding-left-right-m-bp-xl.u-padding-left-right-m-bp-xl {
    padding-left: 20px !important; } }

@media (min-width: 1440px) {
  .u-padding-left-right-m-bp-xxl.u-padding-left-right-m-bp-xxl.u-padding-left-right-m-bp-xxl.u-padding-left-right-m-bp-xxl.u-padding-left-right-m-bp-xxl.u-padding-left-right-m-bp-xxl {
    padding-left: 20px !important; } }

@media (min-width: 540px) {
  .u-padding-left-right-m-bp-xs {
    padding-right: 20px !important; } }

@media (min-width: 800px) {
  .u-padding-left-right-m-bp-s.u-padding-left-right-m-bp-s {
    padding-right: 20px !important; } }

@media (min-width: 980px) {
  .u-padding-left-right-m-bp-m.u-padding-left-right-m-bp-m.u-padding-left-right-m-bp-m {
    padding-right: 20px !important; } }

@media (min-width: 1080px) {
  .u-padding-left-right-m-bp-l.u-padding-left-right-m-bp-l.u-padding-left-right-m-bp-l.u-padding-left-right-m-bp-l {
    padding-right: 20px !important; } }

@media (min-width: 1200px) {
  .u-padding-left-right-m-bp-xl.u-padding-left-right-m-bp-xl.u-padding-left-right-m-bp-xl.u-padding-left-right-m-bp-xl.u-padding-left-right-m-bp-xl {
    padding-right: 20px !important; } }

@media (min-width: 1440px) {
  .u-padding-left-right-m-bp-xxl.u-padding-left-right-m-bp-xxl.u-padding-left-right-m-bp-xxl.u-padding-left-right-m-bp-xxl.u-padding-left-right-m-bp-xxl.u-padding-left-right-m-bp-xxl {
    padding-right: 20px !important; } }

@media (min-width: 540px) {
  .u-padding-top-bottom-m-bp-xs {
    padding-top: 20px !important; } }

@media (min-width: 800px) {
  .u-padding-top-bottom-m-bp-s.u-padding-top-bottom-m-bp-s {
    padding-top: 20px !important; } }

@media (min-width: 980px) {
  .u-padding-top-bottom-m-bp-m.u-padding-top-bottom-m-bp-m.u-padding-top-bottom-m-bp-m {
    padding-top: 20px !important; } }

@media (min-width: 1080px) {
  .u-padding-top-bottom-m-bp-l.u-padding-top-bottom-m-bp-l.u-padding-top-bottom-m-bp-l.u-padding-top-bottom-m-bp-l {
    padding-top: 20px !important; } }

@media (min-width: 1200px) {
  .u-padding-top-bottom-m-bp-xl.u-padding-top-bottom-m-bp-xl.u-padding-top-bottom-m-bp-xl.u-padding-top-bottom-m-bp-xl.u-padding-top-bottom-m-bp-xl {
    padding-top: 20px !important; } }

@media (min-width: 1440px) {
  .u-padding-top-bottom-m-bp-xxl.u-padding-top-bottom-m-bp-xxl.u-padding-top-bottom-m-bp-xxl.u-padding-top-bottom-m-bp-xxl.u-padding-top-bottom-m-bp-xxl.u-padding-top-bottom-m-bp-xxl {
    padding-top: 20px !important; } }

@media (min-width: 540px) {
  .u-padding-top-bottom-m-bp-xs {
    padding-bottom: 20px !important; } }

@media (min-width: 800px) {
  .u-padding-top-bottom-m-bp-s.u-padding-top-bottom-m-bp-s {
    padding-bottom: 20px !important; } }

@media (min-width: 980px) {
  .u-padding-top-bottom-m-bp-m.u-padding-top-bottom-m-bp-m.u-padding-top-bottom-m-bp-m {
    padding-bottom: 20px !important; } }

@media (min-width: 1080px) {
  .u-padding-top-bottom-m-bp-l.u-padding-top-bottom-m-bp-l.u-padding-top-bottom-m-bp-l.u-padding-top-bottom-m-bp-l {
    padding-bottom: 20px !important; } }

@media (min-width: 1200px) {
  .u-padding-top-bottom-m-bp-xl.u-padding-top-bottom-m-bp-xl.u-padding-top-bottom-m-bp-xl.u-padding-top-bottom-m-bp-xl.u-padding-top-bottom-m-bp-xl {
    padding-bottom: 20px !important; } }

@media (min-width: 1440px) {
  .u-padding-top-bottom-m-bp-xxl.u-padding-top-bottom-m-bp-xxl.u-padding-top-bottom-m-bp-xxl.u-padding-top-bottom-m-bp-xxl.u-padding-top-bottom-m-bp-xxl.u-padding-top-bottom-m-bp-xxl {
    padding-bottom: 20px !important; } }

@media (min-width: 540px) {
  .u-padding-top-m-bp-xs {
    padding-top: 20px !important; } }

@media (min-width: 800px) {
  .u-padding-top-m-bp-s.u-padding-top-m-bp-s {
    padding-top: 20px !important; } }

@media (min-width: 980px) {
  .u-padding-top-m-bp-m.u-padding-top-m-bp-m.u-padding-top-m-bp-m {
    padding-top: 20px !important; } }

@media (min-width: 1080px) {
  .u-padding-top-m-bp-l.u-padding-top-m-bp-l.u-padding-top-m-bp-l.u-padding-top-m-bp-l {
    padding-top: 20px !important; } }

@media (min-width: 1200px) {
  .u-padding-top-m-bp-xl.u-padding-top-m-bp-xl.u-padding-top-m-bp-xl.u-padding-top-m-bp-xl.u-padding-top-m-bp-xl {
    padding-top: 20px !important; } }

@media (min-width: 1440px) {
  .u-padding-top-m-bp-xxl.u-padding-top-m-bp-xxl.u-padding-top-m-bp-xxl.u-padding-top-m-bp-xxl.u-padding-top-m-bp-xxl.u-padding-top-m-bp-xxl {
    padding-top: 20px !important; } }

@media (min-width: 540px) {
  .u-padding-right-m-bp-xs {
    padding-right: 20px !important; } }

@media (min-width: 800px) {
  .u-padding-right-m-bp-s.u-padding-right-m-bp-s {
    padding-right: 20px !important; } }

@media (min-width: 980px) {
  .u-padding-right-m-bp-m.u-padding-right-m-bp-m.u-padding-right-m-bp-m {
    padding-right: 20px !important; } }

@media (min-width: 1080px) {
  .u-padding-right-m-bp-l.u-padding-right-m-bp-l.u-padding-right-m-bp-l.u-padding-right-m-bp-l {
    padding-right: 20px !important; } }

@media (min-width: 1200px) {
  .u-padding-right-m-bp-xl.u-padding-right-m-bp-xl.u-padding-right-m-bp-xl.u-padding-right-m-bp-xl.u-padding-right-m-bp-xl {
    padding-right: 20px !important; } }

@media (min-width: 1440px) {
  .u-padding-right-m-bp-xxl.u-padding-right-m-bp-xxl.u-padding-right-m-bp-xxl.u-padding-right-m-bp-xxl.u-padding-right-m-bp-xxl.u-padding-right-m-bp-xxl {
    padding-right: 20px !important; } }

@media (min-width: 540px) {
  .u-padding-bottom-m-bp-xs {
    padding-bottom: 20px !important; } }

@media (min-width: 800px) {
  .u-padding-bottom-m-bp-s.u-padding-bottom-m-bp-s {
    padding-bottom: 20px !important; } }

@media (min-width: 980px) {
  .u-padding-bottom-m-bp-m.u-padding-bottom-m-bp-m.u-padding-bottom-m-bp-m {
    padding-bottom: 20px !important; } }

@media (min-width: 1080px) {
  .u-padding-bottom-m-bp-l.u-padding-bottom-m-bp-l.u-padding-bottom-m-bp-l.u-padding-bottom-m-bp-l {
    padding-bottom: 20px !important; } }

@media (min-width: 1200px) {
  .u-padding-bottom-m-bp-xl.u-padding-bottom-m-bp-xl.u-padding-bottom-m-bp-xl.u-padding-bottom-m-bp-xl.u-padding-bottom-m-bp-xl {
    padding-bottom: 20px !important; } }

@media (min-width: 1440px) {
  .u-padding-bottom-m-bp-xxl.u-padding-bottom-m-bp-xxl.u-padding-bottom-m-bp-xxl.u-padding-bottom-m-bp-xxl.u-padding-bottom-m-bp-xxl.u-padding-bottom-m-bp-xxl {
    padding-bottom: 20px !important; } }

@media (min-width: 540px) {
  .u-padding-left-m-bp-xs {
    padding-left: 20px !important; } }

@media (min-width: 800px) {
  .u-padding-left-m-bp-s.u-padding-left-m-bp-s {
    padding-left: 20px !important; } }

@media (min-width: 980px) {
  .u-padding-left-m-bp-m.u-padding-left-m-bp-m.u-padding-left-m-bp-m {
    padding-left: 20px !important; } }

@media (min-width: 1080px) {
  .u-padding-left-m-bp-l.u-padding-left-m-bp-l.u-padding-left-m-bp-l.u-padding-left-m-bp-l {
    padding-left: 20px !important; } }

@media (min-width: 1200px) {
  .u-padding-left-m-bp-xl.u-padding-left-m-bp-xl.u-padding-left-m-bp-xl.u-padding-left-m-bp-xl.u-padding-left-m-bp-xl {
    padding-left: 20px !important; } }

@media (min-width: 1440px) {
  .u-padding-left-m-bp-xxl.u-padding-left-m-bp-xxl.u-padding-left-m-bp-xxl.u-padding-left-m-bp-xxl.u-padding-left-m-bp-xxl.u-padding-left-m-bp-xxl {
    padding-left: 20px !important; } }

.u-padding-l {
  padding: 24px !important; }

.u-padding-left-right-l {
  padding-left: 24px !important;
  padding-right: 24px !important; }

.u-padding-top-bottom-l {
  padding-top: 24px !important;
  padding-bottom: 24px !important; }

.u-padding-top-l {
  padding-top: 24px !important; }

.u-padding-right-l {
  padding-right: 24px !important; }

.u-padding-bottom-l {
  padding-bottom: 24px !important; }

.u-padding-left-l {
  padding-left: 24px !important; }

@media (min-width: 540px) {
  .u-padding-l-bp-xs {
    padding: 24px !important; } }

@media (min-width: 800px) {
  .u-padding-l-bp-s.u-padding-l-bp-s {
    padding: 24px !important; } }

@media (min-width: 980px) {
  .u-padding-l-bp-m.u-padding-l-bp-m.u-padding-l-bp-m {
    padding: 24px !important; } }

@media (min-width: 1080px) {
  .u-padding-l-bp-l.u-padding-l-bp-l.u-padding-l-bp-l.u-padding-l-bp-l {
    padding: 24px !important; } }

@media (min-width: 1200px) {
  .u-padding-l-bp-xl.u-padding-l-bp-xl.u-padding-l-bp-xl.u-padding-l-bp-xl.u-padding-l-bp-xl {
    padding: 24px !important; } }

@media (min-width: 1440px) {
  .u-padding-l-bp-xxl.u-padding-l-bp-xxl.u-padding-l-bp-xxl.u-padding-l-bp-xxl.u-padding-l-bp-xxl.u-padding-l-bp-xxl {
    padding: 24px !important; } }

@media (min-width: 540px) {
  .u-padding-left-right-l-bp-xs {
    padding-left: 24px !important; } }

@media (min-width: 800px) {
  .u-padding-left-right-l-bp-s.u-padding-left-right-l-bp-s {
    padding-left: 24px !important; } }

@media (min-width: 980px) {
  .u-padding-left-right-l-bp-m.u-padding-left-right-l-bp-m.u-padding-left-right-l-bp-m {
    padding-left: 24px !important; } }

@media (min-width: 1080px) {
  .u-padding-left-right-l-bp-l.u-padding-left-right-l-bp-l.u-padding-left-right-l-bp-l.u-padding-left-right-l-bp-l {
    padding-left: 24px !important; } }

@media (min-width: 1200px) {
  .u-padding-left-right-l-bp-xl.u-padding-left-right-l-bp-xl.u-padding-left-right-l-bp-xl.u-padding-left-right-l-bp-xl.u-padding-left-right-l-bp-xl {
    padding-left: 24px !important; } }

@media (min-width: 1440px) {
  .u-padding-left-right-l-bp-xxl.u-padding-left-right-l-bp-xxl.u-padding-left-right-l-bp-xxl.u-padding-left-right-l-bp-xxl.u-padding-left-right-l-bp-xxl.u-padding-left-right-l-bp-xxl {
    padding-left: 24px !important; } }

@media (min-width: 540px) {
  .u-padding-left-right-l-bp-xs {
    padding-right: 24px !important; } }

@media (min-width: 800px) {
  .u-padding-left-right-l-bp-s.u-padding-left-right-l-bp-s {
    padding-right: 24px !important; } }

@media (min-width: 980px) {
  .u-padding-left-right-l-bp-m.u-padding-left-right-l-bp-m.u-padding-left-right-l-bp-m {
    padding-right: 24px !important; } }

@media (min-width: 1080px) {
  .u-padding-left-right-l-bp-l.u-padding-left-right-l-bp-l.u-padding-left-right-l-bp-l.u-padding-left-right-l-bp-l {
    padding-right: 24px !important; } }

@media (min-width: 1200px) {
  .u-padding-left-right-l-bp-xl.u-padding-left-right-l-bp-xl.u-padding-left-right-l-bp-xl.u-padding-left-right-l-bp-xl.u-padding-left-right-l-bp-xl {
    padding-right: 24px !important; } }

@media (min-width: 1440px) {
  .u-padding-left-right-l-bp-xxl.u-padding-left-right-l-bp-xxl.u-padding-left-right-l-bp-xxl.u-padding-left-right-l-bp-xxl.u-padding-left-right-l-bp-xxl.u-padding-left-right-l-bp-xxl {
    padding-right: 24px !important; } }

@media (min-width: 540px) {
  .u-padding-top-bottom-l-bp-xs {
    padding-top: 24px !important; } }

@media (min-width: 800px) {
  .u-padding-top-bottom-l-bp-s.u-padding-top-bottom-l-bp-s {
    padding-top: 24px !important; } }

@media (min-width: 980px) {
  .u-padding-top-bottom-l-bp-m.u-padding-top-bottom-l-bp-m.u-padding-top-bottom-l-bp-m {
    padding-top: 24px !important; } }

@media (min-width: 1080px) {
  .u-padding-top-bottom-l-bp-l.u-padding-top-bottom-l-bp-l.u-padding-top-bottom-l-bp-l.u-padding-top-bottom-l-bp-l {
    padding-top: 24px !important; } }

@media (min-width: 1200px) {
  .u-padding-top-bottom-l-bp-xl.u-padding-top-bottom-l-bp-xl.u-padding-top-bottom-l-bp-xl.u-padding-top-bottom-l-bp-xl.u-padding-top-bottom-l-bp-xl {
    padding-top: 24px !important; } }

@media (min-width: 1440px) {
  .u-padding-top-bottom-l-bp-xxl.u-padding-top-bottom-l-bp-xxl.u-padding-top-bottom-l-bp-xxl.u-padding-top-bottom-l-bp-xxl.u-padding-top-bottom-l-bp-xxl.u-padding-top-bottom-l-bp-xxl {
    padding-top: 24px !important; } }

@media (min-width: 540px) {
  .u-padding-top-bottom-l-bp-xs {
    padding-bottom: 24px !important; } }

@media (min-width: 800px) {
  .u-padding-top-bottom-l-bp-s.u-padding-top-bottom-l-bp-s {
    padding-bottom: 24px !important; } }

@media (min-width: 980px) {
  .u-padding-top-bottom-l-bp-m.u-padding-top-bottom-l-bp-m.u-padding-top-bottom-l-bp-m {
    padding-bottom: 24px !important; } }

@media (min-width: 1080px) {
  .u-padding-top-bottom-l-bp-l.u-padding-top-bottom-l-bp-l.u-padding-top-bottom-l-bp-l.u-padding-top-bottom-l-bp-l {
    padding-bottom: 24px !important; } }

@media (min-width: 1200px) {
  .u-padding-top-bottom-l-bp-xl.u-padding-top-bottom-l-bp-xl.u-padding-top-bottom-l-bp-xl.u-padding-top-bottom-l-bp-xl.u-padding-top-bottom-l-bp-xl {
    padding-bottom: 24px !important; } }

@media (min-width: 1440px) {
  .u-padding-top-bottom-l-bp-xxl.u-padding-top-bottom-l-bp-xxl.u-padding-top-bottom-l-bp-xxl.u-padding-top-bottom-l-bp-xxl.u-padding-top-bottom-l-bp-xxl.u-padding-top-bottom-l-bp-xxl {
    padding-bottom: 24px !important; } }

@media (min-width: 540px) {
  .u-padding-top-l-bp-xs {
    padding-top: 24px !important; } }

@media (min-width: 800px) {
  .u-padding-top-l-bp-s.u-padding-top-l-bp-s {
    padding-top: 24px !important; } }

@media (min-width: 980px) {
  .u-padding-top-l-bp-m.u-padding-top-l-bp-m.u-padding-top-l-bp-m {
    padding-top: 24px !important; } }

@media (min-width: 1080px) {
  .u-padding-top-l-bp-l.u-padding-top-l-bp-l.u-padding-top-l-bp-l.u-padding-top-l-bp-l {
    padding-top: 24px !important; } }

@media (min-width: 1200px) {
  .u-padding-top-l-bp-xl.u-padding-top-l-bp-xl.u-padding-top-l-bp-xl.u-padding-top-l-bp-xl.u-padding-top-l-bp-xl {
    padding-top: 24px !important; } }

@media (min-width: 1440px) {
  .u-padding-top-l-bp-xxl.u-padding-top-l-bp-xxl.u-padding-top-l-bp-xxl.u-padding-top-l-bp-xxl.u-padding-top-l-bp-xxl.u-padding-top-l-bp-xxl {
    padding-top: 24px !important; } }

@media (min-width: 540px) {
  .u-padding-right-l-bp-xs {
    padding-right: 24px !important; } }

@media (min-width: 800px) {
  .u-padding-right-l-bp-s.u-padding-right-l-bp-s {
    padding-right: 24px !important; } }

@media (min-width: 980px) {
  .u-padding-right-l-bp-m.u-padding-right-l-bp-m.u-padding-right-l-bp-m {
    padding-right: 24px !important; } }

@media (min-width: 1080px) {
  .u-padding-right-l-bp-l.u-padding-right-l-bp-l.u-padding-right-l-bp-l.u-padding-right-l-bp-l {
    padding-right: 24px !important; } }

@media (min-width: 1200px) {
  .u-padding-right-l-bp-xl.u-padding-right-l-bp-xl.u-padding-right-l-bp-xl.u-padding-right-l-bp-xl.u-padding-right-l-bp-xl {
    padding-right: 24px !important; } }

@media (min-width: 1440px) {
  .u-padding-right-l-bp-xxl.u-padding-right-l-bp-xxl.u-padding-right-l-bp-xxl.u-padding-right-l-bp-xxl.u-padding-right-l-bp-xxl.u-padding-right-l-bp-xxl {
    padding-right: 24px !important; } }

@media (min-width: 540px) {
  .u-padding-bottom-l-bp-xs {
    padding-bottom: 24px !important; } }

@media (min-width: 800px) {
  .u-padding-bottom-l-bp-s.u-padding-bottom-l-bp-s {
    padding-bottom: 24px !important; } }

@media (min-width: 980px) {
  .u-padding-bottom-l-bp-m.u-padding-bottom-l-bp-m.u-padding-bottom-l-bp-m {
    padding-bottom: 24px !important; } }

@media (min-width: 1080px) {
  .u-padding-bottom-l-bp-l.u-padding-bottom-l-bp-l.u-padding-bottom-l-bp-l.u-padding-bottom-l-bp-l {
    padding-bottom: 24px !important; } }

@media (min-width: 1200px) {
  .u-padding-bottom-l-bp-xl.u-padding-bottom-l-bp-xl.u-padding-bottom-l-bp-xl.u-padding-bottom-l-bp-xl.u-padding-bottom-l-bp-xl {
    padding-bottom: 24px !important; } }

@media (min-width: 1440px) {
  .u-padding-bottom-l-bp-xxl.u-padding-bottom-l-bp-xxl.u-padding-bottom-l-bp-xxl.u-padding-bottom-l-bp-xxl.u-padding-bottom-l-bp-xxl.u-padding-bottom-l-bp-xxl {
    padding-bottom: 24px !important; } }

@media (min-width: 540px) {
  .u-padding-left-l-bp-xs {
    padding-left: 24px !important; } }

@media (min-width: 800px) {
  .u-padding-left-l-bp-s.u-padding-left-l-bp-s {
    padding-left: 24px !important; } }

@media (min-width: 980px) {
  .u-padding-left-l-bp-m.u-padding-left-l-bp-m.u-padding-left-l-bp-m {
    padding-left: 24px !important; } }

@media (min-width: 1080px) {
  .u-padding-left-l-bp-l.u-padding-left-l-bp-l.u-padding-left-l-bp-l.u-padding-left-l-bp-l {
    padding-left: 24px !important; } }

@media (min-width: 1200px) {
  .u-padding-left-l-bp-xl.u-padding-left-l-bp-xl.u-padding-left-l-bp-xl.u-padding-left-l-bp-xl.u-padding-left-l-bp-xl {
    padding-left: 24px !important; } }

@media (min-width: 1440px) {
  .u-padding-left-l-bp-xxl.u-padding-left-l-bp-xxl.u-padding-left-l-bp-xxl.u-padding-left-l-bp-xxl.u-padding-left-l-bp-xxl.u-padding-left-l-bp-xxl {
    padding-left: 24px !important; } }

.u-padding-xl {
  padding: 28px !important; }

.u-padding-left-right-xl {
  padding-left: 28px !important;
  padding-right: 28px !important; }

.u-padding-top-bottom-xl {
  padding-top: 28px !important;
  padding-bottom: 28px !important; }

.u-padding-top-xl {
  padding-top: 28px !important; }

.u-padding-right-xl {
  padding-right: 28px !important; }

.u-padding-bottom-xl {
  padding-bottom: 28px !important; }

.u-padding-left-xl {
  padding-left: 28px !important; }

@media (min-width: 540px) {
  .u-padding-xl-bp-xs {
    padding: 28px !important; } }

@media (min-width: 800px) {
  .u-padding-xl-bp-s.u-padding-xl-bp-s {
    padding: 28px !important; } }

@media (min-width: 980px) {
  .u-padding-xl-bp-m.u-padding-xl-bp-m.u-padding-xl-bp-m {
    padding: 28px !important; } }

@media (min-width: 1080px) {
  .u-padding-xl-bp-l.u-padding-xl-bp-l.u-padding-xl-bp-l.u-padding-xl-bp-l {
    padding: 28px !important; } }

@media (min-width: 1200px) {
  .u-padding-xl-bp-xl.u-padding-xl-bp-xl.u-padding-xl-bp-xl.u-padding-xl-bp-xl.u-padding-xl-bp-xl {
    padding: 28px !important; } }

@media (min-width: 1440px) {
  .u-padding-xl-bp-xxl.u-padding-xl-bp-xxl.u-padding-xl-bp-xxl.u-padding-xl-bp-xxl.u-padding-xl-bp-xxl.u-padding-xl-bp-xxl {
    padding: 28px !important; } }

@media (min-width: 540px) {
  .u-padding-left-right-xl-bp-xs {
    padding-left: 28px !important; } }

@media (min-width: 800px) {
  .u-padding-left-right-xl-bp-s.u-padding-left-right-xl-bp-s {
    padding-left: 28px !important; } }

@media (min-width: 980px) {
  .u-padding-left-right-xl-bp-m.u-padding-left-right-xl-bp-m.u-padding-left-right-xl-bp-m {
    padding-left: 28px !important; } }

@media (min-width: 1080px) {
  .u-padding-left-right-xl-bp-l.u-padding-left-right-xl-bp-l.u-padding-left-right-xl-bp-l.u-padding-left-right-xl-bp-l {
    padding-left: 28px !important; } }

@media (min-width: 1200px) {
  .u-padding-left-right-xl-bp-xl.u-padding-left-right-xl-bp-xl.u-padding-left-right-xl-bp-xl.u-padding-left-right-xl-bp-xl.u-padding-left-right-xl-bp-xl {
    padding-left: 28px !important; } }

@media (min-width: 1440px) {
  .u-padding-left-right-xl-bp-xxl.u-padding-left-right-xl-bp-xxl.u-padding-left-right-xl-bp-xxl.u-padding-left-right-xl-bp-xxl.u-padding-left-right-xl-bp-xxl.u-padding-left-right-xl-bp-xxl {
    padding-left: 28px !important; } }

@media (min-width: 540px) {
  .u-padding-left-right-xl-bp-xs {
    padding-right: 28px !important; } }

@media (min-width: 800px) {
  .u-padding-left-right-xl-bp-s.u-padding-left-right-xl-bp-s {
    padding-right: 28px !important; } }

@media (min-width: 980px) {
  .u-padding-left-right-xl-bp-m.u-padding-left-right-xl-bp-m.u-padding-left-right-xl-bp-m {
    padding-right: 28px !important; } }

@media (min-width: 1080px) {
  .u-padding-left-right-xl-bp-l.u-padding-left-right-xl-bp-l.u-padding-left-right-xl-bp-l.u-padding-left-right-xl-bp-l {
    padding-right: 28px !important; } }

@media (min-width: 1200px) {
  .u-padding-left-right-xl-bp-xl.u-padding-left-right-xl-bp-xl.u-padding-left-right-xl-bp-xl.u-padding-left-right-xl-bp-xl.u-padding-left-right-xl-bp-xl {
    padding-right: 28px !important; } }

@media (min-width: 1440px) {
  .u-padding-left-right-xl-bp-xxl.u-padding-left-right-xl-bp-xxl.u-padding-left-right-xl-bp-xxl.u-padding-left-right-xl-bp-xxl.u-padding-left-right-xl-bp-xxl.u-padding-left-right-xl-bp-xxl {
    padding-right: 28px !important; } }

@media (min-width: 540px) {
  .u-padding-top-bottom-xl-bp-xs {
    padding-top: 28px !important; } }

@media (min-width: 800px) {
  .u-padding-top-bottom-xl-bp-s.u-padding-top-bottom-xl-bp-s {
    padding-top: 28px !important; } }

@media (min-width: 980px) {
  .u-padding-top-bottom-xl-bp-m.u-padding-top-bottom-xl-bp-m.u-padding-top-bottom-xl-bp-m {
    padding-top: 28px !important; } }

@media (min-width: 1080px) {
  .u-padding-top-bottom-xl-bp-l.u-padding-top-bottom-xl-bp-l.u-padding-top-bottom-xl-bp-l.u-padding-top-bottom-xl-bp-l {
    padding-top: 28px !important; } }

@media (min-width: 1200px) {
  .u-padding-top-bottom-xl-bp-xl.u-padding-top-bottom-xl-bp-xl.u-padding-top-bottom-xl-bp-xl.u-padding-top-bottom-xl-bp-xl.u-padding-top-bottom-xl-bp-xl {
    padding-top: 28px !important; } }

@media (min-width: 1440px) {
  .u-padding-top-bottom-xl-bp-xxl.u-padding-top-bottom-xl-bp-xxl.u-padding-top-bottom-xl-bp-xxl.u-padding-top-bottom-xl-bp-xxl.u-padding-top-bottom-xl-bp-xxl.u-padding-top-bottom-xl-bp-xxl {
    padding-top: 28px !important; } }

@media (min-width: 540px) {
  .u-padding-top-bottom-xl-bp-xs {
    padding-bottom: 28px !important; } }

@media (min-width: 800px) {
  .u-padding-top-bottom-xl-bp-s.u-padding-top-bottom-xl-bp-s {
    padding-bottom: 28px !important; } }

@media (min-width: 980px) {
  .u-padding-top-bottom-xl-bp-m.u-padding-top-bottom-xl-bp-m.u-padding-top-bottom-xl-bp-m {
    padding-bottom: 28px !important; } }

@media (min-width: 1080px) {
  .u-padding-top-bottom-xl-bp-l.u-padding-top-bottom-xl-bp-l.u-padding-top-bottom-xl-bp-l.u-padding-top-bottom-xl-bp-l {
    padding-bottom: 28px !important; } }

@media (min-width: 1200px) {
  .u-padding-top-bottom-xl-bp-xl.u-padding-top-bottom-xl-bp-xl.u-padding-top-bottom-xl-bp-xl.u-padding-top-bottom-xl-bp-xl.u-padding-top-bottom-xl-bp-xl {
    padding-bottom: 28px !important; } }

@media (min-width: 1440px) {
  .u-padding-top-bottom-xl-bp-xxl.u-padding-top-bottom-xl-bp-xxl.u-padding-top-bottom-xl-bp-xxl.u-padding-top-bottom-xl-bp-xxl.u-padding-top-bottom-xl-bp-xxl.u-padding-top-bottom-xl-bp-xxl {
    padding-bottom: 28px !important; } }

@media (min-width: 540px) {
  .u-padding-top-xl-bp-xs {
    padding-top: 28px !important; } }

@media (min-width: 800px) {
  .u-padding-top-xl-bp-s.u-padding-top-xl-bp-s {
    padding-top: 28px !important; } }

@media (min-width: 980px) {
  .u-padding-top-xl-bp-m.u-padding-top-xl-bp-m.u-padding-top-xl-bp-m {
    padding-top: 28px !important; } }

@media (min-width: 1080px) {
  .u-padding-top-xl-bp-l.u-padding-top-xl-bp-l.u-padding-top-xl-bp-l.u-padding-top-xl-bp-l {
    padding-top: 28px !important; } }

@media (min-width: 1200px) {
  .u-padding-top-xl-bp-xl.u-padding-top-xl-bp-xl.u-padding-top-xl-bp-xl.u-padding-top-xl-bp-xl.u-padding-top-xl-bp-xl {
    padding-top: 28px !important; } }

@media (min-width: 1440px) {
  .u-padding-top-xl-bp-xxl.u-padding-top-xl-bp-xxl.u-padding-top-xl-bp-xxl.u-padding-top-xl-bp-xxl.u-padding-top-xl-bp-xxl.u-padding-top-xl-bp-xxl {
    padding-top: 28px !important; } }

@media (min-width: 540px) {
  .u-padding-right-xl-bp-xs {
    padding-right: 28px !important; } }

@media (min-width: 800px) {
  .u-padding-right-xl-bp-s.u-padding-right-xl-bp-s {
    padding-right: 28px !important; } }

@media (min-width: 980px) {
  .u-padding-right-xl-bp-m.u-padding-right-xl-bp-m.u-padding-right-xl-bp-m {
    padding-right: 28px !important; } }

@media (min-width: 1080px) {
  .u-padding-right-xl-bp-l.u-padding-right-xl-bp-l.u-padding-right-xl-bp-l.u-padding-right-xl-bp-l {
    padding-right: 28px !important; } }

@media (min-width: 1200px) {
  .u-padding-right-xl-bp-xl.u-padding-right-xl-bp-xl.u-padding-right-xl-bp-xl.u-padding-right-xl-bp-xl.u-padding-right-xl-bp-xl {
    padding-right: 28px !important; } }

@media (min-width: 1440px) {
  .u-padding-right-xl-bp-xxl.u-padding-right-xl-bp-xxl.u-padding-right-xl-bp-xxl.u-padding-right-xl-bp-xxl.u-padding-right-xl-bp-xxl.u-padding-right-xl-bp-xxl {
    padding-right: 28px !important; } }

@media (min-width: 540px) {
  .u-padding-bottom-xl-bp-xs {
    padding-bottom: 28px !important; } }

@media (min-width: 800px) {
  .u-padding-bottom-xl-bp-s.u-padding-bottom-xl-bp-s {
    padding-bottom: 28px !important; } }

@media (min-width: 980px) {
  .u-padding-bottom-xl-bp-m.u-padding-bottom-xl-bp-m.u-padding-bottom-xl-bp-m {
    padding-bottom: 28px !important; } }

@media (min-width: 1080px) {
  .u-padding-bottom-xl-bp-l.u-padding-bottom-xl-bp-l.u-padding-bottom-xl-bp-l.u-padding-bottom-xl-bp-l {
    padding-bottom: 28px !important; } }

@media (min-width: 1200px) {
  .u-padding-bottom-xl-bp-xl.u-padding-bottom-xl-bp-xl.u-padding-bottom-xl-bp-xl.u-padding-bottom-xl-bp-xl.u-padding-bottom-xl-bp-xl {
    padding-bottom: 28px !important; } }

@media (min-width: 1440px) {
  .u-padding-bottom-xl-bp-xxl.u-padding-bottom-xl-bp-xxl.u-padding-bottom-xl-bp-xxl.u-padding-bottom-xl-bp-xxl.u-padding-bottom-xl-bp-xxl.u-padding-bottom-xl-bp-xxl {
    padding-bottom: 28px !important; } }

@media (min-width: 540px) {
  .u-padding-left-xl-bp-xs {
    padding-left: 28px !important; } }

@media (min-width: 800px) {
  .u-padding-left-xl-bp-s.u-padding-left-xl-bp-s {
    padding-left: 28px !important; } }

@media (min-width: 980px) {
  .u-padding-left-xl-bp-m.u-padding-left-xl-bp-m.u-padding-left-xl-bp-m {
    padding-left: 28px !important; } }

@media (min-width: 1080px) {
  .u-padding-left-xl-bp-l.u-padding-left-xl-bp-l.u-padding-left-xl-bp-l.u-padding-left-xl-bp-l {
    padding-left: 28px !important; } }

@media (min-width: 1200px) {
  .u-padding-left-xl-bp-xl.u-padding-left-xl-bp-xl.u-padding-left-xl-bp-xl.u-padding-left-xl-bp-xl.u-padding-left-xl-bp-xl {
    padding-left: 28px !important; } }

@media (min-width: 1440px) {
  .u-padding-left-xl-bp-xxl.u-padding-left-xl-bp-xxl.u-padding-left-xl-bp-xxl.u-padding-left-xl-bp-xxl.u-padding-left-xl-bp-xxl.u-padding-left-xl-bp-xxl {
    padding-left: 28px !important; } }

.u-padding-xxl {
  padding: 36px !important; }

.u-padding-left-right-xxl {
  padding-left: 36px !important;
  padding-right: 36px !important; }

.u-padding-top-bottom-xxl {
  padding-top: 36px !important;
  padding-bottom: 36px !important; }

.u-padding-top-xxl {
  padding-top: 36px !important; }

.u-padding-right-xxl {
  padding-right: 36px !important; }

.u-padding-bottom-xxl {
  padding-bottom: 36px !important; }

.u-padding-left-xxl {
  padding-left: 36px !important; }

@media (min-width: 540px) {
  .u-padding-xxl-bp-xs {
    padding: 36px !important; } }

@media (min-width: 800px) {
  .u-padding-xxl-bp-s.u-padding-xxl-bp-s {
    padding: 36px !important; } }

@media (min-width: 980px) {
  .u-padding-xxl-bp-m.u-padding-xxl-bp-m.u-padding-xxl-bp-m {
    padding: 36px !important; } }

@media (min-width: 1080px) {
  .u-padding-xxl-bp-l.u-padding-xxl-bp-l.u-padding-xxl-bp-l.u-padding-xxl-bp-l {
    padding: 36px !important; } }

@media (min-width: 1200px) {
  .u-padding-xxl-bp-xl.u-padding-xxl-bp-xl.u-padding-xxl-bp-xl.u-padding-xxl-bp-xl.u-padding-xxl-bp-xl {
    padding: 36px !important; } }

@media (min-width: 1440px) {
  .u-padding-xxl-bp-xxl.u-padding-xxl-bp-xxl.u-padding-xxl-bp-xxl.u-padding-xxl-bp-xxl.u-padding-xxl-bp-xxl.u-padding-xxl-bp-xxl {
    padding: 36px !important; } }

@media (min-width: 540px) {
  .u-padding-left-right-xxl-bp-xs {
    padding-left: 36px !important; } }

@media (min-width: 800px) {
  .u-padding-left-right-xxl-bp-s.u-padding-left-right-xxl-bp-s {
    padding-left: 36px !important; } }

@media (min-width: 980px) {
  .u-padding-left-right-xxl-bp-m.u-padding-left-right-xxl-bp-m.u-padding-left-right-xxl-bp-m {
    padding-left: 36px !important; } }

@media (min-width: 1080px) {
  .u-padding-left-right-xxl-bp-l.u-padding-left-right-xxl-bp-l.u-padding-left-right-xxl-bp-l.u-padding-left-right-xxl-bp-l {
    padding-left: 36px !important; } }

@media (min-width: 1200px) {
  .u-padding-left-right-xxl-bp-xl.u-padding-left-right-xxl-bp-xl.u-padding-left-right-xxl-bp-xl.u-padding-left-right-xxl-bp-xl.u-padding-left-right-xxl-bp-xl {
    padding-left: 36px !important; } }

@media (min-width: 1440px) {
  .u-padding-left-right-xxl-bp-xxl.u-padding-left-right-xxl-bp-xxl.u-padding-left-right-xxl-bp-xxl.u-padding-left-right-xxl-bp-xxl.u-padding-left-right-xxl-bp-xxl.u-padding-left-right-xxl-bp-xxl {
    padding-left: 36px !important; } }

@media (min-width: 540px) {
  .u-padding-left-right-xxl-bp-xs {
    padding-right: 36px !important; } }

@media (min-width: 800px) {
  .u-padding-left-right-xxl-bp-s.u-padding-left-right-xxl-bp-s {
    padding-right: 36px !important; } }

@media (min-width: 980px) {
  .u-padding-left-right-xxl-bp-m.u-padding-left-right-xxl-bp-m.u-padding-left-right-xxl-bp-m {
    padding-right: 36px !important; } }

@media (min-width: 1080px) {
  .u-padding-left-right-xxl-bp-l.u-padding-left-right-xxl-bp-l.u-padding-left-right-xxl-bp-l.u-padding-left-right-xxl-bp-l {
    padding-right: 36px !important; } }

@media (min-width: 1200px) {
  .u-padding-left-right-xxl-bp-xl.u-padding-left-right-xxl-bp-xl.u-padding-left-right-xxl-bp-xl.u-padding-left-right-xxl-bp-xl.u-padding-left-right-xxl-bp-xl {
    padding-right: 36px !important; } }

@media (min-width: 1440px) {
  .u-padding-left-right-xxl-bp-xxl.u-padding-left-right-xxl-bp-xxl.u-padding-left-right-xxl-bp-xxl.u-padding-left-right-xxl-bp-xxl.u-padding-left-right-xxl-bp-xxl.u-padding-left-right-xxl-bp-xxl {
    padding-right: 36px !important; } }

@media (min-width: 540px) {
  .u-padding-top-bottom-xxl-bp-xs {
    padding-top: 36px !important; } }

@media (min-width: 800px) {
  .u-padding-top-bottom-xxl-bp-s.u-padding-top-bottom-xxl-bp-s {
    padding-top: 36px !important; } }

@media (min-width: 980px) {
  .u-padding-top-bottom-xxl-bp-m.u-padding-top-bottom-xxl-bp-m.u-padding-top-bottom-xxl-bp-m {
    padding-top: 36px !important; } }

@media (min-width: 1080px) {
  .u-padding-top-bottom-xxl-bp-l.u-padding-top-bottom-xxl-bp-l.u-padding-top-bottom-xxl-bp-l.u-padding-top-bottom-xxl-bp-l {
    padding-top: 36px !important; } }

@media (min-width: 1200px) {
  .u-padding-top-bottom-xxl-bp-xl.u-padding-top-bottom-xxl-bp-xl.u-padding-top-bottom-xxl-bp-xl.u-padding-top-bottom-xxl-bp-xl.u-padding-top-bottom-xxl-bp-xl {
    padding-top: 36px !important; } }

@media (min-width: 1440px) {
  .u-padding-top-bottom-xxl-bp-xxl.u-padding-top-bottom-xxl-bp-xxl.u-padding-top-bottom-xxl-bp-xxl.u-padding-top-bottom-xxl-bp-xxl.u-padding-top-bottom-xxl-bp-xxl.u-padding-top-bottom-xxl-bp-xxl {
    padding-top: 36px !important; } }

@media (min-width: 540px) {
  .u-padding-top-bottom-xxl-bp-xs {
    padding-bottom: 36px !important; } }

@media (min-width: 800px) {
  .u-padding-top-bottom-xxl-bp-s.u-padding-top-bottom-xxl-bp-s {
    padding-bottom: 36px !important; } }

@media (min-width: 980px) {
  .u-padding-top-bottom-xxl-bp-m.u-padding-top-bottom-xxl-bp-m.u-padding-top-bottom-xxl-bp-m {
    padding-bottom: 36px !important; } }

@media (min-width: 1080px) {
  .u-padding-top-bottom-xxl-bp-l.u-padding-top-bottom-xxl-bp-l.u-padding-top-bottom-xxl-bp-l.u-padding-top-bottom-xxl-bp-l {
    padding-bottom: 36px !important; } }

@media (min-width: 1200px) {
  .u-padding-top-bottom-xxl-bp-xl.u-padding-top-bottom-xxl-bp-xl.u-padding-top-bottom-xxl-bp-xl.u-padding-top-bottom-xxl-bp-xl.u-padding-top-bottom-xxl-bp-xl {
    padding-bottom: 36px !important; } }

@media (min-width: 1440px) {
  .u-padding-top-bottom-xxl-bp-xxl.u-padding-top-bottom-xxl-bp-xxl.u-padding-top-bottom-xxl-bp-xxl.u-padding-top-bottom-xxl-bp-xxl.u-padding-top-bottom-xxl-bp-xxl.u-padding-top-bottom-xxl-bp-xxl {
    padding-bottom: 36px !important; } }

@media (min-width: 540px) {
  .u-padding-top-xxl-bp-xs {
    padding-top: 36px !important; } }

@media (min-width: 800px) {
  .u-padding-top-xxl-bp-s.u-padding-top-xxl-bp-s {
    padding-top: 36px !important; } }

@media (min-width: 980px) {
  .u-padding-top-xxl-bp-m.u-padding-top-xxl-bp-m.u-padding-top-xxl-bp-m {
    padding-top: 36px !important; } }

@media (min-width: 1080px) {
  .u-padding-top-xxl-bp-l.u-padding-top-xxl-bp-l.u-padding-top-xxl-bp-l.u-padding-top-xxl-bp-l {
    padding-top: 36px !important; } }

@media (min-width: 1200px) {
  .u-padding-top-xxl-bp-xl.u-padding-top-xxl-bp-xl.u-padding-top-xxl-bp-xl.u-padding-top-xxl-bp-xl.u-padding-top-xxl-bp-xl {
    padding-top: 36px !important; } }

@media (min-width: 1440px) {
  .u-padding-top-xxl-bp-xxl.u-padding-top-xxl-bp-xxl.u-padding-top-xxl-bp-xxl.u-padding-top-xxl-bp-xxl.u-padding-top-xxl-bp-xxl.u-padding-top-xxl-bp-xxl {
    padding-top: 36px !important; } }

@media (min-width: 540px) {
  .u-padding-right-xxl-bp-xs {
    padding-right: 36px !important; } }

@media (min-width: 800px) {
  .u-padding-right-xxl-bp-s.u-padding-right-xxl-bp-s {
    padding-right: 36px !important; } }

@media (min-width: 980px) {
  .u-padding-right-xxl-bp-m.u-padding-right-xxl-bp-m.u-padding-right-xxl-bp-m {
    padding-right: 36px !important; } }

@media (min-width: 1080px) {
  .u-padding-right-xxl-bp-l.u-padding-right-xxl-bp-l.u-padding-right-xxl-bp-l.u-padding-right-xxl-bp-l {
    padding-right: 36px !important; } }

@media (min-width: 1200px) {
  .u-padding-right-xxl-bp-xl.u-padding-right-xxl-bp-xl.u-padding-right-xxl-bp-xl.u-padding-right-xxl-bp-xl.u-padding-right-xxl-bp-xl {
    padding-right: 36px !important; } }

@media (min-width: 1440px) {
  .u-padding-right-xxl-bp-xxl.u-padding-right-xxl-bp-xxl.u-padding-right-xxl-bp-xxl.u-padding-right-xxl-bp-xxl.u-padding-right-xxl-bp-xxl.u-padding-right-xxl-bp-xxl {
    padding-right: 36px !important; } }

@media (min-width: 540px) {
  .u-padding-bottom-xxl-bp-xs {
    padding-bottom: 36px !important; } }

@media (min-width: 800px) {
  .u-padding-bottom-xxl-bp-s.u-padding-bottom-xxl-bp-s {
    padding-bottom: 36px !important; } }

@media (min-width: 980px) {
  .u-padding-bottom-xxl-bp-m.u-padding-bottom-xxl-bp-m.u-padding-bottom-xxl-bp-m {
    padding-bottom: 36px !important; } }

@media (min-width: 1080px) {
  .u-padding-bottom-xxl-bp-l.u-padding-bottom-xxl-bp-l.u-padding-bottom-xxl-bp-l.u-padding-bottom-xxl-bp-l {
    padding-bottom: 36px !important; } }

@media (min-width: 1200px) {
  .u-padding-bottom-xxl-bp-xl.u-padding-bottom-xxl-bp-xl.u-padding-bottom-xxl-bp-xl.u-padding-bottom-xxl-bp-xl.u-padding-bottom-xxl-bp-xl {
    padding-bottom: 36px !important; } }

@media (min-width: 1440px) {
  .u-padding-bottom-xxl-bp-xxl.u-padding-bottom-xxl-bp-xxl.u-padding-bottom-xxl-bp-xxl.u-padding-bottom-xxl-bp-xxl.u-padding-bottom-xxl-bp-xxl.u-padding-bottom-xxl-bp-xxl {
    padding-bottom: 36px !important; } }

@media (min-width: 540px) {
  .u-padding-left-xxl-bp-xs {
    padding-left: 36px !important; } }

@media (min-width: 800px) {
  .u-padding-left-xxl-bp-s.u-padding-left-xxl-bp-s {
    padding-left: 36px !important; } }

@media (min-width: 980px) {
  .u-padding-left-xxl-bp-m.u-padding-left-xxl-bp-m.u-padding-left-xxl-bp-m {
    padding-left: 36px !important; } }

@media (min-width: 1080px) {
  .u-padding-left-xxl-bp-l.u-padding-left-xxl-bp-l.u-padding-left-xxl-bp-l.u-padding-left-xxl-bp-l {
    padding-left: 36px !important; } }

@media (min-width: 1200px) {
  .u-padding-left-xxl-bp-xl.u-padding-left-xxl-bp-xl.u-padding-left-xxl-bp-xl.u-padding-left-xxl-bp-xl.u-padding-left-xxl-bp-xl {
    padding-left: 36px !important; } }

@media (min-width: 1440px) {
  .u-padding-left-xxl-bp-xxl.u-padding-left-xxl-bp-xxl.u-padding-left-xxl-bp-xxl.u-padding-left-xxl-bp-xxl.u-padding-left-xxl-bp-xxl.u-padding-left-xxl-bp-xxl {
    padding-left: 36px !important; } }

.u-padding-xxxl {
  padding: 48px !important; }

.u-padding-left-right-xxxl {
  padding-left: 48px !important;
  padding-right: 48px !important; }

.u-padding-top-bottom-xxxl {
  padding-top: 48px !important;
  padding-bottom: 48px !important; }

.u-padding-top-xxxl {
  padding-top: 48px !important; }

.u-padding-right-xxxl {
  padding-right: 48px !important; }

.u-padding-bottom-xxxl {
  padding-bottom: 48px !important; }

.u-padding-left-xxxl {
  padding-left: 48px !important; }

@media (min-width: 540px) {
  .u-padding-xxxl-bp-xs {
    padding: 48px !important; } }

@media (min-width: 800px) {
  .u-padding-xxxl-bp-s.u-padding-xxxl-bp-s {
    padding: 48px !important; } }

@media (min-width: 980px) {
  .u-padding-xxxl-bp-m.u-padding-xxxl-bp-m.u-padding-xxxl-bp-m {
    padding: 48px !important; } }

@media (min-width: 1080px) {
  .u-padding-xxxl-bp-l.u-padding-xxxl-bp-l.u-padding-xxxl-bp-l.u-padding-xxxl-bp-l {
    padding: 48px !important; } }

@media (min-width: 1200px) {
  .u-padding-xxxl-bp-xl.u-padding-xxxl-bp-xl.u-padding-xxxl-bp-xl.u-padding-xxxl-bp-xl.u-padding-xxxl-bp-xl {
    padding: 48px !important; } }

@media (min-width: 1440px) {
  .u-padding-xxxl-bp-xxl.u-padding-xxxl-bp-xxl.u-padding-xxxl-bp-xxl.u-padding-xxxl-bp-xxl.u-padding-xxxl-bp-xxl.u-padding-xxxl-bp-xxl {
    padding: 48px !important; } }

@media (min-width: 540px) {
  .u-padding-left-right-xxxl-bp-xs {
    padding-left: 48px !important; } }

@media (min-width: 800px) {
  .u-padding-left-right-xxxl-bp-s.u-padding-left-right-xxxl-bp-s {
    padding-left: 48px !important; } }

@media (min-width: 980px) {
  .u-padding-left-right-xxxl-bp-m.u-padding-left-right-xxxl-bp-m.u-padding-left-right-xxxl-bp-m {
    padding-left: 48px !important; } }

@media (min-width: 1080px) {
  .u-padding-left-right-xxxl-bp-l.u-padding-left-right-xxxl-bp-l.u-padding-left-right-xxxl-bp-l.u-padding-left-right-xxxl-bp-l {
    padding-left: 48px !important; } }

@media (min-width: 1200px) {
  .u-padding-left-right-xxxl-bp-xl.u-padding-left-right-xxxl-bp-xl.u-padding-left-right-xxxl-bp-xl.u-padding-left-right-xxxl-bp-xl.u-padding-left-right-xxxl-bp-xl {
    padding-left: 48px !important; } }

@media (min-width: 1440px) {
  .u-padding-left-right-xxxl-bp-xxl.u-padding-left-right-xxxl-bp-xxl.u-padding-left-right-xxxl-bp-xxl.u-padding-left-right-xxxl-bp-xxl.u-padding-left-right-xxxl-bp-xxl.u-padding-left-right-xxxl-bp-xxl {
    padding-left: 48px !important; } }

@media (min-width: 540px) {
  .u-padding-left-right-xxxl-bp-xs {
    padding-right: 48px !important; } }

@media (min-width: 800px) {
  .u-padding-left-right-xxxl-bp-s.u-padding-left-right-xxxl-bp-s {
    padding-right: 48px !important; } }

@media (min-width: 980px) {
  .u-padding-left-right-xxxl-bp-m.u-padding-left-right-xxxl-bp-m.u-padding-left-right-xxxl-bp-m {
    padding-right: 48px !important; } }

@media (min-width: 1080px) {
  .u-padding-left-right-xxxl-bp-l.u-padding-left-right-xxxl-bp-l.u-padding-left-right-xxxl-bp-l.u-padding-left-right-xxxl-bp-l {
    padding-right: 48px !important; } }

@media (min-width: 1200px) {
  .u-padding-left-right-xxxl-bp-xl.u-padding-left-right-xxxl-bp-xl.u-padding-left-right-xxxl-bp-xl.u-padding-left-right-xxxl-bp-xl.u-padding-left-right-xxxl-bp-xl {
    padding-right: 48px !important; } }

@media (min-width: 1440px) {
  .u-padding-left-right-xxxl-bp-xxl.u-padding-left-right-xxxl-bp-xxl.u-padding-left-right-xxxl-bp-xxl.u-padding-left-right-xxxl-bp-xxl.u-padding-left-right-xxxl-bp-xxl.u-padding-left-right-xxxl-bp-xxl {
    padding-right: 48px !important; } }

@media (min-width: 540px) {
  .u-padding-top-bottom-xxxl-bp-xs {
    padding-top: 48px !important; } }

@media (min-width: 800px) {
  .u-padding-top-bottom-xxxl-bp-s.u-padding-top-bottom-xxxl-bp-s {
    padding-top: 48px !important; } }

@media (min-width: 980px) {
  .u-padding-top-bottom-xxxl-bp-m.u-padding-top-bottom-xxxl-bp-m.u-padding-top-bottom-xxxl-bp-m {
    padding-top: 48px !important; } }

@media (min-width: 1080px) {
  .u-padding-top-bottom-xxxl-bp-l.u-padding-top-bottom-xxxl-bp-l.u-padding-top-bottom-xxxl-bp-l.u-padding-top-bottom-xxxl-bp-l {
    padding-top: 48px !important; } }

@media (min-width: 1200px) {
  .u-padding-top-bottom-xxxl-bp-xl.u-padding-top-bottom-xxxl-bp-xl.u-padding-top-bottom-xxxl-bp-xl.u-padding-top-bottom-xxxl-bp-xl.u-padding-top-bottom-xxxl-bp-xl {
    padding-top: 48px !important; } }

@media (min-width: 1440px) {
  .u-padding-top-bottom-xxxl-bp-xxl.u-padding-top-bottom-xxxl-bp-xxl.u-padding-top-bottom-xxxl-bp-xxl.u-padding-top-bottom-xxxl-bp-xxl.u-padding-top-bottom-xxxl-bp-xxl.u-padding-top-bottom-xxxl-bp-xxl {
    padding-top: 48px !important; } }

@media (min-width: 540px) {
  .u-padding-top-bottom-xxxl-bp-xs {
    padding-bottom: 48px !important; } }

@media (min-width: 800px) {
  .u-padding-top-bottom-xxxl-bp-s.u-padding-top-bottom-xxxl-bp-s {
    padding-bottom: 48px !important; } }

@media (min-width: 980px) {
  .u-padding-top-bottom-xxxl-bp-m.u-padding-top-bottom-xxxl-bp-m.u-padding-top-bottom-xxxl-bp-m {
    padding-bottom: 48px !important; } }

@media (min-width: 1080px) {
  .u-padding-top-bottom-xxxl-bp-l.u-padding-top-bottom-xxxl-bp-l.u-padding-top-bottom-xxxl-bp-l.u-padding-top-bottom-xxxl-bp-l {
    padding-bottom: 48px !important; } }

@media (min-width: 1200px) {
  .u-padding-top-bottom-xxxl-bp-xl.u-padding-top-bottom-xxxl-bp-xl.u-padding-top-bottom-xxxl-bp-xl.u-padding-top-bottom-xxxl-bp-xl.u-padding-top-bottom-xxxl-bp-xl {
    padding-bottom: 48px !important; } }

@media (min-width: 1440px) {
  .u-padding-top-bottom-xxxl-bp-xxl.u-padding-top-bottom-xxxl-bp-xxl.u-padding-top-bottom-xxxl-bp-xxl.u-padding-top-bottom-xxxl-bp-xxl.u-padding-top-bottom-xxxl-bp-xxl.u-padding-top-bottom-xxxl-bp-xxl {
    padding-bottom: 48px !important; } }

@media (min-width: 540px) {
  .u-padding-top-xxxl-bp-xs {
    padding-top: 48px !important; } }

@media (min-width: 800px) {
  .u-padding-top-xxxl-bp-s.u-padding-top-xxxl-bp-s {
    padding-top: 48px !important; } }

@media (min-width: 980px) {
  .u-padding-top-xxxl-bp-m.u-padding-top-xxxl-bp-m.u-padding-top-xxxl-bp-m {
    padding-top: 48px !important; } }

@media (min-width: 1080px) {
  .u-padding-top-xxxl-bp-l.u-padding-top-xxxl-bp-l.u-padding-top-xxxl-bp-l.u-padding-top-xxxl-bp-l {
    padding-top: 48px !important; } }

@media (min-width: 1200px) {
  .u-padding-top-xxxl-bp-xl.u-padding-top-xxxl-bp-xl.u-padding-top-xxxl-bp-xl.u-padding-top-xxxl-bp-xl.u-padding-top-xxxl-bp-xl {
    padding-top: 48px !important; } }

@media (min-width: 1440px) {
  .u-padding-top-xxxl-bp-xxl.u-padding-top-xxxl-bp-xxl.u-padding-top-xxxl-bp-xxl.u-padding-top-xxxl-bp-xxl.u-padding-top-xxxl-bp-xxl.u-padding-top-xxxl-bp-xxl {
    padding-top: 48px !important; } }

@media (min-width: 540px) {
  .u-padding-right-xxxl-bp-xs {
    padding-right: 48px !important; } }

@media (min-width: 800px) {
  .u-padding-right-xxxl-bp-s.u-padding-right-xxxl-bp-s {
    padding-right: 48px !important; } }

@media (min-width: 980px) {
  .u-padding-right-xxxl-bp-m.u-padding-right-xxxl-bp-m.u-padding-right-xxxl-bp-m {
    padding-right: 48px !important; } }

@media (min-width: 1080px) {
  .u-padding-right-xxxl-bp-l.u-padding-right-xxxl-bp-l.u-padding-right-xxxl-bp-l.u-padding-right-xxxl-bp-l {
    padding-right: 48px !important; } }

@media (min-width: 1200px) {
  .u-padding-right-xxxl-bp-xl.u-padding-right-xxxl-bp-xl.u-padding-right-xxxl-bp-xl.u-padding-right-xxxl-bp-xl.u-padding-right-xxxl-bp-xl {
    padding-right: 48px !important; } }

@media (min-width: 1440px) {
  .u-padding-right-xxxl-bp-xxl.u-padding-right-xxxl-bp-xxl.u-padding-right-xxxl-bp-xxl.u-padding-right-xxxl-bp-xxl.u-padding-right-xxxl-bp-xxl.u-padding-right-xxxl-bp-xxl {
    padding-right: 48px !important; } }

@media (min-width: 540px) {
  .u-padding-bottom-xxxl-bp-xs {
    padding-bottom: 48px !important; } }

@media (min-width: 800px) {
  .u-padding-bottom-xxxl-bp-s.u-padding-bottom-xxxl-bp-s {
    padding-bottom: 48px !important; } }

@media (min-width: 980px) {
  .u-padding-bottom-xxxl-bp-m.u-padding-bottom-xxxl-bp-m.u-padding-bottom-xxxl-bp-m {
    padding-bottom: 48px !important; } }

@media (min-width: 1080px) {
  .u-padding-bottom-xxxl-bp-l.u-padding-bottom-xxxl-bp-l.u-padding-bottom-xxxl-bp-l.u-padding-bottom-xxxl-bp-l {
    padding-bottom: 48px !important; } }

@media (min-width: 1200px) {
  .u-padding-bottom-xxxl-bp-xl.u-padding-bottom-xxxl-bp-xl.u-padding-bottom-xxxl-bp-xl.u-padding-bottom-xxxl-bp-xl.u-padding-bottom-xxxl-bp-xl {
    padding-bottom: 48px !important; } }

@media (min-width: 1440px) {
  .u-padding-bottom-xxxl-bp-xxl.u-padding-bottom-xxxl-bp-xxl.u-padding-bottom-xxxl-bp-xxl.u-padding-bottom-xxxl-bp-xxl.u-padding-bottom-xxxl-bp-xxl.u-padding-bottom-xxxl-bp-xxl {
    padding-bottom: 48px !important; } }

@media (min-width: 540px) {
  .u-padding-left-xxxl-bp-xs {
    padding-left: 48px !important; } }

@media (min-width: 800px) {
  .u-padding-left-xxxl-bp-s.u-padding-left-xxxl-bp-s {
    padding-left: 48px !important; } }

@media (min-width: 980px) {
  .u-padding-left-xxxl-bp-m.u-padding-left-xxxl-bp-m.u-padding-left-xxxl-bp-m {
    padding-left: 48px !important; } }

@media (min-width: 1080px) {
  .u-padding-left-xxxl-bp-l.u-padding-left-xxxl-bp-l.u-padding-left-xxxl-bp-l.u-padding-left-xxxl-bp-l {
    padding-left: 48px !important; } }

@media (min-width: 1200px) {
  .u-padding-left-xxxl-bp-xl.u-padding-left-xxxl-bp-xl.u-padding-left-xxxl-bp-xl.u-padding-left-xxxl-bp-xl.u-padding-left-xxxl-bp-xl {
    padding-left: 48px !important; } }

@media (min-width: 1440px) {
  .u-padding-left-xxxl-bp-xxl.u-padding-left-xxxl-bp-xxl.u-padding-left-xxxl-bp-xxl.u-padding-left-xxxl-bp-xxl.u-padding-left-xxxl-bp-xxl.u-padding-left-xxxl-bp-xxl {
    padding-left: 48px !important; } }

.u-padding-xxxxl {
  padding: 60px !important; }

.u-padding-left-right-xxxxl {
  padding-left: 60px !important;
  padding-right: 60px !important; }

.u-padding-top-bottom-xxxxl {
  padding-top: 60px !important;
  padding-bottom: 60px !important; }

.u-padding-top-xxxxl {
  padding-top: 60px !important; }

.u-padding-right-xxxxl {
  padding-right: 60px !important; }

.u-padding-bottom-xxxxl {
  padding-bottom: 60px !important; }

.u-padding-left-xxxxl {
  padding-left: 60px !important; }

@media (min-width: 540px) {
  .u-padding-xxxxl-bp-xs {
    padding: 60px !important; } }

@media (min-width: 800px) {
  .u-padding-xxxxl-bp-s.u-padding-xxxxl-bp-s {
    padding: 60px !important; } }

@media (min-width: 980px) {
  .u-padding-xxxxl-bp-m.u-padding-xxxxl-bp-m.u-padding-xxxxl-bp-m {
    padding: 60px !important; } }

@media (min-width: 1080px) {
  .u-padding-xxxxl-bp-l.u-padding-xxxxl-bp-l.u-padding-xxxxl-bp-l.u-padding-xxxxl-bp-l {
    padding: 60px !important; } }

@media (min-width: 1200px) {
  .u-padding-xxxxl-bp-xl.u-padding-xxxxl-bp-xl.u-padding-xxxxl-bp-xl.u-padding-xxxxl-bp-xl.u-padding-xxxxl-bp-xl {
    padding: 60px !important; } }

@media (min-width: 1440px) {
  .u-padding-xxxxl-bp-xxl.u-padding-xxxxl-bp-xxl.u-padding-xxxxl-bp-xxl.u-padding-xxxxl-bp-xxl.u-padding-xxxxl-bp-xxl.u-padding-xxxxl-bp-xxl {
    padding: 60px !important; } }

@media (min-width: 540px) {
  .u-padding-left-right-xxxxl-bp-xs {
    padding-left: 60px !important; } }

@media (min-width: 800px) {
  .u-padding-left-right-xxxxl-bp-s.u-padding-left-right-xxxxl-bp-s {
    padding-left: 60px !important; } }

@media (min-width: 980px) {
  .u-padding-left-right-xxxxl-bp-m.u-padding-left-right-xxxxl-bp-m.u-padding-left-right-xxxxl-bp-m {
    padding-left: 60px !important; } }

@media (min-width: 1080px) {
  .u-padding-left-right-xxxxl-bp-l.u-padding-left-right-xxxxl-bp-l.u-padding-left-right-xxxxl-bp-l.u-padding-left-right-xxxxl-bp-l {
    padding-left: 60px !important; } }

@media (min-width: 1200px) {
  .u-padding-left-right-xxxxl-bp-xl.u-padding-left-right-xxxxl-bp-xl.u-padding-left-right-xxxxl-bp-xl.u-padding-left-right-xxxxl-bp-xl.u-padding-left-right-xxxxl-bp-xl {
    padding-left: 60px !important; } }

@media (min-width: 1440px) {
  .u-padding-left-right-xxxxl-bp-xxl.u-padding-left-right-xxxxl-bp-xxl.u-padding-left-right-xxxxl-bp-xxl.u-padding-left-right-xxxxl-bp-xxl.u-padding-left-right-xxxxl-bp-xxl.u-padding-left-right-xxxxl-bp-xxl {
    padding-left: 60px !important; } }

@media (min-width: 540px) {
  .u-padding-left-right-xxxxl-bp-xs {
    padding-right: 60px !important; } }

@media (min-width: 800px) {
  .u-padding-left-right-xxxxl-bp-s.u-padding-left-right-xxxxl-bp-s {
    padding-right: 60px !important; } }

@media (min-width: 980px) {
  .u-padding-left-right-xxxxl-bp-m.u-padding-left-right-xxxxl-bp-m.u-padding-left-right-xxxxl-bp-m {
    padding-right: 60px !important; } }

@media (min-width: 1080px) {
  .u-padding-left-right-xxxxl-bp-l.u-padding-left-right-xxxxl-bp-l.u-padding-left-right-xxxxl-bp-l.u-padding-left-right-xxxxl-bp-l {
    padding-right: 60px !important; } }

@media (min-width: 1200px) {
  .u-padding-left-right-xxxxl-bp-xl.u-padding-left-right-xxxxl-bp-xl.u-padding-left-right-xxxxl-bp-xl.u-padding-left-right-xxxxl-bp-xl.u-padding-left-right-xxxxl-bp-xl {
    padding-right: 60px !important; } }

@media (min-width: 1440px) {
  .u-padding-left-right-xxxxl-bp-xxl.u-padding-left-right-xxxxl-bp-xxl.u-padding-left-right-xxxxl-bp-xxl.u-padding-left-right-xxxxl-bp-xxl.u-padding-left-right-xxxxl-bp-xxl.u-padding-left-right-xxxxl-bp-xxl {
    padding-right: 60px !important; } }

@media (min-width: 540px) {
  .u-padding-top-bottom-xxxxl-bp-xs {
    padding-top: 60px !important; } }

@media (min-width: 800px) {
  .u-padding-top-bottom-xxxxl-bp-s.u-padding-top-bottom-xxxxl-bp-s {
    padding-top: 60px !important; } }

@media (min-width: 980px) {
  .u-padding-top-bottom-xxxxl-bp-m.u-padding-top-bottom-xxxxl-bp-m.u-padding-top-bottom-xxxxl-bp-m {
    padding-top: 60px !important; } }

@media (min-width: 1080px) {
  .u-padding-top-bottom-xxxxl-bp-l.u-padding-top-bottom-xxxxl-bp-l.u-padding-top-bottom-xxxxl-bp-l.u-padding-top-bottom-xxxxl-bp-l {
    padding-top: 60px !important; } }

@media (min-width: 1200px) {
  .u-padding-top-bottom-xxxxl-bp-xl.u-padding-top-bottom-xxxxl-bp-xl.u-padding-top-bottom-xxxxl-bp-xl.u-padding-top-bottom-xxxxl-bp-xl.u-padding-top-bottom-xxxxl-bp-xl {
    padding-top: 60px !important; } }

@media (min-width: 1440px) {
  .u-padding-top-bottom-xxxxl-bp-xxl.u-padding-top-bottom-xxxxl-bp-xxl.u-padding-top-bottom-xxxxl-bp-xxl.u-padding-top-bottom-xxxxl-bp-xxl.u-padding-top-bottom-xxxxl-bp-xxl.u-padding-top-bottom-xxxxl-bp-xxl {
    padding-top: 60px !important; } }

@media (min-width: 540px) {
  .u-padding-top-bottom-xxxxl-bp-xs {
    padding-bottom: 60px !important; } }

@media (min-width: 800px) {
  .u-padding-top-bottom-xxxxl-bp-s.u-padding-top-bottom-xxxxl-bp-s {
    padding-bottom: 60px !important; } }

@media (min-width: 980px) {
  .u-padding-top-bottom-xxxxl-bp-m.u-padding-top-bottom-xxxxl-bp-m.u-padding-top-bottom-xxxxl-bp-m {
    padding-bottom: 60px !important; } }

@media (min-width: 1080px) {
  .u-padding-top-bottom-xxxxl-bp-l.u-padding-top-bottom-xxxxl-bp-l.u-padding-top-bottom-xxxxl-bp-l.u-padding-top-bottom-xxxxl-bp-l {
    padding-bottom: 60px !important; } }

@media (min-width: 1200px) {
  .u-padding-top-bottom-xxxxl-bp-xl.u-padding-top-bottom-xxxxl-bp-xl.u-padding-top-bottom-xxxxl-bp-xl.u-padding-top-bottom-xxxxl-bp-xl.u-padding-top-bottom-xxxxl-bp-xl {
    padding-bottom: 60px !important; } }

@media (min-width: 1440px) {
  .u-padding-top-bottom-xxxxl-bp-xxl.u-padding-top-bottom-xxxxl-bp-xxl.u-padding-top-bottom-xxxxl-bp-xxl.u-padding-top-bottom-xxxxl-bp-xxl.u-padding-top-bottom-xxxxl-bp-xxl.u-padding-top-bottom-xxxxl-bp-xxl {
    padding-bottom: 60px !important; } }

@media (min-width: 540px) {
  .u-padding-top-xxxxl-bp-xs {
    padding-top: 60px !important; } }

@media (min-width: 800px) {
  .u-padding-top-xxxxl-bp-s.u-padding-top-xxxxl-bp-s {
    padding-top: 60px !important; } }

@media (min-width: 980px) {
  .u-padding-top-xxxxl-bp-m.u-padding-top-xxxxl-bp-m.u-padding-top-xxxxl-bp-m {
    padding-top: 60px !important; } }

@media (min-width: 1080px) {
  .u-padding-top-xxxxl-bp-l.u-padding-top-xxxxl-bp-l.u-padding-top-xxxxl-bp-l.u-padding-top-xxxxl-bp-l {
    padding-top: 60px !important; } }

@media (min-width: 1200px) {
  .u-padding-top-xxxxl-bp-xl.u-padding-top-xxxxl-bp-xl.u-padding-top-xxxxl-bp-xl.u-padding-top-xxxxl-bp-xl.u-padding-top-xxxxl-bp-xl {
    padding-top: 60px !important; } }

@media (min-width: 1440px) {
  .u-padding-top-xxxxl-bp-xxl.u-padding-top-xxxxl-bp-xxl.u-padding-top-xxxxl-bp-xxl.u-padding-top-xxxxl-bp-xxl.u-padding-top-xxxxl-bp-xxl.u-padding-top-xxxxl-bp-xxl {
    padding-top: 60px !important; } }

@media (min-width: 540px) {
  .u-padding-right-xxxxl-bp-xs {
    padding-right: 60px !important; } }

@media (min-width: 800px) {
  .u-padding-right-xxxxl-bp-s.u-padding-right-xxxxl-bp-s {
    padding-right: 60px !important; } }

@media (min-width: 980px) {
  .u-padding-right-xxxxl-bp-m.u-padding-right-xxxxl-bp-m.u-padding-right-xxxxl-bp-m {
    padding-right: 60px !important; } }

@media (min-width: 1080px) {
  .u-padding-right-xxxxl-bp-l.u-padding-right-xxxxl-bp-l.u-padding-right-xxxxl-bp-l.u-padding-right-xxxxl-bp-l {
    padding-right: 60px !important; } }

@media (min-width: 1200px) {
  .u-padding-right-xxxxl-bp-xl.u-padding-right-xxxxl-bp-xl.u-padding-right-xxxxl-bp-xl.u-padding-right-xxxxl-bp-xl.u-padding-right-xxxxl-bp-xl {
    padding-right: 60px !important; } }

@media (min-width: 1440px) {
  .u-padding-right-xxxxl-bp-xxl.u-padding-right-xxxxl-bp-xxl.u-padding-right-xxxxl-bp-xxl.u-padding-right-xxxxl-bp-xxl.u-padding-right-xxxxl-bp-xxl.u-padding-right-xxxxl-bp-xxl {
    padding-right: 60px !important; } }

@media (min-width: 540px) {
  .u-padding-bottom-xxxxl-bp-xs {
    padding-bottom: 60px !important; } }

@media (min-width: 800px) {
  .u-padding-bottom-xxxxl-bp-s.u-padding-bottom-xxxxl-bp-s {
    padding-bottom: 60px !important; } }

@media (min-width: 980px) {
  .u-padding-bottom-xxxxl-bp-m.u-padding-bottom-xxxxl-bp-m.u-padding-bottom-xxxxl-bp-m {
    padding-bottom: 60px !important; } }

@media (min-width: 1080px) {
  .u-padding-bottom-xxxxl-bp-l.u-padding-bottom-xxxxl-bp-l.u-padding-bottom-xxxxl-bp-l.u-padding-bottom-xxxxl-bp-l {
    padding-bottom: 60px !important; } }

@media (min-width: 1200px) {
  .u-padding-bottom-xxxxl-bp-xl.u-padding-bottom-xxxxl-bp-xl.u-padding-bottom-xxxxl-bp-xl.u-padding-bottom-xxxxl-bp-xl.u-padding-bottom-xxxxl-bp-xl {
    padding-bottom: 60px !important; } }

@media (min-width: 1440px) {
  .u-padding-bottom-xxxxl-bp-xxl.u-padding-bottom-xxxxl-bp-xxl.u-padding-bottom-xxxxl-bp-xxl.u-padding-bottom-xxxxl-bp-xxl.u-padding-bottom-xxxxl-bp-xxl.u-padding-bottom-xxxxl-bp-xxl {
    padding-bottom: 60px !important; } }

@media (min-width: 540px) {
  .u-padding-left-xxxxl-bp-xs {
    padding-left: 60px !important; } }

@media (min-width: 800px) {
  .u-padding-left-xxxxl-bp-s.u-padding-left-xxxxl-bp-s {
    padding-left: 60px !important; } }

@media (min-width: 980px) {
  .u-padding-left-xxxxl-bp-m.u-padding-left-xxxxl-bp-m.u-padding-left-xxxxl-bp-m {
    padding-left: 60px !important; } }

@media (min-width: 1080px) {
  .u-padding-left-xxxxl-bp-l.u-padding-left-xxxxl-bp-l.u-padding-left-xxxxl-bp-l.u-padding-left-xxxxl-bp-l {
    padding-left: 60px !important; } }

@media (min-width: 1200px) {
  .u-padding-left-xxxxl-bp-xl.u-padding-left-xxxxl-bp-xl.u-padding-left-xxxxl-bp-xl.u-padding-left-xxxxl-bp-xl.u-padding-left-xxxxl-bp-xl {
    padding-left: 60px !important; } }

@media (min-width: 1440px) {
  .u-padding-left-xxxxl-bp-xxl.u-padding-left-xxxxl-bp-xxl.u-padding-left-xxxxl-bp-xxl.u-padding-left-xxxxl-bp-xxl.u-padding-left-xxxxl-bp-xxl.u-padding-left-xxxxl-bp-xxl {
    padding-left: 60px !important; } }

.u-padding-right-55 {
  padding-right: 55px !important; }

@media (max-width: 420px) {
  .u-padding-left-right-4 {
    padding-left: 4px !important;
    padding-right: 4px !important; } }

/* -- Margin ------------------------ */
.u-margin-0 {
  margin: 0px !important; }

.u-margin-left-right-0 {
  margin-left: 0px !important;
  margin-right: 0px !important; }

.u-margin-top-bottom-0 {
  margin-top: 0px !important;
  margin-bottom: 0px !important; }

.u-margin-top-0 {
  margin-top: 0px !important; }

.u-margin-right-0 {
  margin-right: 0px !important; }

.u-margin-bottom-0 {
  margin-bottom: 0px !important; }

.u-margin-left-0 {
  margin-left: 0px !important; }

@media (min-width: 540px) {
  .u-margin-0-bp-xs {
    margin: 0px !important; } }

@media (min-width: 800px) {
  .u-margin-0-bp-s.u-margin-0-bp-s {
    margin: 0px !important; } }

@media (min-width: 980px) {
  .u-margin-0-bp-m.u-margin-0-bp-m.u-margin-0-bp-m {
    margin: 0px !important; } }

@media (min-width: 1080px) {
  .u-margin-0-bp-l.u-margin-0-bp-l.u-margin-0-bp-l.u-margin-0-bp-l {
    margin: 0px !important; } }

@media (min-width: 1200px) {
  .u-margin-0-bp-xl.u-margin-0-bp-xl.u-margin-0-bp-xl.u-margin-0-bp-xl.u-margin-0-bp-xl {
    margin: 0px !important; } }

@media (min-width: 1440px) {
  .u-margin-0-bp-xxl.u-margin-0-bp-xxl.u-margin-0-bp-xxl.u-margin-0-bp-xxl.u-margin-0-bp-xxl.u-margin-0-bp-xxl {
    margin: 0px !important; } }

@media (min-width: 540px) {
  .u-margin-left-right-0-bp-xs {
    margin-left: 0px !important; } }

@media (min-width: 800px) {
  .u-margin-left-right-0-bp-s.u-margin-left-right-0-bp-s {
    margin-left: 0px !important; } }

@media (min-width: 980px) {
  .u-margin-left-right-0-bp-m.u-margin-left-right-0-bp-m.u-margin-left-right-0-bp-m {
    margin-left: 0px !important; } }

@media (min-width: 1080px) {
  .u-margin-left-right-0-bp-l.u-margin-left-right-0-bp-l.u-margin-left-right-0-bp-l.u-margin-left-right-0-bp-l {
    margin-left: 0px !important; } }

@media (min-width: 1200px) {
  .u-margin-left-right-0-bp-xl.u-margin-left-right-0-bp-xl.u-margin-left-right-0-bp-xl.u-margin-left-right-0-bp-xl.u-margin-left-right-0-bp-xl {
    margin-left: 0px !important; } }

@media (min-width: 1440px) {
  .u-margin-left-right-0-bp-xxl.u-margin-left-right-0-bp-xxl.u-margin-left-right-0-bp-xxl.u-margin-left-right-0-bp-xxl.u-margin-left-right-0-bp-xxl.u-margin-left-right-0-bp-xxl {
    margin-left: 0px !important; } }

@media (min-width: 540px) {
  .u-margin-left-right-0-bp-xs {
    margin-right: 0px !important; } }

@media (min-width: 800px) {
  .u-margin-left-right-0-bp-s.u-margin-left-right-0-bp-s {
    margin-right: 0px !important; } }

@media (min-width: 980px) {
  .u-margin-left-right-0-bp-m.u-margin-left-right-0-bp-m.u-margin-left-right-0-bp-m {
    margin-right: 0px !important; } }

@media (min-width: 1080px) {
  .u-margin-left-right-0-bp-l.u-margin-left-right-0-bp-l.u-margin-left-right-0-bp-l.u-margin-left-right-0-bp-l {
    margin-right: 0px !important; } }

@media (min-width: 1200px) {
  .u-margin-left-right-0-bp-xl.u-margin-left-right-0-bp-xl.u-margin-left-right-0-bp-xl.u-margin-left-right-0-bp-xl.u-margin-left-right-0-bp-xl {
    margin-right: 0px !important; } }

@media (min-width: 1440px) {
  .u-margin-left-right-0-bp-xxl.u-margin-left-right-0-bp-xxl.u-margin-left-right-0-bp-xxl.u-margin-left-right-0-bp-xxl.u-margin-left-right-0-bp-xxl.u-margin-left-right-0-bp-xxl {
    margin-right: 0px !important; } }

@media (min-width: 540px) {
  .u-margin-top-bottom-0-bp-xs {
    margin-top: 0px !important; } }

@media (min-width: 800px) {
  .u-margin-top-bottom-0-bp-s.u-margin-top-bottom-0-bp-s {
    margin-top: 0px !important; } }

@media (min-width: 980px) {
  .u-margin-top-bottom-0-bp-m.u-margin-top-bottom-0-bp-m.u-margin-top-bottom-0-bp-m {
    margin-top: 0px !important; } }

@media (min-width: 1080px) {
  .u-margin-top-bottom-0-bp-l.u-margin-top-bottom-0-bp-l.u-margin-top-bottom-0-bp-l.u-margin-top-bottom-0-bp-l {
    margin-top: 0px !important; } }

@media (min-width: 1200px) {
  .u-margin-top-bottom-0-bp-xl.u-margin-top-bottom-0-bp-xl.u-margin-top-bottom-0-bp-xl.u-margin-top-bottom-0-bp-xl.u-margin-top-bottom-0-bp-xl {
    margin-top: 0px !important; } }

@media (min-width: 1440px) {
  .u-margin-top-bottom-0-bp-xxl.u-margin-top-bottom-0-bp-xxl.u-margin-top-bottom-0-bp-xxl.u-margin-top-bottom-0-bp-xxl.u-margin-top-bottom-0-bp-xxl.u-margin-top-bottom-0-bp-xxl {
    margin-top: 0px !important; } }

@media (min-width: 540px) {
  .u-margin-top-bottom-0-bp-xs {
    margin-bottom: 0px !important; } }

@media (min-width: 800px) {
  .u-margin-top-bottom-0-bp-s.u-margin-top-bottom-0-bp-s {
    margin-bottom: 0px !important; } }

@media (min-width: 980px) {
  .u-margin-top-bottom-0-bp-m.u-margin-top-bottom-0-bp-m.u-margin-top-bottom-0-bp-m {
    margin-bottom: 0px !important; } }

@media (min-width: 1080px) {
  .u-margin-top-bottom-0-bp-l.u-margin-top-bottom-0-bp-l.u-margin-top-bottom-0-bp-l.u-margin-top-bottom-0-bp-l {
    margin-bottom: 0px !important; } }

@media (min-width: 1200px) {
  .u-margin-top-bottom-0-bp-xl.u-margin-top-bottom-0-bp-xl.u-margin-top-bottom-0-bp-xl.u-margin-top-bottom-0-bp-xl.u-margin-top-bottom-0-bp-xl {
    margin-bottom: 0px !important; } }

@media (min-width: 1440px) {
  .u-margin-top-bottom-0-bp-xxl.u-margin-top-bottom-0-bp-xxl.u-margin-top-bottom-0-bp-xxl.u-margin-top-bottom-0-bp-xxl.u-margin-top-bottom-0-bp-xxl.u-margin-top-bottom-0-bp-xxl {
    margin-bottom: 0px !important; } }

@media (min-width: 540px) {
  .u-margin-top-0-bp-xs {
    margin-top: 0px !important; } }

@media (min-width: 800px) {
  .u-margin-top-0-bp-s.u-margin-top-0-bp-s {
    margin-top: 0px !important; } }

@media (min-width: 980px) {
  .u-margin-top-0-bp-m.u-margin-top-0-bp-m.u-margin-top-0-bp-m {
    margin-top: 0px !important; } }

@media (min-width: 1080px) {
  .u-margin-top-0-bp-l.u-margin-top-0-bp-l.u-margin-top-0-bp-l.u-margin-top-0-bp-l {
    margin-top: 0px !important; } }

@media (min-width: 1200px) {
  .u-margin-top-0-bp-xl.u-margin-top-0-bp-xl.u-margin-top-0-bp-xl.u-margin-top-0-bp-xl.u-margin-top-0-bp-xl {
    margin-top: 0px !important; } }

@media (min-width: 1440px) {
  .u-margin-top-0-bp-xxl.u-margin-top-0-bp-xxl.u-margin-top-0-bp-xxl.u-margin-top-0-bp-xxl.u-margin-top-0-bp-xxl.u-margin-top-0-bp-xxl {
    margin-top: 0px !important; } }

@media (min-width: 540px) {
  .u-margin-right-0-bp-xs {
    margin-right: 0px !important; } }

@media (min-width: 800px) {
  .u-margin-right-0-bp-s.u-margin-right-0-bp-s {
    margin-right: 0px !important; } }

@media (min-width: 980px) {
  .u-margin-right-0-bp-m.u-margin-right-0-bp-m.u-margin-right-0-bp-m {
    margin-right: 0px !important; } }

@media (min-width: 1080px) {
  .u-margin-right-0-bp-l.u-margin-right-0-bp-l.u-margin-right-0-bp-l.u-margin-right-0-bp-l {
    margin-right: 0px !important; } }

@media (min-width: 1200px) {
  .u-margin-right-0-bp-xl.u-margin-right-0-bp-xl.u-margin-right-0-bp-xl.u-margin-right-0-bp-xl.u-margin-right-0-bp-xl {
    margin-right: 0px !important; } }

@media (min-width: 1440px) {
  .u-margin-right-0-bp-xxl.u-margin-right-0-bp-xxl.u-margin-right-0-bp-xxl.u-margin-right-0-bp-xxl.u-margin-right-0-bp-xxl.u-margin-right-0-bp-xxl {
    margin-right: 0px !important; } }

@media (min-width: 540px) {
  .u-margin-bottom-0-bp-xs {
    margin-bottom: 0px !important; } }

@media (min-width: 800px) {
  .u-margin-bottom-0-bp-s.u-margin-bottom-0-bp-s {
    margin-bottom: 0px !important; } }

@media (min-width: 980px) {
  .u-margin-bottom-0-bp-m.u-margin-bottom-0-bp-m.u-margin-bottom-0-bp-m {
    margin-bottom: 0px !important; } }

@media (min-width: 1080px) {
  .u-margin-bottom-0-bp-l.u-margin-bottom-0-bp-l.u-margin-bottom-0-bp-l.u-margin-bottom-0-bp-l {
    margin-bottom: 0px !important; } }

@media (min-width: 1200px) {
  .u-margin-bottom-0-bp-xl.u-margin-bottom-0-bp-xl.u-margin-bottom-0-bp-xl.u-margin-bottom-0-bp-xl.u-margin-bottom-0-bp-xl {
    margin-bottom: 0px !important; } }

@media (min-width: 1440px) {
  .u-margin-bottom-0-bp-xxl.u-margin-bottom-0-bp-xxl.u-margin-bottom-0-bp-xxl.u-margin-bottom-0-bp-xxl.u-margin-bottom-0-bp-xxl.u-margin-bottom-0-bp-xxl {
    margin-bottom: 0px !important; } }

@media (min-width: 540px) {
  .u-margin-left-0-bp-xs {
    margin-left: 0px !important; } }

@media (min-width: 800px) {
  .u-margin-left-0-bp-s.u-margin-left-0-bp-s {
    margin-left: 0px !important; } }

@media (min-width: 980px) {
  .u-margin-left-0-bp-m.u-margin-left-0-bp-m.u-margin-left-0-bp-m {
    margin-left: 0px !important; } }

@media (min-width: 1080px) {
  .u-margin-left-0-bp-l.u-margin-left-0-bp-l.u-margin-left-0-bp-l.u-margin-left-0-bp-l {
    margin-left: 0px !important; } }

@media (min-width: 1200px) {
  .u-margin-left-0-bp-xl.u-margin-left-0-bp-xl.u-margin-left-0-bp-xl.u-margin-left-0-bp-xl.u-margin-left-0-bp-xl {
    margin-left: 0px !important; } }

@media (min-width: 1440px) {
  .u-margin-left-0-bp-xxl.u-margin-left-0-bp-xxl.u-margin-left-0-bp-xxl.u-margin-left-0-bp-xxl.u-margin-left-0-bp-xxl.u-margin-left-0-bp-xxl {
    margin-left: 0px !important; } }

.u-margin-xxs {
  margin: 8px !important; }

.u-margin-left-right-xxs {
  margin-left: 8px !important;
  margin-right: 8px !important; }

.u-margin-top-bottom-xxs {
  margin-top: 8px !important;
  margin-bottom: 8px !important; }

.u-margin-top-xxs {
  margin-top: 8px !important; }

.u-margin-right-xxs {
  margin-right: 8px !important; }

.u-margin-bottom-xxs {
  margin-bottom: 8px !important; }

.u-margin-left-xxs {
  margin-left: 8px !important; }

@media (min-width: 540px) {
  .u-margin-xxs-bp-xs {
    margin: 8px !important; } }

@media (min-width: 800px) {
  .u-margin-xxs-bp-s.u-margin-xxs-bp-s {
    margin: 8px !important; } }

@media (min-width: 980px) {
  .u-margin-xxs-bp-m.u-margin-xxs-bp-m.u-margin-xxs-bp-m {
    margin: 8px !important; } }

@media (min-width: 1080px) {
  .u-margin-xxs-bp-l.u-margin-xxs-bp-l.u-margin-xxs-bp-l.u-margin-xxs-bp-l {
    margin: 8px !important; } }

@media (min-width: 1200px) {
  .u-margin-xxs-bp-xl.u-margin-xxs-bp-xl.u-margin-xxs-bp-xl.u-margin-xxs-bp-xl.u-margin-xxs-bp-xl {
    margin: 8px !important; } }

@media (min-width: 1440px) {
  .u-margin-xxs-bp-xxl.u-margin-xxs-bp-xxl.u-margin-xxs-bp-xxl.u-margin-xxs-bp-xxl.u-margin-xxs-bp-xxl.u-margin-xxs-bp-xxl {
    margin: 8px !important; } }

@media (min-width: 540px) {
  .u-margin-left-right-xxs-bp-xs {
    margin-left: 8px !important; } }

@media (min-width: 800px) {
  .u-margin-left-right-xxs-bp-s.u-margin-left-right-xxs-bp-s {
    margin-left: 8px !important; } }

@media (min-width: 980px) {
  .u-margin-left-right-xxs-bp-m.u-margin-left-right-xxs-bp-m.u-margin-left-right-xxs-bp-m {
    margin-left: 8px !important; } }

@media (min-width: 1080px) {
  .u-margin-left-right-xxs-bp-l.u-margin-left-right-xxs-bp-l.u-margin-left-right-xxs-bp-l.u-margin-left-right-xxs-bp-l {
    margin-left: 8px !important; } }

@media (min-width: 1200px) {
  .u-margin-left-right-xxs-bp-xl.u-margin-left-right-xxs-bp-xl.u-margin-left-right-xxs-bp-xl.u-margin-left-right-xxs-bp-xl.u-margin-left-right-xxs-bp-xl {
    margin-left: 8px !important; } }

@media (min-width: 1440px) {
  .u-margin-left-right-xxs-bp-xxl.u-margin-left-right-xxs-bp-xxl.u-margin-left-right-xxs-bp-xxl.u-margin-left-right-xxs-bp-xxl.u-margin-left-right-xxs-bp-xxl.u-margin-left-right-xxs-bp-xxl {
    margin-left: 8px !important; } }

@media (min-width: 540px) {
  .u-margin-left-right-xxs-bp-xs {
    margin-right: 8px !important; } }

@media (min-width: 800px) {
  .u-margin-left-right-xxs-bp-s.u-margin-left-right-xxs-bp-s {
    margin-right: 8px !important; } }

@media (min-width: 980px) {
  .u-margin-left-right-xxs-bp-m.u-margin-left-right-xxs-bp-m.u-margin-left-right-xxs-bp-m {
    margin-right: 8px !important; } }

@media (min-width: 1080px) {
  .u-margin-left-right-xxs-bp-l.u-margin-left-right-xxs-bp-l.u-margin-left-right-xxs-bp-l.u-margin-left-right-xxs-bp-l {
    margin-right: 8px !important; } }

@media (min-width: 1200px) {
  .u-margin-left-right-xxs-bp-xl.u-margin-left-right-xxs-bp-xl.u-margin-left-right-xxs-bp-xl.u-margin-left-right-xxs-bp-xl.u-margin-left-right-xxs-bp-xl {
    margin-right: 8px !important; } }

@media (min-width: 1440px) {
  .u-margin-left-right-xxs-bp-xxl.u-margin-left-right-xxs-bp-xxl.u-margin-left-right-xxs-bp-xxl.u-margin-left-right-xxs-bp-xxl.u-margin-left-right-xxs-bp-xxl.u-margin-left-right-xxs-bp-xxl {
    margin-right: 8px !important; } }

@media (min-width: 540px) {
  .u-margin-top-bottom-xxs-bp-xs {
    margin-top: 8px !important; } }

@media (min-width: 800px) {
  .u-margin-top-bottom-xxs-bp-s.u-margin-top-bottom-xxs-bp-s {
    margin-top: 8px !important; } }

@media (min-width: 980px) {
  .u-margin-top-bottom-xxs-bp-m.u-margin-top-bottom-xxs-bp-m.u-margin-top-bottom-xxs-bp-m {
    margin-top: 8px !important; } }

@media (min-width: 1080px) {
  .u-margin-top-bottom-xxs-bp-l.u-margin-top-bottom-xxs-bp-l.u-margin-top-bottom-xxs-bp-l.u-margin-top-bottom-xxs-bp-l {
    margin-top: 8px !important; } }

@media (min-width: 1200px) {
  .u-margin-top-bottom-xxs-bp-xl.u-margin-top-bottom-xxs-bp-xl.u-margin-top-bottom-xxs-bp-xl.u-margin-top-bottom-xxs-bp-xl.u-margin-top-bottom-xxs-bp-xl {
    margin-top: 8px !important; } }

@media (min-width: 1440px) {
  .u-margin-top-bottom-xxs-bp-xxl.u-margin-top-bottom-xxs-bp-xxl.u-margin-top-bottom-xxs-bp-xxl.u-margin-top-bottom-xxs-bp-xxl.u-margin-top-bottom-xxs-bp-xxl.u-margin-top-bottom-xxs-bp-xxl {
    margin-top: 8px !important; } }

@media (min-width: 540px) {
  .u-margin-top-bottom-xxs-bp-xs {
    margin-bottom: 8px !important; } }

@media (min-width: 800px) {
  .u-margin-top-bottom-xxs-bp-s.u-margin-top-bottom-xxs-bp-s {
    margin-bottom: 8px !important; } }

@media (min-width: 980px) {
  .u-margin-top-bottom-xxs-bp-m.u-margin-top-bottom-xxs-bp-m.u-margin-top-bottom-xxs-bp-m {
    margin-bottom: 8px !important; } }

@media (min-width: 1080px) {
  .u-margin-top-bottom-xxs-bp-l.u-margin-top-bottom-xxs-bp-l.u-margin-top-bottom-xxs-bp-l.u-margin-top-bottom-xxs-bp-l {
    margin-bottom: 8px !important; } }

@media (min-width: 1200px) {
  .u-margin-top-bottom-xxs-bp-xl.u-margin-top-bottom-xxs-bp-xl.u-margin-top-bottom-xxs-bp-xl.u-margin-top-bottom-xxs-bp-xl.u-margin-top-bottom-xxs-bp-xl {
    margin-bottom: 8px !important; } }

@media (min-width: 1440px) {
  .u-margin-top-bottom-xxs-bp-xxl.u-margin-top-bottom-xxs-bp-xxl.u-margin-top-bottom-xxs-bp-xxl.u-margin-top-bottom-xxs-bp-xxl.u-margin-top-bottom-xxs-bp-xxl.u-margin-top-bottom-xxs-bp-xxl {
    margin-bottom: 8px !important; } }

@media (min-width: 540px) {
  .u-margin-top-xxs-bp-xs {
    margin-top: 8px !important; } }

@media (min-width: 800px) {
  .u-margin-top-xxs-bp-s.u-margin-top-xxs-bp-s {
    margin-top: 8px !important; } }

@media (min-width: 980px) {
  .u-margin-top-xxs-bp-m.u-margin-top-xxs-bp-m.u-margin-top-xxs-bp-m {
    margin-top: 8px !important; } }

@media (min-width: 1080px) {
  .u-margin-top-xxs-bp-l.u-margin-top-xxs-bp-l.u-margin-top-xxs-bp-l.u-margin-top-xxs-bp-l {
    margin-top: 8px !important; } }

@media (min-width: 1200px) {
  .u-margin-top-xxs-bp-xl.u-margin-top-xxs-bp-xl.u-margin-top-xxs-bp-xl.u-margin-top-xxs-bp-xl.u-margin-top-xxs-bp-xl {
    margin-top: 8px !important; } }

@media (min-width: 1440px) {
  .u-margin-top-xxs-bp-xxl.u-margin-top-xxs-bp-xxl.u-margin-top-xxs-bp-xxl.u-margin-top-xxs-bp-xxl.u-margin-top-xxs-bp-xxl.u-margin-top-xxs-bp-xxl {
    margin-top: 8px !important; } }

@media (min-width: 540px) {
  .u-margin-right-xxs-bp-xs {
    margin-right: 8px !important; } }

@media (min-width: 800px) {
  .u-margin-right-xxs-bp-s.u-margin-right-xxs-bp-s {
    margin-right: 8px !important; } }

@media (min-width: 980px) {
  .u-margin-right-xxs-bp-m.u-margin-right-xxs-bp-m.u-margin-right-xxs-bp-m {
    margin-right: 8px !important; } }

@media (min-width: 1080px) {
  .u-margin-right-xxs-bp-l.u-margin-right-xxs-bp-l.u-margin-right-xxs-bp-l.u-margin-right-xxs-bp-l {
    margin-right: 8px !important; } }

@media (min-width: 1200px) {
  .u-margin-right-xxs-bp-xl.u-margin-right-xxs-bp-xl.u-margin-right-xxs-bp-xl.u-margin-right-xxs-bp-xl.u-margin-right-xxs-bp-xl {
    margin-right: 8px !important; } }

@media (min-width: 1440px) {
  .u-margin-right-xxs-bp-xxl.u-margin-right-xxs-bp-xxl.u-margin-right-xxs-bp-xxl.u-margin-right-xxs-bp-xxl.u-margin-right-xxs-bp-xxl.u-margin-right-xxs-bp-xxl {
    margin-right: 8px !important; } }

@media (min-width: 540px) {
  .u-margin-bottom-xxs-bp-xs {
    margin-bottom: 8px !important; } }

@media (min-width: 800px) {
  .u-margin-bottom-xxs-bp-s.u-margin-bottom-xxs-bp-s {
    margin-bottom: 8px !important; } }

@media (min-width: 980px) {
  .u-margin-bottom-xxs-bp-m.u-margin-bottom-xxs-bp-m.u-margin-bottom-xxs-bp-m {
    margin-bottom: 8px !important; } }

@media (min-width: 1080px) {
  .u-margin-bottom-xxs-bp-l.u-margin-bottom-xxs-bp-l.u-margin-bottom-xxs-bp-l.u-margin-bottom-xxs-bp-l {
    margin-bottom: 8px !important; } }

@media (min-width: 1200px) {
  .u-margin-bottom-xxs-bp-xl.u-margin-bottom-xxs-bp-xl.u-margin-bottom-xxs-bp-xl.u-margin-bottom-xxs-bp-xl.u-margin-bottom-xxs-bp-xl {
    margin-bottom: 8px !important; } }

@media (min-width: 1440px) {
  .u-margin-bottom-xxs-bp-xxl.u-margin-bottom-xxs-bp-xxl.u-margin-bottom-xxs-bp-xxl.u-margin-bottom-xxs-bp-xxl.u-margin-bottom-xxs-bp-xxl.u-margin-bottom-xxs-bp-xxl {
    margin-bottom: 8px !important; } }

@media (min-width: 540px) {
  .u-margin-left-xxs-bp-xs {
    margin-left: 8px !important; } }

@media (min-width: 800px) {
  .u-margin-left-xxs-bp-s.u-margin-left-xxs-bp-s {
    margin-left: 8px !important; } }

@media (min-width: 980px) {
  .u-margin-left-xxs-bp-m.u-margin-left-xxs-bp-m.u-margin-left-xxs-bp-m {
    margin-left: 8px !important; } }

@media (min-width: 1080px) {
  .u-margin-left-xxs-bp-l.u-margin-left-xxs-bp-l.u-margin-left-xxs-bp-l.u-margin-left-xxs-bp-l {
    margin-left: 8px !important; } }

@media (min-width: 1200px) {
  .u-margin-left-xxs-bp-xl.u-margin-left-xxs-bp-xl.u-margin-left-xxs-bp-xl.u-margin-left-xxs-bp-xl.u-margin-left-xxs-bp-xl {
    margin-left: 8px !important; } }

@media (min-width: 1440px) {
  .u-margin-left-xxs-bp-xxl.u-margin-left-xxs-bp-xxl.u-margin-left-xxs-bp-xxl.u-margin-left-xxs-bp-xxl.u-margin-left-xxs-bp-xxl.u-margin-left-xxs-bp-xxl {
    margin-left: 8px !important; } }

.u-margin-xs {
  margin: 12px !important; }

.u-margin-left-right-xs {
  margin-left: 12px !important;
  margin-right: 12px !important; }

.u-margin-top-bottom-xs {
  margin-top: 12px !important;
  margin-bottom: 12px !important; }

.u-margin-top-xs {
  margin-top: 12px !important; }

.u-margin-right-xs {
  margin-right: 12px !important; }

.u-margin-bottom-xs {
  margin-bottom: 12px !important; }

.u-margin-left-xs {
  margin-left: 12px !important; }

@media (min-width: 540px) {
  .u-margin-xs-bp-xs {
    margin: 12px !important; } }

@media (min-width: 800px) {
  .u-margin-xs-bp-s.u-margin-xs-bp-s {
    margin: 12px !important; } }

@media (min-width: 980px) {
  .u-margin-xs-bp-m.u-margin-xs-bp-m.u-margin-xs-bp-m {
    margin: 12px !important; } }

@media (min-width: 1080px) {
  .u-margin-xs-bp-l.u-margin-xs-bp-l.u-margin-xs-bp-l.u-margin-xs-bp-l {
    margin: 12px !important; } }

@media (min-width: 1200px) {
  .u-margin-xs-bp-xl.u-margin-xs-bp-xl.u-margin-xs-bp-xl.u-margin-xs-bp-xl.u-margin-xs-bp-xl {
    margin: 12px !important; } }

@media (min-width: 1440px) {
  .u-margin-xs-bp-xxl.u-margin-xs-bp-xxl.u-margin-xs-bp-xxl.u-margin-xs-bp-xxl.u-margin-xs-bp-xxl.u-margin-xs-bp-xxl {
    margin: 12px !important; } }

@media (min-width: 540px) {
  .u-margin-left-right-xs-bp-xs {
    margin-left: 12px !important; } }

@media (min-width: 800px) {
  .u-margin-left-right-xs-bp-s.u-margin-left-right-xs-bp-s {
    margin-left: 12px !important; } }

@media (min-width: 980px) {
  .u-margin-left-right-xs-bp-m.u-margin-left-right-xs-bp-m.u-margin-left-right-xs-bp-m {
    margin-left: 12px !important; } }

@media (min-width: 1080px) {
  .u-margin-left-right-xs-bp-l.u-margin-left-right-xs-bp-l.u-margin-left-right-xs-bp-l.u-margin-left-right-xs-bp-l {
    margin-left: 12px !important; } }

@media (min-width: 1200px) {
  .u-margin-left-right-xs-bp-xl.u-margin-left-right-xs-bp-xl.u-margin-left-right-xs-bp-xl.u-margin-left-right-xs-bp-xl.u-margin-left-right-xs-bp-xl {
    margin-left: 12px !important; } }

@media (min-width: 1440px) {
  .u-margin-left-right-xs-bp-xxl.u-margin-left-right-xs-bp-xxl.u-margin-left-right-xs-bp-xxl.u-margin-left-right-xs-bp-xxl.u-margin-left-right-xs-bp-xxl.u-margin-left-right-xs-bp-xxl {
    margin-left: 12px !important; } }

@media (min-width: 540px) {
  .u-margin-left-right-xs-bp-xs {
    margin-right: 12px !important; } }

@media (min-width: 800px) {
  .u-margin-left-right-xs-bp-s.u-margin-left-right-xs-bp-s {
    margin-right: 12px !important; } }

@media (min-width: 980px) {
  .u-margin-left-right-xs-bp-m.u-margin-left-right-xs-bp-m.u-margin-left-right-xs-bp-m {
    margin-right: 12px !important; } }

@media (min-width: 1080px) {
  .u-margin-left-right-xs-bp-l.u-margin-left-right-xs-bp-l.u-margin-left-right-xs-bp-l.u-margin-left-right-xs-bp-l {
    margin-right: 12px !important; } }

@media (min-width: 1200px) {
  .u-margin-left-right-xs-bp-xl.u-margin-left-right-xs-bp-xl.u-margin-left-right-xs-bp-xl.u-margin-left-right-xs-bp-xl.u-margin-left-right-xs-bp-xl {
    margin-right: 12px !important; } }

@media (min-width: 1440px) {
  .u-margin-left-right-xs-bp-xxl.u-margin-left-right-xs-bp-xxl.u-margin-left-right-xs-bp-xxl.u-margin-left-right-xs-bp-xxl.u-margin-left-right-xs-bp-xxl.u-margin-left-right-xs-bp-xxl {
    margin-right: 12px !important; } }

@media (min-width: 540px) {
  .u-margin-top-bottom-xs-bp-xs {
    margin-top: 12px !important; } }

@media (min-width: 800px) {
  .u-margin-top-bottom-xs-bp-s.u-margin-top-bottom-xs-bp-s {
    margin-top: 12px !important; } }

@media (min-width: 980px) {
  .u-margin-top-bottom-xs-bp-m.u-margin-top-bottom-xs-bp-m.u-margin-top-bottom-xs-bp-m {
    margin-top: 12px !important; } }

@media (min-width: 1080px) {
  .u-margin-top-bottom-xs-bp-l.u-margin-top-bottom-xs-bp-l.u-margin-top-bottom-xs-bp-l.u-margin-top-bottom-xs-bp-l {
    margin-top: 12px !important; } }

@media (min-width: 1200px) {
  .u-margin-top-bottom-xs-bp-xl.u-margin-top-bottom-xs-bp-xl.u-margin-top-bottom-xs-bp-xl.u-margin-top-bottom-xs-bp-xl.u-margin-top-bottom-xs-bp-xl {
    margin-top: 12px !important; } }

@media (min-width: 1440px) {
  .u-margin-top-bottom-xs-bp-xxl.u-margin-top-bottom-xs-bp-xxl.u-margin-top-bottom-xs-bp-xxl.u-margin-top-bottom-xs-bp-xxl.u-margin-top-bottom-xs-bp-xxl.u-margin-top-bottom-xs-bp-xxl {
    margin-top: 12px !important; } }

@media (min-width: 540px) {
  .u-margin-top-bottom-xs-bp-xs {
    margin-bottom: 12px !important; } }

@media (min-width: 800px) {
  .u-margin-top-bottom-xs-bp-s.u-margin-top-bottom-xs-bp-s {
    margin-bottom: 12px !important; } }

@media (min-width: 980px) {
  .u-margin-top-bottom-xs-bp-m.u-margin-top-bottom-xs-bp-m.u-margin-top-bottom-xs-bp-m {
    margin-bottom: 12px !important; } }

@media (min-width: 1080px) {
  .u-margin-top-bottom-xs-bp-l.u-margin-top-bottom-xs-bp-l.u-margin-top-bottom-xs-bp-l.u-margin-top-bottom-xs-bp-l {
    margin-bottom: 12px !important; } }

@media (min-width: 1200px) {
  .u-margin-top-bottom-xs-bp-xl.u-margin-top-bottom-xs-bp-xl.u-margin-top-bottom-xs-bp-xl.u-margin-top-bottom-xs-bp-xl.u-margin-top-bottom-xs-bp-xl {
    margin-bottom: 12px !important; } }

@media (min-width: 1440px) {
  .u-margin-top-bottom-xs-bp-xxl.u-margin-top-bottom-xs-bp-xxl.u-margin-top-bottom-xs-bp-xxl.u-margin-top-bottom-xs-bp-xxl.u-margin-top-bottom-xs-bp-xxl.u-margin-top-bottom-xs-bp-xxl {
    margin-bottom: 12px !important; } }

@media (min-width: 540px) {
  .u-margin-top-xs-bp-xs {
    margin-top: 12px !important; } }

@media (min-width: 800px) {
  .u-margin-top-xs-bp-s.u-margin-top-xs-bp-s {
    margin-top: 12px !important; } }

@media (min-width: 980px) {
  .u-margin-top-xs-bp-m.u-margin-top-xs-bp-m.u-margin-top-xs-bp-m {
    margin-top: 12px !important; } }

@media (min-width: 1080px) {
  .u-margin-top-xs-bp-l.u-margin-top-xs-bp-l.u-margin-top-xs-bp-l.u-margin-top-xs-bp-l {
    margin-top: 12px !important; } }

@media (min-width: 1200px) {
  .u-margin-top-xs-bp-xl.u-margin-top-xs-bp-xl.u-margin-top-xs-bp-xl.u-margin-top-xs-bp-xl.u-margin-top-xs-bp-xl {
    margin-top: 12px !important; } }

@media (min-width: 1440px) {
  .u-margin-top-xs-bp-xxl.u-margin-top-xs-bp-xxl.u-margin-top-xs-bp-xxl.u-margin-top-xs-bp-xxl.u-margin-top-xs-bp-xxl.u-margin-top-xs-bp-xxl {
    margin-top: 12px !important; } }

@media (min-width: 540px) {
  .u-margin-right-xs-bp-xs {
    margin-right: 12px !important; } }

@media (min-width: 800px) {
  .u-margin-right-xs-bp-s.u-margin-right-xs-bp-s {
    margin-right: 12px !important; } }

@media (min-width: 980px) {
  .u-margin-right-xs-bp-m.u-margin-right-xs-bp-m.u-margin-right-xs-bp-m {
    margin-right: 12px !important; } }

@media (min-width: 1080px) {
  .u-margin-right-xs-bp-l.u-margin-right-xs-bp-l.u-margin-right-xs-bp-l.u-margin-right-xs-bp-l {
    margin-right: 12px !important; } }

@media (min-width: 1200px) {
  .u-margin-right-xs-bp-xl.u-margin-right-xs-bp-xl.u-margin-right-xs-bp-xl.u-margin-right-xs-bp-xl.u-margin-right-xs-bp-xl {
    margin-right: 12px !important; } }

@media (min-width: 1440px) {
  .u-margin-right-xs-bp-xxl.u-margin-right-xs-bp-xxl.u-margin-right-xs-bp-xxl.u-margin-right-xs-bp-xxl.u-margin-right-xs-bp-xxl.u-margin-right-xs-bp-xxl {
    margin-right: 12px !important; } }

@media (min-width: 540px) {
  .u-margin-bottom-xs-bp-xs {
    margin-bottom: 12px !important; } }

@media (min-width: 800px) {
  .u-margin-bottom-xs-bp-s.u-margin-bottom-xs-bp-s {
    margin-bottom: 12px !important; } }

@media (min-width: 980px) {
  .u-margin-bottom-xs-bp-m.u-margin-bottom-xs-bp-m.u-margin-bottom-xs-bp-m {
    margin-bottom: 12px !important; } }

@media (min-width: 1080px) {
  .u-margin-bottom-xs-bp-l.u-margin-bottom-xs-bp-l.u-margin-bottom-xs-bp-l.u-margin-bottom-xs-bp-l {
    margin-bottom: 12px !important; } }

@media (min-width: 1200px) {
  .u-margin-bottom-xs-bp-xl.u-margin-bottom-xs-bp-xl.u-margin-bottom-xs-bp-xl.u-margin-bottom-xs-bp-xl.u-margin-bottom-xs-bp-xl {
    margin-bottom: 12px !important; } }

@media (min-width: 1440px) {
  .u-margin-bottom-xs-bp-xxl.u-margin-bottom-xs-bp-xxl.u-margin-bottom-xs-bp-xxl.u-margin-bottom-xs-bp-xxl.u-margin-bottom-xs-bp-xxl.u-margin-bottom-xs-bp-xxl {
    margin-bottom: 12px !important; } }

@media (min-width: 540px) {
  .u-margin-left-xs-bp-xs {
    margin-left: 12px !important; } }

@media (min-width: 800px) {
  .u-margin-left-xs-bp-s.u-margin-left-xs-bp-s {
    margin-left: 12px !important; } }

@media (min-width: 980px) {
  .u-margin-left-xs-bp-m.u-margin-left-xs-bp-m.u-margin-left-xs-bp-m {
    margin-left: 12px !important; } }

@media (min-width: 1080px) {
  .u-margin-left-xs-bp-l.u-margin-left-xs-bp-l.u-margin-left-xs-bp-l.u-margin-left-xs-bp-l {
    margin-left: 12px !important; } }

@media (min-width: 1200px) {
  .u-margin-left-xs-bp-xl.u-margin-left-xs-bp-xl.u-margin-left-xs-bp-xl.u-margin-left-xs-bp-xl.u-margin-left-xs-bp-xl {
    margin-left: 12px !important; } }

@media (min-width: 1440px) {
  .u-margin-left-xs-bp-xxl.u-margin-left-xs-bp-xxl.u-margin-left-xs-bp-xxl.u-margin-left-xs-bp-xxl.u-margin-left-xs-bp-xxl.u-margin-left-xs-bp-xxl {
    margin-left: 12px !important; } }

.u-margin-s {
  margin: 16px !important; }

.u-margin-left-right-s {
  margin-left: 16px !important;
  margin-right: 16px !important; }

.u-margin-top-bottom-s {
  margin-top: 16px !important;
  margin-bottom: 16px !important; }

.u-margin-top-s {
  margin-top: 16px !important; }

.u-margin-right-s {
  margin-right: 16px !important; }

.u-margin-bottom-s {
  margin-bottom: 16px !important; }

.u-margin-left-s {
  margin-left: 16px !important; }

@media (min-width: 540px) {
  .u-margin-s-bp-xs {
    margin: 16px !important; } }

@media (min-width: 800px) {
  .u-margin-s-bp-s.u-margin-s-bp-s {
    margin: 16px !important; } }

@media (min-width: 980px) {
  .u-margin-s-bp-m.u-margin-s-bp-m.u-margin-s-bp-m {
    margin: 16px !important; } }

@media (min-width: 1080px) {
  .u-margin-s-bp-l.u-margin-s-bp-l.u-margin-s-bp-l.u-margin-s-bp-l {
    margin: 16px !important; } }

@media (min-width: 1200px) {
  .u-margin-s-bp-xl.u-margin-s-bp-xl.u-margin-s-bp-xl.u-margin-s-bp-xl.u-margin-s-bp-xl {
    margin: 16px !important; } }

@media (min-width: 1440px) {
  .u-margin-s-bp-xxl.u-margin-s-bp-xxl.u-margin-s-bp-xxl.u-margin-s-bp-xxl.u-margin-s-bp-xxl.u-margin-s-bp-xxl {
    margin: 16px !important; } }

@media (min-width: 540px) {
  .u-margin-left-right-s-bp-xs {
    margin-left: 16px !important; } }

@media (min-width: 800px) {
  .u-margin-left-right-s-bp-s.u-margin-left-right-s-bp-s {
    margin-left: 16px !important; } }

@media (min-width: 980px) {
  .u-margin-left-right-s-bp-m.u-margin-left-right-s-bp-m.u-margin-left-right-s-bp-m {
    margin-left: 16px !important; } }

@media (min-width: 1080px) {
  .u-margin-left-right-s-bp-l.u-margin-left-right-s-bp-l.u-margin-left-right-s-bp-l.u-margin-left-right-s-bp-l {
    margin-left: 16px !important; } }

@media (min-width: 1200px) {
  .u-margin-left-right-s-bp-xl.u-margin-left-right-s-bp-xl.u-margin-left-right-s-bp-xl.u-margin-left-right-s-bp-xl.u-margin-left-right-s-bp-xl {
    margin-left: 16px !important; } }

@media (min-width: 1440px) {
  .u-margin-left-right-s-bp-xxl.u-margin-left-right-s-bp-xxl.u-margin-left-right-s-bp-xxl.u-margin-left-right-s-bp-xxl.u-margin-left-right-s-bp-xxl.u-margin-left-right-s-bp-xxl {
    margin-left: 16px !important; } }

@media (min-width: 540px) {
  .u-margin-left-right-s-bp-xs {
    margin-right: 16px !important; } }

@media (min-width: 800px) {
  .u-margin-left-right-s-bp-s.u-margin-left-right-s-bp-s {
    margin-right: 16px !important; } }

@media (min-width: 980px) {
  .u-margin-left-right-s-bp-m.u-margin-left-right-s-bp-m.u-margin-left-right-s-bp-m {
    margin-right: 16px !important; } }

@media (min-width: 1080px) {
  .u-margin-left-right-s-bp-l.u-margin-left-right-s-bp-l.u-margin-left-right-s-bp-l.u-margin-left-right-s-bp-l {
    margin-right: 16px !important; } }

@media (min-width: 1200px) {
  .u-margin-left-right-s-bp-xl.u-margin-left-right-s-bp-xl.u-margin-left-right-s-bp-xl.u-margin-left-right-s-bp-xl.u-margin-left-right-s-bp-xl {
    margin-right: 16px !important; } }

@media (min-width: 1440px) {
  .u-margin-left-right-s-bp-xxl.u-margin-left-right-s-bp-xxl.u-margin-left-right-s-bp-xxl.u-margin-left-right-s-bp-xxl.u-margin-left-right-s-bp-xxl.u-margin-left-right-s-bp-xxl {
    margin-right: 16px !important; } }

@media (min-width: 540px) {
  .u-margin-top-bottom-s-bp-xs {
    margin-top: 16px !important; } }

@media (min-width: 800px) {
  .u-margin-top-bottom-s-bp-s.u-margin-top-bottom-s-bp-s {
    margin-top: 16px !important; } }

@media (min-width: 980px) {
  .u-margin-top-bottom-s-bp-m.u-margin-top-bottom-s-bp-m.u-margin-top-bottom-s-bp-m {
    margin-top: 16px !important; } }

@media (min-width: 1080px) {
  .u-margin-top-bottom-s-bp-l.u-margin-top-bottom-s-bp-l.u-margin-top-bottom-s-bp-l.u-margin-top-bottom-s-bp-l {
    margin-top: 16px !important; } }

@media (min-width: 1200px) {
  .u-margin-top-bottom-s-bp-xl.u-margin-top-bottom-s-bp-xl.u-margin-top-bottom-s-bp-xl.u-margin-top-bottom-s-bp-xl.u-margin-top-bottom-s-bp-xl {
    margin-top: 16px !important; } }

@media (min-width: 1440px) {
  .u-margin-top-bottom-s-bp-xxl.u-margin-top-bottom-s-bp-xxl.u-margin-top-bottom-s-bp-xxl.u-margin-top-bottom-s-bp-xxl.u-margin-top-bottom-s-bp-xxl.u-margin-top-bottom-s-bp-xxl {
    margin-top: 16px !important; } }

@media (min-width: 540px) {
  .u-margin-top-bottom-s-bp-xs {
    margin-bottom: 16px !important; } }

@media (min-width: 800px) {
  .u-margin-top-bottom-s-bp-s.u-margin-top-bottom-s-bp-s {
    margin-bottom: 16px !important; } }

@media (min-width: 980px) {
  .u-margin-top-bottom-s-bp-m.u-margin-top-bottom-s-bp-m.u-margin-top-bottom-s-bp-m {
    margin-bottom: 16px !important; } }

@media (min-width: 1080px) {
  .u-margin-top-bottom-s-bp-l.u-margin-top-bottom-s-bp-l.u-margin-top-bottom-s-bp-l.u-margin-top-bottom-s-bp-l {
    margin-bottom: 16px !important; } }

@media (min-width: 1200px) {
  .u-margin-top-bottom-s-bp-xl.u-margin-top-bottom-s-bp-xl.u-margin-top-bottom-s-bp-xl.u-margin-top-bottom-s-bp-xl.u-margin-top-bottom-s-bp-xl {
    margin-bottom: 16px !important; } }

@media (min-width: 1440px) {
  .u-margin-top-bottom-s-bp-xxl.u-margin-top-bottom-s-bp-xxl.u-margin-top-bottom-s-bp-xxl.u-margin-top-bottom-s-bp-xxl.u-margin-top-bottom-s-bp-xxl.u-margin-top-bottom-s-bp-xxl {
    margin-bottom: 16px !important; } }

@media (min-width: 540px) {
  .u-margin-top-s-bp-xs {
    margin-top: 16px !important; } }

@media (min-width: 800px) {
  .u-margin-top-s-bp-s.u-margin-top-s-bp-s {
    margin-top: 16px !important; } }

@media (min-width: 980px) {
  .u-margin-top-s-bp-m.u-margin-top-s-bp-m.u-margin-top-s-bp-m {
    margin-top: 16px !important; } }

@media (min-width: 1080px) {
  .u-margin-top-s-bp-l.u-margin-top-s-bp-l.u-margin-top-s-bp-l.u-margin-top-s-bp-l {
    margin-top: 16px !important; } }

@media (min-width: 1200px) {
  .u-margin-top-s-bp-xl.u-margin-top-s-bp-xl.u-margin-top-s-bp-xl.u-margin-top-s-bp-xl.u-margin-top-s-bp-xl {
    margin-top: 16px !important; } }

@media (min-width: 1440px) {
  .u-margin-top-s-bp-xxl.u-margin-top-s-bp-xxl.u-margin-top-s-bp-xxl.u-margin-top-s-bp-xxl.u-margin-top-s-bp-xxl.u-margin-top-s-bp-xxl {
    margin-top: 16px !important; } }

@media (min-width: 540px) {
  .u-margin-right-s-bp-xs {
    margin-right: 16px !important; } }

@media (min-width: 800px) {
  .u-margin-right-s-bp-s.u-margin-right-s-bp-s {
    margin-right: 16px !important; } }

@media (min-width: 980px) {
  .u-margin-right-s-bp-m.u-margin-right-s-bp-m.u-margin-right-s-bp-m {
    margin-right: 16px !important; } }

@media (min-width: 1080px) {
  .u-margin-right-s-bp-l.u-margin-right-s-bp-l.u-margin-right-s-bp-l.u-margin-right-s-bp-l {
    margin-right: 16px !important; } }

@media (min-width: 1200px) {
  .u-margin-right-s-bp-xl.u-margin-right-s-bp-xl.u-margin-right-s-bp-xl.u-margin-right-s-bp-xl.u-margin-right-s-bp-xl {
    margin-right: 16px !important; } }

@media (min-width: 1440px) {
  .u-margin-right-s-bp-xxl.u-margin-right-s-bp-xxl.u-margin-right-s-bp-xxl.u-margin-right-s-bp-xxl.u-margin-right-s-bp-xxl.u-margin-right-s-bp-xxl {
    margin-right: 16px !important; } }

@media (min-width: 540px) {
  .u-margin-bottom-s-bp-xs {
    margin-bottom: 16px !important; } }

@media (min-width: 800px) {
  .u-margin-bottom-s-bp-s.u-margin-bottom-s-bp-s {
    margin-bottom: 16px !important; } }

@media (min-width: 980px) {
  .u-margin-bottom-s-bp-m.u-margin-bottom-s-bp-m.u-margin-bottom-s-bp-m {
    margin-bottom: 16px !important; } }

@media (min-width: 1080px) {
  .u-margin-bottom-s-bp-l.u-margin-bottom-s-bp-l.u-margin-bottom-s-bp-l.u-margin-bottom-s-bp-l {
    margin-bottom: 16px !important; } }

@media (min-width: 1200px) {
  .u-margin-bottom-s-bp-xl.u-margin-bottom-s-bp-xl.u-margin-bottom-s-bp-xl.u-margin-bottom-s-bp-xl.u-margin-bottom-s-bp-xl {
    margin-bottom: 16px !important; } }

@media (min-width: 1440px) {
  .u-margin-bottom-s-bp-xxl.u-margin-bottom-s-bp-xxl.u-margin-bottom-s-bp-xxl.u-margin-bottom-s-bp-xxl.u-margin-bottom-s-bp-xxl.u-margin-bottom-s-bp-xxl {
    margin-bottom: 16px !important; } }

@media (min-width: 540px) {
  .u-margin-left-s-bp-xs {
    margin-left: 16px !important; } }

@media (min-width: 800px) {
  .u-margin-left-s-bp-s.u-margin-left-s-bp-s {
    margin-left: 16px !important; } }

@media (min-width: 980px) {
  .u-margin-left-s-bp-m.u-margin-left-s-bp-m.u-margin-left-s-bp-m {
    margin-left: 16px !important; } }

@media (min-width: 1080px) {
  .u-margin-left-s-bp-l.u-margin-left-s-bp-l.u-margin-left-s-bp-l.u-margin-left-s-bp-l {
    margin-left: 16px !important; } }

@media (min-width: 1200px) {
  .u-margin-left-s-bp-xl.u-margin-left-s-bp-xl.u-margin-left-s-bp-xl.u-margin-left-s-bp-xl.u-margin-left-s-bp-xl {
    margin-left: 16px !important; } }

@media (min-width: 1440px) {
  .u-margin-left-s-bp-xxl.u-margin-left-s-bp-xxl.u-margin-left-s-bp-xxl.u-margin-left-s-bp-xxl.u-margin-left-s-bp-xxl.u-margin-left-s-bp-xxl {
    margin-left: 16px !important; } }

.u-margin-m {
  margin: 20px !important; }

.u-margin-left-right-m {
  margin-left: 20px !important;
  margin-right: 20px !important; }

.u-margin-top-bottom-m {
  margin-top: 20px !important;
  margin-bottom: 20px !important; }

.u-margin-top-m {
  margin-top: 20px !important; }

.u-margin-right-m {
  margin-right: 20px !important; }

.u-margin-bottom-m {
  margin-bottom: 20px !important; }

.u-margin-left-m {
  margin-left: 20px !important; }

@media (min-width: 540px) {
  .u-margin-m-bp-xs {
    margin: 20px !important; } }

@media (min-width: 800px) {
  .u-margin-m-bp-s.u-margin-m-bp-s {
    margin: 20px !important; } }

@media (min-width: 980px) {
  .u-margin-m-bp-m.u-margin-m-bp-m.u-margin-m-bp-m {
    margin: 20px !important; } }

@media (min-width: 1080px) {
  .u-margin-m-bp-l.u-margin-m-bp-l.u-margin-m-bp-l.u-margin-m-bp-l {
    margin: 20px !important; } }

@media (min-width: 1200px) {
  .u-margin-m-bp-xl.u-margin-m-bp-xl.u-margin-m-bp-xl.u-margin-m-bp-xl.u-margin-m-bp-xl {
    margin: 20px !important; } }

@media (min-width: 1440px) {
  .u-margin-m-bp-xxl.u-margin-m-bp-xxl.u-margin-m-bp-xxl.u-margin-m-bp-xxl.u-margin-m-bp-xxl.u-margin-m-bp-xxl {
    margin: 20px !important; } }

@media (min-width: 540px) {
  .u-margin-left-right-m-bp-xs {
    margin-left: 20px !important; } }

@media (min-width: 800px) {
  .u-margin-left-right-m-bp-s.u-margin-left-right-m-bp-s {
    margin-left: 20px !important; } }

@media (min-width: 980px) {
  .u-margin-left-right-m-bp-m.u-margin-left-right-m-bp-m.u-margin-left-right-m-bp-m {
    margin-left: 20px !important; } }

@media (min-width: 1080px) {
  .u-margin-left-right-m-bp-l.u-margin-left-right-m-bp-l.u-margin-left-right-m-bp-l.u-margin-left-right-m-bp-l {
    margin-left: 20px !important; } }

@media (min-width: 1200px) {
  .u-margin-left-right-m-bp-xl.u-margin-left-right-m-bp-xl.u-margin-left-right-m-bp-xl.u-margin-left-right-m-bp-xl.u-margin-left-right-m-bp-xl {
    margin-left: 20px !important; } }

@media (min-width: 1440px) {
  .u-margin-left-right-m-bp-xxl.u-margin-left-right-m-bp-xxl.u-margin-left-right-m-bp-xxl.u-margin-left-right-m-bp-xxl.u-margin-left-right-m-bp-xxl.u-margin-left-right-m-bp-xxl {
    margin-left: 20px !important; } }

@media (min-width: 540px) {
  .u-margin-left-right-m-bp-xs {
    margin-right: 20px !important; } }

@media (min-width: 800px) {
  .u-margin-left-right-m-bp-s.u-margin-left-right-m-bp-s {
    margin-right: 20px !important; } }

@media (min-width: 980px) {
  .u-margin-left-right-m-bp-m.u-margin-left-right-m-bp-m.u-margin-left-right-m-bp-m {
    margin-right: 20px !important; } }

@media (min-width: 1080px) {
  .u-margin-left-right-m-bp-l.u-margin-left-right-m-bp-l.u-margin-left-right-m-bp-l.u-margin-left-right-m-bp-l {
    margin-right: 20px !important; } }

@media (min-width: 1200px) {
  .u-margin-left-right-m-bp-xl.u-margin-left-right-m-bp-xl.u-margin-left-right-m-bp-xl.u-margin-left-right-m-bp-xl.u-margin-left-right-m-bp-xl {
    margin-right: 20px !important; } }

@media (min-width: 1440px) {
  .u-margin-left-right-m-bp-xxl.u-margin-left-right-m-bp-xxl.u-margin-left-right-m-bp-xxl.u-margin-left-right-m-bp-xxl.u-margin-left-right-m-bp-xxl.u-margin-left-right-m-bp-xxl {
    margin-right: 20px !important; } }

@media (min-width: 540px) {
  .u-margin-top-bottom-m-bp-xs {
    margin-top: 20px !important; } }

@media (min-width: 800px) {
  .u-margin-top-bottom-m-bp-s.u-margin-top-bottom-m-bp-s {
    margin-top: 20px !important; } }

@media (min-width: 980px) {
  .u-margin-top-bottom-m-bp-m.u-margin-top-bottom-m-bp-m.u-margin-top-bottom-m-bp-m {
    margin-top: 20px !important; } }

@media (min-width: 1080px) {
  .u-margin-top-bottom-m-bp-l.u-margin-top-bottom-m-bp-l.u-margin-top-bottom-m-bp-l.u-margin-top-bottom-m-bp-l {
    margin-top: 20px !important; } }

@media (min-width: 1200px) {
  .u-margin-top-bottom-m-bp-xl.u-margin-top-bottom-m-bp-xl.u-margin-top-bottom-m-bp-xl.u-margin-top-bottom-m-bp-xl.u-margin-top-bottom-m-bp-xl {
    margin-top: 20px !important; } }

@media (min-width: 1440px) {
  .u-margin-top-bottom-m-bp-xxl.u-margin-top-bottom-m-bp-xxl.u-margin-top-bottom-m-bp-xxl.u-margin-top-bottom-m-bp-xxl.u-margin-top-bottom-m-bp-xxl.u-margin-top-bottom-m-bp-xxl {
    margin-top: 20px !important; } }

@media (min-width: 540px) {
  .u-margin-top-bottom-m-bp-xs {
    margin-bottom: 20px !important; } }

@media (min-width: 800px) {
  .u-margin-top-bottom-m-bp-s.u-margin-top-bottom-m-bp-s {
    margin-bottom: 20px !important; } }

@media (min-width: 980px) {
  .u-margin-top-bottom-m-bp-m.u-margin-top-bottom-m-bp-m.u-margin-top-bottom-m-bp-m {
    margin-bottom: 20px !important; } }

@media (min-width: 1080px) {
  .u-margin-top-bottom-m-bp-l.u-margin-top-bottom-m-bp-l.u-margin-top-bottom-m-bp-l.u-margin-top-bottom-m-bp-l {
    margin-bottom: 20px !important; } }

@media (min-width: 1200px) {
  .u-margin-top-bottom-m-bp-xl.u-margin-top-bottom-m-bp-xl.u-margin-top-bottom-m-bp-xl.u-margin-top-bottom-m-bp-xl.u-margin-top-bottom-m-bp-xl {
    margin-bottom: 20px !important; } }

@media (min-width: 1440px) {
  .u-margin-top-bottom-m-bp-xxl.u-margin-top-bottom-m-bp-xxl.u-margin-top-bottom-m-bp-xxl.u-margin-top-bottom-m-bp-xxl.u-margin-top-bottom-m-bp-xxl.u-margin-top-bottom-m-bp-xxl {
    margin-bottom: 20px !important; } }

@media (min-width: 540px) {
  .u-margin-top-m-bp-xs {
    margin-top: 20px !important; } }

@media (min-width: 800px) {
  .u-margin-top-m-bp-s.u-margin-top-m-bp-s {
    margin-top: 20px !important; } }

@media (min-width: 980px) {
  .u-margin-top-m-bp-m.u-margin-top-m-bp-m.u-margin-top-m-bp-m {
    margin-top: 20px !important; } }

@media (min-width: 1080px) {
  .u-margin-top-m-bp-l.u-margin-top-m-bp-l.u-margin-top-m-bp-l.u-margin-top-m-bp-l {
    margin-top: 20px !important; } }

@media (min-width: 1200px) {
  .u-margin-top-m-bp-xl.u-margin-top-m-bp-xl.u-margin-top-m-bp-xl.u-margin-top-m-bp-xl.u-margin-top-m-bp-xl {
    margin-top: 20px !important; } }

@media (min-width: 1440px) {
  .u-margin-top-m-bp-xxl.u-margin-top-m-bp-xxl.u-margin-top-m-bp-xxl.u-margin-top-m-bp-xxl.u-margin-top-m-bp-xxl.u-margin-top-m-bp-xxl {
    margin-top: 20px !important; } }

@media (min-width: 540px) {
  .u-margin-right-m-bp-xs {
    margin-right: 20px !important; } }

@media (min-width: 800px) {
  .u-margin-right-m-bp-s.u-margin-right-m-bp-s {
    margin-right: 20px !important; } }

@media (min-width: 980px) {
  .u-margin-right-m-bp-m.u-margin-right-m-bp-m.u-margin-right-m-bp-m {
    margin-right: 20px !important; } }

@media (min-width: 1080px) {
  .u-margin-right-m-bp-l.u-margin-right-m-bp-l.u-margin-right-m-bp-l.u-margin-right-m-bp-l {
    margin-right: 20px !important; } }

@media (min-width: 1200px) {
  .u-margin-right-m-bp-xl.u-margin-right-m-bp-xl.u-margin-right-m-bp-xl.u-margin-right-m-bp-xl.u-margin-right-m-bp-xl {
    margin-right: 20px !important; } }

@media (min-width: 1440px) {
  .u-margin-right-m-bp-xxl.u-margin-right-m-bp-xxl.u-margin-right-m-bp-xxl.u-margin-right-m-bp-xxl.u-margin-right-m-bp-xxl.u-margin-right-m-bp-xxl {
    margin-right: 20px !important; } }

@media (min-width: 540px) {
  .u-margin-bottom-m-bp-xs {
    margin-bottom: 20px !important; } }

@media (min-width: 800px) {
  .u-margin-bottom-m-bp-s.u-margin-bottom-m-bp-s {
    margin-bottom: 20px !important; } }

@media (min-width: 980px) {
  .u-margin-bottom-m-bp-m.u-margin-bottom-m-bp-m.u-margin-bottom-m-bp-m {
    margin-bottom: 20px !important; } }

@media (min-width: 1080px) {
  .u-margin-bottom-m-bp-l.u-margin-bottom-m-bp-l.u-margin-bottom-m-bp-l.u-margin-bottom-m-bp-l {
    margin-bottom: 20px !important; } }

@media (min-width: 1200px) {
  .u-margin-bottom-m-bp-xl.u-margin-bottom-m-bp-xl.u-margin-bottom-m-bp-xl.u-margin-bottom-m-bp-xl.u-margin-bottom-m-bp-xl {
    margin-bottom: 20px !important; } }

@media (min-width: 1440px) {
  .u-margin-bottom-m-bp-xxl.u-margin-bottom-m-bp-xxl.u-margin-bottom-m-bp-xxl.u-margin-bottom-m-bp-xxl.u-margin-bottom-m-bp-xxl.u-margin-bottom-m-bp-xxl {
    margin-bottom: 20px !important; } }

@media (min-width: 540px) {
  .u-margin-left-m-bp-xs {
    margin-left: 20px !important; } }

@media (min-width: 800px) {
  .u-margin-left-m-bp-s.u-margin-left-m-bp-s {
    margin-left: 20px !important; } }

@media (min-width: 980px) {
  .u-margin-left-m-bp-m.u-margin-left-m-bp-m.u-margin-left-m-bp-m {
    margin-left: 20px !important; } }

@media (min-width: 1080px) {
  .u-margin-left-m-bp-l.u-margin-left-m-bp-l.u-margin-left-m-bp-l.u-margin-left-m-bp-l {
    margin-left: 20px !important; } }

@media (min-width: 1200px) {
  .u-margin-left-m-bp-xl.u-margin-left-m-bp-xl.u-margin-left-m-bp-xl.u-margin-left-m-bp-xl.u-margin-left-m-bp-xl {
    margin-left: 20px !important; } }

@media (min-width: 1440px) {
  .u-margin-left-m-bp-xxl.u-margin-left-m-bp-xxl.u-margin-left-m-bp-xxl.u-margin-left-m-bp-xxl.u-margin-left-m-bp-xxl.u-margin-left-m-bp-xxl {
    margin-left: 20px !important; } }

.u-margin-l {
  margin: 24px !important; }

.u-margin-left-right-l {
  margin-left: 24px !important;
  margin-right: 24px !important; }

.u-margin-top-bottom-l {
  margin-top: 24px !important;
  margin-bottom: 24px !important; }

.u-margin-top-l {
  margin-top: 24px !important; }

.u-margin-right-l {
  margin-right: 24px !important; }

.u-margin-bottom-l {
  margin-bottom: 24px !important; }

.u-margin-left-l {
  margin-left: 24px !important; }

@media (min-width: 540px) {
  .u-margin-l-bp-xs {
    margin: 24px !important; } }

@media (min-width: 800px) {
  .u-margin-l-bp-s.u-margin-l-bp-s {
    margin: 24px !important; } }

@media (min-width: 980px) {
  .u-margin-l-bp-m.u-margin-l-bp-m.u-margin-l-bp-m {
    margin: 24px !important; } }

@media (min-width: 1080px) {
  .u-margin-l-bp-l.u-margin-l-bp-l.u-margin-l-bp-l.u-margin-l-bp-l {
    margin: 24px !important; } }

@media (min-width: 1200px) {
  .u-margin-l-bp-xl.u-margin-l-bp-xl.u-margin-l-bp-xl.u-margin-l-bp-xl.u-margin-l-bp-xl {
    margin: 24px !important; } }

@media (min-width: 1440px) {
  .u-margin-l-bp-xxl.u-margin-l-bp-xxl.u-margin-l-bp-xxl.u-margin-l-bp-xxl.u-margin-l-bp-xxl.u-margin-l-bp-xxl {
    margin: 24px !important; } }

@media (min-width: 540px) {
  .u-margin-left-right-l-bp-xs {
    margin-left: 24px !important; } }

@media (min-width: 800px) {
  .u-margin-left-right-l-bp-s.u-margin-left-right-l-bp-s {
    margin-left: 24px !important; } }

@media (min-width: 980px) {
  .u-margin-left-right-l-bp-m.u-margin-left-right-l-bp-m.u-margin-left-right-l-bp-m {
    margin-left: 24px !important; } }

@media (min-width: 1080px) {
  .u-margin-left-right-l-bp-l.u-margin-left-right-l-bp-l.u-margin-left-right-l-bp-l.u-margin-left-right-l-bp-l {
    margin-left: 24px !important; } }

@media (min-width: 1200px) {
  .u-margin-left-right-l-bp-xl.u-margin-left-right-l-bp-xl.u-margin-left-right-l-bp-xl.u-margin-left-right-l-bp-xl.u-margin-left-right-l-bp-xl {
    margin-left: 24px !important; } }

@media (min-width: 1440px) {
  .u-margin-left-right-l-bp-xxl.u-margin-left-right-l-bp-xxl.u-margin-left-right-l-bp-xxl.u-margin-left-right-l-bp-xxl.u-margin-left-right-l-bp-xxl.u-margin-left-right-l-bp-xxl {
    margin-left: 24px !important; } }

@media (min-width: 540px) {
  .u-margin-left-right-l-bp-xs {
    margin-right: 24px !important; } }

@media (min-width: 800px) {
  .u-margin-left-right-l-bp-s.u-margin-left-right-l-bp-s {
    margin-right: 24px !important; } }

@media (min-width: 980px) {
  .u-margin-left-right-l-bp-m.u-margin-left-right-l-bp-m.u-margin-left-right-l-bp-m {
    margin-right: 24px !important; } }

@media (min-width: 1080px) {
  .u-margin-left-right-l-bp-l.u-margin-left-right-l-bp-l.u-margin-left-right-l-bp-l.u-margin-left-right-l-bp-l {
    margin-right: 24px !important; } }

@media (min-width: 1200px) {
  .u-margin-left-right-l-bp-xl.u-margin-left-right-l-bp-xl.u-margin-left-right-l-bp-xl.u-margin-left-right-l-bp-xl.u-margin-left-right-l-bp-xl {
    margin-right: 24px !important; } }

@media (min-width: 1440px) {
  .u-margin-left-right-l-bp-xxl.u-margin-left-right-l-bp-xxl.u-margin-left-right-l-bp-xxl.u-margin-left-right-l-bp-xxl.u-margin-left-right-l-bp-xxl.u-margin-left-right-l-bp-xxl {
    margin-right: 24px !important; } }

@media (min-width: 540px) {
  .u-margin-top-bottom-l-bp-xs {
    margin-top: 24px !important; } }

@media (min-width: 800px) {
  .u-margin-top-bottom-l-bp-s.u-margin-top-bottom-l-bp-s {
    margin-top: 24px !important; } }

@media (min-width: 980px) {
  .u-margin-top-bottom-l-bp-m.u-margin-top-bottom-l-bp-m.u-margin-top-bottom-l-bp-m {
    margin-top: 24px !important; } }

@media (min-width: 1080px) {
  .u-margin-top-bottom-l-bp-l.u-margin-top-bottom-l-bp-l.u-margin-top-bottom-l-bp-l.u-margin-top-bottom-l-bp-l {
    margin-top: 24px !important; } }

@media (min-width: 1200px) {
  .u-margin-top-bottom-l-bp-xl.u-margin-top-bottom-l-bp-xl.u-margin-top-bottom-l-bp-xl.u-margin-top-bottom-l-bp-xl.u-margin-top-bottom-l-bp-xl {
    margin-top: 24px !important; } }

@media (min-width: 1440px) {
  .u-margin-top-bottom-l-bp-xxl.u-margin-top-bottom-l-bp-xxl.u-margin-top-bottom-l-bp-xxl.u-margin-top-bottom-l-bp-xxl.u-margin-top-bottom-l-bp-xxl.u-margin-top-bottom-l-bp-xxl {
    margin-top: 24px !important; } }

@media (min-width: 540px) {
  .u-margin-top-bottom-l-bp-xs {
    margin-bottom: 24px !important; } }

@media (min-width: 800px) {
  .u-margin-top-bottom-l-bp-s.u-margin-top-bottom-l-bp-s {
    margin-bottom: 24px !important; } }

@media (min-width: 980px) {
  .u-margin-top-bottom-l-bp-m.u-margin-top-bottom-l-bp-m.u-margin-top-bottom-l-bp-m {
    margin-bottom: 24px !important; } }

@media (min-width: 1080px) {
  .u-margin-top-bottom-l-bp-l.u-margin-top-bottom-l-bp-l.u-margin-top-bottom-l-bp-l.u-margin-top-bottom-l-bp-l {
    margin-bottom: 24px !important; } }

@media (min-width: 1200px) {
  .u-margin-top-bottom-l-bp-xl.u-margin-top-bottom-l-bp-xl.u-margin-top-bottom-l-bp-xl.u-margin-top-bottom-l-bp-xl.u-margin-top-bottom-l-bp-xl {
    margin-bottom: 24px !important; } }

@media (min-width: 1440px) {
  .u-margin-top-bottom-l-bp-xxl.u-margin-top-bottom-l-bp-xxl.u-margin-top-bottom-l-bp-xxl.u-margin-top-bottom-l-bp-xxl.u-margin-top-bottom-l-bp-xxl.u-margin-top-bottom-l-bp-xxl {
    margin-bottom: 24px !important; } }

@media (min-width: 540px) {
  .u-margin-top-l-bp-xs {
    margin-top: 24px !important; } }

@media (min-width: 800px) {
  .u-margin-top-l-bp-s.u-margin-top-l-bp-s {
    margin-top: 24px !important; } }

@media (min-width: 980px) {
  .u-margin-top-l-bp-m.u-margin-top-l-bp-m.u-margin-top-l-bp-m {
    margin-top: 24px !important; } }

@media (min-width: 1080px) {
  .u-margin-top-l-bp-l.u-margin-top-l-bp-l.u-margin-top-l-bp-l.u-margin-top-l-bp-l {
    margin-top: 24px !important; } }

@media (min-width: 1200px) {
  .u-margin-top-l-bp-xl.u-margin-top-l-bp-xl.u-margin-top-l-bp-xl.u-margin-top-l-bp-xl.u-margin-top-l-bp-xl {
    margin-top: 24px !important; } }

@media (min-width: 1440px) {
  .u-margin-top-l-bp-xxl.u-margin-top-l-bp-xxl.u-margin-top-l-bp-xxl.u-margin-top-l-bp-xxl.u-margin-top-l-bp-xxl.u-margin-top-l-bp-xxl {
    margin-top: 24px !important; } }

@media (min-width: 540px) {
  .u-margin-right-l-bp-xs {
    margin-right: 24px !important; } }

@media (min-width: 800px) {
  .u-margin-right-l-bp-s.u-margin-right-l-bp-s {
    margin-right: 24px !important; } }

@media (min-width: 980px) {
  .u-margin-right-l-bp-m.u-margin-right-l-bp-m.u-margin-right-l-bp-m {
    margin-right: 24px !important; } }

@media (min-width: 1080px) {
  .u-margin-right-l-bp-l.u-margin-right-l-bp-l.u-margin-right-l-bp-l.u-margin-right-l-bp-l {
    margin-right: 24px !important; } }

@media (min-width: 1200px) {
  .u-margin-right-l-bp-xl.u-margin-right-l-bp-xl.u-margin-right-l-bp-xl.u-margin-right-l-bp-xl.u-margin-right-l-bp-xl {
    margin-right: 24px !important; } }

@media (min-width: 1440px) {
  .u-margin-right-l-bp-xxl.u-margin-right-l-bp-xxl.u-margin-right-l-bp-xxl.u-margin-right-l-bp-xxl.u-margin-right-l-bp-xxl.u-margin-right-l-bp-xxl {
    margin-right: 24px !important; } }

@media (min-width: 540px) {
  .u-margin-bottom-l-bp-xs {
    margin-bottom: 24px !important; } }

@media (min-width: 800px) {
  .u-margin-bottom-l-bp-s.u-margin-bottom-l-bp-s {
    margin-bottom: 24px !important; } }

@media (min-width: 980px) {
  .u-margin-bottom-l-bp-m.u-margin-bottom-l-bp-m.u-margin-bottom-l-bp-m {
    margin-bottom: 24px !important; } }

@media (min-width: 1080px) {
  .u-margin-bottom-l-bp-l.u-margin-bottom-l-bp-l.u-margin-bottom-l-bp-l.u-margin-bottom-l-bp-l {
    margin-bottom: 24px !important; } }

@media (min-width: 1200px) {
  .u-margin-bottom-l-bp-xl.u-margin-bottom-l-bp-xl.u-margin-bottom-l-bp-xl.u-margin-bottom-l-bp-xl.u-margin-bottom-l-bp-xl {
    margin-bottom: 24px !important; } }

@media (min-width: 1440px) {
  .u-margin-bottom-l-bp-xxl.u-margin-bottom-l-bp-xxl.u-margin-bottom-l-bp-xxl.u-margin-bottom-l-bp-xxl.u-margin-bottom-l-bp-xxl.u-margin-bottom-l-bp-xxl {
    margin-bottom: 24px !important; } }

@media (min-width: 540px) {
  .u-margin-left-l-bp-xs {
    margin-left: 24px !important; } }

@media (min-width: 800px) {
  .u-margin-left-l-bp-s.u-margin-left-l-bp-s {
    margin-left: 24px !important; } }

@media (min-width: 980px) {
  .u-margin-left-l-bp-m.u-margin-left-l-bp-m.u-margin-left-l-bp-m {
    margin-left: 24px !important; } }

@media (min-width: 1080px) {
  .u-margin-left-l-bp-l.u-margin-left-l-bp-l.u-margin-left-l-bp-l.u-margin-left-l-bp-l {
    margin-left: 24px !important; } }

@media (min-width: 1200px) {
  .u-margin-left-l-bp-xl.u-margin-left-l-bp-xl.u-margin-left-l-bp-xl.u-margin-left-l-bp-xl.u-margin-left-l-bp-xl {
    margin-left: 24px !important; } }

@media (min-width: 1440px) {
  .u-margin-left-l-bp-xxl.u-margin-left-l-bp-xxl.u-margin-left-l-bp-xxl.u-margin-left-l-bp-xxl.u-margin-left-l-bp-xxl.u-margin-left-l-bp-xxl {
    margin-left: 24px !important; } }

.u-margin-xl {
  margin: 28px !important; }

.u-margin-left-right-xl {
  margin-left: 28px !important;
  margin-right: 28px !important; }

.u-margin-top-bottom-xl {
  margin-top: 28px !important;
  margin-bottom: 28px !important; }

.u-margin-top-xl {
  margin-top: 28px !important; }

.u-margin-right-xl {
  margin-right: 28px !important; }

.u-margin-bottom-xl {
  margin-bottom: 28px !important; }

.u-margin-left-xl {
  margin-left: 28px !important; }

@media (min-width: 540px) {
  .u-margin-xl-bp-xs {
    margin: 28px !important; } }

@media (min-width: 800px) {
  .u-margin-xl-bp-s.u-margin-xl-bp-s {
    margin: 28px !important; } }

@media (min-width: 980px) {
  .u-margin-xl-bp-m.u-margin-xl-bp-m.u-margin-xl-bp-m {
    margin: 28px !important; } }

@media (min-width: 1080px) {
  .u-margin-xl-bp-l.u-margin-xl-bp-l.u-margin-xl-bp-l.u-margin-xl-bp-l {
    margin: 28px !important; } }

@media (min-width: 1200px) {
  .u-margin-xl-bp-xl.u-margin-xl-bp-xl.u-margin-xl-bp-xl.u-margin-xl-bp-xl.u-margin-xl-bp-xl {
    margin: 28px !important; } }

@media (min-width: 1440px) {
  .u-margin-xl-bp-xxl.u-margin-xl-bp-xxl.u-margin-xl-bp-xxl.u-margin-xl-bp-xxl.u-margin-xl-bp-xxl.u-margin-xl-bp-xxl {
    margin: 28px !important; } }

@media (min-width: 540px) {
  .u-margin-left-right-xl-bp-xs {
    margin-left: 28px !important; } }

@media (min-width: 800px) {
  .u-margin-left-right-xl-bp-s.u-margin-left-right-xl-bp-s {
    margin-left: 28px !important; } }

@media (min-width: 980px) {
  .u-margin-left-right-xl-bp-m.u-margin-left-right-xl-bp-m.u-margin-left-right-xl-bp-m {
    margin-left: 28px !important; } }

@media (min-width: 1080px) {
  .u-margin-left-right-xl-bp-l.u-margin-left-right-xl-bp-l.u-margin-left-right-xl-bp-l.u-margin-left-right-xl-bp-l {
    margin-left: 28px !important; } }

@media (min-width: 1200px) {
  .u-margin-left-right-xl-bp-xl.u-margin-left-right-xl-bp-xl.u-margin-left-right-xl-bp-xl.u-margin-left-right-xl-bp-xl.u-margin-left-right-xl-bp-xl {
    margin-left: 28px !important; } }

@media (min-width: 1440px) {
  .u-margin-left-right-xl-bp-xxl.u-margin-left-right-xl-bp-xxl.u-margin-left-right-xl-bp-xxl.u-margin-left-right-xl-bp-xxl.u-margin-left-right-xl-bp-xxl.u-margin-left-right-xl-bp-xxl {
    margin-left: 28px !important; } }

@media (min-width: 540px) {
  .u-margin-left-right-xl-bp-xs {
    margin-right: 28px !important; } }

@media (min-width: 800px) {
  .u-margin-left-right-xl-bp-s.u-margin-left-right-xl-bp-s {
    margin-right: 28px !important; } }

@media (min-width: 980px) {
  .u-margin-left-right-xl-bp-m.u-margin-left-right-xl-bp-m.u-margin-left-right-xl-bp-m {
    margin-right: 28px !important; } }

@media (min-width: 1080px) {
  .u-margin-left-right-xl-bp-l.u-margin-left-right-xl-bp-l.u-margin-left-right-xl-bp-l.u-margin-left-right-xl-bp-l {
    margin-right: 28px !important; } }

@media (min-width: 1200px) {
  .u-margin-left-right-xl-bp-xl.u-margin-left-right-xl-bp-xl.u-margin-left-right-xl-bp-xl.u-margin-left-right-xl-bp-xl.u-margin-left-right-xl-bp-xl {
    margin-right: 28px !important; } }

@media (min-width: 1440px) {
  .u-margin-left-right-xl-bp-xxl.u-margin-left-right-xl-bp-xxl.u-margin-left-right-xl-bp-xxl.u-margin-left-right-xl-bp-xxl.u-margin-left-right-xl-bp-xxl.u-margin-left-right-xl-bp-xxl {
    margin-right: 28px !important; } }

@media (min-width: 540px) {
  .u-margin-top-bottom-xl-bp-xs {
    margin-top: 28px !important; } }

@media (min-width: 800px) {
  .u-margin-top-bottom-xl-bp-s.u-margin-top-bottom-xl-bp-s {
    margin-top: 28px !important; } }

@media (min-width: 980px) {
  .u-margin-top-bottom-xl-bp-m.u-margin-top-bottom-xl-bp-m.u-margin-top-bottom-xl-bp-m {
    margin-top: 28px !important; } }

@media (min-width: 1080px) {
  .u-margin-top-bottom-xl-bp-l.u-margin-top-bottom-xl-bp-l.u-margin-top-bottom-xl-bp-l.u-margin-top-bottom-xl-bp-l {
    margin-top: 28px !important; } }

@media (min-width: 1200px) {
  .u-margin-top-bottom-xl-bp-xl.u-margin-top-bottom-xl-bp-xl.u-margin-top-bottom-xl-bp-xl.u-margin-top-bottom-xl-bp-xl.u-margin-top-bottom-xl-bp-xl {
    margin-top: 28px !important; } }

@media (min-width: 1440px) {
  .u-margin-top-bottom-xl-bp-xxl.u-margin-top-bottom-xl-bp-xxl.u-margin-top-bottom-xl-bp-xxl.u-margin-top-bottom-xl-bp-xxl.u-margin-top-bottom-xl-bp-xxl.u-margin-top-bottom-xl-bp-xxl {
    margin-top: 28px !important; } }

@media (min-width: 540px) {
  .u-margin-top-bottom-xl-bp-xs {
    margin-bottom: 28px !important; } }

@media (min-width: 800px) {
  .u-margin-top-bottom-xl-bp-s.u-margin-top-bottom-xl-bp-s {
    margin-bottom: 28px !important; } }

@media (min-width: 980px) {
  .u-margin-top-bottom-xl-bp-m.u-margin-top-bottom-xl-bp-m.u-margin-top-bottom-xl-bp-m {
    margin-bottom: 28px !important; } }

@media (min-width: 1080px) {
  .u-margin-top-bottom-xl-bp-l.u-margin-top-bottom-xl-bp-l.u-margin-top-bottom-xl-bp-l.u-margin-top-bottom-xl-bp-l {
    margin-bottom: 28px !important; } }

@media (min-width: 1200px) {
  .u-margin-top-bottom-xl-bp-xl.u-margin-top-bottom-xl-bp-xl.u-margin-top-bottom-xl-bp-xl.u-margin-top-bottom-xl-bp-xl.u-margin-top-bottom-xl-bp-xl {
    margin-bottom: 28px !important; } }

@media (min-width: 1440px) {
  .u-margin-top-bottom-xl-bp-xxl.u-margin-top-bottom-xl-bp-xxl.u-margin-top-bottom-xl-bp-xxl.u-margin-top-bottom-xl-bp-xxl.u-margin-top-bottom-xl-bp-xxl.u-margin-top-bottom-xl-bp-xxl {
    margin-bottom: 28px !important; } }

@media (min-width: 540px) {
  .u-margin-top-xl-bp-xs {
    margin-top: 28px !important; } }

@media (min-width: 800px) {
  .u-margin-top-xl-bp-s.u-margin-top-xl-bp-s {
    margin-top: 28px !important; } }

@media (min-width: 980px) {
  .u-margin-top-xl-bp-m.u-margin-top-xl-bp-m.u-margin-top-xl-bp-m {
    margin-top: 28px !important; } }

@media (min-width: 1080px) {
  .u-margin-top-xl-bp-l.u-margin-top-xl-bp-l.u-margin-top-xl-bp-l.u-margin-top-xl-bp-l {
    margin-top: 28px !important; } }

@media (min-width: 1200px) {
  .u-margin-top-xl-bp-xl.u-margin-top-xl-bp-xl.u-margin-top-xl-bp-xl.u-margin-top-xl-bp-xl.u-margin-top-xl-bp-xl {
    margin-top: 28px !important; } }

@media (min-width: 1440px) {
  .u-margin-top-xl-bp-xxl.u-margin-top-xl-bp-xxl.u-margin-top-xl-bp-xxl.u-margin-top-xl-bp-xxl.u-margin-top-xl-bp-xxl.u-margin-top-xl-bp-xxl {
    margin-top: 28px !important; } }

@media (min-width: 540px) {
  .u-margin-right-xl-bp-xs {
    margin-right: 28px !important; } }

@media (min-width: 800px) {
  .u-margin-right-xl-bp-s.u-margin-right-xl-bp-s {
    margin-right: 28px !important; } }

@media (min-width: 980px) {
  .u-margin-right-xl-bp-m.u-margin-right-xl-bp-m.u-margin-right-xl-bp-m {
    margin-right: 28px !important; } }

@media (min-width: 1080px) {
  .u-margin-right-xl-bp-l.u-margin-right-xl-bp-l.u-margin-right-xl-bp-l.u-margin-right-xl-bp-l {
    margin-right: 28px !important; } }

@media (min-width: 1200px) {
  .u-margin-right-xl-bp-xl.u-margin-right-xl-bp-xl.u-margin-right-xl-bp-xl.u-margin-right-xl-bp-xl.u-margin-right-xl-bp-xl {
    margin-right: 28px !important; } }

@media (min-width: 1440px) {
  .u-margin-right-xl-bp-xxl.u-margin-right-xl-bp-xxl.u-margin-right-xl-bp-xxl.u-margin-right-xl-bp-xxl.u-margin-right-xl-bp-xxl.u-margin-right-xl-bp-xxl {
    margin-right: 28px !important; } }

@media (min-width: 540px) {
  .u-margin-bottom-xl-bp-xs {
    margin-bottom: 28px !important; } }

@media (min-width: 800px) {
  .u-margin-bottom-xl-bp-s.u-margin-bottom-xl-bp-s {
    margin-bottom: 28px !important; } }

@media (min-width: 980px) {
  .u-margin-bottom-xl-bp-m.u-margin-bottom-xl-bp-m.u-margin-bottom-xl-bp-m {
    margin-bottom: 28px !important; } }

@media (min-width: 1080px) {
  .u-margin-bottom-xl-bp-l.u-margin-bottom-xl-bp-l.u-margin-bottom-xl-bp-l.u-margin-bottom-xl-bp-l {
    margin-bottom: 28px !important; } }

@media (min-width: 1200px) {
  .u-margin-bottom-xl-bp-xl.u-margin-bottom-xl-bp-xl.u-margin-bottom-xl-bp-xl.u-margin-bottom-xl-bp-xl.u-margin-bottom-xl-bp-xl {
    margin-bottom: 28px !important; } }

@media (min-width: 1440px) {
  .u-margin-bottom-xl-bp-xxl.u-margin-bottom-xl-bp-xxl.u-margin-bottom-xl-bp-xxl.u-margin-bottom-xl-bp-xxl.u-margin-bottom-xl-bp-xxl.u-margin-bottom-xl-bp-xxl {
    margin-bottom: 28px !important; } }

@media (min-width: 540px) {
  .u-margin-left-xl-bp-xs {
    margin-left: 28px !important; } }

@media (min-width: 800px) {
  .u-margin-left-xl-bp-s.u-margin-left-xl-bp-s {
    margin-left: 28px !important; } }

@media (min-width: 980px) {
  .u-margin-left-xl-bp-m.u-margin-left-xl-bp-m.u-margin-left-xl-bp-m {
    margin-left: 28px !important; } }

@media (min-width: 1080px) {
  .u-margin-left-xl-bp-l.u-margin-left-xl-bp-l.u-margin-left-xl-bp-l.u-margin-left-xl-bp-l {
    margin-left: 28px !important; } }

@media (min-width: 1200px) {
  .u-margin-left-xl-bp-xl.u-margin-left-xl-bp-xl.u-margin-left-xl-bp-xl.u-margin-left-xl-bp-xl.u-margin-left-xl-bp-xl {
    margin-left: 28px !important; } }

@media (min-width: 1440px) {
  .u-margin-left-xl-bp-xxl.u-margin-left-xl-bp-xxl.u-margin-left-xl-bp-xxl.u-margin-left-xl-bp-xxl.u-margin-left-xl-bp-xxl.u-margin-left-xl-bp-xxl {
    margin-left: 28px !important; } }

.u-margin-xxl {
  margin: 36px !important; }

.u-margin-left-right-xxl {
  margin-left: 36px !important;
  margin-right: 36px !important; }

.u-margin-top-bottom-xxl {
  margin-top: 36px !important;
  margin-bottom: 36px !important; }

.u-margin-top-xxl {
  margin-top: 36px !important; }

.u-margin-right-xxl {
  margin-right: 36px !important; }

.u-margin-bottom-xxl {
  margin-bottom: 36px !important; }

.u-margin-left-xxl {
  margin-left: 36px !important; }

@media (min-width: 540px) {
  .u-margin-xxl-bp-xs {
    margin: 36px !important; } }

@media (min-width: 800px) {
  .u-margin-xxl-bp-s.u-margin-xxl-bp-s {
    margin: 36px !important; } }

@media (min-width: 980px) {
  .u-margin-xxl-bp-m.u-margin-xxl-bp-m.u-margin-xxl-bp-m {
    margin: 36px !important; } }

@media (min-width: 1080px) {
  .u-margin-xxl-bp-l.u-margin-xxl-bp-l.u-margin-xxl-bp-l.u-margin-xxl-bp-l {
    margin: 36px !important; } }

@media (min-width: 1200px) {
  .u-margin-xxl-bp-xl.u-margin-xxl-bp-xl.u-margin-xxl-bp-xl.u-margin-xxl-bp-xl.u-margin-xxl-bp-xl {
    margin: 36px !important; } }

@media (min-width: 1440px) {
  .u-margin-xxl-bp-xxl.u-margin-xxl-bp-xxl.u-margin-xxl-bp-xxl.u-margin-xxl-bp-xxl.u-margin-xxl-bp-xxl.u-margin-xxl-bp-xxl {
    margin: 36px !important; } }

@media (min-width: 540px) {
  .u-margin-left-right-xxl-bp-xs {
    margin-left: 36px !important; } }

@media (min-width: 800px) {
  .u-margin-left-right-xxl-bp-s.u-margin-left-right-xxl-bp-s {
    margin-left: 36px !important; } }

@media (min-width: 980px) {
  .u-margin-left-right-xxl-bp-m.u-margin-left-right-xxl-bp-m.u-margin-left-right-xxl-bp-m {
    margin-left: 36px !important; } }

@media (min-width: 1080px) {
  .u-margin-left-right-xxl-bp-l.u-margin-left-right-xxl-bp-l.u-margin-left-right-xxl-bp-l.u-margin-left-right-xxl-bp-l {
    margin-left: 36px !important; } }

@media (min-width: 1200px) {
  .u-margin-left-right-xxl-bp-xl.u-margin-left-right-xxl-bp-xl.u-margin-left-right-xxl-bp-xl.u-margin-left-right-xxl-bp-xl.u-margin-left-right-xxl-bp-xl {
    margin-left: 36px !important; } }

@media (min-width: 1440px) {
  .u-margin-left-right-xxl-bp-xxl.u-margin-left-right-xxl-bp-xxl.u-margin-left-right-xxl-bp-xxl.u-margin-left-right-xxl-bp-xxl.u-margin-left-right-xxl-bp-xxl.u-margin-left-right-xxl-bp-xxl {
    margin-left: 36px !important; } }

@media (min-width: 540px) {
  .u-margin-left-right-xxl-bp-xs {
    margin-right: 36px !important; } }

@media (min-width: 800px) {
  .u-margin-left-right-xxl-bp-s.u-margin-left-right-xxl-bp-s {
    margin-right: 36px !important; } }

@media (min-width: 980px) {
  .u-margin-left-right-xxl-bp-m.u-margin-left-right-xxl-bp-m.u-margin-left-right-xxl-bp-m {
    margin-right: 36px !important; } }

@media (min-width: 1080px) {
  .u-margin-left-right-xxl-bp-l.u-margin-left-right-xxl-bp-l.u-margin-left-right-xxl-bp-l.u-margin-left-right-xxl-bp-l {
    margin-right: 36px !important; } }

@media (min-width: 1200px) {
  .u-margin-left-right-xxl-bp-xl.u-margin-left-right-xxl-bp-xl.u-margin-left-right-xxl-bp-xl.u-margin-left-right-xxl-bp-xl.u-margin-left-right-xxl-bp-xl {
    margin-right: 36px !important; } }

@media (min-width: 1440px) {
  .u-margin-left-right-xxl-bp-xxl.u-margin-left-right-xxl-bp-xxl.u-margin-left-right-xxl-bp-xxl.u-margin-left-right-xxl-bp-xxl.u-margin-left-right-xxl-bp-xxl.u-margin-left-right-xxl-bp-xxl {
    margin-right: 36px !important; } }

@media (min-width: 540px) {
  .u-margin-top-bottom-xxl-bp-xs {
    margin-top: 36px !important; } }

@media (min-width: 800px) {
  .u-margin-top-bottom-xxl-bp-s.u-margin-top-bottom-xxl-bp-s {
    margin-top: 36px !important; } }

@media (min-width: 980px) {
  .u-margin-top-bottom-xxl-bp-m.u-margin-top-bottom-xxl-bp-m.u-margin-top-bottom-xxl-bp-m {
    margin-top: 36px !important; } }

@media (min-width: 1080px) {
  .u-margin-top-bottom-xxl-bp-l.u-margin-top-bottom-xxl-bp-l.u-margin-top-bottom-xxl-bp-l.u-margin-top-bottom-xxl-bp-l {
    margin-top: 36px !important; } }

@media (min-width: 1200px) {
  .u-margin-top-bottom-xxl-bp-xl.u-margin-top-bottom-xxl-bp-xl.u-margin-top-bottom-xxl-bp-xl.u-margin-top-bottom-xxl-bp-xl.u-margin-top-bottom-xxl-bp-xl {
    margin-top: 36px !important; } }

@media (min-width: 1440px) {
  .u-margin-top-bottom-xxl-bp-xxl.u-margin-top-bottom-xxl-bp-xxl.u-margin-top-bottom-xxl-bp-xxl.u-margin-top-bottom-xxl-bp-xxl.u-margin-top-bottom-xxl-bp-xxl.u-margin-top-bottom-xxl-bp-xxl {
    margin-top: 36px !important; } }

@media (min-width: 540px) {
  .u-margin-top-bottom-xxl-bp-xs {
    margin-bottom: 36px !important; } }

@media (min-width: 800px) {
  .u-margin-top-bottom-xxl-bp-s.u-margin-top-bottom-xxl-bp-s {
    margin-bottom: 36px !important; } }

@media (min-width: 980px) {
  .u-margin-top-bottom-xxl-bp-m.u-margin-top-bottom-xxl-bp-m.u-margin-top-bottom-xxl-bp-m {
    margin-bottom: 36px !important; } }

@media (min-width: 1080px) {
  .u-margin-top-bottom-xxl-bp-l.u-margin-top-bottom-xxl-bp-l.u-margin-top-bottom-xxl-bp-l.u-margin-top-bottom-xxl-bp-l {
    margin-bottom: 36px !important; } }

@media (min-width: 1200px) {
  .u-margin-top-bottom-xxl-bp-xl.u-margin-top-bottom-xxl-bp-xl.u-margin-top-bottom-xxl-bp-xl.u-margin-top-bottom-xxl-bp-xl.u-margin-top-bottom-xxl-bp-xl {
    margin-bottom: 36px !important; } }

@media (min-width: 1440px) {
  .u-margin-top-bottom-xxl-bp-xxl.u-margin-top-bottom-xxl-bp-xxl.u-margin-top-bottom-xxl-bp-xxl.u-margin-top-bottom-xxl-bp-xxl.u-margin-top-bottom-xxl-bp-xxl.u-margin-top-bottom-xxl-bp-xxl {
    margin-bottom: 36px !important; } }

@media (min-width: 540px) {
  .u-margin-top-xxl-bp-xs {
    margin-top: 36px !important; } }

@media (min-width: 800px) {
  .u-margin-top-xxl-bp-s.u-margin-top-xxl-bp-s {
    margin-top: 36px !important; } }

@media (min-width: 980px) {
  .u-margin-top-xxl-bp-m.u-margin-top-xxl-bp-m.u-margin-top-xxl-bp-m {
    margin-top: 36px !important; } }

@media (min-width: 1080px) {
  .u-margin-top-xxl-bp-l.u-margin-top-xxl-bp-l.u-margin-top-xxl-bp-l.u-margin-top-xxl-bp-l {
    margin-top: 36px !important; } }

@media (min-width: 1200px) {
  .u-margin-top-xxl-bp-xl.u-margin-top-xxl-bp-xl.u-margin-top-xxl-bp-xl.u-margin-top-xxl-bp-xl.u-margin-top-xxl-bp-xl {
    margin-top: 36px !important; } }

@media (min-width: 1440px) {
  .u-margin-top-xxl-bp-xxl.u-margin-top-xxl-bp-xxl.u-margin-top-xxl-bp-xxl.u-margin-top-xxl-bp-xxl.u-margin-top-xxl-bp-xxl.u-margin-top-xxl-bp-xxl {
    margin-top: 36px !important; } }

@media (min-width: 540px) {
  .u-margin-right-xxl-bp-xs {
    margin-right: 36px !important; } }

@media (min-width: 800px) {
  .u-margin-right-xxl-bp-s.u-margin-right-xxl-bp-s {
    margin-right: 36px !important; } }

@media (min-width: 980px) {
  .u-margin-right-xxl-bp-m.u-margin-right-xxl-bp-m.u-margin-right-xxl-bp-m {
    margin-right: 36px !important; } }

@media (min-width: 1080px) {
  .u-margin-right-xxl-bp-l.u-margin-right-xxl-bp-l.u-margin-right-xxl-bp-l.u-margin-right-xxl-bp-l {
    margin-right: 36px !important; } }

@media (min-width: 1200px) {
  .u-margin-right-xxl-bp-xl.u-margin-right-xxl-bp-xl.u-margin-right-xxl-bp-xl.u-margin-right-xxl-bp-xl.u-margin-right-xxl-bp-xl {
    margin-right: 36px !important; } }

@media (min-width: 1440px) {
  .u-margin-right-xxl-bp-xxl.u-margin-right-xxl-bp-xxl.u-margin-right-xxl-bp-xxl.u-margin-right-xxl-bp-xxl.u-margin-right-xxl-bp-xxl.u-margin-right-xxl-bp-xxl {
    margin-right: 36px !important; } }

@media (min-width: 540px) {
  .u-margin-bottom-xxl-bp-xs {
    margin-bottom: 36px !important; } }

@media (min-width: 800px) {
  .u-margin-bottom-xxl-bp-s.u-margin-bottom-xxl-bp-s {
    margin-bottom: 36px !important; } }

@media (min-width: 980px) {
  .u-margin-bottom-xxl-bp-m.u-margin-bottom-xxl-bp-m.u-margin-bottom-xxl-bp-m {
    margin-bottom: 36px !important; } }

@media (min-width: 1080px) {
  .u-margin-bottom-xxl-bp-l.u-margin-bottom-xxl-bp-l.u-margin-bottom-xxl-bp-l.u-margin-bottom-xxl-bp-l {
    margin-bottom: 36px !important; } }

@media (min-width: 1200px) {
  .u-margin-bottom-xxl-bp-xl.u-margin-bottom-xxl-bp-xl.u-margin-bottom-xxl-bp-xl.u-margin-bottom-xxl-bp-xl.u-margin-bottom-xxl-bp-xl {
    margin-bottom: 36px !important; } }

@media (min-width: 1440px) {
  .u-margin-bottom-xxl-bp-xxl.u-margin-bottom-xxl-bp-xxl.u-margin-bottom-xxl-bp-xxl.u-margin-bottom-xxl-bp-xxl.u-margin-bottom-xxl-bp-xxl.u-margin-bottom-xxl-bp-xxl {
    margin-bottom: 36px !important; } }

@media (min-width: 540px) {
  .u-margin-left-xxl-bp-xs {
    margin-left: 36px !important; } }

@media (min-width: 800px) {
  .u-margin-left-xxl-bp-s.u-margin-left-xxl-bp-s {
    margin-left: 36px !important; } }

@media (min-width: 980px) {
  .u-margin-left-xxl-bp-m.u-margin-left-xxl-bp-m.u-margin-left-xxl-bp-m {
    margin-left: 36px !important; } }

@media (min-width: 1080px) {
  .u-margin-left-xxl-bp-l.u-margin-left-xxl-bp-l.u-margin-left-xxl-bp-l.u-margin-left-xxl-bp-l {
    margin-left: 36px !important; } }

@media (min-width: 1200px) {
  .u-margin-left-xxl-bp-xl.u-margin-left-xxl-bp-xl.u-margin-left-xxl-bp-xl.u-margin-left-xxl-bp-xl.u-margin-left-xxl-bp-xl {
    margin-left: 36px !important; } }

@media (min-width: 1440px) {
  .u-margin-left-xxl-bp-xxl.u-margin-left-xxl-bp-xxl.u-margin-left-xxl-bp-xxl.u-margin-left-xxl-bp-xxl.u-margin-left-xxl-bp-xxl.u-margin-left-xxl-bp-xxl {
    margin-left: 36px !important; } }

.u-margin-xxxl {
  margin: 48px !important; }

.u-margin-left-right-xxxl {
  margin-left: 48px !important;
  margin-right: 48px !important; }

.u-margin-top-bottom-xxxl {
  margin-top: 48px !important;
  margin-bottom: 48px !important; }

.u-margin-top-xxxl {
  margin-top: 48px !important; }

.u-margin-right-xxxl {
  margin-right: 48px !important; }

.u-margin-bottom-xxxl {
  margin-bottom: 48px !important; }

.u-margin-left-xxxl {
  margin-left: 48px !important; }

@media (min-width: 540px) {
  .u-margin-xxxl-bp-xs {
    margin: 48px !important; } }

@media (min-width: 800px) {
  .u-margin-xxxl-bp-s.u-margin-xxxl-bp-s {
    margin: 48px !important; } }

@media (min-width: 980px) {
  .u-margin-xxxl-bp-m.u-margin-xxxl-bp-m.u-margin-xxxl-bp-m {
    margin: 48px !important; } }

@media (min-width: 1080px) {
  .u-margin-xxxl-bp-l.u-margin-xxxl-bp-l.u-margin-xxxl-bp-l.u-margin-xxxl-bp-l {
    margin: 48px !important; } }

@media (min-width: 1200px) {
  .u-margin-xxxl-bp-xl.u-margin-xxxl-bp-xl.u-margin-xxxl-bp-xl.u-margin-xxxl-bp-xl.u-margin-xxxl-bp-xl {
    margin: 48px !important; } }

@media (min-width: 1440px) {
  .u-margin-xxxl-bp-xxl.u-margin-xxxl-bp-xxl.u-margin-xxxl-bp-xxl.u-margin-xxxl-bp-xxl.u-margin-xxxl-bp-xxl.u-margin-xxxl-bp-xxl {
    margin: 48px !important; } }

@media (min-width: 540px) {
  .u-margin-left-right-xxxl-bp-xs {
    margin-left: 48px !important; } }

@media (min-width: 800px) {
  .u-margin-left-right-xxxl-bp-s.u-margin-left-right-xxxl-bp-s {
    margin-left: 48px !important; } }

@media (min-width: 980px) {
  .u-margin-left-right-xxxl-bp-m.u-margin-left-right-xxxl-bp-m.u-margin-left-right-xxxl-bp-m {
    margin-left: 48px !important; } }

@media (min-width: 1080px) {
  .u-margin-left-right-xxxl-bp-l.u-margin-left-right-xxxl-bp-l.u-margin-left-right-xxxl-bp-l.u-margin-left-right-xxxl-bp-l {
    margin-left: 48px !important; } }

@media (min-width: 1200px) {
  .u-margin-left-right-xxxl-bp-xl.u-margin-left-right-xxxl-bp-xl.u-margin-left-right-xxxl-bp-xl.u-margin-left-right-xxxl-bp-xl.u-margin-left-right-xxxl-bp-xl {
    margin-left: 48px !important; } }

@media (min-width: 1440px) {
  .u-margin-left-right-xxxl-bp-xxl.u-margin-left-right-xxxl-bp-xxl.u-margin-left-right-xxxl-bp-xxl.u-margin-left-right-xxxl-bp-xxl.u-margin-left-right-xxxl-bp-xxl.u-margin-left-right-xxxl-bp-xxl {
    margin-left: 48px !important; } }

@media (min-width: 540px) {
  .u-margin-left-right-xxxl-bp-xs {
    margin-right: 48px !important; } }

@media (min-width: 800px) {
  .u-margin-left-right-xxxl-bp-s.u-margin-left-right-xxxl-bp-s {
    margin-right: 48px !important; } }

@media (min-width: 980px) {
  .u-margin-left-right-xxxl-bp-m.u-margin-left-right-xxxl-bp-m.u-margin-left-right-xxxl-bp-m {
    margin-right: 48px !important; } }

@media (min-width: 1080px) {
  .u-margin-left-right-xxxl-bp-l.u-margin-left-right-xxxl-bp-l.u-margin-left-right-xxxl-bp-l.u-margin-left-right-xxxl-bp-l {
    margin-right: 48px !important; } }

@media (min-width: 1200px) {
  .u-margin-left-right-xxxl-bp-xl.u-margin-left-right-xxxl-bp-xl.u-margin-left-right-xxxl-bp-xl.u-margin-left-right-xxxl-bp-xl.u-margin-left-right-xxxl-bp-xl {
    margin-right: 48px !important; } }

@media (min-width: 1440px) {
  .u-margin-left-right-xxxl-bp-xxl.u-margin-left-right-xxxl-bp-xxl.u-margin-left-right-xxxl-bp-xxl.u-margin-left-right-xxxl-bp-xxl.u-margin-left-right-xxxl-bp-xxl.u-margin-left-right-xxxl-bp-xxl {
    margin-right: 48px !important; } }

@media (min-width: 540px) {
  .u-margin-top-bottom-xxxl-bp-xs {
    margin-top: 48px !important; } }

@media (min-width: 800px) {
  .u-margin-top-bottom-xxxl-bp-s.u-margin-top-bottom-xxxl-bp-s {
    margin-top: 48px !important; } }

@media (min-width: 980px) {
  .u-margin-top-bottom-xxxl-bp-m.u-margin-top-bottom-xxxl-bp-m.u-margin-top-bottom-xxxl-bp-m {
    margin-top: 48px !important; } }

@media (min-width: 1080px) {
  .u-margin-top-bottom-xxxl-bp-l.u-margin-top-bottom-xxxl-bp-l.u-margin-top-bottom-xxxl-bp-l.u-margin-top-bottom-xxxl-bp-l {
    margin-top: 48px !important; } }

@media (min-width: 1200px) {
  .u-margin-top-bottom-xxxl-bp-xl.u-margin-top-bottom-xxxl-bp-xl.u-margin-top-bottom-xxxl-bp-xl.u-margin-top-bottom-xxxl-bp-xl.u-margin-top-bottom-xxxl-bp-xl {
    margin-top: 48px !important; } }

@media (min-width: 1440px) {
  .u-margin-top-bottom-xxxl-bp-xxl.u-margin-top-bottom-xxxl-bp-xxl.u-margin-top-bottom-xxxl-bp-xxl.u-margin-top-bottom-xxxl-bp-xxl.u-margin-top-bottom-xxxl-bp-xxl.u-margin-top-bottom-xxxl-bp-xxl {
    margin-top: 48px !important; } }

@media (min-width: 540px) {
  .u-margin-top-bottom-xxxl-bp-xs {
    margin-bottom: 48px !important; } }

@media (min-width: 800px) {
  .u-margin-top-bottom-xxxl-bp-s.u-margin-top-bottom-xxxl-bp-s {
    margin-bottom: 48px !important; } }

@media (min-width: 980px) {
  .u-margin-top-bottom-xxxl-bp-m.u-margin-top-bottom-xxxl-bp-m.u-margin-top-bottom-xxxl-bp-m {
    margin-bottom: 48px !important; } }

@media (min-width: 1080px) {
  .u-margin-top-bottom-xxxl-bp-l.u-margin-top-bottom-xxxl-bp-l.u-margin-top-bottom-xxxl-bp-l.u-margin-top-bottom-xxxl-bp-l {
    margin-bottom: 48px !important; } }

@media (min-width: 1200px) {
  .u-margin-top-bottom-xxxl-bp-xl.u-margin-top-bottom-xxxl-bp-xl.u-margin-top-bottom-xxxl-bp-xl.u-margin-top-bottom-xxxl-bp-xl.u-margin-top-bottom-xxxl-bp-xl {
    margin-bottom: 48px !important; } }

@media (min-width: 1440px) {
  .u-margin-top-bottom-xxxl-bp-xxl.u-margin-top-bottom-xxxl-bp-xxl.u-margin-top-bottom-xxxl-bp-xxl.u-margin-top-bottom-xxxl-bp-xxl.u-margin-top-bottom-xxxl-bp-xxl.u-margin-top-bottom-xxxl-bp-xxl {
    margin-bottom: 48px !important; } }

@media (min-width: 540px) {
  .u-margin-top-xxxl-bp-xs {
    margin-top: 48px !important; } }

@media (min-width: 800px) {
  .u-margin-top-xxxl-bp-s.u-margin-top-xxxl-bp-s {
    margin-top: 48px !important; } }

@media (min-width: 980px) {
  .u-margin-top-xxxl-bp-m.u-margin-top-xxxl-bp-m.u-margin-top-xxxl-bp-m {
    margin-top: 48px !important; } }

@media (min-width: 1080px) {
  .u-margin-top-xxxl-bp-l.u-margin-top-xxxl-bp-l.u-margin-top-xxxl-bp-l.u-margin-top-xxxl-bp-l {
    margin-top: 48px !important; } }

@media (min-width: 1200px) {
  .u-margin-top-xxxl-bp-xl.u-margin-top-xxxl-bp-xl.u-margin-top-xxxl-bp-xl.u-margin-top-xxxl-bp-xl.u-margin-top-xxxl-bp-xl {
    margin-top: 48px !important; } }

@media (min-width: 1440px) {
  .u-margin-top-xxxl-bp-xxl.u-margin-top-xxxl-bp-xxl.u-margin-top-xxxl-bp-xxl.u-margin-top-xxxl-bp-xxl.u-margin-top-xxxl-bp-xxl.u-margin-top-xxxl-bp-xxl {
    margin-top: 48px !important; } }

@media (min-width: 540px) {
  .u-margin-right-xxxl-bp-xs {
    margin-right: 48px !important; } }

@media (min-width: 800px) {
  .u-margin-right-xxxl-bp-s.u-margin-right-xxxl-bp-s {
    margin-right: 48px !important; } }

@media (min-width: 980px) {
  .u-margin-right-xxxl-bp-m.u-margin-right-xxxl-bp-m.u-margin-right-xxxl-bp-m {
    margin-right: 48px !important; } }

@media (min-width: 1080px) {
  .u-margin-right-xxxl-bp-l.u-margin-right-xxxl-bp-l.u-margin-right-xxxl-bp-l.u-margin-right-xxxl-bp-l {
    margin-right: 48px !important; } }

@media (min-width: 1200px) {
  .u-margin-right-xxxl-bp-xl.u-margin-right-xxxl-bp-xl.u-margin-right-xxxl-bp-xl.u-margin-right-xxxl-bp-xl.u-margin-right-xxxl-bp-xl {
    margin-right: 48px !important; } }

@media (min-width: 1440px) {
  .u-margin-right-xxxl-bp-xxl.u-margin-right-xxxl-bp-xxl.u-margin-right-xxxl-bp-xxl.u-margin-right-xxxl-bp-xxl.u-margin-right-xxxl-bp-xxl.u-margin-right-xxxl-bp-xxl {
    margin-right: 48px !important; } }

@media (min-width: 540px) {
  .u-margin-bottom-xxxl-bp-xs {
    margin-bottom: 48px !important; } }

@media (min-width: 800px) {
  .u-margin-bottom-xxxl-bp-s.u-margin-bottom-xxxl-bp-s {
    margin-bottom: 48px !important; } }

@media (min-width: 980px) {
  .u-margin-bottom-xxxl-bp-m.u-margin-bottom-xxxl-bp-m.u-margin-bottom-xxxl-bp-m {
    margin-bottom: 48px !important; } }

@media (min-width: 1080px) {
  .u-margin-bottom-xxxl-bp-l.u-margin-bottom-xxxl-bp-l.u-margin-bottom-xxxl-bp-l.u-margin-bottom-xxxl-bp-l {
    margin-bottom: 48px !important; } }

@media (min-width: 1200px) {
  .u-margin-bottom-xxxl-bp-xl.u-margin-bottom-xxxl-bp-xl.u-margin-bottom-xxxl-bp-xl.u-margin-bottom-xxxl-bp-xl.u-margin-bottom-xxxl-bp-xl {
    margin-bottom: 48px !important; } }

@media (min-width: 1440px) {
  .u-margin-bottom-xxxl-bp-xxl.u-margin-bottom-xxxl-bp-xxl.u-margin-bottom-xxxl-bp-xxl.u-margin-bottom-xxxl-bp-xxl.u-margin-bottom-xxxl-bp-xxl.u-margin-bottom-xxxl-bp-xxl {
    margin-bottom: 48px !important; } }

@media (min-width: 540px) {
  .u-margin-left-xxxl-bp-xs {
    margin-left: 48px !important; } }

@media (min-width: 800px) {
  .u-margin-left-xxxl-bp-s.u-margin-left-xxxl-bp-s {
    margin-left: 48px !important; } }

@media (min-width: 980px) {
  .u-margin-left-xxxl-bp-m.u-margin-left-xxxl-bp-m.u-margin-left-xxxl-bp-m {
    margin-left: 48px !important; } }

@media (min-width: 1080px) {
  .u-margin-left-xxxl-bp-l.u-margin-left-xxxl-bp-l.u-margin-left-xxxl-bp-l.u-margin-left-xxxl-bp-l {
    margin-left: 48px !important; } }

@media (min-width: 1200px) {
  .u-margin-left-xxxl-bp-xl.u-margin-left-xxxl-bp-xl.u-margin-left-xxxl-bp-xl.u-margin-left-xxxl-bp-xl.u-margin-left-xxxl-bp-xl {
    margin-left: 48px !important; } }

@media (min-width: 1440px) {
  .u-margin-left-xxxl-bp-xxl.u-margin-left-xxxl-bp-xxl.u-margin-left-xxxl-bp-xxl.u-margin-left-xxxl-bp-xxl.u-margin-left-xxxl-bp-xxl.u-margin-left-xxxl-bp-xxl {
    margin-left: 48px !important; } }

.u-margin-xxxxl {
  margin: 60px !important; }

.u-margin-left-right-xxxxl {
  margin-left: 60px !important;
  margin-right: 60px !important; }

.u-margin-top-bottom-xxxxl {
  margin-top: 60px !important;
  margin-bottom: 60px !important; }

.u-margin-top-xxxxl {
  margin-top: 60px !important; }

.u-margin-right-xxxxl {
  margin-right: 60px !important; }

.u-margin-bottom-xxxxl {
  margin-bottom: 60px !important; }

.u-margin-left-xxxxl {
  margin-left: 60px !important; }

@media (min-width: 540px) {
  .u-margin-xxxxl-bp-xs {
    margin: 60px !important; } }

@media (min-width: 800px) {
  .u-margin-xxxxl-bp-s.u-margin-xxxxl-bp-s {
    margin: 60px !important; } }

@media (min-width: 980px) {
  .u-margin-xxxxl-bp-m.u-margin-xxxxl-bp-m.u-margin-xxxxl-bp-m {
    margin: 60px !important; } }

@media (min-width: 1080px) {
  .u-margin-xxxxl-bp-l.u-margin-xxxxl-bp-l.u-margin-xxxxl-bp-l.u-margin-xxxxl-bp-l {
    margin: 60px !important; } }

@media (min-width: 1200px) {
  .u-margin-xxxxl-bp-xl.u-margin-xxxxl-bp-xl.u-margin-xxxxl-bp-xl.u-margin-xxxxl-bp-xl.u-margin-xxxxl-bp-xl {
    margin: 60px !important; } }

@media (min-width: 1440px) {
  .u-margin-xxxxl-bp-xxl.u-margin-xxxxl-bp-xxl.u-margin-xxxxl-bp-xxl.u-margin-xxxxl-bp-xxl.u-margin-xxxxl-bp-xxl.u-margin-xxxxl-bp-xxl {
    margin: 60px !important; } }

@media (min-width: 540px) {
  .u-margin-left-right-xxxxl-bp-xs {
    margin-left: 60px !important; } }

@media (min-width: 800px) {
  .u-margin-left-right-xxxxl-bp-s.u-margin-left-right-xxxxl-bp-s {
    margin-left: 60px !important; } }

@media (min-width: 980px) {
  .u-margin-left-right-xxxxl-bp-m.u-margin-left-right-xxxxl-bp-m.u-margin-left-right-xxxxl-bp-m {
    margin-left: 60px !important; } }

@media (min-width: 1080px) {
  .u-margin-left-right-xxxxl-bp-l.u-margin-left-right-xxxxl-bp-l.u-margin-left-right-xxxxl-bp-l.u-margin-left-right-xxxxl-bp-l {
    margin-left: 60px !important; } }

@media (min-width: 1200px) {
  .u-margin-left-right-xxxxl-bp-xl.u-margin-left-right-xxxxl-bp-xl.u-margin-left-right-xxxxl-bp-xl.u-margin-left-right-xxxxl-bp-xl.u-margin-left-right-xxxxl-bp-xl {
    margin-left: 60px !important; } }

@media (min-width: 1440px) {
  .u-margin-left-right-xxxxl-bp-xxl.u-margin-left-right-xxxxl-bp-xxl.u-margin-left-right-xxxxl-bp-xxl.u-margin-left-right-xxxxl-bp-xxl.u-margin-left-right-xxxxl-bp-xxl.u-margin-left-right-xxxxl-bp-xxl {
    margin-left: 60px !important; } }

@media (min-width: 540px) {
  .u-margin-left-right-xxxxl-bp-xs {
    margin-right: 60px !important; } }

@media (min-width: 800px) {
  .u-margin-left-right-xxxxl-bp-s.u-margin-left-right-xxxxl-bp-s {
    margin-right: 60px !important; } }

@media (min-width: 980px) {
  .u-margin-left-right-xxxxl-bp-m.u-margin-left-right-xxxxl-bp-m.u-margin-left-right-xxxxl-bp-m {
    margin-right: 60px !important; } }

@media (min-width: 1080px) {
  .u-margin-left-right-xxxxl-bp-l.u-margin-left-right-xxxxl-bp-l.u-margin-left-right-xxxxl-bp-l.u-margin-left-right-xxxxl-bp-l {
    margin-right: 60px !important; } }

@media (min-width: 1200px) {
  .u-margin-left-right-xxxxl-bp-xl.u-margin-left-right-xxxxl-bp-xl.u-margin-left-right-xxxxl-bp-xl.u-margin-left-right-xxxxl-bp-xl.u-margin-left-right-xxxxl-bp-xl {
    margin-right: 60px !important; } }

@media (min-width: 1440px) {
  .u-margin-left-right-xxxxl-bp-xxl.u-margin-left-right-xxxxl-bp-xxl.u-margin-left-right-xxxxl-bp-xxl.u-margin-left-right-xxxxl-bp-xxl.u-margin-left-right-xxxxl-bp-xxl.u-margin-left-right-xxxxl-bp-xxl {
    margin-right: 60px !important; } }

@media (min-width: 540px) {
  .u-margin-top-bottom-xxxxl-bp-xs {
    margin-top: 60px !important; } }

@media (min-width: 800px) {
  .u-margin-top-bottom-xxxxl-bp-s.u-margin-top-bottom-xxxxl-bp-s {
    margin-top: 60px !important; } }

@media (min-width: 980px) {
  .u-margin-top-bottom-xxxxl-bp-m.u-margin-top-bottom-xxxxl-bp-m.u-margin-top-bottom-xxxxl-bp-m {
    margin-top: 60px !important; } }

@media (min-width: 1080px) {
  .u-margin-top-bottom-xxxxl-bp-l.u-margin-top-bottom-xxxxl-bp-l.u-margin-top-bottom-xxxxl-bp-l.u-margin-top-bottom-xxxxl-bp-l {
    margin-top: 60px !important; } }

@media (min-width: 1200px) {
  .u-margin-top-bottom-xxxxl-bp-xl.u-margin-top-bottom-xxxxl-bp-xl.u-margin-top-bottom-xxxxl-bp-xl.u-margin-top-bottom-xxxxl-bp-xl.u-margin-top-bottom-xxxxl-bp-xl {
    margin-top: 60px !important; } }

@media (min-width: 1440px) {
  .u-margin-top-bottom-xxxxl-bp-xxl.u-margin-top-bottom-xxxxl-bp-xxl.u-margin-top-bottom-xxxxl-bp-xxl.u-margin-top-bottom-xxxxl-bp-xxl.u-margin-top-bottom-xxxxl-bp-xxl.u-margin-top-bottom-xxxxl-bp-xxl {
    margin-top: 60px !important; } }

@media (min-width: 540px) {
  .u-margin-top-bottom-xxxxl-bp-xs {
    margin-bottom: 60px !important; } }

@media (min-width: 800px) {
  .u-margin-top-bottom-xxxxl-bp-s.u-margin-top-bottom-xxxxl-bp-s {
    margin-bottom: 60px !important; } }

@media (min-width: 980px) {
  .u-margin-top-bottom-xxxxl-bp-m.u-margin-top-bottom-xxxxl-bp-m.u-margin-top-bottom-xxxxl-bp-m {
    margin-bottom: 60px !important; } }

@media (min-width: 1080px) {
  .u-margin-top-bottom-xxxxl-bp-l.u-margin-top-bottom-xxxxl-bp-l.u-margin-top-bottom-xxxxl-bp-l.u-margin-top-bottom-xxxxl-bp-l {
    margin-bottom: 60px !important; } }

@media (min-width: 1200px) {
  .u-margin-top-bottom-xxxxl-bp-xl.u-margin-top-bottom-xxxxl-bp-xl.u-margin-top-bottom-xxxxl-bp-xl.u-margin-top-bottom-xxxxl-bp-xl.u-margin-top-bottom-xxxxl-bp-xl {
    margin-bottom: 60px !important; } }

@media (min-width: 1440px) {
  .u-margin-top-bottom-xxxxl-bp-xxl.u-margin-top-bottom-xxxxl-bp-xxl.u-margin-top-bottom-xxxxl-bp-xxl.u-margin-top-bottom-xxxxl-bp-xxl.u-margin-top-bottom-xxxxl-bp-xxl.u-margin-top-bottom-xxxxl-bp-xxl {
    margin-bottom: 60px !important; } }

@media (min-width: 540px) {
  .u-margin-top-xxxxl-bp-xs {
    margin-top: 60px !important; } }

@media (min-width: 800px) {
  .u-margin-top-xxxxl-bp-s.u-margin-top-xxxxl-bp-s {
    margin-top: 60px !important; } }

@media (min-width: 980px) {
  .u-margin-top-xxxxl-bp-m.u-margin-top-xxxxl-bp-m.u-margin-top-xxxxl-bp-m {
    margin-top: 60px !important; } }

@media (min-width: 1080px) {
  .u-margin-top-xxxxl-bp-l.u-margin-top-xxxxl-bp-l.u-margin-top-xxxxl-bp-l.u-margin-top-xxxxl-bp-l {
    margin-top: 60px !important; } }

@media (min-width: 1200px) {
  .u-margin-top-xxxxl-bp-xl.u-margin-top-xxxxl-bp-xl.u-margin-top-xxxxl-bp-xl.u-margin-top-xxxxl-bp-xl.u-margin-top-xxxxl-bp-xl {
    margin-top: 60px !important; } }

@media (min-width: 1440px) {
  .u-margin-top-xxxxl-bp-xxl.u-margin-top-xxxxl-bp-xxl.u-margin-top-xxxxl-bp-xxl.u-margin-top-xxxxl-bp-xxl.u-margin-top-xxxxl-bp-xxl.u-margin-top-xxxxl-bp-xxl {
    margin-top: 60px !important; } }

@media (min-width: 540px) {
  .u-margin-right-xxxxl-bp-xs {
    margin-right: 60px !important; } }

@media (min-width: 800px) {
  .u-margin-right-xxxxl-bp-s.u-margin-right-xxxxl-bp-s {
    margin-right: 60px !important; } }

@media (min-width: 980px) {
  .u-margin-right-xxxxl-bp-m.u-margin-right-xxxxl-bp-m.u-margin-right-xxxxl-bp-m {
    margin-right: 60px !important; } }

@media (min-width: 1080px) {
  .u-margin-right-xxxxl-bp-l.u-margin-right-xxxxl-bp-l.u-margin-right-xxxxl-bp-l.u-margin-right-xxxxl-bp-l {
    margin-right: 60px !important; } }

@media (min-width: 1200px) {
  .u-margin-right-xxxxl-bp-xl.u-margin-right-xxxxl-bp-xl.u-margin-right-xxxxl-bp-xl.u-margin-right-xxxxl-bp-xl.u-margin-right-xxxxl-bp-xl {
    margin-right: 60px !important; } }

@media (min-width: 1440px) {
  .u-margin-right-xxxxl-bp-xxl.u-margin-right-xxxxl-bp-xxl.u-margin-right-xxxxl-bp-xxl.u-margin-right-xxxxl-bp-xxl.u-margin-right-xxxxl-bp-xxl.u-margin-right-xxxxl-bp-xxl {
    margin-right: 60px !important; } }

@media (min-width: 540px) {
  .u-margin-bottom-xxxxl-bp-xs {
    margin-bottom: 60px !important; } }

@media (min-width: 800px) {
  .u-margin-bottom-xxxxl-bp-s.u-margin-bottom-xxxxl-bp-s {
    margin-bottom: 60px !important; } }

@media (min-width: 980px) {
  .u-margin-bottom-xxxxl-bp-m.u-margin-bottom-xxxxl-bp-m.u-margin-bottom-xxxxl-bp-m {
    margin-bottom: 60px !important; } }

@media (min-width: 1080px) {
  .u-margin-bottom-xxxxl-bp-l.u-margin-bottom-xxxxl-bp-l.u-margin-bottom-xxxxl-bp-l.u-margin-bottom-xxxxl-bp-l {
    margin-bottom: 60px !important; } }

@media (min-width: 1200px) {
  .u-margin-bottom-xxxxl-bp-xl.u-margin-bottom-xxxxl-bp-xl.u-margin-bottom-xxxxl-bp-xl.u-margin-bottom-xxxxl-bp-xl.u-margin-bottom-xxxxl-bp-xl {
    margin-bottom: 60px !important; } }

@media (min-width: 1440px) {
  .u-margin-bottom-xxxxl-bp-xxl.u-margin-bottom-xxxxl-bp-xxl.u-margin-bottom-xxxxl-bp-xxl.u-margin-bottom-xxxxl-bp-xxl.u-margin-bottom-xxxxl-bp-xxl.u-margin-bottom-xxxxl-bp-xxl {
    margin-bottom: 60px !important; } }

@media (min-width: 540px) {
  .u-margin-left-xxxxl-bp-xs {
    margin-left: 60px !important; } }

@media (min-width: 800px) {
  .u-margin-left-xxxxl-bp-s.u-margin-left-xxxxl-bp-s {
    margin-left: 60px !important; } }

@media (min-width: 980px) {
  .u-margin-left-xxxxl-bp-m.u-margin-left-xxxxl-bp-m.u-margin-left-xxxxl-bp-m {
    margin-left: 60px !important; } }

@media (min-width: 1080px) {
  .u-margin-left-xxxxl-bp-l.u-margin-left-xxxxl-bp-l.u-margin-left-xxxxl-bp-l.u-margin-left-xxxxl-bp-l {
    margin-left: 60px !important; } }

@media (min-width: 1200px) {
  .u-margin-left-xxxxl-bp-xl.u-margin-left-xxxxl-bp-xl.u-margin-left-xxxxl-bp-xl.u-margin-left-xxxxl-bp-xl.u-margin-left-xxxxl-bp-xl {
    margin-left: 60px !important; } }

@media (min-width: 1440px) {
  .u-margin-left-xxxxl-bp-xxl.u-margin-left-xxxxl-bp-xxl.u-margin-left-xxxxl-bp-xxl.u-margin-left-xxxxl-bp-xxl.u-margin-left-xxxxl-bp-xxl.u-margin-left-xxxxl-bp-xxl {
    margin-left: 60px !important; } }

.u-margin-left-right-auto {
  margin-left: auto;
  margin-right: auto; }

.u-margin-left-auto {
  margin-left: auto !important; }

.u-margin-bottom-minus-66 {
  margin-bottom: -66px !important; }

.u-margin-bottom-16 {
  margin-bottom: 16px !important; }

.u-margin-bottom-10vh {
  margin-bottom: 10vh !important; }

.u-margin-bottom-200 {
  margin-bottom: 200px !important; }

@media (min-width: 1080px) {
  .u-padding-left-70-l {
    padding-left: 70px !important; } }

.u-last-child-margin-bottom-0 *:last-child {
  margin-bottom: 0 !important; }

.u-rotate-5 {
  transform: rotateZ(-5deg); }

/*------------------------------------*\
    Element Utilities
\*------------------------------------*/
.u-overflow-auto {
  overflow: auto; }

.u-overflow-hidden {
  overflow: hidden; }

.u-overflow-visible {
  overflow: visible; }

/* -- Floats ------------------------ */
.u-float-right {
  float: right !important; }

@media (min-width: 540px) {
  .u-float-right-xs {
    float: right !important; } }

@media (min-width: 800px) {
  .u-float-right-s.u-float-right-s {
    float: right !important; } }

@media (min-width: 980px) {
  .u-float-right-m.u-float-right-m.u-float-right-m {
    float: right !important; } }

@media (min-width: 1080px) {
  .u-float-right-l.u-float-right-l.u-float-right-l.u-float-right-l {
    float: right !important; } }

@media (min-width: 1200px) {
  .u-float-right-xl.u-float-right-xl.u-float-right-xl.u-float-right-xl.u-float-right-xl {
    float: right !important; } }

@media (min-width: 1440px) {
  .u-float-right-xxl.u-float-right-xxl.u-float-right-xxl.u-float-right-xxl.u-float-right-xxl.u-float-right-xxl {
    float: right !important; } }

.u-float-left {
  float: left !important; }

@media (min-width: 540px) {
  .u-float-left-xs {
    float: left !important; } }

@media (min-width: 800px) {
  .u-float-left-s.u-float-left-s {
    float: left !important; } }

@media (min-width: 980px) {
  .u-float-left-m.u-float-left-m.u-float-left-m {
    float: left !important; } }

@media (min-width: 1080px) {
  .u-float-left-l.u-float-left-l.u-float-left-l.u-float-left-l {
    float: left !important; } }

@media (min-width: 1200px) {
  .u-float-left-xl.u-float-left-xl.u-float-left-xl.u-float-left-xl.u-float-left-xl {
    float: left !important; } }

@media (min-width: 1440px) {
  .u-float-left-xxl.u-float-left-xxl.u-float-left-xxl.u-float-left-xxl.u-float-left-xxl.u-float-left-xxl {
    float: left !important; } }

.u-float-clear {
  clear: both; }

.u-clear-both {
  clear: both; }

.u-clear-left {
  clear: left !important; }

.u-float-none {
  float: none !important; }

/* -- Cursors ----------------------- */
.u-cursor-pointer {
  cursor: pointer !important; }

.u-cursor-progress {
  cursor: progress !important; }

/* -- Position ---------------------- */
.u-position-relative {
  position: relative !important; }

.u-position-absolute {
  position: absolute !important; }

.u-position-fixed {
  position: fixed !important; }

.u-left-0 {
  left: 0; }

.u-right-0 {
  right: 0; }

.u-top-0 {
  top: 0; }

/* -- Standard Styled Elements ------ */
.u-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis; }

.u-white-box {
  margin-bottom: 24px;
  position: relative;
  color: #000000;
  padding: 20px;
  background-color: #ffffff;
  border: 1px solid #ebebeb; }
  @media (min-width: 900px) {
    .u-white-box {
      padding: 28px; } }

.u-color-5-border-box {
  margin-bottom: 24px;
  position: relative;
  color: #005EB0;
  padding: 12px;
  background-color: transparent;
  border: 2px solid #005EB0; }

.u-color-5-box {
  margin-bottom: 24px;
  position: relative;
  color: #ffffff;
  padding: 12px;
  background-color: #005EB0;
  border: 0; }

.u-clearfix:after {
  content: "";
  display: table;
  clear: both; }

/* -- Hidden Elements --------------- */
.u-expand-height-from-zero {
  overflow: hidden;
  height: 0;
  transition: height 0.3s ease-in-out; }

/* for accessibility purposes */
.u-visually-hidden {
  margin: -1px;
  padding: 0;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0 0 0 0);
  clip: rect(0, 0, 0, 0);
  position: absolute; }

.u-visibility-hidden {
  visibility: hidden;
  opacity: 0;
  transition: opacity .3s ease-in-out;
  transition-property: visibility, opacity; }

.u-visibility-hidden-until-load {
  visibility: hidden;
  opacity: 0;
  transition: opacity .3s ease-in-out;
  transition-property: visibility, opacity; }

.u-autofill-fix-hide-input {
  opacity: 0;
  width: 0px;
  height: 0px;
  position: absolute;
  top: -9999px;
  left: -9999px; }

/* -- Images -------------------------- */
.u-image-reset {
  max-width: 100%;
  display: inline-block;
  margin: 0 0 0 0; }

/* -- borders ------------------------- */
.u-border-fix {
  border: 1px solid transparent; }

/* -- Z-Index ------------------------- */
.u-z-index-10 {
  z-index: 10; }

.u-z-index-20 {
  z-index: 20; }

.u-z-index-30 {
  z-index: 30; }

.u-z-index-40 {
  z-index: 40; }

.u-z-index-50 {
  z-index: 50; }

.u-z-index-60 {
  z-index: 60; }

.u-z-index-70 {
  z-index: 70; }

.u-z-index-80 {
  z-index: 80; }

.u-z-index-90 {
  z-index: 90; }

.u-z-index-100 {
  z-index: 100; }

/* -- Footer alterations -------------- */
.u-no-fredericks-logo .fredericksFoundation {
  display: none !important; }

/*--------------------------------------*\
    Sizing Utilities
\*--------------------------------------*/
.u-max-width-none {
  max-width: none !important; }

.u-max-width-50 {
  max-width: 50% !important; }

.u-max-width-100 {
  max-width: 100% !important; }

.u-height-auto {
  height: auto !important; }

.u-min-height-100vh {
  min-height: 100vh !important; }

.u-width-auto {
  width: auto !important; }

.u-width-100 {
  width: 100% !important; }

@media (max-width: 600px) {
  .u-max-height-100-on-mobile {
    max-height: 100px; } }

.u-max-width-400 {
  max-width: 400px; }

.u-max-width-420 {
  max-width: 420px; }

.u-max-width-540 {
  max-width: 540px; }

.u-max-width-800 {
  max-width: 800px; }

.u-height-100 {
  height: 100% !important; }

/*------------------------------------*\
    Icons Utilities
\*------------------------------------*/
.u-icon-angle-up:before {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-before-icon-angle-up:before {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-after-icon-angle-up:after {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-icon-angle-down:before {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-before-icon-angle-down:before {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-after-icon-angle-down:after {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-icon-angle-left:before {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-before-icon-angle-left:before {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-after-icon-angle-left:after {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-icon-angle-right:before {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-before-icon-angle-right:before {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-after-icon-angle-right:after {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-icon-arrow-down:before {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-before-icon-arrow-down:before {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-after-icon-arrow-down:after {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-icon-arrow-left:before {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-before-icon-arrow-left:before {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-after-icon-arrow-left:after {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-icon-arrow-right:before {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-before-icon-arrow-right:before {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-after-icon-arrow-right:after {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-icon-arrow-up:before {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-before-icon-arrow-up:before {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-after-icon-arrow-up:after {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-icon-atm:before {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-before-icon-atm:before {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-after-icon-atm:after {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-icon-attach:before {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-before-icon-attach:before {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-after-icon-attach:after {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-icon-attention:before {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-before-icon-attention:before {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-after-icon-attention:after {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-icon-bank-transfers:before {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-before-icon-bank-transfers:before {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-after-icon-bank-transfers:after {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-icon-bar-chart:before {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-before-icon-bar-chart:before {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-after-icon-bar-chart:after {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-icon-broken-card:before {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-before-icon-broken-card:before {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-after-icon-broken-card:after {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-icon-calendar:before {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-before-icon-calendar:before {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-after-icon-calendar:after {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-icon-cancel:before {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-before-icon-cancel:before {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-after-icon-cancel:after {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-icon-cancel-circled:before {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-before-icon-cancel-circled:before {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-after-icon-cancel-circled:after {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-icon-card-back:before {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-before-icon-card-back:before {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-after-icon-card-back:after {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-icon-cash-back:before {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-before-icon-cash-back:before {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-after-icon-cash-back:after {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-icon-chart-area:before {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-before-icon-chart-area:before {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-after-icon-chart-area:after {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-icon-chat:before {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-before-icon-chat:before {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-after-icon-chat:after {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-icon-check:before {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-before-icon-check:before {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-after-icon-check:after {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-icon-circular-arrows:before {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-before-icon-circular-arrows:before {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-after-icon-circular-arrows:after {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-icon-clock:before {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-before-icon-clock:before {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-after-icon-clock:after {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-icon-contactless:before {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-before-icon-contactless:before {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-after-icon-contactless:after {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-icon-conversation:before {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-before-icon-conversation:before {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-after-icon-conversation:after {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-icon-copy:before {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-before-icon-copy:before {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-after-icon-copy:after {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-icon-direct-debit:before {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-before-icon-direct-debit:before {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-after-icon-direct-debit:after {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-icon-double-angle-right:before {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-before-icon-double-angle-right:before {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-after-icon-double-angle-right:after {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-icon-download:before {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-before-icon-download:before {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-after-icon-download:after {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-icon-extra-accounts:before {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-before-icon-extra-accounts:before {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-after-icon-extra-accounts:after {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-icon-eye:before {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-before-icon-eye:before {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-after-icon-eye:after {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-icon-eye-off:before {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-before-icon-eye-off:before {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-after-icon-eye-off:after {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-icon-download:before {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-before-icon-download:before {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-after-icon-download:after {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-icon-download-envelope:before {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-before-icon-download-envelope:before {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-after-icon-download-envelope:after {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-icon-facebook:before {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-before-icon-facebook:before {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-after-icon-facebook:after {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-icon-faster-payments:before {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-before-icon-faster-payments:before {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-after-icon-faster-payments:after {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-icon-file-open:before {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-before-icon-file-open:before {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-after-icon-file-open:after {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-icon-flag:before {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-before-icon-flag:before {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-after-icon-flag:after {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-icon-gauge:before {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-before-icon-gauge:before {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-after-icon-gauge:after {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-icon-globe:before {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-before-icon-globe:before {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-after-icon-globe:after {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-icon-grid:before {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-before-icon-grid:before {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-after-icon-grid:after {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-icon-info-fill:before {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-before-icon-info-fill:before {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-after-icon-info-fill:after {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-icon-instagram:before {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-before-icon-instagram:before {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-after-icon-instagram:after {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-icon-line-chart:before {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-before-icon-line-chart:before {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-after-icon-line-chart:after {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-icon-linkedin:before {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-before-icon-linkedin:before {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-after-icon-linkedin:after {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-icon-loading-circle:before {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-before-icon-loading-circle:before {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-after-icon-loading-circle:after {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-icon-lock:before {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-before-icon-lock:before {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-after-icon-lock:after {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-icon-lock-open:before {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-before-icon-lock-open:before {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-after-icon-lock-open:after {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-icon-mail:before {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-before-icon-mail:before {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-after-icon-mail:after {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-icon-mastercard:before {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-before-icon-mastercard:before {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-after-icon-mastercard:after {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-icon-minus:before {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-before-icon-minus:before {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-after-icon-minus:after {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-icon-mobile:before {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-before-icon-mobile:before {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-after-icon-mobile:after {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-icon-money:before {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-before-icon-money:before {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-after-icon-money:after {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-icon-no-checks:before {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-before-icon-no-checks:before {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-after-icon-no-checks:after {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-icon-paper-plane:before {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-before-icon-paper-plane:before {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-after-icon-paper-plane:after {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-icon-paypoint:before {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-before-icon-paypoint:before {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-after-icon-paypoint:after {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-icon-pencil:before {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-before-icon-pencil:before {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-after-icon-pencil:after {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-icon-picture:before {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-before-icon-picture:before {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-after-icon-picture:after {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-icon-pie-chart:before {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-before-icon-pie-chart:before {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-after-icon-pie-chart:after {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-icon-postal:before {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-before-icon-postal:before {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-after-icon-postal:after {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-icon-plus:before {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-before-icon-plus:before {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-after-icon-plus:after {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-icon-resize:before {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-before-icon-resize:before {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-after-icon-resize:after {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-icon-search:before {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-before-icon-search:before {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-after-icon-search:after {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-icon-send:before {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-before-icon-send:before {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-after-icon-send:after {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-icon-share:before {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-before-icon-share:before {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-after-icon-share:after {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-icon-sliders:before {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-before-icon-sliders:before {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-after-icon-sliders:after {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-icon-small-arrow-up:before {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-before-icon-small-arrow-up:before {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-after-icon-small-arrow-up:after {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-icon-star:before {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-before-icon-star:before {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-after-icon-star:after {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-icon-statements:before {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-before-icon-statements:before {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-after-icon-statements:after {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-icon-swipe:before {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-before-icon-swipe:before {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-after-icon-swipe:after {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-icon-tools:before {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-before-icon-tools:before {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-after-icon-tools:after {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-icon-twitter:before {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-before-icon-twitter:before {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-after-icon-twitter:after {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-icon-u-logo-simplified:before {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-before-icon-u-logo-simplified:before {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-after-icon-u-logo-simplified:after {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-icon-upload:before {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-before-icon-upload:before {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-after-icon-upload:after {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-icon-user:before {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-before-icon-user:before {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-after-icon-user:after {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-icon-user-add:before {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-before-icon-user-add:before {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-after-icon-user-add:after {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-icon-users-group:before {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-before-icon-users-group:before {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-after-icon-users-group:after {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-icon-spanner:before {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-before-icon-spanner:before {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-after-icon-spanner:after {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-icon-youtube:before {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-before-icon-youtube:before {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

.u-after-icon-youtube:after {
  content: "";
  font-family: 'ffreesicons', sans-serif;
  font-weight: 500;
  font-style: normal;
  display: inline-block; }

/*------------------------------------*\
    Color utilities
\*------------------------------------*/
.u-color-1, .c-glossary__subtitle {
  color: #f2663b !important; }

.u-color-1-bg {
  background-color: #f2663b !important; }

.u-color-1-light {
  color: #fef8f7 !important; }

.u-color-1-light-bg {
  background-color: #fef8f7 !important; }

.u-color-1-mid {
  color: #fcded5 !important; }

.u-color-1-mid-bg {
  background-color: #fcded5 !important; }

.u-color-1-dark {
  color: #ea4310 !important; }

.u-color-1-dark-bg {
  background-color: #ea4310 !important; }

.u-color-2 {
  color: #6eb52b !important; }

.u-color-2-bg {
  background-color: #6eb52b !important; }

.u-color-2-light {
  color: #eff9e6 !important; }

.u-color-2-light-bg {
  background-color: #eff9e6 !important; }

.u-color-2-mid {
  color: #cfe4ba !important; }

.u-color-2-mid-bg {
  background-color: #cfe4ba !important; }

.u-color-2-dark {
  color: #558c21 !important; }

.u-color-2-dark-bg {
  background-color: #558c21 !important; }

.u-color-3 {
  color: #ffb20a !important; }

.u-color-3-bg {
  background-color: #ffb20a !important; }

.u-color-3-light {
  color: #FFF6E1 !important; }

.u-color-3-light-bg {
  background-color: #FFF6E1 !important; }

.u-color-3-dark {
  color: #d69300 !important; }

.u-color-3-dark-bg {
  background-color: #d69300 !important; }

.u-color-4 {
  color: #d31a35 !important; }

.u-color-4-bg {
  background-color: #d31a35 !important; }

.u-color-4-light {
  color: #FABCCC !important; }

.u-color-4-light-bg {
  background-color: #FABCCC !important; }

.u-color-4-dark {
  color: #a6142a !important; }

.u-color-4-dark-bg {
  background-color: #a6142a !important; }

.u-color-5 {
  color: #005EB0 !important; }

.u-color-5-bg {
  background-color: #005EB0 !important; }

.u-color-5-light {
  color: #E8F1F8 !important; }

.u-color-5-light-bg {
  background-color: #E8F1F8 !important; }

.u-color-5-dark {
  color: #00437d !important; }

.u-color-5-dark-bg {
  background-color: #00437d !important; }

.u-color-black {
  color: #000000 !important; }

.u-color-black-bg {
  background-color: #000000 !important; }

.u-color-white {
  color: #ffffff !important; }

.u-color-white-bg {
  background-color: #ffffff !important; }

.u-color-grey-xdark {
  color: #4d4d4d !important; }

.u-color-grey-xdark-bg {
  background-color: #4d4d4d !important; }

.u-color-grey-dark {
  color: #afafaf !important; }

.u-color-grey-dark-bg {
  background-color: #afafaf !important; }

.u-color-grey-mid {
  color: #d1d3d4 !important; }

.u-color-grey-mid-bg {
  background-color: #d1d3d4 !important; }

.u-color-grey-light {
  color: #ebebeb !important; }

.u-color-grey-light-bg {
  background-color: #ebebeb !important; }

.u-color-grey-xlight {
  color: #f1f1f1 !important; }

.u-color-grey-xlight-bg {
  background-color: #f1f1f1 !important; }

.u-color-grey-xxlight {
  color: #f6f6f6 !important; }

.u-color-grey-xxlight-bg {
  background-color: #f6f6f6 !important; }

.u-color-grey-xxxlight {
  color: #fbfcfc !important; }

.u-color-grey-xxxlight-bg {
  background-color: #fbfcfc !important; }

.u-color-grey-1 {
  color: #4d4d4d !important; }

.u-color-grey-1-bg {
  background-color: #4d4d4d !important; }

.u-color-grey-2 {
  color: #afafaf !important; }

.u-color-grey-2-bg {
  background-color: #afafaf !important; }

.u-color-grey-3 {
  color: #d1d3d4 !important; }

.u-color-grey-3-bg {
  background-color: #d1d3d4 !important; }

.u-color-grey-4 {
  color: #f1f1f1 !important; }

.u-color-grey-4-bg {
  background-color: #f1f1f1 !important; }

.u-color-grey-5 {
  color: #f6f6f6 !important; }

.u-color-grey-5-bg {
  background-color: #f6f6f6 !important; }

.u-color-grey-6 {
  color: #f8f8f8 !important; }

.u-color-grey-6-bg {
  background-color: #f8f8f8 !important; }

.u-color-grey-7 {
  color: #fbfcfc !important; }

.u-color-grey-7-bg {
  background-color: #fbfcfc !important; }

.u-color-grey-8 {
  color: #757575 !important; }

.u-color-grey-8-bg {
  background-color: #757575 !important; }

.u-color-grey-9 {
  color: #e5e5e5 !important; }

.u-color-grey-9-bg {
  background-color: #e5e5e5 !important; }

.u-highlight {
  color: #f2663b !important; }

.u-highlight-bg {
  background-color: #f2663b !important; }

.u-color-twitter {
  color: #0FA5F8 !important; }

.u-color-twitter-bg {
  background-color: #0FA5F8 !important; }

.u-color-facebook {
  color: #27579A !important; }

.u-color-facebook-bg {
  background-color: #27579A !important; }

.u-color-dot-dot-loans-1 {
  color: #ea9605 !important; }

.u-color-dot-dot-loans-1-bg {
  background-color: #ea9605 !important; }

.u-color-dot-dot-loans-2 {
  color: #feefd7 !important; }

.u-color-dot-dot-loans-2-bg {
  background-color: #feefd7 !important; }

.u-color-dot-dot-loans-3 {
  color: #ffe5bb !important; }

.u-color-dot-dot-loans-3-bg {
  background-color: #ffe5bb !important; }

.u-color-dot-dot-loans-4 {
  color: #ebcada !important; }

.u-color-dot-dot-loans-4-bg {
  background-color: #ebcada !important; }

.u-color-dot-dot-loans-5 {
  color: #e42386 !important; }

.u-color-dot-dot-loans-5-bg {
  background-color: #e42386 !important; }

.bg-none {
  background-color: transparent; }

/*------------------------------------*\
    Animation Styles
\*------------------------------------*/
/*----------- constant animations -------------*/
.u-animate-const-rotate {
  animation: rotate 1.5s linear infinite; }

@keyframes rotate {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

.u-animate-const-rotate-before:before {
  animation: rotate 1.5s linear infinite; }

@keyframes rotate {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

.u-animate-const-rotate-after:after {
  animation: rotate 1.5s linear infinite; }

@keyframes rotate {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

.u-animate-const-pulse {
  animation: pulse 0.8s ease-in-out infinite; }

@keyframes pulse {
  0% {
    transform: scale(1); }
  50% {
    transform: scale(1.2); }
  100% {
    transform: scale(1); } }

/*----------- triggered animations -------------*/
.u-animate-slide-holder {
  overflow: hidden; }

.u-animate-spin-holder {
  perspective: 0; }

.u-animate-slide-ltr {
  transition: transform 0.65s ease-in-out;
  transform: translateX(-100vw);
  transform: translateX(calc(-100% - 1px)); }
  .u-animate-slide-ltr.is-animated {
    transform: translateX(0); }

.u-animate-slide-rtl {
  transition: transform 0.65s ease-in-out;
  transform: translateX(100vw);
  transform: translateX(calc(100% + 1px)); }
  .u-animate-slide-rtl.is-animated {
    transform: translateX(0); }

.u-animate-fade-in {
  transition: opacity 0.5s ease-in-out;
  opacity: 0; }
  .u-animate-fade-in.is-animated {
    opacity: 1; }

.u-animate-spin {
  transition-property: transform;
  transition-duration: 1.35s;
  transition-timing-function: cubic-bezier(0.86, 0.28, 0.18, 2);
  transform: rotateY(90deg);
  perspective: 800px; }
  .u-animate-spin.is-animated {
    transform: rotateY(0deg); }

.u-animate-present-bottom {
  transition-property: transform;
  transition-duration: 0.55s;
  transition-timing-function: cubic-bezier(1, 0.02, 0.66, 0.97);
  transform: rotateX(-90deg);
  transform-origin: 100% 100%;
  perspective: 800px; }
  .u-animate-present-bottom.is-animated {
    transform: rotateY(0deg); }

.u-animate-highlight-flash {
  transition: background .85s ease-in-out;
  background-color: transparent; }
  .u-animate-highlight-flash.is-animated {
    background-color: #E8F1F8; }

.u-animate-blur {
  transition: filter .25s ease-in-out;
  filter: blur(0); }
  .u-animate-blur.is-animated {
    filter: blur(20px); }

/*------------------------------------*\
    IE specific css
\*------------------------------------*/
@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
  .u-color-white-bg-ie {
    background-color: #ffffff !important; } }

/*------------------------------------*\
    IE specific css
\*------------------------------------*/
@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
  .u-color-white-bg-ie {
    background-color: #ffffff; } }

/*doc
---
title: Animation
name: animation
category: Animation
---

Animation classes fall into two categories. <b>Constant animations</b> and <b>triggered animations</b>. As the name suggests, constant animations will animate constantly if a class is applied. For more complex animation we use triggered animations which have a starting state, then the class of <code>is-animated</code> is added to trigger the animation transition to its end state. This trigger could be added on load or when the element comes into the viewport. This system offers a lot of flexibility.

Note: <code>.c-sg-block</code> is purely to style the element for demonstration.

Constant example:

```html_example
<div class="c-sg-block u-animate-const-rotate"></div>
```

Triggered example:

```html_example
<div data-parent-classes="js-dont-format" class="c-sg-block u-animate-stamp-in js-animate"></div>
```

*/
/*doc
---
title: Triggered animation utility classes
name: triggered-animation-utility-classes
category: Animation
---

```html_example_table
<div data-parent-classes="js-dont-format" class="c-sg-block u-animate-slide-holder">
    <div class="c-sg-block u-animate-slide-ltr js-animate"></div>
</div>

<div data-parent-classes="js-dont-format" class="c-sg-block u-animate-slide-holder">
    <div class="c-sg-block u-animate-slide-rtl js-animate"></div>
</div>

<div data-parent-classes="js-dont-format" class="c-sg-block u-animate-spin-holder">
    <div class="c-sg-block u-animate-spin "></div>
</div>

<div data-parent-classes="js-dont-format" class="c-sg-block u-animate-fade-in js-animate"></div>

<div data-parent-classes="js-dont-format" class="c-sg-block u-animate-stamp-in js-animate"></div>

<div data-parent-classes="js-dont-format" class="c-sg-block u-animate-present-bottom js-animate"></div>

```
*/
/*------------------------------------*\
    Development helper buttons
\*------------------------------------*/
#__bs_notify__ {
  position: fixed;
  bottom: 0 !important;
  top: auto !important;
  height: 50px;
  border-radius: 0 !important; }

.smallRoundedLabel {
  border-radius: 22px;
  padding: 5px 6px;
  color: #ffffff;
  display: inline-block;
  line-height: 2.5; }

.greenbtn {
  color: #000;
  background-color: #DCEDCC;
  border: 1px solid #6EB52B;
  padding: 0 70px; }
  .greenbtn:hover {
    background-color: #c2e0a6;
    color: #000; }

.transparentbtn {
  color: #000;
  background-color: transparent; }
  .transparentbtn:hover {
    color: #000;
    background-color: transparent; }

.c-text-blob {
  background-color: #fcded5;
  -moz-border-radius: 24px;
  border-radius: 24px;
  padding: 0 5px;
  color: #000;
  text-decoration: none; }
  .c-text-blob:hover {
    color: #000;
    border: 1px solid #f2663b; }

.c-checkbox-radio {
  padding: 12px; }
  .c-checkbox-radio:nth-child(odd) {
    background: #f6f6f6;
    border-radius: 25px; }

.allowedHeight {
  min-height: 1px; }

.allowSticky {
  overflow: auto;
  padding-top: 10px;
  padding-bottom: 30px; }
