.c-payment-card {
    $class: c-payment-card;
    $padding: $space-xs;

    position: relative;
    height: 153px;
    width: 240px;
    margin: $space-m auto;
    border-radius: 6px;
    background-color: $color-1;
    border: 1px solid $color-1;
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.25);
    text-align: left;

    &__info {
        @include absolute-center('both');
        @include main-button-text;
        margin: 0;
        width: 100%;
        padding: $padding;
    }

        &__title {
            color: $white;
            margin-bottom: $space-xxs;
        }

        &__value {
            margin: 0;
            line-height: 1;
            margin-bottom: $space-s;
            @include font-monospace;
        }


    &__logo {
        @include u-logo-font;
        position: absolute;
        bottom: $padding;
        right: $padding;
        color: $white;
        font-size: $space-xl;
        line-height: 1;
    }

    &--inactive {
        background: $color-grey-2;
        border-color: $color-grey-2;
    }

    &--damaged {
        background: $color-3;
        border-color: $color-3;
    }

    &--inverse {
        background: $white;
        border-color: $color-1;

        .#{$class}__title {
            color: $color-grey-8;
        }

        .#{$class}__logo {
            color: $color-1;
        }
    }

    &--frozen {
        background: $color-5-light;
        border-color: $color-5-light;

        .#{$class}__title {
            color: $black;
        }

        .#{$class}__logo {
            color: $color-1;
        }
    }

    &--small {
        height: 48px;
        width: 74px;
        margin: 0;
        box-shadow: none;

        .#{$class}__logo {
            font-size: $space-m;
            bottom: 4px;
            right: 2px;
        }

        .#{$class}__info {
            display: none;
        }
    }

    @media (min-width: $m_bp) {
        width: 297px;
        height: 190px;
        box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.25);

        &__info {
            padding: $space-s;
        }

        &__logo {
            font-size: $space-xxxl;
        }

        &--small {
            height: 48px;
            width: 74px;
            box-shadow: none;

            .#{$class}__logo {
                font-size: $space-m;
            }
        }
    }
}

/*doc
---
title: Payment card
name: payment-card
category: Components
---

```html_example
<div class="c-payment-card">
    <span class="c-payment-card__logo">U</span>
    <dl class="c-payment-card__info">
        <dt class="c-payment-card__title">Name</dt>
        <dd class="c-payment-card__value">MR MORAY ABDIOU</dd>
        <dt class="c-payment-card__title">Exp. date</dt>
        <dd class="c-payment-card__value">11/22</dd>
    </dl>
</div>
```
<div class="c-sg-modifier-wrap js-modifier-wrap">
    <div>
        <button>c-payment-card--inactive</button>
        <button>c-payment-card--damaged</button>
        <button>c-payment-card--inverse</button>
        <button>c-payment-card--frozen</button>
    </div>
    <div>
        <button>c-payment-card--small</button>
    </div>
</div>
*/