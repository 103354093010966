.c-reveal-vertical {
    overflow: hidden;
    transition: height .5s ease-in-out;
    height: 0;

    &.is-revealed {
        height: auto;
    }

    &__content {
        overflow: auto;
    }
}