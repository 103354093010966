/*------------------------------------*\
    accordion block
\*------------------------------------*/
.c-accordion-block {
    $class: c-accordion-block;
    $padding: $space-s;
    $transition-speed: 300ms;

    &__header {
        position: relative;
        display: block;
        width: 100%;
        padding: $padding;
        padding-right: $space-xxl;
        margin-bottom: 1px;
        background: $black;
        color: $white;
        cursor: pointer;
        transition: color $transition-speed ease-in-out;

        &::after {
            content: $icon-angle-down;
            @include ffrees-icons-font;
            @include absolute-center('y');
            right: $padding;
            transition: transform $transition-speed ease-in-out, color $transition-speed ease-in-out;
        }
    }

    &__title {
        @include section-title-secondary;
        margin: 0;
    }

    &__meta {
        margin: 0;
    }

    &__content {
        background-color: $white;
        height: 0;
        overflow: hidden;
        transition: height $transition-speed ease-in-out;
    }

    &__content-inner {
        padding: $padding;
        border: 1px solid $color-grey-light;
    }

    &.is-active {
        .#{$class}__header {
            &::after {
                transform: translateY(-50%) rotate(180deg);
            }
        }
    }

    &.is-disabled {
        .#{$class}__header {
            cursor: not-allowed;
        }
    }

    &.is-disabled.is-active {
        .#{$class}__header {
            cursor: auto;
        }
    }

    &--padding-small {
        .#{$class}__header {
            padding: $padding;
            padding-right: $space-xxl;

            &::after {
                right: $padding;
            }
        }
    }

    &--outline-center {
        .#{$class}__header {
            background-color: $white;
            color: $black;
            box-shadow: inset 0 0 0 4px $black;
            text-align: center;
        }
    }

    &--raised {
        @include outer-shadow-tight();
        margin-bottom: $padding;
        position: relative;
        z-index: $z-base;
        border-radius: $space-xs;
        border: 1px solid $color-grey-9;
        background: $white;

        .#{$class}__header {
            padding-right: $padding;
            margin-bottom: 0;
            color: inherit;
            background: none;
            text-align: left;
            overflow: hidden;

            &:after {
                display: none
            }
        }

        .#{$class}__title {
            @include list-item-title();
            display: inline-block;
            position: relative;
            transform: translateX(-50%);
            left: 50%;
            @include list-item-title();
            margin-bottom: 0;
            text-transform: none;
            transition: all $transition-speed ease;
        }

        .#{$class}__subtitle {
            position: relative;
            transform: translateX(-50%);
            left: 50%;
            margin: 0;
            text-align: center;
        }

        .#{$class}__meta {
            max-height: 0;
            opacity: 0;
            overflow: hidden;
            transition: all $transition-speed ease-in-out;
        }

        .#{$class}__content {
            background: none;
        }

        .#{$class}__content-inner {
            border: 0;
            padding-top: 0;
        }

        &.is-active {
            z-index: $z-tooltip;

            .#{$class}__header {
                text-align: left;
            }

                .#{$class}__title {
                    @include section-title-text();
                    margin-bottom: 0;
                    color: $color-1;
                    left: 0;
                    transform: translateX(0);
                }

                .#{$class}__meta {
                    max-height: $space-l;
                    opacity: 1;
                }
        }

        &.is-disabled {
            color: $color-grey-8;
        }

        &.is-not-allowed {
            box-shadow: none;
            color: $black;
            background: $color-grey-6;
        }
    }

    @media (min-width: $xs_bp) {
        &__header {
            padding: $space-l;
            padding-right: $space-xxxxl;

            &::after {
                right: $space-l;
            }
        }

        &__content-inner {
            padding: $space-l;
        }

        &--raised {
            .#{$class}__header {
                padding-right: $space-l;
            }
        }
    }

    @media (min-width: $m_bp) {
        &__indent {
            padding-left: $space-xxxl;

            &--left-right {
                padding-right: $space-xxxl;
            }
        }

        &--show-accordion-content-on-desktop {
            .#{$class}__header {
                display: none;
            }

            .#{$class}__content {
                height: auto;

                .#{$class}__content-inner {
                    padding: 0;
                    border: 0;
                }
            }
        }

        &--raised {
            .#{$class}__header {
                padding-top: $space-xl;
            }

            .#{$class}__header,
            .#{$class}__content-inner {
                padding-left: $space-xxl;
                padding-right: $space-xxl;
            }
        }

        &--padding-0 {
            .#{$class}__content-inner {
                padding-left: 0;
                padding-right: 0;
            }
        }

        &--padding-90 {
            .#{$class}__content-inner {
                padding-left: 90px;
                padding-right: 90px;
            }
        }
    }
}

/*doc
---
title: accordion block
name: accordion-block
category: Components
---
Please note the state modifier will not perform all the functionality as a click event is needed to run the JS slideToggle function.

```html_example
<section class="c-accordion-block js-accordion-block">
    <header class="c-accordion-block__header js-accordion-header">
        <h2 class="c-accordion-block__title">
            January 2018
        </h2>
        <p class="c-accordion-block__meta">Step 1 of 2</p>
    </header>
    <div class="c-accordion-block__content">
        <div class="c-accordion-block__content-inner">
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nostrum consequuntur vero quaerat veritatis dolore, similique facilis ea, tempora, eaque reiciendis, quia nisi. Ut veritatis dicta expedita, corporis hic libero accusantium.
        </div>
    </div>
</section>

<section class="c-accordion-block js-accordion-block">
    <header class="c-accordion-block__header js-accordion-header">
        <h2 class="c-accordion-block__title">
            January 2018
        </h2>
    </header>
    <div class="c-accordion-block__content">
        <div class="c-accordion-block__content-inner">
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nostrum consequuntur vero quaerat veritatis dolore, similique facilis ea, tempora, eaque reiciendis, quia nisi. Ut veritatis dicta expedita, corporis hic libero accusantium.
        </div>
    </div>
</section>

```

<div class="c-sg-modifier-wrap js-modifier-wrap">
    <div>
        <button>is-active</button>
    </div>
    <div>
        <button>c-accordion-block--outline-center</button>
    </div>
    <div>
        <button>c-accordion-block--padding-small</button>
    </div>
    <div>
        <button>c-accordion-block--padding-0</button>
        <button>c-accordion-block--padding-90</button>
    </div>
    <div>
        <button>c-accordion-block--show-accordion-content-on-desktop</button>
    </div>
    <div>
        <button>c-accordion-block--raised</button>
    </div>
    <div>
        <button>is-disabled</button>
    </div>
</div>
*/
