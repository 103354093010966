.c-progress-circle {
    $class: c-progress-circle;

    position: relative;
    height: $space-xl;
    width: $space-xl;
    @include footnote-text;
    @include animate-prop(transform);

    &__meter {
        transform: rotate(-90deg);
    }

    &__circle {
        fill: $color-grey-4;
    }

    &__bar {
        stroke: $color-grey-8;
        stroke-width: 1.5;
        stroke-linecap: round;
        fill: none;
        transition: all 250ms;
    }

    &__text {
        @include absolute-center('both');
    }

    &--warning {
        .#{$class}__circle {
            fill: $color-1-light;
        }
        .#{$class}__bar {
            stroke: $color-1;
        }
    }

    &--invalid {
        .#{$class}__circle {
            fill: $color-4-light;
        }
        .#{$class}__bar {
            stroke: $color-invalid;
        }
    }

    &--invalid-b {
        .#{$class}__circle {
            fill: none;
        }
        .#{$class}__bar {
            stroke: none;
        }
    }
}