.c-checkbox-radio {
    $class: c-checkbox-radio;
    $padding-x-text-side: $space-m;
    $padding-x-checkbox-side: $space-l;
    $padding-y: $space-xs;

    @include clearfix;
    margin-bottom: $space-l;

    &__label {
        position: relative;
        display: flex;
        align-items: center;
        cursor: pointer;
        margin: 0;
        font-weight: 100;
    }

    &__label-indicator {
        position: relative;
        display: flex;
        width: $space-xl;
        height: $space-xl;
        border: 1px solid $color-grey-2;
        border-radius: 100%;
        margin-right: $space-xs;
        background: $white;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;

        &:before {
            content: $icon-check;
            @include ffrees-icons-font;
            font-size: 20px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            display: none;
        }
    }

        &__label-subtext {
            display: block;
            @include footnote-text();
            color: $color-1;
        }

    &__input {
        @include visually-hidden();

        &:checked {
            & + .#{$class}__label {
                .#{$class}__label-indicator {
                    border-color: $color-2;

                    &:before {
                        display: block;
                    }
                }
            }
        }
    }

    &--checkbox-right {
        margin-right: 0;

        .#{$class}__label-indicator {
            order: 2;
            margin-right: 0;
            margin-left: auto;
        }

        .#{$class}__label-text {
            order: 1;
            margin-right: $padding-x-text-side;
        }
    }

    &--preferences:nth-child(1n) {
        .#{$class}__label {
            border-radius: $padding-x-checkbox-side;
        }

        .#{$class}__input:focus {
            & + .#{$class}__label {
                //background: $color-grey-4;
            }
        }
    }

    &--border-bottom {
        .#{$class}__label {

            &::after {
                content: '';
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 7px;
                background: $color-grey-6;
                border-radius: 7px;
            }
        }

        .#{$class}__input {
            &:focus,
            &:checked {
                & + .#{$class}__label {
                    border-radius: $padding-x-checkbox-side;

                    &::after {
                        display: none;
                    }
                }
            }
        }
    }

    &--margin-bottom-s {
        margin-bottom: $space-s;
    }

    &--margin-bottom-0 {
        margin-bottom: 0;
    }

    &.is-loading {
        .#{$class}__label {
            cursor: default;
        }

        // extra specificty to override &__input:checked + .#{$class}__label .#{$class}__label-indicator selector above
        .#{$class}__label-indicator.#{$class}__label-indicator.#{$class}__label-indicator {
            border-color: transparent;
            background: transparent;

            &:before {
                display: none;
            }

            &:after {
                $loaderScale: 1; // to avoid subpixel positioning issues

                content: $icon-loading-circle;

                display: block;
                width: auto;
                height: auto;

                @include ffrees-icons-font;
                color: $color-1;
                text-align: center;

                @keyframes rotate {
                    0% {
                        transform: rotate(0deg) scale($loaderScale);
                    }
                    100% {
                        transform: rotate(360deg) scale($loaderScale);
                    }
                }
                animation: rotate 0.8s linear infinite;
            }
        }
    }

    @media (min-width: $m_bp) {
        &__label-indicator {
            margin-right: $padding-x-checkbox-side;
        }

        &--border-bottom,
        &--grey-bg {
            .#{$class}__label {
                padding-left: $padding-x-checkbox-side;
            }
        }

        &--checkbox-right.#{$class}--border-bottom,
        &--checkbox-right.#{$class}--grey-bg {
            .#{$class}__label {
                padding-left: $padding-x-text-side;
                padding-right: $padding-x-checkbox-side;
            }
        }
    }
}

.c-checkbox-radio {

    &:nth-child(odd) {
        background: #f6f6f6;
        border-radius: 25px;
    }
}

/*doc
---
title: checkbox radio
name: input-checkbox-radio
category: Inputs
---

Note <code>c-checkbox-radio__label-subtext</code> is optional, remove this element if it is not needed.

```html_example
<div class="c-checkbox-radio">
    <input class="c-checkbox-radio__input showManualAddress" type="checkbox" id="manuallyAddAddress">
    <label class="c-checkbox-radio__label" for="manuallyAddAddress">
        <div class="c-checkbox-radio__label-indicator"></div>
        <div class="c-checkbox-radio__label-text">Manually Enter Address
            <div class="c-checkbox-radio__label-subtext">(opted in at 14:24 on 24 Feb 2018)</div>
        </div>
    </label>
</div>
```
<div class="c-sg-modifier-wrap js-modifier-wrap">
    <div>
        <button>is-loading</button>
    </div>
    <div>
        <button>c-checkbox-radio--checkbox-right</button>
    </div>
    <div>
        <button>c-checkbox-radio--grey-bg</button>
        <button>c-checkbox-radio--border-bottom</button>
    </div>
</div>

Common layouts:

```html_example_table
<div class="c-checkbox-radio">
    <input class="c-checkbox-radio__input showManualAddress" type="checkbox" id="manuallyAddAddress2">
    <label class="c-checkbox-radio__label" for="manuallyAddAddress2">
        <div class="c-checkbox-radio__label-indicator"></div>
        <div class="c-checkbox-radio__label-text">Manually Enter Address
            <div class="c-checkbox-radio__label-subtext">(opted in at 14:24 on 24 Feb 2018)</div>
        </div>
    </label>
</div>

<div class="c-checkbox-radio c-checkbox-radio--grey-bg">
    <input class="c-checkbox-radio__input showManualAddress" type="checkbox" id="manuallyAddAddress3">
    <label class="c-checkbox-radio__label" for="manuallyAddAddress3">
        <div class="c-checkbox-radio__label-indicator"></div>
        <div class="c-checkbox-radio__label-text">Manually Enter Address
            <div class="c-checkbox-radio__label-subtext">(opted in at 14:24 on 24 Feb 2018)</div>
        </div>
    </label>
</div>

<div class="c-checkbox-radio c-checkbox-radio--checkbox-right c-checkbox-radio--grey-bg">
    <input class="c-checkbox-radio__input showManualAddress" type="checkbox" id="manuallyAddAddress4">
    <label class="c-checkbox-radio__label" for="manuallyAddAddress4">
        <div class="c-checkbox-radio__label-indicator"></div>
        <div class="c-checkbox-radio__label-text">Manually Enter Address
            <div class="c-checkbox-radio__label-subtext">(opted in at 14:24 on 24 Feb 2018)</div>
        </div>
    </label>
</div>

<div class="c-checkbox-radio c-checkbox-radio--checkbox-right c-checkbox-radio--border-bottom">
    <input class="c-checkbox-radio__input showManualAddress" type="checkbox" id="manuallyAddAddress5">
    <label class="c-checkbox-radio__label" for="manuallyAddAddress5">
        <div class="c-checkbox-radio__label-indicator"></div>
        <div class="c-checkbox-radio__label-text">Manually Enter Address
            <div class="c-checkbox-radio__label-subtext">(opted in at 14:24 on 24 Feb 2018)</div>
        </div>
    </label>
</div>
```
<div class="c-sg-modifier-wrap js-modifier-wrap">
    <div>
        <button>is-loading</button>
    </div>
    <div>
        <button>c-checkbox-radio--checkbox-right</button>
    </div>
    <div>
        <button>c-checkbox-radio--grey-bg</button>
        <button>c-checkbox-radio--border-bottom</button>
    </div>
    <div>
        <button>c-checkbox-radio--margin-bottom-s</button>
    </div>
    <div>
        <button>c-checkbox-radio--margin-bottom-0</button>
    </div>
</div>
*/