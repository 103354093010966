/*------------------------------------*\ 
    toggle buttons    
\*------------------------------------*/
.c-toggle-buttons {
    $class: c-toggle-buttons;

    padding: 5px 3px;
    border: 2px solid $color-grey-mid;
    background: $color-grey-xlight;
    display: flex;
    margin-bottom: $space-l;

    &__button {
        @include button-styles;
        @include font-heavy;
        @include font-size(10px);
        flex: 1;
        padding: 5px;
        color: $black;
        background: transparent;
        margin: 0;
        height: auto;
        max-width: none;
        margin: 0 2px;

        &.is-active, &:hover {
            color: white;
            background: $color-1;            
        }            
    }

    @media (min-width:600px) {
        &__button { 
            @include font-size(16px, 1.5);
            padding: $space-xxs $space-m;
        }
    }

}

/*doc
---
title: Toggle Buttons
name: toggle-buttons
category: Buttons
---

```html_example
<div class="c-toggle-buttons js-toggle-buttons">
    <button type="button" class="c-toggle-buttons__button">Money in</button>
    <button type="button" class="c-toggle-buttons__button">Money out</button>
    <button type="button" class="c-toggle-buttons__button">All</button>
</div>

```

*/
