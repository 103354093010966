.c-message {
    $class: 'c-message';
    $dismiss-btn-size: 44px;

    transition: height .5s ease-in-out, max-height .5s ease-in-out;
    padding: 0;
    border-bottom: 1px solid $white;
    @include font-medium();

    &__body {
        @include content-width();
        padding: $space-xs;
        color: $white;
        position: relative;
        will-change: height;
    }

        &__copy {
            &:last-child {
                margin-bottom: 0;
            }

            &--text-align-center {
                text-align: center
            }
        }

            &__link {
                color: $white;
                text-decoration: underline;

                &:hover,
                &:focus,
                &:active {
                    color: $white-active;
                }
            }

    &__dismiss-btn {
        @include button-styles;
        display: none;
        width: $dismiss-btn-size;
        height: $dismiss-btn-size;
        padding: 0;
        margin: 0;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        color: $white;
        background-color: transparent;
        line-height: 1;

        &:after {
            @include ffrees-icons-font;
            content: $icon-cancel;
            font-weight: 900;
            font-size: 25px;
        }

        &:hover,
        &:focus,
        &:active {
            color: $color-1;
        }
    }

    &--with-dismiss-btn {
        .#{$class}__body {
            padding-left: $dismiss-btn-size + $space-l;
        }

        .#{$class}__dismiss-btn {
            display: block;
        }
    }

    &--error {
        background-color: $color-4;

        .#{$class}__dismiss-btn {
            &:hover,
            &:focus,
            &:active {
                color: $color-4-light;
            }
        }
    }

    &--info {
        background-color: $color-5;

        .#{$class}__dismiss-btn {
            &:hover,
            &:focus,
            &:active {
                color: $color-5-light;
            }
        }
    }

    &--info-light {
        background-color: $color-5-light;
        color: $black;
        border: 1px solid $color-5;

        .#{$class}__body {
            color: $black;
        }

            .#{$class}__link {
                color: $black;
            }

            .#{$class}__dismiss-btn {
                color: $black;

                &:hover,
                &:focus,
                &:active {
                    color: $black;
                }
            }
    }

    &--margin-bottom-xs {
        margin-bottom: $space-xs;
    }

    &--padding-left-right-l {
        padding: 0 $space-l;
    }

    &--no-border {
        border-bottom: 0;
    }

    // TODO RFC - modifier containing unrefactored messages that need removing from back end of site
    &--error-container {
        > div {
            padding: $space-l;
            text-align: center;
            color: $white;
            position: relative;
            will-change: height;

            a {
                color: currentColor;
            }

            p:last-child {
                margin-bottom: 0;
            }

            .flash-highlight {
                color: black;
            }
        }

        .successMessage {
            background-color: $color-2;
        }

        .errorMessage {
            background-color: $color-4;
        }

        .warningMessage {
            background-color: $color-3;
        }

        .infoMessage {
            background-color: $color-5;
        }
    }

    @media (min-width: $m_bp) {
        $body-padding: $space-s;

        &__body {
            padding: $body-padding;
        }

        &--with-dismiss-btn {
            .#{$class}__body {
                padding-left: $dismiss-btn-size + $body-padding;
            }
        }
    }
}

/*doc
---
title: Message
name: message
category: Components
---

```html_example
    <div class="c-message c-message--info js-dismissible-message">
        <div class="c-message__body">
            <button class="c-message__dismiss-btn js-message-dismiss-btn"></button>
            <p class="c-message__copy">You have an outstanding account management fee of <strong>£10</strong>. we'll take this amount from your balance when you next pay in to your account. </p>
        </div>
    </div>
```
<div class="c-sg-modifier-wrap js-modifier-wrap">
    <div>
        <button>c-message--with-dismiss-btn</button>
    </div>
    <div>
        <button>c-message--error</button>
        <button>c-message--info</button>
        <button>c-message--info-light</button>
    </div>
    <div>
        <button>c-message--margin-bottom-xs</button>
    </div>
    <div>
        <button>c-message--padding-left-right-l</button>
    </div>
    <div>
        <button>c-message--no-border</button>
    </div>
    <div>
        <button data-target="c-message__copy">c-message__copy--text-align-center</button>
    </div>
</div>

*/

/*doc
---
title: Message (Error Container)
name: message-error-container
category: Components
---
These messages are not fully refactored - they need to be reworked or removed from back end

```html_example_table
    <div class="c-message c-message--error-container"><div class="successMessage">Success Message</div></div>

    <div class="c-message c-message--error-container"><div class="errorMessage">Error Message</div></div>

    <div class="c-message c-message--error-container"><div class="infoMessage">Info Message</div></div>

    <div class="c-message c-message--error-container"><div class="warningMessage">Warning Message</div></div>
```

*/
