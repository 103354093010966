/*------------------------------------*\
    buttons
\*------------------------------------*/
@mixin base-button-styles {
    @include button-styles;
    @include main-button-text;

    &[class*="u-before-icon"] {
        &:before {
            margin-right: $space-xxs;
        }
    }

    &[class*="u-after-icon"] {
        &:after {
            margin-left: $space-xxs;
        }
    }

    &--full-width {
        width: 100%;
        max-width: none;
        display: block;
    }

    &--icon-down {
        &:after {
            @include ffrees-icons-font;
            content: $icon-angle-down;
            display: inline-block;
            margin-left: $space-xxs;
        }
    }

    &--icon-next {
        &:after {
            @include ffrees-icons-font;
            content: $icon-arrow-down;
            display: inline-block;
            margin-left: $space-xxs;
        }
    }

    &--float-right {
        float:right;
        margin-right: 0;
    }

    &--small-header {
        padding: $space-xxs $space-s;
        @include font-size(14px, 1.5);
    }

    &--margin-right-s {
        margin-right: $space-s;
    }

    &--margin-bottom-s {
        margin-bottom: $space-s;
    }

    &--margin-0 {
        margin: 0;
    }

    &--margin-bottom-0 {
        margin-bottom: 0;
    }

    &--more-padding {
        padding: $space-m;
    }

    &--nowrap {
        white-space: nowrap;
    }

    &--text-align-left {
        text-align: left;
    }

    &--icon-only {
        &[class*="u-before-icon"] {
            &:before {
                margin-right: 0;
            }
        }
        &[class*="u-after-icon"] {
            &:after {
                margin-left: 0;
            }
        }
    }

    &--plus-minus-icon {
        $padding: $space-m;
        position: relative;

        &:before,
        &:after {
            position: absolute;
            content: '';
            background-color: currentColor;
            display: block;
        }

        &:before {
            width: 15px;
            height: 3px;
            top: calc(50% - 1px);
            right: $padding;
        }

        &:after {
            width: 3px;
            height: 15px;
            top: calc(50% - 7px);
            right: $padding + 6px;
            transition: transform 0.3s ease-in-out;
        }

        &.is-active {
            &:after {
                transform: rotate(90deg);
            }
        }
    }

    &--large {
        @include subtitle-text(false);
        padding: $space-s $space-xxxl;
    }

    &--raised {
        @include outer-shadow-tight;
    }

    &.is-disabled {
        opacity: .4;
        cursor: not-allowed;
    }

    @media (max-width: 540px) {
        &--mobile-full-width {
            width: 100%;
            max-width: none;
            display: block;
        }
    }

    @media (min-width: 540px) {
        &--margin-bottom-0-xs {
            margin-bottom: 0;
        }
    }

    @media (min-width: $s_bp) {
        &--more-padding {
            padding: $space-m $space-xxl;
        }
    }

    @media (min-width: $m_bp) {
        &--icon-next {
            &:after {
                content: $icon-arrow-right;
            }
        }
    }
}

@mixin button-colors {
    color: $white;
    background: $color-1;

    &:hover,
    &:focus {
        color: $white;
        background: $color-1-dark;
    }

    &--1-light {
        color: $color-1;
        background-color: $white;

        &:hover,
        &:focus {
            color: $color-1;
            background-color: $color-1-light;
        }
    }

    &--2 {
        color: $white;
        background: $color-2;

        &:hover,
        &:focus {
            color: $white;
            background: $color-2-dark;
        }
    }

    &--3 {
        color: $white;
        background: $color-3;

        &:hover,
        &:focus {
            color: $white;
            background: $color-3-dark;
        }
    }

    &--4 {
        color: $white;
        background: $color-4;

        &:hover,
        &:focus {
            color: $white;
            background: $color-4-dark;
        }
    }

    &--5 {
        color: $white;
        background: $color-5;

        &:hover,
        &:focus {
            color: $white;
            background: $color-5-dark;
        }
    }

    &--white {
        color: $black;
        background: $white;

        &:hover,
        &:focus {
            color: $black;
            background: $white-active;
        }
    }

    &--black {
        color: $white;
        background: $black;

        &:hover,
        &:focus {
            color: $white;
            background: $black-active;
        }
    }
}

.c-btn {
    @include base-button-styles;
    @include button-colors;
}

.c-btn-outline {
    @include base-button-styles;
    color: $color-1;
    background: none;
    box-shadow: inset 0 0 0 4px currentColor;

    &:hover,
    &:focus {
        color: $color-1;
        background: rgba($color-1, .3);
    }

    &--2 {
        color: $color-2;
        box-shadow: inset 0 0 0 4px currentColor;

        &:hover,
        &:focus {
            color: $color-2;
            background: rgba($color-2, .3);
        }
    }

    &--3 {
        color: $color-3;
        box-shadow: inset 0 0 0 4px currentColor;

        &:hover,
        &:focus {
            color: $color-3;
            background: rgba($color-3, .3);
        }
    }

    &--4 {
        color: $color-4;
        box-shadow: inset 0 0 0 4px currentColor;

        &:hover,
        &:focus {
            color: $color-4;
            background: rgba($color-4, .3);
        }
    }

    &--5 {
        color: $color-5;
        box-shadow: inset 0 0 0 4px currentColor;

        &:hover,
        &:focus {
            color: $color-5;
            background: rgba($color-5, .3);
        }
    }

    &--black {
        color: $black;
        box-shadow: inset 0 0 0 4px currentColor;

        &:hover,
        &:focus {
            color: $black;
            background: rgba($black, .3);
        }
    }

    &--white {
        color: $white;
        box-shadow: inset 0 0 0 4px currentColor;

        &:hover,
        &:focus {
            color: $white;
            background: rgba($white, .3);
        }
    }
}

.c-btn-small-square {
    @include base-button-styles;
    padding: 0;
    color: $color-1;
    background: none;

    &:before {
        width: 44px;
        height: 44px;
        margin: 0 auto;
        font-size: 44px;
        line-height: 44px;
        display: block !important;
        color: $white;
        background-color: $color-1;
        box-shadow: 2px 1px 4px transparentize($black, .8);
    }

    &:hover,
    &:focus {
        color: $color-1-dark;
        background: none;

        &:before {
            background-color: $color-1-dark;
        }
    }

    &--2 {
        color: $color-2;

        &:before {
            color: $white;
            background-color: $color-2;
        }

        &:hover,
        &:focus {
            &:before {
                background: $color-2-dark;
            }
        }
    }

    &--3 {
        color: $color-3;

        &:before {
            color: $white;
            background-color: $color-3;
        }

        &:hover,
        &:focus {
            color: $color-3-dark;

            &:before {
                background: $color-3-dark;
            }
        }
    }

    &--4 {
        color: $color-4;

        &:before {
            color: $white;
            background-color: $color-4;
        }

        &:hover,
        &:focus {
            color: $color-4-dark;

            &:before {
                background: $color-4-dark;
            }
        }
    }

    &--5 {
        color: $color-5;

        &:before {
            color: $white;
            background-color: $color-5;
        }

        &:hover,
        &:focus {
            color: $color-5-dark;

            &:before {
                background: $color-5-dark;
            }
        }
    }

    &--white {
        color: $white;

        &:before {
            color: $black;
            background-color: $white;
        }

        &:hover,
        &:focus {
            color: $white;

            &:before {
                background: $color-1;
            }
        }
    }

    &--black {
        color: $black;

        &:before {
            color: $white;
            background-color: $black;
        }

        &:hover,
        &:focus {
            color: $color-1;

            &:before {
                background: $color-1;
            }
        }
    }

    &--position-fixed-right-top {
        margin: 0;
        position: fixed;
        top: $space-l;
        right: $space-xs;
    }

    &--position-fixed-right-bottom {
        margin: 0;
        position: fixed;
        right: $space-xs;
        bottom: $space-l;
    }

    @media (min-width: $m_bp) {
        right: $space-l;
    }
}

.c-btn-circle {
    $class: c-btn-circle;

    @include base-button-styles;
    @include button-colors;
    @include aspect-ratio(1, 1);
    @include list-item-title;
    width: 70px;
    padding: 0;
    border-radius: 50%;
    background: $color-1;
    color: $white;
    text-transform: none;

    &__content {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }

    &--raised {
        box-shadow: 0px 2px 4px 1px rgba(0, 0, 0, 0.25);
    }

    @media (min-width: $m_bp) {
        width: 90px;
    }
}

.c-btn-small-round {
    $class: c-btn-small-round;
    $button-width: 44px;

    @include base-button-styles;
    @include outer-shadow;
    @include aspect-ratio(1, 1);
    width: $button-width;
    min-width: $button-width;
    background-color: $white;
    border-radius: 50%;
    padding: 0;

    &:hover,
    &:focus {
        background-color: $color-1-light;
    }

    &__content {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        color: $black;
        text-transform: none;
        @include main-body-text;

        &:before,
        &:after {
            @include main-body-text;
        }
    }

    &--large {
        $button-width: 54px;

        padding: 0;
        width: $button-width;
        min-width: $button-width;
    }
}

.c-btn-block {
    $class: c-btn-block;

    @include base-button-styles;
    @include button-block-height;
    padding-top: 0;
    padding-bottom: 0;
    color: $black;
    background-color: $white;
    @include outer-shadow;
    @include font-medium;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border: 1px solid $color-grey-9;
    border-radius: $space-s;
    overflow: hidden;

    &:hover,
    &:focus,
    &:active {
        color: $black;
        background-color: $color-1-light;
        border-color: $color-1;
        @include outer-shadow-tight($color-1-dark);
    }

    &[class*="u-before-icon"] {
        &:before {
            margin-right: $space-xs;
        }
    }

    &[class*="u-after-icon"] {
        &:after {
            margin-left: $space-xs;
        }
    }

    &__image {
        margin-right: $space-s;
        width: 80px;
    }

    &__content {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

        &__subtext {
            display: none;
            color: $color-1;
            @include font-medium;
            text-transform: none;
            @include main-body-text;
        }

    &--with-subtext {
        .#{$class}__subtext {
            display: block;
        }
    }

    &--full-width {
        display: flex;
    }

    &--justify-content-flex-start {
        justify-content: flex-start;
    }

    &--no-fixed-height {
        height: auto;
        padding-top: $space-xs;
        padding-bottom: $space-xs;
    }

    &--dot-dot-loans {
        background-color: $color-dot-dot-loans-2;
        border: 2px solid $color-dot-dot-loans-1;
        @include outer-shadow-tight($color-dot-dot-loans-1);
        animation: fadeInButtonShadow 200ms;

        &:hover,
        &:focus,
        &:active {
            color: $black;
            background-color: $color-dot-dot-loans-3;
            border-color: $color-dot-dot-loans-1;
            @include outer-shadow-tight($color-dot-dot-loans-1, inset);
            animation: fadeOutButtonShadow 200ms;
        }
    }

    @media (min-width: $m_bp) {
        &__image {
            margin-right: $space-m;
            width: 100px;
        }

        &--no-fixed-height {
            padding: $space-m;
        }

        &.is-selected-desktop {
            background-color: $color-1-light;
            border-color: $color-1;
            @include outer-shadow-tight($color-1-dark);
        }
    }
}

.c-btn-tertiary {
    @include base-button-styles;
    color: $black;
    text-transform: none;
    font-size: $main-body-font-size-mobile;
    @include underline($black, 2px);

    &::after {
        left: $button-padding-left-right;
        right: $button-padding-left-right;
        bottom: $button-padding-top-bottom + 4px;
        transition: transform .2s ease-in-out;
    }

    &:hover,
    &:focus,
    &:active {
        &::after {
            left: $button-padding-left-right;
            right: $button-padding-left-right;
            transform: translateY(4px);
        }
    }

    &:hover,
    &:focus {
        color: $black;
    }

    @media (min-width: $m_bp) {
        font-size: $main-body-font-size-desktop;
        padding: 0;

        &::after {
            left: 0;
            right: 0;
            bottom: 7px;
        }

        &:hover,
        &:focus,
        &:active {
            &::after {
                left: 0;
                right: 0;
            }
        }
    }
}

@keyframes fadeInButtonShadow {
    0% { @include outer-shadow-tight($color-1-dark, inset); }
    50% { box-shadow: none; }
    100% { @include outer-shadow-tight($color-1-dark); }
}
@keyframes fadeOutButtonShadow {
    0% { @include outer-shadow-tight($color-1-dark); }
    50% { box-shadow: none; }
    100% { @include outer-shadow-tight($color-1-dark, inset); }
}
@keyframes fadeInButtonShadowValid {
    0% { @include outer-shadow-tight($color-2-dark, inset); }
    50% { box-shadow: none; }
    100% { @include outer-shadow-tight($color-2-dark); }
}
@keyframes fadeOutButtonShadowValid {
    0% { @include outer-shadow-tight($color-2-dark); }
    50% { box-shadow: none; }
    100% { @include outer-shadow-tight($color-2-dark, inset); }
}
@keyframes fadeInButtonShadowDeselected {
    0% { @include outer-shadow-tight($black, inset); }
    50% { box-shadow: none; }
    100% { @include outer-shadow-tight; }
}
@keyframes fadeOutButtonShadowDeselected {
    0% { @include outer-shadow-tight; }
    50% { box-shadow: none; }
    100% { @include outer-shadow-tight($black, inset); }
}

@mixin rounded-button-styles {
    @include base-button-styles;
    display: inline-block;
    padding-left: $space-l;
    padding-right: $space-l;
    border-radius: $space-l;
    border: 1px solid $color-1;
    background-color: $color-1-light;
    @include font-medium;
    color: $black;
    @include outer-shadow-tight($color-1-dark);
    animation: fadeInButtonShadow 200ms;

    &:hover,
    &:focus,
    &:active {
        color: $black;
        @include outer-shadow-tight($color-1-dark, inset);
        animation: fadeOutButtonShadow 200ms;
    }

    &.is-valid {
        border-color: $color-2;
        background-color: $color-2-light;
        @include outer-shadow-tight($color-2-dark);
        animation: fadeInButtonShadowValid 200ms;

        &:hover,
        &:focus,
        &:active {
            @include outer-shadow-tight($color-2-dark, inset);
            animation: fadeOutButtonShadowValid 200ms;
        }
    }

    &.is-selected {
        background-color: $color-1-mid;
    }

    &.is-deselected {
        background-color: white;
        border-color: $color-grey-2;
        @include outer-shadow-tight;
        animation: fadeInButtonShadowDeselected 200ms;

        &:hover,
        &:focus,
        &:active {
            @include outer-shadow-tight($black, inset);
            animation: fadeOutButtonShadowDeselected 200ms;
        }
    }

    &.is-disabled {
        background-color: white;
        border-color: $color-grey-2;
        @include outer-shadow-tight;
        opacity: 1;
        animation: none;
    }
}

.c-btn-rounded {
    @include rounded-button-styles;
    min-width: 295px;
}

.styleguide {
    .c-btn-small-square--position-fixed-right-top,
    .c-btn-small-square--position-fixed-right-bottom {
        position: absolute;
    }
}

/*doc
---
title: Buttons
name: buttons
category: Buttons
---

```html_example_table
    <button class="c-btn">Button</button>

    <button data-parent-classes="u-sg-darkgrey-bg" class="c-btn c-btn--1-light">Button</button>

    <button class="c-btn c-btn--2">Button</button>

    <button class="c-btn c-btn--3">Button</button>

    <button class="c-btn c-btn--4">Button</button>

    <button class="c-btn c-btn--5">Button</button>

    <button data-parent-classes="u-sg-darkgrey-bg" class="c-btn c-btn--white">Button</button>

    <button class="c-btn c-btn--black">Button</button>

```
<div class="c-sg-modifier-wrap js-modifier-wrap">
    <div>
        <button>c-btn--full-width</button>
        <button>c-btn--mobile-full-width</button>
    </div>
    <div>
        <button>c-btn--raised</button>
    </div>
    <div>
        <button>c-btn--small-header</button>
        <button>c-btn--large</button>
    </div>
    <div>
        <button>c-btn--more-padding</button>
    </div>
    <div>
        <button>c-btn--margin-0</button>
    </div>
    <div>
        <button>c-btn--margin-right-s</button>
    </div>
    <div>
        <button>c-btn--margin-bottom-0</button>
        <button>c-btn--margin-bottom-0-xs</button>
    </div>
    <div>
        <button>c-btn--text-align-left</button>
    </div>
    <div>
        <button>c-btn--icon-only</button>
    </div>
    <div>
        <button>c-btn--plus-minus-icon</button>
        <button>c-btn--plus-minus-icon is-active</button>
    </div>
    <div>
        <button>is-disabled</button>
    </div>
    <div>
        <button>c-btn--float-right</button>
    </div>
</div>

*/

/*doc
---
title: Buttons rounded
name: buttons-rounded
category: Buttons
---

```html_example_table
    <button class="c-btn-rounded">Button</button>

```
<div class="c-sg-modifier-wrap js-modifier-wrap">
    <div>
        <button>is-valid</button>
        <button>is-disabled</button>
    </div>
    <div>
        <button>c-btn-rounded--full-width</button>
        <button>c-btn-rounded--mobile-full-width</button>
    </div>
    <div>
        <button>c-btn-rounded--margin-left-s</button>
    </div>
    <div>
        <button>c-btn-rounded--margin-right-0</button>
        <button>c-btn-rounded--margin-right-xs</button>
        <button>c-btn-rounded--margin-right-s</button>
    </div>
    <div>
        <button>c-btn-rounded--more-padding</button>
    </div>
    <div>
        <button>c-btn-rounded--margin-0</button>
    </div>
    <div>
        <button>c-btn-rounded--margin-bottom-0</button>
        <button>c-btn-rounded--margin-bottom-0-xs</button>
    </div>
    <div>
        <button>c-btn-rounded--text-align-left</button>
    </div>
    <div>
        <button>c-btn-rounded--float-right</button>
    </div>
</div>

*/

/*doc
---
title: Buttons outlined
name: buttons-outlined
category: Buttons
---

```html_example_table
    <button class="c-btn-outline">Button</button>

    <button class="c-btn-outline c-btn-outline--2">Button</button>

    <button class="c-btn-outline c-btn-outline--3">Button</button>

    <button class="c-btn-outline c-btn-outline--4">Button</button>

    <button class="c-btn-outline c-btn-outline--5">Button</button>

    <button data-parent-classes="u-sg-darkgrey-bg" class="c-btn-outline c-btn-outline--white">Button</button>

    <button class="c-btn-outline c-btn-outline--black">Button</button>
```

<div class="c-sg-modifier-wrap js-modifier-wrap">
    <div>
        <button>c-btn-outline--full-width</button>
        <button>c-btn-outline--mobile-full-width</button>
    </div>
    <div>
        <button>c-btn-outline--small-header</button>
        <button>c-btn-outline--large</button>
    </div>
    <div>
        <button>c-btn-outline--more-padding</button>
    </div>
    <div>
        <button>c-btn-outline--margin-0</button>
    </div>
    <div>
        <button>c-btn-outline--margin-right-s</button>
    </div>
    <div>
        <button>c-btn-outline--margin-bottom-0</button>
        <button>c-btn-outline--margin-bottom-0-xs</button>
    </div>
    <div>
        <button>c-btn-outline--text-align-left</button>
    </div>
    <div>
        <button>c-btn-outline--icon-only</button>
    </div>
    <div>
        <button>c-btn-outline--plus-minus-icon</button>
        <button>c-btn-outline--plus-minus-icon is-active</button>
    </div>
    <div>
        <button>is-disabled</button>
    </div>
    <div>
        <button>c-btn-outline--float-right</button>
    </div>
</div>

*/

/*doc
---
title: Buttons small square
name: buttons-small-square
category: Buttons
---

```html_example_table
    <button data-parent-classes="u-sg-height-200" class="c-btn-small-square u-icon-cancel">Button</button>

    <button data-parent-classes="u-sg-height-200" class="c-btn-small-square c-btn-small-square--2 u-icon-cancel">Button</button>

    <button data-parent-classes="u-sg-height-200" class="c-btn-small-square c-btn-small-square--3 u-icon-cancel">Button</button>

    <button data-parent-classes="u-sg-height-200" class="c-btn-small-square c-btn-small-square--4 u-icon-cancel">Button</button>

    <button data-parent-classes="u-sg-height-200" class="c-btn-small-square c-btn-small-square--5 u-icon-cancel">Button</button>

    <button data-parent-classes="u-sg-height-200 u-sg-darkgrey-bg" class="c-btn-small-square c-btn-small-square--white u-icon-cancel">Button</button>

    <button data-parent-classes="u-sg-height-200" class="c-btn-small-square c-btn-small-square--black u-icon-cancel">Button</button>
```

<div class="c-sg-modifier-wrap js-modifier-wrap">
    <div>
        <button>c-btn-small-square--margin-0</button>
    </div>
    <div>
        <button>c-btn-small-square--margin-right-s</button>
    </div>
    <div>
        <button>c-btn-small-square--margin-bottom-0</button>
        <button>c-btn-small-square--margin-bottom-0-xs</button>
    </div>
    <div>
        <button>is-disabled</button>
    </div>
    <div>
        <button>c-btn-small-square--float-right</button>
    </div>
    <div>
        <button>c-btn-small-square--position-fixed-right-top</button>
        <button>c-btn-small-square--position-fixed-right-bottom</button>
    </div>
</div>

*/

/*doc
---
title: Buttons circled
name: buttons-circled
category: Buttons
---

```html_example_table
    <button class="c-btn-circle">
        <div class="c-btn-circle__content">Button</div>
    </button>

    <button data-parent-classes="u-sg-darkgrey-bg" class="c-btn-circle c-btn-circle--1-light">
        <div class="c-btn-circle__content">Button</div>
    </button>

    <button class="c-btn-circle c-btn-circle--2">
        <div class="c-btn-circle__content">Button</div>
    </button>

    <button class="c-btn-circle c-btn-circle--3">
        <div class="c-btn-circle__content">Button</div>
    </button>

    <button class="c-btn-circle c-btn-circle--4">
        <div class="c-btn-circle__content">Button</div>
    </button>

    <button class="c-btn-circle c-btn-circle--5">
        <div class="c-btn-circle__content">Button</div>
    </button>

    <button data-parent-classes="u-sg-darkgrey-bg" class="c-btn-circle c-btn-circle--white">
        <div class="c-btn-circle__content">Button</div>
    </button>

    <button class="c-btn-circle c-btn-circle--black">
        <div class="c-btn-circle__content">Button</div>
    </button>
```

<div class="c-sg-modifier-wrap js-modifier-wrap">
    <div>
        <button>c-btn-circle--raised</button>
    </div>
    <div>
        <button>c-btn-circle--margin-left-s</button>
    </div>
    <div>
        <button>c-btn-circle--margin-right-s</button>
    </div>
    <div>
        <button>c-btn-circle--margin-bottom-0</button>
        <button>c-btn-circle--margin-bottom-0-xs</button>
    </div>
    <div>
        <button>is-disabled</button>
    </div>
    <div>
        <button>c-btn-circle--float-right</button>
    </div>
</div>
*/

/*doc
---
title: Buttons small round
name: buttons-small-round
category: Buttons
---

```html_example_table
    <button class="c-btn-small-round">
        <div class="c-btn-small-round__content">Send</div>
    </button>

    <button class="c-btn-small-round">
        <div class="c-btn-small-round__content u-before-icon-paper-plane"></div>
    </button>
```

<div class="c-sg-modifier-wrap js-modifier-wrap">
    <div>
        <button>c-btn-small-round--large</button>
    </div>
    <div>
        <button>c-btn-small-round--margin-0</button>
    </div>
    <div>
        <button>c-btn-small-round--margin-right-s</button>
    </div>
    <div>
        <button>c-btn-small-round--margin-bottom-0</button>
        <button>c-btn-small-round--margin-bottom-0-xs</button>
    </div>
    <div>
        <button>is-disabled</button>
    </div>
    <div>
        <button>c-btn-small-round--float-right</button>
    </div>
</div>
*/

/*doc
---
title: Buttons block
name: buttons-block
category: Buttons
---

```html_example
    <button class="c-btn-block">
        <span class="c-btn-block__content">
            Button
            <span class="c-btn-block__subtext">Subtext</span>
        </span>
    </button>
```

<div class="c-sg-modifier-wrap js-modifier-wrap">
    <div>
        <button>c-btn-block--with-subtext</button>
    </div>
    <div>
        <button>c-btn-block--full-width</button>
    </div>
    <div>
        <button>c-btn-block--margin-0</button>
    </div>
    <div>
        <button>c-btn-block--margin-right-s</button>
    </div>
    <div>
        <button>c-btn-block--margin-bottom-0</button>
        <button>c-btn-block--margin-bottom-0-xs</button>
    </div>
    <div>
        <button>c-btn-block--text-align-left</button>
    </div>
    <div>
        <button>c-btn-block--icon-only</button>
    </div>
    <div>
        <button>c-btn-block--plus-minus-icon</button>
        <button>c-btn-block--plus-minus-icon is-active</button>
    </div>
    <div>
        <button>is-disabled</button>
    </div>
    <div>
        <button>c-btn-block--justify-content-flex-start</button>
    </div>
    <div>
        <button>c-btn-block--no-fixed-height</button>
    </div>
</div>
*/

/*doc
---
title: Buttons block (Dot Dot Loans example)
name: buttons-block-dot-dot-loans-example
category: Buttons
---
Note - This button block component example also includes an image, as well as a number of modifiers available to the component

```html_example
    <a href="#" class="c-btn-block c-btn-block--full-width c-btn-block--justify-content-flex-start c-btn-block--text-align-left c-btn-block--no-fixed-height c-btn-block--dot-dot-loans">
        <img class="c-btn-block__image" src="img/dotdotlogo.png" alt="Dot Dot Loans Logo" />
        <span class="c-btn-block__content">
            Simple online loans from £200 - £1,000*
        </span>
    </a>
```

<div class="c-sg-modifier-wrap js-modifier-wrap">
    <div>
        <button>c-btn-block--with-subtext</button>
    </div>
    <div>
        <button>c-btn-block--full-width</button>
    </div>
    <div>
        <button>c-btn-block--margin-0</button>
    </div>
    <div>
        <button>c-btn-block--margin-right-s</button>
    </div>
    <div>
        <button>c-btn-block--margin-bottom-0</button>
        <button>c-btn-block--margin-bottom-0-xs</button>
    </div>
    <div>
        <button>c-btn-block--text-align-left</button>
    </div>
    <div>
        <button>c-btn-block--icon-only</button>
    </div>
    <div>
        <button>c-btn-block--plus-minus-icon</button>
        <button>c-btn-block--plus-minus-icon is-active</button>
    </div>
    <div>
        <button>is-disabled</button>
    </div>
    <div>
        <button>c-btn-block--justify-content-flex-start</button>
    </div>
    <div>
        <button>c-btn-block--no-fixed-height</button>
    </div>
</div>
*/

/*doc
---
title: Button tertiary
name: button-tertiary
category: Buttons
---

```html_example
    <button class="c-btn-tertiary">Log out</button>
```

Note - other modifiers can be applied to this component (as it uses the base buttons styles mixin), but here all of the relevant modifiers which won't break the component are shown.

<div class="c-sg-modifier-wrap js-modifier-wrap">
    <div>
        <button>c-btn-tertiary--margin-0</button>
    </div>
    <div>
        <button>c-btn-tertiary--margin-right-s</button>
    </div>
    <div>
        <button>c-btn-tertiary--margin-bottom-0</button>
        <button>c-btn-tertiary--margin-bottom-0-xs</button>
    </div>
    <div>
        <button>is-disabled</button>
    </div>
</div>
*/
