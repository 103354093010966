.c-sidebar-content-header {
    position: relative;
    margin-bottom: $space-xxl;

    &__close-content-btn,
    &__content-right {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
    }

    &__close-content-btn {
        @include button-styles;
        @include main-button-text;
        left: 0;
        background-color: transparent;
        margin: 0;
        min-height: 100%;

        &::before {
            content: $icon-angle-left;
            @include ffrees-icons-font;
            display: inline-block;
        }
    }

    &__title {
        text-align: center;
        margin: 0 $space-xxxl;
    }

    &__content-right {
        right: 0;
    }

    @media (min-width: $m_bp) {
        &__close-content-btn {
            display: none;
        }

        &--display-until-m {
            display: none;
        }
    }
}

/*doc
---
title: sidebar content header
name: sidebar-content-header
category: Organisms
---

```html_example
<div class="c-sidebar-content-header">
    <button class="c-sidebar-content-header__close-content-btn js-hide-sidebar-content"></button>
    <div class="c-sidebar-content-header__title js-main-account-header">
        <h1 class="u-tabs-section-title u-margin-bottom-0 u-line-height-1">Main Account</h1>
        <h2 class="u-title u-margin-bottom-0 u-line-height-1">£179.67</h2>
    </div>
    <div class="c-sidebar-content-header__content-right">
        <a class="c-text-link">Edit <span class="c-text-link__icon u-after-icon-pencil"></span></a>
    </div>
</div>

```
<div class="c-sg-modifier-wrap js-modifier-wrap">
    <div>c-sidebar-content-header--display-until-m</div>
</div>

*/
