@keyframes backgroundBackAndForth {

    0% {
        background-position: 0 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0 50%;
    }
}

@keyframes backgroundForwards {

    0% {
        background-position: 200% 0%;
    }

    100% {
        background-position: 0 0%;
    }
}
