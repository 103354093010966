.c-full-width-box {
    $class: c-full-width-box;
    $border-radius: $space-m;

    position: relative;
    background: $color-grey-7;
    padding: $space-s 0;

    &::before,
    &::after {
        content: '';
        position: absolute;
        top: 0;
        right: 100%;
        height: 100%;
        width: 100vw;
        background: $color-grey-7;
    }

    &::after {
        left: 100%;
        right: auto;
    }

    &--left {
        padding-right: $space-l;
        border-radius: 0 $border-radius $border-radius 0;

        &::after {
            content: none;
        }
    }

    &--right {
        padding-left: $space-l;
        border-radius: $border-radius 0 0 $border-radius;

        &::before {
            content: none;
        }
    }

    &--color-grey-4 {
        background: $color-grey-4;

        &::before,
        &::after {
            background: $color-grey-4;
        }
    }

    &--color-grey-5 {
        background: $color-grey-5;

        &::before,
        &::after {
            background: $color-grey-5;
        }
    }

    &--slanted {
        transform: rotate(-3deg);

        .#{$class}__content {
            transform: rotate(3deg);
        }
    }

    @media (min-width: $m_bp) {
        padding: $space-xxl 0;

        &--left,
        &--left-bp-m {
            padding-right: $space-xxl;
        }

        &--right,
        &--right-bp-m {
            padding-left: $space-xxl;
        }

        &--left-bp-m {
            border-radius: 0 $border-radius $border-radius 0;
            padding-left: 0;

            &::before {
                content: '';
            }

            &::after {
                content: none;
            }
        }

        &--right-bp-m {
            border-radius: $border-radius 0 0 $border-radius;
            padding-right: 0;

            &::before {
                content: none;
            }

            &::after {
                content: '';
            }
        }
    }
}

/*doc
---
title: full width box
name: full-width-box
category: Components
---

```html_example
<div class="l-content-width l-content-width--max-width-420">
    <div class="c-full-width-box">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla luctus blandit erat, sagittis ultricies ante posuere id. Nulla pulvinar vulputate purus, vel semper sapien euismod sed. Cras vehicula tempus placerat. Nunc sit amet eleifend justo. Pellentesque quis mauris quam. In vel tortor dui. Nulla quam magna, varius at urna pulvinar, eleifend lobortis purus. Nulla facilisi. Suspendisse vitae orci erat. Ut lobortis magna velit, non molestie nulla blandit vitae. Praesent luctus cursus sem vel dapibus. Nunc semper est sit amet nisl auctor sollicitudin. Vestibulum blandit sit amet mi sed feugiat. Nulla facilisi.
    </div>
</div>
```
<div class="c-sg-modifier-wrap js-modifier-wrap">
    <div>
        <button data-target="c-full-width-box">c-full-width-box--left</button>
        <button data-target="c-full-width-box">c-full-width-box--right</button>
    </div>
    <div>
        <button data-target="c-full-width-box">c-full-width-box--left-bp-m</button>
        <button data-target="c-full-width-box">c-full-width-box--right-bp-m</button>
    </div>
    <div>
        <button data-target="c-full-width-box">c-full-width-box--color-grey-4</button>
        <button data-target="c-full-width-box">c-full-width-box--color-grey-5</button>
    </div>
</div>
*/

/*doc
---
title: full width box (slanted)
name: full-width-box-slanted
category: Components
---

Note - For the slanted modified version of the component, the content within the full width box is included within a new content element

```html_example
<div class="l-content-width l-content-width--max-width-420">
    <div class="c-full-width-box c-full-width-box--slanted">
        <div class="c-full-width-box__content">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla luctus blandit erat, sagittis ultricies ante posuere id. Nulla pulvinar vulputate purus, vel semper sapien euismod sed. Cras vehicula tempus placerat. Nunc sit amet eleifend justo. Pellentesque quis mauris quam. In vel tortor dui. Nulla quam magna, varius at urna pulvinar, eleifend lobortis purus. Nulla facilisi. Suspendisse vitae orci erat. Ut lobortis magna velit, non molestie nulla blandit vitae. Praesent luctus cursus sem vel dapibus. Nunc semper est sit amet nisl auctor sollicitudin. Vestibulum blandit sit amet mi sed feugiat. Nulla facilisi.
        </div>
    </div>
</div>
```
<div class="c-sg-modifier-wrap js-modifier-wrap">
    <div>
        <button data-target="c-full-width-box">c-full-width-box--left</button>
        <button data-target="c-full-width-box">c-full-width-box--right</button>
    </div>
    <div>
        <button data-target="c-full-width-box">c-full-width-box--color-grey-4</button>
        <button data-target="c-full-width-box">c-full-width-box--color-grey-5</button>
    </div>
</div>
*/