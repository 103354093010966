.c-app-badges {
    $class: c-app-badges;

    width: 100%;
    padding: $space-xs 0 $space-l;
    clear: left;
    float: left;

    &__link {
        margin-bottom: $space-xs;
        display: block;
    }

        &__img {
            margin: 0 auto;
            display: block;
            width: auto;
        }

    &--no-float {
        float: none;
        padding-bottom: 0;
    }

    @media (min-width: $xs_bp) {
        &__link {
            margin-right: $space-xs;
            margin-bottom: $space-l;
            display: inline-block;
        }

            &__img {
                margin: 0;
            }

        &--margin-bottom-0 {
            .#{$class}__link {
                margin-bottom: 0;
            }
        }
    }
}


/*doc
---
title: App Badges
name: app-badges
category: Components
---

```html_example
<div class="c-app-badges">

    <a class="c-app-badges__link" href="https://itunes.apple.com/gb/app/balance/id1224502407?mt=8" target="_blank">
        <img class="c-app-badges__img" src="img/app_store_badge_us-uk.svg" title="iOS App Store Badge" height="55" alt="apple app store link">
    </a>

    <a class="c-app-badges__link" href="https://play.google.com/store/apps/details?id=uk.uaccount.android" target="_blank">
        <img class="c-app-badges__img" src="img/google-play-badge.svg" title="Google Play Store Badge" height="55" alt="google play store link">
    </a>

</div>
```
<div class="c-sg-modifier-wrap js-modifier-wrap">
    <div>
        <button>c-app-badges--margin-bottom-0</button>
    </div>
</div>
*/
