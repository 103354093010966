.c-icon {
    $class: c-icon;

    width: 27px;
    height: 21px;
    fill: transparent;
    shape-rendering: geometricPrecision;

    @each $color in $colors {
        $key: nth($color, 1);
        $value: nth($color, 2);

        &--fill-#{$key} {
            fill: #{$value};
            stroke-width: 0;
        }

        &--outline-#{$key} {
            fill: transparent;
            stroke: #{$value};
            stroke-width: 1;
        }
    }

    &--center {
        margin-right: auto;
        margin-left: auto;
    }

    &--stroke-width-2 {
        stroke-width: 2;
    }

}

.styleguide {
    .c-icon {
        width: 100px;
        height: 88px;
    }
}

/*doc
---
title: Filled Icons
name: filled-icons
category: Icons
---

You need to create a virtual host for the icons to load correctly. Follow the steps on <a href="https://ffrees.atlassian.net/wiki/spaces/PROD/pages/1472922027/Creating+vhost+for+Styleguides" target="_Blank">this confluence page</a>.

Icons need a title to be accessible to screenreader users, or to be hidden from them using aria-hidden="true" if they are purely decorative. <a href="https://css-tricks.com/accessible-svgs/#article-header-id-9">Here are some examples of accessible svg icon use</a>.

```html_example_table

<svg class="c-icon c-icon--fill-color-1">
    <title>Account</title>
    <use xlink:href="#account-outline" />
</svg>

<svg class="c-icon c-icon--fill-color-1">
    <title>Account</title>
    <use xlink:href="#account" />
</svg>

<svg class="c-icon c-icon--fill-color-1">
    <title>Card</title>
    <use xlink:href="#card" />
</svg>

<svg class="c-icon c-icon--fill-color-1">
    <title>Damaged card</title>
    <use xlink:href="#card-damaged" />
</svg>

<svg class="c-icon c-icon--fill-color-1">
    <title>Key</title>
    <use xlink:href="#key" />
</svg>

<svg class="c-icon c-icon--fill-color-1">
    <title>Plus</title>
    <use xlink:href="#plus" />
</svg>

<svg class="c-icon c-icon--fill-color-1">
    <title>Share</title>
    <use xlink:href="#share" />
</svg>

<svg class="c-icon c-icon--fill-color-1">
    <title>Snowflake</title>
    <use xlink:href="#snowflake" />
</svg>

<svg class="c-icon c-icon--fill-color-1">
    <title>Warning</title>
    <use xlink:href="#warning" />
</svg>

<svg class="c-icon c-icon--fill-color-1">
    <title>Checkmark</title>
    <use xlink:href="#check" />
</svg>

<svg class="c-icon c-icon--fill-color-1">
    <title>Copy</title>
    <use xlink:href="#copy" />
</svg>

<svg class="c-icon c-icon--fill-color-1">
    <title>Help</title>
    <use xlink:href="#help" />
</svg>

<svg class="c-icon c-icon--fill-color-1">
    <title>Pay</title>
    <use xlink:href="#pay" />
</svg>

<svg class="c-icon c-icon--fill-color-1">
    <title>Settings</title>
    <use xlink:href="#settings" />
</svg>

<svg class="c-icon c-icon--fill-color-1">
    <title>Share</title>
    <use xlink:href="#share" />
</svg>

```
<div class="c-sg-modifier-wrap js-modifier-wrap">
    <div>
        <button>c-icon--fill-color-1</button>
        <button>c-icon--fill-color-2</button>
        <button>c-icon--fill-color-3</button>
        <button>c-icon--fill-color-4</button>
        <button>c-icon--fill-color-black</button>
        <button>c-icon--fill-color-white</button>
    </div>
    <div>
        <button>c-icon--center</button>
    </div>
</div>
*/

/*doc
---
title: Outlined Icons
name: outlined-icons
category: Icons
---

```html_example_table

<svg class="c-icon c-icon--outline-color-1">
    <title>Account</title>
    <use xlink:href="#account" />
</svg>

<svg class="c-icon c-icon--outline-color-1">
    <title>Card</title>
    <use xlink:href="#card" />
</svg>

<svg class="c-icon c-icon--outline-color-1">
    <title>Damaged card</title>
    <use xlink:href="#card-damaged" />
</svg>

<svg class="c-icon c-icon--outline-color-1">
    <title>Key</title>
    <use xlink:href="#key" />
</svg>

<svg class="c-icon c-icon--outline-color-1">
    <title>Plus</title>
    <use xlink:href="#plus" />
</svg>

<svg class="c-icon c-icon--outline-color-1">
    <title>Share</title>
    <use xlink:href="#share" />
</svg>

<svg class="c-icon c-icon--outline-color-1">
    <title>Snowflake</title>
    <use xlink:href="#snowflake" />
</svg>

<svg class="c-icon c-icon--outline-color-1">
    <title>Warning</title>
    <use xlink:href="#warning" />
</svg>

<svg class="c-icon c-icon--outline-color-1">
    <title>Checkmark</title>
    <use xlink:href="#check" />
</svg>

<svg class="c-icon c-icon--outline-color-1">
    <title>Copy</title>
    <use xlink:href="#copy" />
</svg>

<svg class="c-icon c-icon--outline-color-1">
    <title>Help</title>
    <use xlink:href="#help" />
</svg>

<svg class="c-icon c-icon--outline-color-1">
    <title>Pay</title>
    <use xlink:href="#pay" />
</svg>

<svg class="c-icon c-icon--outline-color-1">
    <title>Settings</title>
    <use xlink:href="#settings" />
</svg>

<svg class="c-icon c-icon--outline-color-1">
    <title>Share</title>
    <use xlink:href="#share" />
</svg>

```
<div class="c-sg-modifier-wrap js-modifier-wrap">
    <div>
        <button>c-icon--outline-color-1</button>
        <button>c-icon--outline-color-2</button>
        <button>c-icon--outline-color-3</button>
        <button>c-icon--outline-color-4</button>
        <button>c-icon--outline-color-black</button>
        <button>c-icon--outline-color-white</button>
    </div>
    <div>
        <button>c-icon--center</button>
        <button>c-icon--stroke-width-2</button>
    </div>
</div>
*/
