.c-accounts-sidebar {
    $class: c-accounts-sidebar;

    height: 100%;

    &__section-wrapper {
        padding: $space-s $space-xxs;

        &--accounts-list {
            background-color: $white;
        }

        &--hr {
            display: none;
            padding-top: 0;
            padding-bottom: 0;
        }
    }

        &__section {
            @include content-width;
            max-width: 420px;
            padding: 0;
        }

    @media (min-width: $xs_bp) {
        &__section-wrapper {
            padding: $space-l;

            &--accounts-list {
                background-color: transparent;
            }

            &--hr {
                display: block;
                padding-top: 0;
                padding-bottom: 0;

                .#{$class}__section {
                    padding: 0 $space-xl;
                }
            }
        }

        &__section--hr {
            display: block;
        }
    }

    @media (min-width: $m_bp) {
        &__section-wrapper {
            padding: $space-l $space-m;

            &--hr {
                padding-top: 0;
                padding-bottom: 0;
            }
        }

            &__section {
                max-width: none;
            }
    }

    @media (min-width: $l_bp) {
        &__section-wrapper {
            padding: $space-l;

            &--hr {
                padding-top: 0;
                padding-bottom: 0;
            }
        }
    }
}

/*doc
---
title: accounts sidebar
name: accounts-sidebar
category: Organisms
---

```html_example
<div class="c-accounts-sidebar">

    <div class="c-accounts-sidebar__section-wrapper">
        <div class="c-accounts-sidebar__section">
            <div class="c-account-balance js-accounts-page-balance js-balance-ls">

                <div class="c-reveal-vertical js-reveal is-revealed" data-event-trigger="" data-reveal-height="76" style="">

                    <div class="c-reveal-vertical__content">

                        <h3 class="c-account-balance__main-balance">
                            Available Balance
                            £<span class="js-available-balance-figure js-balance-count-up tt-available-balance">597.72</span>
                            <span class="c-account-balance__bracket js-available-bracket" style="width: 100%;"></span>
                        </h3>

                        <div class="u-clearfix">

                            <p class="c-account-balance__balance c-account-balance--main-account-balance js-main-account-balance" style="opacity: 1;">
                                Main Account £<span class="js-main-account-balance-figure tt-main-account-balance">509.71</span>
                            </p>

                            <p class="c-account-balance__balance c-account-balance--pending-balance js-pending-balance">
                                Pending £<span class="js-pending-balance-figure tt-pending-balance"></span>
                            </p>

                        </div>

                    </div>

                </div>

                <div class="c-account-balance__bar js-balance-bar">

                    <div class="c-account-balance__section c-account-balance--main-account-section js-main-account-section" style="width: calc(85% + 1px);"></div>

                    <div class="c-account-balance__section c-account-balance--extra-accounts-section js-extra-section" style="width: 15%;"></div>

                    <div class="c-account-balance__section c-account-balance--pending-section js-pending-section"></div>

                </div>

                <div class="c-reveal-vertical js-reveal is-revealed" data-event-trigger="" data-reveal-height="96" style="">

                    <div class="c-reveal-vertical__content">

                        <p class="c-account-balance__balance c-account-balance--extra-account-balance js-extra-account-balance" style="right: 0%; opacity: 1;">
                            Extra Accounts £<span class="js-extra-account-balance-figure tt-extra-account-balance">88.01</span>
                        </p>

                        <h3 class="c-account-balance__main-balance c-account-balance--total-balance">
                            <span class="c-account-balance__bracket c-account-balance--total-bracket js-total-bracket" style="width: 100%;"></span>
                            Total Balance
                            £<span class="js-total-balance-figure js-balance-count-up tt-total-balance">597.72</span>
                        </h3>

                    </div>

                </div>
            </div>
        </div>
    </div>

    <div class="c-accounts-sidebar__section-wrapper c-accounts-sidebar__section-wrapper--hr">
        <div class="c-accounts-sidebar__section">
            <hr class="c-hr c-hr--full-width c-hr--margin-top-0 c-hr--margin-bottom-0 u-color-grey-mid-bg">
        </div>
    </div>

    <div class="c-accounts-sidebar__section-wrapper c-accounts-sidebar__section-wrapper--accounts-list">
        <div class="c-accounts-sidebar__section">
            <div class="js-accounts-list">
                <div class="c-account-listing js-account-listing js-main-account">
                    <div class="c-account-listing__block">
                        <div class="c-account-listing__message-wrap">
                            <div class="c-account-listing__message">
                                Message text here
                            </div>
                        </div>
                        <button class="c-account-listing__main-content" type="button">
                            <div class="c-account-listing__main-content-inner">
                                <div class="c-account-listing__info-wrap">
                                    <h3 class="c-account-listing__name">
                                        Main Account
                                    </h3>
                                    <div class="c-account-listing__details">
                                        <h5 class="c-account-listing__account-number">12345678</h5>|<h5 class="c-account-listing__sort-code">21-45-56</h5>
                                    </div>
                                </div>
                                <h4 class="c-account-listing__amount">
                                    £80.00
                                </h4>
                            </div>
                        </button>
                        <div class="c-account-listing__dropdown-content-wrap js-dropdown-content-wrap">
                            <div>
                                <div class="c-account-listing__dropdown-hr">
                                    <hr class="c-hr c-hr--full-width c-hr--margin-top-0 c-hr--margin-bottom-0" />
                                </div>
                                <div class="c-account-listing__dropdown-content">
                                    Test dropdown content
                                </div>
                            </div>
                        </div>
                    </div>
                    <button class="c-account-listing__dropdown-button js-listing-dropdown-btn">
                        <div class="c-account-listing__dropdown-button-inner js-listing-dropdown-btn-inner"></div>
                    </button>
                </div>

                <a class="c-btn-block c-btn-block--full-width c-btn-block--margin-0" href="/dashboard/extra-accounts">
                    <span>Manage Extra Accounts</span>
                </a>
            </div>
        </div>
    </div>

    <div class="c-accounts-sidebar__section-wrapper c-accounts-sidebar__section-wrapper--hr">
        <div class="c-accounts-sidebar__section">
            <hr class="c-hr c-hr--full-width c-hr--margin-top-0 c-hr--margin-bottom-0 u-color-grey-mid-bg">
        </div>
    </div>

    <div class="c-accounts-sidebar__section-wrapper">
        <div class="c-accounts-sidebar__section">
            <div class="c-btn-block-group c-btn-block-group--flex-layout-400 c-btn-block-group--standard-layout-m c-btn-block-group--flex-layout-l">
                <a class="c-btn-block c-btn-block--full-width c-btn-block--margin-right-0" href="/pay-in">
                    How to pay in
                </a>
                <a class="c-btn-block c-btn-block--margin-right-0 c-btn-block--with-subtext" href="/dashboard/my-fees">
                    <span class="c-btn-block__content">
                        Fees &amp; Limits
                        <span class="c-btn-block__subtext">Next <span class="js-dashboard-price-point" data-product-handle="ACC_TWO_500">£5</span> bill: <span class="js-billing-date">14/03/17</span></span>
                    </span>
                </a>
            </div>
        </div>
    </div>

</div>

```

*/
