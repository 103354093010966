.c-slider-v2 {

    &__pips {
        width: 100%;
        margin-bottom: $space-xs;
        padding: 0;
        text-align: center;
    }

        &__pip {
            @include outer-shadow;
            width: $space-xs;
            height: $space-xs;
            margin: $space-xs;
            background-color: $white;
            display: inline-block;
            border-radius: 50%;
            transition: background-color .25s ease-in-out;

            &.is-highlighted {
                background-color: $black;
            }
        }

    &__wrap {
        position: relative;
        max-width: 100%;
        overflow: hidden;
        transition: height 0.3s ease-in-out;
    }

        &__slide {
            position: absolute;
            width: 100%;
            align-self: flex-start;
            margin-right: 1%;
        }
}
