.c-product-selector {
    $class: c-product-selector;
    $left-width: 42%;
    $center-width: 52%;
    $right-width: 6%;
    $dormancy-message-size: 14px;
    $button-height: 44px;
    $card-border: 2px;
    $price-height: 44px;

    margin: $space-xl 0;

    &__info-desktop {
        display: none;
        background-color: $color-grey-4;
    }

    &__card-flag {
        display: none;
        @include section-intro-text;
        @include font-heavy;
        margin: 0;
        width: calc((100% / 3) - (2 * #{$card-border} / 3));
        padding: $space-xs;
        color: $white;
        background-color: $color-1;
        text-align: center;
    }

    &__cards-wrapper-inner {
        .c-product-card {
            border-right-color: $color-grey-light;
        }
    }

    &__dormancy-info {
        display: none;
    }

    &__info-desktop-right-bar {
        display: none;
        background-color: $color-grey-4;
    }

    @media (min-width: $m-bp) {
        &__card-flag-wrap-outer {
            display: flex;
        }

            &__card-flag-wrap-inner {
                margin-left: $left-width;
                flex: 0 0 auto;
                width: $center-width;
            }

                &__card-flag {
                    line-height: $space-xl;
                }

        &__main {
            display: flex;
            margin-bottom: $space-l;
        }

            &__info-desktop {
                flex: 0 0 auto;
                width: $left-width;
                display: block;
                padding: 0 24px;
                margin: 0;
                margin-top: calc(#{$price-height} + 4 * #{$space-xs});
                margin-bottom: calc(2 * #{$space-xs} + #{$dormancy-message-size} + #{$space-xs} + #{$button-height});
            }

                &__entry-title {
                    display: flex;
                    align-items: center;
                    font-size: 20px;
                    min-height: 70px;
                    @include font-heavy;
                    margin: 0;
                    padding: 4px 0;
                    min-height: 70px;

                    &:before {
                        color: $color-1;
                        font-size: $space-l;
                        margin-right: $space-s;
                    }
                }

            &__cards-wrapper-outer {
                flex: 0 0 auto;
                width: $center-width;
                z-index: 2;
                display: flex;
                flex-direction: column;
            }

                &__cards-wrapper-inner {
                    flex-grow: 1;
                    display: flex;
                    background-color: $white;
                    @include outer-shadow;

                    .c-product-card {
                        flex: 1 0 auto;
                        width: calc((100% / 3) - (2 * #{$card-border} / 3) + 2px);
                        border-right: solid $card-border $color-grey-light;

                        &:last-child {
                            width: calc((100% / 3) - (2 * #{$card-border} / 3));
                            border-right-width: 0;
                        }

                        &:hover,
                        &:focus {
                            border-right-width: 0;
                        }
                    }
                }

                &__dormancy-info {
                    display: block;
                    margin: 0;
                    padding-top: $space-xs;
                    font-size: $dormancy-message-size;
                    line-height: $dormancy-message-size;
                    text-align: center;
                }

            &__info-desktop-right-bar {
                flex: 0 0 auto;
                width: $right-width;
                display: block;
                margin: 0;
                margin-top: calc(#{$price-height} + 4 * #{$space-xs});
                margin-bottom: calc(2 * #{$space-xs} + #{$dormancy-message-size} + #{$space-xs} + #{$button-height});;
            }

        &--first-flagged {
            .#{$class}__card-flag {
                display: inline-block;
                margin-left: 0;
            }
        }

        &--second-flagged {
            .#{$class}__card-flag {
                display: inline-block;
                margin-left: calc((100% / 3) - (2 * #{$card-border} / 3) + #{$card-border});
            }
        }

        &--last-flagged {
            .#{$class}__card-flag {
                display: inline-block;
                margin-left: calc(2 * ((100% / 3) - (2 * #{$card-border} / 3) + #{$card-border}));
            }
        }
    }
}

/*doc
---
title: product selector
name: product-selector
category: Organisms
---
Requires click event to open and close the cards in mobile view. The state modifier does show the different states the cards will be in, however.
<br>
Note that this component also contains the <code>.c-product-card</code> and <code>.c-product-price</code> components.

```html_example
<div class="c-product-selector">
    <div class="c-product-selector__card-flag-wrap-outer">
        <div class="c-product-selector__card-flag-wrap-inner">
            <div class="c-product-selector__card-flag">Most<br>Popular</div>
        </div>
    </div>
    <div class="c-product-selector__main">
        <div class="c-product-selector__info-desktop">
            <p class="c-product-selector__entry-title u-before-icon-upload">Payments out/direct debits</p>
            <p class="c-product-selector__entry-title u-before-icon-atm">UK cash withdrawals</p>
            <p class="c-product-selector__entry-title u-before-icon-user-add">Free Extra Accounts</p>
            <p class="c-product-selector__entry-title u-before-icon-user-add">Additional Extra Accounts</p>
            <p class="c-product-selector__entry-title u-before-icon-globe">Cash withdrawals abroad</p>
        </div>
        <div class="c-product-selector__cards-wrapper-outer">
            <div class="c-product-selector__cards-wrapper-inner">
                <div class="c-product-card">
                    <p class="c-product-card__flag">
                        Most<br>Popular
                    </p>
                    <div class="c-product-card__outer-wrap">
                        <div class="c-product-card__header">
                            <h3 class="c-product-price">
                                <span class="c-product-price__currency">&pound;</span>
                                <span class="c-product-price__value">10</span>
                                <span class="c-product-price__frequency">per<br>month</span>
                            </h3>
                        </div>
                        <div class="c-product-card__info-wrapper">
                            <div class="c-product-card__info">
                                <div class="c-product-card__info-entry">
                                    <p class="c-product-card__entry-title u-before-icon-upload">Payments out/direct debits</p>
                                    <p class="c-product-card__entry-value">FREE</p>
                                </div>
                                <div class="c-product-card__info-entry">
                                    <p class="c-product-card__entry-title u-before-icon-atm">UK cash withdrawals</p>
                                    <p class="c-product-card__entry-value">FREE</p>
                                </div>
                                <div class="c-product-card__info-entry">
                                    <p class="c-product-card__entry-title u-before-icon-user-add">Free Extra Accounts</p>
                                    <p class="c-product-card__entry-value">5</p>
                                </div>
                                <div class="c-product-card__info-entry">
                                    <p class="c-product-card__entry-title u-before-icon-user-add">Additional Extra Accounts</p>
                                    <div class="c-product-card__entry-value">
                                        50p
                                        <p class="c-product-card__entry-value-sub-text">per month</p>
                                    </div>
                                </div>
                                <div class="c-product-card__info-entry">
                                    <p class="c-product-card__entry-title u-before-icon-globe">Cash withdrawals abroad</p>
                                    <p class="c-product-card__entry-value">3%</p>
                                </div>
                            </div>
                        </div>
                        <button class="c-btn c-btn--2 c-btn--full-width c-btn--margin-0">Apply</button>
                    </div>
                </div>
                <div class="c-product-card">
                    <div class="c-product-card__outer-wrap">
                        <div class="c-product-card__header">
                            <h3 class="c-product-price">
                                <span class="c-product-price__currency">&pound;</span>
                                <span class="c-product-price__value">5</span>
                                <span class="c-product-price__frequency">per<br>month</span>
                            </h3>
                        </div>
                        <div class="c-product-card__info-wrapper">
                            <div class="c-product-card__info">
                                <div class="c-product-card__info-entry">
                                    <p class="c-product-card__entry-title u-before-icon-upload">Payments out/direct debits</p>
                                    <p class="c-product-card__entry-value">50p</p>
                                </div>
                                <div class="c-product-card__info-entry">
                                    <p class="c-product-card__entry-title u-before-icon-atm">UK cash withdrawals</p>
                                    <p class="c-product-card__entry-value">50p</p>
                                </div>
                                <div class="c-product-card__info-entry">
                                    <p class="c-product-card__entry-title u-before-icon-user-add">Free Extra Accounts</p>
                                    <p class="c-product-card__entry-value">2</p>
                                </div>
                                <div class="c-product-card__info-entry">
                                    <p class="c-product-card__entry-title u-before-icon-user-add">Additional Extra Accounts</p>
                                    <div class="c-product-card__entry-value">
                                        50p
                                        <p class="c-product-card__entry-value-sub-text">per month</p>
                                    </div>
                                </div>
                                <div class="c-product-card__info-entry">
                                    <p class="c-product-card__entry-title u-before-icon-globe">Cash withdrawals abroad</p>
                                    <p class="c-product-card__entry-value">3% + 50p</p>
                                </div>
                            </div>
                        </div>
                        <button class="c-btn c-btn--2 c-btn--full-width c-btn--margin-0">Apply</button>
                    </div>
                </div>
                <div class="c-product-card">
                    <div class="c-product-card__outer-wrap">
                        <div class="c-product-card__header">
                            <h3 class="c-product-price">
                                <span class="c-product-price__currency">&pound;</span>
                                <span class="c-product-price__value">0</span>
                                <span class="c-product-price__frequency">per<br>month*</span>
                            </h3>
                        </div>
                        <div class="c-product-card__info-wrapper">
                            <div class="c-product-card__info">
                                <div class="c-product-card__info-entry">
                                    <p class="c-product-card__entry-title u-before-icon-upload">Payments out/direct debits</p>
                                    <p class="c-product-card__entry-value">&pound;1</p>
                                </div>
                                <div class="c-product-card__info-entry">
                                    <p class="c-product-card__entry-title u-before-icon-atm">UK cash withdrawals</p>
                                    <p class="c-product-card__entry-value">&pound;1</p>
                                </div>
                                <div class="c-product-card__info-entry">
                                    <p class="c-product-card__entry-title u-before-icon-user-add">Free Extra Accounts</p>
                                    <p class="c-product-card__entry-value">0</p>
                                </div>
                                <div class="c-product-card__info-entry">
                                    <p class="c-product-card__entry-title u-before-icon-user-add">Additional Extra Accounts</p>
                                    <div class="c-product-card__entry-value">
                                        &pound;1
                                        <p class="c-product-card__entry-value-sub-text">per month</p>
                                    </div>
                                </div>
                                <div class="c-product-card__info-entry">
                                    <p class="c-product-card__entry-title u-before-icon-globe">Cash withdrawals abroad</p>
                                    <p class="c-product-card__entry-value">3% + &pound;1</p>
                                </div>
                                <p class="c-product-card__dormancy-info">*&pound;1.00 monthly dormancy fee after 60 days of inactivity</p>
                            </div>
                        </div>
                        <button class="c-btn c-btn--2 c-btn--full-width c-btn--margin-0">Apply</button>
                    </div>
                </div>
            </div>
            <p class="c-product-selector__dormancy-info">*&pound;1.00 monthly dormancy fee after 60 days of inactivity</p>
        </div>
        <div class="c-product-selector__info-desktop-right-bar"></div>
    </div>
</div>
```
<div class="c-sg-modifier-wrap js-modifier-wrap">
    <div>
        <button>c-product-selector--first-flagged</button>
        <button>c-product-selector--second-flagged</button>
        <button>c-product-selector--last-flagged</button>
    </div>
</div>
*/
