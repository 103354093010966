//Grid System
$gutter: $space-s;

// global col styling
[class^=l-col]{
    width: 100%;
    float: left;
    padding: $gutter;
    margin-right: 0;
    margin-left: 0;
}

// base coloumn widths
.l-col-100 { width: 100%; }
.l-col-75 { width: 75%; }
.l-col-66 { width: 66.66%; }
.l-col-60 { width: 60%; }
.l-col-50 { width: 50%; }
.l-col-40 { width: 40%; }
.l-col-33 { width: 33.33%; }
.l-col-25 { width: 25%; }


.l-row {
    // set grid to full width (compensating for padding of col elements)
    margin-left: -$gutter;
    margin-right: -$gutter;

    &:after { content: ""; display: table; clear: both; }

    // max widths on the grid
    &--w-1600 {max-width:1600px;}
    &--w-1440 {max-width:1440px;}
    &--w-1400 {max-width:1400px;}
    &--w-1200 {max-width:1200px;}
    &--w-1000 {max-width:1000px;}
    &--w-800 {max-width:800px;}
    &--w-600 {max-width:600px;}
    &--w-400 {max-width:400px;}

    // set grid to have side padding
    &--side-padding { margin: 0 auto;
        &.l-row--w-1600 {max-width:calc(1600px + (#{$gutter} * 2) ); margin: 0 auto;}
        &.l-row--w-1440 {max-width:calc(1440px + (#{$gutter} * 2) ); margin: 0 auto;}
        &.l-row--w-1400 {max-width:calc(1400px + (#{$gutter} * 2) ); margin: 0 auto;}
        &.l-row--w-1200 {max-width:calc(1200px + (#{$gutter} * 2) ); margin: 0 auto;}
        &.l-row--w-1000 {max-width:calc(1000px + (#{$gutter} * 2) ); margin: 0 auto;}
        &.l-row--w-800 {max-width:calc(800px + (#{$gutter} * 2) ); margin: 0 auto;}
        &.l-row--w-600 {max-width:calc(600px + (#{$gutter} * 2) ); margin: 0 auto;}
        &.l-row--w-400 {max-width:calc(400px + (#{$gutter} * 2) ); margin: 0 auto;}
    }

    &--vertical-margins {margin-top: $base-spacing-unit; margin-bottom: $base-spacing-unit;}
    &--no-vertical-padding > [class^=l-col] {padding-top: 0; padding-bottom: 0;}
    &--no-padding {
        margin-left: 0;
        margin-right: 0;
        > [class^=l-col] {padding: 0;}
    }
}

@mixin grid-sizes($grid-size) {
    .l-col-#{$grid-size}-100 {width:100%}
    .l-col-#{$grid-size}-75 {width:75%}
    .l-col-#{$grid-size}-66 {width:66.66%}
    .l-col-#{$grid-size}-60 {width:60%}
    .l-col-#{$grid-size}-50 {width:50%}
    .l-col-#{$grid-size}-40 {width:40%}
    .l-col-#{$grid-size}-33 {width:33.33%}
    .l-col-#{$grid-size}-25 {width:25%}
}

@media (min-width: $xs_bp) { @include grid-sizes(xs);}
@media (min-width: $s_bp) {@include grid-sizes(s);}
@media (min-width: $m_bp) {@include grid-sizes(m);}
@media (min-width: $l_bp) {@include grid-sizes(l);}
@media (min-width: $xl_bp) {@include grid-sizes(xl);}
@media (min-width: $xxl_bp) {@include grid-sizes(xxl);}


/*doc
---
title: Base grid system
name: base-grid-system
category: Grid system
---

```html_example
<div class="l-row">
    <div class="l-col-33 u-sg-col">l-col-33</div>
    <div class="l-col-33 u-sg-col">l-col-33</div>
    <div class="l-col-33 u-sg-col">l-col-33</div>
</div>
```
<div class="c-sg-modifier-wrap js-modifier-wrap">
    <div><button>l-row--side-padding</button></div>
    <div><button>l-row--vertical-margins</button></div>
    <div><button>l-row--no-vertical-padding</button></div>
    <div><button data-target="l-col-33">u-sg-col</button></div>
</div>

All grids columns are wrapped in a <code>.l-row</code>.<br>
The grid uses padding on each column to create the gutters/spacing between elements as opposed to margins. This means with dont need to constantly adjust margins (like margin-right) based on the amount of columns in the grid.

Having this padding would usually mean that the content might not align with the content around it. We have negative margins on the <code>.l-row</code> element to negate this. You can see this negative margin above, note how the column content is aligned correctly, and the background on each column shows the negative margin expanding beyond its parents padding.

Note the <code>.u-sg-col</code> is used to better present the columns, and is not needed in practice.

Side padding is enabled using modifier <code>'l-row--side-padding'</code>. This removes the negative margin on <code>.l-row</code>.

```html_example
<div class="l-row l-row--side-padding">
    <div class="l-col-33 u-sg-col">l-col-33</div>
    <div class="l-col-33 u-sg-col">l-col-33</div>
    <div class="l-col-33 u-sg-col">l-col-33</div>
</div>
```

<div class="c-sg-modifier-wrap js-modifier-wrap">
    <div><button>l-row--side-padding</button></div>
    <div><button>l-row--vertical-margins</button></div>
    <div><button>l-row--no-vertical-padding</button></div>
    <div><button data-target="l-col-33">u-sg-col</button></div>
</div>

To give spacing between column elements, wrap each column content with a div. This may cause unwanted vertical padding. see next example for a modifier. <br>
Note the <code>.u-sg-col-padding</code> is used to better present the columns, and is not needed in practice.

```html_example
<div class="l-row l-row--side-padding">
    <div class="l-col-33"><div class="u-sg-col-padding"> l-col-33</div></div>
    <div class="l-col-33"><div class="u-sg-col-padding"> l-col-33</div></div>
    <div class="l-col-33"><div class="u-sg-col-padding"> l-col-33</div></div>
</div>
```
<div class="c-sg-modifier-wrap js-modifier-wrap">
    <div><button>l-row--side-padding</button></div>
    <div><button>l-row--vertical-margins</button></div>
    <div><button>l-row--no-vertical-padding</button></div>
    <div><button data-target="l-col-33 > div">u-sg-col-padding</button></div>
</div>

To remove padding top and bottom on the grid columns use the modifier <code>'l-row--no-vertical-padding'.</code>

```html_example
<div class="l-row l-row--no-vertical-padding">
    <div class="l-col-33"><div class="u-sg-col-padding"> l-col-33</div></div>
    <div class="l-col-33"><div class="u-sg-col-padding"> l-col-33</div></div>
    <div class="l-col-33"><div class="u-sg-col-padding"> l-col-33</div></div>
</div>
```
<div class="c-sg-modifier-wrap js-modifier-wrap">
    <div><button>l-row--side-padding</button></div>
    <div><button>l-row--vertical-margins</button></div>
    <div><button>l-row--no-vertical-padding</button></div>
    <div><button data-target="l-col-33 > div">u-sg-col-padding</button></div>
</div>

To add vertical margins to the grid, use the modifier <code>'l-row--vertical-margins'</code>.

```html_example
<div class="l-row l-row--vertical-margins l-row--no-vertical-padding">
    <div class="l-col-33"><div class="u-sg-col-padding">l-col-33</div></div>
    <div class="l-col-33"><div class="u-sg-col-padding">l-col-33</div></div>
    <div class="l-col-33"><div class="u-sg-col-padding">l-col-33</div></div>
</div>
```


*/


/*doc
---
title: Mobile first responsive grid
name: mobile-first-responsive-grid
category: Grid system
---


Columns can be the following widths: <b>100%, 75%, 66%, 60%, 50%, 40%, 33%, 25%</b>.

Responsive changes are made by using classes which indicate a responsive breakpoint and a column width. For example <code>'.l-col-m-50'</code> will cause a column to change from 100% width (the default width for all columns) at the medium breakpoint (and upwards - mobile first) to 50% width.</p>

Reposnive breakpoints are as follows

<pre class="c-sg-code-block-light">
xs:  540px;
s:   800px;
m:   980px;
l:   1080px;
xl:  1200px;
xxl: 1440px;</pre>

In the exmaple below all columns will be <b>100%</b> width (default column width) until medium breakpoint.<br>
Then the first two columns become <b>50%</b> width and the last column becomes <b>100%</b> width.<br>
Note how we dont need to use the class <code>l-col-100</code> at all, just the responsive class <code>l-col-m-50</code>, becuase all columns are default to 100% width. This makes for much cleaner markup.

```html_example
<div class="l-row l-row--side-padding">
    <div class="l-col-m-50 u-sg-col"> l-col-m-50 </div>
    <div class="l-col-m-50 u-sg-col"> l-col-m-50 </div>
    <div class="l-col-m-100 u-sg-col"> l-col-m-100 </div>
</div>
```
<div class="c-sg-modifier-wrap js-modifier-wrap">
    <div><button>l-row--side-padding</button></div>
    <div><button>l-row--vertical-margins</button></div>
    <div><button>l-row--no-vertical-padding</button></div>
    <div><button data-target="l-row > div">u-sg-col</button></div>
</div>


Another example in which all columns will be <b>33%</b> width until medium breakpoint<br>
Then the first two columns become <b>50%</b> width and the last column becomes <b>100%</b> width.<br>
This offers a lot of flexibilty in creating grid layouts.

```html_example
<div class="l-row l-row--side-padding">
    <div class="l-col-33 l-col-m-50 u-sg-col"> l-col-33, l-col-m-50</div>
    <div class="l-col-33 l-col-m-50 u-sg-col"> l-col-33, l-col-m-50 </div>
    <div class="l-col-33 l-col-m-100 u-sg-col"> l-col-33, l-col-m-100 </div>
</div>
```
<div class="c-sg-modifier-wrap js-modifier-wrap">
    <div><button>l-row--side-padding</button></div>
    <div><button>l-row--vertical-margins</button></div>
    <div><button>l-row--no-vertical-padding</button></div>
    <div><button data-target="l-col-33">u-sg-col</button></div>
</div>

You dont necessarily need to wrap a <code>'.l-row'</code> around each presentational row. You can stack them up to make more complex layouts:

```html_example
<div class="l-row l-row--side-padding">
    <div class="l-col-33 l-col-m-50 u-sg-col"> l-col-33, l-col-m-50 </div>
    <div class="l-col-33 l-col-m-50 u-sg-col"> l-col-33, l-col-m-50 </div>
    <div class="l-col-33 l-col-m-100 u-sg-col"> l-col-33, l-col-m-100 </div>

    <div class="l-col-25 l-col-m-50 u-sg-col"> l-col-25, l-col-m-50 </div>
    <div class="l-col-25 l-col-m-50 u-sg-col"> l-col-25, l-col-m-50 </div>
    <div class="l-col-25 l-col-m-100 u-sg-col"> l-col-25, l-col-m-100 </div>
    <div class="l-col-25 l-col-m-100 u-sg-col"> l-col-25, l-col-m-100 </div>
</div>
```
<div class="c-sg-modifier-wrap js-modifier-wrap">
    <div><button>l-row--side-padding</button></div>
    <div><button>l-row--vertical-margins</button></div>
    <div><button>l-row--no-vertical-padding</button></div>
    <div><button data-target="l-row > div">u-sg-col</button></div>
</div>

Same as above but with spacing

```html_example
<div class="l-row l-row--side-padding">
    <div class="l-col-33 l-col-m-50"><div class="u-sg-col-padding"> l-col-33, l-col-m-50 </div></div>
    <div class="l-col-33 l-col-m-50"><div class="u-sg-col-padding"> l-col-33, l-col-m-50 </div></div>
    <div class="l-col-33 l-col-m-100"><div class="u-sg-col-padding"> l-col-33, l-col-m-100 </div></div>

    <div class="l-col-25 l-col-m-50"><div class="u-sg-col-padding"> l-col-25, l-col-m-50 </div></div>
    <div class="l-col-25 l-col-m-50"><div class="u-sg-col-padding"> l-col-25, l-col-m-50 </div></div>
    <div class="l-col-25 l-col-m-100"><div class="u-sg-col-padding"> l-col-25, l-col-m-100 </div></div>
    <div class="l-col-25 l-col-m-100"><div class="u-sg-col-padding"> l-col-25, l-col-m-100 </div></div>
</div>
```
<div class="c-sg-modifier-wrap js-modifier-wrap">
    <div><button>l-row--side-padding</button></div>
    <div><button>l-row--vertical-margins</button></div>
    <div><button>l-row--no-vertical-padding</button></div>
    <div><button data-target="l-row > div > div">u-sg-col-padding</button></div>
</div>

*/



/*doc
---
title: Responsive form grid example (using wip inputs)
name: responsive-form-grid-example
category: Grid system
---

```html_example
<div class="u-white-box">
    <div class="l-row l-row--no-vertical-padding">
        <div class="l-col-m-50">
            <label class="c-input js-input-validation">
                <span class="c-input__label">First name</span>
                <input name="Name" value="" type="text" id="test" class="c-input__input"
                data-v-input-min="1"
                data-v-input-max="100"
                data-v-type="letters"
                data-v-hint="Please fill this in"
                data-v-message="Please enter a valid name"
                required>
                <span class="c-input__validation-hint"></span>
                <span class="c-input__validation-message"></span>
            </label>
        </div>

        <div class="l-col-m-50">
            <div class="c-input-group c-input-group--width-50-50">
                <label class="c-input js-input-validation">
                    <span class="c-input__label">First name</span>
                    <input name="Name" value="" type="text" id="test" class="c-input__input"
                    data-v-input-min="1"
                    data-v-input-max="100"
                    data-v-type="letters"
                    data-v-hint="Please fill this in"
                    data-v-message="Please enter a valid name"
                    required>
                    <span class="c-input__validation-hint"></span>
                    <span class="c-input__validation-message"></span>
                </label>
                <label class="c-input js-input-validation">
                    <span class="c-input__label">Last Name</span>
                    <input name="LastName" value="" type="text" id="test" class="c-input__input"
                    data-v-input-min="1"
                    data-v-input-max="100"
                    data-v-type="letters"
                    data-v-hint="Please fill this in"
                    data-v-message="Please enter a valid name"
                    required>
                    <span class="c-input__validation-hint"></span>
                    <span class="c-input__validation-message"></span>
                </label>
            </div>
        </div>

        <div class="l-col-m-50">
            <label class="c-input c-input--dropdown js-dropdown-input">
                <span class="c-input__label">How often</span>
                <span class="c-input__dropdown-text">
                    <span>filler</span>
                </span>
                <select name="gender" required="" class="c-input__dropdown" id="test3">
                    <option selected="" disabled="" class="c-input__hidden-option"></option>
                    <option disabled="">Please pick one</option>
                    <option value="M">Male</option>
                    <option value="F">Female</option>
                </select>
                <span class="c-input__validation-hint"></span>
                <span class="c-input__validation-message"></span>
            </label>
        </div>

        <div class="l-col-m-50">
            <label class="c-input js-input-validation">
                <span class="c-input__label">First name</span>
                <input name="Name" value="" type="text" id="test" class="c-input__input"
                data-v-input-min="1"
                data-v-input-max="100"
                data-v-type="letters"
                data-v-hint="Please fill this in"
                data-v-message="Please enter a valid name"
                required>
                <span class="c-input__validation-hint"></span>
                <span class="c-input__validation-message"></span>
            </label>
        </div>
    </div>
    <div class="c-checkbox-radio manAddCheck">
        <input class="c-checkbox-radio__input showManualAddress" checked="" type="checkbox" name="radio" id="manuallyAddAddress2">
        <label class="c-checkbox-radio__label" for="manuallyAddAddress2">
            <div class="c-checkbox-radio__label-indicator"></div>
            <div class="c-checkbox-radio__label-text">Manually Enter Address</div>
        </label>
    </div>

    <div class="c-toggle-switch ">
        <input id="signup2" type="checkbox" class="c-toggle-switch__input" />
        <label for="signup2" class="c-toggle-switch__label">
            <span class="c-toggle-switch__text">Create standing order for this recipient</span>
            <span class="c-toggle-switch__label-inner">
            </span>
        </label>
    </div>
    <button class="c-btn c-btn--4 c-btn--mobile-full-width u-before-icon-cancel u-margin-bottom-xs">Cancel</button>
    <button class="c-btn c-btn--2 c-btn--mobile-full-width c-btn--float-right u-before-icon-arrow-right u-margin-bottom-0">Review</button>

</div>

```

*/


/*doc
---
title: Common base page layout
name: q-common-base-page-layout
category: Grid system
---

```html_example
<div class="l-content-width">
        <div class="l-row u-padding-top-bottom-l">
            <div class="l-col-m-40 l-col-l-33">
                <h1 class="u-color-1">Dashboard</h1>

                <div class="c-info-text">
                    <h4 class="c-info-text__title">
                        <span class="u-before-icon-info-fill c-info-text__icon"></span>
                        Payment Information
                    </h4>
                    <p class="c-info-text__copy">Make sure you're in the know by reading through our payment information and terms.</p>
                </div>
            </div>
            <div class="l-col-m-60 u-float-right">
                        <label for="sortCode" class=" input-radius-zero">
                            <span class="label">Sort Code</span>
                            <input
                                id="sortCode"
                                class="changeable validateInput numberOnly input-radius-zero js-modulus-check tt-recipient-sortcode"
                                name="toSortCode"
                                type="text"
                                value=""
                                maxlength="8"
                                max="999999"
                                autocomplete="off"
                                data-type="regMatch"
                                data-reg-match="sortCode"
                                data-input-hint="A valid Sort Code will be 6 numbers"
                                required
                            />
                        </label>
                            <label class="datePickerLabel showDay datepicker--radius-zero tt-standing-order-end-date">
                                <span class="label">Last payment date <sup>(optional)</sup></span>
                                <input
                                        id="nextPaymentDate"
                                        class="secondPaymentDate datePicker future"
                                        name="endDate"
                                        type="date"
                                        value=""
                                        data-history="future"
                                        data-year-limit="5"
                                        data-input-hint="This date must be after your first payment"
                                        data-required="no"
                                />
                            </label>
                        <button type="button" class="c-btn c-btn--4 c-btn--mobile-full-width u-before-icon-cancel u-margin-bottom-zero">Cancel</button>
                        <button type="submit" class="c-btn c-btn--2 c-btn--mobile-full-width c-btn--float-right u-before-icon-arrow-right u-margin-bottom-zero">Submit</button>
            </div>
        </div>
</div>

```

*/
