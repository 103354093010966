.c-create-name {
    $class: c-create-name;

    &__input-holder {
        transition: opacity .3s ease-in-out 1.6s, background .3s ease-in-out;
        width: 100%;
        position: relative;
        display: inline-block;

        &::after {
            content: '\23CE';
            display: block;
            opacity: 0;
            position: absolute;
            right: $space-xxs;
            top: 50%;
            transform: translateY(-50%);
            color: $color-grey-dark;
            font-size: 24px;
            transition: opacity .3s ease-in-out;
        }
    }

        &__input {
            outline: none;
            display: block;
            width: 100%;
            transition: all .2s ease-in-out;
            height: auto;
            padding: 0;
            @include title-text;
            margin-bottom: 0;
            border: 0;
            border-radius: 0;
            color: $color-1;
            background-color: transparent;
            @include font-heavy;

            &:hover {
                box-shadow: inset 0 -2px 0 0 $color-grey-light;
            }

            &::-webkit-input-placeholder {
                color: $color-1;
            }

            &:-moz-placeholder {
                color: $color-1;
                opacity: 1;
            }

            &:-ms-input-placeholder {
                color: $color-1;
            }
        }

    &--create {
        background-color: transparent;
        box-shadow: inset 0 -2px 0 0 $color-grey-light;

        &::after {
            opacity: 1;
        }
    }

    @media (min-width: $s_bp) {
        &__input {
            text-align: left;
        }
    }

    @media (min-width: $m_bp) {
        &__input {
            margin-bottom: 0;
        }
    }
}

/*doc
---
title: create name
name: create-name
category: Components
---

```html_example
<div class="c-create-name js-create-name">

    <div class="c-create-name__input-holder js-name-input-holder">
        <input type="text" class="c-create-name__input js-custom-name-input" value="Your" maxlength="12" />
    </div>

</div>

<h1 class="u-heading-secondary u-color-1 u-text-align-left">new current account</h1>

```
<div class="c-sg-modifier-wrap js-modifier-wrap">
    <div>
        <button data-target="c-create-name__input-holder">c-create-name--create</button>
    </div>
    <div>
        <button data-target="c-create-name__input-holder">c-create-name--created</button>
    </div>
</div>
*/
