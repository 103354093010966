// Partials are hidden but will be commented back in when
// they are added as the refactor goes on

/*------------------------------------*\
    Settings & Tools
\*------------------------------------*/
@import "settings.variables";
@import "tools.functions";
@import "tools.mixins";

/*------------------------------------*\
    Generic
\*------------------------------------*/
@import "generic.normalize";
@import "generic.box-sizing";
// @import "generic.reset";
// @import "generic.shared";

/*------------------------------------*\
    Elements
\*------------------------------------*/
@import "elements/elements.button";
@import "elements/elements.fonts";
@import "elements/elements.hr";
@import "elements/elements.html-body";
@import "elements/elements.img";
@import "elements/elements.lists";
// @import "elements/elements.links";
// @import "elements/elements.images";
// @import "elements/elements.quotes";
// @import "elements/elements.tables";

/*------------------------------------*\
    Layout
\*------------------------------------*/
@import "layout.wrappers";
@import "layout.grid-system";
@import "layout.section";
@import "layout.sidebar";

/*------------------------------------*\
    Animations
\*------------------------------------*/
@import "animations.general";

/*------------------------------------*\
    Components
\*------------------------------------*/
@import "components/components.accordion-block";
@import "components/components.account-balance";
@import "components/components.account-listing";
@import "components/components.accounts-sidebar";
@import "components/components.app-badges";
@import "components/components.bottom-nav";
@import "components/components.breadcrumb";
@import "components/components.bullet-list";
@import "components/components.button-group";
@import "components/components.buttons";
@import "components/components.card";
@import "components/components.checkbox-radio";
@import "components/components.create-name";
@import "components/components.date-picker";
@import "components/components.dot-dot-loans-banner";
@import "components/components.drag-file-upload";
@import "components/components.feature-animations";
@import "components/components.fees-table";
@import "components/components.full-width-box";
@import "components/components.home-image-animation";
@import "components/components.hr";
@import "components/components.icon";
@import "components/components.icon-tab-system";
@import "components/components.image-bg";
@import "components/components.input-datalist";
@import "components/components.input";
@import "components/components.limits-table";
@import "components/components.list";
@import "components/components.loan-calculator";
@import "components/components.message";
@import "components/components.notification";
@import "components/components.numbered-list";
@import "components/components.offset-box";
@import "components/components.payment-card";
@import "components/components.percentage-bar";
@import "components/components.placeholder";
@import "components/components.product-card";
@import "components/components.product-price";
@import "components/components.product-selector";
@import "components/components.progress-circle";
@import "components/components.promo";
@import "components/components.range-slider";
@import "components/components.reveal-vertical";
@import "components/components.scroll-lock-animation";
@import "components/components.sidebar-content-header";
@import "components/components.site-footer";
@import "components/components.site-header";
@import "components/components.site-modal";
@import "components/components.slider-v2";
@import "components/components.sliding-panels";
@import "components/components.social-link";
@import "components/components.social-share";
@import "components/components.tab-system";
@import "components/components.text-hint";
@import "components/components.text-link";
@import "components/components.thumbnail";
@import "components/components.title-block";
@import "components/components.toggle-buttons";
@import "components/components.toggle-content";
@import "components/components.toggle-option";
@import "components/components.toggle-switch";
@import "components/components.u-points-chart";

/*------------------------------------*\
    Utilities & Fallbacks
\*------------------------------------*/
@import "utilities.general";
// @import "fallbacks.main";


/*------------------------------------*\
    Development helper buttons
\*------------------------------------*/
//.dev-helpers {
//    position: fixed;
//    z-index:99999;
//    top:0; left:0;
//    width: 100%;
//
//    button {
//        padding:1px;
//        float: left;
//        clear:none;
//        height: 30px;
//        margin-bottom: 0;
//    }
//}

// browsersync indicator placed at bottom
#__bs_notify__ {
    position: fixed;
    bottom:0 !important;
    top: auto !important;
    height: 50px;
    border-radius: 0 !important;
}

.smallRoundedLabel {
    border-radius: 22px;
    padding: 5px 6px;
    color: $white;
    display: inline-block;
    line-height: 2.5;
}

.greenbtn {
    color: #000;
    background-color: #DCEDCC;
    border: 1px solid #6EB52B;
    padding: 0 70px;

    &:hover {
        background-color: darken($color: #DCEDCC, $amount: 10);
        color: #000;
    }
}

.transparentbtn {
    color: #000;
    background-color: transparent;

    &:hover {
        color: #000;
        background-color: transparent;
    }
}

.c-text-blob {
    background-color: #fcded5;
    -moz-border-radius: 24px;
    border-radius: 24px;
    padding: 0 5px;

    color: #000;
    text-decoration: none;

    &:hover {
        color: #000;
        border: 1px solid #f2663b;
    }
}

.c-checkbox-radio {
    padding: 12px;

    &:nth-child(odd) {
        background: #f6f6f6;
        border-radius: 25px;
    }
}

// sticky columns
.allowedHeight {
    min-height: 1px;
}

.allowSticky {
    overflow: auto;
    padding-top: 10px;
    padding-bottom: 30px;
}