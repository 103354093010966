.l-sidebar-layout {
    $class: l-sidebar-layout;
    $sidebar-width: 400px;
    $transition-time: 0.5s;

    position: relative;
    flex-grow: 1;

    &__content,
    &__sidebar {
        position: absolute;
        top: 0;
        height: 100%;
        width: 100%;
        overflow-y: auto;
    }

    &__content {
        left: 0;
        visibility: hidden;
        z-index: 1;
        transform: translateX(100%);
        transition: transform $transition-time ease-in-out, visibility 0s linear $transition-time, z-index 0s linear $transition-time;
        background-color: $white;

        &.is-shown {
            visibility: visible;
            z-index: 2;
            transform: translateX(0%);
            transition: transform $transition-time ease-in-out;
        }
    }

        &__content-inner {
            height: 100%;
        }

            &__panels-container {
                position: relative;
                height: 100%;
                margin: auto;

                &--max-width-800 {
                    max-width: 800px;
                }
            }

                &__panel {
                    @include content-width;
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    opacity: 0;
                    height: 0;
                    overflow: hidden;

                    &.is-current {
                        opacity: 1;
                        height: auto;
                    }
                }

    &__sidebar {
        right: 0;
        z-index: 1;
        background-color: $color-grey-xxxlight;
    }

        &__sidebar-inner {
            height: 100%;
            margin: auto;
        }

    &--theme-reversed {
        .#{$class}__sidebar {
            background-color: $white;
        }
    }

    @media (min-width: $m_bp) {
        &__content {
            visibility: visible;
            z-index: 1;
            display: block;
            transform: none;
            transition: none;
            width: calc(100% - #{$sidebar-width});

            &.is-shown {
                z-index: 1;
                transform: none;
                transition: none;
            }
        }

            &__panel {
                transition: opacity $transition-time ease-in-out;

                &.is-permanent-desktop {
                    opacity: 1;
                    height: auto;
                }
            }

        &__sidebar,
        &__sidebar-inner {
            width: $sidebar-width;
        }

        &__sidebar {
            overflow-x: hidden;
        }

            &__sidebar-inner {
                margin: 0;
                margin-right: auto;
            }

        &--theme-reversed {
            .#{$class}__sidebar {
                background-color: $color-grey-xxxlight;
            }
        }

        &--sidebar-padding-bp-m {
            .#{$class}__sidebar-inner {
                padding-left: $space-l;
                padding-right: $space-l;
            }
        }
    }

    @media (min-width: $l_bp) {
        $sidebar-width: 480px;

        &__content {
            width: calc(100% - #{$sidebar-width});
        }

        &__sidebar,
        &__sidebar-inner {
            width: $sidebar-width;
        }
    }

    @media (min-width: $xxl_bp) {
        // These variables are used to keep the screen at a max width of xxl breakpoint, while keeping the page centred
        // Note - the variables share most of the same equation, but are repeated to avoid having a calc within a calc, which causes issues on IE11
        $outer-sidebar-width: calc(#{$sidebar-width} + ((100% - #{$xxl_bp}) / 2));
        $outer-content-width: calc(100% - (#{$sidebar-width} + ((100% - #{$xxl_bp}) / 2)));

        &__sidebar {
            background-color: $white;
            box-shadow: inset 2px 0 0 $color-grey-xxxlight;
            width: $outer-sidebar-width;
        }

        &__content {
            width: $outer-content-width;
        }

            &__content-inner {
                width: calc(#{$xxl_bp} - #{$sidebar-width});
                margin-left: auto;
            }

        &--theme-reversed {
            .#{$class}__sidebar {
                background-color: $white;
            }
        }
    }
}
