.c-loan-calculator {
    $class: c-loan-calculator;

    &__calculator {
        max-width: 520px;
    }

    &__table {
        opacity: 1;
        transition: opacity 250ms;
    }

    &__row {
        display: flex;
        justify-content: flex-start;

        &--after-s-bp {
            display: block;

            .#{$class}__row-item {
                width: auto;
            }
        }
    }

        &__row-item {
            width: 33%;
        }

    &__hint {
        display: flex;
        color: $color-5;
        @include condensed-body-text;
        margin-top: $space-xxs;

        &::before {
            @include ffrees-icons-font;
            content: $icon-info-fill;
            margin-right: $space-xxs;
        }
    }

    &__separator {
        background-color: $color-grey-light;
    }

    &__label,
    &__total-label {
        @include font-heavy;
        margin-bottom: 0;
        color: $color-grey-dark;
    }

    &__total-label {
        color: $color-1;
    }

    &__value {
        @include section-title-text;
        @include font-heavy;
        display: block;

        &::after {
            content: $zero-width-space;
        }
    }

        &__sign {
            font-size: 70%;
            line-height: 1;
        }

    &__total {
        color: $color-1;
        @include title-text;
        @include font-heavy;
        margin: 0;
    }

    &.is-loading {
        .#{$class}__table {
            opacity: 0.3;
        }
    }

    @media (min-width: $s_bp) {
        &__row {
            &--after-s-bp {
                display: flex;

                .#{$class}__row-item {
                    width: 33%;
                }
            }
        }
    }

    @media (min-width: $m_bp) {
        &__total {
            margin: 0;
        }

        &__row {
            justify-content: space-between;

            &--until-m-bp {
                display: block;

                .#{$class}__row-item {
                    width: auto;
                }
            }
        }

            &__row-item {
                width: auto;
            }
    }
}
