.c-card {
    $class: 'c-card';
    $transition: 300ms ease-in-out;
    $img-header-height: 100px;

    position: relative;
    display: block;
    color: inherit;
    font-weight: inherit;
    text-decoration: none;
    z-index: $z-base;

    &:hover,
    &:focus {
        color: inherit;
    }

    &__title {
        @include section-title-text();
    }

    &__image-header {
        position: relative;
        margin: $space-s;
    }

        &__header-image {
            display: block;
            margin: auto;
            max-height: $space-xxxxl * 1.5;
        }

    &--padding,
    &--padding-xs-bp,
    &--padding-less {
        padding: $space-xs;
    }

    &--raised {
        @include outer-shadow;
    }

    &--extra-raised {
        box-shadow: 5px 5px 30px rgba(0, 0, 0, 0.25);
    }

    &--white-bg {
        background: $white;
    }

    &--interactive {
        transition: transform $transition;

        &:hover,
        &:focus {
            transform: scale(1.08);
        }
    }

    &--interactive.#{$class}--raised {
        transition: transform $transition, box-shadow $transition;

        &:hover,
        &:focus {
            box-shadow: 1px 1px 8px 1px rgba(0, 0, 0, 0.3)
        }
    }


    @media (min-width: $xs_bp) {
        &__image-header {
            height: $img-header-height;
            margin: $space-l;
        }

            &__header-image {
                @include absolute-center('both');
                max-height: $img-header-height - $space-l;
            }

        &--full-height {
            height: 100%;
        }

        &--padding-xs-bp {
            padding: $space-xxl $space-xxxl;
        }
    }

    @media (min-width: $s_bp) {
        &__indent {
            padding-left: $space-xxxxl;
        }

        &__image-header {
            margin-top: 0;
        }

        &--padding {
            padding: $space-xxl $space-xxxl;
        }

        &--padding-less {
            padding: $space-l;
        }

        &--raised-s-bp {
            @include outer-shadow;
        }
    }
}

.borderRadius {
    border-radius: 10px;
}

/*doc
---
title: Card
name: card
category: Components
---

```html_example
<div class="c-card">
    <h2 class="c-card__title">Card title</h2>
    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed suscipit ullamcorper luctus. Curabitur fermentum mollis feugiat. Quisque placerat sem nec est cursus, quis convallis est fringilla. Sed ut libero massa. Sed at tincidunt magna. Nunc ex sem, imperdiet vitae placerat sed, luctus ac ligula. Proin facilisis ornare blandit. Nunc interdum eros a maximus pharetra.</p>
    <div class="c-card__indent">
        <p>Curabitur eleifend faucibus quam nec gravida. In at justo ac dolor auctor faucibus id in mi. Donec et nunc ante. Nunc ultricies at enim nec lacinia. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Nulla sit amet neque lacus. Suspendisse potenti. Pellentesque non diam nulla.</p>
    </div>
</div>
```

<div class="c-sg-modifier-wrap js-modifier-wrap">
    <div>
        <button>c-card--padding</button>
        <button>c-card--padding-xs-bp</button>
        <button>c-card--padding-less</button>
    </div>
    <div>
        <button>c-card--full-height</button>
    </div>
    <div>
        <button>c-card--raised</button>
        <button>c-card--raised-s-bp</button>
        <button>c-card--extra-raised</button>
    </div>
    <div>
        <button>c-card--interactive</button>
    </div>
    <div>
        <button>c-card--white-bg</button>
    </div>
</div>

```html_example
<div class="l-row u-display-flex u-flex-wrap">
    <div class="l-col-xs-50 l-col-m-33">
        <div class="c-card">
            <div class="c-card__image-header">
                <img class="c-card__header-image" src="img/advice-and-support-logos/ND.png" alt="National Debtline"/>
            </div>
            <p>Free advice from expert debt advisors, plus step-by-step guides and answers to common questions on dealing with debt and managing your income. Call or chat online.</p>
        </div>
    </div>
    <div class="l-col-xs-50 l-col-m-33">
        <div class="c-card">
            <div class="c-card__image-header">
                <img class="c-card__header-image" src="img/advice-and-support-logos/CA.svg" alt="Citizens Advice"/>
            </div>
            <p>Local centres offering face-to-face advice on everything from managing overdue payments to applying for benefits, plus helpful online information and a 5-step process for dealing with debt. </p>
        </div>
    </div>
    <div class="l-col-xs-50 l-col-m-33">
        <div class="c-card">
            <div class="c-card__image-header">
                <img class="c-card__header-image" src="img/advice-and-support-logos/DA.png" alt="Debt Advice Foundation"/>
            </div>
            <p>UK charity offering a free and confidential phone line for advice on a full range of debt solutions, plus online tools like a debt analyser, budget planner and money-management guide.</p>
        </div>
    </div>
</div>
```

<div class="c-sg-modifier-wrap js-modifier-wrap">
    <div>
        <button data-target="c-card">c-card--padding</button>
        <button data-target="c-card">c-card--padding-xs-bp</button>
        <button data-target="c-card">c-card--padding-less</button>
    </div>
    <div>
        <button data-target="c-card">c-card--full-height</button>
    </div>
    <div>
        <button data-target="c-card">c-card--raised</button>
        <button data-target="c-card">c-card--raised-s-bp</button>
        <button data-target="c-card">c-card--extra-raised</button>
    </div>
    <div>
        <button data-target="c-card">c-card--interactive</button>
    </div>
    <div>
        <button data-target="c-card">c-card--white-bg</button>
    </div>
</div>
  */
