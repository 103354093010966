.c-breadcrumb {
    $class: c-breadcrumb;

    height: $space-xxxl;
    text-align: center;

    &__inner {
        @include content-width();
        @include max-content-width();

        position: fixed;
        top: $header-height-mobile;
        left: 0;
        width: 100%;
        background: $white;
        box-shadow: $header-shadow;
        font-size: 18px;
        z-index: $z-breadcrumb;

        .u-no-header & {
            top: 0;
        }

        .styleguide & {
            position: static;
        }
    }


        &__list {
            list-style: none;
        }

            &__item {
                position: relative;
                display: none;
                margin: 0;

                &::before {
                    @include ffrees-icons-font;
                    content: $icon-angle-left;
                    @include absolute-center('y');
                    left: 0;
                    pointer-events: none;
                }

                &::after {
                    @include ffrees-icons-font;
                    content: $icon-angle-right;
                    display: none;
                    color: $color-grey-8;
                    vertical-align: top;
                    margin: 0 4px;
                }

                &:nth-last-child(2) {
                    display: block;
                }
            }

                &__link {
                    display: block;
                    padding: $space-xs 0;
                    @include reset-link-styles();
                    font-weight: $font-weight-heading;
                }

    @media (min-width: $s_bp) {
        height: auto;
        text-align: left;

        &__inner {
            position: static;
            top: auto;
            width: auto;
            padding-top: 52px;
            box-shadow: none;
            @include meta-info-text();
        }

            &__list {
                padding: $space-s 0;
            }

                &__item {
                    display: inline-block;

                    &::before {
                        display: none;
                    }

                    &::after {
                        display: inline;
                    }

                    &:nth-last-child(2) {
                        display: inline-block;
                    }

                    &:last-child {
                        &::after {
                            display: none;
                        }
                    }
                }

                    &__link {
                        display: inline;
                        color: $color-grey-8;
                        padding: 0;

                        &--current {
                            color: $color-1;
                        }
                    }
    }
}

/*doc
---
title: breadcrumb
name: breadcrumb
category: Components
---

```html_example
<nav aria-label="breadcrumb" class="c-breadcrumb">
    <h2 class="u-visually-hidden">Breadcrumbs</h2>
    <div class="c-breadcrumb__inner">
        <ol itemscope itemtype="http://schema.org/BreadcrumbList" class="c-breadcrumb__list">
            <li itemprop="itemListElement" itemscope itemtype="http://schema.org/ListItem" class="c-breadcrumb__item">
                <meta itemprop="position" content="1"/>
                <a href="/u-credit/" itemprop="item" class="c-breadcrumb__link">
                    <span itemprop="name"><span class="u-u-in-copy">U</span> Credit</span>
                </a>
            </li>
            <li itemprop="itemListElement" itemscope itemtype="http://schema.org/ListItem" class="c-breadcrumb__item">
                <meta itemprop="position" content="2"/>
                <a href="/u-credit/short-term-loan/" itemprop="item" class="c-breadcrumb__link">
                    <span itemprop="name">Short Term Loan</span>
                </a>
            </li>
            <li itemprop="itemListElement" itemscope itemtype="http://schema.org/ListItem" class="c-breadcrumb__item">
                <meta itemprop="position" content="3"/>
                <a href="/u-credit/short-term-loan/loan-application/" itemprop="item" class="c-breadcrumb__link c-breadcrumb__link--current" aria-current="page">
                    <span itemprop="name">Loan Application</span>
                </a>
            </li>
        </ol>
    </div>
</nav>
```
*/