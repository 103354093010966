
.c-u-points-chart {
    overflow: hidden;

    &__holder {
        display: flex;
        overflow: hidden;
    }

    &__points-paid {
        width: 40%;
        padding: $space-xxs 0 0 $space-s;
        color: $white;
        background-color: $black;
    }

        &__sub-title {
            margin-bottom: $space-xxs;
            font-size: 13px;
            text-align: left;
        }

        &__paid-value {
            color: $white;
            margin: 0;
            font-size: 24px;
            text-align: left;
            display: inherit;
        }

    &__chart-holder {
        width: 26.6%;
        padding-top: $space-xxs;
        margin-right: 0;
        position: relative;
        z-index: 5;
        overflow: visible;
        background-color: $white;
        border: {
            width: 1px 0;
            style: solid;
            color: $black;
        }
    }

        &__chart {
            margin: 0 auto $space-xs;
            display: block;
        }

    &__chart-values {
        width: 33.3%;
        padding: $space-xxs $space-xs 0 $space-xxs;
        border: {
            width: 1px 1px 1px 0;
            style: solid;
            color: $black;
        }
        text-align: center;
        background-color: $white;
    }

        &__pending-points,
        &__confirmed-points {
            margin-bottom: $space-xxs;
            font-size: 13px;
            line-height: 1.2;
            text-align: left;
        }

        &__pending-points {
            color: $color-grey-dark;
        }

        &__confirmed-points {
            color: $color-2
        }

    @media (min-width: $m_bp) {

        &__sub-title,
        &__pending-points,
        &__confirmed-points {
            padding-left: 0;
            padding-right: $space-s;
        }
    }

    @media (min-width: $l_bp) {

        &__sub-title,
        &__pending-points,
        &__confirmed-points {
            font-size: 11px;
        }

        &__points-paid,
        &__chart-holder,
        &__chart-values {
            padding-top: $space-s;
        }
    }
}

/*doc
---
title: u points chart
name: u-points-chart
category: Components
---

Note - The points chart only appears when the graphs.js file is included, and it is not present in the styleguide

```html_example
<div class="c-u-points-chart">

    <div class="c-u-points-chart__holder">

        <div class="c-u-points-chart__points-paid">

            <p class="c-u-points-chart__sub-title">Total Points paid:</p>

            <p class="c-u-points-chart__paid-value">£0.00</p>

        </div>

        <div class="c-u-points-chart__chart-holder">

            <canvas class="c-u-points-chart__chart js-u-points-chart" width="65" height="65" data-green-value="0" data-gray-value="1" style="width: 65px; height: 65px;"></canvas>

        </div>

        <div class="c-u-points-chart__chart-values">

            <p class="c-u-points-chart__pending-points">Pending Points: 0.00</p>

            <p class="c-u-points-chart__confirmed-points">
                Confirmed Points to be paid on 20th Dec: 0.00
            </p>

        </div>

    </div>

</div>

```

*/
