.c-fees-table {
    $class: c-fees-table;

    margin-bottom: $space-l;

    &__row-outer {
        text-align: left;
        padding: $space-xxs $space-xs;
        background-color: $color-grey-5;

        &:nth-child(2n) {
            background-color: $white;
        }
    }

        &__row-inner {
            margin: auto;
            display: flex;
            align-items: center;
            max-width: 660px;
        }

            &__left-col {
                @include list-item-title;
                @include font-heavy;
                display: flex;
                align-items: center;
                margin: 0;
                padding-right: $space-xs;

                &:before {
                    width: $space-l;
                    text-align: center;
                    display: block;
                    font-size: $space-m;
                    color: $color-1;
                    margin-right: $space-xxs;
                }
            }

            &__right-col {
                @include list-item-text;
                margin: 0;
                margin-left: auto;
            }

    @media (min-width: $xs-bp) {
        &__row-outer {
            padding: $space-xs $space-xxl;
        }

            &__left-col:before {
                width: $space-xl;
                font-size: 22px;
                margin-right: $space-xs;
            }
    }

    @media (min-width: $m-bp) {
        &__row-outer {
            padding: $space-s 100px;
        }

            &__row-inner {
                max-width: none;
            }

                &__left-col {
                    &:before {
                        width: $space-xxl;
                        font-size: $space-l;
                        margin-right: $space-l;
                    }
                }
    }
}

/*doc
---
title: Fees table
name: fees-table
category: Components
---

```html_example
<div class="c-fees-table">
    <div class="c-fees-table__row-outer">
        <div class="c-fees-table__row-inner">
            <h3 class="c-fees-table__left-col u-before-icon-contactless">Contactless card</h3>
            <p class="c-fees-table__right-col">FREE</p>
        </div>
    </div>

    <div class="c-fees-table__row-outer">
        <div class="c-fees-table__row-inner">
            <h3 class="c-fees-table__left-col u-before-icon-card-back">Card transactions</h3>
            <p class="c-fees-table__right-col">FREE</p>
        </div>
    </div>

    <div class="c-fees-table__row-outer">
        <div class="c-fees-table__row-inner">
            <h3 class="c-fees-table__left-col u-before-icon-download">Transfers in</h3>
            <p class="c-fees-table__right-col">FREE</p>
        </div>
    </div>

    <div class="c-fees-table__row-outer">
        <div class="c-fees-table__row-inner">
            <h3 class="c-fees-table__left-col u-before-icon-paypoint">Paying in with PayPoint</h3>
            <p class="c-fees-table__right-col">3%</p>
        </div>
    </div>

    <div class="c-fees-table__row-outer">
        <div class="c-fees-table__row-inner">
            <h3 class="c-fees-table__left-col u-before-icon-globe">Transactions not in GBP (&pound;)</h3>
            <p class="c-fees-table__right-col">3%</p>
        </div>
    </div>

    <div class="c-fees-table__row-outer">
        <div class="c-fees-table__row-inner">
            <h3 class="c-fees-table__left-col u-before-icon-statements">Postal statements</h3>
            <p class="c-fees-table__right-col">&pound;2.50</p>
        </div>
    </div>

    <div class="c-fees-table__row-outer">
        <div class="c-fees-table__row-inner">
            <h3 class="c-fees-table__left-col u-before-icon-mastercard">Replacement card</h3>
            <p class="c-fees-table__right-col">&pound;2.50</p>
        </div>
    </div>
</div>

```

*/
