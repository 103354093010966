.c-social-link {
    width: 44px;
    height: 44px;
    margin-right: $space-xxs;
    display: inline-block;
    text-align: center;
    color: $white;
    @include font-size(22px, 44px);
    background-color: $black;
    border-radius: 50%;
    transition: background .2s ease-in-out, color .2s ease-in-out;

    &:last-child {
        margin-right: 0;
    }

    &--white-circle {
        color: $color-1;
        background-color: $white;

        &:hover,
        &:focus,
        &:active {
            color: $black;
        }
    }

    &:hover,
    &:focus,
    &:active {
        color: $white;
        background-color: $color-1;
    }

    @media (min-width: $m_bp) {
        width: 53px;
        height: 53px;
        @include font-size(24px, 53px);
    }
}
