/*------------------------------------*\
    text hint
\*------------------------------------*/

.c-text-hint {
    $class: c-text-hint;
    $states: (
        success: (
            bg: $color-2,
            textColor: $white,
            icon: $icon-check
        ),
        info: (
            bg: $color-5,
            textColor: $white,
            icon: $icon-info-fill
        ),
        warning: (
            bg: $color-3,
            textColor: $black,
            icon: $icon-attention
        ),
        error: (
            bg: $color-4,
            textColor: $white,
            icon: $icon-attention
        )
    );

    @mixin icon-left-styles {
        padding: 0;
        padding-left: $space-xs;

        // icon
        &:before {
            top: 0;
            left: 0;
        }

        &[class*="-icon"] {
            padding-left: $space-xxl;

            &:after {
                left: 26px;
            }
        }
    }

    background: $color-grey-4;
    padding: $space-xs;
    margin-bottom: $base-spacing-unit;
    position: relative;
    text-align: center;

    *:last-child {
        margin-bottom: 0;
    }

    &:before {
        @include ffrees-icons-font;
        display: block;
        margin-bottom: $space-xxs;
    }

    @each $state, $map in $states {
        &--#{$state} {
            background: map-get($map, bg);
            color: map-get($map, textColor);

            .#{$class}__link {
                color: map-get($map, textColor);
            }
        }

        &--#{$state}-icon {
            &:before {
                content: map-get($map, icon)
            }
        }
    }

    &--bold-message {
        @include font-heavy;
        text-transform: uppercase;
    }

    &--inverse {
        text-align: left;
        background: none;
        color: inherit;
        @include icon-left-styles;

        &:before {
            position: absolute;
            font-size: 18px;
        }

        // line
        &:after {
            content: '';
            @include absolute-center('y');
            left: 0;
            width: 2px;
            border-radius: 1px;
            height: calc(100% - #{$space-xs});
            min-height: 22px;
            background-color: $color-grey-3;
        }

        @each $state, $map in $states {
            &.#{$class}--#{$state} {
                &:before {
                    color: map-get($map, bg);
                }

                &:after {
                    background-color: map-get($map, bg);
                }
            }
        }
    }

    &--full-width {
        width: 100%;
    }

    @media (min-width: 720px) {
        text-align: left;

        &:before {
            position: absolute;
            top: 50%;
            left: 15px;
            transform: translateY(-50%);
            margin-bottom: 0;
        }

        &[class*="-icon"] {
            padding-left: 40px;
        }

        &--inverse {
            &:before {
                top: 0;
                left: 0;
                transform: none;
            }
        }
    }

    @media (min-width: 900px) {
        padding: $space-s $space-m;

        &--inverse {
            @include icon-left-styles;
        }
    }
}

/*doc
---
title: Text hint
name: texthint
category: Components
---
```html_example_table
<div class="c-text-hint">
    <p>Make sure this is memorable - you’ll need it to make payments and account changes.</p>
</div>

<div class="c-text-hint c-text-hint--success">
    <p>Make sure this is memorable - you’ll need it to make payments and account changes.</p>
</div>

<div class="c-text-hint c-text-hint--success c-text-hint--success-icon">
    <p>Make sure this is memorable - you’ll need it to make payments and account changes.</p>
</div>

<div class="c-text-hint c-text-hint--info">
    <p>Make sure this is memorable - you’ll need it to make payments and account changes.</p>
</div>

<div class="c-text-hint c-text-hint--info c-text-hint--info-icon">
    <p>Make sure this is memorable - you’ll need it to make payments and account changes.</p>
</div>

<div class="c-text-hint c-text-hint--warning">
    <p>Make sure this is memorable - you’ll need it to make payments and account changes.</p>
</div>

<div class="c-text-hint c-text-hint--warning c-text-hint--warning-icon">
    <p>Make sure this is memorable - you’ll need it to make payments and account changes.</p>
</div>

<div class="c-text-hint c-text-hint--error">
    <p>Make sure this is memorable - you’ll need it to make payments and account changes.</p>
</div>

<div class="c-text-hint c-text-hint--error c-text-hint--error-icon">
    <p>Make sure this is memorable - you’ll need it to make payments and account changes.</p>
</div>
```
<div class="c-sg-modifier-wrap js-modifier-wrap">
    <div>
        <button>c-text-hint--bold-message</button>
    </div>
    <div>
        <button>c-text-hint--inverse</button>
    </div>
    <div>
        <button>c-text-hint--full-width</button>
    </div>
</div>
*/
