.c-toggle-option {
    $class: c-toggle-option;

    &__input {
        @include visually-hidden;
    }

    &__indicator {
        @include rounded-button-styles;
        min-width: 120px;
        cursor: pointer;
    }

    &__input {
        &:focus {
            + .#{$class}__indicator {
                @include outer-shadow-tight($color-1-dark, inset);
                animation: fadeOutButtonShadow 200ms;

                &.is-deselected {
                    @include outer-shadow-tight($black, inset);
                    animation: fadeOutButtonShadowDeselected 200ms;
                }
            }
        }

        &:checked {
            + .#{$class}__indicator {
                background-color: $color-1-mid;
            }
        }
    }
}

/*doc
---
title: toggle-option
name: input-toggle-option
category: Inputs
---

```html_example
<fieldset>
    <legend class="u-label">Where was the transaction made?</legend>
    <label class="c-toggle-option">
        <input type="radio" name="location" class="c-toggle-option__input">
        <div class="c-toggle-option__indicator">ATM</div>
    </label>
    <label class="c-toggle-option"><input type="radio" name="location" class="c-toggle-option__input">
        <div class="c-toggle-option__indicator">Point of sale / Online</div>
    </label>
</fieldset>
```
<div class="c-sg-modifier-wrap js-modifier-wrap">
    <div>
        <button data-target="c-toggle-option__indicator">is-selected</button>
        <button data-target="c-toggle-option__indicator">is-deselected</button>
        <button data-target="c-toggle-option__indicator">is-disabled</button>
    </div>
</div>
*/