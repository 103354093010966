@font-face {
    font-family: "uLogo";
    font-weight: normal;
    font-style: normal;
    src: url("../font/u-logo.eot");
    src: url("../font/u-logo.eot?#iefix") format("embedded-opentype"), url("../font/u-logo.woff") format("woff"), url("../font/u-logo.ttf") format("truetype"), url("../font/u-logo.svg#u-font") format("svg");
}

@font-face {
    font-family: "u-font";
    font-weight: normal;
    font-style: normal;
    src: url("../font/ufont2016.eot");
    src: url("../font/ufont2016.eot?#iefix") format("embedded-opentype"), url("../font/ufont2016.woff") format("woff"), url("../font/ufont2016.ttf") format("truetype"), url("../font/ufont2016.svg#u-font") format("svg");
}

@font-face {
    font-family: "ffreesicons";
    font-weight: normal;
    font-style: normal;
    src: url("../font/ffreesicons.eot");
    src: url("../font/ffreesicons.eot?#iefix") format("embedded-opentype"), url("../font/ffreesicons.woff") format("woff"), url("../font/ffreesicons.ttf") format("truetype"), url("../font/ffreesicons.svg#ffreesicons") format("svg");
}

// font mixins for cleaner font managment
@mixin u-font {
    font-family: 'u-font', sans-serif;
    font-weight: 300;
}

@mixin u-logo-font {
    font-family: 'uLogo', sans-serif;
    font-weight: 300;
}

@mixin ffrees-icons-font {
    font-family: 'ffreesicons', sans-serif;
    font-weight: 500;
    font-style: normal;
}

@mixin font-book {
    font-family: $main-font;
    font-weight: $font-weight-book;
}

@mixin font-medium {
    font-family: $main-font;
    font-weight: $font-weight-medium;
}

@mixin font-heavy {
    font-family: $main-font;
    font-weight: $font-weight-heavy;
}

@mixin font-extra-bold {
    font-family: $main-font;
    font-weight: $font-weight-extra-bold;
}

@mixin font-monospace {
    font-family: 'Courier New', monospace;
    font-weight: 700;
}

// font utilities
.u-u-font {
    @include u-font;
}

.u-u-font-large-center {
    @include u-font;
    vertical-align: middle;
    font-size: 120%;
}

body {
    -webkit-font-smoothing: antialiased;
    color: $black;
    @include font-medium;
    @include main-body-text;
}

p {
    margin: 0 0 $space-s;
}

a {
    transition: color 0.2s ease-in-out;
    cursor: pointer;
    @include font-heavy;
    color: black;
    text-decoration: none;

    &:hover,
    &:focus,
    &:active {
        outline: none;
        color: $color-1-dark;
    }
}

// title elements and classes
h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
    margin-bottom: $space-s;
}

.u-mega-title {
    display: block;
    margin-bottom: $space-l;
    color: $color-1;
    text-transform: uppercase;
    @include font-size(42px, 1);

    @media (min-width: $m_bp) {
        margin-bottom: $space-xxl;
        @include font-size(96px, 1);
    }
}

.u-super-title {
    display: block;
    margin-bottom: $space-l;
    color: $color-1;
    @include font-size(30px, 40px);

    @media (min-width: $m_bp) {
        margin-bottom: $space-xxl;
        @include font-size(85px, 109px);
    }
}

.u-title {
    color: $color-1;
    @include title-text;
}

.u-subtitle {
    display: block;
    @include subtitle-text;
}

.u-section-title {
    @include section-title-text;
}

.u-section-title-secondary,
.u-tabs-section-title {
    @include section-title-secondary;
}

.u-section-subtitle {
    @include section-title-secondary;
    padding-bottom: $space-xxs;
    border-bottom: 1px solid $color-grey-mid;
}

.u-section-intro {
    @include section-intro-text;
}

.u-label {
    @include label-text;
}

.u-footnote {
    display: block;
    @include footnote-text;
}

/*doc
---
title: Headings
name: headings
category: Typography
---
```html_example
<h1 class="u-mega-title">Mega Title</h1>
<h1 class="u-super-title">Super Title</h1>
<h1 class="u-title">Title</h1>
<h2 class="u-subtitle">Subtitle</h2>
<h3 class="u-section-title">Section Title</h3>
<h3 class="u-section-subtitle">Section Subtitle</h3>
<h4 class="u-section-intro">Section Intro</h4>
<p>Copy</p>
```
*/

/*doc
---
title: Body font
name: bodyfont
category: Typography
---
```html_example
<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Magni sunt dolores vitae libero quasi incidunt quas, quos, voluptates repellat facilis, optio, praesentium assumenda earum? Sit at maxime, necessitatibus voluptates. Ratione.</p>
<small class="u-footnote">Footnote</small>
```
*/
