/*------------------------------------*\
    account listing
\*------------------------------------*/
.c-account-listing {
    $class: c-account-listing;
    $listing-padding: $space-xxs;
    $info-spacing: 4px;
    $transition-time: 300ms;

    display: flex;
    align-items: flex-start;
    width: 100%;
    margin-bottom: $space-xs;

    &__block {
        color: $black;
        background-color: $white;
        border: 1px solid $color-grey-9;
        border-radius: $space-s;
        @include outer-shadow-tight;
        padding: 0;
        flex: 1 1 auto;
        transition: all $transition-time ease-in-out;
        min-width: 0;
        overflow: hidden;

        &:hover,
        &:focus,
        &:focus-within,
        &:active {
            border-color: $color-1;
            @include outer-shadow-tight($color-1-dark);
        }
    }

        &__message-wrap {
            height: 0;
            overflow: hidden;
            transition: height $transition-time ease-in-out;
            background-color: $color-2-mid;

            &--error {
                background-color: $color-4-light;
            }
        }

            &__message {
                padding: $listing-padding;
                height: 100%;
            }

        &__main-content {
            @include button-block-height;
            width: 100%;
            padding: $listing-padding;
            cursor: pointer;
            background-color: $white;
            transition: background-color $transition-time ease-in-out;

            &:hover,
            &:focus {
                background-color: $color-1-light;

                ~ .#{$class}__dropdown-content-wrap {
                    background-color: $color-1-light;
                }
            }
        }

            &__main-content-inner {
                display: flex;
                align-items: center;
                height: 100%;
            }

                &__info-wrap {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    height: 100%;
                    flex-grow: 1;
                    min-width: 0;
                    margin-right: $space-xxs;

                    &--no-direct-debit {
                        justify-content: center;

                        .#{$class}__details {
                            display: none;
                        }
                    }
                }

                    &__name {
                        @include main-button-text;
                        @include font-medium;
                        margin: 0;
                        text-align: left;
                        line-height: 1.2;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        overflow: hidden;
                    }

                    &__details {
                        color: $color-1;
                        margin: 0;
                        line-height: 1;
                        text-align: left;
                    }

                        &__account-number,
                        &__sort-code {
                            @include main-body-text;
                            @include font-medium;
                            display: inline-block;
                            margin: 0;
                            line-height: 1;
                        }

                        &__account-number {
                            padding-right: $info-spacing;
                        }

                        &__sort-code {
                            padding-left: $info-spacing;
                        }

                &__amount {
                    @include main-button-text;
                    @include font-medium;
                    margin: 0;
                }

        &__dropdown-content-wrap {
            height: 0;
            overflow: hidden;
            transition: height $transition-time ease-in-out, background-color $transition-time ease-in-out;
        }

            &__dropdown-hr,
            &__dropdown-content {
                opacity: 0;
                transition: opacity $transition-time ease-in-out;
            }

            &__dropdown-hr {
                padding: 0 $listing-padding $listing-padding;
            }

            &__dropdown-content {
                padding: 0 $listing-padding $listing-padding;
            }

    &__dropdown-button {
        @include button-styles;
        @include button-block-height;
        flex-shrink: 0;
        margin: 0;
        margin-left: $space-xxs;
        padding: 0;
        @include outer-shadow;
        color: $black;
        background-color: $color-grey-light;
        transition: margin $transition-time ease-in-out, background-color $transition-time ease-in-out, width $transition-time ease-in-out;
        overflow: hidden;

        &:hover {
            color: $black;
            background-color: $color-grey-mid;
        }

        &:focus {
            outline: 1px dotted $color-grey-dark;
        }

        &.is-disabled {
            &:hover {
                background-color: $color-grey-light;
            }
        }
    }

        &__dropdown-button-inner {
            padding: $space-xs;

            &::before {
                content: $icon-angle-down;
                @include ffrees-icons-font;
                font-size: 20px;
                display: inline-block;
                transition: transform $transition-time ease-in-out;
            }
        }

    &--dropdown-btn-hidden {
        .#{$class}__dropdown-button {
            margin-left: 0;
            width: 0;
        }
    }

    &--with-message {
        .#{$class}__message-wrap {
            @include button-block-height;
        }

        .#{$class}__dropdown-button {
            margin-top: $button-block-height-mobile;
        }
    }

    &.is-expanded {
        .#{$class}__dropdown-content-wrap {
            height: auto;
            overflow: visible;
        }

            .#{$class}__dropdown-hr,
            .#{$class}__dropdown-content {
                opacity: 1;
            }

            .#{$class}__dropdown-content {
                transition: opacity $transition-time ease-in-out $transition-time;
            }

        .#{$class}__dropdown-button-inner::before {
            transform: rotate(180deg);
        }
    }

    @media (min-width: $xs_bp) {
        $info-spacing: $space-xxs;

        &__account-number {
            padding-right: $info-spacing;
        }

        &__sort-code {
            padding-left: $info-spacing;
        }
    }

    @media (min-width: $m_bp) {
        $listing-padding: $space-xs $space-xxs;
        $info-spacing: 4px;

        &__block.is-selected-desktop {
            background-color: $color-1-light;
            border-color: $color-1;
            @include outer-shadow-tight($color-1-dark);

            .#{$class}__main-content {
                background-color: $color-1-light;
            }
        }

            &__message {
                padding: $listing-padding;
            }

            &__main-content {
                padding: $listing-padding;
            }

                &__name {
                    line-height: 1.2;
                }

                &__account-number,
                &__sort-code {
                    line-height: 1;
                }

                &__account-number {
                    padding-right: $info-spacing;
                }

                &__sort-code {
                    padding-left: $info-spacing;
                }

            &__dropdown-hr {
                padding: 0 $listing-padding $listing-padding;
            }

            &__dropdown-content {
                padding: 0 $listing-padding $listing-padding;
            }

        &__dropdown-button-inner {
            padding: $space-xs;
        }

        &--with-message {
            .#{$class}__dropdown-button {
                margin-top: $button-block-height-desktop;
            }
        }
    }

    @media (min-width: $l_bp) {
        $listing-padding: $space-xs;
        $info-spacing: $space-xs;

        &__message {
            padding: $listing-padding;
        }

        &__main-content {
            padding: $listing-padding;
        }

            &__account-number {
                padding-right: $info-spacing;
            }

            &__sort-code {
                padding-left: $info-spacing;
            }

        &__dropdown-hr {
            padding: 0 $listing-padding $listing-padding;
        }

        &__dropdown-content {
            padding: 0 $listing-padding $listing-padding;
        }

        &__dropdown-button-inner {
            padding: $space-s;
        }
    }
}

/*doc
---
title: account listing
name: account-listing
category: Components
---

```html_example
<div class="c-account-listing js-account-listing">
    <div class="c-account-listing__block">
        <div class="c-account-listing__message-wrap">
            <div class="c-account-listing__message">
                Message text here
            </div>
        </div>
        <button class="c-account-listing__main-content" type="button">
            <div class="c-account-listing__main-content-inner">
                <div class="c-account-listing__info-wrap">
                    <h3 class="c-account-listing__name">
                        Main Account
                    </h3>
                    <div class="c-account-listing__details">
                        <h5 class="c-account-listing__account-number">12345678</h5>|<h5 class="c-account-listing__sort-code">21-45-56</h5>
                    </div>
                </div>
                <h4 class="c-account-listing__amount">
                    £80.00
                </h4>
            </div>
        </button>
        <div class="c-account-listing__dropdown-content-wrap js-dropdown-content-wrap">
            <div>
                <div class="c-account-listing__dropdown-hr">
                    <hr class="c-hr c-hr--full-width c-hr--margin-top-0 c-hr--margin-bottom-0" />
                </div>
                <div class="c-account-listing__dropdown-content">
                    Test dropdown content
                </div>
            </div>
        </div>
    </div>
    <button class="c-account-listing__dropdown-button js-listing-dropdown-btn">
        <div class="c-account-listing__dropdown-button-inner js-listing-dropdown-btn-inner"></div>
    </button>
</div>

```
<div class="c-sg-modifier-wrap js-modifier-wrap">
    <div>
        <button data-target="c-account-listing__block">is-selected-desktop</button>
    </div>
    <div>
        <button>is-expanded</button>
    </div>
    <div>
        <button>c-account-listing--dropdown-btn-hidden</button>
    </div>
    <div>
        <button>c-account-listing--with-message</button>
    </div>
    <div>
        <button data-target="c-account-listing__message-wrap">c-account-listing__message-wrap--error</button>
    </div>
    <div>
        <button data-target="c-account-listing__info-wrap">c-account-listing__info-wrap--no-direct-debit</button>
    </div>
</div>
*/