/*------------------------------------*\
    tab system
\*------------------------------------*/
@mixin tab-styles {
    @include font-heavy;
    @include font-size(16px, 1.3);
    height: auto;
    min-height: 110px;
    margin: 0 $space-xs 0 0;
    padding: $space-m 0;
    position: relative;
    text-align: center;
    color: $black;
    background: $color-grey-xlight;
    border: {
        width: 2px;
        style: solid;
        color: $color-grey-xlight $color-grey-xlight $color-grey-mid;
    }
    border-bottom: 0;
    flex-grow: 1;
    z-index: 1;

    &:after {
        content: '';
        height: 2px;
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
        background-color: $border-grey-tab;
    }

    &:last-of-type {
        margin: 0;
    }

    &:hover,
    &:focus {
        background: $color-grey-6;
        color: $black;
        border-color: $color-grey-mid;
    }

    &.is-active {
        background: $white;
        color: $black;
        border-color: $color-grey-mid;
        z-index: 2;

        &:after {
            bottom: -2px;
            background-color: $white;
        }

        &:hover,
        &:focus {
            background: $white;
            cursor: default;
        }
    }
}

@mixin tab-nav-styles {
    display: flex;
    flex-direction: row;
}

@mixin tab-content-mobile-styles {
    width: 100%;
    margin-top: -2px;
    padding: $space-xs;
    display: block;
    left: 0;
    border: 2px solid $color-grey-mid;
    z-index: 1;
    display: none;

    &.is-active {
        display: block;
    }
}

@mixin tab-content-desktop-styles {
    padding: $space-xl;
    border: 2px solid $color-grey-mid;
    position: relative;
}

.c-tab-system {
    $class: c-tab-system;

    margin-bottom: $space-l;

    &__tab-nav {
        @include tab-nav-styles;
    }

    &__tab {
        @include tab-styles;
    }

    &__accordion-btn {
        @include button-styles;
        color: $white;
        display: block;
        width: 100%;
        height: auto;
        margin: 0;
        position: relative;
        background: $black;
        border-top: 2px solid $white;

        &:hover {
            background: $black;
        }

        &:after {
            @include ffrees-icons-font;
            transition: rotate .3s linear;
            content: $icon-small-arrow-down;
            padding: 0 $space-xxs;
            margin: 0;
            position: absolute;
            right: 0;
        }

        &:first-child {
            border-top: 0;
        }

        &.is-active {
            background: $color-1;

            &:after {
                transform: rotate(180deg);
            }
        }
    }

    &__accordion-content-holder {
        transition: height .83s ease-in-out;
        width: 100%;
        height: 0;
        overflow: hidden;
        position: relative;
    }

    &__tab-icon {
        @include font-size(18px, 24px);
        display: block;
        margin-bottom: $space-xxs;
    }

    &__tab-content-wrap {
        background: $white;
    }

    &__tab-content {
        @include tab-content-mobile-styles;
        position: absolute;
        display: block;
    }

    &__tab-nav {
        display: none;
    }

    @media (min-width: 600px) {
        &__tab {
            @include font-size(16px, 1.3);
        }

        &__accordion-btn {
            display: none;
        }

        &__accordion-content-holder {
            height: auto;
            overflow: visible;
        }

        &__tab-nav {
            display: flex;
            flex-direction: row;
        }

        &__tab-nav-inner {
            display: flex;
            flex-direction: row;
        }

        &__tab-content {
            @include tab-content-desktop-styles;
            display: none;

            &.is-active {
                display: block;
            }
        }

        &__accordion-btn {
            display: none;
        }
    }
}


/*doc
---
title: Tab system
name: tab-system
category: Components
---

When using multiple tab systems on a page, use unique id, data-hash and data-tab/data-tab-content (do not use an id that contains another id as part of it e.g. 'tab-system' and 'tab-system2'). <br>
The <code>c-tab-system__tab-icon</code> element is optional and can be removed.

```html_example
<div data-parent-classes="u-sg-vlightgrey-bg" id="tab-one" class="c-tab-system js-tab-wrapper">
    <nav class="c-tab-system__tab-nav js-tab-nav">
        <button class="c-tab-system__tab js-function-btn" data-hash="tab-name-1" data-tab="1">
            <span class="c-tab-system__tab-icon u-before-icon-clock"></span> Future
            <br> Payments
        </button>
        <button class="c-tab-system__tab js-function-btn" data-hash="tab-name-2" data-tab="2">
            <span class="c-tab-system__tab-icon u-before-icon-direct-debit"></span> Direct
            <br> Debits
        </button>
        <button class="c-tab-system__tab js-function-btn" data-hash="tab-name-3" data-tab="3">
            <span class="c-tab-system__tab-icon u-before-icon-user-add"></span> Saved
            <br> Recipients
        </button>
    </nav>
    <div class="c-tab-system__tab-content-wrap">
        <button class="c-tab-system__accordion-btn js-function-btn" data-hash="tab-name-1" data-tab="1">Future Payments</button>
        <div class="c-tab-system__accordion-content-holder js-accordion-holder">
            <div class="c-tab-system__tab-content js-tab-content" data-tab-content="1">
                Pellentesque in ipsum id orci porta dapibus. Cras ultricies ligula sed magna dictum porta. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Donec velit neque, auctor sit amet aliquam vel, ullamcorper sit amet ligula.
            </div>
        </div>
        <button class="c-tab-system__accordion-btn js-function-btn" data-hash="tab-name-2" data-tab="2">Direct Debits</button>
        <div class="c-tab-system__accordion-content-holder js-accordion-holder">
            <div class="c-tab-system__tab-content js-tab-content" data-tab-content="2">
                quidem omnis nostrum reprehenderit nam quo, sit corporis provident facilis, doloremque repellat adipisci soluta. Aspernatur hic in, recusandae minus.
            </div>
        </div>
        <button class="c-tab-system__accordion-btn js-function-btn" data-hash="tab-name-3" data-tab="3">Saved Recipients</button>
        <div class="c-tab-system__accordion-content-holder js-accordion-holder">
            <div class="c-tab-system__tab-content js-tab-content" data-tab-content="3">
                doloremque repellat adipisci soluta. Aspernatur hic in, recusandae minus.
            </div>
        </div>
    </div>
</div>
```

*/
