.c-product-card {
    $class: c-product-card;
    $header-padding: $space-xxs;

    margin-bottom: $space-xs;
    background-color: $white;
    @include outer-shadow;
    text-align: center;
    transition: all .3s ease-in-out;
    outline: none;

    &__flag {
        @include section-intro-text;
        @include font-heavy;
        color: $white;
        background-color: $color-1;
        text-align: center;
        margin: 0;
        padding: $space-xxs;
        transition: color 0.3s ease-in-out;
    }

    &__outer-wrap {
        padding: $space-xxs;
    }

        &__header {
            position: relative;
            padding: $header-padding;
            cursor: pointer;

            &:after {
                position: absolute;
                top: 50%;
                right: $header-padding;
                content: $icon-angle-down;
                @include ffrees-icons-font;
                font-size: 32px;
                color: $color-1;
                transition: all 0.3s ease-in-out;
                transform: translateY(-50%);
            }
        }

        &__info-wrapper {
            height: 0;
            overflow: hidden;
            transition: height 0.3s ease-in-out;
            margin-bottom: $space-xxs;
        }

            &__info {
                padding: $space-xxs $space-xs;
            }

                &__info-entry {
                    display: flex;
                    align-items: center;
                    min-height: $space-xl;
                }

                    &__entry-title {
                        display: flex;
                        align-items: center;
                        font-size: 12px;
                        @include font-heavy;
                        margin: 0;
                        padding-right: $space-s;
                        text-align: left;

                        &:before {
                            color: $color-1;
                            font-size: 18px;
                            margin-right: $space-xxs;
                        }
                    }

                    &__entry-value {
                        font-size: 12px;
                        line-height: 16px;
                        margin: 0;
                        margin-left: auto;
                        text-align: right;
                    }

                        &__entry-value-sub-text {
                            @include footnote-text;
                            text-align: right;
                            margin: 0;
                            color: $color-grey-8;
                        }

                &__dormancy-info {
                    @include footnote-text;
                    color: $color-grey-8;
                    text-align: center;
                    margin: 0;
                    margin-top: $space-xxs;
                }

    &.is-open {
        .#{$class}__header:after {
            transform: translateY(-50%) rotate(180deg);
        }

        .#{$class}__info-wrapper {
            height: auto;
        }
    }

    @media (min-width: $xs-bp) {
        $header-padding: $space-xs;

        &__flag {
            padding: $space-xs;
        }

        &__header {
            padding: $header-padding;

            &:after {
                font-size: 32px;
            }
        }

        &__info {
            padding-left: $space-l;
            padding-right: $space-l;
        }

            &__info-entry {
                min-height: 36px;
            }

                &__entry-title {
                    @include list-item-font-mobile();

                    &:before {
                        font-size: 22px;
                    }
                }

                &__entry-value {
                    @include list-item-font-mobile();
                }
    }

    @media (min-width: $m-bp) {
        $header-padding: $space-xs;

        margin: 0;
        box-shadow: none;
        transition: all .3s ease-in-out;

        &:hover,
        &:focus {
            z-index: 10;
            transform: scale(1.05, 1.05);
            @include outer-shadow-tight;
            border-color: transparent;
        }

        &__flag {
            @include visually-hidden;
        }

        &__outer-wrap {
            padding: $space-xs;
        }

            &__header {
                padding: $header-padding 0;
                margin-bottom: $space-xs;
                cursor: auto;

                &:after {
                    content: '';
                }
            }

            &__info-wrapper {
                height: auto;
                margin-bottom: $space-xs;
            }

                &__info {
                    padding: 0;
                }

                    &__info-entry {
                        min-height: 70px;
                        justify-content: center;
                    }

                        &__entry-title {
                            display: none;
                        }

                        &__entry-value {
                            text-align: center;
                            margin-left: 0;
                            font-size: 18px;
                        }

                            &__entry-value-sub-text {
                                text-align: center;
                            }

                    &__dormancy-info {
                        display: none;
                    }
    }
}

/*doc
---
title: product card
name: product-card
category: Components
---
This component is used in the <code>.c-product-selector</code> component.

```html_example
<div class="c-product-card">
    <p class="c-product-card__flag">
        Most Popular
    </p>
    <div class="c-product-card__outer-wrap">
        <div class="c-product-card__header">
            <h3 class="c-product-price">
                <span class="c-product-price__currency">&pound;</span>
                <span class="c-product-price__value">0</span>
                <span class="c-product-price__frequency">per<br>month*</span>
            </h3>
        </div>
        <div class="c-product-card__info-wrapper">
            <div class="c-product-card__info">
                <div class="c-product-card__info-entry">
                    <p class="c-product-card__entry-title u-before-icon-upload">Payments out/direct debits</p>
                    <p class="c-product-card__entry-value">&pound;1</p>
                </div>
                <div class="c-product-card__info-entry">
                    <p class="c-product-card__entry-title u-before-icon-atm">UK cash withdrawals</p>
                    <p class="c-product-card__entry-value">&pound;1</p>
                </div>
                <div class="c-product-card__info-entry">
                    <p class="c-product-card__entry-title u-before-icon-user-add">Free Extra Accounts</p>
                    <p class="c-product-card__entry-value">0</p>
                </div>
                <div class="c-product-card__info-entry">
                    <p class="c-product-card__entry-title u-before-icon-user-add">Additional Extra Accounts</p>
                    <div class="c-product-card__entry-value">
                        &pound;1
                        <p class="c-product-card__entry-value-sub-text">per month</p>
                    </div>
                </div>
                <div class="c-product-card__info-entry">
                    <p class="c-product-card__entry-title u-before-icon-globe">Cash withdrawals abroad</p>
                    <p class="c-product-card__entry-value">3% + &pound;1</p>
                </div>
                <p class="c-product-card__dormancy-info">*&pound;1.00 monthly dormancy fee after 60 days of inactivity</p>
            </div>
        </div>
        <button class="c-btn c-btn--2 c-btn--full-width c-btn--margin-0">Apply</button>
    </div>
</div>
```
<div class="c-sg-modifier-wrap js-modifier-wrap">
    <div>
        <button>is-open</button>
    </div>
</div>
*/
