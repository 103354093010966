ul,
ol {
    margin: 0;
    padding: 0;
    padding-left: $space-s;
    list-style-type: none;

    li {
        margin-bottom: $space-s;
    }
}

ul {
    list-style-type: disc;
}

ol {
    list-style-type: decimal;
}