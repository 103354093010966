.c-icon-tab-system {
    $class: c-icon-tab-system;

    &__circle-nav {
        overflow: hidden;
    }
        &__circle-nav-title {
            @include section-title-text;
        }

        &__tab-nav {
            margin: 0 auto;
            transition: transform .35s ease-in-out;
            width: 469px;
        }

        &__tab {
            width: 55px;
            height: 55px;
            margin: 0 8px 8px 0;
            padding: 0;
            font-size: $space-l;
            line-height: 1;
            background-color: $white;
            border: 0;
            border-radius: 50%;
            transition: all 200ms;

            &:hover,
            &:focus {
                color: $color-1-dark;
            }

            &.is-active {
                color: $white;
                background-color: $color-1;

                .#{$class}__btn-content {
                    @include outer-shadow-tight($color-1-dark);
                }
            }
        }

            &__title {
                @include visually-hidden;
            }

            &__btn-content {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 100%;
                overflow: hidden;
                border-radius: 50%;
                @include outer-shadow-tight;
            }

    &__tab-content-wrap {
        height: 100%;
        position: relative;
    }

        &__tab-content {
            width: 100%;
            transition: opacity .3s ease-in-out;
            position: absolute;
            opacity: 0;

            &.is-active {
                opacity: 1;
            }
        }

    @media (min-width: $m_bp) {
        display: flex;

        &__circle-nav-title {
            transform: translate(0, -50%);
            position: absolute;
            top: 50%;
            display: block;
            line-height: 1;
            text-align: left;
        }

        &__circle-nav {
            position: relative;
            overflow: visible;
            width: 50%;
            padding: $space-xxxxl 0;
        }

            &__tab-nav {
                @include circle-arrangement(11.2, 500px, 82px, 'c-icon-tab-system__tab');
                transform: translate(-50%) rotate(-80deg);
                margin: 0;
            }

            &__tab {
                padding: 8px 4px 4px;
                line-height: 1;
            }

            &__btn-content {
                position: absolute;
                top: 0;
                left: 0;
                transform: rotate(80deg);

                &:before {
                    font-size: $space-xxl;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    margin-bottom: $space-xxs;
                }
            }

        &__tab-content-wrap {
            width: 50%;
        }
    }
}

/*doc
---
title: icon tab system (circle)
name: icon-tab-system-circle
category: Organisms
---

```html_example
<div class="c-icon-tab-system js-icon-tab-wrapper" data-style="circle" data-auto-scroll="true">
    <div class="c-icon-tab-system__circle-nav js-circle-nav">
        <h2 class="c-icon-tab-system__circle-nav-title u-section-title js-circle-nav-title">
            <span class="u-u-font u-color-1">U</span> Account <br class="u-display-none u-display-block-m">features
        </h2>
        <nav class="c-icon-tab-system__tab-nav js-icon-tab-nav">
            <button
                class="c-icon-tab-system__tab js-tab-func-btn tt-contactless-feature-button"
                data-hash="contactless-card-tab"
                data-tab="contactless-card"
            >
                <span class="c-icon-tab-system__btn-content u-before-icon-contactless">
                    <span class="c-icon-tab-system__title">
                        Contactless Mastercard<sup>&reg;</sup> card
                    </span>
                </span>
            </button>
            <button
                class="c-icon-tab-system__tab js-tab-func-btn tt-extra-accounts-feature-button"
                data-hash="extra-accounts-tab"
                data-tab="extra-accounts"
            >
                <span class="c-icon-tab-system__btn-content u-before-icon-user-add">
                    <span class="c-icon-tab-system__title">
                        Extra Accounts for budgeting
                    </span>
                </span>
            </button>
            <button
                class="c-icon-tab-system__tab js-tab-func-btn tt-apps-feature-button"
                data-hash="mobile-apps-tab"
                data-tab="mobile-apps"
            >
                <span class="c-icon-tab-system__btn-content u-before-icon-mobile">
                    <span class="c-icon-tab-system__title">
                        App for iOS and Android
                    </span>
                </span>
            </button>
            <button
                class="c-icon-tab-system__tab js-tab-func-btn tt-money-manager-feature-button"
                data-hash="money-manager-tab"
                data-tab="money-manager"
            >
                <span class="c-icon-tab-system__btn-content u-before-icon-line-chart">
                    <span class="c-icon-tab-system__title">
                        Free Money Manager
                    </span>
                </span>
            </button>
            <button
                class="c-icon-tab-system__tab js-tab-func-btn tt-payments-feature-button"
                data-hash="payments-in-tab"
                data-tab="payments-in"
            >
                <span class="c-icon-tab-system__btn-content u-before-icon-download">
                    <span class="c-icon-tab-system__title">
                        Payments
                    </span>
                </span>
            </button>
            <button
                class="c-icon-tab-system__tab js-tab-func-btn tt-cashback-feature-button"
                data-hash="cash-back-tab"
                data-tab="cash-back"
            >
                <span class="c-icon-tab-system__btn-content u-before-icon-cash-back">
                    <span class="c-icon-tab-system__title">
                        Cashback Rewards
                    </span>
                </span>
            </button>
            <button
                class="c-icon-tab-system__tab js-tab-func-btn tt-online-support-button"
                data-hash="online-support-tab"
                data-tab="online-support"
            >
                <span class="c-icon-tab-system__btn-content u-before-icon-chat">
                    <span class="c-icon-tab-system__title">
                        Online Support
                    </span>
                </span>
            </button>
        </nav>
    </div>
    <div class="c-icon-tab-system__tab-content-wrap c-icon-tab-system--orange-text js-tab-content-wrap js-features">
        <div class="c-icon-tab-system__tab-content js-tab-content" data-tab-content="contactless-card">
            <p>Tab 1</p>
        </div>
        <div class="c-icon-tab-system__tab-content js-tab-content" data-tab-content="extra-accounts">
            <p>Tab 2</p>
        </div>
        <div class="c-icon-tab-system__tab-content js-tab-content" data-tab-content="mobile-apps">
            <p>Tab 3</p>
        </div>
        <div class="c-icon-tab-system__tab-content js-tab-content" data-tab-content="money-manager">
            <p>Tab 4</p>
        </div>
        <div class="c-icon-tab-system__tab-content js-tab-content" data-tab-content="payments-in">
            <p>Tab 5</p>
        </div>
        <div class="c-icon-tab-system__tab-content js-tab-content" data-tab-content="cash-back">
            <p>Tab 6</p>
        </div>
        <div class="c-icon-tab-system__tab-content js-tab-content" data-tab-content="no-credit-checks">
            <p>Tab 7</p>
        </div>
        <div class="c-icon-tab-system__tab-content js-tab-content" data-tab-content="online-support">
            <p>Tab 8</p>
        </div>
    </div>
</div>
```
*/
