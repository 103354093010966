.c-btn-group {
    $class: 'c-button-group';
	@include clearfix;

    .c-btn,
    .c-btn-outline {
        float: left;
    }

    &--margin-right {
        .c-btn,
        .c-btn-outline {
            margin-right: $space-l;
        }
    }
}

.c-btn-block-group {
    $class: c-btn-block-group;

    .c-btn-block {
        width: 100%;
    }

    @mixin btn-block-standard-layout($breakpoint: null) {
        $modifier: standard-layout;

        @if ($breakpoint) {
            $modifier: standard-layout-#{$breakpoint};
        }

        &.#{$class}--#{$modifier} {
            display: block;

            .c-btn-block {
                display: flex;
                flex-grow: 0;
                margin-right: 0;
            }
        }
    }

    @mixin btn-block-flex-layout($breakpoint: null) {
        $modifier: flex-layout;

        @if ($breakpoint) {
            $modifier: flex-layout-#{$breakpoint};
        }

        &.#{$class}--#{$modifier} {
            display: flex;

            .c-btn-block {
                display: inline-flex;
                flex-grow: 1;
                margin-right: $space-xxs;

                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }

    @include btn-block-standard-layout();
    @include btn-block-flex-layout();

    @media (min-width: 400px) {
        @include btn-block-standard-layout(400);
        @include btn-block-flex-layout(400);
    }

    @each $bp in $breakpoints {
        $key: nth($bp, 1);
        $value: nth($bp, 2);

        @media (min-width: #{$value}) {
            @include btn-block-standard-layout($key);
            @include btn-block-flex-layout($key);
        }
    }
}

/*doc
---
title: Button group
name: z-button-group
category: Buttons
---

```html_example
<div class="c-btn-group">
    <a href="/" class="c-btn c-btn--2">Apply now</a>
    <a href="/" class="c-btn c-btn--black">See pricing</a>
</div>
```

<div class="c-sg-modifier-wrap js-modifier-wrap">
    <div>
        <button>c-btn-group--margin-right</button>
    </div>
</div>

*/

/*doc
---
title: Button block group
name: button-block-group
category: Buttons
---

```html_example
<div class="c-btn-block-group js-account-functions">
    <button class="c-btn-block c-btn-block--with-subtext">
        <span class="c-btn-block__content">
            How to pay in
        </span>
    </button>

    <button class="c-btn-block c-btn-block--with-subtext">
        <span class="c-btn-block__content">
            Fees &amp; Limits
            <span class="c-btn-block__subtext">Next £5 bill: 12/07/19</span>
        </span>
    </button>
</div>
```

<div class="c-sg-modifier-wrap js-modifier-wrap">
    <div>
        <button>c-btn-block-group--standard-layout</button>
    </div>
    <div>
        <button>c-btn-block-group--standard-layout-xs</button>
    </div>
    <div>
        <button>c-btn-block-group--standard-layout-s</button>
    </div>
    <div>
        <button>c-btn-block-group--standard-layout-m</button>
    </div>
    <div>
        <button>c-btn-block-group--standard-layout-l</button>
    </div>
    <div>
        <button>c-btn-block-group--standard-layout-xl</button>
    </div>
    <div>
        <button>c-btn-block-group--standard-layout-xxl</button>
    </div>
    <div>
        <button>c-btn-block-group--flex-layout</button>
    </div>
    <div>
        <button>c-btn-block-group--flex-layout-xs</button>
    </div>
    <div>
        <button>c-btn-block-group--flex-layout-s</button>
    </div>
    <div>
        <button>c-btn-block-group--flex-layout-m</button>
    </div>
    <div>
        <button>c-btn-block-group--flex-layout-l</button>
    </div>
    <div>
        <button>c-btn-block-group--flex-layout-xl</button>
    </div>
    <div>
        <button>c-btn-block-group--flex-layout-xxl</button>
    </div>
</div>

*/
