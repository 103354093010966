/*------------------------------------*\
    percentage bar
\*------------------------------------*/
.c-percentage-bar {
    $class: c-percentage-bar;
    $bar-height: $space-l;

    width: 100%;
    height: $bar-height;
    background: $color-1-mid;
    position: relative;
    overflow: hidden;
    margin-bottom: $space-s;

    &__total {
        transition: width .5s cubic-bezier(.680, -.550, .265, 1.550);
        width: 0;
        background: $color-1;
        position: absolute;
        left: 0;
        height: $bar-height;
    }

    &--sg-animate-bar {
        .#{$class}__total {
            width: 54%;
        }
    }
}


/*doc
---
title: percentage bar
name: percentage-bar
category: Components
---

Note - Set the position of the bar using width as an inline style in the js
<br>
The modifier <code>sg-animate-bar</code> is solely for demonstration purposes in the styleguide to show the bar animate, and is set to 54% in this example

```html_example
<div class="c-percentage-bar">
    <div class="c-percentage-bar__total"></div>
</div>

```
<div class="c-sg-modifier-wrap js-modifier-wrap">
    <div>
        <button>c-percentage-bar--sg-animate-bar</button>
    </div>
</div>
*/
