html,
body {
    transition: background .3s ease-in-out;
    min-height: 100%;
    min-width: 320px;
    width: 100%;
    max-width: 100%;
    overflow-x: hidden;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; //firefox font too bold solution
    background-color: $color-grey-light;
}

body {
    display: flex;
    margin-top: $header-height-mobile;
    margin-bottom: $bottom-nav-height;

    &.u-no-header {
        margin-top: 0;
        margin-bottom: 0;
    }

    &.l-reduced-header,
    &.l-no-navigation {
        margin-bottom: 0;
    }
}

@media (min-width: $m_bp) {
    body {
        margin-top: $header-height-desktop;
        margin-bottom: 0;

        &.u-no-header {
            margin-top: 0;
        }
    }
}
