.c-home-image-animation {
    $class: c-home-image-animation;

    @keyframes move-card-x {
        0% {
            transform: translateX(-50%);
            z-index: $z-base * 3;
        }

        25% {
            transform: translateX(-70%) rotate(0deg);
        }

        50% {
            transform: translateX(-70%) rotate(-50deg);
            z-index: $z-base * 3;
        }

        51% {
            z-index: $z-base;
        }

        60% {
            transform: translateX(-70%) rotate(-33.3deg);
        }

        80% {
            transform: translateX(-60%) rotate(0deg);
        }

        100% {
            transform: translateX(-50%);
            z-index: $z-base;
        }
    }

    @keyframes move-card-y {
        0% {
            transform: translateY(10%);
            box-shadow: 0 8px 6px -6px transparentize($black, .2);
        }

        25% {
            box-shadow: 0 8px 6px -6px transparentize($black, .2);
        }

        50% {
            box-shadow: 0 8px 6px -6px transparentize($black, .7);
        }

        80% {
            box-shadow: 0 8px 6px -6px transparentize($black, .2);
        }

        100% {
            transform: translateY(-10%);
            box-shadow: 0 8px 6px -6px transparentize($black, .2);
        }
    }

    position: relative;
    height: 220px;

    &__card-wrapper {
        position: absolute;
        z-index: $z-base * 3;
        bottom: 0;
        left: 40%;
        transform: translateX(-50%);
        transform-origin: bottom left;
        height: 50%;
    }

        &__card {
            margin: 0;
            max-height: 100%;
            max-width: 100%;
            box-shadow: 0 8px 6px -6px transparentize($black, .2);
            transform: translateY(10%);
        }

    &__phone {
        position: absolute;
        z-index: $z-base * 2;
        bottom: 0;
        left: 65%;
        transform: translateX(-50%);
        margin: 0;
        max-height: 100%;
        max-width: 100%;
        box-shadow: 0 8px 6px -6px transparentize($black, .2);
    }

    &__screen {
        position: absolute;
        z-index: $z-base * 2;
        bottom: 0;
        left: 65%;
        transform: translateX(-50%);
        margin: 0;
        max-height: 100%;
        max-width: 100%;
    }

    &--no-shadow {
        .#{$class}__phone,
        .#{$class}__card {
            box-shadow: none;
        }
    }

    &.is-animated {
        .#{$class}__card-wrapper {
            animation: move-card-x 1.25s linear;
            transform: translateX(-50%);
            z-index: $z-base;
        }

        .#{$class}__card {
            animation: move-card-y 1.25s cubic-bezier(.9,.25,.21,.92);
            transform: translateY(-10%);
        }
    }

    @media (min-width: $xs_bp) {
        height: 240px;

        &__card-wrapper {
            left: 47%;
        }

        &__phone {
            left: 58%;
        }
    }

    @media (min-width: $s_bp) {
        height: 220px;

        &__card-wrapper {
            left: 45%;
        }

        &__phone {
            left: 60%;
        }

        &--large {
            height: 260px;
        }
    }

    @media (min-width: $m_bp) {
        height: 280px;

        &--large {
            height: 400px;
        }
    }

    @media (min-width: $xl_bp) {
        &--large {
            height: 500px;
        }
    }
}

/*doc
---
title: home image animation
name: home-image-animation
category: Organisms
---

```html_example
<div class="c-home-image-animation js-home-image-animation">
    <div class="c-home-image-animation__card-wrapper js-home-animation-card">
        <img class="c-home-image-animation__card " src="img/homepage-images/u-card.png" alt="U Card" title="U Card">
    </div>
    <img class="c-home-image-animation__phone" src="img/homepage-images/u-app-mobile.png" alt="U Account App on Mobile" title="U Account App on Mobile">
</div>

```
<div class="c-sg-modifier-wrap js-modifier-wrap">
    <div>
        <button>is-animated</button>
    </div>
    <div>
        <button>c-home-image-animation--large</button>
    </div>
    <div>
        <button>c-home-image-animation--no-shadow</button>
    </div>
</div>
*/
