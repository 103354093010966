/*------------------------------------*\
    toggle content
\*------------------------------------*/
.c-toggle-content {
    $class: c-toggle-content;
    margin-bottom: $space-l;
    position: relative;

    &__content {
        height: 0;
        transition: height .3s ease-in-out;
        overflow: hidden;
    }

    @media(min-width: $m_bp) {
        &--show-content-on-desktop {
            .#{$class}__content {
                height: auto;
            }
        }
    }
}  

/*doc
---
title: toggle content
name: toggle-content
category: Components
---

If this component is to be expanded by default on page load, add <code>checked="checked"</code> to the input.

```html_example
<div class="c-toggle-content">
    <div class="c-toggle-switch c-toggle-switch--expanding-content-layout">
        <input id="signup" type="checkbox" class="c-toggle-switch__input js-toggle-content">
        <label for="signup" class="c-toggle-switch__label">
            <span class="c-toggle-switch__text">
                Types of notification
            </span>
            <span class="c-toggle-switch__label-inner"></span>
        </label>
    </div>
    <div class="c-toggle-content__content js-toggle-content-target">
        <div>
            <p class="u-color-5 u-font-heavy"><span class="u-before-icon-info-fill"></span> Lorem ipsum dolor sit amet, consectetur adipisicing elit. Pariatur libero, perspiciatis rem aspernatur amet eius labore? Perferendis nesciunt, ab temporibus beatae officiis, dolorum debitis eveniet. Hic dolor culpa vero mollitia.</p>
            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Esse praesentium molestias reiciendis sint, sapiente voluptate accusamus dolorum earum eos, nostrum possimus quis nulla ullam quasi, deleniti nihil inventore porro cum!</p>
        </div>
    </div>
</div>

```
<div class="c-sg-modifier-wrap js-modifier-wrap">
    <div>
        <button>c-toggle-content--show-content-on-desktop</button>
    </div>
    <div>
        <button data-target="c-toggle-switch">c-toggle-switch--hide-toggle-on-desktop</button>
    </div>
</div>

To have the content shown on desktop, and the toggle hidden, give the nested child component <code>c-toggle-switch</code> the modifier <code>c-toggle-switch--hide-toggle-on-desktop</code> and the parent <code>c-toggle-content</code> the modifier of <code>c-toggle-content--show-content-on-desktop</code>:

```html_example
<div class="c-toggle-content c-toggle-content--show-content-on-desktop">
    <div class="c-toggle-switch c-toggle-switch--expanding-content-layout c-toggle-switch--hide-toggle-on-desktop">
        <input id="signup2" type="checkbox" class="c-toggle-switch__input js-toggle-content">
        <label for="signup2" class="c-toggle-switch__label">
            <span class="c-toggle-switch__text">
                Types of notification
            </span>
            <span class="c-toggle-switch__label-inner"></span>
        </label>
    </div>
    <div class="c-toggle-content__content js-toggle-content-target">
        <div>
            <p class="u-color-5 u-font-heavy"><span class="u-before-icon-info-fill"></span> Lorem ipsum dolor sit amet, consectetur adipisicing elit. Pariatur libero, perspiciatis rem aspernatur amet eius labore? Perferendis nesciunt, ab temporibus beatae officiis, dolorum debitis eveniet. Hic dolor culpa vero mollitia.</p>

            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Esse praesentium molestias reiciendis sint, sapiente voluptate accusamus dolorum earum eos, nostrum possimus quis nulla ullam quasi, deleniti nihil inventore porro cum!</p>
        </div>
    </div>
</div>

```
<div class="c-sg-modifier-wrap js-modifier-wrap">
    <div>
        <button>c-toggle-content--show-content-on-desktop</button>
    </div>
    <div>
        <button data-target="c-toggle-switch">c-toggle-switch--hide-toggle-on-desktop</button>
    </div>
</div>

*/
