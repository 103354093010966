.c-site-footer {
    $class: c-site-footer;

    flex-shrink: 0;
    background-color: $color-1;
    color: $white;

    &__content {
        text-align: center;
        display: inline-block;
        padding: $space-l;
        width: 100%;
    }
        &__card {
            margin-top: -($space_xxxl * 2);
            margin-bottom: $space-xl;
            padding: $space-l;
            background: $white;
            color: $black;
            @include outer-shadow;
        }
            &__card-title {
                @include subtitle-text;
            }

            &__card-action {
                .c-btn {
                    width: 100%;
                    padding: $space-l;
                }
            }

        &__links-list {
            text-align: center;
            list-style: none;
            list-style-image: none;
            margin-bottom: $space-xs;
            padding: 0;
            display: none;
            justify-content: center;
        }

            &__links-list-item {
                margin: 0 $space-xxl;
            }

                &__link {
                    @include navigation-link-text;
                    @include font-medium;
                    margin: 0;
                    color: $white;
                    @include underline($white, 2px);

                    &:hover,
                    &:focus {
                        color: $white;
                    }

                    &.is-current {
                        &:after {
                            left: 0;
                            right: 0;
                        }
                    }
                }

        &__social-links {
            display: none;
            margin-bottom: $space-xs;
        }

        &__cookie-preferences {
            margin-bottom: $space-xs;

            a {
                color: $white;
                @include underline($white, 2px);

                &:hover,
                &:focus {
                    color: $white;
                }
            }
        }

        &__legals {
            @include content-width();
            margin: 0 auto;
            text-align: center;
            @include font-size(12px, 1.3);
        }

    &--reduced {
        .#{$class}__links-list,
        .#{$class}__social-links {
            display: none;
        }
    }

    &--card {
        margin-top: $space_xxxxl * 2;
    }

    @media (min-width: $m_bp) {
        &__links-list {
            display: inline-flex;
        }

        &__social-links {
            display: block;
        }

        &__card {
            display: flex;
            justify-content: space-around;
            align-items: center;
            max-width: 800px;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: $space-xxxl;
            margin-top: -($space_xxxxl * 2);
            padding-top: $space-xxl;
            padding-bottom: $space-xxl;
        }
            &__card-title {
                margin-bottom: 0;
            }

            &__card-action {
                .c-btn {
                    padding: $space-l $space-xxxl;
                }
            }

        &--card {
            margin-top: $space_xxxxl * 4;
        }
    }
}

/*doc
---
title: site footer
name: site-footer
category: Global
---

```html_example
<footer class="c-site-footer">

    <div class="c-site-footer__content">

        <ul class="c-site-footer__links-list">
            <li id="ttAboutUsFooterLink" class="c-site-footer__links-list-item">
                <a class="c-site-footer__link" href="/about">About</a>
            </li>
            <li id="ttCareersFooterLink" class="c-site-footer__links-list-item">
                <a class="c-site-footer__link" href="/careers">Careers</a>
            </li>
            <li id="ttTermsFooterLink" class="c-site-footer__links-list-item">
                <a class="c-site-footer__link" href="/policies">Policies &amp; Terms</a>
            </li>
            <li id="ttMediaFooterLink" class="c-site-footer__links-list-item">
                <a class="c-site-footer__link" href="/media">Press</a>
            </li>
        </ul>

        <div class="c-site-footer__social-links">

            <a class="c-social-link c-social-link--white-circle u-icon-facebook" href="//facebook.com/U-Account-205171676580179" target="_blank" title="U Account on Facebook"></a>

            <a class="c-social-link c-social-link--white-circle u-icon-twitter" href="//twitter.com/uaccount" target="_blank" title="U Account on Twitter"></a>

            <a class="c-social-link c-social-link--white-circle u-icon-linkedin" href="//www.linkedin.com/company/15193705" target="_blank" title="U Account LinkedIn"></a>

            <a class="c-social-link c-social-link--white-circle u-icon-instagram" title="U Account on Instagram" target="_blank" href="https://www.instagram.com/uaccountuk/"></a>

        </div>

        <div class="c-site-footer__cookie-preferences">
            <a>Cookie Preferences</a>
        </div>

        <p class="c-site-footer__legals">U is the trading name of Ffrees Family Finance Ltd (Company Number: 7516554). U is not a bank. U is authorised and regulated by the Financial Conduct Authority as a credit broker (Number: 630255). U Accounts are serviced online and by telephone. U does not offer overdrafts. Mastercard<sup>®</sup> is a registered trademark, and the circles design is a trademark of Mastercard<sup>®</sup> International Incorporated. The Card is issued by Wirecard Card Solutions Ltd ("WDCS") pursuant to license by Mastercard<sup>®</sup> International Inc. WDCS is authorised by the Financial Conduct Authority to conduct electronic money service activities under the Electronic Money Regulations 2011 (Ref: 900051).</p>

    </div>

</footer>
```

<div class="c-sg-modifier-wrap js-modifier-wrap">
    <div>
        <button>c-site-footer--reduced</button>
    </div>
    <div>
        <button data-target="c-site-footer__link">is-current</button>
    </div>
</div>
*/


/*doc
---
title: Site Footer with Card
name: site-footer-card
category: Global
---
Used on landing pages

```html_example
<footer class="c-site-footer c-site-footer--card">
    <div class="c-site-footer__content">
        <div class="c-site-footer__card">
            <h2 class="c-site-footer__card-title">Ready to join?</h2>
            <div class="c-site-footer__card-action">
                <a href="/unbank" class="c-btn-block u-color-1">Apply now</a>
            </div>
        </div>
        <div class="c-site-footer__social-links">
            <a class="c-social-link c-social-link--white-circle u-icon-facebook" href="//facebook.com/U-Account-205171676580179" target="_blank" title="U Account on Facebook"></a>
            <a class="c-social-link c-social-link--white-circle u-icon-twitter" href="//twitter.com/uaccount" target="_blank" title="U Account on Twitter"></a>
            <a class="c-social-link c-social-link--white-circle u-icon-linkedin" href="//www.linkedin.com/company/15193705" target="_blank" title="U Account LinkedIn"></a>
            <a class="c-social-link c-social-link--white-circle u-icon-instagram" title="U Account on Instagram" target="_blank" href="https://www.instagram.com/uaccountuk/"></a>
        </div>
        <p class="c-site-footer__legals">U is the trading name of Ffrees Family Finance Ltd (Company Number: 7516554). U is not a bank. U is authorised and regulated by the Financial Conduct Authority as a credit broker (Number: 630255). U Accounts are serviced online and by telephone. U does not offer overdrafts. Mastercard<sup>®</sup> is a registered trademark, and the circles design is a trademark of Mastercard<sup>®</sup> International Incorporated. The Card is issued by Wirecard Card Solutions Ltd ("WDCS") pursuant to license by Mastercard<sup>®</sup> International Inc. WDCS is authorised by the Financial Conduct Authority to conduct electronic money service activities under the Electronic Money Regulations 2011 (Ref: 900051).</p>
    </div>
</footer>
```
*/