.c-promo {
    $class: c-promo;

    display: block;
    @include reset-link-styles;
    margin-bottom: $space-l;

    &__title {
        @include section-title-secondary;
        margin-bottom: $space-xxs;
    }

    &__footer {
        @include footnote-text;
        font-weight: $font-weight-body;

        display: flex;
        align-items: center;
        justify-content: flex-start;

        &::before {
            content: '';
            height: 2px;
            width: 90px;
            margin-right: $space-xxs;
            background: $color-grey-1;
            transition: all 250ms ease-in-out;
        }
    }

    &:hover,
    &:focus,
    &:active {
        color: inherit;

        .#{$class}__footer {
            &::before {
                background: $color-1;
                width: 110px;
            }
        }
    }

    @media (min-width: $m_bp) {
        margin-bottom: $space-xxxl;
        padding-right: $space-l;
    }
}

/*doc
---
title: Promo
name: promo
category: Components
---

```html_example
<a href="/help/question/977" class="c-promo">
    <h2 class="c-promo__title">Why is my available amount different to my balance?</h2>
    <p class="c-promo__footer">Read answer</p>
</a>
```
*/