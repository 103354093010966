// TODO - Refactor modal CSS into a BEM component (MMM-304)
.modalContainer {
    .modalOverLay {
        transition: all .3s ease-in-out;
        transform: translateX(50%);
        height: 100%;
        position: fixed;
        width: 0;
        overflow: hidden;
        top: 0;
        background-color: transparentize($black, 0.5);
        z-index: 10;
        left: 50%;
        z-index: 1000;

        &.modalOpen {
            transform: translateX(0);
            width: 100%;
            left: 0;
        }
    }

    .modalContent {
        transform: translateY(-50%);
        position: relative;
        top: 50%;
        transition: opacity .3s ease-in-out .3s;
        transform: translate(-50%, -50%);
        background-color: $black;
        width: 90%;
        max-width: 600px;
        max-height: 90%;
        color: $white;
        padding: 20px;
        z-index: 1001;
        opacity: 0;
        left: 50%;
        position: fixed;
        pointer-events: none;
        overflow: visible;

        &.modalOpen {
            opacity: 1;
            pointer-events: auto;
        }

        &.centreContent {
            text-align: center;

            p {
                text-align: center;
            }
        }

        .modalBody {
            overflow-y: auto;
            overflow-x: hidden;

            a:not(.button) {
                color: $color-1;

                &:hover {
                    color: darken($color-1, 20);
                }
            }
        }

        .closeModalIcon {
            width: 1.5em;
            position: absolute;
            top: -.725em;
            right: -.725em;
            background-color: $black;
            border-radius: 50%;
            font-size: 1.8em;
            line-height: 1.5em;
            text-align: center;
            cursor: pointer;
        }

        /* Have also included extra old styles (needed for old modal to work) within the modal class */
        h2 {
            font-size: 1.3em;
            line-height: 1.15;
        }

        .buttonContainer {
            float: left;
            display: block;
            width: 100%;
            overflow: hidden;

            &.buttonCenter {
                display: inline-flex;

                a {
                    margin-left: auto;
                }

                button {
                    margin-right: auto;
                }
            }

            > * {
                float: left;
                display: block;

                &:last-child {
                    margin-right: 0;
                    float: right;
                    clear: none;
                }
            }
        }

        [class^="icon"]:before, [class*=" icon"]:before {
            font-family: 'ffreesicons';
            font-style: normal;
            font-weight: normal;
            speak: none;

            display: inline-block;
            text-decoration: inherit;
            margin-right: .2em;
            text-align: center;

            /* For safety - reset parent styles, that can break glyph codes*/
            font-variant: normal;
            text-transform: none;

            /* fix buttons height, for twitter bootstrap */
            line-height: 1em;

            /* Animation center compensation - margins should be symmetric */
            /* remove if not needed */
            margin-left: .2em;

            /* Font smoothing. That was taken from TWBS */
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
        }

        .button {
            line-height: 1.3; // needed to overwrite new body line-height
            transition: all .2s ease-in;
            display: block;
            width: 100%;
            max-width: 100%;
            height: 40px;
            color: $white;
            background-color: $white;
            text-align: center;
            padding: 10px 0;
            font-size: 16px;
            font-weight: 700;
            margin-bottom: 2%;
            margin-right: 2%;
            cursor: pointer;

            @media (min-width: 720px) {
                max-width: 240px;
            }

            &:disabled {
                cursor: not-allowed;
                opacity: .5;
            }

            &:focus,
            &:active {
                outline: none;
            }

            &:hover {
                color: $white;
            }
        }

        .redBg {
            background-color: $color-4;

            &:hover {
                background-color: darken($color-4, 20);
            }
        }

        .greenBg {
            background-color: $color-2;

            &:hover {
                background-color: darken($color-2, 20);
            }
        }

        .iconCheck:before {
            content: '\e805';
        }

        .iconCancel:before {
            content: '\e806';
        }
        /* */

        .button {
            width: 100%;
            margin-bottom: 20px;

            &:last-child {
                margin-right: 0;
                margin-bottom: 0;
            }

            @media (min-width: 720px) {
                width: 48%;
                margin-bottom: 0;

                &:last-child {
                    width: 50%;
                }

                &.wideButton {
                    width: 100%;
                }
            }
        }

        .buttonContainer {
            margin-top: 20px;
        }

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            color: $white;

            &.orange {
                color: $color-1;
            }

            &.light {
                font-weight: 300;
            }
        }

        h2 {
            margin-bottom: 20px;
            border-bottom: 1px solid $white;
            padding-bottom: 5px;
            @include font-extra-bold;
        }
    }

    .heightAdjust {
        overflow: auto;
    }

    &.opaHidden {
        opacity: 0;
    }

    &.securitySetupHolder.opaHidden {
        transition: opacity .5s ease-out;
    }

    .wideSecurityModal {
        $default-layout-direction: LTR !default;

        transform: translate(0);
        max-width: 100%;
        max-height: 100%;
        width: 100%;
        left: 0;
        top: 0;
        overflow: scroll;

        @media (min-width: 720px) {
            transform: translate(-50%, -50%);
            max-width: 1140px;
            max-height: 90%;
            width: 90%;
            padding: 3em;
            left: 50%;
            top: 50%;
            overflow: auto;
        }

        section > div {
            padding: 0;
        }

        .heightAdjust {
            @media (min-width: 720px) {
                height: 650px;
            }

            @media(min-width: $m_bp) {
                height: 100%;
                overflow: hidden;
            }
        }

        .closeModalIcon {
            display: none;
        }

        .modalHeader {
            display: none;
        }

        section {
            background: $black;
        }

        .securityMessage{
            // @include transition(height .5s ease-in-out);
            transition: height .5s ease-in-out, max-height .5s ease-in-out;
            @include clearfix;
            max-width: $xxl_bp;
            margin: {
                left: auto;
                right: auto;
            }
            padding: 0;

            > div {
                padding: 1.5em;
                text-align: center;
                color: $white;
                position: relative;
                will-change: height;
                margin: 0 0 1.5em;

                p:last-child {
                    margin-bottom: 0;
                }
            }

            .successMessage {
                background-color: $color-2;
            }

            .errorMessage {
                background-color: $color-4;
            }

            .warningMessage {
                background-color: $color-3;
            }

            .infoMessage {
                background-color: $color-5;
            }
        }

        @function container-span($span: $span) {
            @if length($span) == 3 {
                $container-columns: nth($span, 3);
                @return $container-columns;
            } @else if length($span) == 2 {
                $container-columns: nth($span, 2);
                @return $container-columns;
            }

            @return 12;
        }

        @function modular-scale($value, $increment, $ratio) {
            $v1: nth($value, 1);
            $v2: nth($value, length($value));
            $value: $v1;

            // scale $v2 to just above $v1
            @while $v2 > $v1 {
            $v2: ($v2 / $ratio); // will be off-by-1
            }
            @while $v2 < $v1 {
            $v2: ($v2 * $ratio); // will fix off-by-1
            }

            // check AFTER scaling $v2 to prevent double-counting corner-case
            $double-stranded: $v2 > $v1;

            @if $increment > 0 {
            @for $i from 1 through $increment {
                @if $double-stranded and ($v1 * $ratio) > $v2 {
                $value: $v2;
                $v2: ($v2 * $ratio);
                } @else {
                $v1: ($v1 * $ratio);
                $value: $v1;
                }
            }
            }

            @if $increment < 0 {
                // adjust $v2 to just below $v1
                @if $double-stranded {
                    $v2: ($v2 / $ratio);
                }

                @for $i from $increment through -1 {
                    @if $double-stranded and ($v1 / $ratio) < $v2 {
                        $value: $v2;
                        $v2: ($v2 / $ratio);
                    } @else {
                        $v1: ($v1 / $ratio);
                        $value: $v1;
                    }
                }
            }

            @return $value;
        }

        @function golden-ratio($value, $increment) {
            @return modular-scale($value, $increment, 1.618);
        }

        // Flexible grid
        @function flex-grid($columns, $container-columns: 12) {
            $width: $columns * golden-ratio(1em, 3) + ($columns - 1) * golden-ratio(1em, 1);
            $container-width: $container-columns * golden-ratio(1em, 3) + ($container-columns - 1) * golden-ratio(1em, 1);
            @return percentage($width / $container-width);
        }

        // Flexible gutter
        @function flex-gutter($container-columns: 12, $gutter: golden-ratio(1em, 1)) {
            $container-width: $container-columns * golden-ratio(1em, 3) + ($container-columns - 1) * golden-ratio(1em, 1);
            @return percentage($gutter / $container-width);
        }

        @mixin span-columns($span: $columns of $container-columns, $display: block) {
            $columns: nth($span, 1);
            $container-columns: container-span($span);

            float: left;
            margin-right: flex-gutter($container-columns);
            width: flex-grid($columns, $container-columns);

            &:last-child {
                margin-right: 0;
            }
        }

        .fiveCol {
            @include span-columns(12);

            @media (min-width: $m_bp) {
                @include span-columns(5);
            }
        }

        .sixCol {
            @include span-columns(12);

            @media (min-width: $m_bp) {
                @include span-columns(6);

                &.shiftOne {
                    @include span-columns(6);
                }
            }
        }

        h1.un {
            font-size: 3em;
            line-height: 0.75;
            letter-spacing: -3px;

            @media (min-width: 720px) {
                text-align: left;
                font-size: 3.5em;
            }

            @media (min-width: $m_bp) {
                font-size: 3.5em;

                &.vLong {
                font-size: 2.8em;
                }
            }

            @media (min-width: 1280px) {
                font-size: 3.8em;

                &.long {
                    font-size: 3.6em;
                }

                &.vLong {
                font-size: 3.4em;
                }
            }
        }

        .securityQuestion {
            margin-top: 1em;
        }

        // Input Group styling mixin

        @mixin input-border-colour($colour) {

            border: 1px solid $colour;
            border-right-width: 0;

            &:last-child {
                border-right-width: 1px;
            }

            + label {
                border-left-color: $colour;
            }
        }

        // Base styling of all form elements
        %input-style {
            appearance: none;
            box-sizing: border-box;
            width: 100%;
            height: 2.8rem;
            margin: 0;
            padding: .85rem 1rem 0 .5rem;
            display: block;
            color: $black;
            font-size: $base-font-size;
            background-color: $white;
            border: 1px solid $color-grey-mid;
            border-radius: 6px;
            white-space: nowrap;
            outline: none;
            box-shadow: none;
            word-break: normal;

            @media (min-width: 400px) {
                padding: .85rem 2rem 0 1rem;
            }

            .animation & {
                transition: all .2s ease-in-out;
            }

            &:active,
            &:focus,
            &:hover {
                color: $color-1;
                background-color: lighten(#F39200, 50%);
                border-color: $color-1;
                outline: none;
            }

            &:-moz-submit-invalid {
                display: none;
            }

            &::-ms-clear {
                display: none;
            }

            .noLabel & {
                padding-top: 0;
            }
        }

        %input-hint {
            max-width: calc(100% - 20px);
            min-width: 85px;
            margin: 0 10px;
            padding: 3px 5px;
            position: absolute;
            top: 3.1rem;
            visibility: hidden;
            opacity: 0;
            color: $white;
            font-size: .75rem;
            background-color: $color-4;
            border-radius: 3px;
            box-sizing: border-box;
            z-index: 5;

            .animation & {
                transition: all .2s ease-in-out;
            }

            &:before {
                content: '';
                width: 1px;
                height: 1px;
                margin-left: 16px;
                position: absolute;
                top: -14px;
                border: 8px solid $color-grey-mid;
                border-color: transparent transparent $color-4;
            }
        }

        fieldset {
            background: lighten($color-grey-mid, 10);
            border: 1px solid $color-grey-mid;
            margin: 0 0 (1em / 2);
            padding: 1em;
        }

        input,
        label,
        select {
            display: block;
            font-family: 'futura-pt', sans-serif;
            font-size: 1em;
        }

        textarea {
            @extend %input-style;
            white-space: pre-wrap;
            min-height: 150px;
            min-width: 100%;
            max-width: 100%;

            + .requiredHint {
                top: auto;
                bottom: -22px;
            }
        }

        //-------------------------------------------------------------------------------------------------------
        // Labels & Validation Styling
        //-------------------------------------------------------------------------------------------------------

        // Regular icons for inputs
        // the classes listed here need to be added to the items label
        // -------------------------------------------------------------------------------------------------------

        // Label In-input Styling
        // As form elements do not support the use of pseudo elements I have used the label to style the error and success styling
        // -------------------------------------------------------------------------------------------------------
        label:not(.labelReset) {
            width: 100%;
            margin: 0 0 2em; // if this margin-bottom changes update the margin-bottom of .reveal-label-wrapper (below)
            position: relative;

            .label { // Label text styling
                position: absolute;
                top: .9rem;
                left: .5rem;
                z-index: 1;
                color: transparentize($black, .65);
                font-size: 1em / 1.25;
                font-weight: 700;
                pointer-events: none;

                @media (min-width: 400px) {
                    left: 1rem;
                }

                .animation & {
                    transition: all .2s ease-in-out;
                }

                &.vertCenter {
                    top:50%;
                    transform: translateY(-50%);
                }
            }

            &.toggle:before {
                width: auto;
                height: auto;
            }

            &:before {
                width: 1.3rem;
                height: 1.3rem;
                position: absolute;
                right: .5rem;
                bottom: .55rem;
                text-align: center;
                color: transparentize($black, .5);
                line-height: .9rem;
                font-family: 'ffreesicons';
                font-size: .8em;
                pointer-events: none;
                z-index: 3;

                .animation & {
                    transition: all .2s ease-in-out;
                }

                .ie10 &,
                .ie11 & {
                    bottom: 0;
                }
            }

            &:after {
                width: 1.3rem;
                height: 1.3rem;
                position: absolute;
                left: .5rem;
                bottom: .5rem;
                text-align: center;
                color: #bcbec0;
                line-height: .9rem;
                font-size: $base-font-size;
                font-family: 'ffreesicons';
                z-index: 4;

                .animation & {
                    transition: all .2s ease-in-out;
                }

                .ie10.ie10.ie10 &,
                .ie11.ie11.ie11 & {
                    bottom: -6px;
                }
            }

            &:hover {

                &:before,
                &:after,
                .label {
                    color: $color-1;
                }
            }

            &.smallLabel {

                .label {
                    top: 5px;
                    font-size: 8px;
                    color: $color-1;
                    transform:translateY(0);
                }

                sup {
                    visibility: hidden;
                    opacity: 0;
                }

                &:after {
                    top: auto;
                    bottom: .1rem;
                    opacity: 1;
                    color: $black;
                    font-size: .9em;
                }
            }
        }

        label.invalid {

            &:before {
                content: $icon-attention;
                color: $color-4;
                font-size: 1rem;
                font-family: 'ffreesicons';
            }

            &.smallLabel .label,
            &:after {
                color: $color-4;
            }

            label {
                color: transparentize($color-4, .5);
            }

            input,
            .selectInput,
            .dpTagBox {
                color: $color-4;
                background-color: $color-4-light;
                border-color: $color-4;

                select {
                    color: $color-4;
                    background: none;
                    border: 0;
                }
            }

            .selectInput:before {
                color: $color-4;
            }
        }

        label.warning {

            &:before {
                content: $icon-attention;
                color: $color-3;
                font-size: 1rem;
                font-family: 'ffreesicons';
            }

            &.smallLabel .label,
            &:after {
                color: $color-3;
            }

            .label {
                color: transparentize($color-3, .5);
            }

            input,
            .selectInput,
            .dpTagBox {
                color: $color-3;
                background-color: $color-3-light;
                border-color: $color-3;
                box-shadow: none;

                select {
                    color: $color-3;
                    background: none;
                    border: 0;
                }
            }

            .selectInput:before {
                color: $color-3;
            }
        }

        label.valid {

            &.validWithScrollbar:before {
                padding-right: 2rem;
            }

            &:before {
                content: $icon-check;
                color: $color-2;
                font-size: 1rem;
                font-family: 'ffreesicons';
            }

            &.smallLabel .label,
            &:after {
                color: $color-2;
            }

            &:hover:before {
                color: $color-2;
            }

            .label {
                color: transparentize($color-2, .5);
            }

            input,
            .selectInput,
            .dpTagBox {
                color: $color-2;
                background-color: $color-2-light;
                border-color: $color-2;

                select {
                    color: $color-2;
                    background: none;
                    border: 0;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    // max-width: 92%;
                }
            }
        }

        label.disabled {

            &:before {
                color: #a7a9ac;
                font-size: 1rem;
                font-family: 'ffreesicons';
            }

            &.smallLabel .label,
            &:after {
                color: #a7a9ac;
            }

            &:hover {
                cursor: not-allowed;

                &:before {
                    color: #a7a9ac;
                }
            }

            &.valid {
                &:before {
                    color: $color-2;
                }
            }

            .label {
                color: transparentize(#a7a9ac, .5);
            }

            input,
            .selectInput,
            .dpTagBox {
                color: #a7a9ac;
                background-color: $color-grey-xxxlight;
                border-color: #f0f1f3;

                select {
                    color: #a7a9ac;
                    background: none;
                    border: 0;
                }
            }
        }

        label.inline {
            display: inline-block;
            width:auto;
            vertical-align: top;
            margin-right: 10px;
        }

        input[disabled='disabled'] {
            color: #a7a9ac;
            background-color: $color-grey-xxxlight;
            border-color: $color-grey-mid;
        }

        //-------------------------------------------------------------------------------------------------------
        // Hints/Errors
        //-------------------------------------------------------------------------------------------------------

        .inputHint {
            @extend %input-hint;
        }

        input:focus + .inputHint,
        label[class*='date'] .inputHint,
        .showIt {
            visibility: visible;
            opacity: 1;
            z-index: 10;
        }

        .requiredHint {
            @extend %input-hint;
            visibility: visible;
            opacity: 1;
            background-color: $color-3;
            z-index: 11;

            &:before {
                border-color: transparent transparent $color-3;
            }
        }

        // Password multiple validation hint
        .boxHint {
            @extend %input-hint;
            padding: 6px;
            list-style: none;
            color: $white;
            background-color: $color-grey-xxxlight;
            border: 1px solid $color-grey-mid;
            border-radius: 6px;
            pointer-events: none;

            &:before {
                border-color: transparent transparent $color-grey-mid;
            }

            li {
                font-size: .75rem;
                font-weight: bold;
                line-height: 1.4rem;
                color: $black;

                &:before {
                    content: '-';
                    width: 16px;
                    height: 16px;
                    margin-top: 4px;
                    float: left;
                    line-height: normal;
                    text-align: center;
                    box-sizing: border-box;
                }

                &.invalid {
                    color: $color-4;

                    &:before {
                        content: '\e806';
                        padding: 1px 0 0 1px;
                        font-family: 'ffreesicons';
                        font-size: .65rem;
                    }
                }

                &.valid {
                    color: $color-2;

                    &:before {
                        font-family: 'ffreesicons';
                        content: '\e805';
                        padding: 1px 0 0 1px;
                        font-size: .65rem;
                    }
                }
            }
        }


        //-------------------------------------------------------------------------------------------------------
        // Standard Inputs
        //-------------------------------------------------------------------------------------------------------

        input {
            @extend %input-style;
        }

        input[type=number]::-webkit-inner-spin-button,
            input[type=number]::-webkit-outer-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        input[type=number] {
            -moz-appearance: textfield;
        }

        input[type="password"] {
            //letter-spacing: -4px;
            font-family: initial;
        }

        input[disabled="disabled"] {
            //opacity: .4;

            &:hover {
                color: #a7a9ac;
                background-color: $color-grey-xxxlight;
                border-color: $color-grey-mid;
            }
        }

        // -----------------------------------------------------------------------------------------------
        // Buttons
        // -----------------------------------------------------------------------------------------------

        // Submit Button

        button {
            width: auto;
            float: left;
            clear: left;
            display: inline-block;
            color: $white;
            background-color: $color-2;
            border: 0;

            &.disabled {
                opacity: .4;
            }

            &.submitDisabled,
            &[disabled="disabled"] {
                opacity: .4;
                cursor: not-allowed;

                .animation & {
                    transition: all .2s ease-in-out;
                }
            }

            &:hover {
                background-color: darken($color-2, 10);
            }
        }

        //-------------------------------------------------------------------------------------------------------
        // Cross Browser Select
        //-------------------------------------------------------------------------------------------------------

        .selectInput {
            box-sizing: border-box;
            width: 100%;
            padding: 0;
            position: relative;
            background-color: $white;
            border: 1px solid $color-grey-mid;
            border-radius: 6px;
            z-index: 4;

            .animation & {
                transition: all .2s ease-in-out;
            }

            &:hover {
                background-color: lighten(#F39200, 50%);
                border-color: $color-1;

                &:after {
                    color: $color-1;
                }
            }

            &:after {
                content: '\e804';
                height: 100%;
                padding: 0 .8em;
                display: block;
                position: absolute;
                top: 0;
                right: -5px;
                color: #bcbec0;
                line-height: 2.8rem;
                font-family: 'ffreesicons';
                font-size: $base-font-size;
                text-align: center;
                z-index: -1;

                @media (min-width: 400px) {
                    right: 0;
                }

                .animation & {
                    transition: all .2s ease-in-out;
                }

                .ie10 &,
                .ie11 & {
                    top: 0px;
                }
            }

            select {
                @extend %input-style;
                height: calc(#{2.8rem} - 2px); // this is because the border is on the parent element
                margin-bottom: 0;
                background-color: transparent;
                border: 0 none;
                box-shadow: none;
                cursor: pointer;
                padding-right: 10px;

                @media (min-width: 330px) {
                    padding-right: inherit;
                }

                &:hover,
                &:focus {
                    background-color: transparent;
                    border: 0;
                }

                &:hover {
                    color: $color-1;
                }

                &:-moz-focusring {
                    color: transparent;
                    text-shadow: 0 0 0 $color-1;
                }

                &::-ms-expand {
                    display: none;
                }
            }

            option {
                appearance: none;
                padding: 3px;
                color: $black;
                text-shadow: none;
                background-color: $white;
                border: 0;

                &[disabled] {
                    color: $color-grey-mid;

                    &:not(.view) {
                        display: none;
                    }
                }
            }
        }

        //little trick for custom select elements in mozilla firefox  17/06/2014 @rodrigoludgero
        // pseudo class https://developer.mozilla.org/en-US/docs/Web/CSS/:any
        :-moz-any(.selectLabel) {

            .selectInput {

                select {
                    width: calc(100% - 2px);
                }

                &:after {
                    background-color: $white;
                    pointer-events: none;
                    border-radius: 0 6px 6px 0;
                    z-index: 1;

                    .animation & {
                        transition: all .2s ease-in-out;
                    }
                }

                &:hover:after {
                    background-color: lighten(#F39200, 50%);
                }
            }

            &.invalid .selectInput:after {
                background-color: $color-4-light;
            }

            &.valid .selectInput:after {
                background-color: $color-2-light;
            }
        }
    }
}