.c-list {
    $class: c-list;

    padding: 0;
    margin: $space-xl 0;
    list-style: none;
    text-align: left;

    @mixin itemStyles() {
        background: $white;
        padding: $space-s $space-l;
    }

    &__item {
        @include itemStyles;
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        align-items: center;
        margin: 0;
        border-top: 1px solid $color-grey-9;

        &:last-child {
            border-bottom: 1px solid $color-grey-9;
        }

        &--condensed {
            padding-top: $space-xxs;
            padding-bottom: $space-xxs;

            .#{$class}__link {
                padding-top: $space-xxs;
                padding-bottom: $space-xxs;
            }
        }

        &--link {
            padding: 0;
            background: none;

            > * {
                width: 100%;
            }
        }
    }

        &__link {
            @include itemStyles;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            width: 100%;
            color: inherit;
            text-decoration: none;
            @include font-medium;
            transition: all 250ms;

            &:hover,
            &:focus,
            &:active {
                color: inherit;
                background: $color-grey-4;
            }
        }

        &__icon {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: $space-m;

            .c-icon {
                height: $space-l;
                width: $space-xl;
            }
        }

        &__body {
            display: block;
        }

            &__subtext {
                @include footnote-text;
                color: $color-grey-8;
                margin-top: -2px;
                margin-bottom: 1px;
                display: block;
            }

        &__actions {
            margin-left: auto;
        }

            &__action {
                & + .#{$class}__action {
                    margin-left: $space-xs;
                }
            }

    &--style-b {
        @include list-item-font-mobile;
        margin: 0;

        .#{$class}__item {
            border: 0;
        }

        .#{$class}__link {
            margin-bottom: 4px;
            padding: $space-xxs $space-l;
            border-radius: $space-s;
        }
    }

    @media (min-width: $m_bp) {
        &__icon {
            margin-right: $space-xxl;
        }

        &--buttons-after-m {
            .#{$class}__item {
                border: 0;
                margin-bottom: $space-xxs;
            }

            .#{$class}__link {
                border: 1px solid $color-grey-9;
                border-radius: $space-s;
                @include outer-shadow-tight;
                padding-left: $space-s;
                padding-right: $space-s;

                &:hover,
                &:focus,
                &:active,
                &.is-selected-desktop {
                    border-color: $color-1;
                    background: $color-1-light;
                    @include outer-shadow-tight($color-1-dark);
                }

                &.is-disabled {
                    pointer-events: none;
                }
            }
        }

        &--style-b {
            @include list-item-font-desktop;

            .#{$class}__link {
                padding: $space-s $space-xxl;
            }
        }
    }
}

/*doc
---
title: List
name: list
category: Components
---

```html_example
<ol class="c-list">
    <li class="c-list__item c-list__item--link">
        <a href="#" class="c-list__link">
            <span class="c-list__icon" aria-hidden="true">
                <svg viewBox="0 0 100 100" class="c-icon c-icon--fill-color-1">
                    <use xlink:href="#help"></use>
                </svg>
            </span>
            <span class="c-list__body">Retrieve my PIN</span>
            <span class="c-list__actions">
                <span class="c-list__action u-before-icon-angle-right"></span>
            </span>
        </a>
    </li>
    <li class="c-list__item c-list__item--link c-list__item--condensed">
        <button class="c-list__link">
            <span class="c-list__icon" aria-hidden="true">
                <svg viewBox="0 0 100 100" class="c-icon c-icon--fill-color-1">
                    <use xlink:href="#help"></use>
                </svg>
            </span>
            <span class="c-list__body">
                Lock my card
                <span class="c-list__subtext">Tap again to unlock your card</span>
            </span>
        </button>
    </li>
    <li class="c-list__item">
        2 Oct 2018 - 1 Oct 2019
        <span class="c-list__actions">
            <span class="c-list__action" title="Generating">
                <span class="u-before-icon-loading-circle u-animate-const-rotate-before u-color-1"></span>
            </span>
        </span>
    </li>
</ol>
```
<div class="c-sg-modifier-wrap js-modifier-wrap">
    <div>
        <button>c-list--buttons-after-m</button>
        <button>c-list--style-b</button>
    </div>
</div>
*/