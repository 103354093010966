.c-notification {
    $class: c-notification;
    $notification-color: $color-4;
    $notification-width: 22px;
    $notification-dot-width: 8px;

    @include outer-shadow;
    @include aspect-ratio(1, 1);
    width: 0;
    margin: calc(#{$notification-width}/2);
    border-radius: 50%;
    background-color: $notification-color;
    color: $white;
    transition: width 0.3s ease-in-out, margin 0.3s ease-in-out;

    &__text {
        opacity: 0;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        @include font-size(13px, 1);
        transition: opacity 0.3s ease-in-out;
    }

    &--font-heavy {
        @include font-heavy;
    }

    &--variable-width {
        display: none;
        border-radius: 10px;
        padding: 2px 5px;

        &::before {
            content: none;
        }

        .#{$class}__text {
            position: relative;
            top: 0;
            left: 0;
            transform: none;
        }
    }

    &--dot {
        border-radius: 50%;
        padding: 0;
        box-shadow: none;

        &::before {
            content: "";
        }

        .#{$class}__text {
            display: none;
        }
    }

    &--color-1 {
        $notification-color: $color-1;

        background-color: $notification-color;

        .#{$class}__text {
            background-color: $notification-color;
        }
    }

    &.is-shown {
        width: $notification-width;
        margin: 0;
        border: 2px solid $white;

        .#{$class}__text {
            opacity: 1;
        }
    }

    &.is-shown.#{$class}--variable-width {
        display: inline-block;
        width: auto;
        margin: 0;
    }

    &.is-shown.#{$class}--dot {
        width: $notification-dot-width;
        margin: 0;
        border: 0;
    }

    &.is-shown.#{$class}--thin-border {
        border-width: 1px;
        box-shadow: none;
    }

    @media (min-width: $m_bp) {
        $notification-width: 18px;
        $notification-dot-width: 6px;

        &__text {
            @include font-size(12px, 1);
        }

        &.is-shown {
            width: $notification-width;
            margin: 0;
            border: 0;

            .#{$class}__text {
                opacity: 1;
            }
        }

        &.is-shown.#{$class}--variable-width {
            width: auto;
            margin: 0;
        }

        &.is-shown.#{$class}--dot {
            width: $notification-dot-width;
            margin: 0;
        }
    }
}

/*doc
---
title: notification
name: notification
category: Components
---

Use the <code>is-shown</code> modifier to reveal the notification.

```html_example_table
<div class="c-notification">
    <div class="c-notification__text">2</div>
</div>

<div class="c-notification c-notification--variable-width">
    <div class="c-notification__text">New</div>
</div>
```
<div class="c-sg-modifier-wrap js-modifier-wrap">
    <div>
        <button>c-notification--dot</button>
    </div>
    <div>
        <button>c-notification--color-1</button>
    </div>
    <div>
        <button>c-notification--font-heavy</button>
    </div>
    <div>
        <button>c-notification--thin-border</button>
    </div>
    <div>
        <button>is-shown</button>
    </div>
</div>
*/
