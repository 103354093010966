.c-product-price {
    $class: c-product-price;

    display: inline-flex;
    margin: 0;

    &__currency {
        margin: 0;
        align-self: flex-start;
        @include font-size(18px, 14px);
        @include font-medium;
    }

    &__value {
        margin: 0;
        @include font-size(44px, 33px);
        letter-spacing: -5px;
    }

    &__frequency {
        margin: 0 0 0 6px;
        align-self: flex-end;
        text-align: left;
        @include font-size(15px, 14px);
        @include font-medium;
    }

    @media (min-width: $m-bp) {
        &__currency {
            @include font-size(22px, 18px);
        }

        &__value {
            @include font-size(56px, 44px);
            letter-spacing: -8px;
        }

        &__frequency {
            @include font-size(20px, 19px);
            margin-left: 10px;
        }
    }
}

/*doc
---
title: product price
name: product-price
category: Components
---

```html_example
<h3 class="c-product-price">
    <span class="c-product-price__currency">&pound;</span>
    <span class="c-product-price__value">0</span>
    <span class="c-product-price__frequency">per<br>month*</span>
</h3>
```
*/
