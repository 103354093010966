.c-dot-dot-loans-banner {
    $class: c-dot-dot-loans-banner;

    display: flex;
    flex-direction: column;
    align-items: center;
    padding: $space-m 0;

    &__logo {
        width: 173px;
        flex-shrink: 0;
        margin-bottom: $space-m;
    }

    &__title,
    &__disclaimer {
        @include font-heavy;
    }

    &__title {
        @include font-size(23px, 33px);
        @include font-heavy;
        margin-bottom: $space-l;
    }

    &__disclaimer {
        @include font-size(18.5px, 28px);
        margin-bottom: 0;

        .c-text-link--blob {
            @include font-heavy;
            background-color: $color-dot-dot-loans-4;
            @include text-wrap-border($color-dot-dot-loans-4, $color-dot-dot-loans-4);

            &:hover,
            &:focus,
            &:active {
                @include text-wrap-border($color-dot-dot-loans-4, $color-dot-dot-loans-5);
            }
        }
    }

    &__btn {
        @include button-styles;
        @include outer-shadow-tight;
        @include font-size(18.5px, 24px);
        color: $black;
        background-color: $white;
        margin-bottom: $space-m;

        &:hover,
        &:focus,
        &:active {
            color: $black;
            background-color: $color-dot-dot-loans-4;
        }
    }

    @media (min-width: $m-bp) {
        flex-direction: row;
        padding: $space-xl 0;
        align-items: start;

        &__logo {
            width: 203px;
            order: 2;
        }

        &__content {
            order: 1;
            margin-right: $space-xxxxl;
        }

            &__title {
                @include font-size(28px, 36px);
                margin-bottom: $space-xxl;
            }

            &__disclaimer {
                @include font-size(23.5px, 32px);
            }

            &__btn {
                @include font-size(23.5px, 30px);
                margin-bottom: $space-xl;
            }
    }
}

/*doc
---
title: dot dot loans banner
name: dot-dot-loans-banner
category: Organisms
---

```html_example
<div class="l-content-width l-content-width--max-width-1080">
    <div class="c-full-width-box c-full-width-box--slanted c-full-width-box--color-grey-4">
        <div class="c-full-width-box__content">
            <div class="c-dot-dot-loans-banner">
                <img class="c-dot-dot-loans-banner__logo" src="img/dotdotlogo.png" alt="Dot Dot Loans Logo" width="200px" />

                <div class="c-dot-dot-loans-banner__content">
                    <p class="c-dot-dot-loans-banner__title">Simple online loans from £200 - £1,000.</p>

                    <a class="c-dot-dot-loans-banner__btn" href="https://www.dotdotloans.co.uk/">Apply with Dot Dot today</a>

                    <p class="c-dot-dot-loans-banner__disclaimer">Warning: Late repayment can cause you serious money problems. For help, go to <a class="c-text-link c-text-link--blob" href="http://moneyadviceservice.org.uk/">moneyadviceservice.org.uk</a></p>
                </div>
            </div>
        </div>
    </div>
</div>
```
*/
