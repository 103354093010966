.c-limit-table {
    $class: c-limit-table;
    height: 0;
    overflow: hidden;

    &__row {
        width: 100%;
        padding: $space-xs;
        background-color: $color-grey-5;

        &:nth-child(2n) {
            background-color: $white;
        }
    }

    &__title {
        @include list-item-title;
        @include font-heavy;
        text-align: left;
        text-transform: none;
        margin-bottom: $space-xs;
    }

    &__values {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        text-align: center;
    }

        &__value {
            margin: 0;
            padding: 0 $space-xxs;
            align-content: flex-start;
            text-align: center;
        }

            &__value-title {
                display: block;
                text-transform: none;
                color: $color-1;
                @include footnote-text;
                @include font-medium;
            }
}

/*doc
---
title: Limit table
name: limits-table
category: Components
---

Note - This component is hidden until the data is loaded in on the page, at which point height: auto is added to the element

```html_example
<div class="c-limit-table js-account-limits" data-limit="getActiveLimits" style="height: auto;">
    <div class="c-limit-table__row js-limit-row">
        <h3 class="c-limit-table__title js-limit-title">Debit Card Payments</h3>
        <div class="c-limit-table__values">
            <h4 class="c-limit-table__value">
                <small class="c-limit-table__value-title">Daily Limit</small>
                <span class="js-limit-daily-limit">£3500</span>
            </h4>
            <h4 class="c-limit-table__value">
                <small class="c-limit-table__value-title">7 Day Limit</small>
                <span class="js-weekly-limit">£7500</span>
            </h4>
            <h4 class="c-limit-table__value">
                <small class="c-limit-table__value-title">30 Day Limit</small>
                <span class="js-monthly-limit">£12000</span>
            </h4>
        </div>
    </div>
    <div class="c-limit-table__row js-limit-row">
        <h3 class="c-limit-table__title js-limit-title">Same-day Payments Out</h3>
        <div class="c-limit-table__values">
            <h4 class="c-limit-table__value">
                <small class="c-limit-table__value-title">Daily Limit</small>
                <span class="js-limit-daily-limit">£3250</span>
            </h4>
            <h4 class="c-limit-table__value">
                <small class="c-limit-table__value-title">7 Day Limit</small>
                <span class="js-weekly-limit">£6000</span>
            </h4>
            <h4 class="c-limit-table__value">
                <small class="c-limit-table__value-title">30 Day Limit</small>
                <span class="js-monthly-limit">£10000</span>
            </h4>
        </div>
    </div>
</div>

```
*/
