.c-datepicker {
    position: relative;
    $class: c-datepicker;

    &__dropdown {
        position: absolute;
        top: 100%;
        width: 100%;
        padding: $space-xs $space-m $space-s;
        border: 1px solid $color-grey-mid;
        border-top: 0;
        background: $white;
        z-index: $z-tooltip;
    }
        &__selectors {
            display: flex;
            justify-content: space-between;

            .c-input {
                width: 100%;
                margin-right: $space-s;
                margin-bottom: $space-xxs;

                &:last-child {
                    width: 40%;
                    margin-right: 0;
                }
            }
        }

        &__calendar {
            width: 100%;
            table-layout: fixed;
            text-align: center;
        }
            &__calendar-row {
                border: 0;
            }

                &__heading,
                &__date {
                    padding: $space-xxs 0;
                }

                &__heading {
                    color: $color-grey-dark;
                    @include font-medium;
                }

                &__date {
                    cursor: pointer;

                    &:hover,
                    &:focus {
                        outline: none;

                        .#{$class}__circle {
                            background-color: $color-1-mid;
                        }
                    }

                    &.is-selected {
                        .#{$class}__circle {
                            background-color: $color-1;
                            color: $white;
                        }

                        &:focus {
                            .#{$class}__circle {
                                background-color: $color-1-dark;
                            }
                        }
                    }

                    &.is-invalid {
                        pointer-events: none;

                        .#{$class}__circle {
                            color: $color-grey-mid;
                        }
                    }

                    &.is-selected.is-invalid {
                        .#{$class}__circle {
                            background-color: transparent;
                            color: $color-grey-mid;
                        }

                        &:focus {
                            .#{$class}__circle {
                                background-color: $color-1-mid;
                            }
                        }
                    }
                }

                    &__circle {
                        position: relative;
                        display: inline-flex;
                        align-items: center;
                        justify-content: center;
                        height: $space-xl;
                        width: $space-xl;
                        border-radius: 100%;
                        background-color: rgba(255, 255, 255, 0);
                        transition: background-color 100ms;
                        z-index: $z-base;
                    }
}
