/*------------------------------------*\
    title block
\*------------------------------------*/

.c-title-block {
    $class: c-title-block;
    @include main-body-text;

    padding: $space-xs;
    margin-bottom: $space-l;
    background: $color-grey-xlight;

    &__title {
        @include main-body-text;
        @include font-medium;
    }

    *:last-child {
        margin-bottom: 0;
    }

    &--info {
        color: $color-info;
        background: $color-info-light;
    }

    &--margin-bottom-0 {
        margin-bottom: 0;
    }
}

/*doc
---
title: Title block
name: title-block
category: Components
---

Note you may prefer to use a different heading tag for SEO purposes.

```html_example
<div class="c-title-block">
    <h3 class="c-title-block__title">Legal information</h3>
</div>
```
<div class="c-sg-modifier-wrap js-modifier-wrap">
    <div>
        <button>c-title-block--info</button>
    </div>
    <div>
        <button>c-title-block--margin-bottom-0</button>
    </div>
</div>

*/
