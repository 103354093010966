.c-input {
    $class: c-input;
    $pad-right: $space-xxxl;
    $pad-left: $space-s;
    $pad-left-prepend: $space-xl;
    $pad-icon: $space-s;
    $pad-progress-circle: $pad-icon - 4px;
    $validation-font-size: 14px;

    @mixin input-icon {
        @include ffrees-icons-font;
        @include animate-prop(color, opacity, transform, font-size, top, bottom);
        @include absolute-center('y');
        display: block;
        right: $pad-icon;
        color: $color-grey-8;
        font-size: inherit;
        line-height: inherit;
    }

    @mixin validation-icon {
        @include ffrees-icons-font;
        @include animate-prop(color, opacity);
        display: block;
        position: relative;
        float: left;
        color: $color-grey-8;
        margin-right: $space-xs;
    }

    position: relative;
    display: block;
    padding: $space-m $pad-right ($space-xxs / 2) $pad-left;
    margin-bottom: $space-l;
    border: 1px solid $color-grey-9;
    color: $black;
    background-color: $white;
    border-radius: $space-s;
    @include outer-shadow-tight;
    @include animate-prop(background, border, color);

    &:hover,
    &.is-hover {
        border-color: $color-hover;
        @include outer-shadow-tight($color-hover)
    }

    &__prepend {
        position: absolute;
        top: $space-xs;
        left: $pad-left;
        color: $color-grey-8;
        color: $black;
        @include animate-prop(transform, top, color, opacity);
    }

    &__label {
        position: absolute;
        display: block;
        color: $color-grey-8;
        top: $space-xs;
        left: $pad-left;
        opacity: 1;
        @include animate-prop(font-size, transform, line-height, top, left, color, opacity);
    }

    &__input {
        position: relative;
        display: block;
        width: 100%;
        border: none;
        border-radius: 0;
        background: none;
        outline: none;
        box-shadow: none;
        @include animate-prop(color);
        z-index: $z-base;
        resize: none;

        &:focus,
        &:hover {
            background: none;
            color: inherit;
        }

        // remove webkit up/down arrows
        &[type=number] {
            &::-webkit-inner-spin-button,
            &::-webkit-outer-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }

            -moz-appearance: textfield;
        }
    }

    &__validation-hint,
    &__validation-message,
    &__info-message {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        position: absolute;
        top: 100%;
        left: 0;
        margin-top: $space-xxs;
        padding: $space-xxs;
        background: $color-3;
        color: $white;
        z-index: $z-tooltip;
        @include font-medium;
        font-size: $validation-font-size;
        line-height: normal;
        opacity: 0;
        pointer-events: none;
        transition: opacity .34s ease-in-out;

        &::before {
            content: '';
            position: absolute;
            display: block;
            top: -$space-s;
            left: $space-xxs;
            border: $space-xxs solid $color-empty;
            border-color: transparent transparent $color-empty;
        }
    }

    &__validation-message {
        background: $color-invalid;

        &::before {
            border-color: transparent transparent $color-invalid;
        }
    }

    &__info-message {
        background: $color-5;

        &::before {
            border-color: transparent transparent $color-5;
        }
    }

    &__validation-hint,
    &__validation-message {
        padding: 2px $space-xxs;
        background: $white;
        color: $black;
        @include outer-shadow-tight();

        &::before {
            content: '';
            display: block;
            position: absolute;
            top: -$space-s;
            border: $space-xxs solid $white;
            border-color: transparent transparent $white;
        }
    }

    .c-progress-circle {
        position: absolute;
        right: $pad-progress-circle;
        bottom: $pad-progress-circle;
        transform: scale(0);
    }

    &--input-button {
        .#{$class}__input,
        .#{$class}__input-button {
            width: 50%;
            max-width: none;
        }

        .#{$class}__input {
            padding-right: $space-xxl;
        }

        .#{$class}__input-button {
            @include font-heavy;
            height: calc(100% + 2px);
            position: absolute;
            top: -1px;
            right: -1px;
            background: $black;
            color: $white;
            padding: $space-xxs;

            &::before {
                @include ffrees-icons-font;
                content: $icon-search;
                margin-right: $space-xxs;
            }
        }

        &::after {
            left: calc(50% - $space-xl);
            right: auto;
        }
    }

    &--dropdown {
        position: relative;

        &::after {
            @include input-icon;
            content: $icon-angle-down;
            color: rgba($black, 0.5);
            pointer-events: none;
        }

        .c-input__dropdown {
            position: absolute;
            opacity: 0;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
        }

        .c-input__hidden-option {
            display: none;
        }

        .c-input__dropdown-text {
            display: block;
            line-height: 1.35;

            span {
                visibility: hidden;
            }
        }
    }

    &--search {
        &::after {
            @include input-icon;
            content: $icon-search;
        }
    }

    &--max-width-350 {
        max-width: 350px;
    }

    &--prepended {
        padding-left: $pad-left-prepend;

        .#{$class}__prepend {
            opacity: 0;
        }
    }

    &--margin-bottom-xxs {
        margin-bottom: $space-xxs;
    }

    // label movement change
    &.is-active,
    &.is-filled,
    &--small-label {
        .#{$class}__label {
            @include font-size(10px, 1px);

            transform: translate3d(0, 3px, 0);
            top: 6px;
        }

        .#{$class}__prepend {
            top: $space-m - 1px;
            transform: translateY(0);
        }


        &.#{$class}--prepended {
            .#{$class}__prepend {
                opacity: 1;
            }

            .#{$class}__label {
                left: $pad-left-prepend + 2px;
            }
        }
    }

    &.is-empty,
    &.is-empty:hover,
    &.is-empty:focus {
        @include outer-shadow-tight($color-empty);
        border-color: $color-empty;

        &::after {
            @include input-icon;
            content: $icon-attention;
            color: $color-empty;
        }

        .#{$class}__validation-hint {
            opacity: 1;

            &::after {
                @include validation-icon;
                content: $icon-attention;
                color: $color-empty;
            }
        }
    }

    &.is-active,
    &.is-active:hover,
    &.is-active:focus {
        @include outer-shadow-tight($color-active);
        border-color: $color-active;

        &::after {
            color: $color-active;
        }

        .c-progress-circle {
            transform: scale(1);
        }
    }

    &.is-invalid,
    &.is-invalid:hover,
    &.is-invalid:focus {
        @include outer-shadow-tight($color-invalid);
        border-color: $color-invalid;

        &::after {
            @include input-icon;
            content: $icon-attention;
            color: $color-invalid;
        }

        .#{$class}__validation-message {
            opacity: 1;

            &::after {
                @include validation-icon;
                content: $icon-cancel-circled;
                color: $color-invalid;
            }
        }
    }

    &.is-valid,
    &.is-valid:hover,
    &.is-valid:focus {
        @include outer-shadow-tight($color-valid);
        border-color: $color-valid;

        //change icon
        &::after {
            @include input-icon;
            content: $icon-check;
            color: $color-valid;
        }

        &.c-input--dropdown {
            &::after {
                content: $icon-angle-down;
            }
        }
    }

    &.is-instructional,
    &.is-instructional:hover,
    &.is-instructional:focus {
        .#{$class}__info-message {
            opacity: 1;
        }
    }

    // TODO:RFC override super specificity caused by mixins higher up
    &--with-progress-circle.#{$class}--with-progress-circle.#{$class}--with-progress-circle {
        &::after {
            top: auto;
            bottom: $pad-progress-circle;
            transform: scale(1);
            transition: all 250ms 250ms;
        }

        &.is-active {
            &::after {
                transform: scale(0);
                transition-delay: 0;
            }

            .c-progress-circle {
                transition-delay: 250ms;
            }
        }
    }

    @media (min-width: $s_bp) {
        padding-top: $space-s;
        margin-bottom: $space-xl;

        &__prepend,
        &__label {
            top: $space-xxs + 2px;
        }

        &__prepend,
        &__label {
            top: $space-xxs + 2px;
        }

        &__prepend,
        &__label {
            top: $space-xxs + 2px;
        }

        &.is-active,
        &.is-filled,
        &--small-label {
            .#{$class}__prepend {
                top: $space-s - 1px;
            }
        }

        &--large {
            @include list-item-font-desktop;
            padding: ($space-xl - 2px) $space-xxxxl ($space-xxs + 2px) $space-xl;
            border-radius: $space-m;

            .#{$class}__prepend,
            .#{$class}__label {
                top: $space-m - 2px;
                left: $space-xl;
            }

            &::after {
                right: $space-xl;
            }

            &.#{$class}--prepended {
                padding-left: $space-xxxxl;
            }

            &.is-active,
            &.is-filled,
            .#{$class}--small-label {
                .#{$class}__label {
                    @include font-size(14px, 1px);
                    top: $space-xs;
                }

                .#{$class}__prepend {
                    top: $space-xl - 1px;
                }

                &.#{$class}--prepended {
                    .#{$class}__label {
                        left: $space-xxxxl + 2px;
                    }
                }
            }

        }
    }
}

#imessage{display: none;}



/*doc
---
title: Text Input
name: text-input
category: Inputs
---

Full documentation can be found in <a href="https://ffrees.atlassian.net/wiki/spaces/PROD/pages/1003815560/Input+component+.c-input+config">input documentation</a>

```html_example_table
<form>
    <div class="js-input-validation">
        <label for="test" id="test-label" class="u-display-block u-margin-bottom-xxs">First name</label>
        <label class="c-input">
            <span class="c-input__label">Type here</span>
            <input
            name="Name"
            value=""
            type="text"
            id="test"
            class="c-input__input js-input"
            data-v-input-min="1"
            data-v-input-max="100"
            data-v-type="free-text"
            data-v-hint="Please fill this in"
            data-v-message="Please enter a valid name"
            aria-labelledby="test-label"
            required>
            <span class="c-input__validation-hint"></span>
            <span class="c-input__validation-message"></span>
        </label>
    </div>
</form>
```
<div class="c-sg-modifier-wrap js-modifier-wrap">
    <div>
        <button data-target="c-input">c-input--large</button>
    </div>
    <div>
        <button data-target="c-input">c-input--small-label</button>
    </div>
    <div>
        <button data-target="c-input">c-input--search</button>
    </div>
</div>

*/

/*doc
---
title: Prepended Input
name: prepended-input
category: Inputs
---

Full documentation can be found in <a href="https://ffrees.atlassian.net/wiki/spaces/PROD/pages/1003815560/Input+component+.c-input+config">input documentation</a>

```html_example_table
<form>
    <div class="js-input-validation">
        <label for="test" id="test-label" class="u-display-block u-margin-bottom-xxs">Monthly take-home pay</label>
        <label class="c-input c-input--prepended">
            <span class="c-input__label">Type here</span>
            <span class="c-input__prepend">£</span>
            <input
            name="Name"
            value=""
            type="text"
            id="test"
            class="c-input__input js-input"
            data-v-input-min="1"
            data-v-input-max="100"
            data-v-type="free-text"
            data-v-hint="Please fill this in"
            data-v-message="Please enter a valid name"
            aria-labelledby="test-label"
            required>
            <span class="c-input__validation-hint"></span>
            <span class="c-input__validation-message"></span>
        </label>
    </div>
</form>
```
<div class="c-sg-modifier-wrap js-modifier-wrap">
    <div>
        <button data-target="c-input">c-input--large</button>
    </div>
    <div>
        <button data-target="c-input">c-input--margin-bottom-xxs</button>
    </div>
</div>

*/

/*doc
---
title: Select Input
name: select-input
category: Inputs
---

Full documentation can be found in <a href="https://ffrees.atlassian.net/wiki/spaces/PROD/pages/1003815560/Input+component+.c-input+config">input documentation</a>

```html_example_table
<form>
    <label class="c-input c-input--dropdown">
        <span class="c-input__label">How often</span>
        <span class="c-input__dropdown-text js-dropdown-text">
            <span>filler</span>
        </span>
        <select
        id="test3"
        class="c-input__dropdown js-input"
        name="frequency"
        required=""
        >
            <option selected="" disabled="" class="c-input__hidden-option"></option>
            <option disabled="">Please pick one</option>
            <option value="Monthly">Monthly</option>
            <option value="Fortnightly">Fortnightly</option>
        </select>
        <span class="c-input__validation-hint"></span>
        <span class="c-input__validation-message"></span>
    </label>
</form>
```
<div class="c-sg-modifier-wrap js-modifier-wrap">
    <div>
        <button data-target="c-input">c-input--large</button>
    </div>
</div>

*/

/*doc
---
title: Input and Button
name: input-button
category: Inputs
---

Full documentation can be found in <a href="https://ffrees.atlassian.net/wiki/spaces/PROD/pages/1003815560/Input+component+.c-input+config">input documentation</a>

```html_example_table
<form>
    <label class="c-input c-input--input-button js-input-validation">
        <span class="c-input__label">Postcode</span>
        <input
        id="firstAddress"
        class="c-input__input js-input"
        name="addressPostcode"
        value=""
        type="text"
        data-v-input-min="5"
        data-v-input-max="8"
        data-v-test="reg-match"
        data-v-type="postcode"
        data-v-hint="Please fill this in"
        data-v-message="Oops! Invalid postcode"
        required >
        <button class="c-input__input-button">
            Find Address
        </button>
        <span class="c-input__validation-hint"></span>
        <span class="c-input__validation-message"></span>
    </label>
</form>
```
*/
