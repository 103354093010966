//Variables are commented in as they are implemented

/*------------------------------------*\
    Font variables
\*------------------------------------*/
$base-font-size:    16px;
$base-line-height:  1.5;

$main-body-font-size-mobile: 14px;
$main-body-font-size-desktop: 16px;

$font-weight-book: 300;
$font-weight-medium: 500;
$font-weight-heavy: 700;
$font-weight-extra-bold: 800;

$font-weight-body: $font-weight-book;
$font-weight-heading: $font-weight-medium;

$main-font: 'futura-pt', sans-serif;

// Icon Variables (most used)
$icon-angle-up: '\e83e';
$icon-angle-down: '\e840';
$icon-angle-left: '\e83f';
$icon-angle-right: '\e83c';
$icon-arrow-up: '\e801';
$icon-arrow-right: '\e802';
$icon-arrow-left: '\e803';
$icon-arrow-down: '\e804';
$icon-atm: '\e84a';
$icon-attach: '\e827';
$icon-attention: '\e81a';
$icon-bank-transfers: '\e849';
$icon-bar-chart: '\e824';
$icon-broken-card: '\e86b';
$icon-calendar: '\e829';
$icon-cancel: '\e806';
$icon-cancel-circled: '\e881';
$icon-card-back: '\e811';
$icon-cash-back: '\e863';
$icon-chart-area: '\e81d';
$icon-chat: '\e862';
$icon-check: '\e884';
$icon-circular-arrows: '\e82e';
$icon-clock: '\e823';
$icon-contactless: '\e845';
$icon-conversation: '\e85c';
$icon-copy: '\e882';
$icon-direct-debit: '\e848';
$icon-double-angle-right: '\e86a';
$icon-download: '\e831';
$icon-download-envelope: '\e85f';
$icon-extra-accounts: '\e865';
$icon-eye: '\e874';
$icon-eye-off: '\e873';
$icon-facebook: '\e80c';
$icon-faster-payments: '\e84c';
$icon-file-open: '\e855';
$icon-flag: '\e814';
$icon-gauge: '\e810';
$icon-globe: '\e81c';
$icon-grid: '\e858';
$icon-info-fill: '\e813';
$icon-instagram: '\f16d';
$icon-line-chart: '\e80f';
$icon-linkedin: '\e80b';
$icon-loading-circle: '\e815';
$icon-lock: '\e800';
$icon-lock-open: '\e86d';
$icon-mail: '\e828';
$icon-mastercard: '\e834';
$icon-minus: '\e850';
$icon-mobile: '\e866';
$icon-money: '\e84b';
$icon-no-checks: 'e864';
$icon-paper-plane: '\e851';
$icon-paypoint: '\e86c';
$icon-pencil: '\e837';
$icon-picture: '\e830';
$icon-pie-chart: '\e825';
$icon-postal: '\e87d';
$icon-plus: '\e846';
$icon-resize: '\e87c';
$icon-search: '\e807';
$icon-send: '\e86f';
$icon-share: '\e883';
$icon-sliders: '\e85a';
$icon-small-arrow-down: '\e840';
$icon-small-arrow-up: '\e83e';
$icon-star: '\e820';
$icon-statements: '\e84d';
$icon-swipe: '\e868';
$icon-tools: '\e852';
$icon-twitter: '\e809';
$icon-u-logo-simplified: '\e869';
$icon-upload: '\e832';
$icon-user: '\e836';
$icon-user-add: '\e844';
$icon-users-group: '\e87b';
$icon-spanner: '\e872';
$icon-youtube: '\e808';

//Icons object used to generate utility classes
$icons:
icon-angle-up '\e83e',
icon-angle-down '\e840',
icon-angle-left '\e83f',
icon-angle-right '\e83c',
icon-arrow-down '\e804',
icon-arrow-left '\e803',
icon-arrow-right '\e802',
icon-arrow-up '\e801',
icon-atm '\e84a',
icon-attach '\e827',
icon-attention '\e81a',
icon-bank-transfers '\e849',
icon-bar-chart '\e824',
icon-broken-card '\e86b',
icon-calendar '\e829',
icon-cancel '\e806',
icon-cancel-circled '\e881',
icon-card-back '\e811',
icon-cash-back '\e863',
icon-chart-area '\e81d',
icon-chat '\e862',
icon-check '\e884',
icon-circular-arrows '\e82e',
icon-clock '\e823',
icon-contactless '\e845',
icon-conversation '\e85c',
icon-copy '\e882',
icon-direct-debit '\e848',
icon-double-angle-right '\e86a',
icon-download '\e831',
icon-extra-accounts '\e865',
icon-eye '\e874',
icon-eye-off '\e873',
icon-download '\e831',
icon-download-envelope '\e85f',
icon-facebook '\e80c',
icon-faster-payments '\e84c',
icon-file-open '\e855',
icon-flag '\e814',
icon-gauge '\e810',
icon-globe '\e81c',
icon-grid '\e858',
icon-info-fill '\e813',
icon-instagram '\f16d',
icon-line-chart '\e80f',
icon-linkedin '\e80b',
icon-loading-circle '\e815',
icon-lock '\e800',
icon-lock-open '\e86d',
icon-mail '\e828',
icon-mastercard '\e834',
icon-minus '\e850',
icon-mobile '\e866',
icon-money '\e84b',
icon-no-checks '\e864',
icon-paper-plane '\e851',
icon-paypoint '\e86c',
icon-pencil '\e837',
icon-picture '\e830',
icon-pie-chart '\e825',
icon-postal '\e87d',
icon-plus '\e846',
icon-resize '\e87c',
icon-search '\e807',
icon-send '\e86f',
icon-share '\e883',
icon-sliders '\e85a',
icon-small-arrow-up '\e83e',
icon-star '\e820',
icon-statements '\e84d',
icon-swipe '\e868',
icon-tools '\e852',
icon-twitter '\e809',
icon-u-logo-simplified '\e869',
icon-upload '\e832',
icon-user '\e836',
icon-user-add '\e844',
icon-users-group '\e87b',
icon-spanner '\e872',
icon-youtube '\e808';

$zero-width-space: '\200B';


/*------------------------------------*\
    Spacing variables
\*------------------------------------*/
$space-xxs: 8px;
$space-xs: 12px;
$space-s: 16px;
$space-m: 20px;
$space-l: 24px;
$space-xl: 28px;
$space-xxl: 36px;
$space-xxxl: 48px;
$space-xxxxl: 60px;

// same vars as above - for utility output
$spacing:
  0 0px,
  xxs 8px,
  xs 12px,
  s 16px,
  m 20px,
  l 24px,
  xl 28px,
  xxl 36px,
  xxxl 48px,
  xxxxl 60px;

// currently used in some mixins and components
$base-spacing-unit: $space-s;

/*------------------------------------*\
    Color palette
\*------------------------------------*/
// $color-1-light:  lighten($color-1, 39.5%); //orange

// orange
$color-1: #f2663b;
$color-1-light: #fef8f7;
$color-1-light-2:#F39200;
$color-1-mid: #fcded5;
$color-1-dark: darken($color-1, 10%); // #ea4310 (not defined by design - used for active states)

// green
$color-2: #6eb52b;
$color-2-light: #eff9e6;
$color-2-mid: #cfe4ba;
$color-2-dark: darken($color-2, 10%); // #558c21 (not defined by design - used for active states)

// yellow
$color-3: #ffb20a;
$color-3-light: #FFF6E1;
$color-3-dark: darken($color-3, 10%); // #d69300 (not defined by design - used for active states)

// red
$color-4: #d31a35;
$color-4-light: #FABCCC;
$color-4-dark: darken($color-4, 10%); // #a6142a (not defined by design - used for active states)

// blue
$color-5: #005EB0;
$color-5-light: #E8F1F8;
$color-5-dark: darken($color-5, 10%); // #00437d (not defined by design - used for active states)

$black: #000000;
$black-active: lighten($black, 30%);
$white: #ffffff;
$white-active: darken($white,10%);

$color-grey-1: #4d4d4d;
$color-grey-2: #afafaf;
$color-grey-3: #d1d3d4;
$color-grey-4: #f1f1f1;
$color-grey-5: #f6f6f6;
$color-grey-6: #f8f8f8;
$color-grey-7: #fbfcfc;
$color-grey-8: #757575; // lightest accessible grey against white
$color-grey-9: #e5e5e5;

// Don't use these - only here for backwards compatibility
$color-grey-xdark: #4d4d4d;
$color-grey-dark: #afafaf;
$color-grey-mid: #d1d3d4;
$color-grey-light: #ebebeb;
$color-grey-xlight: #f1f1f1;
$color-grey-xxlight: #f6f6f6;
$color-grey-xxxlight: #fbfcfc;

// chaining variable names
$color-highlight: $color-1;

$color-warning: $color-3;
$color-error: $color-4;
$color-success: $color-2;
$color-info: $color-5;
$color-info-light: rgb(232,241,248);

// text input and validation colors
$color-active: $color-1; //orange
$color-hover: $color-1; //orange
$color-invalid: $color-4; //red
$color-valid: $color-2; // green
$color-empty: $color-3; //yellow

$color-social-twitter: #0FA5F8;
$color-social-facebook: #27579A;

$color-dot-dot-loans-1: #ea9605;
$color-dot-dot-loans-2: #feefd7;
$color-dot-dot-loans-3: #ffe5bb;
$color-dot-dot-loans-4: #ebcada;
$color-dot-dot-loans-5: #e42386;

// used for looping over utilities
$colors: (
    color-1 $color-1,
    color-1-light $color-1-light,
    color-1-mid $color-1-mid,
    color-1-dark $color-1-dark,
    color-2 $color-2,
    color-2-light $color-2-light,
    color-2-mid $color-2-mid,
    color-2-dark $color-2-dark,
    color-3 $color-3,
    color-3-light $color-3-light,
    color-3-dark $color-3-dark,
    color-4 $color-4,
    color-4-light $color-4-light,
    color-4-dark $color-4-dark,
    color-5 $color-5,
    color-5-light $color-5-light,
    color-5-dark $color-5-dark,
    color-black $black,
    color-white $white,
    color-grey-xdark $color-grey-xdark,
    color-grey-dark $color-grey-dark,
    color-grey-mid $color-grey-mid,
    color-grey-light $color-grey-light,
    color-grey-xlight $color-grey-xlight,
    color-grey-xxlight $color-grey-xxlight,
    color-grey-xxxlight $color-grey-xxxlight,
    color-grey-1 $color-grey-1,
    color-grey-2 $color-grey-2,
    color-grey-3 $color-grey-3,
    color-grey-4 $color-grey-4,
    color-grey-5 $color-grey-5,
    color-grey-6 $color-grey-6,
    color-grey-7 $color-grey-7,
    color-grey-8 $color-grey-8,
    color-grey-9 $color-grey-9,
    highlight $color-highlight,
    color-twitter $color-social-twitter,
    color-facebook $color-social-facebook,
    color-dot-dot-loans-1 $color-dot-dot-loans-1,
    color-dot-dot-loans-2 $color-dot-dot-loans-2,
    color-dot-dot-loans-3 $color-dot-dot-loans-3,
    color-dot-dot-loans-4 $color-dot-dot-loans-4,
    color-dot-dot-loans-5 $color-dot-dot-loans-5
);

/*------------------------------------*\
    z-index table
\*------------------------------------*/
/* 1 */  $z-base: 1;
/* 5 */  $z-progress: 5;
/* 10 */ $z-tooltip: 10;
/* 20 */ $z-breadcrumb: 20;
/* 30 */ $z-menu: 30;
/* 40 */ $z-header: 40;
/* 50 */
/* 60 */
/* 70 */
/* 80 */ $z-alert: 80;
/* 90 */ $z-overlay: 90;
/* 100 */ $z-modal: 100;


/*------------------------------------*\
    reusuable style variables
\*------------------------------------*/
// $border-grey: 1px solid #d1d3d4;
$box-shadow-grey: inset 0 0 0 1px #d1d3d4;

$border-grey: 2px solid #d1d3d4;
$border-grey-tab: #e8e8e8;

$base-radius: 6px;


/*------------------------------------*\
    Media query variables
\*------------------------------------*/
$xxs_bp: 375px;
$xs_bp: 540px;
$s_bp: 800px;
$m_bp: 980px;
$l_bp: 1080px;
$xl_bp: 1200px;
$xxl_bp: 1440px;

// same as above - used in mixins as object
$breakpoints:
    xs 540px,
    s 800px,
    m 980px,
    l 1080px,
    xl 1200px,
    xxl 1440px;

//Page Scroll Height break points
$ps-s-h-bp: 450px;
$ps-m-h-bp: 850px;

//Shared media query variables
// $header-layout-change: 720px;


/*------------------------------------*\
    base64 images
\*------------------------------------*/
$img-arrow-right: 'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxOC4xLjEsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiDQoJIHZpZXdCb3g9IjAgMCAyNyAxMDAiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDI3IDEwMCIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+DQo8cmVjdCBmaWxsPSIjRjFGMUYxIiB3aWR0aD0iMjciIGhlaWdodD0iMTAwIi8+DQo8cG9seWdvbiBmaWxsPSIjRThFOEU4IiBwb2ludHM9IjEsMTAwIDAsMTAwIDAsMCAxLDAgMjYsNTAgIi8+DQo8L3N2Zz4NCg==';
$img-arrow-down: "data:image/svg+xml;base64,PHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgdmlld0JveD0iMCAwIDEwMCAxMDAiIHByZXNlcnZlQXNwZWN0UmF0aW89Im5vbmUiPg0KPHJlY3QgZmlsbD0iI0YxRjFGMSIgd2lkdGg9IjEwMCIgaGVpZ2h0PSIxMDAiLz4NCjxwb2x5Z29uIGZpbGw9IiNFOEU4RTgiIHBvaW50cz0iMCwzLjcgMCwwIDEwMCwwIDEwMCwzLjcgNTAsOTYuMyAiLz4NCjwvc3ZnPg0K";

/*------------------------------------*\
    component variables
\*------------------------------------*/
// these variables are shared across several components.
// if they were only relative to one component, they should be kept in the scope of that component

// header height variables - used both in the header component and the body top padding
$header-height-mobile: 60px;
$header-height-desktop: 100px;
$header-shadow: 0 2px 5px transparentize($black, .75);

$bottom-nav-height: 60px;
$bottom-nav-transition: 300ms;

// button padding variables
$button-padding-top-bottom: $space-xxs;
$button-padding-left-right: $space-m;

// button block heights - standard fixed height for certain types of buttons/components
$button-block-height-mobile: 56px;
$button-block-height-desktop: 76px;

// viewport height variables - fills the viewport height taking headers etc. into account
$viewport-height-mobile: calc(100vh - #{$header-height-mobile} - #{$bottom-nav-height});
$viewport-height-desktop: calc(100vh - #{$header-height-desktop});


/*doc
---
title: colors
name: colors
category: Colors
---
```html_example
<div class="l-row l-row--no-vertical-padding ">
    <div class="l-col-25">
        <div class="c-sg-color-block u-color-1-bg"></div>
        <p class="sg-p">$color-1: #f2663b</p>
    </div>
    <div class="l-col-25">
        <div class="c-sg-color-block u-color-1-light-bg"></div>
        <p class="sg-p">$color-1-light: #fef8f7</p>
    </div>
    <div class="l-col-25">
        <div class="c-sg-color-block u-color-1-mid-bg"></div>
        <p class="sg-p">$color-1-mid: #fcded5</p>
    </div>
    <div class="l-col-25">
        <div class="c-sg-color-block u-color-1-dark-bg"></div>
        <p class="sg-p">$color-1-dark: #ea4310</p>
    </div>
    <div class="l-col-25">
        <div class="c-sg-color-block u-color-2-bg"></div>
        <p class="sg-p">$color-2: #6eb52b</p>
    </div>
    <div class="l-col-25">
        <div class="c-sg-color-block u-color-2-light-bg"></div>
        <p class="sg-p">$color-2-light: #eff9e6</p>
    </div>
    <div class="l-col-25">
        <div class="c-sg-color-block u-color-2-mid-bg"></div>
        <p class="sg-p">$color-2-mid: #cfe4ba</p>
    </div>
    <div class="l-col-25">
        <div class="c-sg-color-block u-color-2-dark-bg"></div>
        <p class="sg-p">$color-2-dark: #558c21</p>
    </div>
    <div class="l-col-25">
        <div class="c-sg-color-block u-color-3-bg"></div>
        <p class="sg-p">$color-3: #ffb20a</p>
    </div>
    <div class="l-col-25">
        <div class="c-sg-color-block u-color-3-light-bg"></div>
        <p class="sg-p">$color-3-light: #fff6e1</p>
    </div>
    <div class="l-col-25">&nbsp;</div>
    <div class="l-col-25">
        <div class="c-sg-color-block u-color-3-dark-bg"></div>
        <p class="sg-p">$color-3-dark: #d69300</p>
    </div>
    <div class="l-col-25">
        <div class="c-sg-color-block u-color-4-bg"></div>
        <p class="sg-p">$color-4: #d31a35</p>
    </div>
    <div class="l-col-25">
        <div class="c-sg-color-block u-color-4-light-bg"></div>
        <p class="sg-p">$color-4-light: #fabccc</p>
    </div>
    <div class="l-col-25">&nbsp;</div>
    <div class="l-col-25">
        <div class="c-sg-color-block u-color-4-dark-bg"></div>
        <p class="sg-p">$color-4-dark: #a6142a</p>
    </div>
    <div class="l-col-25">
        <div class="c-sg-color-block u-color-5-bg"></div>
        <p class="sg-p">$color-5: #005eb0</p>
    </div>
    <div class="l-col-25">
        <div class="c-sg-color-block u-color-5-light-bg"></div>
        <p class="sg-p">$color-5-light: #e8f1f8</p>
    </div>
    <div class="l-col-25">&nbsp;</div>
    <div class="l-col-25">
        <div class="c-sg-color-block u-color-5-dark-bg"></div>
        <p class="sg-p">$color-5-dark: #00437d</p>
    </div>
</div>
```
*/

/*doc
---
title: greys
name: greys
category: Colors
---
```html_example
<div class="l-row l-row--no-vertical-padding ">
    <div class="l-col-25">
        <div class="c-sg-color-block u-color-black-bg"></div>
        <p class="sg-p">$black: #000000</p>
    </div>
    <div class="l-col-25">
        <div class="c-sg-color-block u-color-white-bg"></div>
        <p class="sg-p">$white: #ffffff</p>
    </div>
    <div class="l-col-25">
        <div class="c-sg-color-block u-color-grey-1-bg"></div>
        <p class="sg-p">$color-grey-1: #4d4d4d</p>
    </div>
    <div class="l-col-25">
        <div class="c-sg-color-block u-color-grey-2-bg"></div>
        <p class="sg-p">$color-grey-2: #afafaf</p>
    </div>
    <div class="l-col-25">
        <div class="c-sg-color-block u-color-grey-3-bg"></div>
        <p class="sg-p">$color-grey-3: #d1d3d4</p>
    </div>
    <div class="l-col-25">
        <div class="c-sg-color-block u-color-grey-4-bg"></div>
        <p class="sg-p">$color-grey-4: #f1f1f1</p>
    </div>
    <div class="l-col-25">
        <div class="c-sg-color-block u-color-grey-5-bg"></div>
        <p class="sg-p">$color-grey-5: #f6f6f6</p>
    </div>
    <div class="l-col-25">
        <div class="c-sg-color-block u-color-grey-6-bg"></div>
        <p class="sg-p">$color-grey-6: #f8f8f8</p>
    </div>
    <div class="l-col-25">
        <div class="c-sg-color-block u-color-grey-7-bg"></div>
        <p class="sg-p">$color-grey-7: #fbfcfc</p>
    </div>
    <div class="l-col-25">
        <div class="c-sg-color-block u-color-grey-8-bg"></div>
        <p class="sg-p">$color-grey-9: #757575</p>
    </div>
    <div class="l-col-25">
        <div class="c-sg-color-block u-color-grey-9-bg"></div>
        <p class="sg-p">$color-grey-9: #e5e5e5</p>
    </div>
</div>
```
*/
