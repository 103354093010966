input[type="button"],
input[type="submit"],
input[type="reset"],
input[type="file"],
button {
    border: none;
    text-align: center;
    cursor: pointer;
    vertical-align: top;

    &:focus {
        outline: none;
    }

    &:disabled {
        cursor: not-allowed;
        opacity: .5;
    }
}
